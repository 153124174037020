import React, { useEffect, useState } from 'react'
import UploadReadings from './UploadReadings'
import ArchiveReadingsGrid from './ArchiveReadingsGrid'
import { getAllNewsArticles } from '../services/services';
import CircleIcon from '../../chatbot/Icon';

//#Component Will Display the readings and Suggested Archieve Grid in Suggested Reading Page
const SuggestedReadingMain = () => {

  //#region varibales
  const [selectedArticle, setSelectedArticle] = useState(0);
  const [newsArticles,setNewsArticles] = useState();

  //#region api get calls
  const getSuggestedArticles = async () => {
    const data = await getAllNewsArticles();
    if (data.responseCode === 200) {
      const sortNewsArticles = data.responseData.reverse();
      setNewsArticles(sortNewsArticles);
    }
    else {
      //console.log('unable to fetch data)
    }
  }

  //#region useeffect
  useEffect(()=>{
    getSuggestedArticles();
  },[])

  //#region return
  return (
    <div className='wrapper child-margin-15'>
      <h1>Suggested Readings</h1>
     {/*  <PageHeader
              title="Suggested Readings"
              template={HeaderTemplates.NORMAL_VIEW}
              isHideBackbtn={true}/> */}
      <div>
        <UploadReadings selectedArticle={selectedArticle} selectedReadingID={selectedArticle.suggestedArticleID} setSelectedArticle={setSelectedArticle} getSuggestedArticles={getSuggestedArticles}/>
      </div>
      <div>
        <ArchiveReadingsGrid selectedArticle={selectedArticle} newsArticles={newsArticles} setSelectedArticle={setSelectedArticle} />
      </div>
      <CircleIcon/>
    </div>
  )
}

export default SuggestedReadingMain