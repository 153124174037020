import React, { useEffect, useState } from 'react'
import { HeaderTemplates, PageHeader } from '../../common/PageHeader/PageHeader'
import { auditingData } from '../auditingdata'
import AIPDataGrid from '../../common/DataGrid/AIPDataGrid'
import { useNavigate } from 'react-router-dom';
import { AuditingType } from '../audititngenum';
import { getAuditDetails } from '../services/services';
import Indicator from '../../common/DataGrid/Indicator';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CircleIcon from '../../chatbot/Icon';

const AuditingDashboard = () => {

  //#region variables
  const navigate = useNavigate();
  const [auditingData,setAuditingData] = useState([]);

  //#region grid columns
  const investorColumns = [
    { id: 1, field: 'userName', headerName: 'INVESTOR NAME', width: 500, },
    { id: 2, field: 'investorType', headerName: 'INVESTOR TYPE', width: 500, }
  ]
  
  const subscriptionColumns = [
    { id: 1, field: 'userName', headerName: 'INVESTOR NAME', width: 500, },
    { id: 2, field: 'investorType', headerName: 'INVESTOR TYPE', width: 500, }
  ]

  const fundColumns = [
    { id: 1, field: 'fundName', headerName: 'FUND NAME', width: 500, },
    { id: 2, field: 'fundType', headerName: 'FUND TYPE', width: 500, }
  ]
  
  const pCColumns = [
    { id: 1, field: 'companyName', headerName: 'COMPANY NAME', width: 500, },
    { id: 2, field: 'fundName', headerName: 'FUND NAME', width: 400, },
    { id: 3, field: 'fundType', headerName: 'FUND TYPE', width: 200, }
  ]

  //#region click events
  const handleInvetsorClick = (params) =>{
       navigate('/auditing-overview-page',{
        state: {
          auditingType: AuditingType.INVESTOR_PROFILE,
          auditUserID: params[0],
          investorType : auditingData?.userAuditDetails?.find((row) => row.userID === params[0])?.investorType,
          managerProfileFields: auditingData?.userAuditDetails?.find((row) => row.userID === params[0])?.manageProfileFields
        }
       });
  }

  const handleSubscriptionClick = (params) =>{
     
    navigate('/subscription-auditing-overview',{
      state: {
        auditingType: AuditingType.SUBSCRIPTION,
        auditUserID: params[0]
      }
     });
  }

  const handleFundClick = (params) =>{
    const selectedFund = auditingData?.fundAuditDetails?.find((fund)=> fund.fundID === params[0])
    navigate('/fund-auditing-overview',{
      state: {
        auditingType: AuditingType.FUND,
        auditID: params[0],
        auditDetails: selectedFund
      }
     });
  }

  const handlePCClick = (params) =>{
     
    navigate('/pc-auditing-overview',{
      state: {
        auditingType: AuditingType.PC,
        auditID: params[0]
      }
     });
  }

  //#region api get calls
  const getAuditingDetails = async() =>{
      const data = await getAuditDetails();
      if(data?.responseCode === 200){
        setAuditingData(data.responseData)
      }
      else{
        setAuditingData([]);
      }
  }

  //#region useeffect
  useEffect(()=>{
    getAuditingDetails();
  },[])

  return (
    <div className='page-wrapper'>

          <h1>Auditing</h1>
     
        <div className='whiteCard'>
          <h6>Investor Profile Auditing </h6>
            <div className='height-360 margin-top-20'>
              <AIPDataGrid 
                  columns={investorColumns} 
                  rows={auditingData?.userAuditDetails ? auditingData?.userAuditDetails?.map((item,index) => {
                      return {
                      id: item.userID, ...item
                      }
                  }) : []}
                  onRowsSelectionHandler={handleInvetsorClick}/>
            </div>
        </div>
        {/* <div className='whiteCard margin-top-20'>
            <div className='heading'>Subscription Auditing</div>
            <div className='height-360 margin-top-20'>
              <AIPDataGrid 
                  columns={subscriptionColumns} 
                  rows={auditingData?.subscribedUserDetails ? auditingData?.subscribedUserDetails?.map((item,index) => {
                      return {
                      id: item.userID, ...item
                      }
                  }) : []}
                  onRowsSelectionHandler={handleSubscriptionClick} />
            </div>
        </div> */}
        <div className='whiteCard margin-top-20'>
            <div className='heading'>Fund Auditing</div>
            <div className='height-360 margin-top-20'>
              <AIPDataGrid 
                  columns={fundColumns} 
                  rows={auditingData?.fundAuditDetails ? auditingData?.fundAuditDetails?.map((item,index) => {
                      return {
                      id: item.fundID, ...item
                      }
                  }) : []}
                  onRowsSelectionHandler={handleFundClick} />
            </div>
        </div>
        <div className='whiteCard margin-top-20'>
            <div className='heading'>Portfolio Auditing</div>
            <div className='height-360 margin-top-20'>
              <AIPDataGrid 
                  columns={pCColumns} 
                  rows={auditingData?.pcCompanyAuditDetails ? auditingData?.pcCompanyAuditDetails?.map((item,index) => {
                      return {
                      id: item.companyID, ...item
                      }
                  }) : []}
                  onRowsSelectionHandler={handlePCClick} />
            </div>
        </div>
        <CircleIcon/>
    </div>
  )
}

export default AuditingDashboard