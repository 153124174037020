import React from 'react';
import { TopNavBar } from '../navigation-bar/TopNavBar';
import { useNavigate } from "react-router-dom";
import { Outlet } from 'react-router-dom';
import { Button } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ExpiryLinkPage = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const invalid_signature = queryParams.get('invalid_signature');
  const token_expired = queryParams.get('token_expired');
  let navigate = useNavigate();
  const routeChange = () => {
    navigate('/');
  }

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '100%' }}>
        <TopNavBar fullname='' hideMenuButton={true} />
        <div className="alertMessage">
         <p> {invalid_signature && "Something went wrong. Please contact administrator."}</p>
         <p>{token_expired && "Sorry, This activation link is expired. Please contact administrator."}</p> 
         <p>{!invalid_signature && !token_expired && "This activation link have already been used."}</p> 
          <Button variant="contained" className='btn-primary'
          onClick={routeChange}>
            <ArrowBackIcon/>
            Go Back
        </Button>
        </div>

     
      </div>
    </div>
  )
};

export default ExpiryLinkPage;