import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
// import all slices here
import userReducer from "../slices/userSlice";
import authReducer from "../slices/authSlice";
import subscriberReducer from '../slices/subscribeSlice';
import colorConfigSlice from "../slices/colorConfigSlice";
import userDetailsSlice from "../slices/userDetailsSlice";
import MarketPlaceSlice from "../slices/MarketPlaceSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};
// Add all slices here
const rootReducer = combineReducers({
  user: userReducer,
  auth: authReducer,
  subscriber: subscriberReducer,
  colorConfig: colorConfigSlice,
  userDetails: userDetailsSlice,
  marketPlace: MarketPlaceSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
export let persistor = persistStore(store);
