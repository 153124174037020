import React, { useState } from 'react';
import TreeItem from '@mui/lab/TreeItem';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Checkbox from '@mui/material/Checkbox';
import { useEffect } from 'react';
import VDRoom from '../../../common/VirtualDataRoom/VDRoom';
import secureLocalStorage from 'react-secure-storage';
import Configurations from '../../../../Configurations';

function NotifyVDR(props) {
  useEffect(() => {
    
    console.log(props?.newUuid);
  }, []);

  return (
    <div>
          {Array.isArray(props?.vdrInfo) ? (
  <VDRoom VDRType={"Portfolio"} showToggle={true} vdrdata={props?.vdrInfo} portfolioGUID={props?.newUuid}/>
) : (
  <div>
    Please wait till we fetch the data....
  </div>
)}
       
    </div>
  );
}

export default NotifyVDR;
