import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Grid } from "@mui/material";
import MiniDrawer from "../../common/navigation-bar/NavBarGrid";
import styles from '../CompanyDetail.module.css'
import BasicTabs from "./TabSwitch";
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import Divider from '@mui/material/Divider';
import jsonData from '../jsonData.json';
import '../styles.css'
import { useState } from 'react';
import { useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import secureLocalStorage from 'react-secure-storage';
import { getCompanybyID } from '../Services/CompaniesDetails';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import CircleIcon from '../../chatbot/Icon';
import moment from 'moment';
import FirstComponent from '../../common/DatePicker/DatePicker';
import { checkFeaturePermissions } from '../../../utils/common';
import { FeatureAccess, RolesAndPermissions } from '../../../utils/enum';
import { Typography } from "@mui/material";

const PerformanceTab = (props) => {
    const [PerformanceData, setPerformanceData] = useState();

    const getCompaniesDetails = async () => {
        const companyID = secureLocalStorage.getItem("companyID");

        const data = await getCompanybyID(companyID);
        if (data.responseCode === 200) {
            console.log(data.responseData);
            setPerformanceData(data.responseData);
        } else {
            console.log("Error fetching company data");
        }
    }

    useEffect(() => {
        getCompaniesDetails();
    }, []);

    const handleChange = (field, value, datatype) => {
        const re = /^[0-9\b]+$/;
        if(datatype === "numeric"){
            if (!re.test(value)) {
                return;
            }
        }
        const currState = { ...PerformanceData };
        currState[field] = value;
        setPerformanceData(currState);
    }

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: "left",
    }));

    const [isSaveEnabled, setSaveEnabled] = useState(false);

    /* useEffect(() => {
        props.setSaveEnabled(isSaveEnabled);
    }, [isSaveEnabled]); */

    const handleSave = () => {
        const companyID = secureLocalStorage.getItem("companyID");
        if (isSaveEnabled) {
            const companyDetails = {
                "companyId": companyID,
                "industryId": PerformanceData.industryId,
                "sectorId": PerformanceData.sectorId,
                "companyTypeId": 1,
                "numberOfEmployees": PerformanceData.numberOfEmployees || 0,
                "eboolDAMargin": PerformanceData.eboolDAMargin || 0,
                "netIncomeMargin": PerformanceData.netIncomeMargin || 0,
                "revenueGrowthRate": PerformanceData.revenueGrowthRate || 0,
                "yearOverYearGrowthPercentage": PerformanceData.yearOverYearGrowthPercentage || 0,
                "grossIRR": PerformanceData.grossIRR || 0,
                "grossMOIC": PerformanceData.grossMOIC || 0,
                "ltmEboolda": PerformanceData.ltmEboolda || 0,
                "netIRR": PerformanceData.netIRR || 0,
                "returnOnAssets": PerformanceData.returnOnAssets || 0,
                "returnOnEquity": PerformanceData.returnOnEquity || 0,
                "tev": PerformanceData.tev || 0,
                "tevMultiple": PerformanceData.tevMultiple || 0,
                "latestFinancialStatementsDate": "2023-1-1",
                "latestFinancialStatements": PerformanceData.latestFinancialStatements || "",
                "latestOperatingUpdatesDate": "2023-1-1",
                "investmentStageId": PerformanceData.investmentStageId || 1,
                "exitStatusId": PerformanceData.exitStatusId || 1,
            }
        }
        setSaveEnabled(isSaveEnabled => !isSaveEnabled);
    }


    return (
        <div>
            <Grid item xs={12} marginBottom={1}>
                <div className='space-between'>  
                    <Typography variant='h5'> Performance</Typography>
                    <div>
                    {
   checkFeaturePermissions(RolesAndPermissions.PORTFOLIO_COMPANY, FeatureAccess.UPDATE ) &&
                        <Button variant='outlined' className='btn-primary'
                            startIcon={isSaveEnabled ? <SaveIcon /> : <EditIcon />}
                            onClick={() => handleSave(PerformanceData)}>
                            {isSaveEnabled ? 'Save' : 'Edit'}
                        </Button>
}
                    </div>
                </div>
            </Grid>
            <Box className='background-color-white' sx={{ padding: '16px' }}>
                <Grid container spacing={2} columnSpacing={4} marginTop={'20px'}>
                    <Grid item xs={12} md={6} >
                        {jsonData.performancerevenue.map((data, index) => (
                            <React.Fragment key={index}>
                                <Divider sx={{ color: 'gray', opacity: '1' }} />
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px' }}>
                                    <div>{data.name}</div>
                                    {data.type == "date" ?
                                        (  <FirstComponent
                                            value={PerformanceData && moment(PerformanceData[data.apiName]).format("YYYY-MM-DD")}                                            fieldName={data.apiName}
                                            className={isSaveEnabled ? "fieldClassSave" : "fieldClass"}
                                            readOnly={!isSaveEnabled}
                                            manualAlignment = {true}
                                            isSaveEnabled={isSaveEnabled}
                                            variant='standard'
                                            onhandleChange={(value) => handleChange(data.apiName, value, data.responseDatatype)}
                                          />
                                        ) : (
                                            <input
                                                value={PerformanceData && PerformanceData[data.apiName]}
                                                name={data.apiName}
                                                className={isSaveEnabled ? "fieldClassSave" : "fieldClass"}
                                                readOnly={!isSaveEnabled}

                                                onChange={(event) => handleChange(event.currentTarget.name, event.currentTarget.value, data.responseDatatype)}
                                            />
                                        )}
                                </div>
                            </React.Fragment>
                        ))}
                    </Grid>
                    <Grid item xs={12} md={6} >
                        {jsonData.performancefinancial.map((data, index) => (
                            <React.Fragment key={index}>
                                <Divider sx={{ color: 'gray', opacity: '1' }} />
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px' }}>
                                    <div>{data.name}</div>
                                    {data.type == "date" ?
                                         (  <FirstComponent
                                            value={PerformanceData && moment(PerformanceData[data.apiName]).format("YYYY-MM-DD")}                                            fieldName={data.apiName}
                                            className={isSaveEnabled ? "fieldClassSave" : "fieldClass"}
                                            readOnly={!isSaveEnabled}
                                            manualAlignment = {true}
                                            isSaveEnabled={isSaveEnabled}
                                            variant='standard'
                                            onhandleChange={(value) => handleChange(data.apiName, value, data.responseDatatype)}
                                          />
                                        ) : (
                                            <input
                                                value={PerformanceData && PerformanceData[data.apiName]}
                                                name={data.apiName}
                                                className={isSaveEnabled ? "fieldClassSave" : "fieldClass"}
                                                readOnly={!isSaveEnabled}

                                                onChange={(event) => handleChange(event.currentTarget.name, event.currentTarget.value, data.responseDatatype)}
                                            />
                                        )}
                                </div>
                                {/*  <Divider sx={{ color: 'gray', opacity: '1' }} /> */}
                            </React.Fragment>
                        ))}
                    </Grid>
                </Grid>
                <ToastContainer />
            </Box>
            <CircleIcon />
        </div>
    )
}

export default PerformanceTab