export const AuditingType = {
    INVESTOR_PROFILE: 'personal information',
    SUBSCRIPTION: 'subscription',
    FUND: 'fund',
    PC: 'portfolio company'
}

export const UserPersonalInformation = {
    firstName: "First Name",
    lastName: "Last Name",
    legalEntityTypeName: "Legal Entity Type",
    emailAddress: "Email Address",
    roleName: "Role Name",
    trustedContact: "Trusted Contact",
    taxFillingContact: "Tax Filing Status",
    annualPreTaxIncome: "Annual Pre Tax Income",
    numberOfDependents: "# Of Dependents",
    employerStatus: "Employment Status",
    employer: "Employer",
    occupation: "Occupation",
    spouseAnnualPreTaxIncome: "Spouse Annual Pre Tax Income",
    spouseHasIIAAccount: "Spouse Has IIA Account",
    federalTaxBracket: "Federal Tax Bracket",
    householdInvestableAssets: "Household Investable Assets",
    addressLine1: "Address Line 1",
    addressLine2: "Address Line 2",
    phoneNo: "Phone #",
    zipCode: "Zip-Code",
    cityName: "City Name",
    stateName: "State Name",
    isRegistrationCompleted: "Is Registration Completed",
    briefDescription: "Brief Description",
    // dateOfBirth: "Date Of Birth",
    fundName: "Fund Name",
    userName: "User Name",
    kycStatus: "KYC Status",
    isBulkUploadUser: "Is Bulk Upload User",
    investorType: "Investor Type",
    investorSubType: "Investor Sub Type",
    countryName: "Country",
    website: "Website",
    numberOfFamilyMembers: "# of Family Members",
    languagesSpoken: "Languages Spoken",
    investmentGoalName: "Investment Goal",
    advisoryServicesRequiredName: "Advisory Services",
    referralSource: "Referral Source",
    totalAssetsUnderManagement: "Total Assets Under Management",
    tin: "TIN",
    regulatoryStatusName: "Regulatory Status",
    updatedBy : "Updated By",
    updatedDate : "Updated Date"
}

export const UserBankInformation = {
    bankName: "Bank Name",
    accountNo: "Account #",
    branchLocation: "Branch Location",
    bankAddress: "Bank Address",
    cityName: "City Name",
    stateName: "State Name",
    addressLine1: "AddressLine1",
    addressLine2: "AddressLine2",
    amlStatus: "AML Status",
    updatedBy : "Updated By",
    updatedDate : "Updated Date"
}

export const UserProfileInformation = {
    questionName: "Question Name",
    answer: "Answer",
    updatedBy : "Updated By",
    updatedDate : "Updated Date"
}

export const SubscriptionSteps = {
    fundName: "Fund Name",
    questionName: "Question Name",
    answer: "Answer",
    updatedBy : "Updated By",
    updatedDate : "Updated Date"
}

export const SubscriptionDetails = {
    fundName: "Fund Name",
    fundSubscriptionStatus: "Fund Subscription Status",
    entityName: "Entity Name",
    capitalCall: "Committed Capital",
    isOfflineSubscription: "Is Offline Subscription",
    coUserName: "CO User Name",
    updatedDate: "Updated Date",
    updatedBy : "Updated By",
    updatedDate : "Updated Date"
}

export const FundDetails = {
    fundName: "Fund Name",
    fundSize: "Fund Size",
    minimumInvestmentAmount: "Minimum Investment Amount",
    fundStatus: "Fund Status",
    fundInceptionDate: "Fund Inception Date",
    fundClassType: "Fund Class Type",
    investmentCycle: "Investment Cycle",
    fundDescription: "Fund Description",
    // fundClassName: "Fund Class Name",
    strategyName: "Investment Strategy",
    targetIndustry: "Target Industry",
    fundStatusName: "Fund Status Name",
    fundManager: "Fund Manager",
    capitalCommitted: "Capital Committed",
    geographicFocusName: "Geographic Focus",
    investmentPeriodCriteria: "Investment Period Criteria",
    vintage: "Vintage",
    visibility: "Visibility",
    targettedIRR: "Targetted IRR",
    aum: "AUM",
    isFundMatured: "Is Fund Matured",
    inceptionDate: "Inception Date",
    expectedEndDate: "Expected End Date",
    isFundTerminated: "Is Fund Terminated",
    updatedBy : "Updated By",
    updatedDate : "Updated Date"
}

export const ManagementTeam = {
    managementTeamMember: "Management Team Member",
    title: "Title",
    description: "Description",
    updatedBy : "Updated By",
    updatedDate : "Updated Date"
}

export const InvestmentStrategy = {
    investmentStrategy: "Investment Strategy",
    investmentCriteria: "Investment Criteria",
    investmentProcess: "Investment Process",
    riskManagementApproach: "Risk Management Approach",
    valueCreationAndOperImprovementStrategies: "Value Creation And Operational Improvement Strategies",
    exitStrategies: "Exit Strategies",
    updatedBy : "Updated By",
    updatedDate : "Updated Date"
}

export const FeesExpenses = {
    managementFees: "Management Fees",
    performanceFeesOrCarriedInterest: "Performance Fees Or Carried Interest",
    additionalFeesAndExpenses: "Additional Fees And Expenses",
    updatedBy : "Updated By",
    updatedDate : "Updated Date"
}

export const CompanyDetails = {
    sectorName: "Sector Name",
    companyName: "Company Name",
    ceoName: "CEO Name",
    ceoEmail: "CEO Email",
    ceoPhone: "CEO Phone",
    cfoName: "CFO Name",
    cfoEmail: "CFO Email",
    cfoPhone: "CFO Phone",
    otherContactTitle: "Other Contact Title",
    otherContactPhone: "Other Contact Phone",
    otherContactName: "Other Contact Name",
    otherContactEmail: "Other Contact Email",
    legalCounselFirmName: "Legal Counsel Firm Name",
    legalCounselName: "Legal Counsel Name",
    legalCounselEmail: "Legal Counsel Email",
    legalCounselContactPhone: "Legal Counsel Contact Phone",
    boardRepresentation: "Board Representation",
    endDateTime: "End Date Time",
    updatedBy : "Updated By",
    updatedDate : "Updated Date"
}


export const PCTransactionDetailsColumm = {
    transactionTypeID: "Transaction Type ID",
    transactionTypeName: "Transaction Type Name",
    transactionDate: "Transaction Date",
    shareTypeID: "Share Type ID",
    shareTypeName: "Share Type Name",
    quantity: "Quantity",
    sharePrice: "Share Price",
    transactionCost: "Transaction Cost",
    amount: "Amount",
    roundOfInvestmentID: "Round Of Investment ID",
    particulars: "Particulars",
    narration: "Narration",
    companyID: "CompanyID",
    updatedBy : "Updated By",
    updatedDate : "Updated Date"
}

export const KYCAMLColumns = {
    questionType: "Type",
    questionName: "Question Name",
    answer: "Answer",
    updatedBy : "Updated By",
    updatedDate : "Updated Date"
}
