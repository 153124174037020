import React, { useState, useEffect } from 'react';
import { TextField, MenuItem, Box } from '@mui/material';
import { getFundFilterData } from '../marketplace/services/MarketplaceService';
import { getKeyValuePairs } from '../portfolio-companies/services/services';

const invesmentStatus = 
[{id : 1, value : 'Subscription Initiated'},
 {id : 2, value : 'Subscription Reviewed'},
 {id : 3, value : 'Subscription Approved'},
 {id : 4, value : 'Funds Received'},
 {id : 5, value : 'Investment Active'},
 {id : 6, value : 'Distributions Made'},
 {id : 7, value : 'Investment Matured'},
 {id : 8, value : 'Redemption Processed'},
 {id : 9, value : 'Investment Closed'}
]
export const DropdownFilter = ({ item, applyValue }) => {
  const [fundFilterData, setFundFilterData] = useState([]);

  const getFundFilters = async () => {
    const data = await getFundFilterData();
    if (data.responseCode === 200) {
      setFundFilterData(data.responseData.fundStatus);
    }
  };

  useEffect(() => {
    getFundFilters();
  }, []);

  return (
    
      <TextField
        select
        label="Select status"
        value={item.value || ''}
        onChange={(event) => applyValue({ ...item, value: event.target.value })}
        variant="standard"
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        sx={{
          '& .MuiInputBase-root': {
            backgroundColor: 'white',
            ///maxWidth:'100px'
          },
        }}
      >
        <MenuItem value="">
          <em>Select status</em>
        </MenuItem>
        {fundFilterData.map((option) => (
          <MenuItem key={option.fundStatusId} value={option.fundStatusName}>
            {option.fundStatusName}
          </MenuItem>
        ))}
      </TextField>
  
  );
};

export const SubscriptionStatusFilter = ({ item, applyValue }) => {
  const [fundFilterData, setFundFilterData] = useState([]);

  const getFundFilters = async () => {
    const data = await getKeyValuePairs("FundSubscriptionStatus");
    if (data.responseCode === 200) {
      setFundFilterData(data.responseData);
    }
  };

  useEffect(() => {
    getFundFilters();
  }, []);

  return (
    
      <TextField
        select
        label="Select status"
        value={item.value || ''}
        onChange={(event) => applyValue({ ...item, value: event.target.value })}
        variant="standard"
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        sx={{
          '& .MuiInputBase-root': {
            backgroundColor: 'white',
            ///maxWidth:'100px'
          },
        }}
      >
        <MenuItem value="">
          <em>Select status</em>
        </MenuItem>
        {fundFilterData?.map((option) => (
          <MenuItem key={option.listItemID} value={option.listItemValue}>
            {option.listItemValue}
          </MenuItem>
        ))}
      </TextField>
  
  );
};


export const ApplicationDateFilter = ({ item, applyValue }) => {

  const fundFilterData =[
    { value: 'All', label: 'All' },
    { value: 'Recent', label: 'Recent' },
    {value:'Last7Days',label:'Last 7 Days'},
    {value:'Last1Week',label:'Last 1 week'},
    {value:'Last1Month',label:'last 1 Month'},
    {value:'LastMonth',label:'Last Month'}
  ]

  return (
    
      <TextField
        select
        label="Select status"
        value={item.value || ''}
        onChange={(event) => applyValue({ ...item, value: event.target.value })}
        variant="standard"
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        sx={{
          '& .MuiInputBase-root': {
            backgroundColor: 'white',
            ///maxWidth:'100px'
          },
        }}
      >
        <MenuItem value="">
          <em>Select </em>
        </MenuItem>
        {fundFilterData?.map((option) => (
          <MenuItem key={option.label} value={option.label}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
  
  );
};


export const InvestmentStatusFilter = ({ item, applyValue }) => {
  return (
          <TextField
        select
        label="Select status"
        value={item.value || ''}
        onChange={(event) => applyValue({ ...item, value: event.target.value })}
        variant="standard"
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        sx={{
          '& .MuiInputBase-root': {
            backgroundColor: 'white',
            ///maxWidth:'100px'
          },
        }}
      >
        <MenuItem value="">
          <em>Select status</em>
        </MenuItem>
        {invesmentStatus?.map((option) => (
          <MenuItem key={option?.id} value={option?.value}>
            {option?.value}
          </MenuItem>
        ))}
      </TextField>
  
  );
};
