import React, { useState, useEffect } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Configurations from '../../Configurations';
import "./Google.css"
import secureLocalStorage from "react-secure-storage";
import GoogleIcon from '@mui/icons-material/Google';
const Google = () => {
  const navigate = useNavigate();
  const [ currUser, setCurrUser ] = useState({});

  const setUser = (decodedUserData, isUserProfileExists) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        firstName: decodedUserData.given_name,
        lastName: decodedUserData.family_name,
        emailAddress: decodedUserData.email,
        password: "gmail",
        userRoleId: 0,
        isActive: decodedUserData.email_verified,
        softDelete: true,
        createdBy: "admin",
        createdDate: "2023-05-13T07:22:40.189Z",
        updatedBy: "admin",
        updatedDate: "2023-05-13T07:22:40.189Z"
      })
    };
    console.log('setuser');
    fetch(`${Configurations.apiBaseUri}/v1/User/SetUser?origin=${window.location.origin}`, requestOptions)
      .then(response => {
        if (response.ok) {
          // successful account creation
          // redirect to dashboard or update state accordingly
          response.json().then(data => {
            console.log(data);
            secureLocalStorage.setItem("userId", data.userId);
            secureLocalStorage.setItem("userEmail", data.emailAddress);
            !isUserProfileExists && navigate('/user-selection');
            isUserProfileExists && navigate('/marketplace');
          })
        } else if (response.status === 409) {
          // email address already exists
          alert("Email already exists");
        } else if (response.status === 400) {
          // other error
          throw new Error("400");
        } else {
          // other error
          throw new Error("Something went wrong");
        }
      })
      .catch(error => {
        // handle network errors
        console.error(error);

      });
  }

  const getUserProfile = (decodedUserData) => {
    decodedUserData.email && fetch(`${Configurations.apiBaseUri}/v1/UserProfile/GetUserProfile?emailAddress=${decodedUserData.email}`, {
      method: 'GET',
    })
      .then(response => {
        if (response.ok) {
          // parse the response and update the state with the question data
          response.json().then(data => {
            setUser(decodedUserData, data.length);
          })
        } else {
          // handle the error response
          console.error(response);
          setUser(decodedUserData, 0);
        }
      })
  }

  useEffect(() => {
        if (currUser) {
          currUser.access_token && fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${currUser.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${currUser.access_token}`,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {
                  res.json().then(data => {
                    getUserProfile(data);
                  })
                })
                .catch((err) => console.log(err));
        }
    },[ currUser ]
  );

  const login = useGoogleLogin({
    onSuccess: tokenResponse => setCurrUser(tokenResponse),
    onError:() => {
      console.log('Login Failed');
    }
    //flow: 'auth-code',
  });

  return (
    <button className="btn" onClick={ () => login() }>
      <GoogleIcon alt="google-logo"/>
         Sign Up With Google
    </button>
  )
}
export default Google;