import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Google from '../../third-party/Google';
import CircleIcon from "../../chatbot/Icon";
import secureLocalStorage from "react-secure-storage";

const ResetPassword = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const userEmail = queryParams.get('userEmail');
    if(userEmail !== null){
      secureLocalStorage.setItem("userEmail", userEmail);
    }
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [data, setData] = useState({
      password: "",
      confirmpassword: "",
    });
    const [errors, setErrors] = useState({
      password: "",
      confirmpassword: "",
    });
  
    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  
    const togglePasswordVisibility = () => {
      setPasswordShown(!passwordShown);
    };
  
    const toggleConfirmPasswordVisibility = () => {
      setConfirmPasswordShown(!confirmPasswordShown);
    };
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setData({ ...data, [name]: value });
    };
    const handleSubmit = (event) => {
      event.preventDefault();
  
      // validate fields
      const newErrors = {};
      if (data.password.trim() === "") {
        newErrors.password = "Password is required";
      } else if (data.password.length < 8) {
        newErrors.password = "Password must be at least 8 characters";
      } else if (!/^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/.test(data.password)) {
        newErrors.password = "Password must be alphanumeric, contain at least one capital letter, and one special character (@, $, !, &, etc.)";
      }
      if (data.confirmpassword.trim() === "") {
        newErrors.confirmpassword = "Confirm password is required";
      } else if (data.password !== data.confirmpassword) {
        newErrors.confirmpassword = "Password do not match";
      }
      setErrors(newErrors);

      if(!newErrors.password && !newErrors.confirmpassword){
        const requestOptions = {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            emailAddress: secureLocalStorage.getItem("userEmail"),
            password: data.password,
          })
        };
        
      }
    };

    return (
      <div className="login d-flex align-items-center py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-9 col-lg-8 mx-auto">
              <div className="login-header">
                <h3 className="login-heading text-center">Create a New Password</h3>
                
              </div>
              <form onSubmit={handleSubmit}>
            <p className="text-danger text-center"> {error} </p>
            <p className="text-success text-center"> {success} </p>
                <div className="login-form">
                  <div className="position-relative my-3 inputGroup text-center">
                    <div className="d-flex align-items-center">
                      <input
                        type={passwordShown ? "text" : "password"}
                        className="border-0 flex-grow-1"
                        placeholder="Password"
                        name="password"
                        value={data.password}
                        onChange={handleInputChange}
                      />
                      <i
                        className={`bi bi-eye${passwordShown ? "" : "-slash"} flex-shrink-2`}
                        id="togglePassword"
                        onClick={togglePasswordVisibility}
                      ></i>
                    </div>
                    {errors.password && (
                      <span className="text-danger">{errors.password}</span>
                    )}
                  </div>
                  <div className="position-relative my-3 inputGroup text-center">
                    <div className="d-flex align-items-center">
                      <input
                        type={confirmPasswordShown ? "text" : "password"}
                        className="border-0 w-100"
                        placeholder="Confirm Password"
                        name="confirmpassword"
                        value={data.confirmpassword}
                        onChange={handleInputChange}
                      />
                      <i
                        className={`bi bi-eye${confirmPasswordShown ? "" : "-slash"} flex-shrink-2`}
                        id="toggleConfirmPassword"
                        onClick={toggleConfirmPasswordVisibility}
                      ></i>
                    </div>
                    {errors.confirmpassword && (
                      <span className="text-danger">{errors.confirmpassword}</span>
                    )}
                  </div>
  
                  <button
                    className="btn w-100 my-1 shadow-none d-flex justify-content-between my-3 align-items-center btn-login"
                  >
                    <span className="text-decoration-none w-100">
                      CONFIRM PASSWORD
                    </span>
                  </button>
                  
            
  
                  <div className="recaptcha1 mt-5 text-center">
                    This site is protected by reCAPTCHA and the
                    <p> Google
             <a className="privacy_TC-links" href="#"> Privacy Policy </a>
             and 
              <a className="privacy_TC-links" href="#"> Terms of Service </a>
              Apply
               </p>
  
  
  
                  </div>
                  <CircleIcon/>   
                </div>
              </form >
  
            </div>
          </div>
        </div>
      </div>
  
  
  
  
  
  
    );
  };
export default ResetPassword;