import { Lock, LockOpen, TextFields } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { useCallback, useRef, useState, useEffect } from "react";
import {
  LinkBubbleMenu,
  MenuButton,
  RichTextEditor,
  TableBubbleMenu,
  insertImages,
} from "mui-tiptap";

import EditorMenuControls from "./EditorMenuControls";
import { useExtensions } from "./useExtensions";

export default function Editor({ onSave,submittedContent }) {
  const [selectedOption, setSelectedOption] = useState();
  const extensions = useExtensions({
    placeholder: "Add your own content here...",
   // selectedOption: selectedOption,
  });
  const rteRef = useRef(null);
  const [isEditable, setIsEditable] = useState(true);
  const [showMenuBar, setShowMenuBar] = useState(true);

  const handleNewImageFiles = useCallback(
    (files, insertPosition) => {
      if (!rteRef.current?.editor) {
        return;
      }

      const attributesForImageFiles = files.map((file) => ({
        src: URL.createObjectURL(file),
        alt: file.name,
      }));
      
      insertImages({
        images: attributesForImageFiles,
        editor: rteRef.current.editor,
        insertPosition,
      });
    },
    []
  );

  const handleDrop = useCallback(
    (view, event, _slice, _moved) => {
      if (!(event instanceof DragEvent) || !event.dataTransfer) {
        return false;
      }

      const imageFiles = fileListToImageFiles(event.dataTransfer.files);
      if (imageFiles.length > 0) {
        const insertPosition = view.posAtCoords({
          left: event.clientX,
          top: event.clientY,
        })?.pos;

        handleNewImageFiles(imageFiles, insertPosition);

        event.preventDefault();
        return true;
      }

      return false;
    },
    [handleNewImageFiles]
  );

  const handlePaste = useCallback(
    (_view, event, _slice) => {
      if (!event.clipboardData) {
        return false;
      }

      const pastedImageFiles = fileListToImageFiles(
        event.clipboardData.files
      );
      if (pastedImageFiles.length > 0) {
        handleNewImageFiles(pastedImageFiles);
        return true;
      }

      return false;
    },
    [handleNewImageFiles]
  );

  useEffect(() => {
    const handleContentChange = () => {
      let content = rteRef.current?.editor?.getHTML() ?? "";
      onSave(content);
    };

    const editorInstance = rteRef.current?.editor;
    if (editorInstance) {
      editorInstance.on("update", handleContentChange);
    }

    return () => {
      if (editorInstance) {
        editorInstance.off("update", handleContentChange);
      }
    };
  }, [onSave,selectedOption]);

  const editor = rteRef.current?.editor;
useEffect(() => {
  if (!editor || editor.isDestroyed) {
    return;
  }
  if (!editor.isFocused || !editor.isEditable) {
    queueMicrotask(() => {
      const currentSelection = editor.state.selection;
      const currentContent = rteRef.current?.editor.getHTML() ?? "";
      editor
        .chain()
        .setContent(currentContent + " " + selectedOption)
        .setTextSelection(currentSelection)
        .run();
    });
  }
}, [ editor, editor?.isEditable, editor?.isFocused , selectedOption]);

useEffect(() => {
  const handleKeyDown = (event) => {
    if (event.key === "Backspace" && selectedOption) {
      event.preventDefault(); 
      queueMicrotask(() => {
        const currentSelection = editor.state.selection;
        const currentContent = rteRef.current?.editor.getHTML() ?? "";
        const updatedContent = currentContent.replace(selectedOption, "");
        editor
          .chain()
          .setContent(updatedContent)
          .setTextSelection(currentSelection)
          .run();
      });
     
    }
  };

  document.addEventListener("keydown", handleKeyDown);

  return () => {
    document.removeEventListener("keydown", handleKeyDown);
  };
}, [selectedOption]);

  return (
    <>
      <Box
        sx={{
          "& .ProseMirror": {
            "& h1, & h2, & h3, & h4, & h5, & h6": {
              scrollMarginTop: showMenuBar ? 50 : 0,
            },
          },
        }}
      >
        <RichTextEditor
          ref={rteRef}
          extensions={extensions}
          //editorDependencies={selke}
          editable={isEditable}
          content={submittedContent && submittedContent.length > 0 ? submittedContent[0] : ""}
          editorProps={{
            handleDrop: handleDrop,
            handlePaste: handlePaste,
          }}
          selectedOption={selectedOption}
          renderControls={() => (
            <EditorMenuControls
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          )}
          RichTextFieldProps={{
            variant: "outlined",
            MenuBarProps: {
              hide: !showMenuBar,
            },
            footer: (
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  borderTopStyle: "solid",
                  borderTopWidth: 1,
                  borderTopColor: (theme) => theme.palette.divider,
                  py: 1,
                  px: 1.5,
                }}
              >
              
                <MenuButton
                  value="formatting"
                  tooltipLabel={
                    showMenuBar ? "Hide formatting" : "Show formatting"
                  }
                  size="small"
                  onClick={() =>
                    setShowMenuBar((currentState) => !currentState)
                  }
                  selected={showMenuBar}
                  IconComponent={TextFields}
                />

                <MenuButton
                  value="formatting"
                  tooltipLabel={
                    isEditable
                      ? "Prevent edits (use read-only mode)"
                      : "Allow edits"
                  }
                  size="small"
                  onClick={() => setIsEditable((currentState) => !currentState)}
                  selected={!isEditable}
                  IconComponent={isEditable ? Lock : LockOpen}
                />
              </Stack>
            ),
          }}
        >
          {() => (
            <>
              <LinkBubbleMenu />
              <TableBubbleMenu />
            </>
          )}
        </RichTextEditor>
      </Box>
    </>
  );
}

function fileListToImageFiles(fileList) {
  return Array.from(fileList).filter((file) => {
    const mimeType = (file.type || "").toLowerCase();
    return mimeType.startsWith("image/");
  });
}
