import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import ActionButton from '../../../common/ActionButton/ActionButton';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import * as yup from 'yup';
import { useFormik } from 'formik';
import ALTTextField from '../../../common/input-fields/ALTTextField';
import { validationsConstants } from '../../../../utils/AppConstants';
import SelectField from '../../../common/input-fields/SelectField';
import { getKeyValuePairs } from '../../../portfolio-companies/services/services';
import CheckBoxField from '../../../common/input-fields/CheckBoxField';
import { questionPatterns } from '../../../investor-fund-subscription/services/apiDataTemplate';
import CrudDataGrid from '../../../investor-fund-subscription/components/CrudDataGrid';
import { dateValidationTypeValues, textFormatTypeEnum, textFormatTypesDropDown } from '../../../common/TextInput/appInputenum';
import MultiSelectDropdown from '../../../common/Multi-Select-Dropdown/MultiSelectDropdown';
import MultiSelectField from '../../../common/input-fields/MultiSelectField';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setQuestion } from '../services/services';
import { convertArrayToString, convertStringToArrayWithEmptyArray } from '../../../common/Functions/ConvertStringtoArray';
import { json } from 'd3';
import { configurationTypesEnum } from '../services/apiDataTemplate';

const AddQuestionPopup = (props) => {

    //#region props
    const { type,
            open,
            onClose,
            currQuestionDetails,
            parentQuestionDetails,
            questionPatternTypeIDsDetails,
            isChildQuestion,isEdit,stepsDetails,readOnly,allQuestionDetails,sectionTypes } = props;

    //#region variables
    const [loading, setLoading] = useState(false);
    const optionsGridColumns = [
        { field: 'answer', headerName: 'Option Name',editable: true, flex: 1 },
        { field: 'description', headerName: 'Option Description',editable: true, flex: 1 },
    ]
    const gridQuestionColumns = [
        { field: 'answer', headerName: 'Column Name',editable: true, flex: 1,
            renderCell: (params) => (
                <div className="editable-cell">
                    {params.value}
                </div>
            ),
            renderEditCell: (params) => (
                <TextField
                    fullWidth
                    variant="standard"
                    value={params.value}
                    onChange={(event) => {
                        const newValue = event.target.value;
                        // Regular expression to allow only alphabets, numbers, and spaces
                        if ( /^[a-zA-Z0-9 ]*$/.test(newValue) || newValue === '') {
                            params.api.setEditCellValue({
                                id: params.id,
                                field: params.field,
                                value: newValue,
                            });
                        }
                    }}
                    InputProps={{
                        className: 'editable-cell',
                        disableUnderline:true
                    }}
                />
            ),
         },
        { field: 'description', headerName: 'Column Description',editable: true, flex: 1,
            renderCell: (params) => (
                <div className="editable-cell">
                    {params.value}
                </div>
            ),
            renderEditCell: (params) => (
                <TextField
                    fullWidth
                    variant="standard"
                    value={params.value}
                    onChange={(event) => {
                        const newValue = event.target.value;
                        // Regular expression to allow only alphabets, numbers, and spaces
                        if (/^[a-zA-Z0-9 ]+$/.test(newValue) || newValue === '') {
                            params.api.setEditCellValue({
                                id: params.id,
                                field: params.field,
                                value: newValue,
                            });
                        }
                    }}
                    InputProps={{
                        className: 'editable-cell',
                        disableUnderline:true
                    }}
                />
            ),
         },
    ]

    //#region funcitons
    const currentquestionPatternTypeID = () => {
        const type = questionPatternTypeIDsDetails?.find((item) => item?.listItemID === questionDetails.values.questionPatternTypeID)?.listItemValue
        return type;
    }  

    const parentQuestionPatternTypeID = () => {
        const type = questionPatternTypeIDsDetails?.find((item) => item?.listItemID === parentQuestionDetails.questionPatternTypeID)?.listItemValue
        return type;
    } 

    const fetchGridInitialData = (value) => {
        if(value !== "" & !(Array.isArray(value))){
            return JSON.parse(value !== ""  ? value : "") || []
        }
        else if (Array.isArray(value)){
            return value
        }
        else{
            return []
        }
    }

    const childQuestionDefaultValues = () => {
        const stepName = stepsDetails.find((step)=>step.listItemID === parseInt(parentQuestionDetails.step))?.listItemValue
        questionDetails.setFieldValue("parentQuestionID", parentQuestionDetails.questionBankID)
        questionDetails.setFieldValue("step", parentQuestionDetails.step)
        questionDetails.setFieldValue("stepNo",parentQuestionDetails.step)
        questionDetails.setFieldValue("stepName",stepName)
        if(parentQuestionPatternTypeID() === questionPatterns.HEADER){
            questionDetails.setFieldValue("showIfParentAnswerIs", "")
        }
    }

    //#region change events
    const handleChange = (name,value) => {
        if(name === "questionPatternTypeID"){
            questionDetails.resetForm();
            if(isChildQuestion){
                childQuestionDefaultValues();
            }
        }
        if(name === "step"){
            questionDetails.setFieldValue("stepNo",value)
            const stepName = stepsDetails?.find((step)=>step.listItemID === parseInt(value))?.listItemValue
            questionDetails.setFieldValue("stepName",stepName)
        }
        //#TODO: Need to remove this and change date field checkbox to dropdown so user can set different types of validations 
        if(name === "gridSchema" && currentquestionPatternTypeID() === questionPatterns.DATE_TIME){
            if(value === true){
                questionDetails.setFieldValue(name,dateValidationTypeValues.ALLOW_FUTURE_DATES)
                return;
            }
            else{
                questionDetails.setFieldValue(name,"")
                return;
            }
        }
       questionDetails.setFieldValue(name,value)
    };

    //#region click events
    const onSaveClick = (details) => {
        let requestBody = details;
        requestBody.showIfParentAnswerIDIsIn = convertArrayToString(details.showIfParentAnswerIDIsIn)
        //Question Ordering
        const currStepDetails = allQuestionDetails?.find((question) => question?.branchTitle === details.stepName) 
        if(!isEdit && Array.isArray(currStepDetails?.children)){
            const lastQuestion = currStepDetails?.children[currStepDetails?.children?.length - 1]
            if(isChildQuestion){
                const parentQuestion = allQuestionDetails
                                        ?.find((item)=>item.branchTitle === currStepDetails.branchTitle)
                                        ?.children?.find((question) => question?.questionBank?.questionBankID === parentQuestionDetails.questionBankID)
                if(Array.isArray(parentQuestion?.children)){
                    const lastChildQuestion = parentQuestion?.children[parentQuestion?.children?.length - 1]
                    requestBody.questionNo = lastChildQuestion?.questionBank?.questionNo + 1   
                }
                else{
                    requestBody.questionNo = parentQuestion?.questionBank?.questionNo + 1
                }
            }
            else{
                requestBody.questionNo = lastQuestion?.questionBank?.questionNo + 100
            }
        }

        if( currentquestionPatternTypeID() === questionPatterns.DROP_DOWN ||
            currentquestionPatternTypeID() === questionPatterns.MULTI_CHOICE_QUESTION ||
            currentquestionPatternTypeID() === questionPatterns.RAIDO || 
            currentquestionPatternTypeID() === questionPatterns.GRID){
           if(questionDetails?.values.questionAnswer?.length > 0){
            if (questionDetails?.values.questionAnswer?.some((item) => item.answer.trim() === "")) {
                toast.warning(`Please ensure that all 
                                      ${currentquestionPatternTypeID() === questionPatterns.GRIDoption ? "column" : "option"}
                                    names are filled in the table before proceeding.`,
                     { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
                return;
            }            
            const mappedRequestBody = details?.questionAnswer?.map((option) => ({
                "questionAnswerID": 0,
                "questionBankID": details.questionBankID,
                "answer": option.answer,
                "additionalInfo": null,
                "description": option.description,
                "childID": 0,
                "isAdditionalInfo": false,
                "isSelected": false,
                "userProvidedAnswer": "",
                "createdBy": "0",
                "createdDate": new Date(),
                "updatedBy": "0",
                "updatedDate": new Date(),
                "isActive": true,
                "softDelete": false
            }));
            requestBody.questionAnswer = mappedRequestBody;
            if(currentquestionPatternTypeID() === questionPatterns.GRID){
                const columns = mappedRequestBody?.map((option) => ({
                 "field": `${option?.answer?.toLowerCase().trim()}`, "headerName": `${option?.answer}`, "type": "", "editable": true, "headerAlign":"left","align": "left", "width" : 200   
                }));
                requestBody.gridSchema = JSON.stringify(columns);
            }
            updateQuestion(requestBody);
           }
           else{
                toast.warning(`Please Add atleast one 
                             ${currentquestionPatternTypeID() === questionPatterns.GRIDoption ? "Column" : "option"}`,
                    { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
           }
        }
        else if(currentquestionPatternTypeID() === questionPatterns.FILE_UPLOAD && type === configurationTypesEnum.SUBSCRIPTION){
           requestBody.showInInvestProfile = false;
           updateQuestion(requestBody);
        }
        else{
            updateQuestion(requestBody);
        }
    }

    //#region api calls
    const updateQuestion = async (requestBody) => {
        setLoading(true);
        const data = await setQuestion(requestBody);
        if(data.responseCode === 200){
            if(isEdit){
                toast.success("Question Updated Successfully",
                    { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            }
            else{
                toast.success("Question Added Successfully",
                    { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            }
            setLoading(false);
            onClose({ isSave: true });;
        }
        else{
            if(isEdit){
                toast.error("unable to edit the question",
                    { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            }
            else{
                toast.error("unable to add the question",
                    { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            }
            setLoading(false);
        }
    }

    //#region formik validations
    const questionValidationSchema = yup.object().shape({
        questionPatternTypeID: yup
                    .string()
                    .trim()
                    .required(validationsConstants.REQUIRED),
        step: yup
                .string()
                .trim()
                .required(validationsConstants.REQUIRED),
        questionName: yup
                .string()
                .trim()
                .required(validationsConstants.REQUIRED),
        description: yup
                .string()
                .url(validationsConstants.URL),
        gridSchema:  
            yup.mixed().when([], (questionPatternTypeID, schema) => {
                if (currentquestionPatternTypeID() === questionPatterns.TEXT_BOX) {
                    return schema.required(validationsConstants.REQUIRED);
                }
                return schema;
        }),
        characterLength:  
            yup.string().when([], (questionPatternTypeID, schema) => {
                if (currentquestionPatternTypeID() === questionPatterns.TEXT_BOX) {
                    return schema.required(validationsConstants.REQUIRED);
                }
                return schema;
        }),
    });

    const questionDetails = useFormik({
        initialValues: currQuestionDetails,
        validationSchema: questionValidationSchema, 
        onSubmit: (values) => {
          onSaveClick(values);
        },
    });

    //#region useeffect
    useEffect(()=>{
        if(type === configurationTypesEnum.KYC_AML){
            const selectedLabel = stepsDetails?.find((item)=> item.listItemID === questionDetails.values.step)?.listItemValue; 
            const findQuestionType = sectionTypes?.find((item) => item.listItemValue === selectedLabel)?.listItemID;
            questionDetails.setFieldValue("questionTypeId", findQuestionType)
        }
    },[questionDetails.values.step])

    //#region return
    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>
                <div className='space-between'>
                    <div>
                    {
                        isChildQuestion ? 
                        isEdit ? "Edit Child Question" : "Add Child Question" : 
                        isEdit ? "Edit Question" : "Add Question"
                    }
                    </div>
                    <div className=''>
                       {/*  <ActionButton variant='outlined' disabled={loading} onClick={onClose} icon={<CloseIcon />}  /> */}
                       <Button disabled={loading} onClick={onClose} startIcon={<CloseIcon />}></Button>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className='child-margin-15'>
                    <div>
                        <SelectField 
                            name="questionPatternTypeID"
                            label="Question Type"
                            value={questionDetails?.values?.questionPatternTypeID || []}
                            onChange={(name, value) => handleChange(name, value)}
                            onBlur={questionDetails.handleBlur}
                            options={questionPatternTypeIDsDetails?.map(option => ({ label: option?.listItemValue, value: option?.listItemID }))}
                            required={true}
                            readOnly={isEdit || readOnly}
                            error={questionDetails.touched.questionPatternTypeID && Boolean(questionDetails.errors.questionPatternTypeID)}
                            errorMessage={questionDetails.touched.questionPatternTypeID && questionDetails.errors.questionPatternTypeID}/>
                    </div>
                    {
                        (isChildQuestion && ( parentQuestionPatternTypeID() !== questionPatterns.HEADER) ) &&
                        (
                            parentQuestionDetails.questionPatternType === questionPatterns.MULTI_CHOICE_QUESTION ? 
                            <div>
                                <MultiSelectField 
                                    name="showIfParentAnswerIDIsIn"
                                    label="Select Parent Option"
                                    value={
                                        Array.isArray(questionDetails?.values?.showIfParentAnswerIDIsIn) ? 
                                            questionDetails?.values?.showIfParentAnswerIDIsIn : 
                                            convertStringToArrayWithEmptyArray(questionDetails?.values?.showIfParentAnswerIDIsIn)
                                    }
                                    options={parentQuestionDetails?.questionAnswer?.map((item) => ({
                                        label: item?.answer,
                                        value: item?.questionAnswerID
                                    }))}                                      
                                    onChange={(name, value) => handleChange(name, value)}/>
                            </div> : 
                            <div>
                                <SelectField 
                                    name="showIfParentAnswerIs"
                                    label="Select Parent Option"
                                    value={questionDetails?.values?.showIfParentAnswerIs}
                                    options={parentQuestionDetails?.questionAnswer?.map((item) => ({
                                        label: item?.answer,
                                        value: item?.answer
                                    }))}                                      
                                    onChange={(name, value) => handleChange(name, value)}/>
                            </div>
                        )
                    }
                    {
                        questionDetails?.values?.questionPatternTypeID !== "" &&
                        <>
                            <div>
                                <SelectField 
                                    name="step"
                                    label={type === configurationTypesEnum.KYC_AML ? "Section" : "Step"}
                                    value={questionDetails?.values?.step}
                                    onChange={(name, value) => handleChange(name, value)}
                                    options={stepsDetails?.map(option => ({ label: option?.listItemValue, value: option?.listItemID }))}
                                    onBlur={questionDetails.handleBlur}
                                    readOnly={isChildQuestion || readOnly}
                                    required={true}
                                    error={questionDetails.touched.step && Boolean(questionDetails.errors.step)}
                                    errorMessage={questionDetails.touched.step && questionDetails.errors.step}/>
                            </div>
                            <div>
                                <ALTTextField 
                                    name="questionName"
                                    label="Question Name"
                                    value={questionDetails?.values?.questionName || ""}
                                    onChange={(name, value) => handleChange(name, value)}
                                    onBlur={questionDetails.handleBlur}
                                    required={true}
                                    readOnly={readOnly}
                                    error={questionDetails.touched.questionName && Boolean(questionDetails.errors.questionName)}
                                    errorMessage={questionDetails.touched.questionName && questionDetails.errors.questionName}/>
                            </div>
                            <div className='child-margin-5'>
                                <div>
                                    <CheckBoxField 
                                    name="setValidations"
                                    label="Is this question mandatory?"
                                    value={questionDetails?.values?.setValidations}
                                    defaultChecked={questionDetails?.values?.setValidations}
                                    disabled={readOnly}
                                    /* disabled={true} */
                                    onChange={(name, value) => handleChange(name, value)} />
                                </div>
                                <div>
                                    <CheckBoxField 
                                    name="isActive"
                                    label="Active Question"
                                    value={questionDetails?.values?.isActive}
                                    disabled={readOnly}
                                    defaultChecked={questionDetails?.values?.isActive}
                                    onChange={(name, value) => handleChange(name, value)} />
                                </div>
                                {
                                    (currentquestionPatternTypeID() !== questionPatterns.DATE_TIME && 
                                      type === configurationTypesEnum.SUBSCRIPTION) &&
                                    <>
                                            <div className='margin-top-10'>
                                                <ALTTextField 
                                                    name="description"
                                                    label="Url"
                                                    value={questionDetails?.values?.description || ""}
                                                    onChange={(name, value) => handleChange(name, value)}
                                                    onBlur={questionDetails.handleBlur}
                                                    required={false}
                                                    readOnly={readOnly}
                                                    error={questionDetails.touched.description && Boolean(questionDetails.errors.description)}
                                                    errorMessage={questionDetails.touched.description && questionDetails.errors.description}/>
                                            </div>
                                    </>
                                }
                            </div>
                        </>
                    }
                    {
                       (
                        currentquestionPatternTypeID() === questionPatterns.DROP_DOWN ||
                        currentquestionPatternTypeID() === questionPatterns.MULTI_CHOICE_QUESTION ||
                        currentquestionPatternTypeID() === questionPatterns.RAIDO 
                       ) &&
                       <>
                            <div>
                                <CrudDataGrid
                                    name="questionAnswer"
                                    initialColumns={optionsGridColumns}
                                    initialRows={fetchGridInitialData(questionDetails.values.questionAnswer)}
                                    onRowChange={(name, value) => handleChange(name, value)}
                                    height={300}
                                    disabled={readOnly} />
                            </div>
                       </>
                    }
                    {
                       currentquestionPatternTypeID() === questionPatterns.TEXT_BOX &&
                       <>
                            <div>
                                <SelectField 
                                    name="gridSchema"
                                    label="Format Type"
                                    value={questionDetails?.values?.gridSchema || ""}
                                    onChange={(name, value) => handleChange(name, value)}
                                    options={textFormatTypesDropDown}
                                    onBlur={questionDetails.handleBlur}
                                    required={true}
                                    readOnly={readOnly}
                                    error={questionDetails.touched.gridSchema && Boolean(questionDetails.errors.gridSchema)}
                                    errorMessage={questionDetails.touched.gridSchema && questionDetails.errors.gridSchema}/>
                            </div>
                            <div>
                                <ALTTextField 
                                    name="characterLength"
                                    label="Character Length"
                                    value={questionDetails?.values?.characterLength || ""}
                                    onChange={(name, value) => handleChange(name, value)}
                                    onBlur={questionDetails.handleBlur}
                                    required={true}
                                    readOnly={readOnly}
                                    textFormatType={textFormatTypeEnum.ONLY_NUMBERS}
                                    error={questionDetails.touched.characterLength && Boolean(questionDetails.errors.characterLength)}
                                    errorMessage={questionDetails.touched.characterLength && questionDetails.errors.characterLength}/>
                            </div>
                       </>
                    }
                    {
                       currentquestionPatternTypeID() === questionPatterns.DATE_TIME &&
                       <>
                            <div>
                                <CheckBoxField 
                                    name="gridSchema"
                                    label="Allow Future Dates"
                                    readOnly={readOnly}
                                    value={questionDetails?.values?.gridSchema === dateValidationTypeValues.ALLOW_FUTURE_DATES ? true : false}
                                    defaultChecked={questionDetails?.values?.gridSchema === dateValidationTypeValues.ALLOW_FUTURE_DATES ? true : false}
                                    onChange={(name, value) => handleChange(name, value)} />
                            </div>
                       </>
                    }
                     {
                        currentquestionPatternTypeID() === questionPatterns.GRID &&
                        <>
                             <div>
                                <CrudDataGrid
                                    name="questionAnswer"
                                    initialColumns={gridQuestionColumns}
                                    initialRows={fetchGridInitialData(questionDetails.values.questionAnswer)}
                                    onRowChange={(name, value) => handleChange(name, value)}
                                    height={300}
                                    disabled={readOnly}
                                    maxLimit={6} />
                             </div>
                             <div>
                                <ALTTextField 
                                    name="characterLength"
                                    label="Max Rows Limit"
                                    textFormatType={textFormatTypeEnum.ONLY_NUMBERS}
                                    value={questionDetails?.values?.characterLength || ""}
                                    onChange={(name, value) => handleChange(name, value)}
                                    onBlur={questionDetails.handleBlur}
                                    required={true}
                                    readOnly={readOnly}
                                    error={questionDetails.touched.characterLength && Boolean(questionDetails.errors.characterLength)}
                                    errorMessage={questionDetails.touched.characterLength && questionDetails.errors.characterLength}/>
                            </div>
                        </>
                    }
                </div>
            </DialogContent>
            <DialogActions>
                {
                    readOnly ? <> </> : 
                    <div className='space-between'>
                        <div>
                            <ActionButton variant='outlined' disabled={loading} onClick={onClose} icon={<CloseIcon />} label='Cancel' />
                        </div>
                        <div className='margin-left-10'>
                            <ActionButton
                                label='SAVE'
                                loading={loading}
                                startIconName=''
                                icon={<SaveIcon />}
                                onClick={questionDetails.handleSubmit} />
                        </div>
                    </div>
                }
            </DialogActions>
        </Dialog>
    )
}
export default AddQuestionPopup;