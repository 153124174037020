import { Outlet } from "react-router-dom";
import OnboardingContainer from "./OnboardingContainer";

const OnboardingLayout = ({
  // isSearchEnable
}) => {
  return (
    <>
      <OnboardingContainer 
      // isSearchEnable={isSearchEnable}
      >
        <Outlet />
      </OnboardingContainer>
    </>
  );
};

export default OnboardingLayout;
