import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Grid, Tooltip } from '@mui/material';
import TextField from '@mui/material/TextField';
import TextInput from '../../../common/TextInput/TextInput';
import { width } from '@mui/system';
import { MUIInputsMarginTop } from '../../../CommonCss';
import DeleteIcon from '@mui/icons-material/Delete';
import { MUITextBoxHeight } from '../../../CommonCss';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Gridaligncenter } from '../styles';
import CheckBoxRenderingDemo from '../Components/CheckBoxRenderingDemo';
import { OBMCQdataTemplate } from './APIDataTemplates';
import { OBQOptionsTemplate } from './APIDataTemplates'; 
import CheckBox from '@mui/icons-material/CheckBox';
import { OBQMCQoptionsdataTemplate } from './APIDataTemplates';
import { useEffect } from 'react';
import ALTTextField from '../../../common/input-fields/ALTTextField';
import { validationsConstants } from '../../../../utils/AppConstants';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import { textFormatTypeEnum } from '../../../common/TextInput/appInputenum';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
const AddNewMultiChoiceQuestion = (props) => {

  //#region validations
  const [Open,setOpen] = useState(true);
  let currHighestStep = 0;
  
  props.data.forEach((item) => {
    currHighestStep = Math.max(currHighestStep, item.step);
  });
  
  let highestquestionBankID = 0;
  props.data.forEach((item) => {
    highestquestionBankID = Math.max(highestquestionBankID, item.questionBankID);
  });

  const NewData = {
    ...OBMCQdataTemplate,
    questionBankID: highestquestionBankID+1,
    questionTypeId: props?.investorTypeID,//Investor Type ID
    onboardingQuestionPattern: props?.mcqType?.toString(),//Question Pattern ID
    step: currHighestStep + 1
  };

  const [ItemData,setItemData] = useState(props.edit ? props.selecteditem : {
    ...NewData
  });
  
  const [examplesarray, setexamplearray] = useState(
    props.edit ? props.selecteditem?.questionAnswer : [...NewData.questionAnswer]
  );
  

  const [Initialoptions,setInitialoptions] = useState(props.edit ? props.selecteditem?.questionAnswer : [...NewData.questionAnswer]);

  const isoptionsDisabled = props.edit ? ItemData.isQuestionUsedByInvestor : false;

  //#region change events
  const handleChange = (name,value) => {
    questionDetails.setFieldValue(name, value);
  }

  const handleChangeOptions = (ev, optionitem) => {
    const { name, value } = ev.target;
  
    setexamplearray((prevExamplesArray) => {
      return prevExamplesArray.map((item) => {
         
        if (item.questionAnswerID === optionitem.questionAnswerID) {
          return {
            ...item,
            [name]: value,
          };
        }
        return item;
      });
    });
    console.log(examplesarray);
    /*   */
  };

  const handleChangeChecbox = (name, value) => {
    if(value === true){
      questionDetails.setFieldValue("bookmarks", 1);
    }
    else{
      questionDetails.setFieldValue("bookmarks", 1);
    }
     questionDetails.setFieldValue(name, value);
  };

  //#region click events
  const onClose = () =>{
    setOpen(false)
    props.setSelectQuestionType(false);
  }

  const onAddOption = () =>{
    if(isoptionsDisabled){
       return
    }
    const NewOption = {...OBQMCQoptionsdataTemplate};
    let currHighestoptionID = 0;
  
    examplesarray.forEach((item) => {
      currHighestoptionID = Math.max(currHighestoptionID, item.questionAnswerID);
    });
    NewOption["questionAnswerID"] = currHighestoptionID+1;
    /*   */
    const newArray = [...examplesarray,NewOption]
    setexamplearray(newArray);
  }

  const deleteOption = (optionitem) => {
    // Filter out the deleted option
    
  
    /* const updatedArray = examplesarray.map((item) => {
      if (item.questionAnswerID === optionitem.questionAnswerID) {
        return {
          ...item,
          softDelete: 1,
        };
      }
      return item;
    }); */

    const newArray = examplesarray.filter((item) => item.questionAnswerID !== optionitem.questionAnswerID);
     
    setexamplearray(newArray);
  };
  
  const onSave = () => {
    if(examplesarray.length > 0){
      if(examplesarray.some((item) => item.answer === "")){
        toast.warning("options answer must contain value", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "colored",
        });
      }
      else{
        if(props.edit){
          /*   */
          const updatedOptions = examplesarray?.map(item => {
            // Check if the item exists in initialData
            const existsInInitialData = Initialoptions.some(initialItem => initialItem.questionAnswerID === item.questionAnswerID);
          
            // If the item doesn't exist in initialData, update questionBankID to 0
            if (!existsInInitialData) {
              item.questionAnswerID = 0;
            }
          
            return item;
          });
          questionDetails.values.questionAnswer = updatedOptions;
          const updatedData = props.data.map((item) => {
              if (item.questionBankID === questionDetails.values.questionBankID) {
                return {
                  ...questionDetails.values, 
                };
              }
              return item;
            });
           
          props.setNewData(updatedData);
          setOpen(false)
          props.setSelectQuestionType(false);
        }
        else{
         /*  const updatedOptions = examplesarray?.map(item => {
            // Check if the item exists in initialData
            const existsInInitialData = Initialoptions.some(initialItem => initialItem.questionAnswerID === item.questionAnswerID);
            // If the item doesn't exist in initialData, update questionBankID to 0
            if (!existsInInitialData) {
              item.questionAnswerID = 0;
            }
            return item;
          });*/
          questionDetails.values.questionAnswer = examplesarray;
          questionDetails.values.bookmarks = questionDetails.values.bookmarks?.toString();
            const NewArrayData = [...props.data, questionDetails.values];
            /*   */
            props.setNewData(NewArrayData);
            setOpen(false);
            props.setSelectQuestionType(false);          
        }
      }
    }
    else{
      toast.warning("please add atleast one option", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "colored",
        });
    }
  }

  //#region formik validations
  const mcqQuestionValidationSchema = yup.object().shape({
    questionName: yup
        .string()
        .required(validationsConstants.REQUIRED),
    description: yup
        .string()
        .required(validationsConstants.REQUIRED),
    shortName: yup
        .string()
        .required(validationsConstants.REQUIRED),
    footerText: yup
        .string()
        .required(validationsConstants.REQUIRED), 
    footerToggleDescription: yup
        .string()
        .required(validationsConstants.REQUIRED), 
    bookmarks: yup.string().when([], (bookmarks, schema) => {
          if (questionDetails?.values?.multipleAnswers) {
              return schema.required(validationsConstants.REQUIRED)
                     .test('is-nonzero', "Please enter a valid value. The minimum  selection should be more than 0 ",
                      value => value && parseInt(value) !== 0)
                    .test('is-greaterzero', "Please enter a postive value ",
                      value => value && parseInt(value) > 0)
                    .test('is-lessthanoptions', "The value must be less than number of options",value => {
                      return value && parseInt(value) <= examplesarray.length;
                  });
          }
          return schema;
      }),          
  });

  const questionDetails = useFormik({
    initialValues: ItemData,
    validationSchema: mcqQuestionValidationSchema,
    onSubmit: (values) => {
      onSave()
    },
  });

  //#region useeffect
  useEffect(() => {
    questionDetails.validateField('bookmarks');
  }, [examplesarray]);

  //#region return
  return (
    <Dialog open={Open} fullWidth>
       <DialogTitle>
         <h6> {props.edit ? "Edit a Question" : "Add a Question"} </h6> 
       </DialogTitle>
       <DialogContent>
          <div className='child-margin-15'>
                <div>
                  <ALTTextField
                    name='questionName'
                    label="Question"
                    value={questionDetails.values.questionName}
                    onChange={(name, value)=>handleChange(name, value)} 
                    disabled={isoptionsDisabled}
                    required={true}
                    onBlur={questionDetails.handleBlur}
                    error={questionDetails.touched.questionName && Boolean(questionDetails.errors.questionName)}
                    errorMessage={questionDetails.touched.questionName && questionDetails.errors.questionName} />
                </div>
                <div>
                  <ALTTextField
                    name="description"
                    label="Question Label"
                    value={questionDetails.values.description}
                    onChange={(name, value)=>handleChange(name, value)}
                    disabled={isoptionsDisabled}
                    required={true}
                    onBlur={questionDetails.handleBlur}
                    error={questionDetails.touched.description && Boolean(questionDetails.errors.description)}
                    errorMessage={questionDetails.touched.description && questionDetails.errors.description} />
                </div>
                <div>
                  <ALTTextField
                      name="shortName"
                      label="Short Name"
                      value={questionDetails.values.shortName}
                      onChange={(name, value)=>handleChange(name, value)}
                      charactersMaxLength = {40}
                      disabled={isoptionsDisabled}
                      required={true}
                      onBlur={questionDetails.handleBlur}
                      error={questionDetails.touched.shortName && Boolean(questionDetails.errors.shortName)}
                      errorMessage={questionDetails.touched.shortName && questionDetails.errors.shortName} />
                </div>
                <div>
                  <ALTTextField
                    name="footerText"
                    label="Footer Label"
                    value={questionDetails.values.footerText}
                    onChange={(name, value)=>handleChange(name, value)}
                    disabled={isoptionsDisabled}
                    onBlur={questionDetails.handleBlur}
                    required={true}
                    error={questionDetails.touched.footerText && Boolean(questionDetails.errors.footerText)}
                    errorMessage={questionDetails.touched.footerText && questionDetails.errors.footerText} />
                </div>
                <div>
                    <ALTTextField
                      name="footerToggleDescription"
                      label="Footer Description"
                      value={questionDetails.values.footerToggleDescription}
                      onChange={(name, value)=>handleChange(name, value)}
                      disabled={isoptionsDisabled}
                      required={true}
                      onBlur={questionDetails.handleBlur}
                      error={questionDetails.touched.footerToggleDescription && Boolean(questionDetails.errors.footerToggleDescription)}
                      errorMessage={questionDetails.touched.footerToggleDescription && questionDetails.errors.footerToggleDescription} />
                </div>
          </div>
          <div className='child-margin-10'>
            <div xs={12}>
                <div>{/* <CheckBox checked={ItemData.multipleoptions}/> */}
                <CheckBoxRenderingDemo
                    name="multipleAnswers"
                    label={"Allow investor to select multiple options"}
                    value={questionDetails.values.multipleAnswers}
                    handleChange={handleChangeChecbox}
                    isdisabled={isoptionsDisabled}
                    />
                </div>
                
            </div>
            {
              questionDetails.values?.multipleAnswers === true ?
                <div>
                  <ALTTextField
                    name='bookmarks'
                    label="Minimum Selection Value"
                    textFormatType={textFormatTypeEnum.ONLY_NUMBERS}
                    value={questionDetails.values?.bookmarks}
                    onChange={(name, value)=>handleChange(name, value)} 
                    disabled={isoptionsDisabled}
                    required={true}
                    onBlur={questionDetails.handleBlur}
                    error={questionDetails.touched.bookmarks && Boolean(questionDetails.errors.bookmarks)}
                    errorMessage={questionDetails.touched.bookmarks && questionDetails.errors.bookmarks}  />
                </div> : null
            }
            <div className='child-margin-15'>
              {
                examplesarray?.map((optionitem,index) => {
                    return (
                      <div>
                      <Grid item container spacing={2} xs={12} key={optionitem.questionAnswerID}>
                        <Grid item xs={2} sx={MUIInputsMarginTop}>
                          Option {index+1}
                        </Grid>
                        <Grid item xs={3}>
                        <Tooltip title={isoptionsDisabled ? optionitem.answer : ""}>
                          <TextField
                              placeholder={`answer`}
                              name='answer'
                              fullWidth
                              defaultValue={optionitem.answer}
                              onChange={(event) => {handleChangeOptions(event,optionitem)}}
                              disabled={isoptionsDisabled}
                          />
                        </Tooltip>
                        </Grid>
                        <Grid item xs={6}>
                        <TextField
                            placeholder={`description`}
                            name='description'
                            fullWidth
                            defaultValue={optionitem.description}
                            onChange={(event) => {handleChangeOptions(event,optionitem)}}
                            disabled={isoptionsDisabled}/>
                        </Grid>
                        <Grid item xs={1} sx={MUIInputsMarginTop} >
                          {
                            questionDetails.values.isQuestionUsedByInvestor !== true ? <DeleteIcon onClick={() => deleteOption(optionitem)}/> :  <DeleteIcon sx={{color: 'gray'}}disabled/>
                          }
                        </Grid>
                      </Grid>
                      </div>
                    );
                })
              }
            </div>
            <div onClick={onAddOption}>
                <Grid xs={12} sx={Gridaligncenter}>
                        <AddCircleOutlineIcon 
                                  sx={{height: '40px',
                                  width: '40px',
                                  color: isoptionsDisabled ? 'gray' : 'inherit', }} />
                    </Grid>
            </div>
          </div>
       </DialogContent>
       <DialogActions>
        <div className="space-between">
                <div>
                    <Button
                      component="label"
                      variant="outlined"
                     className='btn-primary'
                      onClick={onClose}>
                        <CancelIcon/>
                    Cancel
                    </Button>
                </div>
                
                <div className="margin-left-10">
                    <Button
                      component="label"
                      variant="contained"
                      className='btn-primary'
                      onClick={questionDetails.handleSubmit}>
                        <SaveIcon/>
                      SAVE
                    </Button>
                </div>
                </div>
       </DialogActions>
    </Dialog>
   
  )
}

export default AddNewMultiChoiceQuestion