import React from 'react'
import { formatAsUSACurrency } from './formatAsUSACurrency'

const GridCurrencyFormater = (params) => {
    
  return (
    <>
       {formatAsUSACurrency(params.params.value)}
    </>
  )
}

export default GridCurrencyFormater