import React, { useState, useEffect } from 'react';
import "../Feel2.css";
// import data from '../data.json';
import { useNavigate } from "react-router-dom";
import { CurrencyFormatter } from '../../../utils/Formater';
import secureLocalStorage from "react-secure-storage";
import { getrootBackgroundColorConfig } from '../../../state/slices/colorConfigSlice';
import { useSelector } from 'react-redux';
import { formatAsUSACurrency } from '../../common/CurrencyFormater/formatAsUSACurrency';
import { AppDashboards } from '../../../utils/enum';


const ListView = ({ isExpanded, filters, searchData, topValue, leftValue,navigateFromFMView, heightvalue, overflowyvalue, backgroundcolor, navigateFromInvestorDB, investorID, disableSubscriptionButton, showRejected, setShowRejected }) => {

  const navigate = useNavigate();
  const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);



  const InvestorOverview = (itemId, fundName, navigateFromInvestorDB, subscriptionStatus, subscriptionID, item, IconImage) => {
    const fundId = secureLocalStorage.setItem("FundId", itemId);
    secureLocalStorage.setItem("FundNameIO", fundName);
    secureLocalStorage.setItem("FundId", itemId);
    secureLocalStorage.setItem('SubscriptionID', subscriptionID)
    secureLocalStorage.setItem("FundName", fundName);

    if (window.location.href.indexOf('marketplace') > 0 || navigateFromFMView) {
      if (secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD) {
        /* navigate('/fund-details', { parsedFundId }, { fundStatusName }); */
        navigate('/fund-details', {
          state: {
            FundID: itemId,
            InvestorID: secureLocalStorage.getItem("userId"),
            SubscriptionID: 0,
            navigatefrommarketplace: true
          }
        });

      } else {

        const SelectedFund = {
          "FundMaturity": item.isFundMatured,
          "FundTermination": item.fundTerminated,
          BGImage: item.bgImage,
          IconImage: item.iconImage
        }
        secureLocalStorage.setItem("selectedFund", SelectedFund);
        navigate('/marketplace/OverviewPage', { itemId });
      }
    }
    else {
      if (navigateFromInvestorDB) {
        if (subscriptionStatus === 'Approved') {
          navigate('/Investor-Overview-Page', {
            state: {
              FundId: { itemId },
              subscriptionId: { subscriptionID }
            }
          });
        }
        else {
          navigate('/subscription-details', {
            state: {
              FundId: { itemId },
              subscriptionId: { subscriptionID },
              selectedRow: { item },
              listview: true
              // ,  fundName: selectedFundName ,
              , IconImage: IconImage, disableSubscriptionButton: { disableSubscriptionButton }
            }
          });
        }
      }
      else {
        navigate('/Investor-Overview-Page', { state: { FundId: { itemId } } });
      }
    }
    window.scrollTo(0, 0);
  };

  // This const is use to filter the data and showing to UI
  const [itemsList, setItemsList] = useState();
  const [fundDetails, setFundDetails] = useState();
  let InvestorFunds = []

  // this use case is used to fetch the fund data for respective users
  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers:
      {
        'Content-Type': 'application/json',
        'TenantID': secureLocalStorage.getItem('tenantID')
      },
    };
    const queryParams = {
      investorID: investorID,
      fundFilter: JSON.stringify(filters) ?? null,
      visibility: 'show',
      userId: secureLocalStorage.getItem("userId")
    }
    const queryString = new URLSearchParams(queryParams).toString();
    if (navigateFromInvestorDB) {
     
    }
    else {
       
     
    }
  // }, [filters, investorID, showRejected]); // We have commented this because of sort not working in list view
   }, [investorID, showRejected]);

  // This useeffect is to filter the fund based on filter options
  useEffect(() => {
    let data = fundDetails;
    if (data?.length > 1) {
      let sortVariable = "";
      if (filters?.sorting === "Oldest to newest" ||
        filters?.sorting === "Newest to Oldest") {
        sortVariable = "fundID";
      }
      if (filters?.sorting === "Min. Investment low to high" ||
        filters?.sorting === "Min. Investment high to low") {
        sortVariable = "minimumInvestmentAmount";
      }
      if (filters?.sorting === "Term low to high" ||
        filters?.sorting === "Term high to low") {
        sortVariable = "fundSize";
      }
      //sorting by descending
      if (filters?.sorting === "Oldest to newest" ||
        filters?.sorting === "Min. Investment low to high" ||
        filters?.sorting === "Term low to high") {
        // data = data.sort((a, b) => a[sortVariable] > b[sortVariable] ? 1 : -1); // for string
        data = [...data]?.sort((a, b) => a[sortVariable] - b[sortVariable]);  // for number
      }

      //sorting by ascending
      if (filters?.sorting === "Newest to Oldest" ||
        filters?.sorting === "Min. Investment high to low" ||
        filters?.sorting === "Term high to low") {
        // data = data.sort((a, b) => a[sortVariable] > b[sortVariable] ? -1 : 1);  // for string
        data = [...data]?.sort((a, b) => b[sortVariable] - a[sortVariable]);  // for number
      }
    }
    const filteredData = searchData?.length >= 5 ? data?.filter(obj => obj.title?.toLocaleLowerCase().includes(searchData?.toLocaleLowerCase())
      || obj.liquidity?.toLocaleLowerCase().includes(searchData?.toLocaleLowerCase())
      || obj.fundType?.toLocaleLowerCase().includes(searchData?.toLocaleLowerCase())) : data;

    console.log(filteredData);
    const filteredItem = filteredData?.filter((item) => {
      return (filters?.fundTypes?.length > 0 ||
        filters?.industries?.length > 0 ||
        filters?.fundManagers?.length > 0)
        ?
        (
          filters?.fundTypes?.some(type => type?.toLowerCase() === item?.fundTypeName?.toLowerCase()) ||
          filters?.industries?.some(industry => industry?.toLowerCase() === item?.targetIndustry?.toLowerCase()) ||
          filters?.fundManagers?.some(manager => manager?.toLowerCase() === item?.fundCompanyName?.toLowerCase())
        )
        :
        item;
    });
    setItemsList(filteredItem);
  }, [filters, searchData])

  return (
    <div
      className={isExpanded ? "marketplaceLookFeelLi-NX" : "marketplaceLookFeelLi top"}
      style={{
        backgroundColor: rootBackColorConfig.color,
        top: topValue,
        left: leftValue,
        height: heightvalue,
        overflowY: overflowyvalue,
        // background: backgroundcolor
      }}

    >
      {itemsList?.length > 0 ? (
        <div className="header-container-feel2">
          <p className="investment-heading">Investment</p>
          {navigateFromInvestorDB && <p className="fund-status">Fund Status</p>}
          <p className="target-heading">Target Return</p>
          <p className="minimum-heading">Minimum</p>
          <p className="liquidity-heading">Liquidity</p>
        </div>
      ) : (
        <div style={{ marginLeft: '4px', marginTop: '4px' }}>
          <p>No Investments.</p>
        </div>
      )}
      <div style={{
        height: heightvalue,
        overflowY: overflowyvalue
      }}>
        {itemsList?.map((item) => (
          !navigateFromInvestorDB && item?.fundTerminated ?
            null
            :
            <div onClick={() => InvestorOverview(item?.fundID, item?.fundName, navigateFromInvestorDB, item?.subscriptionStatus, item?.subscriptionID, item, item.iconImage)}
              className={navigateFromInvestorDB ? item?.isFundMatured
                ? "bgParent-grayout"
                : "bgParent" 
                : "bgParent"}
              key={item?.fundId}>
              <div className="bg" />
              <div className={
                item?.isFundMatured
                  ? "privateEquity-feel2-expanded"
                  : "privateEquity-feel2"
              }>{item?.fundTypeName}</div>
              <b className="consumerGoodsRetailEnhancem-feel2">{item?.fundName}</b>
              {navigateFromInvestorDB && <b className="b5">{item?.fundStatusName}</b>}
              <b className="b">{item?.targettedIRR}%</b>
              {/* <b className="b1">${item?.minimumInvestmentAmount.toLocaleString()}</b> */}
              <b className="b1">{formatAsUSACurrency(item?.minimumInvestmentAmount)}</b>
              <b className="quarterly">{item?.liquidityProvision}</b>
              <img
                className="favoriteButtonIcon"
                alt=""
                src={item.favourite ? '/MarketPlaceImages/favouriteicon1.svg' : '/favorite-button.svg'}
              />
              <div className="maskGroup11Wrapper">
                <img
                  className="maskGroup11"
                  alt=""
                  src={item?.iconImage ? item.iconImage : "/DefaultLogo.png"}
                />
              </div>
              <div className="label-open">
                <b className="labelcontent-open">{navigateFromInvestorDB ? (item?.subscriptionStatus === 'Approved' ||
                  item?.subscriptionStatus === 'Rejected' ? item?.subscriptionStatus
                  : `${item?.subscriptionStatus} | ${item?.isOfflineSubscription ? "Offline" : "Online"}`)
                  : item?.fundStatusName}</b>
              </div>
              <img
                className="chevronRightFill0Wght400GrIcon"
                alt=""
                src="/chevron-right-fill0-wght400-grad0-opsz48.svg"
              />
              <img className="addToCompare" alt="" src="/add-to-compare.svg" />
            </div>
        ))}
      </div>
    </div>

  );
};

export default ListView; 
