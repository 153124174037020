import { Outlet } from "react-router-dom";
import LoginContainer from "./LoginContainer";

const LoginLayout = () => {
  return (
    <>
      <LoginContainer>
        <Outlet />
      </LoginContainer>
    </>
  );
};

export default LoginLayout;
