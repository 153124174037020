import secureLocalStorage from "react-secure-storage"

export const fundSubscriptionStatues = {
    APPROVED : "Approved",
    REJECTED : "Rejected",
    COMPLIANCE_REVIEW : "Under Compliance Review",
    FM_REVIEW : "Under Fund Manager Review",
    INVESTOR_SIGN_PENDING : "Investor Sign Pending",
    Awaiting_Online_Processing : 'Awaiting Online Processing'
}

export const subscriptionBasicDetails = {
    fundID: 0,
    subscriptionID: 0,
    investorID: 0,
    isInvestorVerified: false
}

export const updateSubscriptionDetails = {
    "FundSubscriptionID" : '',
    "FundSubscriptionStatus" : '',
    "IsFundManager" : '',
    "EnvelopID" : '0',
    "RejectionComments" : '',
    "ActionByUserID" : secureLocalStorage.getItem("userId") || 0
}