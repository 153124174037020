import { useState,useEffect  } from "react";
import styles from "./CapitalCallDetailSaved.module.css";
import MiniDrawer from "../common/navigation-bar/NavBarGrid";
import { useNavigate } from "react-router-dom";
import DashboardHeader from "./DashboardHeader";
import Configurations from "../../Configurations";
import Calendar from 'react-calendar'
import jsonData from './capitalcall.json'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { RolesAndPermissions, FeatureAccess } from "../../utils/enum";
import { checkFeaturePermissions } from "../../utils/common";
import { CurrencyFormatter } from "../../utils/Formater";
import CircleIcon from "../chatbot/Icon";
import AIPDataGrid from "../common/DataGrid/AIPDataGrid";
import secureLocalStorage from "react-secure-storage";
import { HeaderTemplates, PageHeader } from "../common/PageHeader/PageHeader";
import { useSelector } from "react-redux";
import { getrootBackgroundColorConfig } from "../../state/slices/colorConfigSlice";
import moment from "moment";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from "react-bootstrap";
const CapitalCallDetailSaved= () => {


  const navigate = useNavigate();

const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);


  const onExecutivesummaryClick = () => {
    navigate("/marketplace/fund-detail-services-1");
  }

  const options = [
    'USD',
    'INR',
    // Add more options as needed
  ];
  const [selectedIds, setSelectedIds] = useState([]);
  const onRowsSelectionHandler = (ids) => {
    
    setSelectedIds(ids.length);
    console.log(selectedIds);
    // const selectedRowsData = ids.map((id) => rows.find((row) => row.id === id));
   //console.log(idsLength)
    //console.log(ids)
  };
 
 
  

    const [values, setValues] = useState(null);
 
  const [date, setDate] = useState(new Date());
  const [showCalendar, setShowCalendar] = useState(false);
  /*const [investordata,setInvestorData] = useState (null);*/

  const handleDateChange = (value) => {
    setDate(value);
    setShowCalendar(false);
  };

  const handleCalendarClick = () => {
    setShowCalendar(!showCalendar);
  };


  const [selectedCurrency, setSelectedCurrency] = useState(''); //currency dropdown//
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleCurrencyChange = (event) => {
    setSelectedCurrency(event.target.value);
  };

  const handleImageClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const [EditbuttonClick,setEditbuttonClick] = useState(true);
  

  const [formData33, setFormData33] = useState({ //to store user inputs//
    
    CapitalCallNumber: '',
    currency: '',
    PurposeOfCall: '',
    StatusOfTheCapitalCall:''
  });

  const handleChange33 = (e) => {
    const { name, value } = e.target;
    setFormData33((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const [value, onChange] = useState(new Date());
  const [isExpanded, setExpendState] = useState(false);
  
  const handleclickSaveButton = () =>
  {
    
  
    setEditbuttonClick(!EditbuttonClick);
  }


 
  const [response, setResponse] = useState(null);



  const handleEditClick = () => {
    setMode('save');
  };
  const [ccdata, setccData] = useState({capitalCallInvestors: [] });
  const [selectedSignedInvestors, setSelectedSignedInvestors] = useState(0);
  const [mode, setMode] = useState('edit');
   const [noOfInvestorsAllocated, setNoOfInvestorsAllocated] = useState(0);
   const [noOfSignedInvestors, setNoOfSignedInvestors] = useState(0);
   const [noOfWiredInvestors, setNoOfWiredInvestors] = useState(0);
   useEffect(() => {
    // Make sure to update the state values only once when the component mounts or when ccdata changes
    if (ccdata) {
      setNoOfInvestorsAllocated(ccdata.noOfInvestorsAllocated || 0);
      setNoOfSignedInvestors(ccdata.noOfSignedInvestors || 0);
      setNoOfWiredInvestors(ccdata.noOfWiredInvestors || 0);
    }
  }, [ccdata]); // Run this effect whenever ccdata changes
  
  console.log("wired", noOfWiredInvestors); 
  
   const handleAddCallClick = () => {
     setNoOfInvestorsAllocated(noOfInvestorsAllocated);
     setNoOfSignedInvestors(selectedIds);
     setNoOfWiredInvestors(selectedIds);
     setMode('save');
   };
   const handleSaveClick = () => {
    setNoOfInvestorsAllocated(noOfInvestorsAllocated);
     setNoOfSignedInvestors(selectedIds);
     setNoOfWiredInvestors(selectedIds);
    setMode('edit');
   
  };
  useEffect(() => {
    const fetchData1 = async () => {
      try {
        const response = await fetch(
          `${Configurations.apiBaseUri}/v1/Servicing/GetActiveCapitalCallDetails?fundID=1
          `
          );
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        
        console.clear()
        console.log("hi")
        
        const data = await response.json();
        console.log(data)
        setccData(data);
       
      } catch (error) {
        
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData1();
  }, []);
  useEffect(() => {
    // Ensure that ccdata is not empty before calculating selectedSignedInvestors
    if (ccdata.capitalCallInvestors && ccdata.capitalCallInvestors.length > 0) {
      // Filter the array to get items with "subscriptionStatus" equal to "Signed"
      const signedInvestors = ccdata.capitalCallInvestors.filter(
        (item) => item.subscriptionStatus === "Signed"
      );

      // Get the length of the filtered array and update the selectedSignedInvestors state
      setSelectedSignedInvestors(signedInvestors.length);
      console.log("signed")
      console.log(selectedSignedInvestors)
    }
  }, [ccdata]);
  
const ccgridrows = ccdata ? ccdata.capitalCallInvestors.map (ccrows=>({
  id:ccrows.capitalCallInvestorID,
  INVESTORS:ccrows.investor,
  STATUS:ccrows.subscriptionStatus,
  TYPE:ccrows.investorType,
  "CALL AMOUNT":(ccrows.callAmount),
  "SUBSCRIBED":(ccrows.subscribed),
  "CONTRIBUTED":(ccrows.contributed),
  "UNFUNDED":(ccrows.unfunded),
  "OWNERSHIP":ccrows.percentOfOwnerShip,
  RECEIVED:(ccrows.received),
  "DATE RECEIVED":moment(ccrows.dateReceived).format("MM-DD-YYYY")
  
  
})): [];
const fundId = secureLocalStorage.getItem("FundId");

  const [previousCapitalCallDetails, setPreviousCapitalCallDetails] = useState(null);
  useEffect(() => {
    const fetchData1 = async () => {
      try {
        const response = await fetch(
          `${Configurations.apiBaseUri}/v1/Servicing/GetPreviousCapitalCallDetails?fundID=1
          `
          );
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        
        console.clear()
        console.log("hi")
        
        const data = await response.json();
        console.log(data)
        setPreviousCapitalCallDetails(data);
      } catch (error) {
        
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData1();
  }, []);

  const prevccgridrows = previousCapitalCallDetails ? previousCapitalCallDetails.map (PreviousRows=> ({
    id:PreviousRows.ccid,
    DATE:PreviousRows.callDate,
    STATUS:PreviousRows.callStatus,
    "TARGET RAISE":(PreviousRows.targetRaise),
    "SUBSCRIBED/COMMITED":(PreviousRows.committedCC),
    "Total Contributed":(PreviousRows.totalContributed),
    "Total Recieved":(PreviousRows.totalReceived),
    "No.of Investors":PreviousRows.noofInvestors

  })) : [];
 

  /* const investordatarows = data ? data.map (data => ({
       id:data.capitalCallInvestorID,
       INVESTORS:data.investorName,
       CALLAMOUNT:data.commitedAmountForThisCC,
       SUBSCRIBED:data.investorSubscribedAmount,
       CONTRIBUTED:data.contributed,
       UNFUNDED:data.unfunded,
       OWNERSHIP:data.percentOfOwnerShip,
       RECIEVED:data.amountReceived,
       DATARECIEVED:data.amountReceivedOn,


       
   })) : []; */
   

  return (
    <div style={{backgroundColor: rootBackColorConfig.color}} className={styles.capitalCallDetailSaved}>
      <PageHeader
          //onBtnClick={props.onClick}
          template={HeaderTemplates.Standard} 
          fundName={`Nexus Health Equity Fund`} 
        />
      <div className={styles.path450Icon} alt=""src="/capitalimages/path-4501.svg" />
      <div className={styles.capitalCallDetailSavedChild} />
      <div className={styles.capitalCallDetailSavedItem} />
      {/*<div className={styles.capitalCallDetailSavedInner} />
      <div className={styles.lineDiv} />
      <div className={styles.capitalCallDetailSavedChild1} />
      <div className={styles.capitalCallDetailSavedChild2} />
   {/*   <img className={styles.colorIcon} alt=""src="/capitalimages/color5.svg" />
      <img className={styles.colorIcon1} alt=""src="/capitalimages/color6.svg" />
      <img className={styles.colorIcon2} alt=""src="/capitalimages/color5.svg" />
      <img className={styles.colorIcon3} alt=""src="/capitalimages/color5.svg" />
      <img className={styles.colorIcon4} alt=""src="/capitalimages/color5.svg" />
  <img className={styles.colorIcon5} alt=""src="/capitalimages/color5.svg" /> */}
      
      <div className={styles.colorIcon6}>
             <AIPDataGrid 
             className={styles.colorIcon6} 
             columns={jsonData.DistributionsColumns} 
             rows={ccgridrows}
             onRowsSelectionHandler={onRowsSelectionHandler}
             checkboxSelection={true}/>
          </div>
      {/*<b className={styles.thomasSmith}>Thomas Smith</b>
      <div className={styles.textButton02}>
        <b className={styles.samanthaChen}>Samantha Chen</b>
      </div>
      <b className={styles.marcusJohnsonTrust}>
        <p className={styles.marcusJohnson}>Marcus Johnson</p>
        <p className={styles.marcusJohnson}>Trust</p>
      </b>
      <b className={styles.davidRodriguez}>David Rodriguez</b>
      <b className={styles.sarahPatel}>Sarah Patel</b>
      <div className={styles.individual}>Individual</div>
      <div className={styles.individual1}>Individual</div>
      <div className={styles.trust1}>Trust</div>
      <div className={styles.individual2}>Individual</div>
      <div className={styles.individual3}>Individual</div>
      <div className={styles.mar12023}>Mar. 1, 2023</div>
      <div className={styles.mar120231}>Mar. 1, 2023</div>
      <div className={styles.mar120232}>Mar. 1, 2023</div>
      <div className={styles.mar120233}>Mar. 1, 2023</div>
      <div className={styles.mar120234}>Mar. 1, 2023</div>
      <div className={styles.ndReminder}>2nd Reminder</div>
      <div className={styles.signed}>Signed</div>
      <div className={styles.signed1}>Signed</div>
      <div className={styles.signed2}>Signed</div>
      <div className={styles.pending}>Pending</div>
      <div className={styles.div}>2.75%</div>
      <div className={styles.div1}>2.75%</div>
      <div className={styles.div2}>2.75%</div>
      <div className={styles.div3}>2.75%</div>
      <div className={styles.div4}>2.75%</div>
      <div className={styles.div5}>$215,000</div>
      <div className={styles.div6}>$50,000</div>
      <div className={styles.div7}>$15,000</div>
      <div className={styles.div8}>$15,000</div>
      <div className={styles.div9}>$15,000</div>
      <div className={styles.div10}>$15,000</div>
      <div className={styles.div11}>$150,000</div>
      <div className={styles.o0}>$215,000.o0</div>
      <div className={styles.o01}>$215,000.o0</div>
      <div className={styles.o02}>$215,000.o0</div>
      <div className={styles.div12}>$22,575</div>
      <div className={styles.div13}>$22,575</div>
      <div className={styles.div14}>$22,575</div>
      <div className={styles.div15}>$22,575</div>
      <div className={styles.div16}>$22,575</div>
      <div className={styles.investorsParent}>
        <div className={styles.investors}>Investors</div>
        <img className={styles.groupChild} alt=""src="/capitalimages/polygon-7.svg" />
      </div>
      <div className={styles.statusParent}>
        <div className={styles.status}>Status</div>
        <img className={styles.groupChild} alt=""src="/capitalimages/polygon-7.svg" />
      </div>
      <div className={styles.typeParent}>
        <div className={styles.type}>Type</div>
        <img className={styles.groupInner} alt=""src="/capitalimages/polygon-7.svg" />
      </div>
      <div className={styles.subscribedParent}>
        <div className={styles.type}>Subscribed</div>
        <img className={styles.polygonIcon} alt=""src="/capitalimages/polygon-7.svg" />
      </div>
      <div className={styles.callAmountParent}>
        <div className={styles.investors}>Call Amount</div>
        <img className={styles.groupChild} alt=""src="/capitalimages/polygon-7.svg" />
      </div>
      <div className={styles.parent}>
        <div className={styles.div17}>$50,000</div>
        <div className={styles.div18}>50,000</div>
        <div className={styles.o03}>$192,474.o0</div>
        <div className={styles.o04}>$192,474.o0</div>
        <div className={styles.o05}>$192,474.o0</div>
        <div className={styles.contributedParent}>
          <div className={styles.contributed}>Contributed</div>
          <img className={styles.groupChild} alt=""src="/capitalimages/polygon-7.svg" />
        </div>
      </div>
      <div className={styles.group}>
        <div className={styles.div17}>$135,000</div>
        <div className={styles.div18}>$0.00</div>
        <div className={styles.o03}>$0.00</div>
        <div className={styles.o04}>$0.00</div>
        <div className={styles.o05}>$0.00</div>
        <div className={styles.contributedParent}>
          <div className={styles.unfunded}>Unfunded</div>
          <img className={styles.groupChild} alt=""src="/capitalimages/polygon-7.svg" />
        </div>
      </div>
      <div className={styles.ownershipParent}>
        <div className={styles.type}>Ownership</div>
        <img className={styles.groupChild4} alt=""src="/capitalimages/polygon-7.svg" />
      </div>
      <div className={styles.receivedParent}>
        <div className={styles.received}>Received</div>
        <img className={styles.groupChild} alt=""src="/capitalimages/polygon-7.svg" />
      </div>
      <div className={styles.dateReceivedParent}>
        <div className={styles.unfunded}>Date Received</div>
        <img className={styles.groupChild} alt=""src="/capitalimages/polygon-7.svg" />
      </div>
      <img
        className={styles.capitalCallDetailSavedChild3}
        alt=""
       src="/capitalimages/polygon-7.svg"
  />*/}
      <div className={styles.rectangleDiv} />
      <div className={styles.capitalCallDetailSavedChild4} />
      <div className={styles.capitalCallDetailSavedChild5} />
      <div className={styles.capitalCallDetailSavedChild6} />
      <div className={styles.capitalCallDetailSavedChild7} />
      <div className={styles.capitalCallDetailSavedChild8} />
      <div className={styles.capitalCallDetailSavedChild9} />
      <div className={styles.targetRaise}>Target Raise</div>
      <div className={styles.totalCommittedCapital}>
        Total Committed capital​
      </div>
      <div className={styles.totalCapitalCalled}>Total Capital Called</div>
      <div className={styles.totalCapitalRemaining}>
        Total Capital Remaining
      </div>
      <div className={styles.investors1}>Investors</div>
      <div className={styles.signed3}>Signed</div>
      <div className={styles.wired}>Wired</div>
      <b className={styles.b}>{ccdata?.ccTargetRaise}</b>
      <b className={styles.b1}>{ccdata?.ccTotalCommitedCapital}</b>
      <b className={styles.b2}>{ccdata?.ccTotalCapitalCalled}</b>
      <b className={styles.b3}>{ccdata?.ccTotalCapitalRemaining}</b>
      <b className={styles.b4}>{noOfInvestorsAllocated}</b>
      <b className={styles.b5}>{mode === 'save' ? selectedIds: noOfSignedInvestors   }</b>
      <b className={styles.b6}>{mode === 'save' ? selectedIds: noOfWiredInvestors }</b>
      <div className={styles.capitalCallActivity}>Capital Call Activity</div>
     { /*<button className={styles.editSaveButton} >
        <div className={styles.editSaveButtonChild} />
        <div className={styles.edit}>
          {EditbuttonClick ? ("Edit") : ("Save")}
          </div>
        <img
          className={styles.editFill0Wght400Grad0Opsz4Icon}
          alt=""
         src={EditbuttonClick ? ("/capitalimages/edit-fill0-wght400-grad0-opsz48-1.svg") : 
                                ("/capitalimages/save-fill0-wght400-grad0-opsz48.svg")}
        />
</button>*/}
 <button className={styles.editSaveButton} onClick={handleEditClick}>
        <div className={styles.editSaveButtonChild} >
           <Button variant="outlined" className="btn-primary" >
            <EditIcon/>
           Edit 
           </Button>
           </div>     
        {/* <img
          className={styles.editFill0Wght400Grad0Opsz4Icon}
          alt=""
          src="/capitalimages/edit-fill0-wght400-grad0-opsz48-1.svg"
        /> */}
        
      </button>

      {mode === 'save' && (
        <div>
          <button className={styles.editSaveButton} onClick={handleSaveClick}>
            <div className={styles.editSaveButtonChild}>
            <Button variant="outlined" className="btn-primary" >
              
           Save 
           </Button>
           </div>
            {/* <img
              className={styles.editFill0Wght400Grad0Opsz4Icon}
              alt=""
              src="/capitalimages/save-fill0-wght400-grad0-opsz48.svg"
            /> */}
          </button>
          </div>
           )}
      {
        mode === 'edit' ?  (
        <div className={styles.SavedComponent}>
          <div className={styles.label}>
            <b className={styles.labelcontent}>Active</b>
          </div>
          <div className={styles.currency}>
          currency
          ​</div>
          <div className={styles.purpose}>Purpose</div>
          <div className={styles.firstInvestmentDate}>First investment date;​</div>
          <div className={styles.capitalCallNumberid}>Capital call number/ID​</div>
          <div className={styles.firstClosingDate}>First closing date;​</div>
          <b className={styles.usd}>{ccdata?.currency}</b>
          <b className={styles.investmentManagementFees}>
            {ccdata?.ccPurpose}
          </b>
          <b className={styles.jan2023}>{ccdata?.ccInvestmentDate}</b>
          <b className={styles.cc000867Db009}>{ccdata?.ccNumber}</b>
          <b className={styles.jun2023}>{ccdata?.ccFirstClosingDate}</b>
          <div className={styles.capitalCallDetailSavedChild10} />
          {/*<div className={styles.capitalCallDetailSavedChild11} />
          <div className={styles.capitalCallDetailSavedChild12} />
          <div className={styles.capitalCallDetailSavedChild13} />
          <div className={styles.capitalCallDetailSavedChild14} />
          <div className={styles.capitalCallDetailSavedChild15} />
          <div className={styles.capitalCallDetailSavedChild16} />*/}
          <div className={styles.capitalCallDetailSavedChild17} />
          <div className={styles.capitalCallDetailSavedChild18} />
          <div className={styles.capitalCallDetailSavedChild19} />
          <div className={styles.capitalCallDetailSavedChild20} />
          <div className={styles.capitalCallDetailSavedChild21} />
          </div>) : 
          (
            <div>
            <div className={styles.EditComponent} >
            <img className={styles.usdEC1} alt="" src="/capitalimages/calendar-outline.svg"  onClick={handleCalendarClick} />
            <div className={styles.labelEC} >
            <div className={styles.capitalCallDateTopEC} >Capital call date​</div>
            <img className={styles.path297IconEC} alt="" src="/capitalimages/path-297.svg"  />   
            <div className={styles.usdEC}>
           
            {showCalendar && (
              <div className={styles.calendarWrapper}>
          <Calendar
            onChange={handleDateChange}
            value={date}
            className={styles.calendar}
          />
           </div>
        )}

{date && (
          <div> {date.toDateString()}</div>
        )}
               </div>
          </div>
          <div className={styles.label1EC}>
            <div className={styles.capitalCallDateTopEC}>Purpose of Call</div>
            <img className={styles.path297IconEC} alt="" src="/capitalimages/path-297.svg" />
            <div className={styles.usdEC}><input
                    type="text"
                    className="border-0 flex-grow-1"
                    name="PurposeOfCall"
                    value={formData33.PurposeOfCall}
                    onChange={handleChange33}
                    placeholder="PurposeOfCall"
                  /></div>
          </div>
          <div className={styles.label2EC}>
            <div className={styles.capitalCallDateTopEC}>Capital call number/ID​</div>
            <img className={styles.path297IconEC} alt="" src="/capitalimages/path-297.svg" />
            <div className={styles.usdEC}><input
                    type="text"
                    className="border-0 flex-grow-1"
                    name="CapitalCallNumber"
                    value={formData33.CapitalCallNumber}
                    onChange={handleChange33}
                    placeholder="CapitalCallNumber"
                  /></div>
          </div>
          <div className={styles.label3EC}>
            <div className={styles.capitalCallDateEC}>Payment Due Date</div>
            <img className={styles.path297IconEC} alt="" src="/capitalimages/path-297.svg" />
            <div className={styles.usdEC}>—</div>
          </div>
          <div className={styles.label4EC}>
          
          <div className={styles.capitalCallDateEC}>
        Currency
      </div>
      <Autocomplete
      value={values}
      
      onChange={(event, newValue) => {
        setValues(newValue);
      }}
      options={options}
      getOptionLabel={(option) => option}
      renderInput={(params) => <TextField {...params} label="" variant="standard" style={{ border: 'none' , bottom:-25 }} />}
    />
            <div className={styles.usdEC}>
          
                   {selectedCurrency}
                  
       </div>
          </div>
          <div className={styles.label5EC}>
            <div
              className={styles.capitalCallDateEC}
            >{`Status of the capital call `}</div>
            <img className={styles.path297IconEC} alt="" src="/capitalimages/path-297.svg" />
            <div className={styles.usdEC}><input
                    type="text"
                    className="border-0 flex-grow-1"
                    name="StatusOfTheCapitalCall"
                    value={formData33.StatusOfTheCapitalCall}
                    onChange={handleChange33}
                    placeholder="StatusOfTheCapitalCall"
                  /></div>
          </div>
        </div>
        <div className={styles.labelDraftButton}>
          <b className={styles.labelcontentDraftButton}>Draft</b>
        </div>
        </div>
          )
      }
      <div className={styles.deleteParent}>
        <div className={styles.delete}>Delete</div>
        <img
          className={styles.deleteFill0Wght400Grad0OpsIcon}
          alt=""
         src="/capitalimages/delete-fill0-wght400-grad0-opsz48.svg"
        />
      </div>
      <div className={styles.previousCapitalCalls1}>Previous Capital Calls</div>
     
      <div className={styles.previousCapitalCalls}>
             <AIPDataGrid onRowsSelectionHandler={()=>{}}  className={styles.previousCapitalCalls} columns={jsonData.PreviousColumns} rows={prevccgridrows}/>
          </div>
         
     {/* <div className={styles.rectangleParent}>
        <div className={styles.groupChild7} />
        <div className={styles.jun2022}>Jun. 2022</div>
        <b className={styles.b7}>$200,000,000.00</b>
        <b className={styles.closed}>Closed</b>
        <b className={styles.b8}>$40,000,000.00</b>
        <b className={styles.b9}>$40,000,000.00</b>
        <b className={styles.b10}>$40,000,000.00</b>
        <b className={styles.b11}>20</b>
      </div>
      <div className={styles.rectangleGroup}>
        <div className={styles.groupChild7} />
        <div className={styles.jun20221}>Jun. 2022</div>
        <b className={styles.b12}>$200,000,000.00</b>
        <b className={styles.closed1}>Closed</b>
        <b className={styles.b13}>$40,000,000.00</b>
        <b className={styles.b14}>$40,000,000.00</b>
        <b className={styles.b15}>$40,000,000.00</b>
        <b className={styles.b16}>20</b>
      </div>
      <div className={styles.rectangleContainer}>
        <div className={styles.groupChild7} />
        <div className={styles.jun20222}>Jun. 2022</div>
        <b className={styles.b17}>$200,000,000.00</b>
        <b className={styles.closed2}>Closed</b>
        <b className={styles.b18}>$40,000,000.00</b>
        <b className={styles.b19}>$40,000,000.00</b>
        <b className={styles.b20}>$40,000,000.00</b>
        <b className={styles.b21}>20</b>
      </div>
      <div className={styles.groupDiv}>
        <div className={styles.groupChild7} />
        <div className={styles.jun20223}>Jun. 2022</div>
        <b className={styles.b22}>$200,000,000.00</b>
        <b className={styles.closed3}>Closed</b>
        <b className={styles.b23}>$40,000,000.00</b>
        <b className={styles.b24}>$40,000,000.00</b>
        <b className={styles.b25}>$40,000,000.00</b>
        <b className={styles.b26}>20</b>
      </div>
     
      <div className={styles.subscribedcommittedParent}>
        <div className={styles.subscribedcommitted}>Subscribed/Committed</div>
        <div className={styles.totalContributed}>Total Contributed</div>
        <div className={styles.totalReceived}>Total Received</div>
        <div className={styles.ofInvestors}># of investors</div>
        <div className={styles.targetRaise1}>Target Raise</div>
        <div className={styles.status1}>Status</div>
        <div className={styles.date}>DATE</div>
        <img className={styles.groupChild11} alt=""src="/capitalimages/polygon-7.svg" />
        <img className={styles.groupChild12} alt=""src="/capitalimages/polygon-7.svg" />
        <img className={styles.groupChild13} alt=""src="/capitalimages/polygon-7.svg" />
        <img className={styles.groupChild14} alt=""src="/capitalimages/polygon-7.svg" />
        <img className={styles.groupChild15} alt=""src="/capitalimages/polygon-7.svg" />
        <img className={styles.groupChild16} alt=""src="/capitalimages/polygon-7.svg" />
        <img className={styles.groupChild17} alt=""src="/capitalimages/polygon-7.svg" />
    </div> */}
       
        <div>
      {mode !== 'save' && (
        <div className={styles.component21237}>
          <div className={styles.buttonNormal} onClick={handleAddCallClick}>
            <div className={styles.buttonNormalChild} />
            <div className={styles.executeCall}>Execute Call</div>
            <img
              className={styles.iconMaterialSend}
              alt=""
              src="/capitalimages/icon-materialsend.svg"
            />
          </div>
        </div>
      )}
      </div>
      
      
      <div
        className={styles.buttonNormal1}
        onClick={mode === 'edit' ? handleAddCallClick : handleSaveClick}>
        <div className={styles.buttonNormalItem} />
        <div className={styles.addCall}>Add Call</div>
        <img
          className={styles.iconMaterialAddCircleOutli}
          alt=""
         src="/capitalimages/icon-materialaddcircleoutline.svg"
         
        />
      </div>
     {/* <NavigationBar  isExpanded={isExpanded} setExpendState={setExpendState}/> */}
     <CircleIcon/>
     {/*Image css */}
     {/* <div  className={styles.maskGroup35ccd}>
      <img className={styles.maskGroup356ccd} alt="" src="/mask-group-36@2x1.png" />
      <div className={styles.consumerGoodsRetailEnhancemParentccd}>
      
        <div className={styles.consumerGoodsRetailEnhancemccd}>
            Nexus Health Equity Fund
        </div> */}
       {/*  <div
          className={styles.marketplacePrivateccd}
        >{`Marketplace > Private Equity Funds`}</div>
        <div className={styles.labelccd}>
          <b className={styles.labelcontentccd}>New</b>
        </div>*/}
        {/* <img
        className={styles.backArrowButton}
        alt=""
        src="/back-arrow-button.svg"
       onClick={onExecutivesummaryClick}
      /> 
       <div className={styles.maskGroup38Container}>
            <img
              className={styles.maskGroup381}
              alt=""
              src="/capitalimages/mask-group-381@2x.png"
            />
      </div>
      </div>
     
      </div> */}
      
    </div>
  );
};

export default CapitalCallDetailSaved;