import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Grid } from '@mui/material';
import { useState } from 'react';
import ALTTextField from '../../common/input-fields/ALTTextField';
import ActionButton from '../../common/ActionButton/ActionButton';
import Text from '../../common/Text/Text';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
//#This popup displays when fund manager or compliance want to reject the fund subscription
const SubscriptionRejectionPopup = (props) => {

    //#region props
    const { open, onClose, isApproved, updateSubscriptionStatus, rejectBtnLoading } = props;

    //#region variables
    const [reason, setReason] = useState()

    //#region change events
    const handleChange = (name, value) => {
        setReason(value);
    }

    //#region click events
    const onConfirmClick = () => {
        updateSubscriptionStatus(isApproved, reason)
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>
                <Text label='Confirmation' />
            </DialogTitle>
            <DialogContent>
                <p>Kindly provide the reason for rejection. This will be sent to the investor in the mail informing about the rejection of the subscription request.</p>
                <div className=''>
                    <ALTTextField
                        name="reason"
                        value={reason}
                        multiline={true}
                        rows={4}
                        onChange={(name, value) => handleChange(name, value)} />
                </div>
            </DialogContent>
            <DialogActions>
                <div className='space-between'>
                    <div>
                        <ActionButton className="btn-primary" variant='outlined' icon={<CancelIcon />} onClick={onClose} label='Cancel' />
                    </div>
                    <div className='margin-left-10'>
                        <ActionButton
                            label='CONFIRM'
                            loading={rejectBtnLoading}
                            icon={<CheckCircleIcon />}
                            className="btn-primary"
                            variant="contained"
                            onClick={onConfirmClick} />
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    )
}
export default SubscriptionRejectionPopup;