export const SpousehasIIAaccdropdown = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
    // Add more options as needed
  ];

export const isemployesdropdown = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
    // Add more options as needed
  ];

export const employmentstatusdropdown = [
    { value: 'Employed', label: 'Employed' },
    { value: 'Retired', label: 'Retired' },
    { value: 'Student', label: 'Student' },
    { value: 'SelfEmployed', label: 'SelfEmployed' },
    // Add more options as needed
  ];

export  const dependentsOptions = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    // Add more options as needed
  ];

export const addditionalDropDownOptions = {
    OTHER : 'other'
}

export const InvestorTypesEnum = {
  INDIVIDUAL_INVESTOR : 'Individual Investor'
}

export const questionsTypesEnum = {
    KYC: 'KYC',
    AML: 'AML',
    SUBSCRIPTION: 'Subscription',
    INVESTOR_PROFILE: 'InvestorProfile'
}