export function formatAsUSACurrency(value) {
    const unexpected = "$0";
    if(value === null || value === undefined || value === "-"){
        return unexpected;
    }
    else {
        const stringValue = String(value);
        const parts = stringValue.split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        const formattedValue = parts.length === 2 ? `$${parts.join('.')}` : `$${parts[0]}`;
        return formattedValue;
    }
}

