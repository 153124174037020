import { Dialog, DialogTitle, DialogActions, Button, DialogContent } from '@mui/material';
import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ActionButton from '../../common/ActionButton/ActionButton';
import CancelIcon from '@mui/icons-material/Cancel';

const ValidationPopup = (props) => {
    const { open, onAccept ,onClose,data } = props;

    const handleClick = (articleURl) => {
        
        const url = articleURl.startsWith('http://') || articleURl.startsWith('https://') 
        ? articleURl
        : `http://${articleURl}`;

        const screenWidth = window.screen.availWidth;
        const screenHeight = window.screen.availHeight;

        const windowWidth = 1000; 
        const windowHeight = 600; 

        const left = (screenWidth - windowWidth) / 2;
        const top = (screenHeight - windowHeight) / 2;

        const newWindow = window.open(
          url,
          "PopupWindow",
          `width=${windowWidth},height=${windowHeight},left=${left},top=${top}`
        );

        if (newWindow) {
          newWindow.opener = null;
        }
        
      }

      const dialogPaperStyles = {
           width : '500px',
      };

    return (
        <>
                    {
                        data?.upload === 'link' ?
                        <Dialog
                            open={open}
                            onClose={onClose}
                            PaperProps={{ style: dialogPaperStyles }}
                             >
                                <div>
                                    <DialogTitle id="alert-dialog-title" sx={{marginLeft: '5px'}}>Validation Check</DialogTitle>
                                   
                                        <DialogContent sx={{marginTop: '14px',marginLeft: '20px'}}>
                                        {
                                            data?.upload === 'link' ?
                                            <Button onClick={() => handleClick(data.articleurl)}>Open URL Here</Button> :
                                              null
                                        }
                                        </DialogContent>
                    
                                    <DialogActions sx={{ padding: '8px 20px !important' }}>
                                    <Button  
                                      component="label"
                                      variant="contained"
                                      onClick={() => onAccept(data)} autoFocus>
                                                        Accept
                                        </Button>
                                        <Button 
                                          component="label"
                                          variant="contained"
                                          style={{ backgroundColor: 'white', color: 'gray' }}
                                          onClick={onClose} autoFocus>
                                            Cancel
                                        </Button>
                                    </DialogActions>
                                </div>
                          </Dialog> :
                          
                        data?.upload === 'file' && data?.addlink !== ''?
                        <Dialog
                            open={open}
                            onClose={onClose}
                            fullWidth={true}
                            maxWidth="md" >
                                <div>
                                    <DialogTitle id="alert-dialog-title">Validation Check</DialogTitle>
                                    <DialogContent>
                                        <DialogContent>
                                        {
                                            data?.upload === 'file' ?
                                            <iframe
                                                id="iframeId"
                                                src={data?.addlink}
                                                className="custom-iframe"
                                                style={{
                                                    height: '430px',
                                                }}
                                                ></iframe>  :
                                            null
                                        }
                                        </DialogContent>
                    
                                        </DialogContent>
                                    <DialogActions>
                                        <ActionButton 
                                            component="label"
                                            variant="contained"
                                            icon = {<CheckCircleIcon/>}
                                            onClick={() => onAccept(data)} autoFocus>
                                            Accept
                                        </ActionButton>
                                        <ActionButton 
                                          component="label"
                                          variant="contained"
                                          icon={<CancelIcon/>}
                                          style={{ backgroundColor: 'white', color: 'gray' }}
                                          onClick={onClose} autoFocus>
                                            Cancel
                                        </ActionButton>
                                    </DialogActions>
                                </div>
                       </Dialog> :  null
                    } 
                <ToastContainer />
        </>
       
    );
}

export default ValidationPopup;
