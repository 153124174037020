export const Gridaligncenter = {
    background: 'white',
    height: '60px',
    border: '1px solid gray',
    display: 'flex',           
    alignItems: 'center',       
    justifyContent: 'center', 
    cursor: 'pointer',
    borderRadius: '4px'
}

export const Gridaligncenterdisabled = {
    background: 'gray',
    height: '60px',
    border: '1px solid gray',
    display: 'flex',           
    alignItems: 'center',       
    justifyContent: 'center', 
    cursor: 'pointer',
    borderRadius: '4px'
}

