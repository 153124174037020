import React from "react";
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../AppAuthentication/authConfig";

const SiteLoginHeader = ({isSearchEnable}) => {
	const msalInstance = new PublicClientApplication(msalConfig);
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const manageaccountclick = () => { navigate("/user-profile"); };

  const handleMenuToggle = () => {
    setShowMenu(!showMenu);
  };

  const handleLogout = () => {
    // Perform logout functionality
    secureLocalStorage.clear();
    msalInstance.logoutRedirect({ postLogoutRedirectUri: "/?login_page=true" });
  };

  const handleManageAccounts = () => {
    // Perform manage accounts functionality
    setShowMenu(false);
  };

  const fullName = `${secureLocalStorage.getItem('firstName')} ${secureLocalStorage.getItem('lastName')}`;
  const emailAddress = secureLocalStorage.getItem('userEmail');

  return (
    <div className={"topnavbarbgParent"}>
      <img className="groupChild" alt="" src="/bluehublab-logo.png" />
      <div className="groupParent1">
        <div className="mediumCardBg">
          <div className="mediumCardBg">
            <div className="alternativeInvestmentPlatforContainer">
              Alternative Investment Platform
            </div>
          </div>
        </div>
      </div>
      {/* {isSearchEnable === "true" && ( */}
     {/* <div className='searchParent'>
        <input type="text" placeholder="Search" className='searchInput' />
        <img
          className='iconactionsearch24px'
          alt=""
          src="/iconactionsearch-24px.svg"
        />
      </div>
      */}
      <div>
        <div className="navbarbgIcon"></div>
        <img className="userMenuIcon" alt="" src="/user-menu.svg" onClick={handleMenuToggle} />
        {showMenu && (
          <div className="whiteBox">
            <div className="menuOption name" onClick={handleManageAccounts}>{fullName}</div>
            <p>{emailAddress}</p>
            <hr className="line" />
            <div className="menuOption" onClick={manageaccountclick}>
              <img
                className="manageAccountsIcon"
                alt=""
                src="https://static.thenounproject.com/png/1635595-200.png"
              />
              MANAGE ACCOUNT</div> <div></div>
            <div className="menuOption" onClick={handleLogout}>
              <img
                className="LogoutIcon"
                alt=""
                src="https://cdn-icons-png.flaticon.com/512/1286/1286853.png"
              />
              LOG OUT
            </div>
          </div>
        )}
      </div>

    </div>
  );
};

export default SiteLoginHeader;
