import React from "react";

const Pipelinecomments = () => {
    return (
        <>
        <div>
           All yml files are added to develop branch Latest - 05-Sep-2024 Latest - 1
        </div>
        </>
    );
}

export default Pipelinecomments