
import React, { useState, useEffect } from "react";
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { EventType, InteractionType } from "@azure/msal-browser";
import { loginRequest, b2cPolicies } from "./authConfig";
import { Tenant } from "./tenant.jsx";
import { Button } from "react-bootstrap";
import secureLocalStorage from "react-secure-storage";

const MainContent = () => {
  const { instance } = useMsal();
  const [redeemToken, setRedeemToken ] = useState(null);
  const [loginError, setLoginError ] = useState(null);
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  useEffect(() => {
      secureLocalStorage.setItem("userLoggedIn", false);
      const queryParams = new URLSearchParams(window.location.search);
      const id = queryParams.get('id_token');
      const domain_hint = queryParams.get('domain_hint');
      const tenant = queryParams.get('tenant');
      const login_hint = queryParams.get('login_hint'); 
      setRedirectToLogin(queryParams.get('login_page') === 'true'); 

    //   // This Code is to navigation to login and forgot password based on functionality      
      console.log("id_token:" + id);
      setRedeemToken(id);

      const callbackId = instance.addEventCallback((event) => {
          if (event.eventType === EventType.LOGIN_FAILURE) {
              console.log("sign in failed:" + event.error.errorMessage);
              if (event.error && event.error.errorMessage.indexOf("AADB2C90118") > -1) {
                  if (event.interactionType === InteractionType.Redirect) {
                      instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
                  } else if (event.interactionType === InteractionType.Popup) {
                      instance.loginPopup(b2cPolicies.authorities.forgotPassword)
                          .catch(e => {
                              return;
                          });
                  }
              } else {
                  setLoginError("No tenants!")
                  return instance.logout();
              }
          }

          if (event.eventType === EventType.LOGIN_SUCCESS) {
            secureLocalStorage.setItem("userLoggedIn", true);
              if (event?.payload) {
                  /**
                   * We need to reject id tokens that were not issued with the default sign-in policy.
                   * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
                   * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
                   */
                  if (event.payload.idTokenClaims["acr"] === b2cPolicies.names.forgotPassword) {
                      window.alert("Password has been reset successfully. \nPlease sign-in with your new password");
                      return instance.logout();
                  }
              }
          }
      });
      if(domain_hint || login_hint || tenant) {
          instance.loginRedirect({
              scopes: loginRequest.scopes, 
              loginHint: login_hint,
              extraQueryParameters : {
                  domain_hint: domain_hint,
                  tenant: tenant
              }})          
      }

      return () => {
          if (callbackId) {
              instance.removeEventCallback(callbackId);
          }
      };
  }, []);

  return (
      <div className="App">
          <AuthenticatedTemplate>
              <Tenant />
              {/* <Button variant="warning" className="ml-auto" onClick={() => {
                        secureLocalStorage.clear();
                        instance.logoutRedirect({ postLogoutRedirectUri: "/" })}}>Log out</Button>    */}
          </AuthenticatedTemplate>

          <UnauthenticatedTemplate>
                    {redirectToLogin  && 
                        instance.loginRedirect({
                            scopes: loginRequest.scopes
                            }).catch((error) => console.log(error))
                     }   
                        {!redirectToLogin &&  <Button variant="outlined" className="hlogin" onClick={() => 
                            instance.loginRedirect({
                                scopes: loginRequest.scopes
                                }).catch((error) => console.log(error))
                            }>Login</Button>  }   
                
          </UnauthenticatedTemplate>
      </div>
  );
};

export default function AuthenticationPage({msalInstance}) {
  return (
      <MsalProvider instance={msalInstance}>
          <MainContent />
      </MsalProvider>
  );
}