import React from 'react'
import OBChangePassword from '../FirmRegistrartion/Components/OBChangePassword'
import { useState } from 'react'
import { updateChangePassword } from '../../services/RegistrationProfilingService';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { updateInvestorRegistartionStatus } from '../../services/RegistrationProfilingService';
import { Dialog, DialogTitle, DialogActions,Button, DialogContent, Grid } from '@mui/material';
import { msalConfig } from '../../../AppAuthentication/authConfig';
import { PublicClientApplication } from '@azure/msal-browser';
import secureLocalStorage from 'react-secure-storage';

const BulkUploadInvite = () => {

   //#region Naviagtions
    const navigate = useNavigate();
    const msalInstance = new PublicClientApplication(msalConfig);

  //#region initialData
    const InitialData = {
        newpassword: '',
        confirmpassword: ''
    }

  //#region useState Variables
  const [Passwords, setPasswords] = useState(InitialData);
  const [btnloading, setLoading] = useState(false);
  const [skipbtnloading, setSkipLoading] = useState(false);
  const [openPopup, setOpenPopUp] = useState(false);

  //#region click events
  const Logout = () => {
    setOpenPopUp(false);
    secureLocalStorage.clear();
    msalInstance.logoutRedirect({ postLogoutRedirectUri: "/?login_page=true" });
};

  //#region update calls
  const handleSubmit = async (skip) => {
    setLoading(true);
    if(skip){
        const data = await updateInvestorRegistartionStatus(true);
        navigate('/user-profile');
    }
    else{
        setLoading(true);
        const data = await updateChangePassword(Passwords.confirmpassword);
        if(data?.responseCode == 200 || data?.responseCode == 204){
            setLoading(false);
            const response = await updateInvestorRegistartionStatus(true);
            setOpenPopUp(true);
        }
        else{
            setLoading(false);
            toast.error("Error Updating Password,Please try again",
                  { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        } 
    }
  }

  //#region render popup
  const reloginPopup = () => {
    return (
        <Dialog open>
            <DialogTitle>

            </DialogTitle>
            <DialogContent>
                Password has been changed please relogin
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {Logout()}}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )
}

  return (
    <div>
        <OBChangePassword 
               Passwords={Passwords} 
               setPasswords={setPasswords}
               handleSubmit={handleSubmit} 
               skipbtnloading={skipbtnloading} 
               btnloading={btnloading} 
               fromBulkupload={true}/>
        <ToastContainer /> 
        {openPopup && reloginPopup()} 
    </div>
  )
}

export default BulkUploadInvite