import { FunctionComponent, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import styles from "./FundDetailServices1.module.css";
import MiniDrawer from "../../common/navigation-bar/NavBarGrid";
import DashboardHeader from "../DashboardHeader";
import FundBanner from "../../Servicing/FundBanner";
import ServicingAccordion from "../../Servicing/FundServicing";
import FundDetailServices from "./FundDetailsServices";
import { RolesAndPermissions, FeatureAccess } from "../../../utils/enum";
import { checkFeaturePermissions } from "../../../utils/common";
import CircleIcon from "../../chatbot/Icon";
import secureLocalStorage from "react-secure-storage";
import { Grid, Typography } from "@mui/material";
import { width } from "@mui/system";
import { getFundbyId } from "../../investor-fund-subscription/services/services";
import { toast } from "react-toastify";

const FundDetailServices1 = () => {
  const navigate = useNavigate();
  const [fundDetails, setFundDetails] = useState(null)

  const fundData = async () => {
    const data = await getFundbyId(secureLocalStorage.getItem('FundId'))
    if (data.responseCode === 200) {
      setFundDetails(data.responseData)
    } else {
      toast.warning(data.responseData,
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
  }
  useEffect(() => {
    fundData()
  }, [])


  return (
    <div className="services-accord">
      <h4> Services </h4>
      <>
        {
          fundDetails ? <div className={styles.fundStatusWrapper}>
            <ServicingAccordion fundDetails={fundDetails} />
            <CircleIcon />
          </div> : 'Loading...'
        }
      </>

    </div>
  );
};

export default FundDetailServices1;
