import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Google from '../../third-party/Google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import CircleIcon from "../../chatbot/Icon";
import { getUser, getUserProfileData } from "../services/RegistrationProfilingService"
import secureLocalStorage from "react-secure-storage";
import { AppDashboards, AppRoles } from "../../../utils/enum";
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import AppleIcon from '@mui/icons-material/Apple';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [errormessage, setErrormessage] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const messengerRoom = "ALT360"
  const [messengerUsersList,setmessengerUsersList] = useState([])
  const [connection, setConnection] = useState();
  const [messages, setMessages] = useState([]);

  const joinRoom = async (user, room) => {
    // debugger
    try {
      // debugger
      const connection = new HubConnectionBuilder()
        .withUrl("https://instantmessaging.azurewebsites.net/chat")//Please provide local url while development
        .configureLogging(LogLevel.Information)
        .build();

      connection.on("ReceiveMessage", (user, message) => {
        setMessages(messages => [...messages, { user, message }]);
        secureLocalStorage.setItem('messengerMessages',(messages => [...messages, { user, message }]))
      });

      connection.on("UsersInRoom", (users) => {
        const uniqueUsers = Array.from(new Set(users));
        setmessengerUsersList(uniqueUsers);
        secureLocalStorage.setItem("messengerUsersList",uniqueUsers)
    });

      connection.onclose(e => {
        setConnection();
        setMessages([]);
        setmessengerUsersList([]);
      });

      await connection.start();
      await connection.invoke("JoinRoom", { user, room });
      setConnection(connection);
      secureLocalStorage.setItem('connection',connection)
    } catch (e) {
      console.log(e);
    }
  }

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };



  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };


  const getUserProfile = async (userData) => {
    const userProfile = await getUserProfileData(userData.emailAddress);
        if (userProfile.responseCode === 200) {
          
            joinRoom(secureLocalStorage.getItem("userName"), messengerRoom);
          // parse the response and update the state with the question data
          secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD
          ? ( userData.fundCount > 0 ? navigate("/Investor-Dashboard") :  navigate("/marketplace"))
            : navigate("/FundManager-Dashboard");

          window.scrollTo(0, 0);
        } else if (userProfile.responseCode === 404) {
            joinRoom(secureLocalStorage.getItem("userName"), messengerRoom);
    
          // navigate('/user-selection'); // we are selecting the user role from login only
          secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD
            ? navigate('/onboarding-process')
            : navigate("/FundManager-Dashboard");
        }
        else {
          // handle the error response
          console.error(userProfile.responseCode);
          throw new Error("Failed to fetch questions");
        }
  }

  const handleLogin = async () => {
    setEmailError("");
    setPasswordError("");
    setErrormessage("");
    if (!email) {
      setEmailError("Please enter valid Email Address");
      return;
    } else if (!/^[^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*@\w+(\.\w+)+$/.test(email)) {
      setEmailError("Please enter valid Email Address");
    } else {
      setEmailError("");
    }
    if (!password) {
      setPasswordError("Please enter Password");
    } else if (
      !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/.test(
        password
      )
    ) {
      setPasswordError(
        "Wrong Password"
      );
    } else {
      const user = await getUser(email, password, secureLocalStorage.getItem('userrole'));

      if(user.responseCode === 200){
        secureLocalStorage.setItem("userId", user.data.userId);
        secureLocalStorage.setItem("userEmail", user.data.emailAddress);
        secureLocalStorage.setItem("firstName", user.data.firstName);
        secureLocalStorage.setItem("lastName", user.data.lastName);
        getUserProfile(user.responseData);
      }
      else{
        setErrormessage(user.error.response.data); 
      }
    }
  };

  return (
    <div className="login d-flex align-items-center py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-9 col-lg-8 mx-auto">
            <div className="login-header">
              <h3 className="login-heading text-center">Welcome</h3>
              <p className="text-center sub-heading">
                Please log in to your account.
              </p>
            </div>
            <p className="text-danger text-center"> {errormessage} </p>
            <div className="login-form">
              <div className="position-relative my-3 inputGroup text-center">
                <input
                  type="email"
                  className="border-0 w-100"
                  placeholder="Email Address"
                  value={email}
                  onChange={handleEmailChange}
                />
                {emailError && (
                  <span className="text-danger">{emailError}</span>
                )}
              </div>
              <div className="position-relative my-3 inputGroup text-center">
                <div className="d-flex align-items-center">
                  <input
                    type={passwordShown ? "text" : "password"}
                    className="border-0 flex-grow-1"
                    placeholder="Password"
                    name="password"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  <i
                    id="togglePassword"
                    onClick={togglePasswordVisibility}
                  >
                    {passwordShown ? (
                      <img src="icons/eye-open.svg" alt="Eye Open" className="eye-open-icon" />
                    ) : (
                      <img src="icons/eye-close.svg" alt="Eye Closed" className="eye-closed-icon" />
                    )}
                  </i>

                </div>
                {passwordError && (
                  <span className="text-danger">{passwordError}</span>
                )}



              </div>

              <div className="d-flex w-full my-3">
                <div className="flex-fill checkbox">
                  <label>
                    <input type="checkbox" className="margin-right-10" />
                    Remember me
                  </label>
                </div>
                <div className="flex-fill text-end">
                  <Link to="/forget-password" className="forgot-pswd">
                    Forgot Password
                  </Link>

                </div>
              </div>
              <button
                className="btn w-100 my-1 shadow-none btn-login"
                onClick={(e) => {
                  e.preventDefault();
                  handleLogin();
                }}
              >
                Login
              </button>
              <div className="other-logins">
                {/* <button className="btn w-100 d-flex justify-content-between my-3 align-items-center">
                  <span className="text-decoration-none w-100"> */}
                <GoogleOAuthProvider clientId="936331901467-lkb9pkj7mk2m940v1a5fm31t3tt8tb8c.apps.googleusercontent.com" >
                  <Google />
                </GoogleOAuthProvider>
                {/* </span>
                </button> */}
                <button className="btn w-100 d-flex justify-content-between my-3 align-items-center">
                  <AppleIcon className="singupicons"/>
                  <span className="text-decoration-none w-100">
                    LOGIN WITH APPLE
                  </span>
                </button>
                <div>
                  <div>

                    <div className="text-center">Or</div>

                  </div>
                </div>




                <Link to="/create-an-account"
                  className="btn w-100 my-1 shadow-none d-flex justify-content-between my-3 align-items-center btn-login"
                >
                  {/* <LocalPostOfficeIcon className="singupicons"/> */}
                  <span className="text-decoration-none w-100">
                    Sign Up
                  </span>
                </Link>

              </div>
            </div>
            <div className="recaptcha1 mt-5 text-center">
              This site is protected by reCAPTCHA and the
              <p> Google
             <a className="privacy_TC-links" href="#"> Privacy Policy </a>
             and 
              <a className="privacy_TC-links" href="#"> Terms of Service </a>
              Apply
               </p>



            </div>

            <CircleIcon/>   
          </div>
        </div>
      </div>
    </div>








  );
};
export default Login;