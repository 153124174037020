import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./FundDetailServices2.module.css";
const FundDetailServices2 = () => {
  const navigate = useNavigate();

  const onTextLinkContainer1Click = () => {
    navigate("/quarterly-reports");
  };
  
  const onExecutivesummaryClick = () => {
    navigate("/executive-summary-reports");
  }

  const onExecutivesummary1Click = () => {
    navigate("/partners-capital-reports");
  }

  const onExecutivesummary2Click = () => {
    navigate("/balance-sheet-reports");
  }


  return (
    <div className={styles.fundDetailServices2}>
      <div

        className={styles.companyProfile3}
        
      >
       
        <div className={styles.companyProfileChild} />
        <div className={styles.companyProfileItem} />
        <div className={styles.companyProfileInner} />
        <div className={styles.companyProfileInner1} />
        <div className={styles.companyProfileInner2} />
      
        <div className={styles.textLink}  onClick = { () =>{onTextLinkContainer1Click()}}>
          <div className={styles.executiveSummary}>
            Quarterly Report(Q1 2023)-NEW
          </div>
        </div>
        <div className={styles.textLink1}  onClick = { () =>{onExecutivesummaryClick()}}>
          <div className={styles.executiveSummary}>
            Executive Summary-(2022)-NEW
          </div>
        </div>
        <div className={styles.textLink2} onClick = { () =>{onExecutivesummary1Click()}} >
          <div className={styles.executiveSummary}>
          Partners Capital Accounts Statement
          </div>
        </div>
        <div className={styles.textLink3} onClick = { () =>{onExecutivesummary2Click()}} >
          <div className={styles.executiveSummary}>
          Balance Sheet
          </div>
        </div>
        
        <img
          className={styles.iconMaterialFileDownload1}
          alt=""
          src="/capitalimages/icon-materialfiledownload1.svg"
        />
         <img
          className={styles.iconMaterialFileDownload}
          alt=""
          src="/capitalimages/icon-materialfiledownload1.svg"
        />
         <img
          className={styles.iconMaterialFileDownload2}
          alt=""
          src="/capitalimages/icon-materialfiledownload1.svg"
        />
         <img
          className={styles.iconMaterialFileDownload3}
          alt=""
          src="/capitalimages/icon-materialfiledownload1.svg"
        />
      </div>
      
     
    </div>
  );
};

export default FundDetailServices2;