import React from 'react'
import { useState, useEffect } from 'react'
import moment from 'moment'
import secureLocalStorage from 'react-secure-storage'
import { Grid, Button } from '@mui/material'
import SendIcon from '@mui/icons-material/Send';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import { GetFundDistributionsForFund } from '../Services/CapitalCall';
import StackView from '../../common/StackView/StackView'
import AddingDistributions from './AddingDistributions'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { GetDistributionsByID } from '../Services/CapitalCall'
import { useCallback } from 'react'
import { useNavigate } from "react-router-dom";
import { HeaderTemplates, PageHeader } from '../../common/PageHeader/PageHeader'
import { getrootBackgroundColorConfig } from '../../../state/slices/colorConfigSlice'
import { useSelector } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material'
import Text from '../../common/Text/Text'
import AIPDataGrid from '../../common/DataGrid/AIPDataGrid'
import { checkFeaturePermissions } from '../../../utils/common'
import { RolesAndPermissions } from '../../../utils/enum'
import { FeatureAccess } from '../../../utils/enum'
import GridCurrencyFormater from '../../common/CurrencyFormater/GridCurrencyFormater'



function DistributionActivity() {
  const [PreviousDistributionsForFund, SetPreviousDistributionsForFund] = useState(null);
  const navigate = useNavigate();
  const [AddingDistribution, SetAddingDistributions] = useState(false)
  const [isEditing, setIsEditing] = useState(false);
  const [rowDistributionNumber, setrowDistributionNumber] = useState(0);
  const [dataRetrived, setdataRetrived] = useState(false)
  const [selectedIds, setSelectedIds] = useState([]);
  const [distributionAmount, setDistributionAmount] = useState('');
  const [distributionDetails, setdistributionDetails] = useState()
  const [navBeforeDistribution, setnavBeforeDistribution] = useState('');
  const [formData, setFormData] = useState(null);
  const [showSave, setshowSave] = useState(false);
  const [showEdit, setshowEdit] = useState(false);
  const [executedStatus, setexecutedStatus] = useState(false);
  const [distributionSaved, setDistributionSaved] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [DeleteClickHappened, setDeleteClickHappened] = useState(false)
  const [showPreviousColumns, setShowPreviousColumns] = useState(true);
  const [showDefault, setShowDefault] = useState(false);


  const handleDelete = () => {
    setShowPreviousColumns(true);

  }
  const handleEditClick = () => {
    setIsEditing(true);
    setshowSave(true);
    setshowEdit(false);
    setDistributionSaved(true);
  };
  const OnExecuteDistributionsClick = () => {
    handleExecuteDialogOpen();
  }
  const onPopupConfirmClick = () => {
    setexecutedStatus(true)
    onEditSaveClick(true);
    setIsDialogOpen(false);
    SetAddingDistributions(false)
  }

  const handleExecuteDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleExecuteDialogClose = () => {
    setIsDialogOpen(false);
    // navigate("/marketplace/fund-detail-services-1");
    SetAddingDistributions(false);
  };
  const handleSaveClick = () => {
     
    const iserrors = MandatoryFieldErrors();
    if (iserrors.length > 0) {
      toast.error("Please Fill the Mandatory fields", {
        position: toast.POSITION.TOP_RIGHT,
         theme: 'colored',
        style: {
          marginTop: '100px',
        },
      });

     }else if (UpdatedDistributionrows.filter(u => u.fdOwnership > 0).length === 0) {
      toast.error("Please Select the Investors", {
        position: toast.POSITION.TOP_RIGHT,
         theme: 'colored',
        style: {
          marginTop: '100px',
        },
      });

     }
     else {
    onEditSaveClick()
    fetchData()
    setshowSave(false);
    setshowEdit(true);
     setDistributionSaved(true);
    //3 
     }


  };
  const BackArrowClick = useCallback(() => {
    navigate("/marketplace/fund-detail-services-1");

  }, [navigate]);

  const handleDistributionChange = (newValue) => {
    const numericValue = newValue.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    // Update the state or handle the numeric value as needed
    setDistributionAmount(numericValue);
  };

 

  const handleNavDistributionChange = (newValue) => {
    const numericValue = newValue.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    setnavBeforeDistribution(numericValue);
  };
  const onDraftStatusClick = (params) => {

    setrowDistributionNumber(params.row.id)
    AddDistributionsClick(params.row.id)
    if (params.row.status === 'Draft' || params.row.status === 'Active') {
      SetAddingDistributions(true);
    }
  };

  const AddDistributionsClick = async (rowDistributionNumber) => {

    SetAddingDistributions(true);
    if (onDraftStatusClick) {
      const data = await GetDistributionsByID(secureLocalStorage.getItem("FundId"), rowDistributionNumber);
      //console.log('json',data);
      if (data.responseCode === 200) {

        setdistributionDetails(data)
        setDistributionAmount(data?.distributionAmount)
        setnavBeforeDistribution(data?.nav)
        setdataRetrived(true)
        const newData = {
          'distributionDate': data?.fdDate,
          "distributionNumber": data.fdSerialNo,
          "currency": data?.fdCurrency,
          paymentDate: data?.fdPaymentDate,
          currency: data?.fdCurrency,
          "distributionSource": data?.fdSourceID,
          "statusoftheDistribution": data?.fdStatusID,
          "paymentMethod": data?.fdPaymentMethodID,
          'distributionType': data?.fdTypeID // === 1 ? "Return of capital" : (data?.fdTypeID === 2 ? "Realized Gains" : (data?.fdTypeID === 3 ? "Dividends" :" "))
        }
        setFormData(newData);
        setShowDefault(!showDefault);


        // setCapitalcallDetails(data);
        // setpurposeid(data.ccPurpose);
        // setTotalCapitalCalled(data?.ccTotalCapitalCalled)
        // settotalCommittedCapital(data?.committedCapital)
        // setOriginalCCInvestors(data);
        // setdataRetrived(true);
        // console.log('initialdata',data);


        // const newData = {
        //   targetRaise: data.targetRaise,
        //   capitalCallNumber:data.ccSerialNumber,
        //   currency: data.ccCurrency,
        //   purposeOfCall: data.ccPurpose,
        //   paymentDueDate: data.ccPaymentDueDate,
        //   statusOfCapitalCall: data.capitalCallStatus,
        //   CCDate: data.ccDate,
        // };


        // setshowSave(rowccnumber > 0 ? false : true);
        // setshowEdit(rowccnumber > 0 ? true : false);

        // setFormData(newData);
        // SetAddingCC(true);
        // SetPreviousCC(false);

        // setIsAddCallClick(true);
        setshowSave(rowDistributionNumber > 0 ? false : true);
        setshowEdit(rowDistributionNumber > 0 ? true : false);
      }
    }
    else {

    }

  }


  const fetchData = async () => {
    try {
      const responseData = await GetFundDistributionsForFund(secureLocalStorage.getItem("FundId"));

      if (!responseData?.error) {
        SetPreviousDistributionsForFund(responseData);
      } else {

      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const rows = PreviousDistributionsForFund ? PreviousDistributionsForFund?.map(DistributionRows => ({
    id: DistributionRows.distributionID,
    DATE: moment(DistributionRows.fdDate).format("MM-DD-YYYY"),
    STATUS: DistributionRows.distributionStatus,
    AMOUNT: DistributionRows.distributionAmount,
    TYPE: DistributionRows.distributionType === "0" ? "--" : DistributionRows.distributionType ,
    NOOFINVESTORS: DistributionRows.noOfInvestors,
  })) : [];

  const DistributionInvestorRows = distributionDetails
    ? distributionDetails.distributionInvestors.map(ccrows => ({
      fundID: ccrows.fundID,
      "distributionID": rowDistributionNumber,
      fdInvestorID: ccrows.fdInvestorID,
      id: ccrows.investorID,
      investorID: ccrows.investorID,
      investor: ccrows.investor,
      investorType: ccrows.investorType === "0" ? "--" : ccrows.investorType,
      ytdDistributionAmount: ccrows.ytdDistributionAmount,
      subscribed: ccrows.subscribed,
      contribution: ccrows.contribution,
      fdOwnership: ccrows.fdOwnership,
      currentDistributionAmount: ccrows.currentDistributionAmount,
      ifdStatus: ccrows.ifdStatus,
    })) : [];
  const UpdatedDistributionrows = DistributionInvestorRows.map((row) => {
    const { id, ...rest } = row;
    return rest;
  });

  const validateSubmition = (updatedData) => {

    if (
      updatedData.fdPaymentDate === null || updatedData.fdPaymentDate === undefined ||
      updatedData.fdSourceID === 0 || updatedData.fdSourceID === undefined ||
      updatedData.fdStatusID === 0 || updatedData.fdStatusID === undefined ||
      updatedData.fdPaymentMethodID === 0 || updatedData.fdPaymentMethodID === undefined ||
      updatedData.fdTypeID === 0 || updatedData.fdTypeID === undefined ||
      updatedData.fdCurrency === 0 ||
      updatedData.distributionInvestors.length === 0
    ) {
      return false
    }
    else {
      return true
    }
  };

  const onEditSaveClick = async (isActive = false) => {
    const updatedData = {
      "fundID": secureLocalStorage.getItem("FundId"),
      fundName: secureLocalStorage.getItem("FundName"),
      "distributionID": rowDistributionNumber,
      "distributionAmount": distributionAmount,
      "nav": navBeforeDistribution,
      "fdDate": formData.distributionDate,
      "fdPaymentDate": formData.paymentDate,
      "fdSerialNo": distributionDetails?.fdSerialNo,
      "fdCurrency": formData.currency,
      "fdSourceID": formData.distributionSource,
      //"distributionSource": formData.distributionSource,
      "fdStatusID": isActive === false ? 2 :  3 ,
      "distributionStatus": DeleteClickHappened ? "delete" : '',
      "fdPaymentMethodID": formData?.paymentMethod,
      //"paymentMethod": formData?.paymentMethod ,
      "fdTypeID": formData?.distributionType,
      "fdCurrency": formData.currency,
      //"distributionType": formData?.distributionType,
      // "distributionInvestors": UpdatedDistributionrows.filter(u=>u.fdOwnership > 0)
      "distributionInvestors": UpdatedDistributionrows.filter(u => u.fdOwnership > 0)
    };




  }

  const onRowsSelectionHandler = (ids) => {
    setSelectedIds(ids);
    calculateOwnershipForSelectedRows(ids);
  };


  const calculateOwnershipForSelectedRows = (ids) => {
    const totalSelectedSubscribed = ids?.reduce((total, id) => {
      const selectedRow = DistributionInvestorRows?.find((row) => row?.id === id);
      return total + (selectedRow ? selectedRow?.contribution : 0);

    }, 0);
    const updatedInvestors = distributionDetails.distributionInvestors?.map((row) => {

      const isSelected = ids.includes(row.investorID);
      const MainDistribution = distributionAmount ? distributionAmount : distributionAmount
      return {
        ...row,
        fdOwnership: isSelected
          ? (row.contribution / totalSelectedSubscribed) * 100 : 0,
        // //originalCCInvestors.capitalCallInvestors.find((r) => r.investorFundSubscriptionsID === row.investorFundSubscriptionsID).ccOwnership,
        // // callAmount: isSelected ? (totalCapitalCalled * (row.subscribed / totalSelectedSubscribed)) : 0
        currentDistributionAmount: isSelected ? ((((row.contribution / totalSelectedSubscribed)) * MainDistribution)) : 0,

        // contribution: isSelected ? (distributionAmount * row.fdOwnership ) : 0
      };
    });

    // Update the state with the modified data
    setdistributionDetails({ ...distributionDetails, distributionInvestors: updatedInvestors });
  };

  const [errors, setErrors] = useState({});


  const handleInputChange = (field, value) => {
    const validationResult = validateField(field, value);

    if (!validationResult.isValid) {
      setErrors(prevErrors => ({ ...prevErrors, [field]: validationResult.error }));
    }
    else {
      setErrors(prevErrors => ({ ...prevErrors, [field]: null }));
      /*   */

      formData[field] = value;
      setFormData(formData);
    };
  };




  const MandatoryFieldErrors = (field, value) => {
     
    const fieldsToKeep = ['currency', 'distributionSource', 'paymentMethod', 'distributionType', 'distributionNumber', 'distributionDate', 'paymentDate',
      'beforeDistribution', 'distributionAmount', ];

    const Missedinformdata = {
      'distributionAmount': distributionAmount,
      'beforeDistribution': navBeforeDistribution,
    }
    const trimmedValues = { ...formData, ...Missedinformdata };




    const filteredFields = fieldsToKeep.map((field) => {
      if (trimmedValues.hasOwnProperty(field)) {
        return { field, value: trimmedValues[field] };
      }
      return null;
    })?.filter(Boolean);

    const Mandatory = filteredFields.map(({ field, value }) => ({
      field,
      value: value === "" || value === '0'? null : value,
    }));


    const EmptyFields = Mandatory?.filter(entry => entry.value === "" || entry.value === null || entry.value === undefined || entry.value === 0).map(entry => entry.field);
    const error = `This field is required.`;
    EmptyFields.map((item) => {
      /*   */
      setErrors(prevErrors => ({ ...prevErrors, [item]: error }));
    })

    return EmptyFields;
  };
  const distributionAmountChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  }
  const beforeDistributionChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  }


  const validateField = (field, value) => {
    if (field === 'distributionAmount') {
      distributionAmountChange(field, value)
      setDistributionAmount(value);
      calculateOwnershipForSelectedRows(selectedIds)

    }
    else if (field === 'beforeDistribution') {
      /*   */
      handleNavDistributionChange(value);
    }
    const validationRules = {
      currency: (value, currState) => {
        const isValid = value !== "" || value !== undefined || value !== null;
        return {
          isValid,
          error: isValid ? null : "Please enter a valid Percentage",
        };
      },
      distributionSource: (value, currState) => {
        const isValid = value !== "" || value !== undefined || value !== null;
        return {
          isValid,
          error: isValid ? null : "Please select an option",
        };
      },
      statusoftheDistribution: (value, currState) => {
        const isValid = value !== "" && value !== undefined && value !== null;
        return {
          isValid,
          error: isValid ? null : "Please select an option",
        };
      },
      paymentMethod: (value, currState) => {
        const isValid = value !== "" && value !== undefined && value !== null;
        return {
          isValid,
          error: isValid ? null : "Please select an option",
        };
      },
      distributionType: (value, currState) => {
        const isValid = value !== "" && value !== undefined && value !== null;
        return {
          isValid,
          error: isValid ? null : "Please select an option",
        };
      },
      distributionNumber: (value, currState) => {
        const isValid = value !== "" && value !== undefined && value !== null;
        return {
          isValid,
          error: isValid ? null : "This field is required",
        };
      },
      distributionDate: (value, currState) => {
        const isValid = value !== "" && value !== undefined && value !== null;
        return {
          isValid,
          error: isValid ? null : "This field is required",
        };
      },
      paymentDate: (value, currState) => {
        const isValid = value !== "" && value !== undefined && value !== null;
        return {
          isValid,
          error: isValid ? null : "This field is required",
        };
      },
      beforeDistribution: (value, currState) => {
        // Check if the value is empty
        const isEmpty = !value.trim();

        if (isEmpty) {
          return {
            isEmpty,
            error: 'Please enter a Valid beforeDistribution.',
          };
        }

        // Check if the value is a valid positive numeric value
        const isNumeric = /^[1-9]\d*(\.\d+)?$/.test(value);

        if (!isNumeric) {
          return {
            isEmpty: false,
            error: 'Please enter a positive numeric value for Capital Call ID.',
          };
        }

        return {
          isEmpty: false,
          error: null,
        };
      },
      distributionAmount: (value, currState) => {
        // Check if the value is empty
        const isEmpty = !value.trim();

        if (isEmpty) {
          return {
            isEmpty,
            error: 'Please enter a Valid distributionAmount.',
          };
        }

        // Check if the value is a valid positive numeric value
        const isNumeric = /^[1-9]\d*(\.\d+)?$/.test(value);

        if (!isNumeric) {
          return {
            isEmpty: false,
            error: 'Please enter a positive numeric value for Capital Call ID.',
          };
        }

        return {
          isEmpty: false,
          error: null,
        };
      },
    };

    return validationRules[field] ? validationRules[field](value) : { isValid: true, error: null };
  };




  const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);

  // const aligndistrubutioncenter = {
  //   marginTop: '20px', marginLeft: '30px', marginRight: '20px', marginBottom: '20px'
  // }

  const PreviousColumns = [

    {

      "id": 1,

      "field": "DATE",

      "headerName": "DATE",

      "width": 240

    },

    {

      "id": 2,

      "field": "STATUS",

      "headerName": "STATUS",

      "width": 240

    },

    {

      "id": 3,

      "field": "AMOUNT",

      "headerName": "AMOUNT",

      "width": 240,
      renderCell: (params) => {
        return <GridCurrencyFormater params={params} />
      }

    },

    {

      "id": 4,

      "field": "TYPE",

      "headerName": "TYPE",

      "width": 240

    },

    {

      "id": 5,

      "field": "NOOFINVESTORS",

      "headerName": "# OF INVESTORS",

      "width": 120

    }
  ]


  const DistributionsColumns = [

    {

      "id": 1,

      "field": "investor",

      "headerName": "INVESTORS",

      "width": 160

    },

    {

      "id": 2,

      "field": "investorType",

      "headerName": "TYPE",

      "width": 160

    },

    {

      "id": 3,

      "field": "ytdDistributionAmount",

      "headerName": "YTD DISTRIBUTION AMOUNT",

      "width": 160,
      renderCell: (params) => {
        return <GridCurrencyFormater params={params} />
      }

    },

    {

      "id": 4,

      "field": "subscribed",

      "headerName": "SUBSCRIBED",

      "width": 160,
      renderCell: (params) => {
        return <GridCurrencyFormater params={params} />
      }

    },

    {

      "id": 5,

      "field": "contribution",

      "headerName": "CONTRIBUTION",

      "width": 160,
      renderCell: (params) => {
        return <GridCurrencyFormater params={params} />
      }

    },

    {

      "id": 6,

      "field": "fdOwnership",

      "headerName": "OWNERSHIP",

     

    },

    {

      "id": 7,

      "field": "currentDistributionAmount",

      "headerName": "CURRENT DISTRIBUTIO NAMOUNT",

      "width": 130,
      renderCell: (params) => {
        return <GridCurrencyFormater params={params} />
      }

    },

    {

      "id": 8,

      "field": "ifdStatus",

      "headerName": "STATUS",

   

    }

  ]
  const initiallySelectedRows = DistributionInvestorRows?.filter((ccrows) => ccrows?.fdOwnership > 0)?.map((ccrows) => ccrows?.id);



  return (
<div>
      {/* <FundBanner BackArrowClick= {BackArrowClick}/> */}
      <PageHeader
        template={HeaderTemplates.Standard}
        fundName={secureLocalStorage.getItem("FundName")}
        headerImage={secureLocalStorage.getItem("selectedFund")?.IconImage}
        customBackgroundImage={secureLocalStorage.getItem("selectedFund")?.BGImage ? secureLocalStorage.getItem("selectedFund")?.BGImage : '/DefaultBackgroundImage.png'}

      />

      <div className='m-lr'>
      <Grid container marginTop={3}>
        <Grid item xs={7}>
          <h2>
            Distributions
          </h2>
        </Grid>
        <Grid item xs={5} >
          <div style={{ display: "flex", float: "right" }} >
            <div hidden={!AddingDistribution}>
              <Button className='btn-primary2'
                variant="outlined"
                size="medium"
                startIcon={<SendIcon />}
                //   disabled={!isEditing}
                //   onClick={openDialog} 
                onClick={OnExecuteDistributionsClick}
                disabled={!distributionSaved || formData?.statusoftheDistribution ===3}
              >
                EXECUTE DISTRIBUTIONS
              </Button>
              <Dialog open={isDialogOpen} onClose={handleExecuteDialogClose}>
                <DialogTitle>
                  <Text label="Distributions Confirmation " />
                </DialogTitle>
                <DialogContent>
                  <strong>Distribution ID</strong> : {rowDistributionNumber}
                  <br></br>
                  <strong>Payment Date</strong> : {moment(formData?.paymentDate).format("MM-DD-YYYY")}
                  <br></br>
                  <strong>Payment Method</strong> : {formData?.paymentMethod === 1 ? 'Wire Transfer' : formData?.paymentMethod === 2 ? 'Cheque Payment' : formData?.paymentMethod === 3 ? 'ACH' : 'EFT'}
                  <br></br>
                  <strong>Distribution Type</strong> : {formData?.distributionType === 1 ? 'Return of capital' : formData?.distributionType === 2 ? 'Realized Gains' : 'Dividends'}
                  <br></br> <br></br>
                  <AIPDataGrid
                    columns={DistributionsColumns}
                    rows={DistributionInvestorRows?.filter((row) => row?.fdOwnership > 0) ?? []}
                    // checkboxSelection={true}
                    onRowsSelectionHandler={onRowsSelectionHandler}
                  // initiallySelectedRows = {initiallySelectedRows}
                  />
                  <br></br> <br></br>
                  <strong>Action Summary:</strong>
                  <br></br>
                  Distributions notices will be sent to the selected Investors. once executed, this action cannot be undone.
                  <br></br> <br></br>
                  <strong>
                  Do you want to proceed with the Distributions?
                  </strong>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleExecuteDialogClose} className='btn-primary' variant='outlined'>
                    Cancel
                  </Button>
                  <Button onClick={onPopupConfirmClick} className='btn-primary' variant='contained'>
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </div>


            <div>
              {
                checkFeaturePermissions(RolesAndPermissions.Distributions, FeatureAccess.CREATE) && formData?.statusoftheDistribution !==3 &&
                <Button className='btn-primary2' 
                  variant="outlined"
                  size="medium"
                  startIcon={<ControlPointOutlinedIcon />}
                  onClick={() => AddDistributionsClick(0)}
                 style={{ whiteSpace: 'nowrap' }}
                 disabled = {formData?.statusoftheDistribution === 2}
                >
                  ADD DISTRIBUTIONS
                </Button>
              }

            </div>
          </div>
        </Grid>
      </Grid>

      {AddingDistribution && dataRetrived && formData && showDefault &&
        <AddingDistributions
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          handleEditClick={handleEditClick}
          handleSaveClick={handleSaveClick}
          distributionDetails={distributionDetails}
          distributionAmount={distributionAmount}
          navBeforeDistribution={navBeforeDistribution}
          handleDistributionChange={handleDistributionChange}
          handleNavDistributionChange={handleNavDistributionChange}
          formData={formData}
          beforeDistributionChange={beforeDistributionChange}
          distributionAmountChange={distributionAmountChange}
          DistributionInvestorRows={DistributionInvestorRows}
          onRowsSelectionHandler={onRowsSelectionHandler}
          handleInputChange={handleInputChange}
          showSave={showSave}
          showEdit={showEdit}
          executedStatus={executedStatus}
          distributionSaved={distributionSaved} // Pass the distributionSaved state
          setDistributionSaved={setDistributionSaved}
          onDelete={handleDelete}
          errors={errors}
        />
      }

      {AddingDistribution && dataRetrived && formData && !showDefault &&
        <AddingDistributions
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          handleEditClick={handleEditClick}
          handleSaveClick={handleSaveClick}
          distributionDetails={distributionDetails}
          distributionAmount={distributionAmount}
          navBeforeDistribution={navBeforeDistribution}
          handleDistributionChange={handleDistributionChange}
          handleNavDistributionChange={handleNavDistributionChange}
          formData={formData}
          beforeDistributionChange={beforeDistributionChange}
          distributionAmountChange={distributionAmountChange}
          DistributionInvestorRows={DistributionInvestorRows}
          onRowsSelectionHandler={onRowsSelectionHandler}
          handleInputChange={handleInputChange}
          showSave={showSave}
          showEdit={showEdit}
          executedStatus={executedStatus}
          distributionSaved={distributionSaved} // Pass the distributionSaved state
          setDistributionSaved={setDistributionSaved}
          onDelete={handleDelete}
          errors={errors}
        />
      }

      <Grid xs={12} container marginTop={4}>
        <Grid item xs={12}>
          <h4>Previous Distributions</h4>
        </Grid>
      </Grid>
      <Grid container xs={12} className='whiteCard' marginBottom={4} >
        <Grid item xs={12}>
          <div className='margin-top-10'>
            {
              PreviousDistributionsForFund === null ? 'No Previous Capital Calls'
                : checkFeaturePermissions(RolesAndPermissions.Distributions, FeatureAccess.READ) && <StackView rows={rows}
                  columns={PreviousColumns}
                  handleRowClick={onDraftStatusClick}
                  norowsMessage={"No distributions have been made yet."}
                  onRowsSelectionHandler={() => { }} />
            }
          </div>
        </Grid>
      </Grid>
      <ToastContainer />
    </div>
    </div>
  )
};

export default DistributionActivity

