import propTypes from 'prop-types';
import './styles.css';

const Text = (props) => {

    const {
        label,
    } = props;

    return(<div className='internalText'>{label}</div>)
}

Text.propTypes = {
    label: propTypes.string.isRequired,
}


export default Text;