import axiosApiCall from "./axiosApiCall";

export async function axiosApiCallForFiles(url,filename){
    try {
        const response = await axiosApiCall.get(url, {
            responseType: 'blob',
        });
        const blobUrl = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);
        return {
            responseCode: response.status,
            responseData: 'File downloaded successfully',
        };
    } catch (error) {
        return {
            responseCode: error.response?.status || 500,
            responseData: error.response?.data || 'Error downloading file',
        };
    }

}