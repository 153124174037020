import React from 'react'
import propTypes from 'prop-types';
import { Checkbox, FormControlLabel } from '@mui/material';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

const SwitchButtonField = (props) => {

  //#region props
  const {
    name,
    label,
    value,
    onChange,
    options,
    onBlur,
    required,
    error,
    errorMessage,
    disabled
  } = props;

  //#region change events
  const handleChange = (ev) => {
    const {name,checked} = ev.target;
    onChange(name,checked)
  }  

  return (
    <FormControlLabel 
        control={
            <StyledSwitch 
                name={name} 
                checked={value} 
                onChange={handleChange} 
                disabled={disabled}
            />
        } 
        label={label} />
  )
}

const StyledSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&::before, &::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&::before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      '&::after': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

SwitchButtonField.propTypes = {
    type: propTypes.string,
    onChange: propTypes.func.isRequired,
    required: propTypes.bool,
    value: propTypes.string,
    label: propTypes.string,
    disabled: propTypes.bool,
    name: propTypes.string,
    size: propTypes.string,
    errorMessage: propTypes.string
};

SwitchButtonField.defaultProps = {
    type: 'select',
    size: 'small',
    required: false,
    value: '',
    label: '',
    options: [],
    disabled: false,
    name: '',
    errorMessage: '',
};

export default SwitchButtonField