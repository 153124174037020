import { useState, useEffect } from "react";
import ListView from "../marketplace/components/ListView";
import { debounce } from 'lodash'; // Import the debounce function from lodash or use any debounce implementation
import { Box } from "@mui/material";
import TreeItem from '@mui/lab/TreeItem';
import FolderIcon from '@mui/icons-material/Folder';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Checkbox from '@mui/material/Checkbox';
import '../Generalize.css';
import FundNews from "../common/FundNews/FundNews";
import secureLocalStorage from "react-secure-storage";
import CircleIcon from "../chatbot/Icon";
import { useSelector } from "react-redux";
import { getrootBackgroundColorConfig } from "../../state/slices/colorConfigSlice";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Grid } from '@mui/material';
import { checkFeaturePermissions } from "../../utils/common";
import { AppDashboards, FeatureAccess, RolesAndPermissions } from "../../utils/enum";
import styles from "../user-profile/FundManagerUserProfile.module.css"
import AppNotifications from "../common/AppNotification/AppNotificationComponent";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const InvestorDashboardOld = () => {
  const navigate = useNavigate();
  const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);
  const [isExpanded, setExpendState] = useState(false);
  const fundDetailId = secureLocalStorage.getItem("FundId");
   
  const [showRejected, setShowRejected] = useState(false);


  console.log(fundDetailId)


  const [investorProfile, setInvestorProfile] = useState({});
  const [investmentdata, setinvestmentData] = useState([]);

  const emailId = secureLocalStorage.getItem("userEmail");
  const firstName = (investorProfile?.personalInformation?.firstName ?? "").charAt(0).toUpperCase() + (investorProfile?.personalInformation?.firstName ?? "").slice(1);
  const lastName = (investorProfile?.personalInformation?.lastName ?? "").charAt(0).toUpperCase() + (investorProfile?.personalInformation?.lastName ?? "").slice(1);
  const fullName = `${firstName} ${lastName}`;

  secureLocalStorage.setItem("fullName", fullName);

  // const isInvestorOnHold = true;
  // const handleStatusNavigation = useCallback(() => {
  //   if (isInvestorOnHold) {
  //     navigate("/ApprovedPage");
  //   }
  // }, [navigate]);

  const fetchData = async () => {
   
  }

  const [tree, setTree] = useState(null);

  const options = [
    'Share',
    'Shareable Link',
    'Download',

  ];
  const ITEM_HEIGHT = 48;
  const investorId = secureLocalStorage.getItem("userId");

  useEffect(() => {
   
  }, []);
  const [selectedNodes, setSelectedNodes] = useState([]);


  const handleCheckboxChange = (title, node) => {
    setSelectedNodes((prevSelectedNodes) => {
      const isSelected = prevSelectedNodes.some((selectedNode) => selectedNode.title === title);
      if (isSelected) {
        return prevSelectedNodes.filter((selectedNode) => selectedNode.title !== title);
      } else {
        return [...prevSelectedNodes, { title, node }];
      }
    });
  };


  const [userInput] = useState('');
  const [setOpen] = useState(false);
  const [setOpen1] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (option) => {
    console.log('Menu item clicked:', option);
    if (option === 'Add Folder') {
      setOpen(true);
    } else if (option === 'Upload File') {
      setOpen1(true);
    }
  };
  console.log(secureLocalStorage.getItem("userId"));
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(false);
  };
  console.log(tree);
  console.log(selectedNodes);
  const handleSave = async () => {
   
  };


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleFileUpload = () => {
    console.log('Selected File:', selectedFile);
    setOpen1(false);
  };
  const renderTree = (node) => (
    <TreeItem
      key={node.documentName}
      nodeId={node.documentName}
      selectedNodes={node}
      label={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            checked={selectedNodes.some((selectedNode) => selectedNode.title === node.documentName)}

            onChange={() => handleCheckboxChange(node.documentName, node)}


          />
          {node.isFolder ? <FolderIcon style={{ color: '#FFE9A2' }} /> : <PictureAsPdfIcon style={{ color: '#FA0F00' }} />}
          <span style={{ marginLeft: 5 }}>{node.documentName}</span>
        </div>
      }
    >
      {Array.isArray(node.children) ? node.children.map((childNode) => renderTree(childNode)) : null}
    </TreeItem>
  );
  const userID = secureLocalStorage.getItem("userId");


  const [FundNewsData, setFundNewsData] = useState();

  const getFundNews = async () => {
    /* 
    if (data.responseCode === 200) {
      console.log(data.responseData);
      setFundNewsData(data.responseData);
    }
    else {
    } */
  }

  useEffect(() => {
    fetchData();
    getFundNews();
  }, []);

  const fundId = secureLocalStorage.getItem("FundId");

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
  }));

  return (
    <div style={{ backgroundColor: rootBackColorConfig.color }} className="wrapper3">
      <Box sx={{ flexGrow: 1 }} overflowx={'auto'}>

        <Grid container>
          <Grid item xs={12} className=" font-size-xsmall font-size-color welcomeuser">
            Welcome back, {fullName}
          </Grid>
          <Grid item xs={12} className="heading-dashboard font-size-colorblack ">
            Investor Dashboard
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Item className="NoticationsDash">
                <h6>Notifications</h6>
                {
                  checkFeaturePermissions(RolesAndPermissions.FUND_SUBSCRIPTION, FeatureAccess.READ) &&
                  <AppNotifications
                    crntFundID={0}
                    crntInvestorid={secureLocalStorage.getItem("userId")} />

                }</Item>
            </Grid>
            <Grid item xs={12} md={6}>
              <Item className="suggestedRead_Notify">
                <div>
                  <FundNews FundNewsData={FundNewsData ?? []} role={AppDashboards.INV_DASHBOARD} />
                </div>
              </Item>
            </Grid>

          </Grid >


          <Grid container item xs={12} style={{ backgroundColor: rootBackColorConfig.color }}>
            <div style={{ height: '580px', width: "100%" }} className={styles.fundStatusWrapper}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Grid item xs={10} >
                <div className={styles.fundStatusHeaderWrapper}>
                  <b className="heading">My Investments</b>
                </div>
              </Grid>
              <Checkbox
                  checked={showRejected}
                  onChange={() => setShowRejected(!showRejected)}
                  color="primary"
                  inputProps={{ 'aria-label': 'Show Rejected' }}
                />
                <span>Show Rejected</span>
                </div>
              <div style={{ position: "relative" }}>
                {/* <Checkbox
                  checked={showRejected}
                  onChange={() => setShowRejected(!showRejected)}
                  color="primary"
                  inputProps={{ 'aria-label': 'Show Rejected' }}
                />
                <span>Show Rejected</span> */}

                <ListView
                  topValue="0px"
                  leftValue="0px"
                  marginLeft="10px"
                  heightvalue="450px"
                  overflowyvalue="auto"
                  backgroundcolor="white"
                  navigateFromInvestorDB={true}
                  navigateto={"/Investor-Overview-Page"}
                  // navigateto={"/subscription-details"}
                  investorID={secureLocalStorage.getItem("userId")}
                  showRejected={showRejected}
                  setShowRejected={setShowRejected}


                />
              </div>
            </div>
          </Grid>


        </Grid>

      </Box>
    </div>
  );
};

export default InvestorDashboardOld;
