export const RolesAndPermissions = {
    MARKET_PLACE: "Marketplace",
    FIRM_DASHBOARD: "Firm Dashboard",
    FUND: "Fund",
    FUND_VDR: "Virtual Data Room",
    CAPITAL_CALL: "Capital Call",
    Distributions: "Distributions",
    INVESTOR_REPORTS: "Investor Reporting Docs",
    SITE_ADMINISTRATION: "Site Settings - site admin",
    TERMINATE_FUND: "Terminate Fund",
    REINSTATE_FUND: "Reinstate fund after termination",
    PUBLIC_PROFILE_VIEW: "Fund Public Profile View",
    COMPLIANCE_ALERTS: "Compliance Alerts",
    SUGGESTED_READINGS: "Suggested Readings",
    PORTFOLIO_COMPANY: "Portfolio company",
    INVESTOR_MEETINGS_AND_EVENTS: "Investor Meetings and Events",
    LP_COMMUNICATION_AND_REPORTING: "LP Communication and Reporting",
    K_1_s: "K-1s",
    KYC_AML_DOCUMENTATION: "KYC/AML Documentation",
    FIRM_PROFILE: "Firm Profile",
    INVESTOR_ONBOARDING_QUESTIONS: "Investor onboarding questions",
    OFFLINE_SUBSCRIPTION_MANAGEMENT: "Offline Subscription Management",
    INVITE_INVESTORS: "Invite Investors",
    KYC_AML_Verification: "KYC AML Verification",
    AUDITING: "Auditing"
    // FUND_OVERVIEW : "FundOverview",
    // FUND_SUBSCRIPTION : "FundSubscription",
    // INVESTOR_PROFILE : "InvestorProfile",
}

export const FeatureAccess = {
    CREATE: "CREATE",
    READ: "READ",
    UPDATE: "UPDATE",
    DELETE: "DELETE",
}

export const AppDashboards = {
    INV_DASHBOARD: "Investor",
    FM_DASHBOARD: "Fund Manager",
    PC_DASHBOARD: "Portfolio Company",
    CO_DASHBOARD: "Legal Compliance Officer",
    FIRM_DASHBOARD: "Firm",
    SUPERADMIN_DASHBOARD: "Super Admin",
}


export const CommunicationType = {
    NewNotification: "NewNotification",
    Drafts: "Drafts",
    SentNotifications: "SentNotifications",
    Groups: "Groups",
}

export const InvestorTypes = {
    INDIVIDUAL_INVESTOR: "Individual Investor",
    SOVERIGN_Wealth_FUNDS: "Sovereign Wealth Funds",
    HEDGE_FUNDS: "Hedge Funds",
}

export const MarketPlaceView = {
    BOX_VIEW: "CREATE",
    LIST_VIEW: "READ",
} 