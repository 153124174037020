import secureLocalStorage from "react-secure-storage";


export const NotifyPortfolioInitialData = {
    companyname: '',
    sectorid: '',
    firstName: '',
    lastName: '',
    phonenumber: '',
    email: ''
}