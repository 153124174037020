import React from 'react';
import Dialog from '@mui/material/Dialog';
import FundDetails from './FundDetails';
const FundDetailsDialog = ({ open, onClose, HideLeftMenu }) => {
    const dialogPaperStyles = {
        minHeight: '500px',
        overflowX: 'hidden',
        // contentsize: '100px',
        
      };
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg" PaperProps={{ style: dialogPaperStyles }} >
    <FundDetails hideBackButton={true} HideLeftMenu={HideLeftMenu} />
    </Dialog>
  );
};

export default FundDetailsDialog;
