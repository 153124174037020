import * as React from 'react';
import { useEffect } from 'react';
import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import ActionButton from '../common/ActionButton/ActionButton';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Accordian from '../common/Accordian/Accordian';
import { InputField, WhileLabelingInputField } from './InputField';
// import './styles.css';
import { LabelingConfig } from './Constants';
import { useDispatch, useSelector } from 'react-redux';
import { getColorConfig, updateColorConfig } from '../../state/slices/colorConfigSlice';
import secureLocalStorage from 'react-secure-storage';
import Configurations from '../../Configurations';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import { ResetWhiteLabelingConfig } from './Constants';
import { ToastContainer, toast } from 'react-toastify';
import { margin } from '@mui/system';
import WhiteLabelingConfigConfirmation from './WhiteLabelingConfigConfirmation';


export const WhiteLabeling = (props) => {

  let DefaultLabelingConfig = useSelector(getColorConfig);
  const [currConfig, setConfig] = React.useState(DefaultLabelingConfig);
  const [confirmation,setConfirmation] = React.useState(false)

  const dispatch = useDispatch();

  const handleUpdateConfig = (root, name, value) => {
    let resConfig = { ...currConfig[root] };
    let actualConfig = { ...currConfig };
    resConfig[name] = value;
    actualConfig[root] = resConfig;
    setConfig({ ...currConfig, ...actualConfig });
  }

  const onApplyClick = () => {
    setConfirmation(true)
  }

 const onConfirmationPopupClose = () => {
  setConfirmation(false)
 }

  const handleApplyConfig = () => {
    let resConfig = currConfig;
    const updateWhiteLabellingConfig = async () => {
      fetch(`${Configurations.apiBaseUri}/v1/UserRole/SaveTenantCustomizations`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(
          {
            whiteLabellingID: 0,
            tenantGuid: secureLocalStorage.getItem("tenantID"),
            ...resConfig
          }
        )
      })
        .then(response => {
          if (response.ok) {
            response.json().then(data => {
              setConfirmation(false)
              console.log('labelling data' + data);
              dispatch(updateColorConfig(data));
              window.location.reload(true); // Reload the window from the server and force cache refresh

            })
          } else {
            // handle the error response
            console.error(response);
            throw new Error("Failed to fetch Data");
          }
        })
    }
    updateWhiteLabellingConfig();
  }

  const handlePreviewConfig = () => {
    handleApplyConfig();
  }

  const ResetConfig = async () => {
    const data = await ResetWhiteLabelingConfig(secureLocalStorage.getItem("tenantID"));
    if (data?.responseCode === 200) {
      dispatch(updateColorConfig(data?.responseData));
      toast.success("Your configuration has been reset successfully. Please perform a hard refresh to apply the changes.",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
       window.location.reload(true);
    }
    else {
      toast.warning("Something went wrong,please try again",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
  }

  return (
    <div>
      <DialogTitle>
        <h4> White Labeling</h4>
      </DialogTitle>
      <div className='white-card'>
        <DialogContent>
          {
            LabelingConfig.map(config => (
              <div className='accordianWrapper'>
                <Accordian
                  title={config.title}
                  defaultExpanded

                  body={<>
                    <Typography sx={{ color: 'text.primary' }}>{config.subText}</Typography>
                    {
                      config.fields.map(field => (
                        <WhileLabelingInputField
                          value={currConfig[config.name][field.name]}
                          onChange={(val) => handleUpdateConfig(config.name, field.name, val)}
                          {...field} />
                      ))}
                  </>}
                />
              </div>
            ))
          }

        </DialogContent>
        <div style={{display:'flex' , justifyContent : 'flex-end'}}> 
        {/* <div style={{textAlign:'left'}}>
          <ActionButton
              icon={<VisibilityIcon />}
              label='Preview' onClick={() => handlePreviewConfig()} />
          </div> */}
          <div style={{alignItems:'right'}}>
            <ActionButton
            variant="outlined"
              icon={<RotateLeftIcon />}
              label='Reset' onClick={() => ResetConfig()} styleProps={{margin:'10px'}}/>
            <ActionButton
              icon={<TouchAppIcon />}
              label='Apply' onClick={() => onApplyClick()} />
          </div>
        </div>
        </div>
      <ToastContainer />
      {confirmation && <WhiteLabelingConfigConfirmation confirmation={confirmation} handleApplyConfig={handleApplyConfig} onClose={onConfirmationPopupClose} />}
    </div>
  )
}