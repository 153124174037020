export const calenderevents = [
    {
      event_id: 1,
      title: "Event 112",
      start: new Date("2024 3 20 09:30"),
      end: new Date("2024 3 20 10:30"),
      attendees: []
    },
    {
      event_id: 2,
      title: "Event 2",
      start: new Date("2024 3 4 11:30"),
      end: new Date("2024 3 4 12:00"),
      attendees: []
    },
    {
      event_id: 3,
      title: "Event 3",
      start: new Date("2024 4 27 09:00"),
      end: new Date("2024 4 28 10:00"),
      attendees: []
    },
    {
      event_id: 4,
      title: "Event 4",
      start: new Date("2024 3 4 9:00"),
      end: new Date("2024 3 4 10:36"),
      attendees: []
    },
    {
      event_id: 3,
      title: "Event 3",
      start: new Date("2024 3 1 10:00"),
      end: new Date("2024 3 18 11:00"),
      attendees: []
    },
    {
      event_id: 6,
      title: "Event 6",
      start: new Date("2024 3 2 11:00"),
      end: new Date("2024 3 2 12:00"),
      attendees: []
    },
    {
      event_id: 7,
      title: "Event 7",
      start: new Date("2024 3 1 12:00"),
      end: new Date("2024 3 1 13:00"),
      attendees: []
    },
    {
      event_id: 8,
      title: "Event 8",
      start: new Date("2024 3 1 13:00"),
      end: new Date("2024 3 1 14:00"),
      attendees: []
    }
  ];