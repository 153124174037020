import axiosApiCall from "../../../../Services/axiosApiCall";
import { getAPIResponse } from "../../../../../utils/common";

export async function getEmailTemplatesGroupsList() {
    return await axiosApiCall.get(`/v1/EventManagement/Get`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function UpdateEmailTemplate(data) {
    return await axiosApiCall.put(`/v1/EventManagement/Update`, data)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {

            return getAPIResponse(error);
        });
}

export async function ResetEmailTemplate(emailTemplateID) {
    return await axiosApiCall.put(`/v1/EventManagement/Reset?emailTemplateID=${emailTemplateID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}