import axios from 'axios';
import resolve from '../../common/resolve.js';
import { getAPIResponse } from '../../../utils/common.js';
import axiosApiCall from '../../Services/axiosApiCall.js';


export async function getFundByInvestorId(userId) {
    return await axiosApiCall.get(`/v1/Fund/GetFundByInvestorId?investorID=${userId}`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function getFundSubscriptions(fundID) {
    return await axiosApiCall.get(`/v1/FundSubscription/GetFundSubscriptions?fundID=${fundID}`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}