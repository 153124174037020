import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';

/* const steps = [
  { label: 'Select campaign settings', description: "Successfully Uploaded", error: false },
  { label: 'Create an ad group', description: "This File Already Exists in Selected Year", error: true },
  { label: 'Create an ad', description: "Successfully Uploaded", error: false }
]; */

export default function VerticalProgressBar(props) {

  //#region props
  const {steps} = props;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
      <Stepper orientation="vertical" connector="">
        {steps.map(({ label, description, error }, index) => (
          <Step key={label} completed={true}>
            <StepLabel error={error}>
              <Typography variant="h8">{label}</Typography>
              <Typography variant="body2" color="textSecondary">{description}</Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
