import { ConvertToUSCurrency } from "../common/Functions/CommonConvertions";
import { stringOperators, dropdownOperators, subscriptionStatusOperator, investmentStatusOperator } from "../FundManagerDashboard/myfundFilterOperators";

export const fundColumns = [
    {
        id: 1,
        field: "iconImage",
        headerName: "Fund Logo",
        width: 170,
        renderCell: (params) => {
            const labelStyle = {
                backgroundColor: '#1F61C5',
                color: 'white',
                padding: '2px 8px',
                borderRadius: '4px',
                position: 'absolute',
                top: '0',
                left: '0',
                transform: 'translate(10%, -50%)',
                zIndex: 1,
                fontSize: '10px',
            };

            const cellContentStyle = {
                position: 'relative',
            };

            return (
                <div style={cellContentStyle}>
                    <img className='list-icon-img-container' src={params.value !== "" ? params.value : "/DefaultLogo.png"} />
                    <span style={labelStyle}>{params.row.isOfflineSubscription ? "Offline" : "Online"}</span>
                </div>
            );
        },
    },
    {
        id: 2,
        field: "fundName",
        headerName: "Fund Name",
        width: 130,
        filterOperators: stringOperators,
    },
    {
        id: 3,
        field: "fundStatusName",
        headerName: "Fund Status",
        /* flex: 1, */
        filterOperators: dropdownOperators,
    },
    {
        id: 4,
        field: "targetIndustry",
        headerName: "Target Sector",
        /* flex: 1, */
    },
    {
        id: 5,
        field: "capitalCommitted",
        headerName: "Capital Commited",
        renderCell: (params) => ConvertToUSCurrency(params.value),
        /* flex: 1, */
    },
    {
        id: 6,
        field: "subscriptionStatus",
        headerName: "Subscription Status",
        filterOperators: subscriptionStatusOperator,
        width: 170,
    },

    {
        id: 7,
        field: "contributedAmount",
        headerName: "Contributed Amount",
        renderCell: (params) => ConvertToUSCurrency(params.value),
    },
    {
        id: 8,
        field: "investmentStatusName",
        headerName: "Investment Current Status",
        width: 170,
        filterOperators: investmentStatusOperator,
    },
    /* {
        id: 9,
        field: "",
        headerName: "",
        width: 30,
        renderCell: (params) => <img src="/chevron-right-fill0-wght400-grad0-opsz48.svg" />,
    }, */
];

// Columns for Private Equity
export const myInvestmentsprivateEquityColumns = [
    fundColumns[0],
    fundColumns[1],
    fundColumns[2],
    fundColumns[3],
    fundColumns[4],
    fundColumns[5],
    //fundColumns[9],
    fundColumns[6],
    fundColumns[7],
];

// Columns for Private Credit
export const myInvestmentsprivateCreditColumns = [
    fundColumns[0],
    fundColumns[1],
    fundColumns[2],
    fundColumns[4],
    fundColumns[5],
    fundColumns[7],
];