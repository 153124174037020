import * as React from 'react';
import Modal from "./Modal";
import propTypes from 'prop-types';

const StepModal = (props) => {

    const { open, maxWidth, scroll, onPrevClick, onNextClick,currentStep,firstStep,lastStep} = props;

    React.useEffect(() => {
        if (open) {
            /*   */
          setTimeout(() => {
            const modalContainer = document.getElementsByClassName('MuiDialog-container')[0];
      /*   */
            // Remove existing buttons if they exist
            const prevButton = document.getElementById('modal_prev');
            const nextButton = document.getElementById('modal_next');
      
            if (prevButton) {
              prevButton.remove();
            }
      
            if (nextButton) {
              nextButton.remove();
            }
      
            // Render buttons based on currentStep
            modalContainer.prepend(renderPrependNode(currentStep !== firstStep));
            modalContainer.append(renderappendNode(currentStep !== lastStep));
          }, 0);
        }
      }, [open, currentStep, firstStep, lastStep]);  
      
    
      const renderPrependNode = (isappend) => {
        let prependNode = document.createElement('div');
        prependNode.setAttribute('id', 'modal_prev');
        if (isappend) {
          prependNode.addEventListener('click', onPrevClick);
        }
        prependNode.innerHTML = isappend
            ? '<p>PREVIOUS</p><i class="fa fa-light fa-circle-arrow-left"></i>'
            : '<p style="opacity: 0; cursor: default;">PREVIOUS</p>';
        return prependNode;
    }
    
    
    
      const renderappendNode = (isappend) =>{
        let appendNode = document.createElement('div');
        appendNode.setAttribute('id', 'modal_next');
        if (isappend) {
          appendNode.addEventListener('click', onNextClick);
        }
        appendNode.innerHTML = isappend
                                ? '<p>NEXT</p><i class="fa fa-light fa-circle-arrow-right"></i>'
                                : '<p style="opacity: 0; cursor: default;">NEXT</p>'
        return appendNode;
      } 
    
    return(
        <Modal open={open} maxWidth={maxWidth} scroll={scroll}>{props.children}</Modal>
    )
}

StepModal.propTypes={
    open: propTypes.bool,
    maxWidth: propTypes.oneOf(['xs','sm', 'md','lg','xl']),
    scroll: propTypes.oneOf(['paper', 'body']),
    onPrevClick: propTypes.func,
    onNextClick: propTypes.func,
    currentStep: propTypes.number,
    firstStep: propTypes.number,
    lastStep: propTypes.number
}

StepModal.defaultProps={
    open: false,
    maxWidth: 'md',
    scroll: 'paper',  
    onPrevClick: () => undefined,
    onNextClick: () => undefined,
    currentStep: 0,
    firstStep: 0,
    lastStep: 0
}

export default StepModal;