import secureLocalStorage from "react-secure-storage" 

export const addEventTemplate = {
    "calenderEventID": 0,
    "title": "",
    "startDate": "",
    "endDate": "",
    "entityTypeID": 0,
    "actionBy": 0,
    "isActive": true,
    "softDelete": false,
    "createdDate": new Date(),
    "updatedDate": new Date(),
    "listOfInvestors": null
  }