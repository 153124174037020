import secureLocalStorage from "react-secure-storage";

export const addNewRoleData = {
    "roleId": 0,
    "roleName": "",
    "priority": 1,
    "description": "",
    "isActive": true,
    "softDelete": false,
    "tenantGUID": secureLocalStorage.getItem("tenantID"),
    "createdBy": secureLocalStorage.getItem("userId"),
    "createdDate": new Date(),
    "updatedBy": secureLocalStorage.getItem("userId"),
    "updatedDate":  new Date(),
    "applicationDashboardId": '',
    "isSystemRole": false,
    "isPrimaryRole": false,
    "primaryRoleID": ""
  }