
import { getAPIResponse } from "../../../utils/common";
import axiosApiCall from "../../Services/axiosApiCall";


export async function getUserDetailsbyEmail(emailId) {
    return await axiosApiCall.get(`/v1/User/GetUserDetails?emailAddress=${emailId}`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}