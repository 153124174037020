import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { InputAdornment } from '@mui/material';

const AutoSelect = (props) => {
    const {
        label,
        placeholder,
        options,
        variant,
        size,
        name,
        required,
        disabled,
        defaultValue,
        onChange,
        error,
        validationMessage,
        startAdornment,
        endAdornment,
    } = props;

    const [inputValue, setInputValue] = useState(defaultValue || '');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [newOption, setNewOption] = useState(null);
    const [localOptions, setLocalOptions] = useState(options);

    useEffect(() => {
        setInputValue(defaultValue || '');
    }, [defaultValue]);

    useEffect(() => {
        setLocalOptions(options);
    }, [options]);

    const handleChange = (event, newValue) => {
        if (newValue === null) {
            const value = '';
            setInputValue(value);
            onChange(name, value);
        } else if (newValue.value && newValue.label.startsWith('Add')) {
            setNewOption(newValue.value);
            setDialogOpen(true);
        } else {
            const value = newValue.value ? newValue.value : newValue;
            setInputValue(value);
            onChange(name, value);
        }
    };

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
    };

    const handleDialogClose = (confirmed) => {
        setDialogOpen(false);
        if (confirmed) {
            const updatedOptions = [...localOptions, { label: newOption, value: newOption }];
            setLocalOptions(updatedOptions);
            setInputValue(newOption);
            onChange(name, newOption);
        }
        setNewOption(null);
    };

    const sortedOptions = localOptions?.slice().sort((a, b) => a.label.localeCompare(b.label));
    const customOption = inputValue && !sortedOptions.find(option => option.value === inputValue) ? [{ label: `Add "${inputValue}"`, value: inputValue }] : [];

    return (
        <>
            <Autocomplete
                freeSolo
                options={[...customOption, ...sortedOptions]}
                readOnly={disabled}
                getOptionLabel={(option) => option.label || ""}
                value={sortedOptions?.find(option => option.value === inputValue) || null}
                onChange={(event, newValue) => handleChange(event, newValue)}
                onInputChange={(event, newInputValue) => handleInputChange(event, newInputValue)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        name={name}
                        placeholder={placeholder}
                        variant={variant}
                        size={size}
                        required={required}
                        disabled={disabled}
                        error={error}
                        helperText={error ? validationMessage : ''}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: startAdornment ? (
                                <InputAdornment position="start">{startAdornment}</InputAdornment>
                            ) : null,
                            endAdornment: endAdornment ? (
                                <InputAdornment position="end">{endAdornment}</InputAdornment>
                            ) : null,
                        }}
                        InputLabelProps={{
                            shrink: true,
                            focused: true,
                        }}
                    />
                )}
            />
            <Dialog open={dialogOpen} onClose={() => handleDialogClose(false)}>
                <DialogTitle>Add New Option</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Do you want to add "{newOption}" to the list of options?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDialogClose(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => handleDialogClose(true)} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AutoSelect;
