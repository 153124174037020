import React, { useEffect } from 'react'
import ActiveUserList from './activeUserList'
import { useState } from "react";
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import secureLocalStorage from 'react-secure-storage';

function MessangerModal() {
    const messengerRoom = "ALT360"
    const [messengerUsersList,setmessengerUsersList] = useState([])
    const [connection, setConnection] = useState();
    const [messages, setMessages] = useState([]);

    //const dispatch = useDispatch();

    const joinRoom = async (user, room) => {
        try {
          const connection = new HubConnectionBuilder()
            .withUrl("https://instantmessaging.azurewebsites.net/chat")//Please provide local url while development
            .configureLogging(LogLevel.Information)
            .build();
    
          connection.on("ReceiveMessage", (user, message) => {
            setMessages(messages => [...messages, { user, message }]);
            secureLocalStorage.setItem('messengerMessages',(messages => [...messages, { user, message }]))
          });
    
          connection.on("UsersInRoom", (users) => {
            const uniqueUsers = Array.from(new Set(users));
            setmessengerUsersList(uniqueUsers);
            secureLocalStorage.setItem("messengerUsersList",uniqueUsers)
        });
    
          connection.onclose(e => {
            setConnection();
            setMessages([]);
            setmessengerUsersList([]);
          });
    
          await connection.start();
          await connection.invoke("JoinRoom", { user, room });
          setConnection(connection);
          secureLocalStorage.setItem('connection',connection)
        } catch (e) {
          console.log(e);
        }
      }

      useEffect(()=> {
        joinRoom(secureLocalStorage.getItem("userName"), messengerRoom);
      },[])

  return (
    <div className="messenger">
        <div className="scrollable sidebar">
          <ActiveUserList />
        </div>

        {/* <div className="scrollable content">
          <MessageList />
        </div> */}
      </div>
  )
}

export default MessangerModal