import React, { useState,useEffect } from 'react';
import TextInput from '../../../common/TextInput/TextInput';
import "../../CompanyDetail.module.css"
import ActionButton from '../../../common/ActionButton/ActionButton';
import { useRef } from 'react';
import secureLocalStorage from 'react-secure-storage';

function Accounting(props) {
    const componentRef = useRef(null);
    useEffect(() => {
        componentRef.current.scrollIntoView({ behavior: 'smooth' });
    }, []);
  const [errors, setErrors] = useState({});
  const handleChange = (field, value) => {
    const validationResult = validateField(field, value);
        if (!validationResult.isValid) {
            setErrors(prevErrors => ({ ...prevErrors, [field]: validationResult.error }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, [field]: null }));    
            
            // props.updateData(currState);
        }
        props.data[field] = value;
        props.setapiData(props.data);
        validateAllErrors();
  };

    const validateField = (field, value) => {

      const validationRules = {
        accountantFirmName: (value, currState) => {
              value = value.trim(); 
              
              const isValid = /^[A-Za-z\s]+$/.test(value);
          
              return {
                  isValid,
                  error: isValid ? null : `Please enter a Valid Name`,
              };
          },
        accountantFirmZipCode: (value, currState) => {
            value = value.trim();
        
            const isValid = /^\d{5}(-\d{4})?$/.test(value);
        
            return {
                isValid,
                error: isValid ? null : 'Please enter a valid ZIP Code (Example:12345-6789)',
            };
        },
        accountantContactPhone: (value, currState) => {
            value = value.trim();
            const isValid = /^\d{10}$/.test(value); 
            return {
                isValid,
                error: isValid ? null :  "Please enter a valid phone number (up to 10 digits)",
            };
        },
        accountantEmail: (value, currState) => {
            value = value.trim();
            const isValid = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}(?:\.[A-Za-z]+)*$/.test(value);
            return {
                isValid,
                error: isValid ? null : "Please enter a valid email address",
                };
            },
      };
  
      return validationRules[field] ? validationRules[field](value) : { isValid: true, error: null };
  };

  const validateAllErrors = () =>{
    const errorsArray = Object.values(errors);
        const hasErrors = errorsArray.some(error => error !== null);

        const fieldsToAdd = ['accountantFirmName','accountantFirmAddress1','accountantFirmState','accountantFirmZipCode',
                             'accountantFirmCity','accountantFirmCountry','accountantName','accountantContactPhone','accountantEmail'];

        const trimmedValues = { ...props.data };

        const filteredValues = Object.keys(trimmedValues)
          .filter(field => fieldsToAdd.includes(field))
          .reduce((obj, key) => {
            obj[key] = trimmedValues[key];
            return obj;
          }, {});

         console.log(filteredValues);

        const Mandatory =  Object.values(filteredValues);

        const MandatoryFields = Mandatory.some(error => error === null || error === "");

        if (hasErrors || MandatoryFields) {
            secureLocalStorage.setItem("PCAccounting", true);
        } else {
            secureLocalStorage.setItem("PCAccounting", false);
        }
  }

    const MandatoryFieldErrors = (field, value) => {
        const fieldsToKeep = ['accountantFirmName','accountantFirmAddress1','accountantFirmState','accountantFirmZipCode',
        'accountantFirmCity','accountantFirmCountry','accountantName','accountantContactPhone','accountantEmail'];

        const trimmedValues = { ...props.data };

        const filteredFields = fieldsToKeep.map((field) => {
            if (trimmedValues.hasOwnProperty(field)) {
            return { field, value: trimmedValues[field] };
            }
            return null;
        }).filter(Boolean);
        const Mandatory = filteredFields.map(({ field, value }) => ({
            field,
            value: value === "" ? null : value,
        }));
        const EmptyFields = Mandatory.filter(entry => entry.value === "" || entry.value === null).map(entry => entry.field);
        const error = `This field is required.`;
        EmptyFields.map((item)=>{
            /*   */
            setErrors(prevErrors => ({ ...prevErrors, [item]: error }));
        })
    };

    useEffect(()=>{
         
    if(props.Onboardingsteperrors === true){
        MandatoryFieldErrors();
    }
},[props.Onboardingsteperrors])

useEffect(()=>{
    validateAllErrors();
},[])
    
      return (
        <div ref={componentRef}>
            <div className="fundRow">
                <div className="fundDetail">
                    <TextInput 
                        label="Name of Firm" 
                        name="accountantFirmName"
                        type="Onlytext"
                        required={true}
                        defaultValue={props?.data?.accountantFirmName} // Bind the value to the state
                        errormessage={errors.accountantFirmName || undefined}
                        onChange={(name, value)=>handleChange(name, value)}/>
                </div>
               
            </div>
            <div className="fundRow">
                <div className="fundDetail">
                <h11>Firm Address</h11>
                </div>
            </div>

            <div className="fundRow">
                <div className="fundDetail">
                    <TextInput 
                        label="Address Line1" 
                        name="accountantFirmAddress1"
                        type="limit"
                        required={true}
                        defaultValue={props?.data?.accountantFirmAddress1}
                        errormessage={errors.accountantFirmAddress1 || undefined}
                        editable={props.editable}
                        onChange={(name, value)=>handleChange(name, value)}/>
                </div>
                <div className="fundDetail">
                <TextInput 
                       type="select"
                        label="State" 
                        name="accountantFirmState"
                        required={true}
                        defaultValue={props?.data?.accountantFirmState}
                        errormessage={errors.accountantFirmState || undefined}
                        options={props.FiltersData?.stateProvince?.map(state => ({ label: state.title, value: state.stateProvinceId }))}
                        onChange={(name, value)=>handleChange(name, value)}/>
                </div>
            </div>
           
            <div className="fundRow">
                <div className="fundDetail">
                    <TextInput 
                        label="Address Line2" 
                        name="accountantFirmAddress2"
                        type="limit"
                        required={true}
                        defaultValue={props?.data?.accountantFirmAddress2}
                        errormessage={errors.accountantFirmAddress2 || undefined}
                        editable={props.editable}
                        onChange={(name, value)=>handleChange(name, value)}/>
                </div>
                <div className="fundDetail">
                    <TextInput 
                        label="Zip Code" 
                        name="accountantFirmZipCode"
                        type="number"
                        required={true}
                        defaultValue={props?.data?.accountantFirmZipCode}
                        editable={props.editable}
                        onChange={(name, value)=>handleChange(name, value)}
                        errormessage={errors.accountantFirmZipCode || undefined}
                        />
                </div>
            </div>
            <div className="fundRow">
                <div className="fundDetail">
                    <TextInput 
                        type="select"
                        label="City" 
                        name="accountantFirmCity"
                        required={true}
                        defaultValue={props?.data?.accountantFirmCity}
                        errormessage={errors.accountantFirmCity || undefined}
                        options={props.FiltersData?.city?.map(city => ({ label: city.title, value: city.cityId }))}
                        onChange={(name, value)=>handleChange(name, value)}/>
                </div>
                <div className="fundDetail">
                <TextInput 
                       type="select"
                        label="Country" 
                        name="accountantFirmCountry"
                        required={true}
                        defaultValue={props?.data?.accountantFirmCountry}
                        errormessage={errors.accountantFirmCountry || undefined}
                        options={props.FiltersData?.country?.map(country => ({ label: country.title, value: country.countryId }))}
                        onChange={(name, value)=>handleChange(name, value)}/>
                </div>
            </div>
            <div className="fundRow">
                <h11>Name of Accountant</h11>
            </div>
            <div className="fundRow">
                <div className="fundDetail">
                <TextInput 
                        label="First Name" 
                        name="accountantName"
                        type="Onlytext"
                        required={true}
                        defaultValue={props?.data?.accountantName}
                        errormessage={errors.accountantName || undefined}
                        editable={props.editable}
                        onChange={(name, value)=>handleChange(name, value)}/>
                </div>
                <div className="fundDetail">
                <TextInput 
                        label="Last Name" 
                        name="lastname"
                        type="Onlytext"
                        // required={true} //need to be implemented in future sprints
                        editable={props.editable}
                        onChange={(name, value)=>handleChange(name, value)}/>
                </div>
            </div>
            <div className="fundRow">
                <div className="fundDetail">
                <TextInput 
                        label="Phone Number" 
                        name="accountantContactPhone"
                        required={true}
                        defaultValue={props?.data?.accountantContactPhone}
                        editable={props.editable}
                        onChange={(name, value)=>handleChange(name, value)}
                        errormessage={errors.accountantContactPhone || undefined}
                        />
                </div>
                <div className="fundDetail">
                <TextInput 
                        label="Email" 
                        name="accountantEmail"
                        required={true}
                        defaultValue={props?.data?.accountantEmail}
                        editable={props.editable}
                        onChange={(name, value)=>handleChange(name, value)}
                        errormessage={errors.accountantEmail || undefined}
                        />
                </div>
            </div>
            <div className="fundRow">
                <div className="fundDetail">
                    <ActionButton 
                        variant="outlined"
                        label="ADD MORE" 
                        startIconName="ControlPointOutlined" 
                        onClick={()=>{}}
                        styleProps={{
                            borderColor: '#b7b7b7',
                            color: '#b7b7b7',
                            fontSize: '14px',
                        }}/>
                </div>
            </div>
            

            
            
        </div>
      );
}
export default Accounting;