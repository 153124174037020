import React, { useState } from 'react'
import propTypes from 'prop-types';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, InputLabel, ListItemText, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material';
import { FormLabel } from 'react-bootstrap';

const CheckBoxGroupField = (props) => {

  //#region props
  const {
    name,
    label,
    value,
    onChange,
    options,
    isBorder,
    readOnly,
    onBlur,
    required,
    error,
    errorMessage
  } = props;

  //#region change events
  const handleChange = (ev,itemID) => {
    const { checked } = ev.target;
    let newSelectedValues;
    if (checked) {
      newSelectedValues = value ? [...value, itemID] : [itemID];
    } else {
      newSelectedValues = value?.filter(item => item !== itemID);
    }
    onChange(name,newSelectedValues)
  };

  //#region return
  return (
    <FormControl>
      <FormLabel error={error} required={required}>{label}</FormLabel>
      <FormGroup
        name={name}
        value={value}
        onBlur={onBlur}
        onError={error}>
            <div className='child-margin-10'>
                {
                    options?.length > 0 ?
                    options.map((item)=>{
                        return(
                          <div key={item.value} className='display-row align-item-center border-padding-5'>
                              <FormControlLabel 
                                  control={ <Checkbox 
                                    checked={value?.includes(item?.value)}
                                    onChange={(ev) => handleChange(ev,item?.value)}
                                    disabled={readOnly} />} 
                                  label={item.label}
                                   />
                          </div>
                        )
                    }): ''
                }
            </div> 
      </FormGroup>
      <FormHelperText error={error}>{errorMessage}</FormHelperText>
    </FormControl>
  )
}

CheckBoxGroupField.propTypes = {
    type: propTypes.string,
    onChange: propTypes.func.isRequired,
    variant: propTypes.oneOf(['standard', 'filled', 'outlined']),
    required: propTypes.bool,
    value: propTypes.string,
    placeholder: propTypes.string,
    label: propTypes.string,
    disabled: propTypes.bool,
    options: propTypes.array,
    name: propTypes.string,
    size: propTypes.string,
    errorMessage: propTypes.string,
    isBorder: propTypes.bool
};

CheckBoxGroupField.defaultProps = {
    type: 'select',
    variant: 'standard',
    size: 'small',
    required: false,
    value: '',
    placeholder: '',
    label: '',
    options: [],
    disabled: false,
    isBorder: false,
    name: '',
    errorMessage: ''
};

export default CheckBoxGroupField;