import React, { useState , useEffect} from 'react'
import './NewOnboardingques/styles.css'
import TextInput from '../../common/TextInput/TextInput'
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import TopBarObq from './NewOnboardingques/TopBarObq';
import { checkUserNameExcists, updateUserNamewithInvestorTypes } from '../services/RegistrationProfilingService';
import { ToastContainer, toast } from 'react-toastify';
import { foulWords } from '../foulwords';
import { reservedWords } from '../foulwords';
import secureLocalStorage from 'react-secure-storage';
import ActionButton from '../../common/ActionButton/ActionButton';
import { getInvestorsTypes } from '../../InvestorRelations/Services/IRServices';
import { getSubInvestorsTypes } from '../../InvestorRelations/Services/IRServices';
import AppTextInput from '../../common/TextInput/AppTextField';
import { InputTypes,textFormatTypeEnum,validationsTypeEnum,validationMessages } from '../../common/TextInput/appInputenum';
import { InvestorTypes } from '../../../utils/enum';
import { InvestorTypesEnum } from '../../user-profiles/services/apiDataTemplate';
import { getrootBackgroundColorConfig } from '../../../state/slices/colorConfigSlice';
import { useSelector } from 'react-redux';
import SaveIcon from '@mui/icons-material/Save';

const UserName = () => {

  //#region Naviagtions
  const navigate = useNavigate();

  const isinitailInvestorType = secureLocalStorage.getItem('investorTypeID')?? 0;
  const userNameFromADB2C = secureLocalStorage.getItem("userName") ?? '';
  const initialSubType = secureLocalStorage.getItem("investorSubType") ?? 0;
  const investorTypeID = secureLocalStorage.getItem("investorTypeID") ?? 0;
  const firstName = secureLocalStorage.getItem("firstName") ?? "";
  const lastName = secureLocalStorage.getItem("lastName") ?? "";
  const currInvestorType = secureLocalStorage.getItem('investorType');

  //#region initialData
  const initialData = {
    username : userNameFromADB2C,
    investortypeID: investorTypeID,
    subInvestortypeID: initialSubType,
    firstName: firstName,
    lastName: lastName
  }

  //#region variables
  const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);
  const [userData,setUserData] = useState(initialData);
  const [nonsubTypeDetails,setNonsubTypeDetails] = useState(null);
  const [isnosubType,setNoSubTypeRequired] = useState(true);
  const [btnloading, setLoading] = useState(false);
  const [investorTypes,setInvestorTypes] = useState(null);
  const [subInvestorTypes,setSubInvestorTypes] = useState(null);

  //#region functions
  const currentInvestorType = () => {
    return investorTypes
            ?.find(item => item?.listItemID === userData.investortypeID)
            ?.listItemValue === InvestorTypesEnum.INDIVIDUAL_INVESTOR
  }

  //#region change events
  const handleChange = (field, value) => {
    if(field == 'investortypeID')
    {
      const investorType = investorTypes?.find(item => item.listItemID == value)?.listItemValue;
      secureLocalStorage.setItem('investorType', investorType);
      if(investorType != "Individual Investor"){
        userData["lastName"] = '';
      }
      if(nonsubTypeDetails?.find(item => item.listItemID === value)){
        setNoSubTypeRequired(true);
        userData[field] = value;
        userData["subInvestortypeID"] = '';
      }
      else{
        setNoSubTypeRequired(false);
        userData[field] = value;
      }
      getSubInvestorsType(value);
    }
    else{
      userData[field] = value;
    }
  }

  //#region click events
  const onSaveClick = () => {
    checkUserName();
  }

  //#region api get calls
  const getInvestorsType = async () => {
    const data = await getInvestorsTypes('InvestorType');
    if (data?.responseCode === 200) {
      setInvestorTypes(data?.responseData);
      const nonsubInvetsortypes = data?.responseData?.filter(item => item.listItemValue === InvestorTypes.INDIVIDUAL_INVESTOR);
      setNonsubTypeDetails(nonsubInvetsortypes);
      if(isinitailInvestorType)
      {
        userData["investortypeID"] = isinitailInvestorType;
        //filter for individual investor
         
        if(nonsubInvetsortypes.find(item => item.listItemID === isinitailInvestorType))
        {
          setNoSubTypeRequired(true);
        }
        else{
          setNoSubTypeRequired(false);
        }
        getSubInvestorsType(isinitailInvestorType);
      }
      else{
        setNoSubTypeRequired(false);
      }
    } else {
      // Handle error if needed
    }
  }

  const getSubInvestorsType = async (typeID) => {
    const data = await getSubInvestorsTypes(typeID);
    if (data?.responseCode === 200) {
      setSubInvestorTypes(data?.responseData);
      userData["subInvestortypeID"] = '';
    } else {
      // Handle error if needed
    }
  }

  //#region useEffect
  useEffect(() => {
    getInvestorsType();
  },[]);

  //#region api update calls
  const updateUserNameWithInvestor = async () => {
    const isbulkUploadUser = secureLocalStorage.getItem('IsBulkUploadUser'); 
    const requestedBody = {
      "userId": secureLocalStorage.getItem("userId"),
      "userFirstName": userData.firstName,
      "userLastName": userData.lastName,
      "userEmailAddress": secureLocalStorage.getItem("userEmail"),
      "userName": userData.username,
      "investorTypeID": userData.investortypeID,
      "investorSubTypeID": userData.subInvestortypeID || 0
    }
    const data = await updateUserNamewithInvestorTypes(requestedBody);
    if (data?.responseCode == 200) {
        if(isbulkUploadUser)
        {
          navigate('/bulk-upload-invite');
        }
        else{
          const investorTypeName = investorTypes?.find(item => item.listItemID == userData.investortypeID)?.listItemValue;
          secureLocalStorage.setItem('investorType',investorTypeName);
          secureLocalStorage.setItem('investorTypeID',userData.investortypeID)
          secureLocalStorage.setItem('investorSubType',userData.subInvestortypeID)
          secureLocalStorage.setItem("userName",userData.username)
          navigate('/onboarding-process');
        }
        setLoading(false);
    } else {
      toast.warning("Username already exists",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      setLoading(false);
    }
  }

  const checkUserName = async () => {
    setLoading(true);
    if(userNameFromADB2C){
      updateUserNameWithInvestor();
    }
    else{
      const userCheck = await checkUserNameExcists(userData.username);
      if (userCheck?.responseCode == 200) {
        toast.warning("Username already exists",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        setLoading(false);
      } 
      else {
        updateUserNameWithInvestor();
      }
    }
  }
  
  //#region validations
  const [noofValidFields] = useState({validfields : 0});

  const [checkIfValid, setcheckIfValid] = useState(false);

  const checknoofValidFields = (isValid) => {
      let TotalFiledsToValidate = isnosubType ? 
                                  currInvestorType === InvestorTypesEnum.INDIVIDUAL_INVESTOR ? 4 : 3
                                  : (currInvestorType === InvestorTypesEnum.INDIVIDUAL_INVESTOR ? 4 : 3) + 1;
      if(userNameFromADB2C){
        TotalFiledsToValidate = TotalFiledsToValidate - 1;
      }
      noofValidFields['validfields'] = isValid ? noofValidFields['validfields'] + 1 : noofValidFields['validfields'];
      if (noofValidFields['validfields'] === TotalFiledsToValidate) {
          noofValidFields['validfields'] = 0;
          onSaveClick();
         /* alert('All fields are valid'); */
      }
  }

  const handleSave = () => {
    setcheckIfValid(true);
    noofValidFields['validfields'] = 0;
  }
  
  return (
       <div style={{ backgroundColor: rootBackColorConfig.color }} className='investor-onboardbg'>
          <TopBarObq position="sticky" hideMenuButton={true}/>
          <div  className='centerFlex'>
              <div className='obqquestionName'>
                <h1 className='welcome-msg heading'>
                    Welcome. Lets get started !
                </h1>
                <div className='welcome-desc'>
                We just need a little information, so that We can customize your experience.
                </div>
              </div>
              <div className='obqoptions'>
                  <div>
                    <AppTextInput
                        name="username"
                        label="User Name"
                        defaultValue={userData?.username}
                        onChange={(name, value) => handleChange(name, value)}
                        validateField={userNameFromADB2C ? false : true}
                        validationType={validationsTypeEnum.USER_NAME}
                        validationMessage={validationMessages.USER_NAME}
                        checkIfValid={userNameFromADB2C ? false : checkIfValid}
                        checknoofValidFields={checknoofValidFields}
                        disabled={userNameFromADB2C ? true : false}
                        setcheckIfValid={setcheckIfValid} />
                  </div>
                  {
                    investorTypes && 
                    <div className='margin-top-10'>
                      <AppTextInput
                          type={InputTypes.SELECT}
                          name="investortypeID"
                          label="Investor Type"
                          defaultValue={userData?.investortypeID || ''}
                          options={investorTypes?.map(item => ({ value: item.listItemID, label: item.listItemValue })) || []}
                          onChange={(name, value) => handleChange(name, value)}
                          /* disabled={investorTypes && isinitailInvestorType ? true : false} */
                          validateField={true}
                          validationType={validationsTypeEnum.REQUIRED}
                          validationMessage={validationMessages.REQUIRED}
                          checkIfValid={checkIfValid}
                          checknoofValidFields={checknoofValidFields}
                          setcheckIfValid={setcheckIfValid} />
                    </div>
                  }
                  {
                    (userData?.investortypeID !== 0 && investorTypes
                      ?.find(item => item?.listItemID === userData.investortypeID)
                      ?.listItemValue !== InvestorTypesEnum.INDIVIDUAL_INVESTOR) &&
                    <div className='margin-top-10'>
                      <AppTextInput
                          type={InputTypes.SELECT}
                          name="subInvestortypeID"
                          label="Sub Investor Type"
                          defaultValue={userData?.subInvestortypeID || ''}
                          options={subInvestorTypes?.map(item => ({ value: item.listItemID, label: item.listItemValue })) || []}
                          onChange={(name, value) => handleChange(name, value)}
                          validateField={true}
                          validationType={validationsTypeEnum.REQUIRED}
                          validationMessage={validationMessages.REQUIRED}
                          checkIfValid={checkIfValid}
                          checknoofValidFields={checknoofValidFields}
                          setcheckIfValid={setcheckIfValid} />
                  </div>
                  }
                  {
                    userData?.investortypeID !== 0 && 
                    <>
                      <div className='margin-top-10'>
                        <AppTextInput
                            textFormatType=
                            {
                              currentInvestorType() ? 
                              textFormatTypeEnum.ONLY_ALPHABETS : textFormatTypeEnum.REQUIRED
                            }
                            name="firstName"
                            label={
                              investorTypes
                              ?.find(item => item?.listItemID === userData.investortypeID)
                              ?.listItemValue === InvestorTypesEnum.INDIVIDUAL_INVESTOR ? 
                              "First Name" : "Legal Entity Name"
                            }
                            defaultValue={userData?.firstName}
                            onChange={(name, value) => handleChange(name, value)}
                            validateField={true}
                            validationType={
                              currentInvestorType() ? 
                              textFormatTypeEnum.ONLY_ALPHABETS : textFormatTypeEnum.REQUIRED
                            }
                            validationMessage={
                              currentInvestorType() ? 
                              validationMessages.ONLY_ALPHABETS : validationMessages.REQUIRED
                            }
                            checkIfValid={checkIfValid}
                            checknoofValidFields={checknoofValidFields}
                            setcheckIfValid={setcheckIfValid} />
                      </div>
                      {
                        investorTypes
                        ?.find(item => item?.listItemID === userData.investortypeID)
                        ?.listItemValue === InvestorTypesEnum.INDIVIDUAL_INVESTOR  &&
                        <div className='margin-top-10'> 
                          <AppTextInput
                              textFormatType={textFormatTypeEnum.ONLY_ALPHABETS}
                              name="lastName"
                              label="Last Name"
                              defaultValue={userData?.lastName}
                              onChange={(name, value) => handleChange(name, value)}
                              validateField={true}
                              validationType={validationsTypeEnum.ONLY_ALPHABETS}
                              validationMessage={validationMessages.ONLY_ALPHABETS}
                              checkIfValid={checkIfValid}
                              checknoofValidFields={checknoofValidFields}
                              setcheckIfValid={setcheckIfValid} />
                        </div>
                      }
                    </>
                  }
              </div>
              <div className="obqquestionName">
                      <ActionButton
                        component="label"
                        variant="contained"
                        label="Save"
                        icon={<SaveIcon />}
                        styleProps={{ 
                          padding: '8px 36px' 
                        }} 
                        loading={btnloading}
                        onClick={handleSave}/>
              </div>
          </div>  
          <ToastContainer />
        </div>
  )
}

export default UserName