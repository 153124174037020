import React from 'react'
import ActionButton from '../../../common/ActionButton/ActionButton'
import AddIcon from '@mui/icons-material/Add';
import { FieldVariants } from '../../../common/TextInput/appInputenum';

const Redemption = () => {
  return (
    <div>
        <div className='space-between'>
            <h4>Redemption</h4>
            <div>
                <ActionButton 
                label="ADD REDEMPTION"
                variant={FieldVariants.OUTLINED}
                icon={<AddIcon />}
                onClick={()=>{}}/>
            </div>
        </div>
         <h6 className='margin-top-70 align-items-center heading color-gray'>
           PAGE IS UNDER CONSTRUCTION
         </h6>
    </div>
  )
}

export default Redemption