import "./NavigationBar.css";
import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../../AppAuthentication/authConfig";
import { checkFeaturePermissions } from "../../../utils/common";
import { AppDashboards, AppRoles, FeatureAccess, RolesAndPermissions } from "../../../utils/enum";
import RemovePopup from "../../marketplace/components/RemovePopup";
import secureLocalStorage from "react-secure-storage";

const NavigationBar = ({ isExpanded, setExpandState, onTextChange, currentpage }) => {
	const navigate = useNavigate();
	const msalInstance = new PublicClientApplication(msalConfig);

	const [showMenu, setShowMenu] = useState(false);
	const [showPopup, setShowPopup] = useState(false);
	const emailId = secureLocalStorage.getItem("userEmail");

	const handleConfirmRemove = () => {
		secureLocalStorage.setItem("fullName","");
        msalInstance.logoutRedirect({ postLogoutRedirectUri: "/?login_page=true" });
		setShowPopup(false);
	  };
	
	  const handleCancelRemove = () => {
		// Close the popup without removing the item
		setShowPopup(false);
		setShowMenu(!showMenu);
	  };

	const manageaccountclick = () => {
		secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD
			&& navigate("/user-profile");
	};

	const [investorProfile, setInvestorProfile] = useState({});
	const [profileIcon, setProfileIcon] = useState('');
	const [fullName, setFullName] = useState('');


	
	const handleLogout = () => {
		// Perform logout functionality help&support​​click
		secureLocalStorage.clear();
		msalInstance.logoutRedirect({ postLogoutRedirectUri: "/?login_page=true" });
	  };


	const handleMenuToggle = () => {
		setShowMenu(!showMenu);
	};

	const handleHelpAndSupport = () => {
		console.log('Help & Support');
		setShowMenu(false);
		navigate('/helpAndsupport');
	};

	const handleManageAccounts = () => {
		// Perform manage accounts functionality
		console.log('Manage Accounts');
		setShowMenu(false);
	};

	const [menuItems, setMenuItems] = useState([
		{
			id: 1,
			text: "Home",
			icon: "icons/sidebar/home-icon.svg",
			hovericon: "icons/sidebar/home-icon-hover.svg",
		},
		{
			id: 2,
			text: "marketplace",
			icon: "icons/sidebar/marketplace-icon.svg",
			hovericon: "icons/sidebar/marketplace-hover.svg",
		},
		{
			id: 3,
			text: "notifications",
			icon: "icons/sidebar/notifications-icon.svg",
			hovericon: "icons/sidebar/notifications-icon-hover.svg",
		},
		{
			id: 4,
			text: "tasks",
			icon: "icons/sidebar/task-icon.svg",
			hovericon: "icons/sidebar/task-icon-hover.svg",
		},
		{
			id: 5,
			text: "Suggested Readings",
			icon: "icons/sidebar/news-icon.png",
			hovericon: "icons/sidebar/news-icon-hover.png",
		},
		{
			id: 6,
			text: "Documents",
			icon: "icons/sidebar/documents-icon.svg",
			hovericon: "icons/sidebar/documents-icon-hover.svg",
		},
		{
			id: 7,
			text: "account",
			icon: "icons/sidebar/account-icon.svg",
			hovericon: "icons/sidebar/account-icon-hover.svg",
		}

	]);
  useEffect(() => {
    if (!checkFeaturePermissions(RolesAndPermissions.MARKET_PLACE, FeatureAccess.READ)) {
      // Filter out the 'marketplace' item if the condition is satisfied
      const updatedMenuItems = menuItems.filter(
        (item) => item.text !== "marketplace"
      );
      setMenuItems(updatedMenuItems);
    }
  }, [menuItems, setMenuItems]); // Include menuItems and setMenuItems in the dependency array



	const menuItems1 = [
		{
			id: 8,
			text: "settings",
			icon: "icons/sidebar/settings-icon.svg",
			hovericon: "icons/sidebar/settings-icon-hover.svg",
		},
		{
			id: 9,
			text: "logout",
			icon: "icons/sidebar/logout-icon.svg",
			hovericon: "icons/sidebar/logout-icon-hover.svg",
		},
	];

	const [hoveredItem, setHoveredItem] = useState(null);


	const [activeitem, setActiveitem] = useState(currentpage ?? 0);

	/* console.log(activeitem);
 */
	const handleMenuItemClick = (id) => {
		setActiveitem(id);
		console.clear();
		secureLocalStorage.setItem("currentpage", id);
		
		if (id === 2) {
			navigate("/marketplace");
		}
		else if (id === 1) {
			console.clear();
			console.log(secureLocalStorage.getItem("userrole"));
			// TODO : This code needs to be removed and refactored
			secureLocalStorage.getItem("userrole") === AppDashboards.FIRM_DASHBOARD ?
				navigate("/firm-wide-dashboard") : (
					secureLocalStorage.getItem("userrole") === AppDashboards.CO_DASHBOARD ?
						navigate("/CoDashBoard") : (
							secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD ?
								  navigate("/Investor-Dashboard") : (
                  secureLocalStorage.getItem("userrole") === AppDashboards.PC_DASHBOARD ?
								  (investorProfile?.personalInformation?.currentOnboardingStep == 5
                    ? navigate("/PortfolioOnboarding")
                    : navigate("/portfolio-company-overview"))
								: navigate("/FundManager-Dashboard"))))
		} else if (id === 5){
			secureLocalStorage.getItem("userrole") === AppDashboards.PC_DASHBOARD?
			 navigate("") : (
				  secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD
							? navigate("/")
							: navigate("/suggested-readings"))
		}
		else{

		}
	};

	return (
		<div>
			<div
				className={
					isExpanded
						? "side-nav-container"
						: "side-nav-container side-nav-container-NX"
				}
			>
				<div className="nav-upper">
					<div className="nav-heading">
						{isExpanded && (
							<div className="nav-brand">

							</div>
						)}
						<button
							className={
								isExpanded ? "hamburger hamburger-in" : "hamburger hamburger-out"
							}
							onClick={() => setExpandState(!isExpanded)}
						>
							<img src={isExpanded ? 'icons/Closed-logo-Extended.svg' : 'icons/Closed-logo.svg'} alt="" />
						</button>
					</div>
					
					<div className="nav-menu">
						
						{menuItems?.map(({ id, text, icon, hovericon }) => (
							<a
								className={isExpanded ? id === activeitem
									? "menu-item-EX"
									: "menu-item " : id === activeitem
									? "menu-item-active"
									: "menu-item menu-item-NX"}
								href="#"
								onClick={() => {
									handleMenuItemClick(id);
								}}
								key={id}
								onMouseEnter={() => setHoveredItem(id)}
								onMouseLeave={() => setHoveredItem(null)}
							>
								<div className="menu-item-icon">
									<div>
										<img
											src={
												(hoveredItem === id || activeitem === id) ? hovericon : icon
											}
										/>
									</div>
								</div>
								{isExpanded && <p className="menu-item-text">{text}</p>}
								{!isExpanded && (
									<span className="menu-item-hover-text"><span className="hover-item-name">{text}</span></span>
								)}
							</a>
						))}
					</div>
				</div>
				<div className="nav-footer">
					<div className="nav-menu">
						{menuItems1?.map(({ id, text, icon, hovericon }) => (
							<a
								className={isExpanded ? "menu-item" : "menu-item menu-item-NX"}
								href="#"
								key={id}
								onClick={() => {
									if (id === 8) {
									
									}
									else {
										
										setShowPopup(true);
									}
								}}
								onMouseEnter={() => setHoveredItem(id)}
								onMouseLeave={() => setHoveredItem(null)}
							>
								<div className="menu-item-icon">
									<div>
										<img
											src={
												(hoveredItem === id) ? hovericon : icon
											}

										/>
									</div>
								</div>
								{isExpanded && <p className="menu-item-text">{text}</p>}
								{!isExpanded && (
									<span className="menu-item-hover-text"><span className="hover-item-name">{text}</span></span>
								)}
							</a>
						))}
					</div>
				</div>




				{/*TOP SIDE WITH APP LOGO AND SEARCH BUTTON*/}
				<div className={
					isExpanded
						? "navbarbgParent navbarbgParent-NX"
						: "navbarbgParent"
				}>
					<div>
						<div className="navbarbgIcon"></div>
						<img className="userMenuIcon" alt="" src={profileIcon} onClick={handleMenuToggle} />
						{showMenu && (						
							<div className="whiteBox">
								<div className="menuOption name" onClick={handleManageAccounts}>{fullName}</div>
								<p>{investorProfile?.personalInformation?.emailAddress}</p>
								<hr className="line" />
								{checkFeaturePermissions(RolesAndPermissions.INVESTOR_PROFILE, FeatureAccess.READ) 
								&& (
								<div className="menuOption" onClick={manageaccountclick}>
									<img
										className="manageAccountsIcon"
										alt=""
										src="https://static.thenounproject.com/png/1635595-200.png"
									/>
									MANAGE ACCOUNT</div> 
									)}
									<div ></div> 
								<div className="menuOption" onClick={handleHelpAndSupport}> 
                                    <img
										className="HelpSupport​​Icon"
										alt=""
										src="https://visualpharm.com/assets/661/Help-595b40b65ba036ed117d1dad.svg"
									/>
									HELP & SUPPORT   ​​</div>    <div ></div> 
								<div className="menuOption" onClick={handleLogout}>
									<img
										className="LogoutIcon"
										alt=""
										src="https://cdn-icons-png.flaticon.com/512/1286/1286853.png"
									/>
									LOG OUT
								</div>
							</div>
						
						)}

					</div>
						
					{/* This is for AIPPOC */}
					<img className="groupChild" alt="" src="/bluehublab-logo.png" />
					{/* This is for AIPDEMO */}
					{/* <img className="groupChildAIPDEMO1" alt="" src="/AIPDEMO.png" /> */}
					<div className="groupParent1">
						<div className="mediumCardBg">
							<div className="mediumCardBg">
								<div className="alternativeInvestmentPlatforContainer">
									Alternative Investment Platform
								</div>
							</div>
						</div>


					</div>
					
				</div>

			</div>
			{showPopup && (
				<RemovePopup
				onConfirm={handleConfirmRemove}
				onCancel={handleCancelRemove}
				message={"Are You Sure You Want to Logout?"}
				Buttonmsg={"LogOut"}
				leftValue={"186px"}
				/>
      )}
		</div>
	)
}
export default NavigationBar;
