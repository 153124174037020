export const FundAddTypes = {
    FAVOURITE : 'favourite',
    COMPARE : 'compare'
}

export const FiltersTypes = {
    CHECKBOX : 'checkboxes',
    DROP_DOWN : 'dropdown',
    RADIO_GROUP : 'radiogroup',
    TEXT_FIELD : 'textfields',
    SLIDER : 'slider'
}

export const SlideFilterValues = {
    TERM_MIN_VALUE : 1,
    TERM_MAX_VALUE : 10,
    INVEST_MIN_VALUE : 1,
    INVEST_MAX_VALUE : 10000000,
}