import React from 'react'
import { Grid } from "@mui/material";
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import TextInput from '../../../common/TextInput/TextInput';
import userprofilestyles from "../../../user-profile/userprofile1.module.css"
import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { getInvestorDetails } from '../../InvestorUserProfile/Services/Investorinfo';
import { UpdateProfileInformation } from '../../InvestorUserProfile/Services/Investorinfo';
import { getOTPAttemptsIn24Hours, updateUserProfileIconData } from '../../services/UserProfileService';
import { useSelector } from 'react-redux';
import { getrootBackgroundColorConfig } from '../../../../state/slices/colorConfigSlice';
import secureLocalStorage from 'react-secure-storage';
import ReactLoading from 'react-loading';
import FirstComponent from '../../../common/DatePicker/DatePicker';
import moment from 'moment';
import CancelIcon from '@mui/icons-material/Cancel';
import { useDispatch } from 'react-redux';
import { updateProfileIcon } from '../../../../state/slices/userDetailsSlice';
import { Typography } from "@mui/material";
import ActionButton from '../../../common/ActionButton/ActionButton';
import OTPVerificationDialog from '../../../common/OTPVerification/OtpVerification';
import { width } from '@mui/system';
import { getOtpID } from '../../services/UserProfileService';
import { updateUserData } from '../../../../state/slices/userDetailsSlice';
import FileUploadButton from '../../../common/FileUploadButton/FileUploadButton';
import { OtpType } from '../../../common/OTPVerification/OtpEnum';

const FirmUserProfile = (props) => {
  const [selectedImage, setSelectedImage] = useState("/UserProfile/avatar@2x.png");
  const [loading, setLoading] = useState(false);
  const [initialImage, setInitialImage] = useState(null);
  const [verifybtn, setVerifybtn] = useState(false);
  const [InvestorProfile, setInvestorProfile] = useState(null);
  const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);
  const [isSaveEnabled, setSaveEnabled] = useState(false);
  const [errors, setErrors] = useState({});
  const [OtpData, setOtpData] = useState(null)
  const [openOTPDialog, setOpenOTPDialog] = useState(false);
  const [otpAttemptsLeft, setOTPAttemptsLeft] = useState(0);
  const UpdatedEmailAdress = InvestorProfile?.personalInformation?.emailAddress
  const handleOpenOTPDialog = () => {
    setOpenOTPDialog(true);
  };

  const otpAttemptsIn24Hours = async () => {
    const data = await getOTPAttemptsIn24Hours();
    if (data?.responseCode === 200) {
      setOTPAttemptsLeft(data?.responseData);
    }
    else {
      setOTPAttemptsLeft(0);
    }
  }
  useEffect(() => {
    otpAttemptsIn24Hours();
  }, [])

  const handleCloseOTPDialog = () => {
    closeOTPDialog();
    if (verifybtn) {
      setSelectedImage(initialImage);
      props.setRefreshSignal(prevSignal => !prevSignal);
      setSaveEnabled(false);
      props.setisSaveEnabled(false);
    }
  }
  const closeOTPDialog = () => {
    setLoading(false);
    setOpenOTPDialog(false);
  };
  const getOtpData = async () => {
    // Validating Email first
    const emailCheck = await getInvestorDetails(UpdatedEmailAdress);
    if (emailCheck?.error) {
      toast.warning('Please wait a moment',
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      const data = await getOtpID(UpdatedEmailAdress);
      if (data?.responseCode == 200) {
        toast.success(`A verification code has been sent to your new email address at ${UpdatedEmailAdress}. Please enter the code below to save the new email address.`,
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        otpAttemptsIn24Hours();
        setOpenOTPDialog(true);
        setOtpData(data?.responseData);
        setLoading(false);
      } else {
        toast.warning(data?.responseData,
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        closeOTPDialog();
        setLoading(false);
      }
    } else {
      setLoading(false);
      toast.error('Email already exists',
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      closeOTPDialog();
    }
  }

  const dispatch = useDispatch();

  const validateDate = (dateString) => {
    const currentDate = moment(); // Current date
    const selectedDate = moment(dateString);

    // Add your date validation logic here
    const isDateValid = selectedDate.isValid() && selectedDate.diff(currentDate, 'years') >= -100 && selectedDate.isSameOrBefore(currentDate);

    return isDateValid;
  };
  const onEditSaveButtonClick = async () => {
    if (verifybtn) { otpAttemptsIn24Hours(); }
    if (isSaveEnabled) {
      // If there are errors, prevent saving
      if (Object.values(errors).some((error) => error !== null)) {
        toast.warning("Please fill Mandatory Fields", {
          position: toast.POSITION.TOP_RIGHT,
          theme: "colored",
        });
      } else {
        await handleUpdateProfileInfo();
      }
    } else {
      setSaveEnabled((prevSaveEnabled) => !prevSaveEnabled);
      props.setisSaveEnabled((prevSaveEnabled) => !prevSaveEnabled);
    }
  };
  const onCancel = () => {
    setVerifybtn(false);
    if (!isSaveEnabled) {
      return;
    }
    setSelectedImage(initialImage);
    props.setRefreshSignal(prevSignal => !prevSignal);
    setSaveEnabled(false);
    props.setisSaveEnabled(false);
  };
  useEffect(() => {
    getFMUserInfo();
  }, []);


  const getFMUserInfo = async () => {
    const emailId = secureLocalStorage.getItem("userEmail");
    const data = await getInvestorDetails(emailId);
    if (data.responseCode === 200) {
      console.log(data.responseData);
      setInvestorProfile(data.responseData);
      setSelectedImage(data.responseData?.personalInformation?.profileIcon);
      setInitialImage(data.responseData?.personalInformation?.profileIcon);
      dispatch(updateProfileIcon({ value: data.responseData?.personalInformation?.profileIcon }));
    } else {
    }
  }

  const handleUpdateProfileInfo = async () => {
    setLoading(true);
    try {
      const requestData = {
        personalInformation: {
          ...InvestorProfile.personalInformation,
          phoneNo: InvestorProfile?.personalInformation?.phoneNo,
          "tenantGUID": secureLocalStorage.getItem('tenantID')
        },
      };
      const validateMandatoryFields = () => {
        const mandatoryFields = ['firstName', 'lastName', 'phoneNo', 'briefDescription', 'emailAddress'];
        const missingFields = mandatoryFields.filter((field) => !InvestorProfile?.personalInformation[field]);
        console.log('Missing Fields:', missingFields);
        return missingFields.length === 0;
      };

      if (!validateMandatoryFields()) {
        toast.warning("Please fill Mandatory Fields", {
          position: toast.POSITION.TOP_RIGHT,
          theme: "colored",
        });
      } else {
        setSaveEnabled(isSaveEnabled => !isSaveEnabled);
        props.setisSaveEnabled(isSaveEnabled => !isSaveEnabled);
        const fullName = `${InvestorProfile.personalInformation.firstName} ${InvestorProfile.personalInformation.lastName}`
        dispatch(updateUserData({ field: "fullName", value: fullName }));

        /* dispatch(updateProfileIcon({ value: selectedImage })) */
        const result = await UpdateProfileInformation(requestData, InvestorProfile?.personalInformation?.userId);
        if (result.responseCode === 200) {
          /* props.setData(result.data); */
          /*   */
          if (initialImage !== selectedImage) {
            const formDataBody = new FormData();
            formDataBody.append("file", selectedImage);
            const imgResponse = await updateUserProfileIconData(formDataBody, InvestorProfile?.personalInformation?.emailAddress);
            getFMUserInfo();
          }
          if (verifybtn) {
            if (otpAttemptsLeft > 0) {
              getOtpData();
            }
            else {
              /*  props.setRefreshSignal(prevSignal => !prevSignal); */
              toast.warning('We were unable to verify your new email address. All changes except the email have been saved. You can retry changing your email address after 24 hours.',
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
              setLoading(false);
            }
          }
          else {
            setLoading(false);
            toast.success("Successfully Saved", {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: "colored",
            });
          }
        } else {
          toast.error("Unable to Save the details", {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: "colored",
          });
          setLoading(false);
        }
      }
    } catch (error) {
      toast.error("Unable to Save the details", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
      setLoading(false);
    }
  };

  const initialmail = InvestorProfile?.personalInformation?.emailAddress
  /*   const [originalEmailAddress, setOriginalEmailAddress] = useState('');
  
    useEffect(() => {
      if (InvestorProfile) {
        setOriginalEmailAddress(InvestorProfile.personalInformation.emailAddress);
      }
    }, [InvestorProfile]);
   */


  const handleChange = (field, value) => {
    setInvestorProfile((prevProfile) => ({
      ...prevProfile,
      personalInformation: {
        ...(prevProfile?.personalInformation ?? {}),
        [field]: value,
      },
    }));

    const validationResult = validateField(field, value);
    if (field === "emailAddress") {

      const btn = validationResult.isValid && value?.toLowerCase() !== secureLocalStorage.getItem("userEmail")?.toLowerCase() ? true : false;
      setVerifybtn(btn);
    }
    if (!validationResult.isValid) {
      setErrors((prevErrors) => ({ ...prevErrors, [field]: validationResult.error }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [field]: null }));
    }
  };

  const validateField = (field, value) => {
    const validationRules = {
      firstName: (value, currState) => {
        const isValid = /^[A-Za-z]+$/.test(value.trim());
        return {
          isValid,
          error: isValid ? null : 'Please enter a valid first name (only alphabets)'
        };
      },

      lastName: (value, currState) => {
        const isValid = /^[A-Za-z]+$/.test(value.trim());
        return {
          isValid,
          error: isValid ? null : 'Please enter a valid last name (only alphabets)'
        };
      },
      // other validation rules...
      phoneNo: (value, currState) => {
        value = value.trim();
        const isValid = /^\d{10}$/.test(value);
        return {
          isValid,
          error: isValid ? null : "Please enter a valid phone number (up to 10 digits)",
        };
      },
      emailAddress: (value, currState) => {
        const isValid = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value);
        return {
          isValid,
          error: isValid ? null : 'Please enter a valid email address',
        };
      },
      briefDescription: (value, currState) => {
        const isEmpty = value.trim() === '';
        return {
          isValid: !isEmpty,
          error: isEmpty ? 'This field is required' : null,
        };
      },
    };
    return validationRules[field] ? validationRules[field](value) : { isValid: true, error: null };


  };


  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    console.log(URL.createObjectURL(file));
    // Process the file and update the selectedImage state
    setSelectedImage(URL.createObjectURL(file));
    const formDataBody = new FormData();
    formDataBody.append("file", file);
    await updateUserProfileIconData(formDataBody, InvestorProfile?.personalInformation?.emailAddress);
  };

  const handleFileUpload = async (name, file) => {
    if (file) {
      setSelectedImage(file);
    }
    else {
      setSelectedImage("");
    }
  }


  return (
    <div>
      {InvestorProfile ? (
        <div className='' >
          <Grid item xs={12} marginTop={3}>
            <div className='space-between'>
              <div className="heading">
                {/* <h1>User Profile </h1>  */}
              </div>
              <div>
                <div className='space-between'>
                  <div style={{ marginRight: '10px' }}>
                    {isSaveEnabled &&
                      <ActionButton className='btn-primary'
                        // style={{backgroundColor: 'lightgray'}}
                        icon={<CancelIcon />}
                        onClick={() => onCancel()}
                        variant='outlined'
                        label={"Cancel"}
                      >
                      </ActionButton>
                    }
                  </div>
                  <div>
                    <ActionButton className='btn-primary'
                      icon={isSaveEnabled ? <SaveIcon /> : <EditIcon />}
                      onClick={() => onEditSaveButtonClick()}
                      variant='contained'
                      label={isSaveEnabled ? "Save" : "Edit"}
                      loading={loading}
                    >
                    </ActionButton>

                  </div>
                </div>
              </div>
            </div>

          </Grid>
          <Box marginTop={2} sx={{ padding: '25px' }} className={isSaveEnabled ? "background-color-white" : "background-color-white disablediv"}>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12} sm={4} md={4} lg={4} display={'flex'}>
                {/* <label className={userprofilestyles.avatarLabel}>
                  <input
                    type="file"
                    accept="image/*"
                    className={userprofilestyles.avatarInput}
                    onChange={handleImageUpload}
                    style={{ display: 'none' }}
                  />
                  <img
                    className={userprofilestyles.avatarIcons}
                    alt=""
                    src={selectedImage ? selectedImage : '/icons/defaultpropic.jpg'}
                  />
                  <div className={userprofilestyles.avatarText}>EDIT</div>
                </label> */}
                <FileUploadButton
                  name="Image"
                  // label={<span className="requiredField">UPLOAD LOGO</span>}
                  label="UPLOAD PROFILE ICON"
                  defaultFile={initialImage ? selectedImage : '/icons/defaultpropic.jpg'}
                  isImage={true}
                  disabled={isSaveEnabled ? false : true}
                  fromWhiteLabeling={isSaveEnabled ? true : false}
                  isSize={true}
                  onChange={(name, value) => handleFileUpload(name, value)}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <TextInput
                  name="firstName"
                  defaultValue={InvestorProfile?.personalInformation?.firstName}
                  label="First Name"
                  required={true}
                  onChange={(name, value) => handleChange(name, value)}
                  errormessage={errors.firstName || undefined}

                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <TextInput
                  name="lastName"
                  defaultValue={InvestorProfile?.personalInformation?.lastName}
                  label="Last Name"
                  required={true}
                  onChange={(name, value) => handleChange(name, value)}
                  errormessage={errors.lastName || undefined}
                />
              </Grid>
            </Grid>
            {/* <Grid container xs={12} spacing={2} marginTop={2}>
              <ActionButton label="Request OTP" disabled={!verifybtn} onClick={getOtpData} />
            </Grid> */}
            <Grid container xs={12} spacing={2} marginTop={2}>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <TextInput
                  name="emailAddress"
                  defaultValue={InvestorProfile?.personalInformation?.emailAddress}
                  label="Email Address"
                  required={true}
                  errormessage={errors.emailAddress || undefined}
                  onChange={(name, value) => handleChange(name, value)}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <TextInput
                  name="phoneNo"
                  defaultValue={InvestorProfile?.personalInformation?.phoneNo}
                  label="Phone Number"
                  type='phoneNumber'
                  symbol=""
                  charactersMaxLength={10}
                  required={true}
                  onChange={(name, value) => handleChange(name, value)}
                  errormessage={errors.phoneNo || undefined}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} style={{ marginTop: '-4px' }}>
                <FirstComponent
                  fieldLabel="Date Of Birth"
                  fieldName="dateOfBirth"
                  variant='standard'
                  required={true}
                  applyMaxDateConstraint={true}
                  applyMinDateConstraint={true}
                  applyFutureDateValidation={true}
                  applyYesterdayConstraint={true}
                  isSaveEnabled={true}
                  defaultValue={(InvestorProfile?.personalInformation?.dateOfBirth).includes('1900') ? null : (InvestorProfile?.personalInformation?.dateOfBirth)}
                  onhandleChange={(fieldName, value) => {
                    handleChange(fieldName, value);
                  }}
                  errormessage={errors.dateOfBirth || undefined}

                />
              </Grid>
            </Grid>
            <Grid container xs={12} marginTop={4}>

              <Grid item xs={12}>
                <TextInput
                  name="briefDescription"
                  label="Description"
                  multiline
                  placeholder={`Please provide a brief, third-person description of yourself, highlighting your relevant experience and qualifications. This information will be displayed on your profile and on the fund page for investors to learn more about you.\n\nExample: John Smith, Portfolio Manager: John has over 10 years of experience in the financial industry, specializing in equity investment analysis. He holds a Master's degree in Finance from [University name] and is a CFA charterholder.`}
                  required
                  defaultValue={InvestorProfile?.personalInformation?.briefDescription}
                  onChange={(name, value) => handleChange(name, value)}
                  errormessage={errors.briefDescription || undefined}
                  rows={5}
                />
              </Grid>
            </Grid>
          </Box>
          <OTPVerificationDialog
            open={openOTPDialog}
            onClose={handleCloseOTPDialog}
            type={OtpType.EMAIL_ADDRESS}
            OtpData={OtpData}
            UpdatedEmailAdress={UpdatedEmailAdress}
            getOtpData={getOtpData}
            otpAttemptsLeft={otpAttemptsLeft}
            getotpAttemptsIn24Hours={otpAttemptsIn24Hours} />
          <ToastContainer />
        </div>
      ) : (
        <div className="loading-container">
          <ReactLoading type="spin" color="#0000FF" height={100} width={100} />
        </div>
      )}
    </div>
  );
};

export default FirmUserProfile