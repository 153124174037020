import * as React from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment/moment';
import '../TextInput/styles.css';
import propTypes from 'prop-types';
import { DatePickerInputTypes,validationsTypeEnum,FieldVariants } from '../TextInput/appInputenum';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useState , useEffect } from 'react';

const DatePickerField = (props) => {
    const {
      type,
      name,
      label,
      defaultValue,
      onChange,
      required,
      disabled,
      variant,
      minDate,
      maxDate,
      validateField,
      validationType,
      validationMessage,
      checkIfValid,
      setcheckIfValid,
      checknoofValidFields,
      cancelFields,
      isActive,
    } = props;

  const [iserror, setError] = useState(false);

    const handleChange = (selectedDate) => {
        const  value  = new Date(selectedDate);
        const isValid = ValidateField(name, value);
        setError(!isValid);//need to set the state to trigger the error message
        onChange(name, value);
    };

    const ValidateField = (name, value) => {
        if (validateField) {
            if (validationType === validationsTypeEnum.AGE_VALIDATION) {
              const selectedDate = dayjs(value);
              const currentDate = dayjs();
              const age = currentDate.diff(selectedDate, 'year');
              return age >= 18;
            }
            else if (validationType === validationsTypeEnum.FUTURE_VALIDATION) {
              const isValid = !value || dayjs(value).isBefore(dayjs(), 'day') || dayjs(value).isSame(dayjs(), 'day');
              return isValid;
            }      
            else if (validationType === validationsTypeEnum.REQUIRED) {
                return value !== '';
            }            
            else {
                return true;
            }
        }
        else {
            return true;
        }

    }

    useEffect(() => {
        if(checkIfValid) {
            const isValid = ValidateField(name, defaultValue);  
            if(isValid) {
                    checknoofValidFields(isValid,name,defaultValue);   
                    setcheckIfValid(false);
            } else {
                checknoofValidFields(isValid,name,defaultValue);   
                setError(!isValid); 
                setcheckIfValid(false);
            }
            //checkIfValidRef.current = true;
        }
    },[checkIfValid])

    useEffect(() => {
        if(cancelFields) {
            setError(false);
        }
    },[cancelFields])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} >
    {
      type === DatePickerInputTypes.DATE ?
        <DatePicker
              label={label}
              slotProps={{
                textField: {
                  variant: variant || FieldVariants.STANDARD,
                  fullWidth: true,
                  size: isActive ? '' : 'small',
                  ...(isActive && { // Conditional object spreading
                    InputLabelProps: {
                        shrink: true,
                        focused: true,
                    },
                  }),
                  required: required || validateField,
                  error: iserror,
                  helperText: (iserror ? validationMessage : '')
                },
              }}
              defaultValue={defaultValue ? dayjs(moment(defaultValue).format("YYYY-MM-DD")) : null}
              onChange={handleChange}
              minDate={minDate !== '' ? minDate: null}
              maxDate={maxDate !== '' ? maxDate: null}
            /> :
      type === DatePickerInputTypes.DATE_AND_TIME ?
        <DateTimePicker
              label={label}
              slotProps={{
                textField: {
                  variant: variant || FieldVariants.STANDARD,
                  fullWidth: true,
                  InputLabelProps: {
                    shrink: true,
                    focused: true,
                  },
                  required: required || validateField,
                  error: iserror,
                  helperText: (iserror ? validationMessage : '')
                },
              }}
              defaultValue={defaultValue ? dayjs(moment(defaultValue).format("YYYY-MM-DDTHH:mm:ss")) : null}
              onChange={handleChange}
            /> : null
    }
    </LocalizationProvider>
  )
}

DateTimePicker.propTypes = {
    type: propTypes.string,
    onChange: propTypes.func.isRequired,
    variant: propTypes.oneOf(['standard', 'filled', 'outlined']),
    required: propTypes.bool,
    multiline: propTypes.bool,
    defaultValue: propTypes.string,
    placeholder: propTypes.string,
    rows: propTypes.number,
    label: propTypes.string,
    disabled: propTypes.bool,
    name: propTypes.string,
    size: propTypes.string,
    minDate: propTypes.instanceOf(Date),
    maxDate: propTypes.instanceOf(Date),
    isActive: propTypes.bool
};

DateTimePicker.defaultProps = {
    type: DatePickerInputTypes.DATE,
    variant: FieldVariants.STANDARD,
    size: 'small',
    required: false,
    multiline: false,
    defaultValue: '',
    placeholder: '',
    rows: 4,
    label: '',
    disabled: false,
    name: '',
    minDate: '',
    maxDate: '',
    isActive: true,
};

export default DatePickerField