import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';

const OfflineConfirmationDialog = ({
  open,
  onClose,
  steps,
  setDisableStepsFrom,
  questionDetais,
  popupopen,
  setCurrStepDetails,
  submitSubscriptionAnswers
}) => {

  const openFirstStepWithEmptyAnswer = () => {
    let firstStepToShow = 0;
    const firstIncompleteStep = questionDetais
      ?.filter(step => step.isActive && step.setValidations && step.userProvidedAnswer === '' && step.showIfParentAnswerIDIsIn === '' && step.questionAnswer.length === 0)
      .sort((a, b) => a.step - b.step);

    if (firstIncompleteStep !== null && firstIncompleteStep.length > 0) {
      firstStepToShow = firstIncompleteStep[0].step;
      if (firstStepToShow > 0) {
        onClose();
        setCurrStepDetails(steps.find(u => u.stepNumber === firstStepToShow));
      }
    }
    popupopen(true);
  };

  const onConfirm = () => {
    submitSubscriptionAnswers(questionDetais)
    onClose();
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      maxWidth="xs"
      fullWidth
      PaperProps={{
        style: { borderRadius: 12, padding: '20px 24px' },
      }}
    >
      <DialogTitle id="confirmation-dialog-title" style={{ display: 'flex', alignItems: 'center', paddingBottom: '8px' }}>
        <InfoIcon style={{ color: '#1976d2', marginRight: '8px' }} />
        <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
          Confirmation
        </Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          style={{ padding: 0 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography id="confirmation-dialog-description" style={{ fontSize: '15px', color: '#4f4f4f' }}>
          Incomplete form fields, Do you want to proceed?
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between', padding: '16px 24px' }}>
        <Button
          variant="outlined"
          onClick={openFirstStepWithEmptyAnswer}
          color="primary"
          style={{ fontWeight: 'bold', textTransform: 'none', minWidth: '120px' }}
        >
          VIEW DETAILS
        </Button>
        <div>
          <Button
            variant="outlined"
            onClick={onClose}
            color="primary"
            style={{ fontWeight: 'bold', textTransform: 'none', marginRight: '8px' }}
          >
            NO
          </Button>
          <Button
            variant="contained"
            onClick={onConfirm}
            color="primary"
          //style={{ fontWeight: 'bold', textTransform: 'none', minWidth: '80px' }}
          >
            YES
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default OfflineConfirmationDialog;
