import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFavouriteFunds } from '../../../../state/slices/MarketPlaceSlice';
import FundBoxView from './FundBoxView';
import { getrootBackgroundColorConfig } from '../../../../state/slices/colorConfigSlice';
import { getAllFunds } from '../../../../state/slices/MarketPlaceSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { FundAddTypes } from '../../mpenum';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { getFavoriteFunds, getFundSubscriptions } from '../../services/mpservices';
import secureLocalStorage from 'react-secure-storage';
import CircleIcon from '../../../chatbot/Icon';

const FavouriteFunds = () => {

  //#region redux variables
  const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const favouriteFunds = useSelector(getFavouriteFunds);
  const [favouriteFunds, setfavouritefunds] = useState(null);
  const allFundsDetails = useSelector(getAllFunds);
  const [allFunds,setAllFunds] = useState(null);
  const [allInvestors,setAllInvestors] = useState();
  const location = useLocation();
  const { state } = location;

  //#region click events
  const handleAddClick = () =>{
      navigate('/marketplace')
  }

  //#region api get calls
  const getFundsSubscriptions = async() => {
    const data = await getFundSubscriptions();
    if(data.responseCode === 200){
        setAllInvestors(data.responseData.filter(u=>u.investorID === secureLocalStorage.getItem("userId") && u.subscriptionStatus !== "Rejected" && u.subscriptionStatus !== "Cancelled"));
    }
    else{
        console.log('unable to load funds')
    }
  }

  const fetchDataforfavfunds = async () => {
    const data = await getFavoriteFunds();
    if(data.responseCode === 200){
      const favouriteFundsData = data.responseData.map(fund => ({
        ...fund,
        favourite: true
      }));

      setfavouritefunds(favouriteFundsData);
    }
    else{

    }
  };

  //#region useEffect
  useEffect(()=>{
        setAllFunds(allFundsDetails.value);
    },[allFundsDetails])

  useEffect(()=>{
      getFundsSubscriptions();
      fetchDataforfavfunds();
    },[])

  return (
    <div className='wrapper mt2'>
        <h1>Favorite Funds</h1>
        <div className='mp-wrapper'>
            {
              allFunds && allInvestors && favouriteFunds?.length > 0 && favouriteFunds?.map((item,index)=>(
                  <div key={index}>
                      <FundBoxView allFunds={allFunds}  allInvestors={allInvestors} fundDetails={item} type={FundAddTypes.FAVOURITE} fetchDataforfavfunds={fetchDataforfavfunds} />
                  </div>
              ))
            }
            <div className='add-box cursor-pointer' onClick={handleAddClick}>
               <div className='add-box2 cursor-pointer'>
                  <div className='add-icon-center'>
                       <ControlPointIcon color="action"/>
                  </div>
               </div>
            </div>
       </div>
       <CircleIcon/>
    </div>
  )
}

export default FavouriteFunds