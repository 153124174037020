import { getAPIResponse } from "../../../utils/common";
import axiosApiCall from "../../Services/axiosApiCall";


export async function getFundTransactions(fundID,transactionType,selectedyear) {
    return await axiosApiCall.get(`/v1/Fund/GetFundTransactionSection?TransactionType=${transactionType}&year=${selectedyear}&fundID=${fundID}&showPC=true`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}


export async function getInvestorTransactions(transactionType,investorID,selectedyear) {
    return await axiosApiCall.get(`/v1/Fund/GetFundTransactionSection?TransactionType=${transactionType}&year=${selectedyear}&investorID=${investorID}&fundID=0&showPC=false`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getInvestmentRelatedTransactions(transactionType,investorID,selectedyear,fundID) {
    return await axiosApiCall.get(`/v1/Fund/GetFundTransactionSection?TransactionType=${transactionType}&year=${selectedyear}&fundID=${fundID}&investorID=${investorID}&showPC=false`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}