import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material';
import { getInvestorDetailsbyUserID } from '../../KYCVerification/services/services';
import { ToastContainer, toast } from 'react-toastify';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import AIPDataGrid from '../../common/DataGrid/AIPDataGrid';
import { auditingUserData } from '../auditingdata';
import { Icon } from '@react-pdf-viewer/core';
import { ComponentsTab } from '../../common/Tabs/ComponentsTab';
import AudtingGrid from './AudtingGrid';
import { HeaderTemplates, PageHeader } from '../../common/PageHeader/PageHeader';
import { AuditingType, IndividualInvestor, KYCAMLColumns, UserBankInformation, UserPersonalInformation, UserProfileInformation } from '../audititngenum';
import { useLocation } from 'react-router-dom';
import { getInvestorProfileAuditing } from '../services/services';
import { width } from '@mui/system';
import TabsComponment from '../../common/Tabs/TabsComponment';
import moment from 'moment/moment';
import PersonIcon from '@mui/icons-material/Person';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import dayjs from 'dayjs';

const InvestorProfileAudit = () => {

  //#region variables
  const navigate = useNavigate();

  const [personalInformation, setPersonalInformation] = useState(null);
  const [bankingInformation, setBankingInformation] = useState();
  const [investorInformation, setInvestorInformation] = useState(null);
  const [personalInfoColumns, setPersonalInfoColumns] = useState(null);
  const [bankingInfoColumns, setBankingInfoColumns] = useState();
  const [investorInfoColumns, setInvestorInfoColumns] = useState(null);
  const [kycAmlInformation, setKycAmlInformation] = useState(null);
  const [kycAmlInfoColumns, setKycAmlInfoColumns] = useState(null);
  const [userName, setUserName] = useState();

  const [selectedTab, setSelectedTab] = useState(0);
  const location = useLocation();
  const { state } = location;
  const auditingType = state.auditingType;

  const columns = [
    { field: 'field', headerName: '', width: 400, },
    { field: 'value', headerName: '', width: 400 },
  ]

  const IndividualInvestor = [
    "firstName",
    "lastName",
    "emailAddress",
    "phoneNo",
    "addressLine1",
    "addressLine2",
    "stateName",
    "cityName",
    "zipCode",
    "taxFillingContact",
    "annualPreTaxIncome",
    "numberOfDependents",
    "employerStatus",
    "employer",
    "occupation",
    "spouseAnnualPreTaxIncome",
    "spouseHasIIAAccount",
    "federalTaxBracket",
    "householdInvestableAssets",
    "isRegistrationCompleted",
    "fundName",
    "userName",
    "kycStatus",
    "isBulkUploadUser",
    "investorType",
    "investorSubType",
    "roleName",
    "updatedBy",
    "updatedDate"
  ]

  const NonIndividual = [
    "firstName",
    "legalEntityTypeName",
    "emailAddress",
    "phoneNo",
    "addressLine1",
    "addressLine2",
    "countryName",
    "stateName",
    "cityName",
    "zipCode",
    "taxFillingContact",
    "annualPreTaxIncome",
    "federalTaxBracket",
    "householdInvestableAssets",
    "website",
    "numberOfFamilyMember",
    "languagesSpoken",
    "investmentGoalName",
    "advisoryServicesRequiredName",
    "referralSource",
    "totalAssetsUnderManagement",
    "regulatoryStatusName",
    "tin",
    "numberOfFamilyMembers",
    "isRegistrationCompleted",
    "fundName",
    "userName",
    "kycStatus",
    "isBulkUploadUser",
    "investorType",
    "investorSubType",
    "roleName",
    "updatedBy",
    "updatedDate",
    
  ]

  //#region tabs
  const [tabs, setTabs] = useState([]);
  const InvestmentProfileTabs = [
    {
      key: 0,
      icon: <PersonIcon/>,
      label: "PERSONAL INFORMATION",
      component: <AudtingGrid />
    },
    {
      key: 1,
      icon: <AccountBalanceIcon/>,
      label: "BANKING INFORMATION",
      component: <AudtingGrid />
    },
    {
      key: 2,
      icon: <MonetizationOnIcon/>,
      label: "INVESTMENT PROFILE",
      component: <AudtingGrid />
    },
    {
      key: 3,
      icon: <ManageAccountsIcon/>,
      label: "KYC/AML",
      component: <AudtingGrid />
    }
  ]

  //#region api get calls
  const getInvestorProfileAuditInfo = async () => {
    const selectedUserID = state.auditUserID;

    const data = await getInvestorProfileAuditing(selectedUserID);
    if (data?.responseCode === 200) {
      // Extract personal information fields
      const personalInformationData = data.responseData?.userPersonalInformationAuditing;
      const bankingInformationData = data.responseData?.userBankingInformationAuditing;
      const investmentProfile = data.responseData?.userProfileInformationAuditing;
      const kycAmlProfile = data.responseData?.userKYCAMLInformationAuditing;
      if (data.responseData?.userPersonalInformationAuditing != null) {
        setUserName(data.responseData?.userPersonalInformationAuditing[0].userName);
      }
      const personalInfoFields = personalInformationData?.map((dataItem, index) => ({
        id: index,
        ...dataItem,
        updatedDate : moment(dataItem.updatedDate).format('MM-DD-YYYY'),
      }));
      const personalinfocolumns = personalInformationData && personalInformationData?.length > 0 ?
        Object.entries(personalInformationData[0]).map(([key]) => {
          let obj = {
            field: key,
            headerName: UserPersonalInformation[key],
            width: 150,
          };

          if (state.investorType == "Individual Investor") {
            if (IndividualInvestor.indexOf(key) >= 0) {
              return obj;
            }
          } else {
            if (NonIndividual.indexOf(key) >= 0) {
              if(key == "firstName"){
                obj.headerName = "Legal Entity Name"
              }
              return obj;
            }
          }
        }) : [];

      const bankingInfoFields = bankingInformationData?.map((dataItem, index) => ({
        id: index,
        ...dataItem,
        updatedDate : moment(dataItem.updatedDate).format('MM-DD-YYYY'),
      }));
      const bankinginfocolumns = bankingInformationData && bankingInformationData.length > 0 ?
        Object.entries(bankingInformationData[0]).map(([key, value], index) => ({
          field: key,
          headerName: UserBankInformation[key],
          width: 150,
        })) : [];

      const investorInfoFields = investmentProfile?.map((dataItem, index) => ({
        id: index,
        ...dataItem,
        updatedDate : moment(dataItem.updatedDate).format('MM-DD-YYYY'),
      }));
      const investorinfocolumns = investmentProfile && investmentProfile.length > 0 ?
        Object.entries(investmentProfile[0]).map(([key, value], index) => ({
          field: key,
          headerName: UserProfileInformation[key],
          width: 600,
        })) : [];
        const kycAmlInfoFields = kycAmlProfile?.map((dataItem, index) => ({
          id: index,
          ...dataItem,
          updatedDate : moment(dataItem.updatedDate).format('MM-DD-YYYY'),
        }));
        const kycAmlInfoColumns = kycAmlProfile && kycAmlProfile.length > 0 ?
          Object.entries(kycAmlProfile[0])?.map(([key, value], index) => ({
            field: key,
            headerName: KYCAMLColumns[key],
            width: 200,
          })) : [];
      // debugger;
      setPersonalInfoColumns(personalinfocolumns.filter(a => a != undefined))
      setPersonalInformation(personalInfoFields);
      setBankingInfoColumns(bankinginfocolumns);
      setBankingInformation(bankingInfoFields);
      setInvestorInformation(investorInfoFields);
      setInvestorInfoColumns(investorinfocolumns);
      setKycAmlInformation(kycAmlInfoFields);
      setKycAmlInfoColumns(kycAmlInfoColumns);

    } else {
      // Handle error if needed
    }
  }

  //#region useEffect
  useEffect(() => {
    getInvestorProfileAuditInfo();
  }, [])

  useEffect(() => {
    if (auditingType === AuditingType.INVESTOR_PROFILE) {
      let modifiedTabs = InvestmentProfileTabs;
      if (modifiedTabs[0]) {
        modifiedTabs[0].component = <AudtingGrid columns={personalInfoColumns} rows={personalInformation} fileName={`${userName}_PersonalInformationAuditData`} />;
      }
      if (modifiedTabs[1]) {
        modifiedTabs[1].component = <AudtingGrid columns={bankingInfoColumns} rows={bankingInformation} fileName={`${userName}_BankingInformationAuditData`} />;
      }
      if (modifiedTabs[2]) {
        modifiedTabs[2].component = <AudtingGrid columns={investorInfoColumns} rows={investorInformation} fileName={`${userName}_PersonalInformationAuditData`} />;
      }
      if (modifiedTabs[3]) {
        modifiedTabs[3].component = <AudtingGrid columns={kycAmlInfoColumns} rows={kycAmlInformation} fileName={`${userName}_KYCAMLInformationAuditData`} />;
      }
      setTabs(modifiedTabs)
    }
  }, [personalInformation])

  return (
    <div>
      <PageHeader
        title="Auditing Overview"
        template={HeaderTemplates.NORMAL_VIEW} />
      <div className="wrapper3">
        {personalInformation && personalInfoColumns ? (
          <>

            <TabsComponment tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />

          </>
        ) : (
          <Grid container xs={12} className='whiteCard'>
            <LinearProgress />
          </Grid>
        )}
      </div>
      <ToastContainer />
    </div>
  )
}

export default InvestorProfileAudit

