import React from "react";

const PageNotFound = () => {
  return (
    <div>
      <img src="assets/img/Page-Not-Found.jpg" className="pageNotFound" />
    </div>
    );
};

export default PageNotFound;
