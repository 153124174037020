import React, { useState,useEffect } from 'react';
import "../Compare.css";
import { useNavigate} from 'react-router-dom';
import RemovePopup from './RemovePopup';
import secureLocalStorage from "react-secure-storage";
import CircleIcon from '../../chatbot/Icon';
import { getrootBackgroundColorConfig } from '../../../state/slices/colorConfigSlice';
import { useSelector } from 'react-redux';
import { AppDashboards, FeatureAccess, RolesAndPermissions } from '../../../utils/enum';
import { checkFeaturePermissions } from '../../../utils/common';
const Compare = () => {

  const navigate = useNavigate();
  const [compareDetails, setCompareDetails] = useState([]);
  const [Funddetails, setFunddetails] = useState([]);
  const [selectedItems, setselectedItems] = useState(localStorage.getItem('selectedItems'));
  const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);

  useEffect(() => {
    const selectedItems = localStorage.getItem('selectedItems');
    const CompareItems = localStorage.getItem('CompareItems');
    if (selectedItems && CompareItems !== 'undefined') {
      const selectedIds = JSON.parse(selectedItems);
      const compareItemsArray = JSON.parse(CompareItems);
      const selectedDetails = compareItemsArray.filter((item) => selectedIds.includes(item?.fundID));
      setCompareDetails(selectedDetails);
    setFunddetails(compareItemsArray);
    }
    
  }, []); 
  const [AllInvestors, setAllInvestors] = useState([]);
 
  const fetchDataforAllFunds = async () => {
  
  }; 
  fetchDataforAllFunds();

  const handleViewDetail = (item,itemIdObj, fundname,fundStatusName, disableSubscriptionButton,event ) => {
    if (event) {
      event.stopPropagation();
    }

   

    let parsedFundId;
    try {
      parsedFundId = JSON.parse(itemIdObj);
    } catch (error) {
      console.error("Error parsing fundDetail:", error);
    }
    console.clear()

    console.log(parsedFundId)

    secureLocalStorage.setItem("FundId", parsedFundId);

    secureLocalStorage.setItem("FundName", fundname);

    const havepermissiontoread =  checkFeaturePermissions(RolesAndPermissions.FUND, FeatureAccess.READ);

    if (secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD) {
      /* navigate('/fund-details', { parsedFundId }, { fundStatusName }); */
      navigate('/fund-details', {
        state: {
          FundID: parsedFundId,
          InvestorID: secureLocalStorage.getItem("userId"),
          SubscriptionID: 0,
          navigatefrommarketplace: true,
          disableSubscriptionButton:disableSubscriptionButton
        }
      });
      
    } else if (havepermissiontoread) {
      console.log(item)
       
      
      const SelectedFund = {
        "FundMaturity": item.isFundMatured,
        "FundTermination": item.fundTerminated,
        BGImage: item.bgImage,
        IconImage: item.iconImage
      }
      secureLocalStorage.setItem("selectedFund", SelectedFund);
      navigate('/marketplace/OverviewPage', { parsedFundId });
    }
    
    secureLocalStorage.setItem("FundId", JSON.stringify(parsedFundId));
    secureLocalStorage.setItem('fundStatusName',fundStatusName);
    window.scrollTo(0, 0);
  };


  
  const handlesubscribe = (itemId,fundname, event) => {
    if (event) {
      event.stopPropagation();
    }
    secureLocalStorage.setItem("FundName", fundname);
    navigate('/subscribe-list', { state: { FundId: { itemId } } });
    window.scrollTo(0, 0);
  };

  const addbutton = (event) => {
    if (event) {
      event.stopPropagation();
    }

    navigate('/marketplace');
    window.scrollTo(0, 0);
    localStorage.setItem('selectedItems-comparepage', JSON.stringify(selectedItems));     localStorage.setItem('CompareItems-comparepage', JSON.stringify(Funddetails));
  };
  

  const [showPopup, setShowPopup] = useState(false);
  const [removeItemId, setRemoveItemId] = useState(null); 

  const handleremoveitems = (itemId) => {
    setShowPopup(true);
    setRemoveItemId(itemId);
      const updatedFundDetails = Funddetails.map((item) => {
      if (item?.fundID === itemId) {
        return { ...item, compare: !item?.compare }; // Toggle the compare state
      }
      return item;
    });

    const selectedItems = updatedFundDetails
      .filter((item) => item?.compare)
      .map((item) => item?.fundID);
    setFunddetails(updatedFundDetails);

    localStorage.setItem('selectedItems11', JSON.stringify(selectedItems));
    setselectedItems(selectedItems)
    localStorage.setItem('CompareItems11', JSON.stringify(updatedFundDetails));

  };
  

  const handleConfirmRemove = () => {
    
    setCompareDetails((prevDetails) =>
      prevDetails.filter((item) => item?.fundID !== removeItemId)
    );
    setShowPopup(false);
  };

  const handleCancelRemove = () => {
    setShowPopup(false);
  };
  

  return (
    <div style={{backgroundColor: rootBackColorConfig.color}} className='mt2-page'>
    <b  className="heading-Compare">Compare</b>
       <div style={{backgroundColor: rootBackColorConfig.color}}className="fundStatusWrapper">
        
       {compareDetails && compareDetails.length > 0 ? (

          compareDetails?.map((item, index) => (
            <div className="cardcontainer" key={index}>
          {console.log(Funddetails)}
            <div className="mediumCardBgParent1">
              <div className="mediumCardBg">
                <div className="bg" />
                <div className="component21294">
                  <div className="mediumCardBg">
                    <div className="buttonNormalItem" />
                    {
                        checkFeaturePermissions(RolesAndPermissions.FUND_SUBSCRIPTION,FeatureAccess.READ  ) 
                        && 
                    <button className="subscribe4" 
                    onClick={() => {handlesubscribe(item,item.fundID, item.fundName)}}

                    disabled={
                      secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD ?
                       (item?.isFundMatured  
                        || secureLocalStorage.getItem("InvestorFundIDs")?.includes(item?.fundID)
                        || item.fundStatusName === 'Closed to New Investors' ||  item.fundStatusName === 'Launching Soon' || item.fundStatusName === 'Closed' || item.fundStatusName === 'Terminated'
                        || AllInvestors.some(u=>u.fundID === item?.fundID)
                        ) :
                       true
                      //  (item?.isFundMatured )
                   } 
                 
                  >
                        {AllInvestors.some(u=>u.fundID === item?.fundID) ? "Subscribed" : "Subscribe"}
                      </button>
}
                  </div>
                </div>
                <div className="privateEquityParent2">
                  <div className="privateEquity4">Private Equity</div>
                  <b className="consumerGoodsRetailEnhancem4">
                    {item?.fundName}
                  </b>
                  <div className="loremIpsumDolor4">
                    {item?.fundDescription}
                  </div>
                  <div className="targetReturn-compare">TARGET RETURN</div>
                  <b className="target-returndata">{item?.targettedIRR}%</b>
                  <div className="minimum4">MINIMUM</div>
                  <b className="b9">${item?.minimumInvestmentAmount?.toLocaleString()}</b>
                  <div className="liquidity4">LIQUIDITY</div>
                  <b className="quarterly4">{item?.liquidityProvision}</b>
                  <img
                    className="favoriteButtonIcon-compare"
                    alt=""
                    src="/favorite-button.svg"
                  />
                  <img
                    className="addToCompare-compare"
                    alt=""
                    src={item?.compare ? '/add-to-compare1.svg' : '/add-to-compare.svg'}
                    onClick={() => handleremoveitems(item?.fundID)}
                  />
                </div>
                
                <div className="component212131">
                  <div className="mediumCardBg">
                    <div className="buttonNormalItem" />
                    <button
                      className="viewDetail"
                      onClick={() => handleViewDetail(item,item.fundID, item.fundName , item.fundStatusName, AllInvestors.some(u=>u.fundID === item?.fundID))}
                      >
                      View Detail
                    </button>
                  </div>
                </div>
                
              </div>
              <img
                className="maskGroup36"
                alt=""
                src={item?.bgImage === null || !item?.bgImage.includes('https') ? '/DefaultBackgroundImage.png' : item?.bgImage}
              />
              <div className="image4" />
              <div className="label4">
                <b className="labelcontent4">{item?.fundStatusName}</b>
              </div>
              <img
                className="maskGroup38"
                alt=""
                src={item?.iconImage === null || !item?.iconImage.includes('https') ? "/DefaultLogo.png": item?.iconImage}

              />
            <div className='funddetails-compare'>
                    <div className='protfoliobyasset-cp'>Portfolio By Asset Type</div>
                    <br />
                    <div className='Equity-cp'>
                        <span className='fund-cp'>Equity</span>
                        <span className='content-cp'>{item?.equityInvestment}%</span>
                    </div>
                    <div className="line-cp"></div>
                    <div className='Credit-cp'>
                    <span className='fund-cp'>Credit</span>
                    <span className='content-cp'>{item?.creditInvestment}%</span>
                    </div>
                    <div className="line-cp"></div>
                    <div className='fundmetrics-cp'>
                    <span className='fund-cp'>Fund Metrics</span>
                        </div>
                    <br />
                    <div className='vintage-cp'>
                    <span className='fund-cp'>Vintage Year</span>
                    <span className='content-cp'>{item?.vintage}</span>
                    </div>
                    <div className="line-cp"></div>
                    <div className='fundsize-cp'>
                    <span className='fund-cp'>Target Raise</span>
                        <span className='content-cp'>${item?.fundSize / 1000000}M</span>
                    </div>
                    <div className="line-cp"></div>
                    <div className='capitalcalled-cp'>
                    <span className='fund-cp'>Capital Called</span>
                         <span className='content-cp'>{item?.capitalCalled}</span>
                    </div>
                    <div className="line-cp"></div>
                    <div className='noofinvestments-cp'>
                    <span className='fund-cp'>Number of Investments</span>
                    <span className='content-cp'>{item?.numberofPortfolioCompanies}</span>
                     </div>
                    <div className="line-cp"></div>
                  </div>
            </div>
            
          </div>
          ))
        ) : (
            <div></div>
          )}
        <div className="addToCompare3">
          <div className='bg3-cp' onClick={() => addbutton()}>
            <div className="iconMaterialAddCircleOutli-cp">
              <img
                alt=""
                src="MarketPlaceImages/icon-materialaddcircleoutline.svg"
              />
            </div>
            <div className='bg4-cp' />
          </div>
        </div>
      </div>
      {showPopup && (
        <RemovePopup
          onConfirm={handleConfirmRemove}
          onCancel={handleCancelRemove}
          message={"Are you sure you would like to remove this fund from your compare list?"}
          Buttonmsg={"Remove"}
          leftValue={"84px"}
        />
      )}
      <CircleIcon/>
    </div>
    
  )
}

export default Compare;
