import { useEffect, useState } from "react";
import AIPDataGrid from "../../common/DataGrid/AIPDataGrid";
import FundNews from "../../common/FundNews/FundNews";
import DashboardSelect2 from "../../common/Select/DashboardSelect2";
import ListView2 from "../../marketplace/components/ListView2";
import styles from "../FundManagerUserProfile.module.css";
import jsondata from './Fm.json';
import Configurations from "../../../Configurations";
import secureLocalStorage from "react-secure-storage";
import { getSuggestedArticlesByUserID } from "../../FundManagerDashboard/Services/FundNewsService";
import { CurrencyFormatter } from "../../../utils/Formater";
import moment from 'moment';
import Indicator from "../../common/DataGrid/Indicator";
import { useLocation } from "react-router-dom";
import { AppDashboards,} from "../../../utils/enum";
import { formatAsUSACurrency } from "../../common/CurrencyFormater/formatAsUSACurrency";
import ListView from "../../marketplace/components/ListView";



export const FundUserOverview = () =>{

  const location = useLocation();
  const [FundNewsData, setFundNewsData] = useState();
  const [fundtransactions, setFundTransactions] = useState();
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedTransactionsYear, setSelectedTransactionsYear] = useState('');

  const userID = secureLocalStorage.getItem("userId");
  const [fundNotifications, setFundNotifications] = useState();
  useEffect(() => {

    const fetchData1 = async () => {

      try {
        const response = await fetch(

          `${Configurations.apiBaseUri}/v1/InvestorDetails/GetNotificationByInvestorID?investorID=${userID}`
          );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        console.clear()
        const data = await response.json();
        console.log(data)
        setFundNotifications(data);

      } catch (error) {

        console.error('Error fetching data:', error);
      }
    };
    fetchData1();

  }, []);

  
  const handleYearChange = (year) => {
    setSelectedYear(year);
    // You can perform additional actions here when the year changes
  };
  const handleTransactionsYearChange = (year) => {
    setSelectedTransactionsYear(year);
  };
    useEffect(() => {
        const fetchDataforfundtransactions = async () => {
          try {
            const response = await fetch(`${Configurations.apiBaseUri}/v1/Servicing/GetFundTransactions?fundID=${userID}`, {
              method: 'GET',
            });
    
            if (response.ok) {
              const jsonData = await response.json();
              console.log(jsonData);
              setFundTransactions(jsonData);
            } else {
              console.error(response);
              throw new Error("Failed to fetch data");
            }
          } catch (error) {
            console.error(error);
          }
        };
    
        fetchDataforfundtransactions();
      }, []);

  const getFundNews = async () =>{
    const data = await getSuggestedArticlesByUserID(userID);
    if (data.responseCode === 200) {
      setFundNewsData(data.responseData);
    }
    else {
    }
  } 

  useEffect(() => {
    getFundNews();
  }, []); 

  const FundTransactionsRowsData = fundtransactions ? fundtransactions.map(transaction => ({
    id: transaction.fundID,
    Type: transaction.transactionType,
    Name: transaction.fundName,
    InvestorName: transaction.investorName,
    Amount: formatAsUSACurrency(transaction.transactionAmount),
    Date: moment(transaction.transactionDate).format('DD-MM-YYYY')

  })) : [];

  const years = [
    { id: 1, label: '2020', value: 2020 },
    { id: 2, label: '2021', value: 2021 },
    { id: 3, label: '2022', value: 2022 },
    { id: 3, label: '2023', value: 2023 },
    // Add more years as needed
  ];

    const NotificationsColumns = [
        // {
        //   "id": 1,
        //   "field": "UserName",
        //   "headerName": "User Name",
        //   "width": 190,
        //   "renderCell": (params) => {
        //     return <Indicator params={params} />
        //   }
        // },
        {
          "id": 1,
          "field": "FundName",
          "headerName": "Fund Name",
          "width": 200,
          "renderCell": (params) => {
            return <Indicator params={params} />
          }
        },
        {
          "id": 2,
          "field": "Status",
          "headerName": "Status",
          "width": 150,
          "renderCell": (params) => {
            return <Indicator params={params} />
          }
        },
      ];

      const NotificationsRowsData = fundNotifications ? fundNotifications.map(user=> ({

        // id: user.sortOrder,
        id:user.fundID,
        "UserName"  :  user.name,
        "FundName"  :  user.fundName,
        "Status"    :  user.actionName,
    
      })) : [];
    

return(
        
    <div className={styles.overviewWrapper}>
    <div style={{height: '450px'}} className={styles.fundStatusWrapper}>
      <div className={styles.fundStatusHeaderWrapper}>
        <h6>Funds Invested In</h6>
      </div>
      <div style={{position: "relative"}}>
      <ListView
          topValue="20px" 
          leftValue="0px" 
          heightvalue="360px" 
          overflowyvalue="auto" 
          backgroundcolor="white"
          navigateFromInvestorDB={true}
          navigateFromFMView={true}
          investorID={secureLocalStorage.getItem("subscriberID")}
      />
      </div>
      
    </div>
    <div className={styles.fundPerfCont}>
      <div className={styles.fundPerfSectionCont}>
        <div className={styles.secondaryStatsWrapper}>
          <h6> Performance Metrics</h6>
          <DashboardSelect2
                type="years"
                options={years}
                label={'Year'}
                value={selectedYear}
                onChange={handleYearChange}
              />
        </div>
            {/* <div style={{height:'90%'}}>
            <AIPDataGrid
            columns={Performancecolumns}
            rows={performanceData || []} // Add the null check here
            onRowsSelectionHandler={() => { }}
          />
            </div> */}
      </div>
      <div className={styles.fundPerfSectionCont}>
        <h6> Notifications </h6>
      <div style={{height:'95%'}}>
        <AIPDataGrid
                    columns={NotificationsColumns}
                    rows={NotificationsRowsData}
                    />
                
          </div>
      </div>
    </div>
    <div className={styles.fundPerfCont}>
      <div className={styles.fundPerfSectionCont}>
         <div>
         <FundNews FundNewsData={FundNewsData} role={AppDashboards.FM_DASHBOARD}/>
        </div>
      </div>
      <div className={styles.fundPerfSectionCont}>
        <div className={styles.secondaryStatsWrapper}>
          <h6> Account Transactions</h6>
          <DashboardSelect2
             type="years"
              options={years}
              label={'Year'}
              value={selectedTransactionsYear}
              onChange={handleTransactionsYearChange}
            />
          </div>
          <div style={{height:'95%'}}>
          <AIPDataGrid
              columns={jsondata.FundTransactionsColumns}
              rows={FundTransactionsRowsData ? FundTransactionsRowsData : []}
              onRowsSelectionHandler={() => { }} />
          </div>
      </div>
    </div>
    </div>
    )
}