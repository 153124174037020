import React, { useState, useEffect } from 'react';
import {
  DialogContent,
  DialogActions,
} from '@mui/material';
import { CreateNewInvestor } from '../Services/IRServices';
import secureLocalStorage from 'react-secure-storage';
import ActionButton from '../../common/ActionButton/ActionButton';
import { getTenantRoles } from '../Services/IRServices';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppTextInput from '../../common/TextInput/AppTextField';
import { InputTypes, textFormatTypeEnum, validationMessages, validationsTypeEnum } from '../../common/TextInput/appInputenum';
import { getInvestorsTypes } from '../Services/IRServices';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import CancelIcon from '@mui/icons-material/Cancel';

const investorFieldsMap = {
  'Individual Investor': [
    { name: 'firstName', label: 'First Name', validationType: validationsTypeEnum.ONLY_ALPHABETS, validationMessage: validationMessages.REQUIRED, validateField: true },
    { name: 'lastName', label: 'Last Name', validationType: validationsTypeEnum.ONLY_ALPHABETS, validationMessage: validationMessages.REQUIRED, validateField: true },
    { name: 'email', label: 'Email Address', validationType: validationsTypeEnum.EMAIL_ADDRESS, validationMessage: validationMessages.REQUIRED,validateField: true  },
  ],
  'Other': [
    { name: 'legalEntityName', label: 'Legal Entity Name', validationType: validationsTypeEnum.REQUIRED, validationMessage: validationMessages.REQUIRED, validateField: true },
    { name: 'email', label: 'Email Address', validationType: validationsTypeEnum.EMAIL_ADDRESS, validationMessage: validationMessages.REQUIRED, validateField: true },
  ],
};

export const InviteNewInvestor = (props) => {
  const { onClose, type, getInvestors, fundsDropdown } = props;
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    investortypeID: '',
    fundid: ''
  });
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [investorRole, setInvestorRole] = useState(null);
  const [investorTypes, setInvestorTypes] = useState([]);
  const [submitbtn, setSubmitbtn] = useState(true);
  const [noofValidFields, setNoofValidFields] = useState({ validfields: 0 });
  const [checkIfValid, setcheckIfValid] = useState(false);
  const [refreshComponent, setrefreshComponent] = useState(true)
  let count = 0; // to Show Toast Message only once

  const getRoles = async () => {
    const data = await getTenantRoles(0);
    if (data.responseCode === 200) {
      const investorRole = data.responseData.filter(item => item.roleName === "Investor");
      setInvestorRole(investorRole[0]);
    } else {
      // Handle error if needed
    }
  };

  const getInvestorsType = async () => {
    const data = await getInvestorsTypes('InvestorType');
    if (data?.responseCode === 200) {
      setInvestorTypes(data?.responseData);
    } else {
      // Handle error if needed
    }
  };

  const handleSubmit = async () => {
    const requestedData = {
      userId: 0,
      userFirstName: userData?.investortypeID !== "Individual Investor" ? userData?.legalEntityName : userData?.firstName,
      userLastName: userData?.lastName,
      userFullName: userData?.investortypeID !== "Individual Investor" ? userData?.legalEntityName : `${userData?.firstName} ${userData?.lastName}`,
      userEmailAddress: userData?.email,
      tenantGUID: secureLocalStorage.getItem("tenantID"),
      userRoleID: investorRole?.roleId, // Role ID of Investor
      userRoleName: investorRole?.roleName,
      fundID: type === 3 ? userData.fundid : 0,
      investortypeID: investorTypes.find(item => item.listItemValue === userData.investortypeID).listItemID,
      userRoles: [
        {
          userId: 0,
          roleId: investorRole?.roleId,
          roleName: investorRole?.roleName,
          isPrimary: true
        }
      ],
    };

    setLoading(true);

    const formData = new FormData();
    formData.append('tenantUserData', JSON.stringify(requestedData));

    const response = await CreateNewInvestor(formData);

    if (response?.responseCode === 200) {
      toast.success("Successfully Saved", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
      getInvestors();
    } else if (response?.responseCode === 409) {
      toast.warning("Investor Already Exists", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    } else {
      getInvestors();
      toast.error("Unable to Invite the Investor", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    }
    setLoading(false);
    setOpen(false);
    onClose();
  };


  const handleCancel = () => {
    setOpen(false);
    onClose();
  };

  const handleChange = (field, value) => {
    setUserData({ ...userData, [field]: value }); 
  };

  //#region validations
  const checknoofValidFields = (isValid, name, defaultValue) => {
    const TotalFiledsToValidate = (type === 3 ? (userData.investortypeID === 'Individual Investor' ? 5 : 4) : (userData.investortypeID === 'Individual Investor' ? 4 : 3));
    count = ++count;
    noofValidFields['validfields'] = isValid ? noofValidFields['validfields'] + 1 : noofValidFields['validfields'];
    if (noofValidFields['validfields'] === TotalFiledsToValidate) {
      noofValidFields['validfields'] = 0;
      count = 0;
      //Post API
      handleSubmit();
    } else {
      if (count === TotalFiledsToValidate) {
        toast.warning("Please Verify the input fields",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored", style: { zIndex: 9999 } });
        count = 0;
      }
    }
  };

  const handleSave = () => {
    setcheckIfValid(true);
    noofValidFields['validfields'] = 0;
  }

  useEffect(() => {
    getRoles();
    getInvestorsType();
  }, []);

  useEffect(() => {
    setrefreshComponent(false)
    setTimeout(() => {
      setrefreshComponent(true)
    }, 500);
    setUserData({
      firstName: '',
      lastName: '',
      email: '',
      investortypeID: '',
      fundid: '',
    });
  }, [type]);

  return (
    <>{refreshComponent ? <div>
      <DialogContent sx={{ marginTop: '-18px' }}>
        <div>
          <AppTextInput
            type="select"
            name="investortypeID"
            required={true}
            options={investorTypes.map(type => ({ value: type.listItemValue, label: type.listItemValue }))}
            label="Investor Type"
            onChange={(name, value) => { handleChange(name, value); }}
            validateField={true}
            validationType={validationsTypeEnum.REQUIRED}
            validationMessage={validationMessages.REQUIRED}
            defaultValue={userData.investortypeID}
            checkIfValid={checkIfValid}
            checknoofValidFields={checknoofValidFields}
            setcheckIfValid={setcheckIfValid}
          />
        </div>
        <div>
          {userData.investortypeID === '' ? (
            <div></div>
          ) : (
            investorFieldsMap[userData.investortypeID === 'Individual Investor' ? 'Individual Investor' : 'Other']
              ?.filter(field => userData[field.name] !== ' ') // Filter out fields with empty values
              .map(field => (
                <div key={field.name} className="margin-top-15">
                  <AppTextInput
                    name={field.name}
                    required={field.validateField}
                    label={field.label}
                    defaultValue={userData[field.name]}
                    onChange={(name, value) => { handleChange(name, value); }}
                    validateField={field.validateField}
                    validationType={field.validationType}
                    validationMessage={field.name==='email' ? (userData[field.name].trim() === "" ? field.validationMessage : validationMessages.EMAIL_ADDRESS) : field.validationMessage}
                    checkIfValid={checkIfValid}
                    checknoofValidFields={checknoofValidFields}
                    setcheckIfValid={setcheckIfValid}
                  />
                </div>
              ))
          )}
        </div>
        {type === 3 && (
          <div className="margin-top-15">
            <AppTextInput
              type="select"
              name="fundid"
              required={true}
              defaultValue={userData.fundid}
              options={fundsDropdown?.map(fundName => ({ value: fundName.value, label: fundName.label })) || []}
              label="Select Fund"
              onChange={(name, value) => { handleChange(name, value); }}
              validateField={true}
              validationType={validationsTypeEnum.REQUIRED}
              validationMessage={validationMessages.REQUIRED}
              checkIfValid={checkIfValid}
              checknoofValidFields={checknoofValidFields}
              setcheckIfValid={setcheckIfValid}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <div className="space-between">
          <div>
            <ActionButton
              className="btn-primary"
              component="label"
              variant="outlined"
              onClick={handleCancel}
              icon={<CancelIcon />}
              label="Cancel"
            />
          </div>
          <div className="margin-left-10">
            <ActionButton
              className="btn-primary"
              icon={<TouchAppIcon />}
              label="Submit"
              loading={loading}
              onClick={handleSave}
            />
          </div>
        </div>
      </DialogActions>
    </div> : "Loading..."}
    </>

  );
};
