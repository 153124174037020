import React from 'react';
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, Button, MenuItem } from '@mui/material';
import { UpdateDocumnet } from '../services/services';
import { ToastContainer, toast } from 'react-toastify';
import { useState } from 'react';
import VerticalProgressBar from '../../../common/ProgressBar/VerticalProgressBar';

export const Replacemsg = (props) => {

    //#region props
    const { open, onClose,steps,onParentClose} = props;

    //#region click events
    const handleClose = () => {
        onClose();
        onParentClose();
    }

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>
                 Documents Status
            </DialogTitle>
            <DialogContent>
                <div>
                   <VerticalProgressBar steps={steps} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    className="btn-primary"
                    component="label"
                    variant="contained"
                    onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}
