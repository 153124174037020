import axios from "axios";
import resolve from "../../common/resolve";
import axiosApiCall from "../../Services/axiosApiCall";
import { getAPIResponse } from "../../../utils/common";


export async function getFolders() {
    return await axiosApiCall.get(`/v1/VirtualDataRooms/GetFoldersAndDocumentTypesByVDRType?VDRType=${"Fund"}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getFolderswithtypeid(typeid) {
    return await axiosApiCall.get(`/v1/VirtualDataRooms/GetFoldersAndDocumentTypesByVDRType?VDRType=${typeid}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}
