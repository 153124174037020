import React, { useState,useRef, useEffect } from 'react';
import TextInput from '../../../common/TextInput/TextInput';
import "../../CompanyDetail.module.css"
import secureLocalStorage from 'react-secure-storage';
function CompanyProfile(props) {

  const [open, setOpen] = useState(true);
  const componentRef = useRef(null);
    useEffect(() => {
        componentRef.current.scrollIntoView({ behavior: 'smooth' });
    }, []);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [errors, setErrors] = useState({});

  const handleChange = (field, value) => {
    const validationResult = validateField(field, value);
        
        if (!validationResult.isValid) {
            setErrors(prevErrors => ({ ...prevErrors, [field]: validationResult.error }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, [field]: null }));    
        }
        props.data[field] = value;
        props.setapiData(props.data);
        validateAllErrors();
  };
    const validateField = (field, value) => {
      const validationRules = {
        companyName: (value, currState) => {
              value = value.trim(); 
              
              const isValid = /^[A-Za-z\s]+$/.test(value);
          
              return {
                  isValid,
                  error: isValid ? null : `Please enter a valid fund name `,
              };
          },
          companyHQZipCode: (value, currState) => {
            value = value.trim();
        
            const isValid = /^\d{5}(-\d{4})?$/.test(value);
        
            return {
                isValid,
                error: isValid ? null : 'Please enter a valid ZIP Code (Example:12345-6789 or 12345)',
            };
        },
        companyHQZipCode: (value, currState) => {
          value = value.trim();
      
          const isValid = /^\d{5}(-\d{4})?$/.test(value);
      
          return {
              isValid,
              error: isValid ? null : 'Please enter a valid ZIP Code (Example:12345-6789 or 12345)',
          };
      },
      };
  
      return validationRules[field] ? validationRules[field](value) : { isValid: true, error: null };
  };

    const MandatoryFieldErrors = (field, value) => {
        const fieldsToKeep = ['companyName','sectorId','companyHQAddress1','companyHQState','companyHQAddress2',
           'companyHQZipCode','companyHQCity','companyHQCountry'];

          const trimmedValues = { ...props.data };

          const filteredFields = fieldsToKeep.map((field) => {
            if (trimmedValues.hasOwnProperty(field)) {
              return { field, value: trimmedValues[field] };
            }
            return null;
          }).filter(Boolean);
          const Mandatory = filteredFields.map(({ field, value }) => ({
            field,
            value: value === "" ? null : value,
          }));
          const EmptyFields = Mandatory.filter(entry => entry.value === "" || entry.value === null).map(entry => entry.field);
          const error = `This field is required.`;
          EmptyFields.map((item)=>{
              setErrors(prevErrors => ({ ...prevErrors, [item]: error }));
          })
      };

  useEffect(()=>{
      if(props.Onboardingsteperrors === true){
          MandatoryFieldErrors();
      }
  },[props.Onboardingsteperrors])

  useEffect(()=>{
    validateAllErrors();
},[])
 
  const validateAllErrors = () =>{
   
    const errorsArray = Object.values(errors);
        const hasErrors = errorsArray.some(error => error !== null);
        const fieldsToAdd = ['companyName','sectorId','companyHQAddress1','companyHQState','companyHQAddress2',
                             'companyHQZipCode','companyHQCity','companyHQCountry'];
        const trimmedValues = { ...props.data };
        const filteredValues = Object.keys(trimmedValues)
          .filter(field => fieldsToAdd.includes(field))
          .reduce((obj, key) => {
            obj[key] = trimmedValues[key];
            return obj;
          }, {});

         console.log(filteredValues);
        const Mandatory =  Object.values(filteredValues);
        const MandatoryFields = Mandatory.some(error => error === null || error === "");
        
        if (hasErrors || MandatoryFields) {
            secureLocalStorage.setItem("PCCompanyOverview", true);
        } else {
            secureLocalStorage.setItem("PCCompanyOverview", false);
        }
  }
 
  return (
    <div ref={componentRef}>
          <div className="fundRow">
            <div className="fundDetail">
              <TextInput
                label="Company Name"
                name="companyName"
                defaultValue={props?.data?.companyName}
                onChange={(name, value) => handleChange(name, value)} 
                required={true}
                type="Onlytext"
                errormessage={errors.companyName || undefined} />

            </div>
            <div className="fundDetail">
               <TextInput
                type="select"
                label="Industry/Sector"
                name="sectorId"
                defaultValue={props?.data?.sectorId}
                errormessage={errors.sectorId || undefined}
                required={true}
                options={props.FiltersData?.sectorFocus?.map(sector => ({ label: sector.title, value: sector.sectorFocusId }))}
                onChange={(name, value) => handleChange(name, value)}
                 />
            </div>
          </div>
          <div className='fundRow'>
            <h7>Company Address</h7>
          </div>
          <div className="fundRow">
            <div className="fundDetail">
            <TextInput
                label="Address Line1"
                name="companyHQAddress1"
                type="limit"
                defaultValue={props?.data?.companyHQAddress1}
                errormessage={errors.companyHQAddress1 || undefined}
                required={true}
                onChange={(name, value) => handleChange(name, value)} />
            </div>
            <div className="fundDetail">
              <TextInput
                type="select"
                label="State"
                name="companyHQState"
                defaultValue={props?.data?.companyHQState}
                errormessage={errors.companyHQState || undefined}
                required={true}
                options={props?.FiltersData?.stateProvince?.map(state => ({ label: state.title, value: state.stateProvinceId }))}
                onChange={(name, value) => handleChange(name, value)} />
            </div>
          </div>
          <div className="fundRow">
            <div className="fundDetail">
             <TextInput
                label="Address Line2"
                name="companyHQAddress2"
                type="limit"
                defaultValue={props?.data?.companyHQAddress2}
                errormessage={errors.companyHQAddress2 || undefined}
                required={true}
                onChange={(name, value) => handleChange(name, value)} />
            </div>
            <div className="fundDetail">
              <TextInput
                label="Zipcode"
                name="companyHQZipCode"
                type="number"
                defaultValue={props?.data?.companyHQZipCode}
                required={true}
                onChange={(name, value) => handleChange(name, value)} 
                errormessage={errors.companyHQZipCode || undefined}

                />
            </div>
          </div>
          <div className="fundRow">
            <div className="fundDetail">
              <TextInput
                type="select"
                label="City"
                name="companyHQCity"
                defaultValue={props?.data?.companyHQCity}
                errormessage={errors.companyHQCity || undefined}
                required={true}
                options={props.FiltersData?.city?.map(city => ({ label: city.title, value: city.cityId }))}
                onChange={(name, value) => handleChange(name, value)} />
            </div>
            <div className="fundDetail">
              <TextInput
                type="select"
                label="Country"
                name="companyHQCountry"
                defaultValue={props?.data?.companyHQCountry}
                errormessage={errors.companyHQCountry || undefined}
                required={true}
                options={props?.FiltersData?.country?.map(country => ({ label: country.title, value: country.countryId }))}
                onChange={(name, value) => handleChange(name, value)} />
            </div>
          </div>
        
    </div>
  );
}
export default CompanyProfile;  