import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { uploadFileTypes } from './uploadComponentenum';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import './styles.css';
import { useEffect } from 'react';
import { validationMessages, validationsTypeEnum } from '../TextInput/appInputenum';
import { ToastContainer, toast } from 'react-toastify';

const FileUploadBtn = (props) => {

    //#region Props
    const {
        name,
        label,
        onChange,
        disabled,
        defaultFile,
        fileType,
        clearFile,
        required,
        checkIfValid,
        setcheckIfValid,
        maxSize,
        validationType,
        readOnly,
        validationMessage,
        checknoofValidFields, } = props;

    //#region variables
    const [file,setFile] = useState(null);
    const [error,setError] = useState(false);
    const fileInputRef = React.createRef();

    //#region change events
    const handleChange = (ev) => {
        const { name, files } = ev.target;
        if(fileType === uploadFileTypes.PDF) {
            if(files[0]?.type !== 'application/pdf') {
                setError(true);
                return;
            }
            else{
                const file = files[0];
                const maxFileSize = maxSize ? maxSize : 10 * 1024 * 1024;
                if (fileType === uploadFileTypes.IMAGE) {
                    setFile(URL.createObjectURL(files[0]))  // Create a preview URL for the uploaded file
                    onChange(name, file);
                }
                else {
                    if (file && file.size > maxFileSize) {
                        const maxFileSizeInMB = maxFileSize / (1024 * 1024); // Convert maxFileSize from bytes to MB
                        toast.warning(`File size exceeds the limit , please upload the file with size of ${maxFileSizeInMB}MB`,
                              { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
                              if (fileInputRef.current) {
                                fileInputRef.current.value = null; // Reset the file input element
                              }
                    }
                    else{
                        setFile(files[0]); 
                        onChange(name, file);
                    }
                }
            }
        }
        else if(fileType === uploadFileTypes.CSV){
            if (
                files[0]?.type !== 'text/csv' &&
                !files[0]?.type.includes('excel') &&
                !files[0]?.type.includes('spreadsheetml')
            ) {
                setError(true);
                setFile(null);
                toast.warning(
                    "The file type should be either Excel or CSV",
                    { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" }
                );
                return;
            }            
            else{
                const file = files[0];
                const maxFileSize = maxSize ? maxSize : 10 * 1024 * 1024;
                if (fileType === uploadFileTypes.IMAGE) {
                    setFile(URL.createObjectURL(files[0]))  // Create a preview URL for the uploaded file
                    onChange(name, file);
                }
                else {
                    if (file && file.size > maxFileSize) {
                        const maxFileSizeInMB = maxFileSize / (1024 * 1024); // Convert maxFileSize from bytes to MB
                        toast.warning(`File size exceeds the limit ${maxFileSizeInMB}MB`,
                              { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
                    }
                    else{
                        setFile(files[0]); 
                        onChange(name, file);
                    }
                }
            }
        }
        else{
            const file = files[0];
            const maxFileSize = maxSize ? maxSize * 1024 * 1024 : 10 * 1024 * 1024;
            if (file && file.size > maxFileSize) {
                const maxFileSizeInMB = maxFileSize / (1024 * 1024); // Convert maxFileSize from bytes to MB
                toast.warning(`The image should be in JPG or PNG format and should not exceed ${maxSize}MB in size.`,
                      { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
                if (fileInputRef.current) {
                    fileInputRef.current.value = null; // Reset the file input element
                }
                return
            }
            if (fileType === uploadFileTypes.IMAGE) {
                setFile(URL.createObjectURL(files[0]))  // Create a preview URL for the uploaded file
            }
            else {
                setFile(files[0]); 
            }
            onChange(name, file);
        }
    }

    //#region click events
    const handleClearFile = () => {
        setFile(null);
        onChange(name,null);
    }

    //#region Validations useEffect
    useEffect(() => {
        if(checkIfValid) {
            const isValid = file === '' || file === null ? false : true;  
            if(isValid) {
                    checknoofValidFields(isValid,name,file);   
                    setcheckIfValid(false);
            } else {
                setError(!isValid); 
                setcheckIfValid(false);
            }
        }
    },[checkIfValid])

    useEffect(() => {
        if(defaultFile && defaultFile !== '') {
            if (fileType === uploadFileTypes.IMAGE) {
                if (defaultFile instanceof Blob || defaultFile instanceof File) {
                    const fileURL = URL.createObjectURL(defaultFile);
                    setFile(fileURL);
                } else {
                    setFile(defaultFile);
                }
            }            
            else {
                setFile(defaultFile);
            }
        }
    }
    ,[defaultFile])

    useEffect(()=>{
        if(clearFile || disabled){
            setFile(null);
        }
    },[clearFile,disabled])

    //#region render accept type
    const renderAcceptType = () => {
        switch (fileType) {
            case uploadFileTypes.PDF:
                return uploadFileTypes.PDF_TYPES;
            case uploadFileTypes.IMAGE:
                return uploadFileTypes.IMAGE_TYPES;
            default:
                return '';
        }
    }

    //#region render File Preview
    const renderFilePreview = () => {
        if (file) {
            return (
                <div className='fileUploadBtn previewWrapper'>
                    {
                        fileType === uploadFileTypes.IMAGE ? <img src={file} alt='Preview' className='file-preview'/> : 
                        <div className='upload-preview'>{file?.name}</div>
                    }
                     {!readOnly && <CloseOutlinedIcon onClick={handleClearFile}/>}
                </div>
            )
        }
    }

    //region render upload file button
    const renderFilebutton = () => {
        const classses = disabled ? (error ? 'filebtn-disabled-error' : 'filebtn-disabled') : (error ? 'fileUploadBtn file-error' : 'fileUploadBtn');
        return (
            <div className='display-column'>
                <div className={classses}>
                    <CloudUploadIcon />
                    <input
                        type="file"
                        id={name}
                        name={name}
                        onChange={handleChange}
                        disabled={disabled}
                        required={required}
                        ref={fileInputRef}
                        accept={renderAcceptType()}
                        hidden />
                    <label htmlFor={name}>{label}</label>
                    <br/>
                </div>
                {error && <span className='errorMessage'>{
                    validationMessage
                }</span>}
            </div>
        )
    };

  //#region end
  return (
    <>
      {
        file ? renderFilePreview() : renderFilebutton()
      }
    </>
  )
}

FileUploadBtn.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    defaultFile: PropTypes.string,
    clearFile: PropTypes.bool,
    fileType: PropTypes.string,
    required: PropTypes.bool,
    helperText: PropTypes.string,
}

FileUploadBtn.defaultProps = {
    label: 'Upload Document',
    name: 'file',
    disabled: false,
    defaultFile: '',
    clearFile: false,
    fileType: uploadFileTypes.ANY,
    required: false,
    helperText: '',
}


export default FileUploadBtn