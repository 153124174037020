import { Outlet } from "react-router-dom";
import MainSiteContainer from "./MainSiteContainer";

const MainSiteLayout = () => {
  return (
    <>
      <MainSiteContainer>
        <Outlet />
      </MainSiteContainer>
    </>
  );
};

export default MainSiteLayout;
