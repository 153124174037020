import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Grid, TextField } from '@mui/material';
import { useState } from 'react';
import ActionButton from '../../common/ActionButton/ActionButton';
import * as yup from 'yup';
import { useFormik } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import { createKeyValuePair } from '../services/services';
import { ToastContainer, toast } from 'react-toastify';
import CancelIcon from '@mui/icons-material/Cancel';
const AddShareType = (props) => {

    //#region props
    const { open, onClose,getShareTypeValues } = props;

    //#region initalData
    const initialData = {
        shareTypeName: '',
        shareTypeDescription: '',
    }

    //#region variables
    const [loading, setLoading] = useState(false);

    //#region api post calls
    const createShareType = async() => {
        setLoading(true);
        const data = await createKeyValuePair("ShareType",shareTypeValues.values.shareTypeName);
        if(data.responseCode === 200){
            getShareTypeValues();
            toast.success("Share Type Created Succesfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                theme: "colored",
                });
            setLoading(false);
            onClose();
        }
        else{
            toast.warning("Error Creating Share Type", {
                position: toast.POSITION.BOTTOM_RIGHT,
                theme: "colored",
               });
            setLoading(false);
            onClose();
        }
    }

    //#region formik validations
    const shareTypeValidationSchema = yup.object().shape({
        shareTypeName: yup.string().required('This Field is required'),
    });

    const shareTypeValues = useFormik({
            initialValues: {
                ...initialData
            },
            validationSchema: shareTypeValidationSchema,
            onSubmit: (values) => {
                createShareType();
            },
    });

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>
               <h6> Add Share Type </h6>
            </DialogTitle>
            <form onSubmit={shareTypeValues.handleSubmit} novalidate="novalidate" className='margin-top-minus-10'>
                <DialogContent>
                <div className='child-margin-15'>
                    <div>
                        <TextField
                            id="shareTypeName"
                            name="shareTypeName"
                            label="Share Type Name"
                            variant='standard'
                            fullWidth
                            value={shareTypeValues?.values?.shareTypeName}
                            onChange={shareTypeValues.handleChange}
                            InputLabelProps={{
                                shrink: true,
                                focused: true,
                            }}
                            required={true}
                            onBlur={shareTypeValues.handleBlur}
                            error={shareTypeValues.touched.shareTypeName && Boolean(shareTypeValues.errors.shareTypeName)}
                            helperText={shareTypeValues.touched.shareTypeName && shareTypeValues.errors.shareTypeName} />
                    </div>
                    <div>
                        <TextField
                            id="shareTypeDescription"
                            name="shareTypeDescription"
                            placeholder="Please Provide Brief Description About Share Type"
                            variant='outlined'
                            multiline
                            fullWidth
                            rows={3}
                            value={shareTypeValues?.values?.shareTypeDescription}
                            onChange={shareTypeValues.handleChange}
                            InputLabelProps={{
                                shrink: true,
                                focused: true,
                            }}
                            onBlur={shareTypeValues.handleBlur}
                            error={shareTypeValues.touched.shareTypeDescription && Boolean(shareTypeValues.errors.shareTypeDescription)}
                            helperText={shareTypeValues.touched.shareTypeDescription && shareTypeValues.errors.shareTypeDescription} />
                    </div>
                </div>
                </DialogContent>
                <DialogActions>
                    <div className='space-between'>
                        <div>
                            <ActionButton variant='outlined' onClick={onClose} label='Cancel' icon={<CancelIcon/>} />
                        </div>
                        <div className='margin-left-10'>
                            <LoadingButton
                                variant='contained'
                                size="large"
                                startIcon={<AddIcon />}
                                loading={loading}
                                onClick={()=>{}}
                                type='submit'
                                loadingPosition="start">
                                <p className={`actionButtonLabel`}>ADD</p>
                            </LoadingButton>
                        </div>
                    </div>
                </DialogActions>
            </form>
        </Dialog>
    )
}
export default AddShareType;