import TextInput from "../../../common/TextInput/TextInput";
import Text from "../../../common/Text/Text";
import './styles.css';
import { useState, useEffect, useRef } from "react";
import json from '../../filterbox.json';
import secureLocalStorage from "react-secure-storage";
import Button from '@mui/material/Button';
import AppTextInput from "../../../common/TextInput/AppTextField";
import { validationsTypeEnum, InputTypes, validationMessages, textFormatTypeEnum } from "../../../common/TextInput/appInputenum";
import ActionButton from "../../../common/ActionButton/ActionButton";
import { ToastContainer, toast } from 'react-toastify';

const FeesExpenses = (props) => {
    const initialFundDetails = {
        managementFees: '',
        performanceFeesOrCarriedInterest: '',
        additionalFeesAndExpenses: '',
        ...props.data,
    }

    const [feesExpensesDetails, setFeesExpensesDetails] = useState(initialFundDetails)

    const componentRef = useRef(null);
    useEffect(() => {
        componentRef.current.scrollIntoView({ behavior: 'smooth' });
    }, []);

    const handleChange = (field, value,) => {
        const currState = { ...feesExpensesDetails };
        currState[field] = value;
        setFeesExpensesDetails(currState);
        /* props.updateData(currState); */

        /*  for create fund */
        if (props.FeesExpenses) {
            props.FeesExpenses[field] = value;
        }
    };

    const isFundMatured = secureLocalStorage.getItem("selectedFund")?.FundMaturity;;
    const isFundTerminated = secureLocalStorage.getItem("selectedFund")?.FundTermination;

    const [noofValidFields, setNoofValidFields] = useState({ validfields: 0 });

    const [checkIfValid, setcheckIfValid] = useState(false);

    //#region fucntions
    const validateMessage = (value) => {
        const intValue = parseInt(value);
        if (value === "") {
            return validationMessages.REQUIRED
        }
        if (intValue <= 0) {
            return "value should be greater than zero"
        }
        if (intValue > 0) {
            return "percentage value cannot be more than 100"
        }
    }

    //intialCount
    let count = 0;   //to Show Toast Message only once for edit fund
    const checknoofValidFields = (isValid, name, defaultValue) => {
        count = ++count;
        const TotalFiledsToValidate = 3;
        noofValidFields['validfields'] = isValid ? noofValidFields['validfields'] + 1 : noofValidFields['validfields'];
        if (noofValidFields['validfields'] === TotalFiledsToValidate) {
            noofValidFields['validfields'] = 0;
            if (props.isedit === true) {
                props.handleClickSave(props.index, feesExpensesDetails)
                count = 0;
            }
            else {
                props.handleSaveData();
                props.setFundDetailErrors(false);
            }
        }
        else {
            if (props.isedit === true) {
                if (count === TotalFiledsToValidate) {
                    toast.warning("Please Verify the input fields",
                        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored", style: { zIndex: 9999 } });
                    count = 0;
                }
            }
            else {
                props.setSubmittbtn(false);
            }
        }
    };

    const handleSave = () => {
        setcheckIfValid(true);
        noofValidFields['validfields'] = 0;
    }

    /* useEffect(() => {
        if (props.FundDetailerrors === true) {
            handleSave();
        }
    }, [props.FundDetailerrors]) */

    useEffect(() => {
        return () => {
            props?.updateData(feesExpensesDetails);
        }
    }, [feesExpensesDetails]);

    return (
        <div ref={componentRef}>
            {props.isedit === true && (
                <div className="fundRow no-margin" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div>
                        <div className="btns">
                            {
                                !isFundTerminated && !isFundMatured ? (props.editable ? (
                                    <>
                                        <div>
                                            <ActionButton variant="outlined" className="btn-primary"
                                                onClick={() => props.handleClickCancel(props.index)}
                                                label="CANCEL"
                                                startIconName="Cancel"
                                            >

                                            </ActionButton>
                                        </div>
                                        <div className="margin-left-5">
                                            <ActionButton variant="contained" className="btn-primary"
                                                onClick={() => handleSave()}
                                                /* disabled={Object.values(errors).some(error => error !== null)} */
                                                label="SAVE"
                                                startIconName="Save"
                                            >

                                            </ActionButton>
                                        </div>

                                    </>
                                ) : (
                                    <ActionButton variant="contained" className="btn-primary"
                                        onClick={() => props.handleClickEdit(props.index)}
                                        label="EDIT"
                                        startIconName="Edit"
                                    >
                                        EDIT
                                    </ActionButton>
                                )) : null
                            }
                            {/* {!isFundTerminated && !isFundMatured && (
                        <button
                            onClick={() => props.handleClickCancel(props.index)}
                            className="Button"
                        >
                            CANCEL
                        </button> )
                     } */}
                        </div>
                    </div>
                </div>
            )}
            <div className="fundRow"><Text label="Fees and Expenses" /></div>
            <div className="fundRow">
                <div className="fundDetail">
                    <AppTextInput
                        textFormatType={textFormatTypeEnum.PERCENTAGE}
                        name="managementFees"
                        label="Management Fees"
                        defaultValue={feesExpensesDetails?.managementFees}
                        onChange={(name, value) => handleChange(name, value)}
                        disabled={props?.isedit ? !(props.editable) : false}
                        validateField={true}
                        validationType={validationsTypeEnum.PERCENTAGE}
                        validationMessage={validateMessage(feesExpensesDetails?.managementFees)}
                        checkIfValid={checkIfValid}
                        checknoofValidFields={checknoofValidFields}
                        setcheckIfValid={setcheckIfValid} />
                </div>
                <div className="fundDetail">
                    <AppTextInput
                        type={InputTypes.SELECT}
                        name="performanceFeesOrCarriedInterest"
                        label=" Performance Fees / Carried Interest"
                        defaultValue={feesExpensesDetails?.performanceFeesOrCarriedInterest}
                        options={json.PerformanceOptions?.map(option => ({ label: option.name, value: option.id }))}
                        onChange={(name, value) => handleChange(name, value)}
                        disabled={props?.isedit ? !(props.editable) : false}
                        validateField={true}
                        validationType={validationsTypeEnum.REQUIRED}
                        validationMessage={validateMessage(feesExpensesDetails?.performanceFeesOrCarriedInterest)}
                        checkIfValid={checkIfValid}
                        checknoofValidFields={checknoofValidFields}
                        setcheckIfValid={setcheckIfValid} />
                </div>
            </div>
            <div className="fundRow">
                <div className="fundDetail">
                    <AppTextInput
                        textFormatType={textFormatTypeEnum.PERCENTAGE}
                        name="additionalFeesAndExpenses"
                        label="Additional Fees and Expenses"
                        defaultValue={feesExpensesDetails?.additionalFeesAndExpenses}
                        onChange={(name, value) => handleChange(name, value)}
                        disabled={props?.isedit ? !(props.editable) : false}
                        validateField={true}
                        validationType={validationsTypeEnum.PERCENTAGE}
                        validationMessage={validateMessage(feesExpensesDetails?.additionalFeesAndExpenses)}
                        checkIfValid={checkIfValid}
                        checknoofValidFields={checknoofValidFields}
                        setcheckIfValid={setcheckIfValid} />
                </div>
            </div>
            {!props.isedit && (
                <div className="fundRowCenter">
                    <ActionButton
                        label="SAVE"
                        className='btn-primary'
                        variant='contained'
                        loading={props.loading}
                        startIconName="SaveOutlined"
                        onClick={() => handleSave()}
                    />
                </div>
            )}
        </div>
    )
}
export default FeesExpenses;