import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { border } from '@mui/system';

export default function Accordian(props) {

  const [expanded, setExpanded] = React.useState();

  console.log('props', props?.body.props);

  const handleChange = (isExpanded) => {
    setExpanded(isExpanded);

    if (props?.onChange) {
      props?.onChange(props?.body?.props)
    }; //
  };

  return (
    <div>
      <Accordion style={{ border: '1px lightgray solid' }}
        expanded={expanded}
        defaultExpanded={props?.defaultExpanded}
        onChange={(event, isExpanded) => handleChange(isExpanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography variant='h6' sx={{ width: '60%', flexShrink: 0, fontWeight: '600' }}>
            {props?.title}
          </Typography>

        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {props?.body}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}