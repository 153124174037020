import axios from "axios";
import resolve from "../../common/resolve";
import Configurations from "../../../Configurations";
import axiosApiCall from "../../Services/axiosApiCall";
import { getAPIResponse } from "../../../utils/common";

export async function capitalCall() {
  return await resolve(axios.get(`${Configurations.apiBaseUri}/v1/Servicing/GetCapitalCall?fundID=36&capitalCallID=0`)
    .then(res => res.data));
}

export async function GetCurrentCapitalCall(fundid) {
  const response = await axios.get(`${Configurations.apiBaseUri}/v1/Servicing/CapitalCall/List?fundID=${fundid}`);
  const data = response.data;

  let ccid;

  if (data.length === 0) {
    ccid = 0;
  } else {
    ccid = data[0].capitalCallID;
  }

  return await GetCapitalCallByID(fundid, ccid);
}

// export async function GetCapitalCallByID(fundid, ccid) {
//   const apiURL = `${Configurations.apiBaseUri}/v1/Servicing/CapitalCall/Get?fundID=${fundid}&capitalCallID=${ccid}`;
//   const response = await axios.get(apiURL);
//   return response.data;
// }


// export async function GetCapitalCallsForFund(fundid) {
//   const response = await axios.get(`${Configurations.apiBaseUri}/v1/Servicing/CapitalCall/List?fundID=${fundid}`);
//   return response.data;
// }
//const response = await fetch(`${Configurations.apiBaseUri}/v1/Fund/GetFundById?fundID=${fundId}`, {

export async function GetFundByID(fundid) {
  const response = await axios.get(`${Configurations.apiBaseUri}/v1/Fund/GetFundById?fundID=${fundid}`);
  return response.data;
}


export async function GetFundDistributionsForFund(fundid) {
  const response = await axios.get(`${Configurations.apiBaseUri}/v1/Servicing/GetFundDistributions?fundID=${fundid}`);
  return response.data;
}


export async function getCurrentDistributions(fundid) {
  return await axiosApiCall.get(`/v1/Servicing/GetFundDistributions?fundID=${fundid}`)
      .then(res => {
          return getAPIResponse(res);
      }).catch(error => {
          return getAPIResponse(error);
      });
}

export async function GetCapitalCallsForFund(fundid) {
  return await axiosApiCall.get(`/v1/Servicing/CapitalCall/List?fundID=${fundid}`)
      .then(res => {
          return getAPIResponse(res);
      }).catch(error => {
          return getAPIResponse(error);
      });
}

export async function GetCapitalCallByID(fundid,ccid) {
  return await axiosApiCall.get(`/v1/Servicing/CapitalCall/Get?fundID=${fundid}&capitalCallID=${ccid}`)
      .then(res => {
          return getAPIResponse(res);
      }).catch(error => {
          return getAPIResponse(error);
      });
}

export async function GetDistributionsByID(fundid, ccid) {
  /* const apiURL = `${Configurations.apiBaseUri}/v1/Servicing/GetDistributionByID?fundID=${fundid}&DistributionID=${ccid}`;
  const response = await axios.get(apiURL); */
  return ;
}
 
export async function ResendNotification(NotificationType, fundID, CCid , newDueDate , capitalCallNotes 
  ) {
  try {
    const response = await axios.put(`${Configurations.apiBaseUri}/v1/Servicing/NotifyCapitalCallUsers?NotificationType=${NotificationType}&fundID=${fundID}&capitalCallID=${CCid}&newDueDate=${newDueDate}&capitalCallNotes=${capitalCallNotes}`);
    return response.data;
  }
  catch (error) {
    console.error('Error updating leadership team details:', error);
    throw error;
  }
}

export async function DeleteCapitalCall(capitalCallID) {
  return await axiosApiCall.post(`/v1/Servicing/CapitalCall/Delete?capitalCallID=${capitalCallID}`)
      .then(res => {
          return getAPIResponse(res);
      }).catch(error => {
          return getAPIResponse(error);
      });
}


export async function postCapitalcallData(data) {
  return await axiosApiCall.post(`/v1/Servicing/CapitalCall/Set`,data)
      .then(res => {
          return getAPIResponse(res);
      }).catch(error => {
          return getAPIResponse(error);
      });
}
