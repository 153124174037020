import React from 'react'
import { getTenantRoleFeatures, resetingRoleFeautures } from '../Services/TabsData';
import {useState, useEffect} from "react";
import {Container} from '@mui/material';
import RFAccordion from './RoleFeatureAccordion';
import { ToastContainer, toast } from 'react-toastify';
import LinearProgress from '@mui/material/LinearProgress';
import RoleFeautreAccordion from './RoleFeatureAccordion';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { LoadingButton } from '@mui/lab';
import RoleFeatureAccordion from './RoleFeatureAccordion';
import ResetConfirmation from '../popups/ResetConfirmation';

function RoleFeatures(props) {

  //#region props
  const { appRoles } = props;

  //#region variables
  const [roleFeatures, setroleFeatures] = useState([]); 
  const [expanded, setExpanded] = useState({});
  const [resetLoading,setResetLoading] = useState(false);
  const [resetPopup,setResetPopup] = useState(false);

  //#region click events
  const handleAccordionClick = (panel) => (event, isExpanded) => {
    setExpanded({ ...expanded, [panel]: isExpanded });
  };

  const openResetPopup = () =>{
   setResetPopup(true);
  }

  const closeResetPopup = () =>{
    setResetPopup(false);
  }


  //#region api get calls
  const getAllRoleFeatures = async () =>{
    const data = await getTenantRoleFeatures();
        if (data.responseCode === 200) {
          setroleFeatures(data.responseData);
        }
        else {
        }
  }

  const resetRoleFeatures = async () => {
    closeResetPopup();
    setResetLoading(true);
    const data = await resetingRoleFeautures(0,0);
    if (data.responseCode === 200) {
      getAllRoleFeatures();
      toast.success("All role features have been successfully reset",
         { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      setResetLoading(false);
    }
    else {
      toast.error("Unable to reset role feautures. please try again",
             { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      setResetLoading(false);
    }
  }

  //#region useeffect
  useEffect(() => {
      getAllRoleFeatures();
  }, []);
  
  //#region return
  return (
    
    <div>
      <h4>Role Features</h4> 
      <div className='white-card margin-top-10'>
        <div className='space-between'>
          <div> </div>
          <div>
            <LoadingButton
                    variant='outlined'
                    className='btn-primary'
                    loading={resetLoading}
                    startIcon={<RotateLeftIcon />}
                    onClick={() => openResetPopup()} 
                    loadingPosition="start">
                        <p className={`actionButtonLabel`}>RESET</p>
              </LoadingButton>
          </div>
        </div>
        <div className='margin-top-10'>
          {appRoles.length > 0 && roleFeatures?.length > 0 ? appRoles?.map((role)=>{
              return (
                <RoleFeatureAccordion 
                    roleName={role.roleName} 
                    roleID={role.roleId}
                    primaryroleID={role.primaryRoleID}
                    expanded={expanded[role.roleId] || false}
                    setExpanded={setExpanded}
                    isEditEnabled={!(role.isPrimaryRole)}
                    onAccordianClick={handleAccordionClick}
                    getAllRoleFeatures={getAllRoleFeatures}
                    gridRowsData={roleFeatures.filter(u=>u.roleName === role.roleName)} />
              )
          }) : <LinearProgress />}
        </div>
      </div>
      {
        resetPopup && 
        <ResetConfirmation 
           open={resetPopup}
           onClose={closeResetPopup}
           onAccept={resetRoleFeatures}/>
      }
      <ToastContainer/>
    </div>
  )
}

export default RoleFeatures
