import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react'
import propTypes from 'prop-types';

const CheckBoxField = (props) => {

  //#region props
  const {
    name,
    label,
    value,
    onChange,
    options,
    variant,
    onBlur,
    required,
    disabled,
    error,
    errorMessage,
    helperText,
    readOnly,
    defaultChecked
  } = props;

  //#region change events
  const handleChange = (ev) => {
    const { checked } = ev.target;
    onChange(name, checked);
}
    
  return (
    <div>
       <FormControlLabel 
           required={required}
           control={<Checkbox value={value} defaultChecked={defaultChecked} readOnly={readOnly} />}
           label={label}
           disabled={disabled}
           onChange={handleChange} />
    </div>
  )
}

CheckBoxField.propTypes = {
    type: propTypes.string,
    onChange: propTypes.func.isRequired,
    variant: propTypes.oneOf(['standard', 'filled', 'outlined']),
    required: propTypes.bool,
    value: propTypes.string,
    placeholder: propTypes.string,
    label: propTypes.string,
    disabled: propTypes.bool,
    options: propTypes.array,
    name: propTypes.string,
    size: propTypes.string,
    errorMessage: propTypes.string,
    helperText: propTypes.string,
    readOnly: propTypes.bool,
    defaultChecked: propTypes.bool
};

CheckBoxField.defaultProps = {
    type: 'select',
    variant: 'standard',
    size: 'small',
    required: false,
    value: '',
    placeholder: '',
    label: '',
    options: [],
    disabled: false,
    name: '',
    errorMessage: '',
    helperText: '',
    readOnly: false,
    defaultChecked: false
};

export default CheckBoxField