import secureLocalStorage from "react-secure-storage";

export const InitialFundDataTemplate = {
    fundID: 0,
     fundName: "",
    investmentStrategyId: 1,
    fundTargetIndustryId: 1,
    fundSize: 0,
    minimumInvestmentAmount: 0,
    fundRaisingStatus: 1,
    inceptionDate: "2023-07-05T11:58:21.539Z",
    fundTypeName: 1,
    geographicFocusId: 1,
    fundStatus: 1,
    firstInvestmentDate: "2023-07-05T11:58:21.539Z",
    fundClosingDate: "2023-07-05T11:58:21.539Z",
    targetFundSize: 0,
    numberofPortfolioCompanies: 33,
    targettedIRR: 10,
    targetedreturn: 10,
    investmentCycle: 0,
    term: 0,
    liquidity: 0,
    maximumInvestmentSize: 10,
    legalForm: "",
    fundCurrency: 0,
    userID: secureLocalStorage.getItem("userId"),
    investorRelationsContact: "",
    valuationPolicy: "",
    esgPolicy: "",
    carriedInterest: 0,
    capitalCallDate: "2023-07-05T11:58:21.539Z",
    totalCapitalCallAmount: 0,
    fundDescription: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore.",
    fundInceptionDate: "2023-07-05T11:58:21.539Z",
    lastOfferingDate: "2023-07-05T11:58:21.539Z",
    fundStaff:'',
    equityInvestment: 70,
    creditInvestment: 30,
    vintage: 2023,
    capitalCalled: "100% and Fully Deployed",
    bgImage: "",
    iconImage: "",
    fundStaff: "",
    visibility: "hide",
    dscInvestmentStrategy: "",
    dscInvestmentCriteria: "",
    dscInvestmentProcess: "",
    dscRiskManagementApproach: "",
    dscValueCreationAndOperImprovementStrategies: "",
    dscExitStrategies: "",
    aum: 3000000.00,
    capitalCommitted: 2000000.00,
    strategyName: "",
    targetIndustry: "",
    fundTypeName: "",
    fundRaisingStatusName: "1",
    fundCompanyName: "",
    favourite: true,
    compare: true,
    investmentPeriodCriteria : 5,
    strategyDocument : {},
    legalStructureID: 1,
    TenantID : secureLocalStorage.getItem("tenantID"),
    managementTeamDetails: [
      {
        fundManagementTeamID: 0,
        fundID: 0,
        
        // firstName: "",
        // lastName: "",
        managementTeamStaff: 0,
        description: "",
        title: "",
        // emailAddress: "",
        // addressLine1: "",
        // addressLine2: "",
        // phoneNumber: "",
        // city: "",
        // state: 0,
        // country: 0,
        // zipCode: ""
      }
    ],
    portfolioCompanies: [
      {
        companyId: 0,
        fundID: 0,
        companyName: "",
        investmentStrategy: 1,
        targetIndustry: 1,
        yearFounded: "2023-07-05T11:58:21.539Z",
        dscInvestmentHighlightsAndRationle: "",
        dscKPIsAndProgessUpdates: "",
        dscExitsAndRealizedReturns: "",
        headQuartersLocation: "US",
        investmentStage: "C Stage",
        investmentCommitment: "27000000.00",
        portfolioCompanyStatus: "Performing",
        fundOwnershipPercentage: "38.00"
      }
    ],
    feesExpenses: [
      {
        id: 0,
        fundID: 0,
        managementFees: 0,
        performanceFeesOrCarriedInterest: 0,
        additionalFeesAndExpenses: 0
      }
    ],
    legalCompliance: {
      offeringDocuments: {},
      subscriptionDocuments: {},
      complianceDocuments: {}
    }
  }

export const InitialFundManagers = {
    id : 0,
    userID : 0,
    description : ""
}

export const FundTypes = {
  PRIVATE_CREDIT: 'Private Credit Fund',
  PRIVATE_EQUITY : 'Private Equity Fund',
}