import React from 'react'
import { useCallback, useState, useEffect, useContext } from "react";
import AIPDataGrid from '../../common/DataGrid/AIPDataGrid';
import jsonData from '../UserDetails.json'
import Button from '@mui/material/Button';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import AddRole from '../popups/AddRole';
import secureLocalStorage from 'react-secure-storage';
import {  getTenantDashboards } from '../Services/TabsData';
import { checkFeaturePermissions } from '../../../utils/common';
import { FeatureAccess, RolesAndPermissions } from '../../../utils/enum';
import AddIcon from '@mui/icons-material/Add';
import { ToastContainer, toast } from 'react-toastify';
import { DataGridPro } from '@mui/x-data-grid-pro';

const Roles = (props) => {

  //#region varibales
  const [dashboards, setDashboards] = useState(null);
  const [open, setOpen] = useState(false);
  const [UsersRowsData,setUsersRowsData] = useState([]);
  const columns = [
    { field: 'roleName', headerName: 'Role Name', width: 300,},
    { field: 'description', headerName: 'Description', width: 300 },
    { field: 'dashboard', headerName: 'Dashboard', width: 300, },
  ]

  //#region click events
  const AddRoleButtonClick = () => {
    setOpen(true);
  }

  const closeButtonClick = () => {
    setOpen(false);
  }

  //#region api get calls
  const getDashboardData = async () => {
    const data = await getTenantDashboards();
    if (data.responseCode === 200) {
      setDashboards(data.responseData?.filter(role => role.title?.toLowerCase() != "investor" || "Portfolio Company"));
    }
    else {
      console.log("Unable to Update the Role")
    }
  }

  //#region useeffect
  useEffect(() => {
    getDashboardData();
  }, []);

  useEffect(()=>{ 
    console.log(dashboards)
    if(props.appRoles){
      const rowsData = props.appRoles ? props.appRoles.map(user => ({
        id: user.roleId,
        "roleName": user.roleName,
        "description": user.description,
        "dashboard": dashboards ? dashboards?.find((item)=> item.applicationDashboardId === user.applicationDashboardId )?.title : ''
      })) : [];
      // debugger
      setUsersRowsData(rowsData);
    }
  },[props.appRoles,dashboards])

  //#region return
  return (
    <div> 
      <div className='space-between'>
        <div> <h4>Roles</h4> </div>
        <div>
          {
            checkFeaturePermissions(RolesAndPermissions.SITE_ADMINISTRATION, FeatureAccess.CREATE) &&
            <Button
              variant="outlined"
              className='btn-primary'
              onClick={AddRoleButtonClick}>
              <AddIcon />
              ADD ROLE
            </Button>
          }
        </div>
      </div>
      <div className='white-card margin-top-5 height-400-scroll'>
        <DataGridPro 
          columns={columns} 
          rows={UsersRowsData}
          hideFooter />
      </div>
      {open &&
        <AddRole
          open={open}
          setOpen={setOpen}
          excistedRoles={props.appRoles}
          onClose={closeButtonClick}
          setRoles={props.refreshRoles}
          dashboardDropdown={dashboards} />}
      <ToastContainer />
    </div>
  )
}

export default Roles
