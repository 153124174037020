import React, { useEffect, useState } from 'react'
import { fundTypesEnum } from '../../marketplace-dashboard/jsonData';
import FundGridView from '../../common/StackView/FundGridView';
import { getKeyValuePairs } from '../../portfolio-companies/services/services';
import secureLocalStorage from 'react-secure-storage';
import SelectField from '../../common/input-fields/SelectField';
import { FieldVariants } from '../../common/TextInput/appInputenum';
import { fundColumns, myInvestmentsprivateCreditColumns, myInvestmentsprivateEquityColumns } from '../../investor-dashboard/fundTypeValues';
import { getInvestorSubscribedFunds } from '../../investor-dashboard/services/services';
import { AppDashboards } from '../../../utils/enum';
import { useNavigate } from 'react-router-dom';
import { fundSubscriptionStatues } from '../../CODashboard/Services/apiDataTempate';
import { subscriberStatusDropdown, subscriberStatusEnum } from '../../FundManagerDashboard/fundTypeValues';

const InvestorInvestmentsGrid = (props) => {

  //#region variables
  const { investorID } = props;

  //#region initialdata
  const initialData = {
      'fundStatus': subscriberStatusEnum.ALL,
      'fundType': fundTypesEnum.ALL
  }  
  
   //#region variables
  const navigate = useNavigate();
  const isInvestorLogin = secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD 
  const [filters,setFilters] = useState(initialData); 
  const [investments,setInvestments] = useState([]);
  const [filteredInvestments,setFilteredInvestments] = useState([]);
  const [investmentColumns,setInvestmentColumns] = useState(fundColumns); 
  const [fundTypes,setFundTypes] = useState([]);

  //#region functions
  const currFundType = () => {
    return fundTypes?.find((item)=>item.value === filters.fundType)?.label;
  }

  const currFundStatus = () => {
        /* return fundStatus?.find((item)=>item.value === filters.fundStatus)?.label; */
        return filters.fundStatus
  }
  
  //#region change events
  const handleFilterChange = (name, value) => {
      setFilteredInvestments([]);
      setFilters((prevFilters) => ({
          ...prevFilters,
          [name]: value,
      }));
  };

  //#region click events
  const onRowClick = (params) => {
      if(isInvestorLogin){
        onInvestorFundClick(params);
      }
      else{
        onFirmFundClick(params);
      }
  }

  const onInvestorFundClick = (params) => {
    secureLocalStorage.setItem("FundId", params.row.id);
    if (params.row.subscriptionStatus === 'Approved') {
      navigate('/Investor-Overview-Page', {
        state: {
          FundId: params.row.id,
          subscriptionId: params.row.subscriptionID
        }
      });
      window.scrollTo(0,0);
    }
    else {
      secureLocalStorage.setItem("FundId", params.row.id);
      navigate('/subscription-details', {
        state: {
          FundId: params.row.id,
          subscriptionId: params.row.subscriptionID,
          selectedRow: params.row,
        }
      });
      window.scrollTo(0,0);
    }
  }

  const onFirmFundClick = (params) => {
    secureLocalStorage.setItem("FundId", params?.row?.id);
    if (params.row.subscriptionStatus === fundSubscriptionStatues.APPROVED) {
       secureLocalStorage.setItem("investorId", investorID);
      navigate("/Investor-Overview-Page", 
        { state: { userId: { itemId: investorID , fullName: params?.row?.investor} } });
    } else {
      const details = {
        "subscriptionsID" : params.row.subscriptionID,
        "investorID" : investorID,
        "isInvestorVerified" : params.row.subscriptionStatus,
      }
      secureLocalStorage.setItem('InvestorAndFMNotificationsRow', details)
      navigate("/subscription-details");
    }
  }
  
  //#region api get calls
  const getFundDetails = async () => {
    const data = await getInvestorSubscribedFunds(investorID);
    if (data.responseCode === 200) {
    setInvestments(data.responseData.filter(u=>u.subscriptionStatus !== "Investor Sign Pending"));
    }
    else {
        setInvestments([])
    }
  }
  
  const getFundTypes = async() => {
      const data = await getKeyValuePairs("FundType");
      if(data.responseCode === 200){
          const dropdownValues = data.responseData?.map(option => ({ label: option.listItemValue, value: option.listItemID })) || []
          setFundTypes(dropdownValues)
      }
      else{
          setFundTypes([])
      }
  }
  
  //#region useeffect
  useEffect(()=>{
      getFundDetails();
      getFundTypes();
  },[])
    
  useEffect(()=>{
    
      const selectedFundType = currFundType();
  
      //sorting
      const sortedFunds = investments?.sort((a, b) => {
        let dateA = new Date(a.updatedDate);
        let dateB = new Date(b.updatedDate);
  
        return dateB - dateA; 
      });
  
      //variables
      let fundTypes = []
      let fundStatus = []
     
      if(filters.fundType !== ""){
          if(filters.fundType !== fundTypesEnum.ALL){
              const selectedSubscribers = sortedFunds.filter((fund)=> fund.fundTypeName === selectedFundType);
              fundTypes = selectedSubscribers;
              if(selectedFundType === fundTypesEnum.PRIVATE_CREDIT){
                setInvestmentColumns(myInvestmentsprivateCreditColumns);
              }
              else{
                setInvestmentColumns(myInvestmentsprivateEquityColumns);
              }
          }
          else{
              setInvestmentColumns(fundColumns);
              fundTypes = sortedFunds;
          }
      }
  
      if(filters.fundStatus !== ""){
        const selectedStatus = currFundStatus();
        if(selectedStatus === subscriberStatusEnum.IN_ACTIVE){
          const selectedSubscribers = sortedFunds.filter((fund)=> fund.subscriptionStatus === selectedStatus);
          if(selectedSubscribers?.length === 0){
            fundStatus = [{}]
          }
          else{
            fundStatus = selectedSubscribers
          }
        }else if(selectedStatus === subscriberStatusEnum.ACTIVE){
          const selectedSubscribers = sortedFunds.filter((fund)=> fund.subscriptionStatus !== subscriberStatusEnum.IN_ACTIVE);
          fundStatus = selectedSubscribers
        }else{
          fundStatus = sortedFunds
        }
      }
      
      let allFilteredSubscribers = [];
      allFilteredSubscribers = sortedFunds?.filter((item) => {
          const typeMatch = fundStatus?.length > 0 && fundStatus.includes(item);
          const statusMatch = fundTypes?.length > 0 && fundTypes.includes(item);
          if (fundStatus?.length > 0 && fundTypes?.length > 0) {
              return typeMatch && statusMatch;
          } else {
              return typeMatch && statusMatch;
          }
      });

      let subscriptionStatusToBeShown = "Under Review"
      
      const updatedSubscribers = allFilteredSubscribers.map(subscriber => {
        if (subscriber.subscriptionStatus.includes("Review")) {
          return { ...subscriber, subscriptionStatus: subscriptionStatusToBeShown };
        }
        return subscriber;
      });

      setFilteredInvestments(isInvestorLogin ? updatedSubscribers : allFilteredSubscribers );
  
  },[investments, filters])  

  //#region return
  return (
    <div className='child-margin-5 white-card width-percent-100'>
          <div className='space-between'>
             <h6>{isInvestorLogin ? "My Investments" : "Fund Invested In"}</h6>
             <div className='child-row-margin-5'>
                <div className='width-100'>
                    <SelectField 
                        name="fundStatus"
                        label="Status"
                        variant={FieldVariants.OUTLINED}
                        value={filters.fundStatus}
                        options={subscriberStatusDropdown}
                        onChange={(name,value)=> {handleFilterChange(name,value)}}/>
                </div>
                <div className='width-180'>
                    <SelectField 
                        name="fundType"
                        label="Fund Type"
                        variant={FieldVariants.OUTLINED}
                        value={filters.fundType}
                        options={fundTypes}
                        onChange={(name,value)=>{handleFilterChange(name,value)}}/>
                </div>
            </div>
          </div>
          <div className='height-450'>
            <FundGridView 
               columns={investmentColumns}
               rows={filteredInvestments.length > 0 ? 
                    filteredInvestments?.map((fund) => ({
                        id: fund.fundID,
                        ...fund
                    })) : []
               }
               isHeight={false}
               handleRowClick={onRowClick}
               pagination={true} />
          </div>
    </div>
  )
}

export default InvestorInvestmentsGrid