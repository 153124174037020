import secureLocalStorage from "react-secure-storage";

export const InitialPortfolioDataTemplate = {
    fundID: 0,
    fundName: "",
    TenantID : secureLocalStorage.getItem("tenantID"),
    fundManagementTeams: [
      {
        fundManagementTeamID: 0,
        fundID: 0,
        firstName: "",
        lastName: "",
        description: "",
        title: "",
        emailAddress: "",
        addressLine1: "",
        addressLine2: "",
        phoneNumber: "",
        city: "",
        state: 0,
        country: 0,
        zipCode: ""
      }
    ],
    portfolioCompanies: [
      {
        companyId: 0,
        fundID: 0,
        companyName: "",
        investmentStrategy: 1,
        targetIndustry: 1,
        yearFounded: "2023-07-05T11:58:21.539Z",
        dscInvestmentHighlightsAndRationle: "",
        dscKPIsAndProgessUpdates: "",
        dscExitsAndRealizedReturns: "",
        headQuartersLocation: "US",
        investmentStage: "C Stage",
        investmentCommitment: "27000000.00",
        portfolioCompanyStatus: "Performing",
        fundOwnershipPercentage: "38.00"
      }
    ],
    feesExpenses: [
      {
        id: 0,
        fundID: 0,
        managementFees: 0,
        performanceFeesOrCarriedInterest: 0,
        additionalFeesAndExpenses: 0
      }
    ],
    legalCompliance: {
      offeringDocuments: {},
      subscriptionDocuments: {},
      complianceDocuments: {}
    }
  }