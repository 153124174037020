import React, { useState, useEffect } from 'react';
import CustomEmail from '../CustomEmail';
import BasicTab from '../../../administration/TabComponent';
import AddGroups from '../AddGroups'
import { CommunicationType } from '../../../../utils/enum';
import { getDraftNotifications } from '../../Services/GroupsServices';
import { ToastContainer, toast } from 'react-toastify';
import DraftNotifcations from './DraftNotifcations';
import SentNotifications from './SentNotifications';
import CreateIcon from '@mui/icons-material/Create'; 
import DraftsIcon from '@mui/icons-material/Drafts'; 
import SendIcon from '@mui/icons-material/Send';
import GroupIcon from '@mui/icons-material/Group';
import { getDraftandSentCount } from '../../Services/GroupsServices';
import GroupsMain from './Groups/GroupsMain';
import MailIcon from '@mui/icons-material/Mail';
import Notifications from '../Notifications/Notifications';
import { getHeaderColorConfig } from '../../../../state/slices/colorConfigSlice';
import { useSelector } from "react-redux";
import { width } from '@mui/system';
import NotificationsInbox from '../Notifications/NotificationsInbox';

function InvestorCommunication(props) {

  const { fromInvestorRelations } = props;

  //#region useState Variables
  const [draftsCount, setDraftsCount] = useState(null);
  const [sentCount, setSentCount] = useState(null);
  const [renderComponent, setRenderComponent] = useState(true)
  const [selectedGroup, setSelectedGroup] = useState({
    id: null
  });

  const [tabs, setTabs] = useState(null);
  const [currentTab,setCurrentTab] = useState(0);

  //#region whitelabeling
  const headerColorConfig = useSelector(getHeaderColorConfig);

  //#region Click Events
  const handleTabChange = (tab,groupID) => {
    setCurrentTab(tab);
    selectedGroup["id"] = groupID;
  }

  //#region get api calls
  const getNotificationsCount = async () => {
    const data = await getDraftandSentCount();
    if (data?.responseCode === 200) {
      const draftCount = data?.responseData?.find((item) => item?.apiKey === "Draft");
      const sentCount = data?.responseData?.find((item) => item?.apiKey === "Sent");
      setDraftsCount(draftCount?.apiValue);
      setSentCount(sentCount?.apiValue);
    } else {
      // Handle error if needed
      setDraftsCount("");
      setSentCount("");
    }
  }

  useEffect(() => {
    const alltabs = [
           {
            id: 1,
            name: <label> <CreateIcon /> Compose </label>,
            label: 'new notification',
            component: <CustomEmail CommunicationType={CommunicationType.NewNotification} selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} getNotificationsCount={getNotificationsCount} renderComponent={renderComponent} setRenderComponent={setRenderComponent} />
          },
          {
            id: 2,
            name: <label> <MailIcon /> Inbox </label>,
            label: 'new notification',
            component: <NotificationsInbox />
          },
          {
            id: 3,
            name: <label> <DraftsIcon /> Drafts  <em className='draftCount'>{draftsCount || ''}</em></label>,
            label: 'drafts',
            component: <DraftNotifcations CommunicationType={CommunicationType.Drafts} getNotificationsCount={getNotificationsCount} renderComponent={renderComponent} setRenderComponent={setRenderComponent}/>
          },
          {
            id: 4,
            name: <label> <SendIcon /> Sent <em className='draftCount'>{sentCount || ''}</em> </label>,
            label: 'sent items',
            component: <SentNotifications  getNotificationsCount={getNotificationsCount} renderComponent={renderComponent} setRenderComponent={setRenderComponent}/>
          },
          {
            id: 5,
            name: <label> <GroupIcon/> Groups </label>,
            label: 'groups',
            component:  <GroupsMain navigateCustomEmail={(tab,groupID)=>{handleTabChange(0,groupID)}}/>

          }
        ]
    const filteredTabs = alltabs.filter((tab) => { if(fromInvestorRelations === true) return tab.id !== 2; else return tab.id === 2; });
    setTabs(filteredTabs);
  }, [draftsCount, sentCount]);

  {/* <GroupsMain  AddGroups /> */}

  useEffect(() => {
    getNotificationsCount();
  }, []);

  return (
    <div style={{width:'100%'}}>
      {
        fromInvestorRelations ? '' : <div className='s-banner'
          style={{
            background: `radial-gradient(50% 50% at 50% 50%, ${headerColorConfig.gradientSecondaryColor}, ${headerColorConfig.gradientPrimaryColor})`,
            backgroundSize: 'cover',
            // marginTop: '50px',
        }}><h1 style={{color: headerColorConfig.color}}>Notifications</h1> </div>
      }
      <div className={fromInvestorRelations ? 'vTabs' : 'vTabs wrapper3'}>
        <h4 className='mb3'>{fromInvestorRelations ? "Investor Communication" : "Notifications"}</h4>
        {
          tabs && 
          <BasicTab
              tabs={tabs}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              isVertical='vertical'
            // backgroundcolor={true}
            /> 
        }
      </div>
    </div>
  );
}

export default InvestorCommunication;
