import React from 'react'
import RowOrderingGrid from '../../../common/DataGrid/RowOrderingGrid'
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {Container} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxRenderingDemo from './CheckBoxRenderingDemo';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { useState,useEffect } from 'react';
import { BoxbackgroundWhite } from '../../../CommonCss';
import { Gridaligncenter } from '../styles';
import { Box } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ChooseQuestionPopup from '../Popups/ChooseQuestionPopup';
import AddNewMultiChoiceQuestion from '../Popups/AddNewMultiChoiceQuestion';
import AddNewTextQuestion from '../Popups/AddNewTextQuestion';
import { OBQJsonData } from '../Services/sampleData';
import { getOBQQuestionsDetails, getQuestionBank, updateOnboardingQuestions } from '../Services/OBQServices';
import { ToastContainer, toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { getOnbordingQuestionPatterns } from '../Services/OBQServices';
import ActionButton from '../../../common/ActionButton/ActionButton';
import { OnboardingQuestionPattern } from '../OnBoardingEnum';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const OnboardingQuestions = (props) => {

     //#region props
      const {investorType,isexpanded,investorTypeID} = props;
    
      //#region variables
      const [Rows,setRows] = useState(null);
      const [initialData,setinitialData] = useState(null);
      const [rowsordetracker,setRowsordertrack] = useState(null);
      const [QuestionTypeOptions,setQuestionTypeOptions] = useState(null);
      const [mcqType,setMcqType] = useState(null);
      const [textType,setTextType] = useState(null);
      const [isSaveEnabled, setSaveEnabled] = useState(false);
      const [SelectQuestionType,setSelectQuestionType] = useState(false);
      const [Questionpopup,setQuestionpopup] = useState(false);
      const [selecteditem,setSelectedItem] = useState();
      const [AllQuestionsDataRows,setAllQuestionsDataRows] = useState(null);
      const [btnloading,setLoading] = useState(false);

      //#region api get calls
      const getallQuestions = async () => {
        const data = await getQuestionBank(investorType);
        if (data.responseCode === 200) {
          setRows(data.responseData);
          setinitialData(data.responseData);
          setRowsordertrack(data.responseData?.map((item) => item?.questionBankID));
          setSaveEnabled(false);
        } else {
          // Handle error if needed
          setRows([]);
        }
      }

      const getOnbordingQuestionOptions = async () => {
        const data = await getOnbordingQuestionPatterns();
        if (data?.responseCode === 200) {
          const questionPattrenData = data?.responseData?.map((item) => {
            return {
              value: item?.listItemID,
              label: item?.listItemValue,
              };
            });
            setMcqType(questionPattrenData?.find(item => item?.label === OnboardingQuestionPattern.MCQ_TYPE)?.value);
            setTextType(questionPattrenData?.find(item => item?.label === OnboardingQuestionPattern.TEXT_TYPE)?.value);
            setQuestionTypeOptions(questionPattrenData);
          }
        else{
          /* console.log('error in getOnbordingQuestionPatterns:', data?.responseData); */
        }
      }
    
      //#region api update functions
    const onSaveClick = async() => {

      setLoading(true);

      const sortedRows = [...Rows].sort((a, b) => {
        const indexA = rowsordetracker.indexOf(a.questionBankID);
        const indexB = rowsordetracker.indexOf(b.questionBankID);
      
        // If a.questionBankID is not found in rowsordetracker, place it at the end
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;
      
        return indexA - indexB;
      });
      
      sortedRows.sort((a, b) => (a.softDelete === b.softDelete ? 0 : a.softDelete ? 1 : -1));
      
      sortedRows.forEach((row, index) => {
        if (rowsordetracker.indexOf(row.questionBankID) === -1) {
          // Keep the step unchanged
        } else {
          row.step = index + 1;
        }
      });
      

      const updatedQuestions = sortedRows?.map(item => {
        // Check if the item exists in initialData
        const existsInInitialData = initialData.some(initialItem => initialItem.questionBankID === item.questionBankID);
      
        // If the item doesn't exist in initialData, update questionBankID to 0
        if (!existsInInitialData) {
          item.questionBankID = 0;
          item?.questionAnswer?.map((item)=>item.questionAnswerID = 0)
        }
      
        return item;
      });
      

      const response = await updateOnboardingQuestions(updatedQuestions);
      /*   */    
      if (response?.responseCode === 200) {
        toast.success("Successfully Saved", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "colored",
        });
        setSaveEnabled(false);
        setLoading(false);
        getallQuestions();
        
      } else {
        getallQuestions();
        toast.error("Unable to Save the details", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "colored",
        });
        setSaveEnabled(false);
        setLoading(false);
      }
    }

    //#region change events
    const handleChangeChecbox = (params, name, value) => {
      /*   */;
      console.log(Rows);
      /*   */
      const updatedData = Rows.map((item) => {
        if (item.questionBankID === params.questionBankID) {
          return {
            ...item, // Preserve other properties
            [name]: value,
          };
        }
        return item;
      });
    
      setRows(updatedData);
      
    };

    //#region click events
    const onEditSaveButtonClick = () => {
        if (isSaveEnabled) {
          onSaveClick();
        }
        else{
            setSaveEnabled(isSaveEnabled => !isSaveEnabled);
        }
    };

    const onCancel = () => {
      setSaveEnabled(false);
      setRows(initialData);
    };
    
    const onOpenSelectType = () =>{
      if(isSaveEnabled){
        /*   */
        setSelectQuestionType(true);
      }
      else{
        return
      }
      
    }

    const OnClosePopup = () => {
      setSelectQuestionType(false);
    }

    const onhandleRowClick = (params) =>{
           /*   */
           if(isSaveEnabled){
            setSelectedItem(params.row);
            setQuestionpopup(true);
           }
           else{
              return
           }   
    }

    //#region grid columns
    const Columns = [
      /* { field: 'questionBankID', headerName: 'ID', width: 70 }, */
      { field: 'step', headerName: 'Sequence', width: 80, align: 'center' },
      { field: 'shortName', headerName: 'Short Name of Question', width: 300 },
      {
        field: 'onboardingQuestionPattern',
        headerName: 'Question Type',
        width: 150,
        renderCell: (params) => {
          if (params.row.onboardingQuestionPattern === "4") {
            return "MCQ";
          } else if (params.row.onboardingQuestionPattern === "5") {
            return "OEQ";
          } else {
            return ""; 
          }
        },
      },      
      { field: 'isActive', headerName: 'Active Question', width: 150, 
          renderCell: (params) => (
            isSaveEnabled ? 
            <>
              <CheckBoxRenderingDemo name="isActive"
                value={params.row.isActive}
                handleChange={handleChangeChecbox} params={params.row} isdisabled={!isSaveEnabled}/>
            </> : 
            <>
              {params.row.isActive ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
            </> 
            ),
      },
      { field: 'askToExisitingInvestor', headerName: 'Ask to Existing Investors', width: 180 ,
          renderCell: (params) => (
              isSaveEnabled ?
              <>
              <CheckBoxRenderingDemo name="askToExisitingInvestor"
                                     value={params.row.askToExisitingInvestor}
                                     handleChange={handleChangeChecbox} params={params.row} 
                                     isdisabled={!isSaveEnabled}/>
              </> :
              <>
                {params.row.askToExisitingInvestor ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
              </>
            ),
      },
      {
        field: 'softDelete',
        headerName: 'Delete',
        width: 100,
        renderCell: (params) => (
                    params.row.isQuestionUsedByInvestor ? 
                          (<Tooltip title="This question cannot be deleted because it has already been used by investors.">
                            <IconButton>
                               <LockIcon sx={{marginLeft: '1px'}}/>
                            </IconButton>
                          </Tooltip>) : 
                        (<CheckBoxRenderingDemo name="softDelete"
                            value={params.row.softDelete}
                            handleChange={handleChangeChecbox} params={params.row} isdisabled={!isSaveEnabled}/>)
        ),
      },
      {
        field: 'isQuestionUsedByInvestor',
        headerName: 'Editable',
        width: 100,
        renderCell: (params) => (
                    params.row.isQuestionUsedByInvestor ? 
                    (<Tooltip title="This question is not editable because it has already been used by investors.">
                            <IconButton>
                               <LockIcon />
                            </IconButton>
                          </Tooltip>): (<IconButton>
                          <LockOpenIcon/>
                            </IconButton>)
        ),
      },
      
    ];

    //#region useEffect
    useEffect(() => {
      if(isexpanded){
        getallQuestions();
        getOnbordingQuestionOptions();
      }
    }, [isexpanded]);

return (
    <div>
      {
        Rows ? Rows.length > 0 ?
          <Box sx={{flexgrow: 1}}>
              <Grid container xs={12} >

                <Grid item xs={12} >
                    <div className='space-between'>
                      {/*  <div className='heading'>Investor Onboarding Questions</div> */}
                      <div></div>
                        <div className='space-between'>
                                <div style={{marginRight: '5px'}}>
                                    {
                                        isSaveEnabled && 
                                        <ActionButton 
                                            variant='outlined' 
                                            className='btn-primary'                   
                                            onClick={() => onCancel()}
                                            icon={<CancelIcon />}
                                            label="Cancel" >
                                        </ActionButton>
                                    }
                                    
                                </div>
                                <div>
                                    <ActionButton 
                                        variant='contained' 
                                        className='btn-primary'
                                        /*  startIcon={isSaveEnabled ? <SaveIcon /> : <EditIcon />} */
                                        loading={btnloading}
                                        onClick={() => onEditSaveButtonClick()} 
                                        icon={isSaveEnabled ? <SaveIcon /> : <EditIcon />}
                                        label={isSaveEnabled ? 'Save' : 'Edit'}
                                       >
                                    </ActionButton>
                                </div>
                            </div>
                    </div>
                </Grid>

                <Grid item xs={12} >
                  <RowOrderingGrid columns={Columns}
                    rowsorder={Rows ? Rows : []} 
                              handleRowClick={onhandleRowClick}
                              setRowsOrder={setAllQuestionsDataRows} setRowsordertrack={setRowsordertrack} isSaveEnabled={isSaveEnabled}/>
                </Grid>

                <Grid item xs={12}>
                <Grid xs={12} sx={Gridaligncenter} onClick={onOpenSelectType} >
                    <AddCircleOutlineIcon
                        sx={{
                          height: '40px',
                          width: '40px',
                          color: isSaveEnabled ? 'inherit'  : 'gray', 
                        }}
                      />
                  </Grid>
                </Grid>

              </Grid>
              {SelectQuestionType  && mcqType && textType && <ChooseQuestionPopup open={SelectQuestionType} onClose={OnClosePopup} 
                                      setSelectQuestionType={setSelectQuestionType} setNewData={setRows}
                                      QuestionTypeDropdown={QuestionTypeOptions}
                                      mcqType={mcqType} textType={textType}
                                      data={Rows} investorTypeID={investorTypeID}/>}
              {Questionpopup && mcqType && textType && (
                        <>
                            {parseInt(selecteditem.onboardingQuestionPattern) === mcqType ? (
                              <AddNewMultiChoiceQuestion data={Rows} setNewData={setRows} selecteditem={selecteditem} setSelectQuestionType={setQuestionpopup} edit={true}
                                  QuestionTypeDropdown={QuestionTypeOptions}
                                  mcqType={mcqType} textType={textType} investorTypeID={investorTypeID}/>
                            ) : null}
                            
                            {parseInt(selecteditem.onboardingQuestionPattern) === textType  ? (
                              <AddNewTextQuestion data={Rows} setNewData={setRows} selecteditem={selecteditem} setSelectQuestionType={setQuestionpopup} edit={true} 
                                  QuestionTypeDropdown={QuestionTypeOptions}
                                  mcqType={mcqType} textType={textType} investorTypeID={investorTypeID}/>
                            ) : null}
                        </>
                        )}
              <ToastContainer />
          </Box> : <div>No Data Found !</div>
        : <div>Loading...</div>
      }
    </div>
  )
}

export default OnboardingQuestions