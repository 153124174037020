import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import "./ThankYou.css";
import CircleIcon from "../../chatbot/Icon";
import secureLocalStorage from "react-secure-storage";

const ThankYou = () => {
  const navigate = useNavigate();

  return (
    <div className="child-margin-5 align-items-center">
          <h6 className="message-thankyou margin-top-75">
                Thank You for Signing Up
          </h6>
          <p>
            Please click the activation link sent to your
            <b> {secureLocalStorage.getItem("registeredMailID")}</b> to activate your account.
          </p>
          <div className="google-recaptcha">
         The site is protected by reCAPTCHA and the <br />
         <p>
            Google <a href="">Privacy Policy</a> and <a href="">Terms of service </a> apply. 
          </p>
          </div>
          
          <CircleIcon/>   
    </div>
  );
};
export default ThankYou;