import React, { useEffect, useState } from 'react';
import {
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
  Bar,
  Line,
} from 'recharts';

//#this component will display Fund TVPI chart
function TwoColorBarChart(props) {
  
  const { selectedYear, selectedFund } = props;
  
  const [jsonData, setJsonData] = useState([]);

  const generateRandomY = () => Math.floor(Math.random() * 1000) - 500;

  const generateTwoColorBarData = (numberOfBars, selectedYear) => {
    const currentYear = new Date().getFullYear();
    const startYear = selectedYear || currentYear;
    const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];

    let data = [];
    for (let i = 0; i < numberOfBars; i++) {
      const year = startYear + Math.floor(i / 4);
      const quarter = quarters[i % 4];
      const color1 = generateRandomY(); // Generate random value for color1
      const color2 = generateRandomY(); // Generate random value for color2
      const sum = color1 + color2; // Calculate sum of color1 and color2

      const dataPoint = { name: `${quarter} ${year}`, color1, color2, sum };
      data.push(dataPoint);
    }

    return data;
  };

  useEffect(() => {
    const numberOfBars = 5; // Define how many bars you want to generate
    setJsonData(generateTwoColorBarData(numberOfBars, selectedYear));
  }, [selectedYear, selectedFund]);
  

  return (
    <div style={{ width: '100%', height: '600px', padding: '16px' }}>
      <ResponsiveContainer width="100%" height="100%">
   
        <ComposedChart
          data={jsonData }
          margin={{ top: 40, right: 30, left: 0, bottom: 140   }}
        >
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend
            iconType="circle" // Use circle icons for the legend items
            iconSize={10} // Adjust the size of the legend icons
            wrapperStyle={{
              backgroundColor: 'rgba(255, 255, 255, 0.8)', // Legend background color
              borderRadius: '10px', // Adjust the border radius to make it round
              padding: '10px', // Adjust padding as needed
            }}
          />
          <Bar dataKey="color1" stackId="a"
              name="DPI" fill="#0A1A27">
            <LabelList
              dataKey="color1"
              position="inside"
              fill="white"
              formatter={(value) => value.toFixed(2)}
            />
          </Bar>
          <Bar dataKey="color2"
              name="RVPI" stackId="a" fill="#1F61C5">
            <LabelList
              dataKey="color2"
              position="inside"
              fill="white"
              formatter={(value) => value.toFixed(2)}
            />
          </Bar>
      
          <Line type="linear" dataKey="sum" stroke="#FF0000" name="TVPI" strokeWidth={4} dot={true}>
            <LabelList
              dataKey="sum"
              position="top"
              dy={-10}
              formatter={(value) => value.toFixed(2)}
              fill="#FF0000" // Change the font color
              fontSize={14}   // Change the font size
              fontFamily="Arial, sans-serif"
              fontWeight={7}
              
            />
           
          </Line>
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

export default TwoColorBarChart;
