import React from 'react'
import { Grid } from '@mui/material';
import '../styles.css'
import { useState } from 'react';
import { getInvestorsList } from '../../InvestorRelations/Services/IRServices';
import { useEffect } from 'react';
import AIPDataGrid from '../../common/DataGrid/AIPDataGrid';
import { BoxbackgroundWhite } from '../../CommonCss';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { getKycAmlDetails } from '../services/services';
import { useParams } from 'react-router-dom';
import { updatekycstatus } from '../services/services';
import { updateamlstatus } from '../services/services';
import { KycStatusvalues } from '../enum';
import { AMLStatusValues } from '../enum';
import { getKYCAMLDashboardData } from '../services/services';
import CircleIcon from '../../chatbot/Icon';

const KYCDashboard = () => {

  const detail = useParams();

  const navigate = useNavigate();
  //variables
  const [investorsList,setInvestorsList] = useState(null);
  const [InvestorsRowsData, setInvestorsRowsData] = useState(null);

   //Dashboarddatausestate
   const [dashboardData, setDashboardData] = useState(null);

  //InvestorGrid Rows and Columns
  const Columns = [
    { field: 'userFullName', headerName: 'Name', width: 300, },
    { field: 'type', headerName: 'Type', width: 200 },
    { field: 'kyc', headerName: 'KYC', width: 200 },
    { field: 'aml', headerName: 'AML', width: 200 },
  ]

  useEffect (
    ()=>{
      const investorData = investorsList
      ? investorsList.map((user) => ({
          id: user.userId,
          userFullName: user.fullName,
          useremail: user.emailAddress,
          type: user.investorType,
          kyc: user.kycStatusName || 'Verified',
          aml: user.amlStatusName || 'Verified',
          kycStatusId:  user.kycStatusId,
          amlStatusId: user.amlStatusId,
          ...user
        }))
      : [];
      setInvestorsRowsData(investorData);
    }
  ,[investorsList])


// statusbars
const [statusBars, setStatusBars] = useState([
  {
    id: 1,
    title: 'NEW',
    value: dashboardData?.new || '.'
  },
  {
    id: 2,
    title: 'UPDATE REQUIRED',
    value: dashboardData?.updateRequired || '.'
  },
  {
    id: 3,
    title: 'UNDER REVIEW',
    value: dashboardData?.underReview || '.'
  }
]);

useEffect(() => {
  setStatusBars([
    {
      id: 1,
      title: 'NEW',
      value: dashboardData?.new || '-'
    },
    {
      id: 2,
      title: 'UPDATE REQUIRED',
      value: dashboardData?.updateRequired || '-'
    },
    {
      id: 3,
      title: 'UNDER REVIEW',
      value: dashboardData?.underReview || '-'
    }
  ]);
}, [dashboardData]);

  //API'S
  const getInvestors = async () => {
    const data = await getKycAmlDetails();
    if (data?.responseCode === 200) {
      const reversedData = data.responseData;
      setInvestorsList(reversedData);
    } else { 
      // Handle error if needed
    }
  }

   //fetchDasboardData
   const getDashboardData = async () => {
    const data = await getKYCAMLDashboardData();
    if (data.responseCode === 200) { 
       
      setDashboardData(data.responseData);
    } else {
      console.log(data.responseData);
    }
  }

  useEffect(() => {
    getDashboardData();
    getInvestors();
  }, []);

  //ClickEvents
  const onInvestorClick = (params) => {
    secureLocalStorage.setItem("KYCInvestorUserId", params.row.id);
    secureLocalStorage.setItem("KYCInvestorEmailAddress", params.row.useremail);
    secureLocalStorage.setItem("KYCInvestorDetails", params.row);
    debugger
    //need to trigger apis if params.row.kyc is new
    if(params.row.kyc === KycStatusvalues.STAUTS_NEW ||
      params.row.kyc === KycStatusvalues.PENDING){
      updatekycstatus(params.row,KycStatusvalues.UNDER_REVIEW);
    }if(params.row.aml === AMLStatusValues.STAUTS_NEW ||
      params.row.aml === AMLStatusValues.PENDING){
      updateamlstatus(params.row,KycStatusvalues.UNDER_REVIEW);
    }
    /* navigate("/kyc-aml-verification"); */
    navigate(`/kyc-aml-verification/details?investorId=${params.row.id}`);
    window.scrollTo(0, 0);
  }

  return (
    <div className='page wrapper mt2-page'>
      <Grid container xs={12}>
        <Grid item xs={12}>
           <h1>KYC & AML Dashboard</h1>
        </Grid>
        <Grid item container xs={12}>
          {
            statusBars.map((item)=>{
              return (
                <Grid item container xs={12} sm={3.9} md={3.9} lg={3.9} className='kyc-statuscard'>
                  <Grid item xs={12}>
                      {item.title}
                  </Grid>
                  <Grid item xs={12} marginTop={1} className='kyc-statusValue'>
                      {item.value}
                  </Grid>
                </Grid>
              )
            })
          }
        </Grid>
        <Grid item container xs={12} >
          <Grid xs={12} className='whiteCard' height={360}>
           <AIPDataGrid
                    columns={Columns}
                    rows={InvestorsRowsData || []}
                    handleRowClick={onInvestorClick}
                    onRowsSelectionHandler={() => { }}
                    sortByLatestFirst = {false}
                  />
          </Grid>
        </Grid>
      </Grid>
      <CircleIcon/>
    </div>
  )
}

export default KYCDashboard