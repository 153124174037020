import React, { Fragment, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./App.scss";
import "./alt360.css";
import "./responsive.css";
import PageNotFound from "./components/common/page-not-found/PageNotFound";
import { fetchAuth, getToken } from "./state/slices/authSlice";
import MainSiteLayout from "./Layouts/MainSiteLayout";
import LoginLayout from "./Layouts/LoginLayout";
import Home from "./components/registration-profiling/components/Home";
import Login from "./components/registration-profiling/components/Login";
import CreateAccount from "./components/registration-profiling/components/CreateAccount";
import CreateAccountWithEmail from "./components/registration-profiling/components/CreateAccountWithEmail";
import ForgotPassword from "./components/registration-profiling/components/ForgotPassword";
import CreateNewPswd from "./components/registration-profiling/components/CreateNewPswd";
import EmailVerificationScreen from "./components/registration-profiling/components/EmailVerificationScreen";
import UserSelection from "./components/registration-profiling/components/UserSelection";
import ResetPassword from "./components/registration-profiling/components/ResetPassword";
import OnboardingLayout from "./Layouts/OnboardingLayout";
import OnboardingQues from "./components/registration-profiling/components/OnboardingQues";
import AnalyzingScreen from "./components/registration-profiling/components/AnalyzingScreen";
import ThankYou from "./components/registration-profiling/components/ThankYou";
import secureLocalStorage from "react-secure-storage";
import { getrootBackgroundColorConfig, updateColorConfig } from "./state/slices/colorConfigSlice";
import { AuthApp } from "./AuthApp";
import { RouteConfig } from "./RouteConfig";
import Chatbot from "./components/chatbot/ChatbotWithMicrophone";
import ServicingAccordion from "./components/Servicing/FundServicing";
import CustomCaptcha from "./components/common/Custom Captcha/Custom Captcha";
import ValidationPopup from "./components/FundNews/Components/ValidationPopup";
import UserDashboard from "./components/AppAuthentication/UserDashboard";
import NonActiveUserLogin from "./components/AppAuthentication/NonActiveUserLogin";
 
import OBQuestions from "./components/registration-profiling/components/NewOnboardingques/OBQuestions";
import UserName from "./components/registration-profiling/components/UserName";
import FirmRegistationModel from "./components/registration-profiling/components/FirmRegistrartion/Components/FirmRegistationModel";
 
import ExpiryLinkPage from "./components/common/page-not-found/ExpiryLinkPage";
import Display from "./UIUX/component/display";
import TestNewField from "./components/common/TextInput/TestNewField";
import CustomLoginPage from "./components/AppAuthentication/CustomLoginPage";
 
import BulkUploadInvite from "./components/registration-profiling/components/InvestorOBQ.jsx/BulkUploadInvite";
import ThemeSelector from "./components/WhiteLabeling/ThemeSelector";
import Pipelinecomments from "./components/TestPipeline/Pipelinecomments";
import TabsComponment from "./components/common/Tabs/TabsComponment";
import { getTenantCustomCSS } from "./components/registration-profiling/services/RegistrationProfilingService";
import HelpPage from "./components/help-support/HelpPage";
 
function App() {
  const dispatch = useDispatch();
  const authToken = useSelector(getToken);
  const [fontSize, setFontSize] = useState('');
  const [fontFamily, setFontFamily] = useState('');
  const mainRootBGColorConfig = useSelector(getrootBackgroundColorConfig);
 
  useEffect(() => {
      const fetchWhiteLabellingConfig = async () => {
        const data = await getTenantCustomCSS();
        if(data.responseCode === 200)
        {
          setFontSize(data?.responseData?.rootBackground.size);
          setFontFamily(data?.responseData?.rootBackground.fontFamily);
          dispatch(updateColorConfig(data?.responseData))
        }
        else{}
      }
      fetchWhiteLabellingConfig();
    }, [secureLocalStorage.getItem("tenantID") != undefined &&
    secureLocalStorage.getItem("tenantID") != null]);
 
  return (
    <div>
      <ThemeSelector fontSize={fontSize} fontFamily={fontFamily}/>
      <Router>
        <Fragment>
          <Routes>
            <Route path="/CustomLogin" element={<CustomLoginPage />}></Route>
            <Route path="" element={<MainSiteLayout />}>
              <Route index path="/home" element={<Home />} />
              <Route path="/" element={<Home />} />
            </Route>
            <Route path="/Pipelinecomments" element={<Pipelinecomments />} />
            <Route path="" element={<LoginLayout />}>
              <Route path="/login" element={<Login />} />
              <Route path="/create-an-account" element={<CreateAccount />} />
              
              <Route
                path="/create-account-with-email"
                element={<CreateAccountWithEmail />}
              />
              <Route path="/create-new-password" element={<CreateNewPswd />} />
              <Route path="/forget-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/thank-you" element={<ThankYou />} />
              <Route path="/verification-screen" element={<EmailVerificationScreen />} />
              <Route path="/chatbot" element={<Chatbot />} />
            </Route>
            <Route path="" element={<OnboardingLayout/>}>
              <Route path="/analyzing-screen" element={<AnalyzingScreen />} />
            </Route>
            <Route path="/onboarding-process" element={<OBQuestions />} />
            <Route path="/set-user-name" element={<UserName />} />
            <Route path="" element={<OnboardingLayout  />}>
              <Route path="/user-selection" element={<UserSelection />} />
              {/* <Route path="/onboarding-process" element={<OnboardingQues />} /> */}
            </Route>
            <Route path = "/NonActiveUserLogin" element = {<NonActiveUserLogin />}/>
            <Route path = "/UserDashboard" element = {<UserDashboard/>}/>
            <Route element={<AuthApp />} >
              {RouteConfig.map(route=><Route path={route.path} element={route.component} />)}
            </Route>
            <Route path = "/helpAndsupport" element={<HelpPage />} >
            </Route>
            <Route path="*" element={<PageNotFound />} />
 
            <Route path = "/firm-onboarding-process" element = {<FirmRegistationModel />}/>
            <Route path = "/bulk-upload-invite" element = {<BulkUploadInvite />}/>
 
            <Route path = "/validationpopup" element = {<ValidationPopup/>}/>
            <Route path ="/serviceAcc" element= {<ServicingAccordion/>}/>
            <Route path = "/customcaptcha" element = {<CustomCaptcha/>}/>
            <Route path = "/validationpopup" element = {<ValidationPopup/>}/>
            <Route path = "/invalid-link" element = {<ExpiryLinkPage/>}/>
 
            <Route path="/test-new-field" element={<TabsComponment />} />
          </Routes>
        </Fragment>
      </Router>
      </div>
  );
}
export default App;