import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from "@mui/material";
import { ArrowBackIos } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from "react-router-dom";
import { AppDashboards } from '../../utils/enum';
import { color, width } from '@mui/system';
import { red } from '@mui/material/colors';
import { BoxbackgroundWhite } from '../CommonCss';
import { useEffect } from 'react';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTab(props) {

  const [value, setValue] = React.useState(props.currentTab ? props.currentTab : 0);
  const navigate = useNavigate();

  const {isVertical} = props;

  const [showBackButton, setshowBackButton] = React.useState(props.showBackButton?props.showBackButton:false);

  const handleChange = (event, newValue) => {
  
    setValue(newValue);
    if (props.setCurrentTab) {
      props.setCurrentTab(newValue);
    }
  };
  

  const handleBackClick = () => {
  
    if (secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD ) {
       navigate ( '/Investor-Dashboard' );
     } else if (secureLocalStorage.getItem('userrole') === AppDashboards.FM_DASHBOARD) {
       navigate ( '/marketplace/fund-detail-investor-relations' );
     }
    window.scrollTo(0, 500);
   };

   useEffect(() => {
    setValue(props.currentTab || 0);
  }, [props.currentTab]);

  return (
    <Grid item xs={12}>
     {showBackButton && (
        <div style={{ marginLeft: '30px', marginTop: '10px', zIndex: 0 }} onClick={handleBackClick}>
          <ArrowBackIcon/>
        </div>
      )}
       <Box sx={isVertical ? { flexGrow: 1, display: 'flex' } : 
              {
              }
            }>

          <Tabs 
             orientation={isVertical ? "vertical" : "horizontal"}
             variant="scrollable"
             value={value}
             onChange={handleChange}
             aria-label={isVertical ? "Vertical tabs example" :"basic tabs example"}
             indicatorColor='none'
             sx={isVertical ? { borderRight: 1, borderColor: 'divider', 
                                minWidth: '200px',
                                borderRadius: '8px',
                                backgroundColor: 'white'} :
                               {
                                backgroundColor: '#d7e2ef',
                                boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16) inset',
                                borderRadius: '34px',
                                textAlign: 'center',
                               }}>
              {props?.tabs?.map((tab, index) => (
                <Tab
                  label={
                    <div style={{ display: 'flex', width: '100%' }}>
                      <span className='vtab-item'>{tab.name}</span>
                    </div>
                  }
                    {...a11yProps(index)} 
                    key={index} 
                     sx={{ 
                      "&.MuiTabs-indicator": { 
                        backgroundColor: "transparent", 
                        borderRadius: "22px", 
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        transition: "0.3s ease-out", 
                        padding: "0px 25px",
                        "&.Mui-selected": { 
                          backgroundColor: "#B0C1D2",
                        },
                        "&:hover": { 
                          backgroundColor: "#B0C1D2",
                          borderRadius: "22px", 
                        }
                      },
                      "&.Mui-selected": {
                        color: "black",
                        border: 1,
                        borderColor: 'divider',
                        backgroundColor: '#B0C1D2',
                        borderRadius: '200px', 
                      },
                      width: isVertical ? "100%" : `calc(100% / 100% ${props.tabs.length})`,
                    }} 
                  />
                ))}
          </Tabs>
        

          {props?.tabs?.map((tab, index) => (
              isVertical ? (
                <TabPanel value={value} index={index} key={index} backgroundColor={props.backgroundcolor} sx={{width: '100%'}}>
                  {tab.component}
                </TabPanel>
              ) : (
                <CustomTabPanel value={value} index={index} key={index} backgroundColor={props.backgroundcolor}>
                  {tab.component}
                </CustomTabPanel>
              )
            ))}

        </Box>
      </Grid>


  );
}

function CustomTabPanel(props) {
  const navigate = useNavigate();
  const { children, value, index, ...other } = props;
  const [showBackButton, setShowBackButton] = React.useState(props.showBackButton ? props.showBackButton : false);
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={props.backgroundColor ? '' : BoxbackgroundWhite } style={{marginTop: '20px'}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div className='v-tabpanel'
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 , backgroundColor : props.backgroundColor ? BoxbackgroundWhite : '' , marginLeft: props.backgroundColor ? 1 : ''}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};