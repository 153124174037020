
import AIPDataGrid from '../common/DataGrid/AIPDataGrid';
import { PageHeader } from '../common/PageHeader/PageHeader';
import secureLocalStorage from 'react-secure-storage';
import { HeaderTemplates } from '../common/PageHeader/PageHeader';
import { getrootBackgroundColorConfig } from '../../state/slices/colorConfigSlice'
import { useSelector } from 'react-redux';

const rows = [
    { id: 1, section: 'Equity Debt Fund Name', content: 'ABC Equity Debt Fund' },
    { id: 2, section: 'Address', content: '123 Main St, Anytown' },
    { id: 3, section: 'City, State, Zip Code', content: 'Anytown, USA, 12345' },
    { id: 4, section: 'Schedule A: Income', content: 'Interest Income: $10,000\nDividend Income: $5,000\nCapital Gains: $7,000\nOther Income: $3,000\nTotal Income: $25,000' },
    { id: 5, section: 'Schedule B: Deductions', content: 'Management Fees: $2,000\nAdministrative Expenses: $1,000\nDepreciation: $500\nOther Deductions: $1,500\nTotal Deductions: $5,000' },
    { id: 6, section: 'Schedule C: Tax Information', content: 'Federal Income Tax Withheld: $4,000\nState Income Tax Withheld: $1,500\nTotal Taxes Withheld: $5,500' },
    { id: 7, section: 'Schedule D: Partner\'s Share of Income, Deductions, and Credits', content: 'Partner\'s Share of Income: $15,000\nPartner\'s Share of Deductions: $3,000\nPartner\'s Share of Tax Credits: $1,200' },
    { id: 8, section: 'Schedule E: Additional Information', content: 'No additional information.' },
  ];

  // Columns configuration
  const columns = [
    { field: 'section', headerName: 'Section', width: 200 },
    { field: 'content', headerName: 'Content', width: 600 },
  ];

const K1Report = () => {
    const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);
  return (
    <div style={{ backgroundColor: rootBackColorConfig.color  }} className='mt-page'>
        <PageHeader
        template={HeaderTemplates.Standard}
        fundName={secureLocalStorage.getItem("FundName")}
        headerImage={secureLocalStorage.getItem("selectedFund")?.IconImage}
        customBackgroundImage={secureLocalStorage.getItem("selectedFund")?.BGImage ? secureLocalStorage.getItem("selectedFund")?.BGImage : '/DefaultBackgroundImage.png'}

      />
      <div className='wrapper mt4'>
       <h1>K-1 Report</h1>
        <div className='whiteCard'>
        <AIPDataGrid
        rows={rows}
        columns={columns}
         />
        </div>
        </div>
      
    </div>
  );
}

export default K1Report;
