import React from 'react'
import { Grid } from '@mui/material';
import '../styles.css'
import { useState } from 'react';
import { useEffect } from 'react';
import AIPDataGrid from '../../common/DataGrid/AIPDataGrid';
import { BoxbackgroundWhite } from '../../CommonCss';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import BasicTab from '../../administration/TabComponent';
import VDRoom from '../../common/VirtualDataRoom/VDRoom';
import InvestorProfile from '../../user-profile/InvestorUserProfile/PersonalInformation';
import InvestorBankingInformation from '../../user-profile/InvestorUserProfile/InvestorBankingInformation';
import { getInvestorDetails } from '../../user-profile/InvestorUserProfile/Services/Investorinfo';
import secureLocalStorage from 'react-secure-storage';
import { getStatesandCitieslist } from '../../InvestorFundSubscription/Services/Statecountry';
import ReactLoading from 'react-loading';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import DiscussionBox from './DiscussionBox';
import { getKycAmlDetails, updatekycamlstatus } from '../services/services';
import { ToastContainer, toast } from 'react-toastify';
import { KycAmlStuatus, KycStatusvalues, AMLStatusValues, KycAmlTabSwitch } from '../enum';
import { getInvestorDetailsbyUserID } from '../services/services';
import { getKYCAMLDashboardData } from '../services/services';
import { RolesAndPermissions, FeatureAccess } from '../../../utils/enum';
import { checkFeaturePermissions } from '../../../utils/common';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ComponentsTab } from '../../common/Tabs/ComponentsTab';
import { HeaderTemplates, PageHeader } from '../../common/PageHeader/PageHeader';
import TabsComponment from '../../common/Tabs/TabsComponment';
import IndividualInformation from '../../user-profiles/components/investor-user-profile/IndividualInformation';
import BankingInformation from '../../user-profiles/components/investor-user-profile/BankingInformation';
import { getKeyValuePairs } from '../../portfolio-companies/services/services';
import KycDetails from '../../user-profiles/components/investor-user-profile/kyc-aml/KycDetails';
import AmlDetails from '../../user-profiles/components/investor-user-profile/kyc-aml/AmlDetails';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

//#this component will show the investor details for kyc verification
const KYCVerification = () => {
  
  //#region variables
  const queryParams = new URLSearchParams(window.location.search);
  const investorId = queryParams.get('investorId');
  const isViewOnly = queryParams.get('readonly');
  const navigate = useNavigate();
  const [investorProfile, setInvestorProfile] = useState(null);
  const [DropdownsData, setDropdownsData] = useState(null);
  const investorDetails = secureLocalStorage.getItem("KYCInvestorDetails");
  const [componentRender,setcomponentRender] = useState(true)
  //verificationusestate for kyc and aml status
  const [kycamlDetails,setkycAmlDetails] = useState();
  const [kycStatus, setKycStatus] = useState(investorDetails?.kyc);
  const [amlStatus, setAmlStatus] = useState(investorDetails?.aml);
  const [currenttab, setCurrentTab] = useState(KycAmlTabSwitch.KYC_TAB);
  const [investorTypesValues,setInvestorTypesValues] = useState([]);

  //#region api get calls
  const getInvestorInfo = async () => {
    const investorId = queryParams.get('investorId');
    const data = await getInvestorDetailsbyUserID(investorId);
    if (data.responseCode === 200) {
      setInvestorProfile(data.responseData);
    } else {
      // Handle error if needed
    }
  }

  const getstateslist = async () => {
    const emailId = secureLocalStorage.getItem("userEmail");
    const data = await getStatesandCitieslist();
    if (data.responseCode === 200) {
      console.log(data.responseData);
      setDropdownsData(data.responseData);
    } else {
      // Handle error if needed
    }
  }

  const getInvestorKycAml = async() => {
    const data = await getKycAmlDetails();
    if (data.responseCode === 200) {     
      setkycAmlDetails(data.responseData);
      const currUser = data?.responseData?.find((item) => item.userId === parseInt(investorId))
      setKycStatus(currUser?.kycStatusName);
      setAmlStatus(currUser?.amlStatusName);
    } else {
      console.log(data.responseData);
    }
  }

  const getInvestorTypes = async() => {
    const data = await getKeyValuePairs("InvestorType");
    if(data.responseCode === 200){
       setInvestorTypesValues(data?.responseData);
    }
    else{
      setInvestorTypesValues([]);
    }
  }

  //#region useeffect
  useEffect(() => {
    getInvestorInfo();
    getstateslist();
    getInvestorKycAml();
    getInvestorTypes();
  }, [])

  //#region tabs
  const tabs = [
    {
        key : KycAmlTabSwitch.KYC_TAB,
        icon : <ManageAccountsIcon />,
        label : "KYC",
        component : <KycDetails 
                       investorDetails={investorProfile} 
                       isKycVerification={true}
                       kycStatus={kycStatus}
                       amlStatus={amlStatus}
                       investorTypesValues={investorTypesValues}
                       getInvestorKycAml={getInvestorKycAml} />
    },
    {
        key : KycAmlTabSwitch.AML_TAB,
        icon : <ManageAccountsIcon />,
        label : "AML",
        component : <AmlDetails 
                          investorDetails={investorProfile} 
                          isKycVerification={true}
                          kycStatus={kycStatus}
                          amlStatus={amlStatus}
                          investorTypesValues={investorTypesValues}
                          getInvestorKycAml={getInvestorKycAml} />
    },
  ]

  //#region return
  return (
    <div>
      <div>
        <PageHeader
              title=" KYC/AML Verification"
              template={HeaderTemplates.NORMAL_VIEW}/>
      </div>
      <div className='wrapper mt2-page'>
        <Grid container xs={12} spacing={2}>
          <Grid item xs={12}>
            {investorProfile  && DropdownsData ? (
              <Grid container item xs={12}>
                <Grid item xs={12} >
                  {
                    componentRender && kycStatus && amlStatus ?  <TabsComponment tabs={tabs} selectedTab={currenttab} setSelectedTab={setCurrentTab} /> : "Loading..."
                  }
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} sx={BoxbackgroundWhite}>
                <LinearProgress />
              </Grid>
            )}
          </Grid>
        </Grid>
        <ToastContainer />
      </div>
    </div>
  )
}

export default KYCVerification