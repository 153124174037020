import React, { useEffect } from 'react'
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import BasicTab from '../../../administration/TabComponent';
import { useState } from 'react';
import { AppDashboards } from '../../../../utils/enum';
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import { ToastContainer, toast } from 'react-toastify';
import AddK1Document from './AddK1Document';
import YearAccordianDocuments from './YearAccordianDocuments';
import secureLocalStorage from 'react-secure-storage';

function K1Documents(props) {
    const { isfileUpload,refreshComponent,setRefreshComponent,reports,setFoldersList,getFoldersDetails,isheadingrequired,documentsFor,ApprovedInvestorsForFund,documents,showOnlyAddDocuments} = props;

    const [currenttab, setCurrentTab] = useState(0);
    const [adddocumentpopup,setAddDocumentPopup] = useState(false);
  
    /* const reportNamesWithComponent = ReportsNames.map((report) => {
      return {
        ...report,
        component: <FoldersAccordian reports={report.relatedFiles} />
      }
    }); */
  
    const [tabs, setTabs] = useState(reports);
  
    //handlepopup
    const onOpenPopup = () =>{
      setAddDocumentPopup(true);
    }
    const onClose = () =>{
      setAddDocumentPopup(false)
    }
  
    const getAccordianData = () => {
      if(reports){
      
        const updatedFoldersList = [...reports];
        const currFiles = documents?.find((item)=> item.year ===  updatedFoldersList[currenttab]?.name ) 
        
        updatedFoldersList[currenttab].relatedFiles = currFiles;
        
        //updatedFoldersList[currenttab].fundReportDetails = sampleK1;
        updatedFoldersList[currenttab].component = <YearAccordianDocuments refreshAccordians ={getFoldersDetails} report={currFiles} isfileUpload={isfileUpload} currenttab ={currenttab} setRefreshComponent={setRefreshComponent} getAccordianData={getAccordianData} getFoldersDetails={getFoldersDetails}/>
        setFoldersList(updatedFoldersList);
      }
    }
  
    //#region useEffect
    useEffect(() => {
      if(reports){
        getAccordianData();
      }
    }, [currenttab]);
  
    useEffect(() => {
      setTabs(reports);
    }, [reports]);


  return (
    <div className='mt2-page'>
    <div className="page">
      <div>
        <Box sx={{ flexGrow: 1 }} overflowx={'auto'}>
          <Grid container marginTop={2}>
            <Grid container>
              {
                !isheadingrequired && <Grid item xs={12}>
                <div className='space-between'>
                    <h1>K-1 Reports</h1>
                  <div>
                    {
                      secureLocalStorage.getItem('userrole') !== AppDashboards.INV_DASHBOARD ? 
                      <>
                      {
                        (showOnlyAddDocuments) &&  
                          <Button variant="outlined"
                              className="btn-primary" 
                              style={{ cursor: 'pointer'}} 
                              onClick={onOpenPopup}>
                              <AddIcon/>
                              <span>Add Document</span>
                          </Button>
                        }
                      </> : ""
                    }
                  </div>
                </div>
               
              </Grid>

              }
               </Grid>
               </Grid>
              <Grid container xs={12} spacing={1} marginTop={1}>
                {/* <Grid item xs={12} marginLeft={17}>
                    <Button variant="contained"
                          className="btn-primary" 
                          style={{ cursor: 'pointer'}} 
                          onClick={() => {}}>
                          <AddIcon/>
                          <span>Add Folder</span>
                      </Button>
                  </Grid> */}
                  {
                    documents !== null ?  <Grid item xs={12} className='vTabs_L'>
                    <BasicTab
                      tabs={tabs}
                      currentTab={currenttab}
                      setCurrentTab={setCurrentTab}
                      //backgroundcolor={true}
                      isVertical='vertical' />
                    </Grid>  : 
                    <div className='k1report-nodata'>No Data Available</div>
                    
                  }
               
              </Grid>
           
         
        </Box>
        {adddocumentpopup && 
             <AddK1Document 
             open={adddocumentpopup} 
             onClose={onClose} 
             setRefreshComponent ={setRefreshComponent}
             refreshComponent = {refreshComponent}
             reports={reports ?? reports} 
             setCurrentTab = {setCurrentTab}
             ApprovedInvestorsForFund ={ApprovedInvestorsForFund ?? ApprovedInvestorsForFund}
             currDocumentType={tabs ? tabs[currenttab].documentTypeID : 0}
             getFoldersDetails={getFoldersDetails}
             getAccordianData={getAccordianData}/>}
      </div>
      <ToastContainer />
    </div>
  </div>
  )
}

export default K1Documents