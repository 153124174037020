import React from 'react'
import '../RemovePopup.css';
import CancelIcon from '@mui/icons-material/Cancel';
const RemovePopup = ({ onConfirm, onCancel ,message,Buttonmsg,leftValue }) => {
  return (
    <div>
      <div className="removepopup-bg" />
        <div className="compareLookFeel2Child-rp" />
      <div className="bgParent-rp">
        <div className="bg5-rp" />
         <div className="areYouSureContainer-rp">
                <p className="areYouSure-rp">{message}</p>
              </div>
        <div className="component21228-rp">
            <button  className="remove-rp" onClick={onConfirm}>{Buttonmsg}
                      </button>
        </div>
        <div className="component21229-rp" >
          
            <button className="cancel-rp" onClick={onCancel}> <CancelIcon/> Cancel</button>
         
        </div>
       
        <img
          className="iconMaterialClose-rp"
          alt=""
          src="/icon-materialclose.svg"
          onClick={onCancel}
        />
      </div>
    </div>
  )
}

export default RemovePopup