import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import Manualsubscription from '../../ComplianceDashboard/Manualofflinesubscriptions/Manualsubscription';
import IRHeader from './IRHeader';
import { Grid } from '@mui/material';
import Investors from './Investors';
import CustomEmail from './CustomEmail';
import { useSelector } from "react-redux";
import { getrootBackgroundColorConfig } from "../../../state/slices/colorConfigSlice";
import { checkFeaturePermissions } from '../../../utils/common';
import { FeatureAccess, RolesAndPermissions } from '../../../utils/enum';
import InvestorCommunication from './InvestorCommunications/InvestorCommunication';
import { getHeaderColorConfig } from '../../../state/slices/colorConfigSlice';
import InstantMessenger from './InstantMessenger/InstantMessenger';
import EventManagementMain from './EventResponseManagement/EventManagementMain';
import CircleIcon from '../../chatbot/Icon';

const FMInvestorRelations = () => {
  const {detail}= useParams();

  const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);
  const headerColorConfig = useSelector(getHeaderColorConfig);
  const [tabs, setTabs] = React.useState([
    {
      
        path: "investors",
        component: <Investors />
    },
     {
        path: "custom-email",
         component: <CustomEmail />
     },
    {
      path: "investor-communication",
      component: <InvestorCommunication fromInvestorRelations={true}/>
  },
    {
        path: "manual-subscription",
        component: <Manualsubscription />
    },
    {
      path: "instant-messenger",
      component: <InstantMessenger />
  },
  {
    path: "event-response-messenger",
    component: <EventManagementMain />
  },
  ])

  useEffect(()=>{
    if(!checkFeaturePermissions(RolesAndPermissions.INVITE_INVESTORS, FeatureAccess.READ)){
      setTabs(tabs?.filter(a=> a.path !== "investors"));
    }
  },[]);
  
  const InvestorRelationsInfoTag =  tabs?.find(el=> el.path == detail)?.component;

  return (
    <div style={{backgroundColor: rootBackColorConfig.color}}>
      <div className='s-banner'
            style={{
              background: `radial-gradient(50% 50% at 50% 50%, ${headerColorConfig.gradientSecondaryColor}, ${headerColorConfig.gradientPrimaryColor})`,
              backgroundSize: 'cover',
              // marginTop: '50px',
          }}>
            <h1 style={{color: headerColorConfig.color}}>Investor Relations </h1> </div>
            <div className='wrapper'>
      <Grid container xs={12}>
        <Grid item xs={12} >
            <IRHeader selectedTab={detail}/>
        </Grid>
        <Grid item container marginTop={2} xs={12}>
              {InvestorRelationsInfoTag}
        </Grid>
      </Grid>
      </div>
      <CircleIcon/>
    </div>
  )
}

export default FMInvestorRelations;