import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { maxWidth } from '@mui/system';

const MultipleSelectDropdown = ({ label, options: propOptions, setSelectedOptions, selectedOptions,onChange,name }) => {
  const options = Array.isArray(propOptions) ? [...propOptions] : [];

  const [selected, setSelected] = useState([]);
  const isAllSelected = options.length > 0 && selected.length === options.length;

  const handleChange = (ev) => {
    const { value } = ev.target;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === options.length ? [] : options);
      return;
    }
    setSelected(value);
    /*   */

    
    console.log(options)

    const selectedValues = options
      .filter(option => value.includes(option.label))
      .map(option => option.value);

    console.log(selectedValues);

    /*   */


    onChange(name, selectedValues);
  };

  if (options.length === 0) {
    return (
      <FormControl fullWidth variant="standard" sx={{}}> 
      <InputLabel htmlFor="select-multiple" style={{marginLeft: '12px'}}>{label}</InputLabel>
      <Select
        multiple
        value={selected}
        onChange={handleChange}
        renderValue={(selected) => selected.join(', ')}
        label={label}
        inputProps={{
          id: 'select-multiple',
        }}
        style={{  border: '1px solid #ccc', borderRadius: '8px' , width: '128px' , paddingLeft: '1px'}}
      >
          <MenuItem value="">
          <em>No options available</em>
          </MenuItem>
      </Select>
    </FormControl>
    );
  }

  return (
    <FormControl fullWidth variant="standard" sx={{}}> 
      <InputLabel htmlFor="select-multiple" style={{marginLeft: '12px'}}>{label}</InputLabel>
      <Select
        multiple
        value={selected}
        onChange={handleChange}
        renderValue={(selected) => selected.join(', ')}
        label={label}
        inputProps={{
          id: 'select-multiple',
        }}
        style={{  border: '1px solid #ccc', borderRadius: '8px' , width: '128px' , paddingLeft: '1px'}}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.label} >
            <label style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox"
                checked={selected.includes(option.label)}
                readOnly
              />
              <span style={{ marginLeft: '8px' }}>{option.label}</span>
            </label>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultipleSelectDropdown;
