import React from 'react'
import { useEffect, useState, useRef } from "react";
import ActionButton from "../../../common/ActionButton/ActionButton";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import '../../../marketplace/components/CreateFundModal/styles.css';
import StepModal from "../../../common/Modal/StepModal";
import secureLocalStorage from "react-secure-storage";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import NotifyTitle from '../PopUps/Title';
import CompanyProfile from './CompanyProfile';
import LeadershipTeam from './Executives';
import LegalCounsel from './LegalCounsel';
import Accounting from './Accounting';
import BoardMembers from './BoardMembers';
import UploadDocumentation from './UploadDocumentation';
import { UpdateCompanyProfileDetails } from '../../Services/CompaniesDetails';
import { updateLeadershipTeamDetails } from '../../Services/CompaniesDetails';
import { UpdateLegalCounselDetails } from '../../Services/CompaniesDetails';
import { UpdateBoardMembersDetails } from '../../Services/CompaniesDetails';
import { UpdateAccountingDetails } from '../../Services/CompaniesDetails';
import Thankyou from './Thankyou';

export const OnBoardingPopupModel = ({ handleCancelModel, currstep, data, FiltersData, title, setActiveStep, stepsData,updateIsValidated,companyName }) => {

    const [step, setStep] = useState(currstep);
    const livePage = useRef(step);
    livePage.current = step;
    const [open, setModalOpen] = useState(true);


    const [apiData, setapiData] = useState();

    const showToastWarning = (message) => {
        toast.warning(message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000, 
        });
    };

    const [Onboardingsteperrors, setOnboardingsteperrors] = useState(false);

    const handleModalNavigation = (isPrev) => {

        const step1 = secureLocalStorage.getItem("PCCompanyOverview");
        const step2 = secureLocalStorage.getItem("PCLeadershipTeam");
        const step3 = secureLocalStorage.getItem("PCLegalCounsel");
        const step4 = secureLocalStorage.getItem("PCAccounting");
        const step5 = secureLocalStorage.getItem("BoardMembers");

     if (livePage.current === 1 && step1) {
            setOnboardingsteperrors(true);
            showToastWarning('Please fill in mandatory fields.');
        }

        else if (livePage.current === 2 && step2) {
            setOnboardingsteperrors(true);
            showToastWarning('Please fill in mandatory fields.');
        }

        else if (livePage.current === 3 && step3) {
            setOnboardingsteperrors(true);
            showToastWarning('Please fill in mandatory fields.');
        }

        else if (livePage.current === 4 && step4) {
            setOnboardingsteperrors(true);
            showToastWarning('Please fill in mandatory fields.');
        }
        else if (livePage.current === 5 && step5) {
            setOnboardingsteperrors(true);
            showToastWarning('Please fill in mandatory fields.');
        }
        else{
            setOnboardingsteperrors(false);
            setStep(currStep => (!(isPrev && (currStep === 1)) && !(!isPrev && (currStep === 7))) ? (isPrev ? currStep - 1 : currStep + 1) : currStep);
        
        if (!isPrev) {
            APICalls();
        }
        else {

        }
    }
    }

    const APICalls = () => {
        const currentPageNumber = livePage.current;
        console.log(apiData);
        updateIsValidated(livePage.current,true);
        if (currentPageNumber === 1) {
            handleUpdateCompanyProfile();
        }
        else if (currentPageNumber === 2) {
            handleUpdateLeadershipTeam();
        }
        else if (currentPageNumber === 3) {
            handleUpdateLeagalCounsel();
        }
        else if (currentPageNumber === 4) {
            handleUpdateAccountingDetails();
        }
        else if (currentPageNumber === 5) {
            handleUpdateBoardMembers();
        }
        else return;

    }

    const handleUpdateCompanyProfile = async (isPrev) => {
        
        try {
            
            const requestData = apiData ? apiData : data;
            requestData["currentOnboardingStep"] = livePage.current;
            const result = await UpdateCompanyProfileDetails(requestData);
            console.log('Update successful:', result);
        } catch (error) {

        }
    };

    const handleUpdateLeadershipTeam = async () => {
        try {
            const requestData = apiData ? apiData : data;
            requestData["currentOnboardingStep"] = livePage.current;
            const result = await updateLeadershipTeamDetails(requestData);
            console.log('Update successful:', result);
        } catch (error) {

        }
    };

    const handleUpdateLeagalCounsel = async () => {
        
        try {
            const requestData = apiData ? apiData : data;
            requestData["currentOnboardingStep"] = livePage.current;
            const result = await UpdateLegalCounselDetails(requestData);
            console.log('Update successful:', result);
        } catch (error) {

        }
    };

    const handleUpdateBoardMembers = async () => {
        try {
            const requestData = apiData ? apiData : data;
            requestData["currentOnboardingStep"] = livePage.current;
            const result = await UpdateBoardMembersDetails(requestData);
            console.log('Update successful:', result);
        } catch (error) {

        }
    };

    const handleUpdateAccountingDetails = async () => {  
        try {     
            const requestData = apiData ? apiData : data;  
            requestData["currentOnboardingStep"] = livePage.current;
            const result = await UpdateAccountingDetails(requestData);
             console.log('Update successful:', result);   
                } catch (error) {   
 
                     }   };


    const [NotifyData, setNotifyData] = useState();

    const renderFundFormByStep = () => {
        switch (step) {
            case 1:
                return <CompanyProfile data={data} FiltersData={FiltersData} setapiData={setapiData} 
                        Onboardingsteperrors={Onboardingsteperrors}/>;

            case 2:
                return <LeadershipTeam data={data} FiltersData={FiltersData} setapiData={setapiData} Onboardingsteperrors={Onboardingsteperrors}/>;

            case 3:
                return <LegalCounsel data={data} FiltersData={FiltersData} setapiData={setapiData} Onboardingsteperrors={Onboardingsteperrors}/>;

            case 4:
                return <Accounting data={data} FiltersData={FiltersData} setapiData={setapiData} Onboardingsteperrors={Onboardingsteperrors}/>;

            case 5:
                return <BoardMembers data={data} FiltersData={FiltersData} setapiData={setapiData}  Onboardingsteperrors={Onboardingsteperrors}/>;

            case 6:
                return <UploadDocumentation onSaveClick={handleModalNavigation(false)}/>;

                case 7:
                    return <Thankyou handleCancelModel={handleCancelModel} companyName={companyName} />;
    

            default:
                return <></>;
        }
    };


    return (
        <StepModal
            open={open}
            onPrevClick={() => handleModalNavigation(true)}
            onNextClick={() => handleModalNavigation(false)}
            currentStep={livePage.current}
            firstStep={1}
            lastStep={6}>
            <DialogTitle >
                <NotifyTitle step={step} noofsteps={7} title={stepsData.find(item => item.stepno === step)?.step} onClose={() => {
                    setModalOpen(false);
                    handleCancelModel(false);
                }} />
            </DialogTitle>

            <DialogContent >
                {renderFundFormByStep()}
            </DialogContent>

            <ToastContainer />
        </StepModal>
    )
}
