import { useTheme } from '@mui/material';
import Chart from 'react-apexcharts';

const useChartOptions = (labels, text) => {
    const theme = useTheme();
    return {
        chart: {
            background: 'transparent'
        },
        dataLabels: {
            enabled: false
        },
        labels,
        legend: {
            show: true,
            formatter: function (name, { seriesIndex, w }) {
                return w?.config?.labels[seriesIndex] + "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$" + w?.config?.series[seriesIndex]?.toLocaleString();
            },
            itemMargin: {
                horizontal: 10,
                vertical: 15
            },
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            showAlways: true,
                            fontSize: '12px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 500,
                            label: `${text}`,
                            color: '#0065bd',
                            formatter: function (w) {
                                return `$${(w?.config?.series?.reduce((a, b) => a + b))?.toLocaleString()}`
                            }
                        }
                    }
                },
            },
        },
        stroke: {
            width: 0
        },
        theme: {
            mode: theme.palette.mode
        },
        tooltip: {
            fillSeriesColor: false,
            y: {
                formatter: (seriesName) => {
                    return `$${seriesName?.toLocaleString()}`;
                },
            },
        }
    };
};

const AIPChart = ({ series = [], labels = [], type = "donut", text = "" }) => {
    const chartOptions = useChartOptions(labels, text);
    return (
        <Chart
            height='100%'
            options={chartOptions}
            series={series}
            type={type}
            width='100%'
        />
    );
}

export default AIPChart;