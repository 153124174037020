import axios from "axios";
import axiosApiCall from "../../Services/axiosApiCall";
import { getAPIResponse } from "../../../utils/common";



export async function GetFundSubscriptions(fundID){
  return await axiosApiCall.get(`/v1/FundSubscription/GetFundSubscriptions?fundID=${fundID}`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
  });
}


export async function updateFundSubscriptionStatus(requestData) {
  return await axiosApiCall.post(`/v1/FundSubscription/UpdateSubscriptionStatus`, requestData)
      .then(res => {
          return getAPIResponse(res);
      }).catch(error => {
         
          return getAPIResponse(error);
      });
}

export async function updateFundSubscriptionStatusWithEmptyDetails(SubscriptionID,subscriptionStatus,isFundManagerAction,signedenvelopeId) {
  return await axiosApiCall.post(`/v1/FundSubscription/UpdateSubscriptionStatus?FundSubscriptionID=${SubscriptionID}&fundSubscriptionStatus=${subscriptionStatus}&isFundManager=${isFundManagerAction}&envelopID=${signedenvelopeId}&ActionByUserID=0`)
      .then(res => {
          return getAPIResponse(res);
      }).catch(error => {
         
          return getAPIResponse(error);
      });
}