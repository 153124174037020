import React from 'react'
import '../../../Generalize.css';
import FundmanagerDetails from './FundmanagerDetails';
import { useState } from 'react';
import ManagementTeam from '../CreateFundModal/ManagementTeam';
import InvestmentStrategy from '../CreateFundModal/InvestmentStrategy';
import FeesExpenses from '../CreateFundModal/FeesExpenses';
import { useEffect } from 'react';
import VDRoom from '../../../common/VirtualDataRoom/VDRoom';
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandMore from "@mui/icons-material/ExpandMore";
import '../../../common/NestedList/styles.css'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
import { ExpandLess } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useSelector } from "react-redux";
import { getrootBackgroundColorConfig } from '../../../../state/slices/colorConfigSlice';
import ManageTeams from './ManageTeams';
import { BoxAlignCenter } from '../../../CommonCss';
import { getRoles } from '../../../FundManagerDashboard/Services/FundNewsService';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { convertStringToArray } from '../../../common/Functions/ConvertStringtoArray';
import { Row } from 'react-bootstrap';
import { updateFund } from '../../services/MarketplaceService';
import { AppDashboards } from '../../../../utils/enum';
import { PageHeader } from '../../../common/PageHeader/PageHeader';
import { HeaderTemplates } from '../../../common/PageHeader/PageHeader';
import { getMarketPlaceFunds } from '../../../marketplace-dashboard/services/mpservices';
import { addFavourite, updateAllFunds } from '../../../../state/slices/MarketPlaceSlice';
import { useDispatch } from 'react-redux';
import { getFundbyId } from '../../../investor-fund-subscription/services/services';

const EditFund = () => {
   
  const [editable, setEditable] = useState(false);
  const showdocuments = false;
  const [openIndex, setOpenIndex] = React.useState(-1);
  const [refreshFlag, setRefreshFlag] = useState(false);
  const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);
  const dispatch = useDispatch();

  const [funddetailsedit,setFundDetailsEdit] = useState(false)
  const [InvestmentStrategyedit,setInvestmentStrategyedit] = useState(false);
  const [disabledFMoptions,setFMDisabledFMOptions] = useState({})

  const [fdloadingbtn, setFdLoadingBtn] = useState(false);

  const handleClick = (index) => {
    getFundDetails();
    
      if (fundData) {
          setOpenIndex((prevIndex) => (prevIndex === index ? -1 : index));
      }
  };

  const handleClickEdit = (index,setedit) => {
    setedit(true);
    /* setEditable(true); */
  };

  const handleClickCancel = (index,setedit) => {
    setOpenIndex(index);
    setFundData(fundData);
    setedit(false);
    
  };

  const handleClose = (index) =>{
    setOpenIndex(index);
  }

  const [InitialData, setInitialData] = useState("");
  const [fundData, setFundData] = useState(null);
  const FundName = secureLocalStorage.getItem("FundName");
  const fundId = secureLocalStorage.getItem("FundId");
  const [componentRefresh,setcomponentRefresh] = useState(true)

  const [FundDetailErrors,setFundDetailErrors] = useState(false);

  const getFundDetails = async () => {
    const data = await getFundbyId(fundId);
    if(data.responseCode === 200){
      setInitialData(data.responseData);
      setFundData(data.responseData);
      userDetails(data.responseData);
    }
    else{

    }
  };

  const userDetails = async (currfundData) => {
    const loggedInUserId = secureLocalStorage.getItem("userId");
    const data = await getRoles("Fund Manager");
    if (data?.responseCode === 200) {
      const usedIds = convertStringToArray(currfundData?.fundStaff)
      const fundStaffOptions = data?.responseData?.filter(a => a.userRoleName !== AppDashboards.INV_DASHBOARD)
      .map(user => ({
          label: 
          user.userId === currfundData?.fundManagerID ? `${currfundData?.fundManager} (Owner)` :
           user.userFullName,
          value: user.userId, 
          disabled: usedIds.includes(user.userId) || user.userId === currfundData?.fundManagerID,   
          briefDescription : user.briefDescription
      }));
      setFundStaffDropdown(fundStaffOptions);
    } else {
        console.log("Error fetching user details");
    }
  }

  useEffect(() => {
    getFundDetails();
  }, []);
  const loggedInUserId = secureLocalStorage.getItem("userId");
  const [fundstaffdropdown, setFundStaffDropdown] = useState(null);

  useEffect(() => { 
}, []);
const [managementstaffdropdown, setManagementStaffDropdown] = useState(null);


useEffect(() => { 
  const managementStaffDetails = async () => {
  const data = await getRoles();
  if (data.responseCode === 200) {
        
      const managementStaffOptions = data.responseData?.filter(a => a.userRoleName !== AppDashboards.INV_DASHBOARD)?.map(user => ({
          label: user.userFullName,
          value: user.userId, 
          disabled: user.userId === loggedInUserId,  
      }));
      setManagementStaffDropdown(managementStaffOptions);
  } else {
      console.log("Error fetching user details");
  }
};
managementStaffDetails();
}, []);
  const [teammembers,setTeamMembers] = useState([
    {
      mainItem: "New Team Member",
      Component: () => (
        <div>
           <ManagementTeam
              data={fundData?.fundManagementTeams && fundData?.fundManagementTeams.length > 0 ? fundData.fundManagementTeams[0] : {}}
              updateData={data => {
                setFundData(currFund => ({ ...currFund, FundManagementTeams: { ...currFund.FundManagementTeams, ...data } }))
              }}
              editable={editable}
              managementStaffData= {managementstaffdropdown ? managementstaffdropdown : []}
            />
        </div>
      ),
    }
    ]);

    const [FeesandExpenses,setFeesandExpenses] = useState([
      {
        mainItem: "Fees and Expenses",
        Component: () => (
          <div>
             <FeesExpenses
            data={fundData.feesExpenses}
            updateData={data => {
              setFundData(currFund => ({ ...currFund, FeesExpenses: { ...currFund.FeesExpenses, ...data } }))
            }}
            editable={true}
          /> 
          </div>
        ),
      }
      ]);

  
  const EditFundDetails = [
    {
      mainItem: "Fund Details",
      Component: () => (
        <div>
          <FundmanagerDetails data={fundData}
            updateData={data => setFundData({ ...fundData, FundDetails: data })} 
            setFundData={setFundData}
            editable={funddetailsedit} 
            setEdit={setFundDetailsEdit}
            setFMDisabledFMOptions={setFMDisabledFMOptions}
            disabledFMoptions={disabledFMoptions}
            FundDetailErrors={FundDetailErrors} setFundDetailErrors={setFundDetailErrors}
            handleClickSave={handleClickSave} 
            handleClickEdit={handleClickEdit}
            handleClickCancel={handleClickCancel}
            openIndex={openIndex}
            setOpenIndex={setOpenIndex}
            fundStaffData= {fundstaffdropdown}
            setFundStaffDropdown={setFundStaffDropdown}
            loadingbtn={fdloadingbtn}
            />
        </div>
      ),
    },
    {
      mainItem: "Management Team",
      Component: () => (
        <div>
          <ManageTeams
            list={teammembers}
            setList={setTeamMembers}
            editable={editable}
            name={"managementteams"}
            setFMDisabledFMOptions={setFMDisabledFMOptions}
            disabledFMoptions={disabledFMoptions}
            label={"New Team Member"}
            ButtoonLabel={"ADD TEAM MEMBER"}
            data={fundData?.fundManagementTeams}
                    updateData={data => {
                        setFundData(currFund => ({ ...currFund, FundManagementTeams: { ...currFund.FundManagementTeams, ...data } }))
                    }}
            Component={ManagementTeam}
            getFundDetails={getFundDetails}
            setFundData={setFundData}
            managementStaffData= {managementstaffdropdown ? managementstaffdropdown : []}
          />
        </div>
      ),
    },
    {
      mainItem: "Investment Strategy and Philosophy",
      Component: () => (
        <div>
          <InvestmentStrategy
            data={fundData}
            updateData={data => {
              setFundData(currFund => ({ ...currFund, InvestmentStrategy: { ...currFund.InvestmentStrategy, ...data } }))
            }}
            editable={InvestmentStrategyedit}
            showdocuments={showdocuments}
            setEdit={setInvestmentStrategyedit}
            FundDetailErrors={FundDetailErrors} setFundDetailErrors={setFundDetailErrors}
            handleClickSave={handleClickSave} 
            handleClickEdit={handleClickEdit}
            handleClickCancel={handleClickCancel}
            openIndex={openIndex}
            setOpenIndex={setOpenIndex}
          />
        </div>
      ),
    },
    {
      mainItem: "Fees and Expenses",
      Component: () => (
        <div>
          <ManageTeams
            list={FeesandExpenses}
            setList={setFeesandExpenses}
            editable={editable}
            name={"feesandexpenses"}
            label={"Fees And Expenses"}
            ButtoonLabel={"ADD Fees And Expenses"}
            data={fundData?.feesExpenses}
            updateData={data => {
              setFundData(currFund => ({ ...currFund, FeesExpenses: { ...currFund.FeesExpenses, ...data } }))
            }}
            Component={FeesExpenses}
            getFundDetails={getFundDetails}
          />
        </div>
      ),
    },
  ];


  const handleClickSave = async(errors) => {
    /*  */;
    if(errors == false){
      if (InitialData?.noOfInvestors > 0 && fundData?.FundDetails?.visibility === "0") {
        setFundDetailsEdit(false);
        toast.warning("Cannot hide this fund, as it has Active Subscription in place",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" , style: { zIndex: 9999 }});
          setFundData(InitialData);
          setTimeout(() => {
            setcomponentRefresh(false);
            setTimeout(() => {
              setcomponentRefresh(true);
            }, 0);
          }, 4000);
      }
      else {
        setFdLoadingBtn(true);
        let currFundData = InitialData;
        currFundData = {
          ...currFundData,
          ...fundData.FundDetails,
          ...fundData.InvestmentStrategy,
        };

        
  
        currFundData.fundManagementTeams = [...fundData.fundManagementTeams];
  
        console.log("CurrFundData", currFundData.dscInvestmentStrategy)
        console.log("FundData", fundData.dscInvestmentStrategy)
        console.log(fundData.dscInvestmentStrategy)
        console.log(currFundData)

        if (fundData.FundDetails) {
          currFundData.iconImage = fundData.FundDetails?.iconImage === "" ? "" : fundData.FundDetails.iconImage?.name ?? InitialData.iconImage;
          currFundData.bgImage = fundData.FundDetails?.bgImage === "" ? "" : fundData.FundDetails.bgImage?.name ?? InitialData.bgImage;
      } else {
          currFundData.iconImage = fundData?.FundDetails?.iconImage == undefined ? "" : InitialData.iconImage;
          currFundData.bgImage = fundData?.FundDetails?.bgImage  == undefined ? "" : InitialData.bgImage;
      }
      
      const isFundMatured = secureLocalStorage.getItem("selectedFund")?.FundMaturity;
      
      
        const requestData  = {
          "fundId": currFundData.fundID,
          "fundName": currFundData.fundName,
          "fundManager": currFundData.fundManager,
          "fundDescription": currFundData.fundDescription,
          "investmentStrategyId": currFundData.investmentStrategyId,
          StrategyName:currFundData.StrategyName,
          TargetIndustry:currFundData.TargetIndustry,
          GeographicFocusName:currFundData.GeographicFocusName,
          "fundTargetIndustryId": currFundData.fundTargetIndustryId,
          "geographicFocusId": currFundData.geographicFocusId,
          "fundStatus": currFundData.fundStatus,
          "vintage": parseInt(currFundData.vintage,10),
          "investmentPeriodCriteria": currFundData.investmentPeriodCriteria,
          "investmentCycle": currFundData.investmentCycle,
          "visibility": currFundData.visibility,
          "bgImage": currFundData.bgImage,
          "iconImage": currFundData.iconImage,
          "fundSize": currFundData.fundSize,
          "minimumInvestmentAmount": currFundData.minimumInvestmentAmount,
          "targettedIRR": currFundData.targettedIRR,
          "createdBy": currFundData.fundManager,
          "createdDate": "2023-10-12T12:03:08.034Z",
          "updatedBy": currFundData.fundManager,
          "updatedBy": currFundData?.fundManager || '',
          "updatedDate": currFundData?.updatedDate || "2023-10-12T12:03:08.034Z",
          "userId" : secureLocalStorage.getItem("userId") || '',
          "isFundMatured" : isFundMatured,
          "isFirmAsGP": currFundData?.isFirmAsGP,
          "legalStructureId": currFundData?.legalStructureID,
          "fundStaff": !(fundData.FundDetails?.isFirmAsGP) && Array.isArray(fundData.FundDetails?.fundStaffeg)
                      ? fundData.FundDetails?.fundStaffeg.join(",")
                      : "",
      }
      
        const formDataBody = new FormData();
        if (fundData.FundDetails?.iconImage?.name !== undefined) {
          formDataBody.append('iconImage', fundData.FundDetails.iconImage, fundData.FundDetails.iconImage.name);
      }
      
      if (fundData.FundDetails?.bgImage?.name !== undefined) {
          formDataBody.append('bgImage', fundData.FundDetails.bgImage, fundData.FundDetails.bgImage.name);
      }
      
        formDataBody.append("fundDetails", JSON.stringify(requestData));
  
        const response = await updateFund(formDataBody);
        if(response?.responseCode === 200){
          setFundData(currFundData);
          setFundDetailsEdit(false);
          toast.success("Successfully Saved",
            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
          console.log("Success", response);
          setFdLoadingBtn(false);
          getFunds();
          if(secureLocalStorage.getItem("FundName") !== requestData.fundName){
            secureLocalStorage.setItem("FundName",requestData.fundName);
            setRefreshFlag(true);
            setTimeout(() => {
                setRefreshFlag(false);
                }, 20);
          }
        } else {
          setFundDetailsEdit(false);
          setFdLoadingBtn(false);
          toast.error("Error Updating FundDetails",
            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
      }
    }
    else{
      toast.warning("Pleasse Check the input fields",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" , style: { zIndex: 9999 }});
    }
    
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
  }));


  //#region api get calls
  const getFunds = async () => {
    const data = await getMarketPlaceFunds();
    if (data.responseCode === 200) {
        dispatch(updateAllFunds({ value: data.responseData }));
        const filterFunds = data.responseData.filter(item => item.favourite === true);
        filterFunds.forEach(favFund => {
            dispatch(addFavourite({ fund: favFund }));
        });
    } else {
        console.log('Unable to load funds');
    }
}

  return (
    <>
    {
      !refreshFlag && <PageHeader
                          title={`Edit Fund : ${FundName}`}
                          template={HeaderTemplates.NORMAL_VIEW}/>
    }
    {
      componentRefresh &&   <div className="wrapper3">   
      <Box marginTop={2} spacing={1}>
            <Grid container  xs={12} sm={12}>
                    <Grid item xs={12} sm={12} md={6.9} className='whiteCard mtb2'>
                        <List
                            sx={{ width: "100%" }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            subheader={
                              <ListSubheader component="div" id="nested-list-subheader"> 
                                {/* Nested List Items */}
                              </ListSubheader>
                            }
                          >
                            {EditFundDetails.map((item, index) => (
                              <div 
                                key={index}
                              >
                                <ListItemButton
                                  onClick={() => handleClick(index)}
                                  sx={{
                                    "&.MuiButtonBase-root.MuiListItemButton-root": {
                                      maxWidth: "none !important",
                                      background: "white",
                                    },
                                  }}
                                >
                                  <ListItemText primary={item.mainItem} />
                                  {openIndex === index ? (
                                      <ExpandLess close={openIndex === index} />
                                  ) : (
                                      fundData ?  <ExpandMore open={openIndex === index} /> : <AutorenewIcon /> 
                                  )}

                                </ListItemButton>

                                <Collapse in={openIndex === index} timeout="auto" unmountOnExit>
                                  <List component="div" disablePadding>
                                    {openIndex === index && (
                                      <div style={{ marginLeft: '15px', width: '94%' }}>
                                        {item.Component(editable)}
                                      </div>
                                    )}
                                  </List>
                                </Collapse>
                              </div>
                            ))}
                          </List>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} className='whiteCard height-450-noscroll editfund-vdr'>
                    {
                       !refreshFlag &&
                        <VDRoom VDRType={'Fund'} cnrtFundID={fundId} crntSubscriptionID={0}/>
                     }
                    </Grid>
                  
                </Grid >
        <ToastContainer />
      </Box>
  </div>
    }
    
    </>
  )
}

export default EditFund