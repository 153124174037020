import axios from 'axios';
import Configurations from '../../Configurations';
import secureLocalStorage from 'react-secure-storage';
 

const axiosApiCall = axios.create({
  baseURL: Configurations.apiBaseUri
});

// Request interceptor for adding the bearer token
axiosApiCall.interceptors.request.use(
  (config) => {
    // Token
    const token = "QWx0MzZvX1VzcjpBbHQzNm9fUHdk";
    if (token) {
      config.headers.Authorization = `Basic ${token}`;
    }
    config.headers['TenantID']  = secureLocalStorage.getItem("tenantID"); // Tenant ID
    config.headers['LoggedinUserID'] = secureLocalStorage.getItem("userId"); // LoggedIn User ID
    config.headers['UserRoleID'] = secureLocalStorage.getItem("AssignedUserID"); // LoggedIn User Role ID
    config.headers['ParentNodeID'] = secureLocalStorage.getItem("ParentNodeID") ?? ''; // Parent Node ID
    config.headers['ParentNodeTypeID'] = secureLocalStorage.getItem("ParentNodeTypeID") ?? ''; // Parent Node Type ID
    config.headers['NodeID'] = secureLocalStorage.getItem("NodeID") ?? ''; // Node ID
    config.headers['NodeTypeID'] = secureLocalStorage.getItem("NodeTypeID") ?? ''; // Node Type ID
    config.headers['ADB2CUserID'] = secureLocalStorage.getItem("tenantUserId");
    config.headers['IsFirmUser'] = secureLocalStorage.getItem("isFirmUser");
    config.headers['UserName'] = secureLocalStorage.getItem("userName");
    config.headers['InvestorTypeID'] = secureLocalStorage.getItem("investorTypeID");
    config.headers['InvestorSubTypeID'] = secureLocalStorage.getItem("investorSubTypeID");
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
 
// Export the api instance
export default axiosApiCall;