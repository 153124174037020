import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./OverView.module.css";
import VDRoom from "../../common/VirtualDataRoom/VDRoom";
import { useState } from "react";
import AIPDataGrid from "../../common/DataGrid/AIPDataGrid";
import React from 'react';
import { useEffect } from "react";
import TreeItem from '@mui/lab/TreeItem';
import FolderIcon from '@mui/icons-material/Folder';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Checkbox from '@mui/material/Checkbox';
import '../../Generalize.css';
import { AppDashboards } from "../../../utils/enum";
import FundNews from "../../common/FundNews/FundNews";
import ChartSelector from "../../common/DataChart/ChartSelector";
import CircleIcon from "../../chatbot/Icon";
import secureLocalStorage from "react-secure-storage";
import DashboardSelect2 from "../../common/Select/DashboardSelect2";
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import AppNotifications from "../../common/AppNotification/AppNotificationComponent";
import CurrencyIndicator from "../../common/DataGrid/CurrencyIndicator";
import { formatAsUSACurrency } from "../../common/CurrencyFormater/formatAsUSACurrency";
import { width } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import { Button } from "react-bootstrap";
import FundTransactions from "../../common/FundTransactions/FundTransactions";
import SelectField from "../../common/input-fields/SelectField";
import { FieldVariants } from "../../common/TextInput/appInputenum";
import { getSuggestedArticlesByFundID } from "../../FundManagerDashboard/Services/FundNewsService";
import { fundTypesEnum } from "../../marketplace-dashboard/jsonData";

const OverviewPage = (props) => {

  //#region props
  const { fund } = props;
  // debugger
  //#region variables
  const navigate = useNavigate();
  const moment = require('moment');
  const fundId = secureLocalStorage.getItem("FundId");
  const years = [
    { id: 1, label: '2020', value: 2020 },
    { id: 2, label: '2021', value: 2021 },
    { id: 3, label: '2022', value: 2022 },
    { id: 4, label: '2023', value: 2023 },
  ];
  const [FundNewsData, setFundNewsData] = useState(null);
  const defaultSet = [
    {
      primaryLabel: 'Total Capital Committed',
      primaryValue: formatAsUSACurrency(fund?.capitalCommitted !== null ? fund?.capitalCommitted : 0),
      secondaryLabel: '',
      secondaryValue: ''
    },
    {
      primaryLabel: 'Total Capital Under Review',
      primaryValue: formatAsUSACurrency(fund?.totalCapitalUnderReview),
      secondaryLabel: '',
      secondaryValue: ''
    },
    {
      primaryLabel: 'Target Raise',
      primaryValue: formatAsUSACurrency(fund?.fundSize !== null ? fund?.fundSize : 0),
      secondaryLabel: '',
      secondaryValue: ''
    },
    {
      primaryLabel: 'Capital Remaining',
      primaryValue: formatAsUSACurrency(fund?.fundSize - (fund?.capitalCommitted + fund?.totalCapitalUnderReview)),
      secondaryLabel: '',
      secondaryValue: ''
    },
    {
      primaryLabel: secureLocalStorage.getItem('FundTypeName') === fundTypesEnum.REAL_ESTATE_FUND ? "Assets" : 'Portfolio Companies',
      primaryValue: fund?.portfolioCompaniesCount ? fund?.portfolioCompaniesCount : 0,
      secondaryLabel: '',
      secondaryValue: ''
    },
    {
      primaryLabel: 'Current IRR',
      primaryValue: '18.5%',
      secondaryLabel: 'Target IRR',
      secondaryValue: `${fund?.targettedIRR}%`
    },
    /* {
      primaryLabel: 'Goal Fund Amount',
      primaryValue: fund.goalFundAmount !== null ? fund.goalFundAmount : 0,
      secondaryLabel: '',
      secondaryValue: ''
    } */
  ]
  const FundStats = fund && secureLocalStorage.getItem('FundTypeName') === fundTypesEnum.PRIVATE_EQUITY
    || secureLocalStorage.getItem('FundTypeName') === fundTypesEnum.VENTURE_CAPITAL ? defaultSet : fund && secureLocalStorage.getItem('FundTypeName') === fundTypesEnum.PRIVATE_CREDIT ? [
      {
        primaryLabel: 'Total Capital Raised',
        primaryValue: formatAsUSACurrency(fund.capitalCommitted !== null ? fund.capitalCommitted : 0),
        secondaryLabel: '',
        secondaryValue: ''
      },
      {
        primaryLabel: 'Total Capital Under Review',
        primaryValue: formatAsUSACurrency(fund.totalCapitalUnderReview),
        secondaryLabel: '',
        secondaryValue: ''
      },
      {
        primaryLabel: 'Target Raise',
        primaryValue: formatAsUSACurrency(fund.fundSize !== null ? fund.fundSize : 0),
        secondaryLabel: '',
        secondaryValue: ''
      },
      {
        primaryLabel: 'Capital Remaining',
        primaryValue: formatAsUSACurrency(fund.fundSize - (fund.capitalCommitted + fund.totalCapitalUnderReview)),
        secondaryLabel: '',
        secondaryValue: ''
      },
      {
        primaryLabel: 'Portfolio Companies',
        primaryValue: fund.portfolioCompaniesCount ? fund.portfolioCompaniesCount : 0,
        secondaryLabel: '',
        secondaryValue: ''
      },

    ] : defaultSet;

  //#region api get calls
  const getFundNews = async () => {
    const data = await getSuggestedArticlesByFundID(fundId);
    if (data.responseCode === 200) {
      setFundNewsData(data.responseData);
    }
    else {
    }
  }

  //#region useeffect
  useEffect(() => {
    getFundNews();
  }, []);

  //#region return
  return (
    <div>
      <h4>Overview</h4>
      <div className="whiteCard">
        <div className="Overview_fundStatusHeader">
          <div className='space-between'>
            <b className={styles.fundStatusVal}>
              {fund?.fundStatusName} &nbsp;|&nbsp; {secureLocalStorage.getItem("FundTypeName")}
            </b>
          </div>
          <div className="width-150">
            <SelectField
              options={years}
              label={'Year'}
              variant={FieldVariants.OUTLINED}
              onChange={() => { }}
              value={''} />
          </div>
        </div>
        {/* status header end  */}

        {fund && <div className="Overview_statistics_row">
          {FundStats?.map(stats => (
            <div className="statisticsCard">
              <div className='statisticsCard_title'>{stats.primaryLabel}</div>
              <h4>{stats.primaryValue}</h4>
              {
                stats.secondaryLabel ? (
                  <div className="statisticsCard_b_title">
                    {/* <span>{stats.secondaryLabel} :  {fund.targettedIRR}%</span> */}
                  </div>
                ) : (
                  <div style={{ visibility: 'hidden' }}>.</div>
                )
              }
            </div>
          ))}
        </div>}

        {/* don't remove clearfix used for responsive floating purpose -RK */}
        <div className="clearfix"></div>
        {fund && <div className="fundstatus_details">
          <div className="fundstatus_details_sec">
            <div className="fundstatus_details_sec_row">
              <label >Vintage Year</label>
              <b >{fund.vintage}</b>
            </div>
            {
              secureLocalStorage.getItem('FundTypeName') !== fundTypesEnum.PRIVATE_CREDIT && <div className="fundstatus_details_sec_row">
                <label>First Closing Date</label>
                <b>{fund.fundClosingDate ? moment(fund.fundClosingDate).format('MM-DD-YYYY') : ''}</b>
              </div>
            }
            {
              secureLocalStorage.getItem('FundTypeName') !== fundTypesEnum.PRIVATE_CREDIT &&
              <div className="fundstatus_details_sec_row">
                <label>Strategy</label>
                <b >{fund.strategyName}</b>
              </div>}
            {
              secureLocalStorage.getItem('FundTypeName') !== fundTypesEnum.PRIVATE_CREDIT &&
              <div className="fundstatus_details_sec_row">
                <label>Geography</label>
                <b >{fund.geographicFocusName}</b>
              </div>}
            <div className="fundstatus_details_sec_row">
              <label>Visibility</label>
              <b >{fund.visibility === "1" ? "show" : "hide"}</b>
            </div>

          </div>

          <div className="fundstatus_details_sec">
            {
              secureLocalStorage.getItem('FundTypeName') !== fundTypesEnum.PRIVATE_CREDIT &&
              <div className="fundstatus_details_sec_row">
                <label>Sector</label>
                <b>{fund.targetIndustry}</b>
              </div>}
            <div className="fundstatus_details_sec_row">
              <label>Currency</label>
              <b >{fund.fundCurrency ?? "USD"}</b>
            </div>

            <div className="space-between padding-12-4">
              <label>General Partner</label>
              <div className="display-column ">
                {
                  fund?.isFirmAsGP ? <b>{secureLocalStorage.getItem("tenantName")}</b> :
                    fund?.fundStaffDetails?.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <b>
                            {item?.userName}
                          </b>
                          {index < fund.fundStaffDetails.length - 1 && ' , '}
                        </React.Fragment>
                      );
                    })
                }
              </div>
            </div>
            {/* <div className="fundstatus_details_sec_row">
              <label>Fund Manager</label>
              <b >{fund.fundManager}</b>
            </div> */}
          </div>
        </div>}
      </div>
      <Grid container xs='12' spacing={2} marginTop={1} style={{ margin: '0px auto' }}>
        <Grid item xs='12' md='6' style={{ paddingLeft: '0px' }} >
          <div className="whiteCard">
            <h6 className=''>{`Notifications`}</h6>
            <div className="notifications-grid">
              {<AppNotifications isInvestorNameRequired={true} crntFundID={secureLocalStorage.getItem("FundId")} crntInvestorid={0} />}
            </div>
          </div>

        </Grid>
        <Grid item xs='12' md='6' className="res-grid">
          <div className="whiteCard">
            {/* {FundNewsData ? <FundNews FundNewsData={FundNewsData} role={AppDashboards.FM_DASHBOARD} /> : null}  */}
            <div className="suggest_readgrid">
              {<FundNews FundNewsData={FundNewsData} role={AppDashboards.FM_DASHBOARD} />}
            </div>
          </div>
        </Grid>
      </Grid>
      <div className="whiteCard margin-top-20">
        <div className="fundTrans-grid">
          <FundTransactions fundTypeName={fund?.fundTypeName} />
        </div>
      </div>
      <div className="whiteCard mt2">
        <VDRoom VDRType={'Fund'} cnrtFundID={fundId} crntSubscriptionID={0} />
      </div>
      <CircleIcon />
    </div>
  );
};

export default OverviewPage;