import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { DialogActions, Typography } from '@mui/material';
import Text from '../Text/Text';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import { MuiOtpInput } from 'mui-one-time-password-input';
import ActionButton from '../ActionButton/ActionButton';
import { PostOTP, getOTPAttemptsIn24Hours } from '../../user-profile/services/UserProfileService';
import secureLocalStorage from 'react-secure-storage';
import { msalConfig } from '../../AppAuthentication/authConfig';
import OtpField from 'react-otp-field';
import { ToastContainer, toast } from 'react-toastify';
import { PublicClientApplication } from '@azure/msal-browser';
import { VerifyOTP } from '../../administration/Services/TabsData';
import { OtpType } from './OtpEnum';

const OTPVerificationDialog = ({ open, onClose,type, OtpData, handleSave, UpdatedEmailAdress, onlyOtpVerification, getOtpData , otpAttemptsLeft,getotpAttemptsIn24Hours}) => {
    const [otp, setOtp] = React.useState('');
    const [retryStep, setRetryStep] = useState(0);
    const [Relogin, setRelogin] = useState(false)
    const [btnloading, setBtnLoading] = useState(false);
    const msalInstance = new PublicClientApplication(msalConfig);
    // const [otpAttemptsLeft, setOTPAttemptsLeft] = useState(3);
    const Logout = () => {
        secureLocalStorage.clear();
        msalInstance.logoutRedirect({ postLogoutRedirectUri: "/?login_page=true" });
    };
    const reloginPopup = () => {
        return (
            <Dialog open>
                <DialogTitle>

                </DialogTitle>
                <DialogContent>
                   Your email address has been changed successfully. Please log in again using your new email address.
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { Logout() }}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    const handleChange = (newValue) => {
        setOtp(newValue);
    };

    const [timer, setTimer] = useState(150);
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    
//   useEffect(() => {
//     const otpAttemptsIn24Hours = async () => {
//       const data = await getOTPAttemptsIn24Hours();
//       if(data?.responseCode === 200){
//         setOTPAttemptsLeft(3 - data?.responseData);
//       }else{
//         toast.warning("Unable to fetch number of OTP attempts pending", {
//           position: toast.POSITION.BOTTOM_RIGHT,
//           theme: "colored",
//         });
//       }
//     }
//     otpAttemptsIn24Hours();
//   }, [resendOTP])

    const handleResendClick = () => {
        setRetryStep(0);
        if(type === OtpType.EMAIL_ADDRESS){
            if( otpAttemptsLeft > 0){
                getOtpData()
             }
             else{
                 toast.error('You have exceeded your attempts. You can retry changing your email address after 24 hours.',
                 { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
                 /* handleClose(); */
             }
        }
        else{
            getOtpData()
        }
    };
    const requestData = {
        "otpId": OtpData?.id,
        "otp": otp,
        "newEmailAddress": UpdatedEmailAdress,
        "oldEmailAddress": secureLocalStorage.getItem("userEmail"),
        "adb2cUserID": secureLocalStorage.getItem("tenantUserId"),
        "userName": secureLocalStorage.getItem("userName")
    };
    const handleVerifyClick = async () => {
        setBtnLoading(true);
        if (onlyOtpVerification) {
            const data = await VerifyOTP(OtpData?.id, otp);
            if (data?.responseCode == 200) {
                setOtp('');
                onClose();
                handleSave();
                setBtnLoading(false);
            } else {
                setBtnLoading(false);
                toast.warning("The OTP entered is not correct.",
                    { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            }
            //handleSave();
        }
        else {
            const data = await PostOTP(requestData);
            if (data?.responseCode == 200) {
                setRelogin(true)
                setBtnLoading(false);
                toast.success(data?.responseData,
                    { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            } else {   
                setRetryStep(retryStep + 1);              
                setBtnLoading(false);
                toast.warning("The verification code entered is not correct (or) Email entered already exists.",
                    { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
                    if (retryStep === 2) {
                        setIsResendDisabled(false);
                    }            
                }
        }
    };

    const handleClose = () => {
        setOtp('');
        onClose();
    };



    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
               <h6>OTP Verification</h6>
                <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} alignItems="center" justifyContent="center" style={{ marginTop: '10px' }}>
                    <MuiOtpInput
                        value={otp}
                        onChange={handleChange}
                        sx={{ width: '80%' }}
                        length={6} />
                </Grid>
                <Grid container spacing={2} alignItems="center" justifyContent="center" style={{ marginTop: '10px' }}>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item justifyContent='center'>
                                <ActionButton variant='contained' 
                                    /* label={`Verify & Proceed in ${Math.floor(timer / 60)}:${timer % 60 < 10 ? `0${timer % 60}` : timer % 60}`} */
                                    label={`Verify & Proceed`}
                                    onClick={handleVerifyClick}
                                    loading={btnloading}
                                    styleProps={{
                                        padding: '8px 36px'
                                    }}/>

                            </Grid>

                        </Grid>
                        <Grid container spacing={2} justifyContent="center" marginTop={'10px'}>
                            <div>
                                Didn’t receive verification code  ? 
                                <a href="#" onClick={handleResendClick} disabled={isResendDisabled} style={{ textDecoration: 'underline' }}>
                                    {'Resend'}
                                </a>
                            </div>
                        </Grid>
                       {
                         !onlyOtpVerification &&  
                         <Grid container spacing={2} alignItems="center" justifyContent="center" marginTop={'5px'}>
                            <Typography>
                               You have [{otpAttemptsLeft}] attempts left to resend the code.
                            </Typography>
                        </Grid>
                       }
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center" justifyContent="space-between" style={{ marginTop: '20px' }}>

                </Grid>
            </DialogContent>
            {Relogin && reloginPopup()}
        </Dialog>
    );
};

export default OTPVerificationDialog;
