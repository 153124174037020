import React from 'react'
import { Divider, Avatar, Grid, Paper, Link } from "@mui/material";


const CommentSectionBox = (props) => {
  const { image, name, description, date, attachedlinks } = props;

  const formatteddate = new Date(date);
  formatteddate.setHours(formatteddate.getHours() + 5);
  formatteddate.setMinutes(formatteddate.getMinutes() + 30);

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: "Asia/Kolkata" // Specify IST time zone
  };

  const formattedTimestamp = formatteddate.toLocaleString("en-IN", options);


  return (
    <Grid xs={12}>
      <Paper style={{ padding: "20px 20px" }}>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item>
            <Avatar alt="Remy Sharp" src={image} />
          </Grid>
          <Grid justifyContent="left" item xs zeroMinWidth>
            <h4 style={{ margin: 0, textAlign: "left" }}>{name}</h4>
            <p style={{ textAlign: "left" }}>
              {description}
            </p>
            {attachedlinks?.map((link, index) => (
                <React.Fragment key={index}>
                  <Link
                    href={link.containerURL + link?.filePath}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {link.fileName}
                  </Link>
                  {index < attachedlinks.length - 1 && <br />} 
                </React.Fragment>
              ))}
            <p style={{ textAlign: "left", color: "gray" }}>
               Posted on {formattedTimestamp}
            </p>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

export default CommentSectionBox