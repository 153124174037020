import React from 'react'
import {
    DialogTitle,
    DialogContent,
    IconButton,
    Grid,
    DialogActions,
    Button
} from '@mui/material';
import { Close } from '@mui/icons-material';
import Text from '../../common/Text/Text';


function RemovingInvestor(props) {
    const { RemoveInvestorCancelClick } = props;
    return (
        <div className='InfoBgColor'>
            <DialogTitle>
                <Grid>
                    <IconButton onClick={RemoveInvestorCancelClick}>
                        <Close htmlColor='white' />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent style={{ color: 'white', fontFamily: 'cresta' }}>
                <Text label='Removing This Investor' />
                <Grid className='margin-top-15' fontSize='16px'>
                    This action will permanently delete investor's information from upload.
                </Grid>
                <Grid className='margin-top-15'>
                    Are you sure you want to proceed ?
                </Grid>
            </DialogContent>
            <DialogActions>
                <div className="space-between">
                    <div>
                        <Button className="btn-primary" component="label" variant="outlined"
                        //onClick={onClose}
                        >
                            Cancel
                        </Button>
                    </div>
                    <div className="margin-left-10">
                        <Button
                            variant="contained"
                            component="span"
                        // startIcon={<FileUploadIcon />}
                        //onClick={handleFileUpload}
                        >
                            Remove Investor
                        </Button>
                    </div>
                </div>
            </DialogActions>
        </div>
    )
}

export default RemovingInvestor