import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import propTypes from 'prop-types';
import { MUIInputsMarginTop, MUIInputsMarginTop0 } from '../../CommonCss';
import { usCurrencyFormat, usPhoneNumberFormat, zipcode, Percentage, AlphanumericInput, NumericInputMask, AppTextInputCurrencyFormat, AppTextPercentageFormat, NumericInput } from './InputTypes';
import { TextFormat } from '@mui/icons-material';
import { validationsTypeEnum, InputTypes } from './appInputenum';
import { textFormatTypeEnum } from './appInputenum';
import { useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import { foulWords, reservedWords } from '../../registration-profiling/foulwords';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

//Need to add the below code in the Parent Component if Validation is required

/*  const [noofValidFields, setNoofValidFields] = useState({validfields : 0});

    const [checkIfValid, setcheckIfValid] = useState(false);

    const  TotalFiledsToValidate  = 0; //Set the total number of fields to validate

    const checknoofValidFields = (isValid) => {
        console.log('isValid', isValid);
        noofValidFields['validfields'] = isValid ? noofValidFields['validfields'] + 1 : noofValidFields['validfields'];
        if (noofValidFields['validfields'] === TotalFiledsToValidate) {
            alert('Data Saved');
            noofValidFields['validfields'] = 0;
        }
    }
    const handleSave = () => {
        setcheckIfValid(true);
        noofValidFields['validfields'] = 0;
      }
}; */

const AppTextInput = (props) => {

    const {
        type,
        textFormatType,
        onChange,
        variant,
        required,
        multiline,
        defaultValue,
        placeholder,
        rows,
        label,
        name,
        disabled,
        size,
        sortDirection,
        tabIndex,
        charactersMaxLength,
        charactersMinLength,
        validateField,
        validateOptionalField,
        validationType,
        validationMessage,
        checkIfValid,
        setcheckIfValid,
        checknoofValidFields,
        cancelFields,
        options,
        infoMessage,
        onCancel,
        dataType
    } = props;

    const [isInputValid, setIsInputValid] = useState(true);


    const [iserror, setError] = useState(false);

    const handleChange = (ev) => {
        const { name, value } = ev.target;
        const isValid = ValidateField(name, value);
        setError(!isValid);//need to set the state to trigger the error message
        /* if (isValid) {
            onChange(name, value);
        }
        else {
            // inform parent 
        } */
        onChange(name, value);

    };

    const isFoulWord = (word) => {
        const foulWordss = [...foulWords];
        const reservedWordsList = [...reservedWords];
        const foulWordsList = [...foulWordss, ...reservedWordsList];
        const sanitizedWord = word.replace(/\s/g, '').toLowerCase();
        return foulWordsList.some(foulWord => sanitizedWord.includes(foulWord));
    };


    const ValidateField = (name, value) => {
        if (validateField || validateOptionalField) {
            if (validationType === validationsTypeEnum.ONLY_ALPHABETS) {
                const onlyAlphabetsRegex = /^[A-Za-z\s]+$/;
                const isValid = onlyAlphabetsRegex.test(value);
                return isValid;
            }
            else if (validationType === validationsTypeEnum.US_PHONE_NUMBER) {
                const usPhoneNumberRegex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
                const isValid = usPhoneNumberRegex.test(value);
                return isValid;
            }
            else if (validationType === validationsTypeEnum.ONLY_NUMBERS) {
                const onlyNumbersRegex = /^[1-9][0-9]*$/;
                const isValid = onlyNumbersRegex.test(value);
                return isValid;
            }
            else if (validationType === validationsTypeEnum.BETWEEN_CHARACTERS) {
                const isValid = (value.length > charactersMaxLength || value.length < charactersMinLength);
                return isValid;
            }

            else if (validationType === validationsTypeEnum.MIN_CHARACTERS) {
                const isValid = (value.length > 0) && (value.length > charactersMinLength);
                return isValid;
            }
            else if (validationType === validationsTypeEnum.MAX_CHARACTERS) {
                return (value.length > charactersMaxLength);
            }
            else if (validationType === validationsTypeEnum.PERCENTAGE) {
                // Regular expression to validate percentage between 0 and 100 with up to two decimal places
                const percentageRegex = /^(?!$)\d{1,2}(\.\d{1,2})?$|^100(\.0{1,2})?$/;
                const isValid = percentageRegex.test(value);
                return parseInt(value) > 0 ? isValid : false;
            }

            else if (validationType === validationsTypeEnum.EMAIL_ADDRESS) {
                const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                const isValid = emailRegex.test(value);
                return isValid;
            } else if (validationType === validationsTypeEnum.ALPHA_NUMERIC) {
                const alphanumericRegex = /^[a-zA-Z0-9 ]*$/; // Notice the space character added inside the brackets
                return alphanumericRegex.test(value);
            }
            else if (validationType === validationsTypeEnum.REQUIRED) {
                const isValid = typeof value === 'string' ? value.trim() !== '' : !!value;
                return isValid;
            }
            else if (validationType === validationsTypeEnum.NON_NEGATIVE) {
                const isValid = typeof value === 'string' ? value.trim() !== '' && parseFloat(value) > 0 : !!value && value > 0;
                return isValid;
            }
            else if (validationType === validationsTypeEnum.US_PHONE_NUMBER) {
                const isValid = typeof value === 'string' && value.trim().length > 10;
                return isValid;
            }
            else if (validationType === validationsTypeEnum.USER_NAME) {
                const trimmedValue = value.replace(/\s/g, '');
                const foulword = isFoulWord(value);
                //check for foul words
                const isNotFoulWord = !foulword;
                // Required field
                const isNotEmpty = trimmedValue !== '';
                // Minimum length: Username must be at least 6 characters long.
                const isMinLengthValid = trimmedValue.length >= 6;
                // Maximum length: Username cannot exceed 35 characters.
                const isMaxLengthValid = trimmedValue.length <= 35;
                // Invalid characters: Username can only contain letters, numbers, and underscore (_).
                const isValidCharacters = /^[a-zA-Z0-9]*_?[a-zA-Z0-9]*$/.test(value);
                // Missing alphabet: Username must contain at least one letter.
                const hasAlphabet = /[a-zA-Z]/.test(trimmedValue);
                // Repetitive numbers: Username cannot contain more than 5 consecutive identical numbers.
                const hasRepetitiveNumbers = !/(.)\1{5,}/.test(trimmedValue);
                // Does not begin with a whitespace character
                const doesNotBeginWithWhitespace = trimmedValue.charAt(0) !== ' ';
                // Does not end with a whitespace character
                const doesNotEndWithWhitespace = trimmedValue.charAt(trimmedValue.length - 1) !== ' ';

                const isValid =
                    isNotFoulWord &&
                    isNotEmpty &&
                    isMinLengthValid &&
                    isMaxLengthValid &&
                    isValidCharacters &&
                    hasAlphabet &&
                    hasRepetitiveNumbers &&
                    doesNotBeginWithWhitespace &&
                    doesNotEndWithWhitespace;
                return isValid;
            }
            else {
                return true;
            }
        }
        else {
            return true;
        }

    }

    useEffect(() => {
        if (checkIfValid) {
            const isValid = ValidateField(name, defaultValue);
            if (isValid) {
                checknoofValidFields(isValid, name, defaultValue);
                setcheckIfValid(false);
            } else {
                checknoofValidFields(isValid, name, defaultValue);
                setError(!isValid);
                setcheckIfValid(false);
            }
            //checkIfValidRef.current = true;
        }
    }, [checkIfValid])

    useEffect(() => {
        if (cancelFields) {
            setError(false);
        }
    }, [cancelFields])

    const currentInputType = () => {
        if (textFormatType === textFormatTypeEnum.US_PHONE_NUMBER) {
            return usPhoneNumberFormat;
        }
        else if (textFormatType === textFormatTypeEnum.US_CURRENCY) {
            return AppTextInputCurrencyFormat
        }
        else if (textFormatType === textFormatTypeEnum.ONLY_ALPHABETS) {
            return;
        }
        else if (textFormatType === textFormatTypeEnum.ONLY_NUMBERS) {
            return NumericInput;
        }
        else if (textFormatType === textFormatTypeEnum.ZIPCODE) {
            return zipcode;
        }
        else if (textFormatType === textFormatTypeEnum.PERCENTAGE) {
            return AppTextPercentageFormat;
        }
        else if (textFormatType === textFormatTypeEnum.ALPHA_NUMERIC) {
            return AlphanumericInput;
        }
    }

    const currstartAdornment = () => {
        if (textFormatType === textFormatTypeEnum.US_CURRENCY) {
            return <InputAdornment position="start">$</InputAdornment>;
        }
        /* if (textFormatType === textFormatTypeEnum.USER_NAME) {
            return <InputAdornment position="start"><AccountCircle /></InputAdornment>;
        } */
        else {
            return '';
        }
    }

    const currendAdornment = () => {
        if (textFormatType === textFormatTypeEnum.PERCENTAGE) {
            return <InputAdornment position="end">%</InputAdornment>;
        } else if (textFormatType === 'info') {
            return (<InputAdornment position="end">
                <Tooltip title={infoMessage || 'Information'}>
                    <InfoIcon style={{ marginBottom: '40px' }} />
                </Tooltip>
            </InputAdornment>)
        }
        else {
            return '';
        }
    }


    const renderMenuItems = () => {
        const sortedOptions = options.slice().sort((a, b) => {
            if (sortDirection === 'ascending') {
                return a.label - b.label; // Numeric sorting in ascending order
            } else if (sortDirection === 'descending') {
                return b.label - a.label; // Numeric sorting in descending order
            }
            return 0;
        });

        return (
            sortedOptions.map(({ label, value, isdisabled }) => (
                <MenuItem key={value} value={value} disabled={isdisabled}>
                    {label}
                </MenuItem>
            ))
        );
    };


    return (
        <TextField
            textFormatType={textFormatType}
            required={validateField || required}
            disabled={disabled}
            defaultValue={defaultValue}
            variant={variant}
            placeholder={placeholder}
            multiline={multiline}
            rows={rows}
            size={size}
            fullWidth
            label={label}
            onChange={handleChange}
            name={name}
            select={type === InputTypes.SELECT}
            inputProps={
                {
                    tabIndex: tabIndex,
                    maxLength: charactersMaxLength,
                }}
            InputProps={{
                startAdornment: currstartAdornment(),
                endAdornment: currendAdornment(),
                inputComponent: currentInputType()
            }}
            error={iserror}
            helperText={iserror ? validationMessage : ''}
            InputLabelProps={{
                shrink: true,
                focused: true,
            }}
        >
            {type === InputTypes?.SELECT && renderMenuItems()}
        </TextField>


    );
};


AppTextInput.propTypes = {
    type: propTypes.string,
    textFormatType: propTypes.oneOf(['usPhoneNumberFormat', 'usCurrencyFormat', 'text', 'onlyAlphabets', 'zipcode', 'percentage', 'alphaNumeric']),
    onChange: propTypes.func.isRequired,
    variant: propTypes.oneOf(['standard', 'filled', 'outlined']),
    required: propTypes.bool,
    multiline: propTypes.bool,
    defaultValue: propTypes.string,
    placeholder: propTypes.string,
    rows: propTypes.number,
    label: propTypes.string,
    disabled: propTypes.bool,
    options: propTypes.array,
    name: propTypes.string,
    size: propTypes.string,
    tabIndex: propTypes.number,
    charactersMaxLength: propTypes.number,
    charactersMinLength: propTypes.number,
    dataType: propTypes.string,
};

AppTextInput.defaultProps = {
    type: 'text',
    textFormatType: 'text',
    variant: 'standard',
    size: 'small',
    required: false,
    multiline: false,
    defaultValue: '',
    placeholder: '',
    rows: 4,
    label: '',
    options: [],
    disabled: false,
    name: '',
    tabIndex: 0,
    charactersMaxLength: 100, //set based on your preference
    charactersMinLength: 100,
    dataType: 'string'
};

export default AppTextInput;