import React, { useState,useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import { useSelector } from 'react-redux';
import BoxView from './BoxView';
import "../Fav.css";
import secureLocalStorage from 'react-secure-storage';
import CircleIcon from '../../chatbot/Icon';
import { getrootBackgroundColorConfig } from '../../../state/slices/colorConfigSlice';
const Favouritepage = () => {
  const navigate = useNavigate();
  const [compareDetails, setCompareDetails] = useState([]);
  const [Funddetails, setFunddetails] = useState([]);
  const [selectedItems, setselectedItems] = useState(localStorage.getItem('selectedItems'));
  const [items, setItems] = useState();
  useEffect(() => {
    const selectedItems = localStorage.getItem('selectedItems');
    const CompareItems = localStorage.getItem('CompareItems');
    if (selectedItems && CompareItems !== 'undefined') {
      const selectedIds = JSON.parse(selectedItems);
      const compareItemsArray = JSON.parse(CompareItems);
      const selectedDetails = compareItemsArray.filter((item) => selectedIds.includes(item.fundID));
      setCompareDetails(selectedDetails);
      setFunddetails(compareItemsArray);
    }
  }, []);
  const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);
  const [favouritefunds, setfavouritefunds] = useState();
  const InvestorID = secureLocalStorage.getItem("userId");
 
  const FavFunds = favouritefunds
    ? favouritefunds
        .filter(favourite => favourite.favourite === true) 
        
    : [];
  
    
  const uniqueFavFunds = [...new Set(FavFunds)];
  useEffect(() => {
  }, [Funddetails]);
  

  const handleViewDetail = (itemId, event) => {
    if (event) {
      event.stopPropagation();
    }
    navigate('/fund-details', { state: { FundId: { itemId } } });
    window.scrollTo(0, 0);
  };

  const handlesubscribe = (event) => {
    if (event) {
      event.stopPropagation();
    }
    navigate('/subscribe-list');
    window.scrollTo(0, 0);
  };
  const [showPopup, setShowPopup] = useState(false);
  const [removeItemId, setRemoveItemId] = useState(null); 

  const handleremoveitems = (itemId) => {
    setShowPopup(true);
    setRemoveItemId(itemId);
  }
    
  const addbutton = (event) => {
    if (event) {
      event.stopPropagation();
    }
    navigate('/marketplace');
    window.scrollTo(0, 0);
    localStorage.setItem('CompareItems-comparepage', JSON.stringify(Funddetails));
  };
  
  const handleFavFund = (itemId) => {
    console.log('Favorite button clicked for item:', itemId);
    
    setItems((prevItems) => {
      const updatedItems = prevItems?.map((item) => {
        if (item.fundID === itemId) {
          return { ...item, favourite: !item.favourite }; 
        }
        return item;
      });
      return updatedItems;
    });
  };

  
  return (
    <div style={{backgroundColor: rootBackColorConfig.color}} className='mt2-page'>
   <b  className="heading-Favourite">Favourite Funds</b>
      <div style={{backgroundColor: rootBackColorConfig.color}}className="fundStatusWrapper">
       
        {uniqueFavFunds.length > 0 ? (
          <BoxView FundsDetails={uniqueFavFunds} setFundsDetails={setFunddetails} />
        ) : (
          <div></div>
        )}
       <div className="addToCompare3">
          <div className='bg3-cp' onClick={() => addbutton()}>
            <div className="iconMaterialAddCircleOutli-cp">
              <img
                alt=""
                src="MarketPlaceImages/icon-materialaddcircleoutline.svg"
              />
            </div>
            <div className='bg4-cp' />
          </div>
        </div>      
      <CircleIcon/>
    </div>

    </div>
  )
}

export default Favouritepage;
