import React, { useEffect, useState } from 'react'
import { HeaderTemplates, PageHeader } from '../../../common/PageHeader/PageHeader'
import TabsComponment from '../../../common/Tabs/TabsComponment'
import { useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import secureLocalStorage from 'react-secure-storage';
import { updateProfileIcon, updateUserData } from '../../../../state/slices/userDetailsSlice';
import { getInvestorProfileDetails } from '../../services/services';
import FirmPersonalInformation from './FirmPersonalInformation';
import FirmDetails from './FirmDetails';
import InfoIcon from '@mui/icons-material/Info';
import { ToastContainer } from 'react-toastify';
import { getFirmDetails } from '../../../user-profile/FirmUserProfile/services/firmdetaailsservices';

const FirmUserProfileMain = () => {

  //#region variables
  const dispatch = useDispatch();
  const [selectedTab,setSelectedTab] = useState(0);
  const [firmUserDetails,setFirmUserDetails] = useState();
  const [firmDetails, setFirmDetails] = useState(null);

  //#region api get calls
  const getFirmUserInfo = async () => {
    const emailId = secureLocalStorage.getItem("userEmail");
    const data = await getInvestorProfileDetails(emailId)
    if (data.responseCode === 200) {
      setFirmUserDetails(data.responseData)

        const fullName = `${data.responseData.personalInformation.firstName} ${data.responseData.personalInformation.lastName}`;
        dispatch(updateUserData({ field: "firstName", value: data.responseData.personalInformation.firstName }));
        dispatch(updateUserData({ field: "lastName", value: data.responseData.personalInformation.lastName }));
        dispatch(updateUserData({ field: "fullName", value: fullName }));
        secureLocalStorage.setItem("fullName", fullName)

        dispatch(updateProfileIcon({ value: data.responseData?.personalInformation?.profileIcon }))
    } else {
      // Handle error if needed
    }
  }

  const getTenantDetails = async () => {
    const data = await getFirmDetails();
    if (data?.responseCode == 200) {
      setFirmDetails(data?.responseData);
    } else {
      setFirmDetails({});
    }
  }

   //#region useEffect
   useEffect(()=>{
    getFirmUserInfo();
    getTenantDetails();
   },[])

  //#region tabs
  const tabs = [
    {
        key : 0,
        icon : <PersonOutlineOutlinedIcon />,
        label : "EMPLOYEE INFORMATION",
        component : <FirmPersonalInformation firmUserDetails={firmUserDetails} getFirmUserInfo={getFirmUserInfo} />
    },
    {
      key : 1,
      icon : <InfoIcon />,
      label : "FIRM INFORMATION",
      component : <FirmDetails firmDetails={firmDetails} getTenantDetails={getTenantDetails} />
    },
  ]

  return (
    <div>
        <PageHeader
            title={`Manage Profile : ${secureLocalStorage.getItem("fullName")}`}
            template={HeaderTemplates.NORMAL_VIEW}
            hideBackButton={true}/>
        <div className="wrapper3">
            {tabs && 
                <Grid xs={12} >
                    <TabsComponment tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                </Grid>}
        </div>
        <ToastContainer />
    </div>
  )
}

export default FirmUserProfileMain