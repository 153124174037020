import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
const UserSelection = () => {

  const navigate = useNavigate();
  const [userTypes, setUserTypes] = useState([]);

  useEffect(() => {
    
  }, [])
  const screenClick = (data) => {
   
  };


  return (
    // Onboarding Question Flow
    <div className="onboarding">
      <div className="container col-md-6 col-lg-4 me-auto">
        <div className="progress-holder w-75 m-auto">
        </div>
        <div className="ob-qus-holder mt-4">
          <div className="ob-qus">
            Please select the type of account you would like to register for.
          </div>
          {userTypes?.map((data, index1) => {
            return <div key={index1} className="ob-qus-opts-holder my-3" onClick={e => screenClick(data)}>
              <div className="ob-qus-opt">{data.roleName}</div>
              <div className="ob-qus-opt-desc">
                {data.description}
              </div>
            </div>
          })}
          <div className="what-are-alternatives d-flex justify-content-center">
          <InfoOutlinedIcon/> What are alternatives?
           
          </div>

        </div>
       
      </div>
    </div>

  );
};

export default UserSelection;