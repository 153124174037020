import React, { useEffect, useState } from 'react'
import { DataGridPro } from '@mui/x-data-grid-pro';
import CurrencyIndicator from '../../DataGrid/CurrencyIndicator';
import PercentageIndicator from '../../DataGrid/PercentageIndicator';

const MonthlyTotalReturns = (props) => {
    const {className,monthlyReturnsData,indicator} = props;

    const columns = [
        { field: 'year', headerName: `${className}`, width: 90 },
        { field: 'Jan', headerName: 'Jan', width: 70,renderCell: (params) => {
            return (indicator === 'percentage' ? <PercentageIndicator params={params}/> : <CurrencyIndicator params={params}/>);
        }, },
        { field: 'Feb', headerName: 'Feb', width: 70,renderCell: (params) => {
            return (indicator === 'percentage' ? <PercentageIndicator params={params}/> : <CurrencyIndicator params={params}/>);
        }, },
        { field: 'Mar', headerName: 'Mar', width: 70,renderCell: (params) => {
            return (indicator === 'percentage' ? <PercentageIndicator params={params}/> : <CurrencyIndicator params={params}/>);
        }, },
        { field: 'Apr', headerName: 'Apr', width: 70 ,renderCell: (params) => {
            return (indicator === 'percentage' ? <PercentageIndicator params={params}/> : <CurrencyIndicator params={params}/>);
        },},
        { field: 'May', headerName: 'May', width: 70,renderCell: (params) => {
            return (indicator === 'percentage' ? <PercentageIndicator params={params}/> : <CurrencyIndicator params={params}/>);
        }, },
        { field: 'Jun', headerName: 'Jun', width: 70,renderCell: (params) => {
            return (indicator === 'percentage' ? <PercentageIndicator params={params}/> : <CurrencyIndicator params={params}/>);
        }, },
        { field: 'Jul', headerName: 'Jul', width: 70 ,renderCell: (params) => {
            return (indicator === 'percentage' ? <PercentageIndicator params={params}/> : <CurrencyIndicator params={params}/>);
        },},
        { field: 'Aug', headerName: 'Aug', width: 70,renderCell: (params) => {
            return (indicator === 'percentage' ? <PercentageIndicator params={params}/> : <CurrencyIndicator params={params}/>);
        }, },
        { field: 'Sep', headerName: 'Sep', width: 70,renderCell: (params) => {
            return (indicator === 'percentage' ? <PercentageIndicator params={params}/> : <CurrencyIndicator params={params}/>);
        }, },
        { field: 'Oct', headerName: 'Oct', width: 70,renderCell: (params) => {
            return (indicator === 'percentage' ? <PercentageIndicator params={params}/> : <CurrencyIndicator params={params}/>);
        }, },
        { field: 'Nov', headerName: 'Nov', width: 70,renderCell: (params) => {
            return (indicator === 'percentage' ? <PercentageIndicator params={params}/> : <CurrencyIndicator params={params}/>);
        }, },
        { field: 'Dec', headerName: 'Dec', width: 70,renderCell: (params) => {
            return (indicator === 'percentage' ? <PercentageIndicator params={params}/> : <CurrencyIndicator params={params}/>);
        }, },
        { field: 'YTD', headerName: 'YTD', width: 100,renderCell: (params) => {
            return (indicator === 'percentage' ? <PercentageIndicator params={params}/> : <CurrencyIndicator params={params}/>);
        }, },

      ];

      return (
        <div>
            <DataGridPro 
              columns={columns}
              rowHeight={39}
              rows={monthlyReturnsData || []}
              hideFooter/>
        </div>
      )
}

export default MonthlyTotalReturns