import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const ROCKET_URL = "https://jsonplaceholder.typicode.com/";

export const fetchAuth = createAsyncThunk("auth/fetchAuth", async () => {
  const response = await axios.get(ROCKET_URL + "users/1");
  return response.data;
});

const initialState = {
  auth: [],
  status: "idle",
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuth.pending, (state) => {
        console.log("Pending");
        state.status = "loading";
      })
      .addCase(fetchAuth.fulfilled, (state, action) => {
        console.log("Fetched Successfully!", action.payload);
        if (action.payload) {
          state.auth = action.payload;
          state.status = "succeeded";
          setUserSession(action.payload.phone, action.payload.name);
        }
      })
      .addCase(fetchAuth.rejected, (state, action) => {
        state.status = "failed";
        console.log("Rejected!");
        state.error = action.error.message;
      });
  },
});

export const getAuth = (state) => state.auth;
export const getAuthStatus = (state) => state.status;
export const getAuthError = (state) => state.error;
export const getToken = (state) => {
  return sessionStorage.getItem("token") || null;
};
export const getUser = (state) => {
  const userStr = sessionStorage.getItem("user");
  if (userStr)
    //return JSON.parse(userStr);
    return { name: "Vinodh" };
  else return null;
};
export const removeUserSession = () => {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("user");
};
export const setUserSession = (token, user) => {
  sessionStorage.setItem("token", token);
  sessionStorage.setItem("user", JSON.stringify(user));
};

export default authSlice.reducer;
