import axiosApiCall from "../../Services/axiosApiCall";
import { getAPIResponse } from "../../../utils/common";
import secureLocalStorage from "react-secure-storage";

export async function getFundDistributions(fundID,DistributionID) {
    return await axiosApiCall.get(`/v1/Servicing/GetFundDistributions?fundID=${fundID}&DistributionID=${DistributionID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getFundDistributionsbyID(fundID,DistributionID,tierID) {
    return await axiosApiCall.get(`/v1/Servicing/GetDistributionByID?fundID=${fundID}&DistributionID=${DistributionID}&distributionTierID=${tierID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function createNewDistributionTier(data) {
    return await axiosApiCall.post(`/v1/Servicing/CreateFundDistribution`, data)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function updateDistributionTierDetails(data) {
    return await axiosApiCall.put(`/v1/Servicing/UpdateFundDistribution`, data)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function deleteDistribution(fundID,distributionID) {
    return await axiosApiCall.delete(`/v1/Servicing/DeleteDistribution?fundID=${fundID}&distributionID=${distributionID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getFundDistributionsStatus(fundID) {
    return await axiosApiCall.get(`/v1/Servicing/GetStatusToDistributeFund?fundID=${fundID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}