import React from 'react';
import './marketacademypage.css';

function Privatemarketacademypage() {
  return (
    <div className="construction-page">
      <img
        // src="https://static.wixstatic.com/media/ea6ac8_b5e51c26b6794808af377f8218153abe~mv2.jpg"
        src="assets/img/Page-Not-Found.jpg"
        alt="Under Construction"
        className="construction-image"
      />
      
      {/* <h1>Under Construction</h1>
      <p>We're working on something awesome. Please check back later!</p> */}
    </div>
   
  );
}

export default Privatemarketacademypage;
