import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import resolve from '../../common/resolve';
import axiosApiCall from '../../Services/axiosApiCall';
import { getAPIResponse } from '../../../utils/common';

export async function getKYCAMLDashboardData() {
    return await axiosApiCall.put(`/v1/User/GetKYCAMLDashboardData`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getKycAmlDetails() {
    return await axiosApiCall.get(`/v1/User/GetKYCAMLDetails`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getKycAmlDetailsbyFundID(fundID) {
    return await axiosApiCall.get(`/v1/User/GetKYCAMLDetails?fundID=${fundID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getkyccomments(investorID) {
    return await axiosApiCall.get(`/v1/User/GetKYCAMLComments?investorID=${investorID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getkycdropdownvalues() {
    return await axiosApiCall.get(`/v1/MasterData/GetListItemsByType?listType=KYCStatus`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getamldropdownvalues() {
    return await axiosApiCall.get(`/v1/MasterData/GetListItemsByType?listType=AMLStatus`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}


export async function postkycComment(formData) {
    return await axiosApiCall.post(`/v1/User/AddKYCAMLComments`, formData)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function updatekycamlstatus(data) {
    return await axiosApiCall.put(`/v1/User/VerifyRejectKYCAMLDetails`, data)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function updatekycstatus(data,status) {
    return await axiosApiCall.put(`/v1/User/UpdateKYCStatus?kycStatus=${status}&userID=${data.id}`, data)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function updateamlstatus(data,status) {
    return await axiosApiCall.put(`/v1/User/UpdateAMLStatus?amlStatus=${status}&userID=${data.id}`, data)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getInvestorDetailsbyUserID(id) {
    return await axiosApiCall.get(`/v1/User/GetUserDetailsByUserID?userID=${id}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}
