import React from 'react'
import propTypes from 'prop-types';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, ListItemText, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material';
import { FormLabel } from 'react-bootstrap';

const RadioGroupField = (props) => {

  //#region props
  const {
    name,
    label,
    value,
    onChange,
    options,
    isBorder,
    onBlur,
    required,
    readOnly,
    error,
    errorMessage,
    hasChildQuestions,
    renderSelectedQuestion,
    disabled
  } = props;

  //#region change events
  const handleChange = (ev) => {
    const {name,value} = ev.target;
    onChange(name,value)
  }

  return (
    <FormControl>
      <FormLabel error={error} required={required}>{label}</FormLabel>
      <RadioGroup
        row
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        sx={{display: 'block !important'}}
        onError={error}>
          {
            isBorder ? 
            <div className='child-margin-10'>
                {
                    options?.length > 0 ?
                    options.map((item)=>{
                        return(
                          <div>
                            <div className='display-border-row'>
                                  <FormControlLabel 
                                     value={item.value} 
                                     control={<Radio />} 
                                     label={item.label} 
                                     disabled={disabled || readOnly} />
                            </div>
                            {
                              hasChildQuestions && 
                              <div className='margin-left-15 margin-top-minus-10'>
                                {renderSelectedQuestion(item?.childQuestionID,value !== item.value)}
                              </div>
                            }
                          </div>
                        )
                    }): ''
                }
            </div> : 
            options?.length > 0 ?
              options.map((item)=>{
                  return(
                      <FormControlLabel value={item.value} control={<Radio />} label={item.label} disabled={readOnly} />
                  )
              }): ''
          }
      </RadioGroup>
      <FormHelperText error={error}>{errorMessage}</FormHelperText>
    </FormControl>
  )
}

RadioGroupField.propTypes = {
    type: propTypes.string,
    onChange: propTypes.func.isRequired,
    variant: propTypes.oneOf(['standard', 'filled', 'outlined']),
    required: propTypes.bool,
    value: propTypes.string,
    placeholder: propTypes.string,
    label: propTypes.string,
    disabled: propTypes.bool,
    options: propTypes.array,
    name: propTypes.string,
    size: propTypes.string,
    errorMessage: propTypes.string,
    isBorder: propTypes.bool
};

RadioGroupField.defaultProps = {
    type: 'select',
    variant: 'standard',
    size: 'small',
    required: false,
    value: '',
    placeholder: '',
    label: '',
    options: [],
    disabled: false,
    isBorder: false,
    name: '',
    errorMessage: ''
};

export default RadioGroupField;