import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { maxWidth } from '@mui/system';
import propTypes from 'prop-types';
import { validationsTypeEnum } from '../TextInput/appInputenum';
import '../TextInput/styles.css';
import { FormHelperText } from '@mui/material';

const MultiSelectDropdown = (props) => {

  const {label,
    name,
    onChange,
    required,
    editable,
    options,
    multiline,
    variant,
    defaultValue,
    errormessage,
    validateField,
    validationType,
    validationMessage,
    checkIfValid,
    setcheckIfValid,
    checknoofValidFields,
    cancelFields} = props;

  const allindividualOption = [
        { label: 'All', value: 'all' },];

  const initialallOptions = [
    { label: 'Option 1', value: 1 },
    { label: 'Option 2', value: 2 },
    { label: 'Option 3', value: 3 },
  ];

  //TODO : Currently all allOptions is not required

  const allOptions = Array.isArray(options) ? [...options] : [];

  const [selectedOptions, setSelectedOptions] = useState(defaultValue || []);

  const [iserror, setError] = useState(false);

  const handleChange = (ev) => {
    const { name,value } = ev.target;
    const isValid = ValidateField(name, value);
    setError(!isValid);
    setSelectedOptions(value);
    onChange(name,value);
  };

  const ValidateField = (name, value) => {
    if (validateField) {
       if (validationType === validationsTypeEnum.REQUIRED) {
          const isValid = value.length > 0 || defaultValue.length > 0;
          return isValid;
       }                      
        else {
            return true;
        }
    }
    else {
        return true;
    }

}

useEffect(() => {
    if(checkIfValid) {
        const isValid = ValidateField(name, selectedOptions);  
        if(isValid) {
                checknoofValidFields(isValid,name,defaultValue);   
                setcheckIfValid(false);
        } else {
            checknoofValidFields(isValid,name,defaultValue);   
            setError(!isValid); 
            setcheckIfValid(false);
        }
        //checkIfValidRef.current = true;
    }
},[checkIfValid])

useEffect(() => {
    if(cancelFields) {
        setError(false);
    }
},[cancelFields])
  
  const renderMultipleallOptions = () => {
    return allOptions?.map((option) => (
        <MenuItem key={option.value} value={option.value}>
        <label style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type="checkbox"
            checked={selectedOptions.includes(option.value) || defaultValue?.includes(option.value)}
            readOnly
          />
          <span style={{ marginLeft: '8px',marginTop: '1.5px' }}>{option.label}</span>
        </label>
      </MenuItem>
    ));
  };

  return (
    <FormControl fullWidth variant="standard" error={iserror} required={validateField}> 
      <InputLabel htmlFor="select-multiple" 
                  focused={true} 
                  shrink={true}>
          {label}
      </InputLabel>
      <Select
        multiple
        required={true}
        value={defaultValue}
        onChange={handleChange}
        defaultValue={[86]}
        renderValue={(selected) => allOptions.filter((item) => selected.includes(item.value)).map(item => item.label).join(', ')}
        label={label}
        fullWidth
        multiline={multiline}
        variant={multiline ? 'outlined' : variant}
        inputProps={{
          id: 'select-multiple',
        }}
        name={name}
        style={{
          border: 'none',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          borderRadius: 0,
          minWidth: '100%',
          maxWidth: '100%'
        }}
        disabled={!editable}
      >
        {renderMultipleallOptions()}
      </Select>
      {iserror && <FormHelperText>{validationMessage}</FormHelperText>}
    </FormControl>
  );
};

MultiSelectDropdown.propTypes = {
    onChange: propTypes.func.isRequired,
    variant: propTypes.oneOf(['standard', 'filled', 'outlined']),
    required: propTypes.bool,
    multiline: propTypes.bool,
    defaultValue: propTypes.string,
    label: propTypes.string,
    options: propTypes.array,
    name: propTypes.string,
    editable: propTypes.bool,
    errormessage: propTypes.string,
}

MultiSelectDropdown.defaultProps = {
    variant: 'standard',
    multiline: false,
    required: false,
    defaultValue: '',
    label: '',
    options: [],
    name: '',
    symbol: 'text',
    editable: true,
    errormessage: '',
};

export default MultiSelectDropdown;