import React, { useEffect, useState } from 'react'
import AIPDataGrid from '../../common/DataGrid/AIPDataGrid';
import LinearProgress from '@mui/material/LinearProgress';
import { Grid } from '@mui/material';
import { auditingUserData } from '../auditingdata';
import { getInvestorDetailsbyUserID } from '../../KYCVerification/services/services';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { GridToolbarContainer,GridToolbarExport,GridToolbarQuickFilter  } from '@mui/x-data-grid-pro';


const AudtingGrid = (props) => {

  //#region props 
  const {type,columns,rows, fileName} = props; 

  //#region return
  return (
    <div className='whiteCard auditgrid'>
        {
            columns && rows ?
                <DataGridPro className='audit-personalInfo'
                    columns={columns}
                    rows={rows}
                    components={{
                      Toolbar: CustomToolbar,
                    }}
                    hideFooter />
                :
                <Grid xs={12} className='whiteCard'>
                    No Data
                </Grid>
        }
    </div>
  )
}


const CustomToolbar = () => {
    return (
        <GridToolbarContainer>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <div></div>
          <div style={{ display: 'flex' }}>
            <GridToolbarQuickFilter />
            <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
          </div>
        </div>
      </GridToolbarContainer>
    );
  };

export default AudtingGrid