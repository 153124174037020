export const CurrencyFormatter = (amount) => {

    // if (amount === null) {
    //     amount = 0;
    // }

    if (isNaN(amount)) {
        amount = 0;
    }

    return Intl.NumberFormat('en-IN', { style: 'currency', currency: 'USD' }).format(amount);

}

// const CurrencyInput = ({ value, onChange }) => {
//     // Remove currency symbol and commas to get the raw number value
//     const rawValue = value.replace(/[$,]/g, "");
  
//     // Format the raw value with currency symbol and commas
//     const formattedValue = new Intl.NumberFormat("en-US", {
//       style: "currency",
//       currency: "USD",
//     }).format(rawValue);
  
//     const handleChange = (e) => {
//       const newValue = e.target.value;
  
//       // Remove currency symbol and commas from the input
//       const numericValue = newValue.replace(/[$,]/g, "");
  
//       // Update the component state with the numeric value
//       onChange(numericValue);
//     };
  
//     return (
//       <input
//         type="text"
//         value={formattedValue}
//         onChange={handleChange}
//         placeholder="$0.00"
//       />
//     );
//   };
//   export { CurrencyFormatter };
//   export default CurrencyInput;
  