import { Dialog, DialogTitle, DialogActions, Button, DialogContent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { articleTypesEnum } from '../apiDataTemplate';
import ActionButton from '../../common/ActionButton/ActionButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Text from '../../common/Text/Text';

const ValidationPopup = (props) => {

    //#region props
    const { open, onAccept, onClose, data } = props;

    //#region variables
    const file = data?.articleUploadFileName;
    const [fileDataUrl, setFileDataUrl] = useState();


    //#region functions
    function isBlobInstance(file) {
        return file instanceof Blob;
      }
    

    //#region click events
    const handleClick = (articleURl) => {

        const url = articleURl.startsWith('http://') || articleURl.startsWith('https://')
            ? articleURl
            : `http://${articleURl}`;

        const screenWidth = window.screen.availWidth;
        const screenHeight = window.screen.availHeight;

        const windowWidth = 1000;
        const windowHeight = 600;

        const left = (screenWidth - windowWidth) / 2;
        const top = (screenHeight - windowHeight) / 2;

        const newWindow = window.open(
            url,
            "PopupWindow",
            `width=${windowWidth},height=${windowHeight},left=${left},top=${top}`
        );

        if (newWindow) {
            newWindow.opener = null;
        }

    }

    const handleDownloadClick = () => {
        fetch(data.articleUploadFileName)
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.blob();
          })
          .then((blob) => {
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${data.articleTitle}.pdf`; 
            link.click();
            URL.revokeObjectURL(url);
          })
          .catch((error) => {
            console.log('Error fetching data:', error);
          }); 
    }

    const dialogPaperStyles = {
        width: '500px',
    };

    //#region useeffect
    useEffect(() => {
        const file = data.articleUploadFileName;

        if (file && typeof file !== "string") {
            // Read the file
            const reader = new FileReader();
            reader.onload = function (event) {
                // Create a data URL from the file content
                const fileData = event.target.result;
                setFileDataUrl(fileData);
            };
            reader.readAsDataURL(file);
        } else if (typeof file === "string") {
            // If file is already a URL or path
            setFileDataUrl(file);
        }
    }, [data.articleUploadFileName]);

    return (
        <>
            {
                data?.type === articleTypesEnum.ADD_LINK ?
                    <Dialog
                        open={open}
                        onClose={onClose}
                        PaperProps={{ style: dialogPaperStyles }}
                    >
                        <div>
                            <DialogTitle id="alert-dialog-title" sx={{ marginLeft: '5px' }}> <Text  label='Validation Check'/> </DialogTitle>

                            <DialogContent sx={{ marginTop: '14px', marginLeft: '20px' }}>
                                {
                                    data?.type === articleTypesEnum.ADD_LINK ?
                                        <Button onClick={() => handleClick(data.articleLinkName)}>Open URL Here</Button> :
                                        null
                                }
                            </DialogContent>

                            <DialogActions sx={{ padding: '8px 20px !important' }}>

                                <ActionButton
                                    component="label"
                                    label={"Cancel"}
                                    variant="outlined"
                                    icon={<CancelIcon/>}
                                    style={{ backgroundColor: 'white', color: 'gray' }}
                                    onClick={onClose} autoFocus>
                                </ActionButton>
                                <ActionButton
                                    component="label"
                                    variant="contained"
                                    label={"Accept"}
                                    icon={<CheckCircleIcon/>}
                                    onClick={() => onAccept()} autoFocus>
                                </ActionButton>
                            </DialogActions>
                        </div>
                    </Dialog> :

                    data?.type === articleTypesEnum.UPLOAD_FILE ?
                        <Dialog
                            open={open}
                            onClose={onClose}
                            fullWidth={true}
                            PaperProps={{ style: isBlobInstance(data.articleUploadFileName) ? {} : dialogPaperStyles }}
                            maxWidth="md" >
                            <div>
                                <DialogTitle id="alert-dialog-title"><Text  label='Validation Check'/></DialogTitle>
                                <DialogContent>
                                    <DialogContent>
                                        {
                                            fileDataUrl &&
                                            
                                            isBlobInstance(data.articleUploadFileName) ? (
                                                    <iframe
                                                        id="iframeId"
                                                        src={fileDataUrl}
                                                        className="custom-iframe"
                                                        style={{
                                                            height: '380px',
                                                        }}
                                                    ></iframe>
                                                ) : <Button onClick={() => handleDownloadClick()}>Download File</Button>
                                            
                                        }
                                    </DialogContent>

                                </DialogContent>
                                <DialogActions>
                                    <ActionButton
                                        component="label"
                                        variant="outlined"
                                        label={"Cancel"}
                                        icon={<CancelIcon/>}
                                        style={{ backgroundColor: 'white', color: 'gray' }}
                                        onClick={onClose} autoFocus>
                                    </ActionButton>
                                    <ActionButton
                                        component="label"
                                        label={"Accept"}
                                        variant="contained"
                                        icon={<CheckCircleIcon/>}
                                        onClick={() => onAccept()} autoFocus>
                                    </ActionButton>
                                </DialogActions>
                            </div>
                        </Dialog> : null
            }
            <ToastContainer />
        </>

    );
}

export default ValidationPopup;
