import React from "react";
import { Box } from "@mui/system";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { useSelector } from "react-redux";
import { getrootBackgroundColorConfig } from "../../../state/slices/colorConfigSlice";
import StackView from "../../common/StackView/StackView";
import { CurrencyFormatter } from "../../../utils/Formater";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import FirstComponent from "../../common/DatePicker/DatePicker";
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import { getSubscribersList, ManualSubscriptionErrorMessages } from "../Services/ComplianceOfficerService";


const Manualsubscription = () => {

  //#region variables
  const navigate = useNavigate();
  const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);
  const [allinvestors, setAllInvestors] = useState();


  //#region service calls 
  const getInvestorsList = async () => {
    const data = await getSubscribersList(0)
    if (data.responseCode === 200) {
      setAllInvestors(data.responseData.filter(u => u.isOfflineSubscription === true))
    } else if (data.responseCode === 404) {
      toast.error(ManualSubscriptionErrorMessages.DATA_NOT_FOUND,
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    } else {
      toast.error(ManualSubscriptionErrorMessages.UNABLE_TO_LOAD,
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
  }

  //# region navigations
  const onCustomerClickstatus = (params) => {
    navigate("/offline-subscription-info", { state: { selectedRow: params.row } });
  };

  // const customCellRenderer = (params) => (
  //   <div style={{ display: "flex", alignItems: "center" }}>
  //     <div style={{
  //       width: 28,
  //       height: 28,
  //       borderRadius: "50%",
  //       background: "lightgray",
  //       display: "flex",
  //       alignItems: "center",
  //       justifyContent: "center",
  //       marginRight: 8,
  //     }}>
  //       <img
  //         src="/icons/icon-awesomebuilding.svg"
  //         alt="Image"
  //         style={{ width: 12 }}
  //       />
  //     </div>
  //     {params.value}
  //   </div>
  // );

  //#region grid columns
  const NewInvestorsColumns = [
    {
      "id": 1,
      "field": "investor",
      "headerName": "Name",
      "width": 450,
      // renderCell: customCellRenderer,
    },
    // {
    //     "id": 2,
    //     "field": "investorType",
    //     "headerName": "Type",
    //     "width": 200
    // },
    {
      "id": 2,
      "field": "fundName",
      "headerName": "Fund Name",
      "width": 400
    },
    {
      "id": 3,
      "field": "dateOfAppliaction",
      "headerName": "Date Of Application",
      "width": 150
    },
    // {
    //     "id": 4,
    //     "field": "subscribed",
    //     "headerName": "Amount",
    //     "width": 150
    // },
    // {
    //     "id": 5,
    //     "field": "subscriptionStatus",
    //     "headerName": "Subscription Status",
    //     "width": 180
    // }
  ]

  useEffect(() => {
    getInvestorsList(0)
  }, []);

  const AllInvestorsRowsData = allinvestors
    ? allinvestors
      .filter((fund) => fund.isOfflineSubscription === true && fund.subscriptionStatus === "Awaiting Online Processing")
      .map((fund) => ({
        id: fund.subscriptionsID,
        investor: fund.investor,
        investorType: fund.investorType ?? "Individual Investor",
        fundName: fund.fundName,
        dateOfAppliaction: moment(fund.updatedDate).format("MM-DD-YYYY"),
        subscribed: CurrencyFormatter(fund.subscribed),
        subscriptionStatus: fund.subscriptionStatus,
        subscriptionID: fund.subscriptionsID,
        fundID: fund.fundID,
        investorID: fund.investorID,
        isOfflineSubscription: fund.isOfflineSubscription,
        offlineFile: fund.offlineFile,
        offlineFilePath: fund.offlineFilePath,
        fundImage: fund.fundImage,
      }))
    : [];

  return (
    <div style={{ backgroundColor: rootBackColorConfig.color, width: '100%' }}>
      <div>
        <Box overflowx={'auto'}>
          <h4>Offline Subscriptions</h4>
          <Grid container marginTop={'10px'}>
            <Grid item xs={12} marginBottom={2}>
              <div className="whiteCard">
                <StackView
                  columns={NewInvestorsColumns}
                  rows={AllInvestorsRowsData}
                  handleRowClick={onCustomerClickstatus}
                  onRowsSelectionHandler={() => { }}
                />
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  )



};
export default Manualsubscription;