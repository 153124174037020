import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from "@mui/material";
import { BoxAlignCenter } from '../../CommonCss';
import InfoIcon from '@mui/icons-material/Info';
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props) {
  const [value, setValue] = React.useState(props.currtab);

  const handleChange = (event, newValue) => {
    if(props.isSaveEnabled === true){
      alert("Please Save the Data ")
    }
    else{
      props.setCurrTabindex(newValue);
    }
    
  };

  return (
    <Grid item xs={12} >
       <Box sx={{
            border: 1,
            borderColor: 'divider',
            backgroundColor: '#d7e2ef',
            boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16) inset',
            borderRadius: '100px',
            padding: '1px',
            width: 'auto', // Set the width to 100%
            margin: '0px 0px',
            height: '50px'
           
          }}>
          <Tabs 
            value={props.currtab} 
            onChange={handleChange} 
            variant="scrollable"  
            scrollButtons="auto"  
            aria-label="basic tabs example"
            TabIndicatorProps={{ style: { display: 'none' } }}
          >
            {props.tabs.map((tab, index) => (
              <Tab 
              label={
                <div >
                  {tab.MUIIcon ? (
                    // code to render when props.MUIIcon is truthy
                     tab.icon
                  ) : (
                    // code to render when props.MUIIcon is falsy
                    <img src={tab.icon} style={{ marginRight: '15px', marginBottom: '3px', width: '24px', height: '24px' }} />
                  )}
                  
                  <span style={{marginLeft:'5px'}}>{tab.name}</span>
                </div>
              }
              {...a11yProps(index)} 
              key={index} 
              sx={{ 
                "& .MuiTabs-indicator": { 
                  backgroundColor: "#1f61c5", 
                  borderRadius: "22px", 
                  height: "auto",
                  position: "absolute",
                  bottom: 0, 
                  left: 0,
                  transition: "0.3s ease-out", 
                  padding:'9px 16px',
                  cursor: 'pointer',
                  "&.Mui-selected": { 
                    backgroundColor: "#B0C1D2",
                    color: '#fff',
                    padding:'0px 16px', 
                    height: '36px',
                  }
                },
                "& .MuiTabs-indicator:hover" : {
                  backgroundColor: "#fff",
                },
                "&.Mui-selected": {
                  border: 1,
                  borderColor: 'divider',
                  backgroundColor: '#B0C1D2',
                  color: '#0A1A27',
                  borderRadius: '100px',
                  height: "36px",
                  padding:'0px 16px', 
                  cursor: 'pointer', 
                },
                width: `calc(100% / 100% ${props.tabs.length})`,
              }}
              />
            ))}
          </Tabs>
        </Box>

        {props.tabs.map((tab, index) => (
          <CustomTabPanel value={props.currtab} index={index} key={index}>
            {tab.component}
          </CustomTabPanel>
        ))}
      </Grid>

     
  );
}
