import { FunctionComponent, useCallback , useEffect , useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../userprofile13.module.css";

const UserProfilePopUp = ({PopupQuestion, PopupOptions ,onCancel , onOptionSelected ,investment ,setanswer}) => {
  const navigate = useNavigate();

   // Initial step value
  const [QuestionBank, setQuestionBank] = useState([]);
  const handleOptionSelection = (index, option) => {
    
    onOptionSelected(index, option);
    // Close the popup or perform any additional logic
  };

  console.log(investment);
  console.log("test");


  
  // Effect to fetch data and update step

  
  // Effect to log the current state of QuestionBank
  useEffect(() => {
    console.log(QuestionBank);
  }, [QuestionBank]);
  return (
    <div>

      <div className={styles.userProfile13Child2} />
      <div className={styles.PopUp}>
      <div className={styles.bgParent}>
        <div className={styles.bg} />
        <div className={styles.component2121} onClick={onCancel}>
          <div className={styles.groupWrapper}>
            <div className={styles.buttonNormalChild} />
            <div className={styles.save}>SAVE</div>
          </div>
        </div>
        <div className={styles.component2122} onClick={onCancel}>
          <div className={styles.groupWrapper}>
            <div className={styles.buttonNormalItem} />
            <div className={styles.cancel}>Cancel</div>
          </div>
        </div>
        <img
          className={styles.iconMaterialClose}
          alt=""
          src="/UserProfile/icon-materialclose.svg"
          onClick={onCancel}
        />
      </div>
      <div className={styles.userProfile13Inner1}>
        <div className={styles.groupWrapper}>
          <div className={styles.logoParent}>
            <b className={styles.logo}>
            
              <p className={styles.haveYouInvested}>{PopupQuestion.question}</p>
            </b>
            <div className={styles.QuestionOptions}>
              {console.clear()}
              {console.log(PopupOptions)}
  {PopupOptions.map((option, index) => (
    <button className={styles.largeSelectionButton} 
    key={index}
    onClick={() => handleOptionSelection(option.questionBankID, option)}
    >
    
      <div className={styles.onboardingConfirmButtonChild} />
      <div className={styles.yes}>{option.answer}</div>
      <div className={styles.inPrivateEquity}>{option.description}</div>
    </button>
  ))}

  {console.log(onOptionSelected)}
  
</div>
           {/* <button className={styles.largeSelectionButton}>
              <div className={styles.onboardingConfirmButtonChild} />
              <div className={styles.yes}>Yes</div>
              <div className={styles.inPrivateEquity}>
                {PopupQuestion.shortName}
              </div>
            </button>
            <button className={styles.largeSelectionButton1}>
              <div className={styles.onboardingConfirmButtonChild} />
              <div className={styles.yes}>No</div>
              <div className={styles.inPrivateEquity}>
              {PopupQuestion.description}
              </div>
            </button> */}
          </div>
         {/*  <div className={styles.whatAreAlternativesParent}>
            <div className={styles.whatAreAlternatives}>
              What are alternatives?
            </div>
            <img
              className={styles.iconMaterialInfoOutline1}
              alt=""
              src="/UserProfile/icon-materialinfooutline1.svg"
            />
          </div> */}
        </div>
      </div>
      </div>
    </div>
  );
};

export default UserProfilePopUp;
