import secureLocalStorage from "react-secure-storage"

export const newComment = {
    "commentID": 0,
    "userComments": "",
    "commentByUserID": secureLocalStorage.getItem("userId"),
    "commentBy": secureLocalStorage.getItem("fullName"),
    "commentOn": new Date() ,
    "entityID": secureLocalStorage.getItem("userId"),
    "entityTypeID": 3,
    "entityType": "Investor",
    "userIcon": null,
    "commentAttachments": []
  }