import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import propTypes from 'prop-types';


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 15,
    borderRadius: 8,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },  
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1F61C5' : '#308fe8',
    },
  }));

  export default function ProgressBar(props) {
    const { progressValue } = props;
    return (
        <BorderLinearProgress variant="determinate" value={progressValue} className='progressbar' />
    );
  }

  ProgressBar.propTypes={
    progressValue: propTypes.string
  }

  ProgressBar.defaultProps={
    progressValue: '30'
  }