import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Grid } from '@mui/material';
import { useState } from 'react';
import ActionButton from '../../../../common/ActionButton/ActionButton';
import InfoIcon from '@mui/icons-material/Info';

const ConfirmationPopup = (props) => {

    //#region props
    const { open, onClose } = props;

    //#region variables
    const [loading, setLoading] = useState(false);

    //#region click events
    const onOkClick = () => {
        onClose(true);
    }

    const onCancel = () => {
        onClose(false);
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <h6>
                    <InfoIcon className='info-i' />
                    <span>Confirmation</span> </h6>
            </DialogTitle>
            <DialogContent>
                Are you sure you want to discard the changes?
            </DialogContent>
            <DialogActions>
                <div className='space-between'>
                    <div>
                        <ActionButton variant='outlined' onClick={onCancel} label='No' />
                    </div>
                    <div className='margin-left-10'>
                        <ActionButton
                            label='Yes'
                            loading={loading}
                            startIconName=''
                            styleProps={{
                                padding: '8px 30px'
                            }}
                            onClick={onOkClick} />
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    )
}
export default ConfirmationPopup;