import React from 'react'
import DocumentsDasboard from '../../FirmEmployees/Components/DocumentsDasboard'
import { ReportsNames } from '../../FirmEmployees/jsonData'
import FoldersAccordian from '../../FirmEmployees/Components/FoldersAccordian'
import { getRepotingFolders, getRepotingFoldersbyInvestorID } from '../../FirmEmployees/services/services'
import { useEffect, useState } from 'react'
import { getInvetsorRepotingDocuments } from '../../FirmEmployees/services/services'
import { DocumentsGETType } from '../../InvestorReportingEnum'
import { CircularProgress } from '@mui/material'
import ReactLoading from 'react-loading';
import secureLocalStorage from 'react-secure-storage';

const InvestorDocuments = () => {

  const [foldersList, setFoldersList] = useState(null);

  const getFolders = async () => {
    const data = await getRepotingFoldersbyInvestorID(secureLocalStorage.getItem("userId"));
    if (data?.responseCode === 200) {
      //TODO: Check if the parentTypeID is always 86
      const filteredData = data?.responseData?.filter((item) => item.title !== 'Other');

     // Need to add 'item.name' to each filtered item for tab component
      filteredData.forEach((item) => {
        item.name = item.title;
        item.relatedFiles = [];
      });

      const folderNamesWithComponent = filteredData?.map((folder) => {
        return {
          ...folder,
          component: <div className='accordian-wrapper'>
                        <div className="loading-container">
                          <CircularProgress />
                        </div>
                     </div>
        }
      });

       ;
      setFoldersList(folderNamesWithComponent);
    } else { 
      setFoldersList([]);
    }
  }

  useEffect(() => {
    getFolders();
  }, []);

  return (
    <div>
      {
        foldersList ?
        <DocumentsDasboard isfileUpload={false} reports={foldersList} setFoldersList={setFoldersList} documentsFor={DocumentsGETType.INVESTOR}/> : 
        <div>
          <div className="loading-container">
            <ReactLoading type="spin" color="#0000FF" height={100} width={100} />
          </div>
          <div className="alertMessage">
            Please wait...
          </div>
        </div>
      }
    </div>
  )
}

export default InvestorDocuments