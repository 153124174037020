import React, { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextInput from "../../common/TextInput/TextInput";
import ActionButton from "../../common/ActionButton/ActionButton";
import { AddNewRole, getPrimaryRoles } from "../Services/TabsData";
import { ToastContainer, toast } from 'react-toastify';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import CancelIcon from '@mui/icons-material/Cancel';
import ALTTextField from "../../common/input-fields/ALTTextField";
import SelectField from "../../common/input-fields/SelectField";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { validationsConstants } from "../../../utils/AppConstants";
import { LoadingButton } from "@mui/lab";
import AddIcon from '@mui/icons-material/Add';
import { addNewRoleData } from "../apiDataTemplate";

function AddRole(props) {

  //#region props
  const { open, onClose, setRoles, dashboardDropdown, excistedRoles } = props;

  //#region variables
  const [loading, setLoading] = useState(false);
  const [primaryRoles, setPrimaryRoles] = useState([])
  const notPrimaryRole = ['Investor', 'Portfolio Manager', 'Firm User'];

  //#region change events
  const handleChange = (name, value) => {
    if (name === "primaryRoleID") {
      const selectedRole = primaryRoles?.find((item) => item.roleId === value)
      roleData.setFieldValue("applicationDashboardId", selectedRole.applicationDashboardId);
    }
    roleData.setFieldValue(name, value);
  }

  //#region api get calls
  const getPrimaryRoleDropdownValues = async () => {
    const data = await getPrimaryRoles();
    if (data.responseCode === 200) {
      const filteredData = data.responseData?.filter((item) => item.isPrimaryRole === true && !notPrimaryRole.includes(item.roleName))
      setPrimaryRoles(filteredData);
    }
    else {
      //console.log('unable to fetch data);
    }
  }

  //#region api post calls
  const handleSubmit = async () => {
    if (excistedRoles.find(eR => eR.roleName?.toLowerCase()?.trim() === roleData.values.roleName?.toLowerCase()?.trim())) {
      toast.warning("Role already exists",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      setLoading(false);
    }
    else {
      setLoading(true);
      const response = await AddNewRole(roleData.values);
      if (response.responseCode === 200) {
        setLoading(true);
        toast.success("Role created successfully",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        setRoles(response.responseData);
      }
      else {
        toast.error("unable to create new role. please try again later",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        setLoading(false);
      }
      onClose();
    }
  }

  //#region useeffect
  useEffect(() => {
    getPrimaryRoleDropdownValues();
  }, [])

  //#region formik validations
  const addRoleValidationSchema = yup.object().shape({
    roleName: yup
      .string()
      .required(validationsConstants.REQUIRED)
      .matches(/^[A-Za-z\s]+$/, validationsConstants.ONLY_ALPHABETS),
    description: yup
      .string()
      .required(validationsConstants.REQUIRED),
    applicationDashboardId: yup
      .string()
      .required(validationsConstants.REQUIRED),
    primaryRoleID: yup
      .string()
      .required(validationsConstants.REQUIRED),
  });

  const roleData = useFormik({
    initialValues: addNewRoleData,
    validationSchema: addRoleValidationSchema,
    onSubmit: (values) => {
      handleSubmit()
    },
  });

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>
        Add Role
      </DialogTitle>
      <DialogContent>
        <form onSubmit={roleData.handleSubmit} novalidate="novalidate">
          <div className="child-margin-15">
            <div>
              <ALTTextField
                name="roleName"
                label="Role Name"
                value={roleData?.values?.roleName}
                onChange={(name, value) => handleChange(name, value)}
                required={true}
                onBlur={roleData.handleBlur}
                error={roleData.touched.roleName && Boolean(roleData.errors.roleName)}
                errorMessage={roleData.touched.roleName && roleData.errors.roleName} />
            </div>
            <div>
              <SelectField
                name="primaryRoleID"
                label="Primary Role"
                value={roleData?.values?.primaryRoleID}
                onChange={(name, value) => handleChange(name, value)}
                options={primaryRoles?.map(option => ({ label: option.roleName, value: option.roleId }))}
                required={true}
                onBlur={roleData.handleBlur}
                error={roleData.touched.primaryRoleID && Boolean(roleData.errors.primaryRoleID)}
                errorMessage={roleData.touched.primaryRoleID && roleData.errors.primaryRoleID} />
            </div>
            <div>
              <SelectField
                name="applicationDashboardId"
                label="Dashboard"
                value={roleData?.values?.applicationDashboardId}
                onChange={(name, value) => handleChange(name, value)}
                options={dashboardDropdown?.map(option => ({ label: option.title, value: option.applicationDashboardId }))}
                required={true}
                readOnly={true}
                onBlur={roleData.handleBlur}
                error={roleData.touched.applicationDashboardId && Boolean(roleData.errors.applicationDashboardId)}
                errorMessage={roleData.touched.applicationDashboardId && roleData.errors.applicationDashboardId} />
            </div>
            <div>
              <ALTTextField
                name="description"
                placeholder={`Brief Description about new role `}
                variant='outlined'
                multiline={true}
                rows={6}
                value={roleData?.values?.description}
                onChange={(name, value) => handleChange(name, value)}
                required={true}
                charactersMaxLength={200}
                onBlur={roleData.handleBlur}
                error={roleData.touched.description && Boolean(roleData.errors.description)}
                errorMessage={roleData.touched.description && roleData.errors.description} />
            </div>
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <div className="space-between">
          <div>
            <LoadingButton
              variant='outlined'
              size="large"
              className=''
              onClick={onClose}
              startIcon={<CancelIcon />}
              loadingPosition="start">
              <p className={`actionButtonLabel`}>Cancel</p>
            </LoadingButton>
          </div>
          <div className="margin-left-10">
            <LoadingButton
              variant='contained'
              size="large"
              loading={loading}
              type='submit'
              onClick={roleData.handleSubmit}
              startIcon={<TouchAppIcon />}
              className=''
              loadingPosition="start">
              <p className={`actionButtonLabel`}>SUBMIT</p>
            </LoadingButton>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default AddRole