import * as React from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
} from '@mui/x-data-grid';
import {
  randomCreatedDate,
  randomTraderName,
  randomId,
  randomArrayItem,
} from '@mui/x-data-grid-generator';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useTimeout } from '@mui/x-data-grid/internals';

const roles = ['Market', 'Finance', 'Development'];
const randomRole = () => {
  return randomArrayItem(roles);
};

//#this grid allow users to perform crud operations
export default function CrudDataGrid(props) {

  const { initialColumns, initialRows, name, onRowChange, disabled, maxLimit, height } = props;

  const rowsWithId = Array.isArray(initialRows) ? initialRows.map((row, index) => ({ ...row, id: index })) : [];
  const [rows, setRows] = React.useState(rowsWithId);
  const columns = [
    ...initialColumns,
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
              disabled={disabled}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              disabled={disabled}
              color="inherit"
            />,
          ];
        } else {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              disabled={disabled}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              disabled={disabled}
              color="inherit"
            />,
          ];
        }
      },
    },
  ];

  function EditToolbar() {
    /* const { setRows,columns,name,rows,onRowChange, setRowModesModel } = props; */

    const handleClick = () => {
      const id = randomId();

      const newRow = {
        id,
        ...columns?.reduce((acc, item) => {
          acc[item.field] = '';
          return acc;
        }, {})
      };

      // Create a new object based on newRow
      const modifiedNewRow = { ...newRow };

      // Remove the 'actions' field if it exists
      delete modifiedNewRow.actions;

      // Add the 'isNew' field set to false
      modifiedNewRow.isNew = false;


      setRows(oldRows => [...oldRows, modifiedNewRow]);

      onRowChange(name, [rows, modifiedNewRow]);
      setRowModesModel(oldModel => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
      }));
    };

    return (
      <GridToolbarContainer style={{ justifyContent: 'flex-end' }}>
        <div>
          <Button
            variant='outlined'
            startIcon={<AddIcon />}
            onClick={handleClick}
            disabled={disabled || rows?.length >= maxLimit}>
            ADD
          </Button>
        </div>
      </GridToolbarContainer>
    );
  }

  const [rowModesModel, setRowModesModel] = React.useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    // Set row mode to 'View'
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });

  };


  const handleDeleteClick = (id) => () => {
    onRowChange(name, rows.filter((row) => row.id !== id));
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow?.isNew) {
      onRowChange(name, rows.filter((row) => row.id !== id));
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    const updatedRows = rows.map((row) => (row.id === newRow.id ? updatedRow : row));
    onRowChange(name, updatedRows);
    setRows(updatedRows);
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  /*  useEffect(() => {
     if (!arraysAreEqual(initialRows, rows)) {
       debugger
       onRowChange(name, rows);
     }
   }, [rows]); */

  function arraysAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i].id !== arr2[i].id) {
        return false;
      }
    }
    return true;
  }


  return (
    <Box
      sx={{
        height: height ? height : 200,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        disableColumnSelector={true}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        disableColumnMenu
        hideFooter
      />
    </Box>
  );
}
