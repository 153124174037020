import React,{useState,useEffect} from 'react'
import '../FundDetails.css';
import VDRoom from '../../common/VirtualDataRoom/VDRoom';
import { useLocation } from 'react-router-dom';
import { useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import { RolesAndPermissions , FeatureAccess } from '../../../utils/enum';
import { checkFeaturePermissions } from '../../../utils/common';
import secureLocalStorage from "react-secure-storage";
import { getrootBackgroundColorConfig } from '../../../state/slices/colorConfigSlice';
import { useSelector } from 'react-redux';
import Grid  from '@mui/material/Grid';
import { PageHeader,HeaderTemplates } from '../../common/PageHeader/PageHeader';
import Box from '@mui/material/Box';
import FundDetailStatusBox from './FundDetailStatusBox';
import { getRoles } from '../../FundManagerDashboard/Services/FundNewsService';
import { convertStringToArrayWithEmptyArray } from '../../common/Functions/ConvertStringtoArray';
import ChartSelector from '../../common/DataChart/ChartSelector';
import CircleIcon from '../../chatbot/Icon';
import { getFundbyId } from '../../investor-fund-subscription/services/services';
import FundPerformance from '../../common/FundPerformance/FundPerformance';

const FundDetails = (props) => {
  const [fund, setFund] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedManagerMembers,setSelectedManagerMembers] = useState([]);
  const [selectedManagementMembers,setSelectedManagementMembers] = useState([]);
  const { state } = location;
  const disableSubscriptionButton = location?.state?.disableSubscriptionButton;
  const fundType = location?.state?.FundType ? (location?.state?.FundType) : secureLocalStorage.getItem('FundTypeName')
  const fundStatusName = secureLocalStorage.getItem('fundStatusName')
  const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);
  const fundDetail = secureLocalStorage.getItem("FundId");
  const [hideLeftMenu] = useState(props?.HideLeftMenu);

  let parsedFundId;
  try {
    parsedFundId = JSON.parse(fundDetail);
  } catch (error) {
    console.error("Error parsing fundDetail:", error);
  }
  
  let crntFundID, crntInvestorID, crntSubscriptionID;

  //#region click events
  const navigateProfile = () =>{
    navigate('/user-profile');
  }

  const navigatetoMarketPlace = () =>{
    navigate('/marketplace');
  }

  //#region api get calls
  const getFundmanagers = async(fund) => {
    const data = await getRoles("Fund Manager");
    if (data?.responseCode === 200) {
        const fundStaffOptions = data?.responseData.map(user => ({
            label: user.userFullName,
            value: user.userId, 
            briefDescription : user.briefDescription
        }));
        const selectedFMs = convertStringToArrayWithEmptyArray(fund.fundStaff);
        const selectedfundStaffOptions = data?.responseData?.filter((item) => {
          return selectedFMs.includes(item.userId);
        });
        setSelectedManagerMembers(selectedfundStaffOptions);
    } else {
        console.log("Error fetching user details");
    }
  }

  const getManagementMembers = async(fund) => {
    const data = await getRoles();
    if (data?.responseCode === 200) {
        const fundStaffOptions = data?.responseData.map(user => ({
            label: user.userFullName,
            value: user.userId, 
            briefDescription : user.briefDescription
        }));
        const selectedFMs = fund.fundManagementTeams.map((item) => item.managementTeamStaff);
        const selectedfundStaffOptions = data?.responseData?.filter((item) => {
          return selectedFMs.includes(item.userId);
        });
        setSelectedManagementMembers(selectedfundStaffOptions);
    } else {
        console.log("Error fetching user details");
    }
  }

  const getFundDetails = async () => {
    const currFundId = props.fundID === null || props.fundID === undefined ? secureLocalStorage.getItem("FundId") : props.fundID
    const data = await getFundbyId(currFundId);
    if(data.responseCode === 200){
      setFund(data.responseData);
    }
    else{

    }
   };

  //#region useeffect
  useEffect(() => {
    getFundDetails();
  }, []);

  useEffect(() => {
    const { FundID, InvestorID, SubscriptionID,navigatefrommarketplace } = state || {};
    if(navigatefrommarketplace)
    {
        crntFundID = FundID || 0;
        crntInvestorID = InvestorID || 0;
        crntSubscriptionID = SubscriptionID || 0;
    }
    else{
      crntFundID = props.FundID || 0;
      crntInvestorID = props.InvestorID || 0;
      crntSubscriptionID = props.SubscriptionID || 0;
    }
  }, [state]);  

 useEffect(()=>{
  if(fund){
    getFundmanagers(fund);
    getManagementMembers(fund);
  }
 },[fund])
  
 //#region return
  return (
   <div className="viewDetailPage">
       <Box sx={{ flexGrow: 1 }} overflowx={'auto'}>
        <Grid xs={12}>
          <PageHeader
              hideBackButton={props.hideBackButton}
              customBackgroundImage={hideLeftMenu ? (fund?.bgImage || '/DefaultBackgroundImage.png') : fund?.bgImage || '/DefaultBackgroundImage.png'}
              headerImage={fund?.iconImage}
              template={HeaderTemplates.Standard}
              isNavigate={ state?.navigatefromonboarding ? navigateProfile : navigatetoMarketPlace}
              isNavigateToolTip={ state?.navigatefromonboarding === true? 'Back to Profile' : 'Back to Marketplace'}
              fundName={hideLeftMenu ? (props?.fundname ?? secureLocalStorage.getItem("FundName")) : fund?.fundName}

            />
        </Grid>
        <div className='investor-publicProfile'>
        <Grid container>
            <Grid item xs={12} sm={12} md={7} lg={7} className='fund-details_overview'>
                <Grid container xs={12} marginTop={3}>
                    <h4>Overview</h4>
                    <Grid item xs={12}>
                    <p>
                      {fund?.fundDescription ?? "No Data"}
                    </p>
                    </Grid>
                </Grid>
                <Grid item xs={12} className='heading' sx={{ marginBottom: '25px' }}>
                        Premise
                </Grid>
                <Grid container spacing={2} xs={12}>
                    {
                      (fund?.dscInvestmentStrategy && fund?.dscInvestmentStrategy?.trim() !== "") &&
                          <>
                            <Grid item xs={12} className='heading font-size-color'>
                                  Investment Strategy
                            </Grid>
                            <Grid item xs={12}>
                              <ul>
                              <li> 
                                  {fund?.dscInvestmentStrategy ?? "No Data"}  
                                </li>
                              </ul>
                            </Grid> 
                          </>
                    }
                </Grid>
                {
                  (fund?.dscInvestmentCriteria && fund?.dscInvestmentCriteria?.trim() !== "" )&&
                  <Grid container spacing={2} xs={12}>
                      <Grid item xs={12} className='heading font-size-color'>
                          Investment Criteria
                      </Grid>
                      <Grid item xs={12}>
                        <ul>
                        <li>
                            {fund?.dscInvestmentCriteria ?? "No Data"}
                          </li>
                        </ul>
                    </Grid>
                  </Grid>
                }
               {/*  <Grid container spacing={2} xs={12}>
                    <Grid item xs={12} className='heading font-size-color'>
                    Investment Process
                    </Grid>
                    <Grid item xs={12} className='atVeroEos'>
                    <p>
                      {fund?.dscInvestmentProcess ?? "No Data"}
                    </p>
                  </Grid>
                </Grid> */}
                {(fund?.dscRiskManagementApproach && fund?.dscRiskManagementApproach?.trim() !== "") && 
                <Grid container spacing={2} xs={12}>
                      <Grid item xs={12} className='heading font-size-color'>
                      Risk Management approach
                      </Grid>
                      <Grid item xs={12}>
                        <ul>
                        <li>
                        {fund?.dscRiskManagementApproach ?? "No Data"}
                      </li>
                        </ul>
                      
                    </Grid>
                  </Grid>
                }
                <Grid container spacing={2} xs={12}>
                    <Grid item xs={12} className='heading font-size-color'>
                       Fund Manager and their description
                    </Grid>
                    <Grid item xs={12} className='atVeroEos'>
                      <div className=''>
                        {
                          fund?.isFirmAsGP ? 
                             <div className='fd-firmname margin-top-5 margin-bottom-5'>{secureLocalStorage.getItem("tenantName")}</div> :
                            fund?.fundStaffDetails?.map((item, index) => {
                              return (
                                <React.Fragment key={index} className='margin-top-5 child-margin-15'>
                                  <div className='fd-firmname'>
                                    {item?.userName}
                                  </div>
                                  <ul>
                                    <li>
                                      {item?.userDescription}
                                    </li>
                                  </ul>
                                </React.Fragment>
                              );
                            })
                          }
                      </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2} xs={12}>
                    <Grid item xs={12} className='heading font-size-color'>
                       Management Team and their description
                    </Grid>
                    <Grid item xs={12} className='atVeroEos'>
                      <div className='margin-top-minus-15'>
                            {
                              selectedManagementMembers?.map((item)=>{
                                return(
                                  <div className='child-margin-15'>
                                    <div className='fd-firmname'>
                                        {item.userFullName}
                                    </div>
                                    <ul>
                                    <li>
                                      {item.briefDescription}
                                    </li>
                                    </ul>
                                  </div>
                                )
                              })
                            }
                        </div>
                  </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5} className='investor-overview-fundstatus'>
            <Grid xs={12} className='Invet-fundstatusbox'>
                {fund && <FundDetailStatusBox FundType = { fundType }  fundStatusName={fundStatusName} fundDetails={fund} disableSubscriptionButton={disableSubscriptionButton} renderSubscribeBtnLabel = {location?.state?.renderSubscribeBtnLabel} />}
            </Grid>

            <Grid xs={12} marginTop={2} className='whiteCard'>
                <Grid container xs={12} padding={2}>
                  {checkFeaturePermissions(
                          RolesAndPermissions.FUND_VDR , 
                          FeatureAccess.READ,
                          ) && (
                                <div>
                                <div >
                                  {/* <div className='alldocumentsgrid'> */}
                                  {/*  Fund Documents */}
                                    <VDRoom hideMenu={hideLeftMenu}
                                    VDRType={'Fund'} isCompliance={true} cnrtFundID={secureLocalStorage.getItem("FundId")} crntSubscriptionID={0}/>
                                  </div>
                                </div>
                        )}
                </Grid>
            </Grid>
            {/*  <Grid xs={12} marginTop={8} className='whiteCard mb4'>
            <Grid container spacing={1} xs={12}>
                <Grid item container>
                      <Grid item xs={12}>
                        <h6> Important Notes</h6> 
                      </Grid>
                      <Grid item>
                      <p>
                        At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                      </p>
                      </Grid>
                  </Grid>
                </Grid>
            </Grid> */}
            </Grid>
        </Grid>
        <Grid container xs={12}>
            <Grid item xs={12}>
               <div className='white-card margin-top-15'>
                 <FundPerformance fundTypeName={fund?.fundTypeName} type = "performancetab" />
               </div>
            </Grid>
        </Grid>
        </div>
       </Box>
  <CircleIcon/>
   </div>
  )
}

export default FundDetails