import React from "react";

export const BoxbackgroundWhite = {
  backgroundColor: "#FFFFFF",
  boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
  borderRadius: '8px',
  padding: '16px',
};

export const BoxWhiteBackgroundPad20 = {
  backgroundColor: "#FFFFFF",
  boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
  borderRadius: '8px',
  padding:'20px'
};

export const BoxAlignCenter = {
  marginLeft: '50px',
  marginRight: '30px'
};

export const boxmarginbottom = {
    marginBottom : '40px'
}

export const MUIInputsMarginTop = {
     marginTop: '15px',
 }

export const MUIInputsMarginTop0 = {
  marginTop: '0px',
}

export const MUITextBoxHeight = {
  height: '15px',
}

export const cancelbutton = {
  width: '100px', 
  backgroundColor: 'white', 
  color: 'gray'
}

export const BadgeDisplay = { border: '1px solid #C0CDD9',
                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                        height: '107px',
                        borderRadius: '8px',
                        margin: '6px',
                        display: 'flex',
                        padding: '20px'
  }

export const justifyContentAllignItem = {
  display : 'flex',
  justifyContent: 'space-between', 
  alignItems: 'center'
}