import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import TextInput from '../../../common/TextInput/TextInput';
import AddNewMultiChoiceQuestion from './AddNewMultiChoiceQuestion';
import AddNewTextQuestion from './AddNewTextQuestion';
import { Add } from '@mui/icons-material';
import AppTextInput from '../../../common/TextInput/AppTextField';
import { ToastContainer, toast } from 'react-toastify';
import { InputTypes, validationMessages, validationsTypeEnum } from '../../../common/TextInput/appInputenum';
import { OnboardingQuestionPattern } from '../OnBoardingEnum';
import CancelIcon from '@mui/icons-material/Cancel';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const ChooseQuestionPopup = (props) => {

  //#region props
 const { open, onAccept ,onClose,data ,mcqType ,textType,QuestionTypeDropdown,investorTypeID} = props;

 //#region variables
 const [QuestionType,setQuestionType] = useState(null);
 const [openpopup,setopenpopup] = useState(false);
 const questionTypeDropDownValues = QuestionTypeDropdown
                                    ?.filter((item) => item?.label?.toLowerCase()?.trim() === OnboardingQuestionPattern.MCQ_TYPE.toLowerCase()?.trim() || 
                                      item?.label?.toLowerCase()?.trim() === OnboardingQuestionPattern.TEXT_TYPE.toLowerCase()?.trim())

 //#region click events
  const handleChange = (field,value) =>{
    setQuestionType(value);
  }

  const onOpenpopup = () =>{
    setopenpopup(true);
  }

  //#region validations
  const [noofValidFields, setNoofValidFields] = useState({ validfields: 0 });
  const [checkIfValid, setcheckIfValid] = useState(false);
  let count = 0;   //to Show Toast Message only once

  const checknoofValidFields = (isValid, name, defaultValue) => {
        const toatlFieldstoValidate = 1;
        count = ++count;
        noofValidFields['validfields'] = isValid ? noofValidFields['validfields'] + 1 : noofValidFields['validfields'];
        if (noofValidFields['validfields'] === toatlFieldstoValidate) {
            noofValidFields['validfields'] = 0;
            count = 0;
            onOpenpopup();
        }else{
         if(count === toatlFieldstoValidate){
            toast.warning("please verify the input fields",
               { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" , style: { zIndex: 9999 }});
            count = 0;
          }
        }
   };

  const handleSave = () => {
    setcheckIfValid(true);
    noofValidFields['validfields'] = 0;
 }

 //#region return
  return (
    <div>
        <Dialog open={open} fullWidth>
            <DialogTitle>
              <h6> Choose Question Type</h6> 
            </DialogTitle>
            <DialogContent>
                <AppTextInput
                    type={InputTypes.SELECT}
                    name="questiontype"
                    label="Select the type of question"
                    defaultValue={QuestionType || ''}
                    options={questionTypeDropDownValues ? questionTypeDropDownValues?.map(option => ({ label: option?.label, value: option?.value })) : []}
                    onChange={(name, value) => handleChange(name, value)}
                    validateField={true}
                    validationType={validationsTypeEnum.REQUIRED}
                    validationMessage={"Please select the type of question"}
                    checkIfValid={checkIfValid}
                    checknoofValidFields={checknoofValidFields}
                    setcheckIfValid={setcheckIfValid} />
            </DialogContent>
            <DialogActions>
            <div className="space-between">
            <div>
                <Button
                component="label"
                variant="outlined"
                className='btn-primary'
                onClick={onClose}
                >
                  <CancelIcon/>
                Cancel
                </Button>
            </div>
            
            <div className="margin-left-10">
                <Button
                component="label"
                variant="contained"
                onClick={handleSave}>
                Next
                <NavigateNextIcon/>
                </Button>
            </div>

            </div>
            </DialogActions>
        </Dialog> 
        {openpopup && (
                <>
                    {QuestionType === mcqType ? (
                    <AddNewMultiChoiceQuestion setSelectQuestionType={props.setSelectQuestionType}
                    data={props.data} setNewData={props.setNewData}
                    mcqType={mcqType} textType={textType} investorTypeID={investorTypeID} />
                    ) : null}
                    
                    {QuestionType === textType ? (
                    <AddNewTextQuestion setSelectQuestionType={props.setSelectQuestionType} 
                         data={props.data} setNewData={props.setNewData}
                         mcqType={mcqType} textType={textType} investorTypeID={investorTypeID}/>
                    ) : null}
                </>
                )}
    </div>
  )
}

export default ChooseQuestionPopup