
import React from 'react';
import Box from '@mui/material/Box';
import { Grid } from "@mui/material";
import Divider from '@mui/material/Divider';
import jsonData from '../jsonData.json'
import '../styles.css'
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { getCompanybyID } from '../Services/CompaniesDetails';
import secureLocalStorage from 'react-secure-storage';
import { GetCompanyFitlerDetails } from '../Services/CompaniesDetails';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import CircleIcon from '../../chatbot/Icon';
import { checkFeaturePermissions } from '../../../utils/common';
import { FeatureAccess, RolesAndPermissions } from '../../../utils/enum';
import { Typography } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

const CompanyOverview = (props) => {

    const [OverviewDetails, setOverviewDetails] = useState();

    const [boardMembers, setBoardMembers] = useState([]);

    const [isSaveEnabled, setSaveEnabled] = useState(props.isSaveEnabled);




    const getCompaniesDetails = async () => {
        const companyID = secureLocalStorage.getItem("companyID");

        if (companyID && companyID !== "0") {
            const data = await getCompanybyID(companyID);

            if (data.responseCode === 200) {
                console.log(data.responseData);
                setOverviewDetails(data.responseData);

                const apiMembers = convertStringToArray(data.responseData.boardMembers);

                const addIDS = apiMembers.map((member, index) => ({
                    id: index,
                    data: member
                }));
                
                setBoardMembers(addIDS);
            } else {
                console.log("Error fetching company data");
            }
        } else {
            setOverviewDetails([
                {
                    companyID: 0,
                }
            ]);
        }
    }

    const [FiltersData, setFiltersData] = useState([]);

    const getFiltersData = async () => {
        const data = await GetCompanyFitlerDetails();
        if (data.responseCode === 200) {
            console.log(data.responseData);
            setFiltersData(data.responseData);
        } else {
            console.log("data.responseData");
        }
    }

    useEffect(() => {
        getCompaniesDetails();
        getFiltersData();
    }, []);

    const handleSave = (OverviewDetails) => {
        const data = OverviewDetails
        const fundID = secureLocalStorage.getItem("FundId");
        const userID = secureLocalStorage.getItem("userId");
        const companyID = secureLocalStorage.getItem("companyID") || 0;
        if (isSaveEnabled) {
            const companyDetails = {
                "companyId": companyID,
                "companyName": OverviewDetails.companyName || "",
                "industryId": OverviewDetails.industryId || 1,
                "sectorId": OverviewDetails.sectorId !== undefined && OverviewDetails.sectorId !== null ? parseInt(OverviewDetails.sectorId) : 1,
                "companyTypeId": OverviewDetails.companyTypeId || 1,
                "name": OverviewDetails.name || "",
                "companyStatusId": 1,
                "ceoName": OverviewDetails.ceoName || "",
                "ceoEmail": OverviewDetails.ceoEmail || "",
                "ceoPhone": OverviewDetails.ceoPhone || "",
                "cfoName": OverviewDetails.cfoName || "",
                "cfoEmail": OverviewDetails.cfoEmail || "",
                "cfoPhone": OverviewDetails.cfoPhone || "",
                "otherContactTitle": OverviewDetails.otherContactTitle || "",
                "otherContactPhone": OverviewDetails.otherContactPhone || "",
                "otherContactName": OverviewDetails.otherContactName || "",
                "otherContactEmail": OverviewDetails.otherContactEmail || "",
                "legalCounselFirmName": OverviewDetails.legalCounselFirmName || "",
                "legalCounselName": OverviewDetails.legalCounselName || "",
                "legalCounselEmail": OverviewDetails.legalCounselEmail || "",
                "legalCounselContactPhone": OverviewDetails.legalCounselContactPhone || "",
                "boardRepresentation": OverviewDetails.boardRepresentation || "",
                "boardMembers": convertArrayToString(boardMembers) || "",
                "accountantFirmName": OverviewDetails.accountantFirmName || "",
                "accountantName": OverviewDetails.accountantName || "",
                "accountantEmail": OverviewDetails.accountantEmail || "",
                "accountantContactPhone": OverviewDetails.accountantContactPhone || "",
                "dueDiligenceLeader": OverviewDetails.dueDiligenceLeader || "",
                "dueDiligenceTeamMembers": OverviewDetails.dueDiligenceTeamMembers || "",
                "companyHQAddressId": OverviewDetails.companyHQAddressId || 0,
                "companyHQAddress1": OverviewDetails.companyHQAddress1 || "",
                "companyHQAddress2": OverviewDetails.companyHQAddress2 || "",
                "companyHQAddress3": OverviewDetails.companyHQAddress3 || "",
                "companyHQState": OverviewDetails.companyHQState || 1,
                "companyHQCity": OverviewDetails.companyHQCity || 1,
                "companyHQCountry": OverviewDetails.companyHQCountry || 1,
                "companyHQCountryName": OverviewDetails.companyHQCountryName || "",
                "companyHQZipCode": OverviewDetails.companyHQZipCode || "",
                "legalCounselMainAddressId": OverviewDetails.legalCounselMainAddressId || 0,
                "legalCounselAddress1": OverviewDetails.legalCounselAddress1 || "",
                "legalCounselAddress2": OverviewDetails.legalCounselAddress2 || "",
                "legalCounselAddress3": OverviewDetails.legalCounselAddress3 || "",
                "legalCounselState": OverviewDetails.legalCounselState || 1,
                "legalCounselCity": OverviewDetails.legalCounselCity || 1,
                "legalCounselCountry": OverviewDetails.legalCounselCountry || 1,
                "legalCounselZipCode": OverviewDetails.name || "",
                "accountantMainAddressId": OverviewDetails.accountantMainAddressId || 0,
                "accountantFirmAddress1": OverviewDetails.accountantFirmAddress1 || "",
                "accountantFirmAddress2": OverviewDetails.accountantFirmAddress2 || "",
                "accountantFirmAddress3": OverviewDetails.accountantFirmAddress3 || "",
                "accountantFirmState": OverviewDetails.accountantFirmState || 1,
                "accountantFirmCity": OverviewDetails.accountantFirmCity || 1,
                "accountantFirmCountry": OverviewDetails.accountantFirmCountry || 1,
                "accountantFirmZipCode": OverviewDetails.accountantFirmZipCode || "",
                "isActive": true,
                "softDelete": false,
                "createdDate": new Date(),
                "updatedDate": new Date(),
                "investmentStageId": OverviewDetails.investmentStageId || 1,
                "exitStatusId": OverviewDetails.exitStatusId || 1
            };
        }

        setSaveEnabled(isSaveEnabled => !isSaveEnabled);
    }

    const handleChange = (field, value, datatype) => {
        const re = /^[0-9\b]+$/;
        if (datatype === "numeric") {
            if (!re.test(value)) {
                return;
            }
        }
        const currState = { ...OverviewDetails };
        currState[field] = value;
        setOverviewDetails(currState);
    }

    const handleAddMore = () => {
        const addmember = {
            id: boardMembers.length > 0 ? boardMembers[boardMembers.length - 1].id + 1 : 1,
            data: ""
        }        
        setBoardMembers([...boardMembers, addmember]);
    }

    const handleDelete = (data) => {
        const updatedMembers = boardMembers.filter(member => member.id !== data.id);
        setBoardMembers(updatedMembers);
    }

    const handleChangeMembers = (field, value, member) => {
        const updatedMembers = boardMembers.map(item => {
            if (item.id === member.id) {
                return {
                    ...item,
                    data : value
                };
            }
            return item;
        });
        setBoardMembers(updatedMembers);
    }
    
    useEffect(() => {
        props.setSaveEnabled(isSaveEnabled);
    }, [isSaveEnabled]);

    const navigate = useNavigate();

    function convertStringToArray(input) {
        if (Array.isArray(input)) {
            return input;
        }

        if (typeof input === 'string' && input.length > 0) {
            return input.split(',');
        }

        return [input];
    }

    function convertArrayToString(arr) {
        if (!Array.isArray(arr) || arr.length === 0) {
            return "";
        }

        const onlyNames = arr.map((item) => item.data);
        return onlyNames.join(',');
    }


    return (
        <div>
            <Grid item xs={12} marginBottom={1}>
                <div className='space-between'>    
               <Typography variant='h5'>Company Overview </Typography>
                    <div>
{
   checkFeaturePermissions(RolesAndPermissions.PORTFOLIO_COMPANY, FeatureAccess.UPDATE ) &&
                        <Button variant='outlined' className='btn-primary'
                            // style={{ width: '100px', backgroundColor: 'lightgray', color: 'black', marginTop: '-7px' }}
                            startIcon={isSaveEnabled ? <SaveIcon /> : <EditIcon />}
                            onClick={() => handleSave(OverviewDetails)}>
                            {isSaveEnabled ? 'Save' : 'Edit'}
                        </Button>
}
                    </div>
                </div>
            </Grid>
            <Box className='background-color-white' sx={{ padding: '16px' }}>
                <Grid container columnSpacing={4} marginTop={'20px'}>

                    <Grid item xs={12} md={6} >

                        <Divider sx={{ color: 'gray', opacity: '1' }} />
                        <React.Fragment>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px' }}>
                                <div className='font-cresta requiredField'>Company Name</div>
                                <input
                                    value={OverviewDetails && OverviewDetails["companyName"]}
                                    name="companyName"
                                    className={isSaveEnabled ? "fieldClassSave" : "fieldClass"}
                                    readOnly={!isSaveEnabled}
                                    
                                    onChange={(event) => handleChange(event.currentTarget.name, event.currentTarget.value, "string")}
                                />
                            </div>
                        </React.Fragment>

                        <Divider sx={{ color: 'gray', opacity: '1' }} />
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px' }} className='font-size-color'>
                            HEAD QUARTERS ADDRESS
                        </div>
                        {jsonData.headquateraddress.map((data, index) => (
                            <React.Fragment key={index}>
                                <Divider sx={{ color: 'gray', opacity: '1' }} />
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px' }}>
                                    <div className={`font-cresta ${data.required ? 'requiredField' : ''}`}>{data.name}</div>
                                    {
                                        data.type == "select" ?
                                            (
                                                <select
                                                    value={OverviewDetails && OverviewDetails[data.apiName]}
                                                    name={data.apiName}
                                                    className={isSaveEnabled ? "fieldClassSave-dropdown" : "fieldClass-dropdown"}
                                                    disabled={!isSaveEnabled}
                                                    style={{ appearance: isSaveEnabled ? 'auto' : 'none' }}
                                                    onChange={(event) => handleChange(event.currentTarget.name, event.currentTarget.value, data.responseDatatype)}
                                                >
                                                    {OverviewDetails && OverviewDetails[data.apiName] === null ?
                                                        <option value="" > </option> : null
                                                    }

                                                    {FiltersData[data.dropdown]?.map((option) => (
                                                        <option key={option.id} value={option[data.dropdownvalue]}>{option[data.dropdownlabel]}</option>
                                                    ))}
                                                </select>

                                            ) : (

                                                <input
                                                    value={OverviewDetails && OverviewDetails[data.apiName]}
                                                    name={data.apiName}
                                                    className={isSaveEnabled ? "fieldClassSave" : "fieldClass"}
                                                    readOnly={!isSaveEnabled}

                                                    onChange={(event) => handleChange(event.currentTarget.name, event.currentTarget.value, data.responseDatatype)}
                                                />
                                            )
                                    }

                                </div>
                            </React.Fragment>
                        ))}

                        <Divider sx={{ color: 'gray', opacity: '1' }} />
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px' }} className='font-size-color'>
                            LEGAL COUNSEL
                        </div>
                        {jsonData.legalcounsel.map((data, index) => (
                            <React.Fragment key={index}>
                                <Divider sx={{ color: 'gray', opacity: '1' }} />
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px' }}>
                                    <div className={`font-cresta ${data.required ? 'requiredField' : ''}`}>{data.name}</div>
                                    {
                                        data.type == "select" ?
                                            (
                                                <select
                                                    value={OverviewDetails && OverviewDetails[data.apiName]}
                                                    name={data.apiName}
                                                    className={isSaveEnabled ? "fieldClassSave-dropdown" : "fieldClass-dropdown"}
                                                    disabled={!isSaveEnabled}
                                                    style={{ appearance: isSaveEnabled ? 'auto' : 'none' }}
                                                    onChange={(event) => handleChange(event.currentTarget.name, event.currentTarget.value, data.responseDatatype)}
                                                >
                                                    {OverviewDetails && OverviewDetails[data.apiName] === null ?
                                                        <option value="" > </option> : null
                                                    }
                                                    {FiltersData[data.dropdown]?.map((option) => (
                                                        <option key={option.id} value={option[data.dropdownvalue]}>{option[data.dropdownlabel]}</option>
                                                    ))}
                                                </select>

                                            ) : (

                                                <input
                                                    value={OverviewDetails && OverviewDetails[data.apiName]}
                                                    name={data.apiName}
                                                    className={isSaveEnabled ? "fieldClassSave" : "fieldClass"}
                                                    readOnly={!isSaveEnabled}

                                                    onChange={(event) => handleChange(event.currentTarget.name, event.currentTarget.value, data.responseDatatype)}
                                                />
                                            )
                                    }
                                </div>
                            </React.Fragment>
                        ))}

                        <Divider sx={{ color: 'gray', opacity: '1' }} />
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px' }} className='font-size-color'>
                            ACCOUNTING FIRM
                        </div>
                        {jsonData.accountingfirm.map((data, index) => (
                            <React.Fragment key={index}>
                                <Divider sx={{ color: 'gray', opacity: '1' }} />
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px' }}>
                                    <div className={`font-cresta ${data.required ? 'requiredField' : ''}`}>{data.name}</div>
                                    {
                                        data.type == "select" ?
                                            (
                                                <select
                                                    value={OverviewDetails && OverviewDetails[data.apiName]}
                                                    name={data.apiName}
                                                    className={isSaveEnabled ? "fieldClassSave-dropdown" : "fieldClass-dropdown"}
                                                    disabled={!isSaveEnabled}
                                                    style={{ appearance: isSaveEnabled ? 'auto' : 'none' }}
                                                    onChange={(event) => handleChange(event.currentTarget.name, event.currentTarget.value, data.responseDatatype)}
                                                >
                                                    {OverviewDetails && OverviewDetails[data.apiName] === null ?
                                                        <option value="" > </option> : null
                                                    }

                                                    {FiltersData[data.dropdown]?.map((option) => (
                                                        <option key={option.id} value={option[data.dropdownvalue]}>{option[data.dropdownlabel]}</option>
                                                    ))}
                                                </select>

                                            ) : (

                                                <input
                                                    value={OverviewDetails && OverviewDetails[data.apiName]}
                                                    name={data.apiName}
                                                    className={isSaveEnabled ? "fieldClassSave" : "fieldClass"}
                                                    readOnly={!isSaveEnabled}

                                                    onChange={(event) => handleChange(event.currentTarget.name, event.currentTarget.value, data.responseDatatype)}
                                                />
                                            )
                                    }
                                </div>
                            </React.Fragment>
                        ))}
                    </Grid>

                    <Grid item xs={12} md={6} >

                        <Divider sx={{ color: 'gray', opacity: '1' }} />
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px' }} className='font-size-color'>
                            LEADERSHIP TEAM
                        </div>
                        {jsonData.leadershipteam.map((data, index) => (
                            <React.Fragment key={index}>
                                <Divider sx={{ color: 'gray', opacity: '1' }} />
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px' }}>
                                    <div className={`font-cresta ${data.required ? 'requiredField' : ''}`}>{data.name}</div>
                                    {
                                        data.type == "select" ?
                                            (
                                                <select
                                                    value={OverviewDetails && OverviewDetails[data.apiName]}
                                                    name={data.apiName}
                                                    className={isSaveEnabled ? "fieldClassSave-dropdown" : "fieldClass-dropdown"}
                                                    disabled={!isSaveEnabled}
                                                    style={{ appearance: isSaveEnabled ? 'auto' : 'none' }}
                                                    onChange={(event) => handleChange(event.currentTarget.name, event.currentTarget.value, data.responseDatatype)}
                                                >
                                                    {OverviewDetails && OverviewDetails[data.apiName] === null ?
                                                        <option value="" disabled hidden></option> : null
                                                    }
                                                    {FiltersData[data.dropdown]?.map((option) => (
                                                        <option key={option.id} value={option[data.dropdownvalue]}>{option[data.dropdownlabel]}</option>
                                                    ))}
                                                </select>

                                            ) : (

                                                <input
                                                    value={OverviewDetails && OverviewDetails[data.apiName]}
                                                    name={data.apiName}
                                                    className={isSaveEnabled ? "fieldClassSave" : "fieldClass"}
                                                    readOnly={!isSaveEnabled}

                                                    onChange={(event) => handleChange(event.currentTarget.name, event.currentTarget.value, data.responseDatatype)}
                                                />
                                            )
                                    }
                                </div>
                            </React.Fragment>
                        ))}

                        <Divider sx={{ color: 'gray', opacity: '1' }} />
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px' }} className='font-size-color'>
                            BOARD REPRESENTATION
                        </div>
                        {boardMembers?.map((member, index) => (
                            <React.Fragment key={index}>
                                <Divider sx={{ color: 'gray', opacity: '1' }} />
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px' }}>
                                        <input
                                            value={member.data}
                                            name={member.data}
                                            className={isSaveEnabled ? "fieldClassSave" : "fieldClass-members"}
                                            readOnly={!isSaveEnabled}
                                            onChange={(event) => handleChangeMembers(event.currentTarget.name, event.currentTarget.value, member)}
                                        />
                                    {isSaveEnabled ?
                                       <Tooltip className='deleteicon' onClick= {() => handleDelete(member)} title="Delete" arrow>
                                       <IconButton>
                                           <DeleteIcon />
                                       </IconButton>
                                       </Tooltip> : null
                                       
                                   }  
                                </div>
                                {/*  <Divider sx={{ color: 'gray', opacity: '1' }} /> */}
                            </React.Fragment>
                        ))}
                        <Divider sx={{ color: 'gray', opacity: '1' }} />
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px' }} className='font-size-color'>
                            {isSaveEnabled && (
                                <Button variant='outlined' className='btn-primary'
                                   onClick={handleAddMore}>
                                    <AddCircleOutlineIcon /> ADD MORE
                                </Button>
                            )}
                        </div>
                    </Grid>
                </Grid>
                <ToastContainer />
            </Box>
            <CircleIcon />
        </div>
    )
}

export default CompanyOverview