import React, { useEffect, useState } from 'react'
import StepContainer from '../../../investor-fund-subscription/components/StepContainer'
import secureLocalStorage from 'react-secure-storage'
import { stepsToShowWhile } from '../../../investor-fund-subscription/services/apiDataTemplate'
import { getUserQuestionBank } from '../../../investor-fund-subscription/services/services'
import { configurationTypesEnum } from '../../../administration/investor-subscription-questions/services/apiDataTemplate'
import { questionsTypesEnum } from '../../services/apiDataTemplate'

const SubscriptionDetails = () => { 

  //#region variables
  const [investorSubscriptionDetails,setInvestorSubscriptionDetails] = useState();

  //#region api get calls
  const getSubscriptionDetails = async () => {
    const investorId=secureLocalStorage.getItem("userId")
    const questionsType = questionsTypesEnum.SUBSCRIPTION
    const data = await getUserQuestionBank(investorId,questionsType);
    if (data.responseCode === 200) {
        setInvestorSubscriptionDetails(data.responseData);
    }
    else {
        setInvestorSubscriptionDetails(null);
    }
  }

  //#region useeffect
  useEffect(()=>{
    getSubscriptionDetails();
  },[])

  return (
    <div>
          <StepContainer 
             investorId={secureLocalStorage.getItem("userId")}
             type={stepsToShowWhile.USER_PROFILE_SAVE}
             fundID={0}
             subscriptionId={0}
             investorSubscriptionDetails={investorSubscriptionDetails}
             fundDeatils={investorSubscriptionDetails?.fundBasicDetails}
             getQuestionAnswerDetails={getSubscriptionDetails} />
    </div>
  )
}

export default SubscriptionDetails