import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import axiosApiCall from "../../../Services/axiosApiCall";
import { getAPIResponse } from "../../../../utils/common";

export async function getFirmStructureDetails() {
    return await axiosApiCall.get(`/v1/MasterData/GetListItemsByType?listType=FirmStructure`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getRegulatoryComplianceDetails() {
    return await axiosApiCall.get(`/v1/MasterData/GetListItemsByType?listType=RegulatoryComplianceStatus`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getFirmTypeDetails() {
    return await axiosApiCall.get(`/v1/MasterData/GetListItemsByType?listType=FirmType`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getFirmDetails() {
    return await axiosApiCall.get(`/v1/Tenant/GetFirmDetails`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function updateFirmDetails(formData) {
    return await axiosApiCall.put(`/v1/Tenant/UpdateFirm`, formData)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}