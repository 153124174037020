import React, { useState } from 'react';

const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [isListening, setIsListening] = useState(false);

  const toggleListening = () => {
    // Code to start listening for speech input
    sendMessage()
  };

  const sendMessage = async (message) => {
    const newMessages = [...messages, { text: message, fromUser: true }];
    setMessages(newMessages);

    try {
      const response = await fetch('https://AABOT-bot-12ea.azurewebsites.net/api/messages', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // You may need to add additional headers like Authorization if required by your endpoint
        },
        body: JSON.stringify({
          text: message,
          // Add any additional parameters required by your bot
        })
      });

      const data = await response.json();
      const botResponse = data.text; // Assuming your bot returns the response in the 'text' field

      const updatedMessages = [...newMessages, { text: botResponse, fromUser: false }];
      setMessages(updatedMessages);
    } catch (error) {
      console.error('Error sending message to QnA Maker:', error);
      // Handle error
    }
  };

  return (
    <div>
      <div>
        {messages.map((message, index) => (
          <div key={index} className={message.fromUser ? 'user-message' : 'bot-message'}>
            {message.text}
          </div>
        ))}
      </div>
      <button onClick={toggleListening} disabled={isListening}>
        {isListening ? 'Listening...' : 'Start Listening'}
      </button>
    </div>
  );
};

export default Chatbot;
