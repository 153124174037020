import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import { Navigate, useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { AppDashboards } from '../../../utils/enum';
import { ConvertToUSCurrency } from '../../common/Functions/CommonConvertions';
import { getFundSubscriptions } from '../../dashboards/services/DashboardService';
import { fundSubscriptionStatues } from '../../CODashboard/Services/apiDataTempate';

const FundDetailStatusBox = ({ fundDetails, fundStatusName, disableSubscriptionButton, FundType, renderSubscribeBtnLabel }) => {
    ;
    const navigate = useNavigate();
    const [allInvestors, setAllInvestors] = useState(null)

    const colorwhite = {
        color: 'white'
    }

    const firstBoxStyle = {
        backgroundColor: '#0A1A27',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',

    }

    const secondBoxStyle = {
        backgroundColor: '#FFFFFF',
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px',
        boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)'
    }

    const linestyle = {
        width: '95%',
        margin: 0,
        marginTop: '3px',
        border: '1px solid #ddd',
        // height: '0.5px', 
    };


    const lastHrStyle = {
        ...linestyle,
        marginBottom: '20px',
    };

    const fontsmall = {
        color: '#f0f0f0',
        fontWeight: 100,
        fontSize: '14px'
    };


    const fontbold = {
        lineHeight: '41px',
        color: 'white',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: '25px',
        overflowWrap: "break-word"
    };

    const textalignright = {
        textAlign: 'right'
    }

    const handlesubscribe = () => {
        navigate('/FundSubscriptionPage');
        window.scrollTo(0, 0);
    };

    //   const fundID = true;
    //   const item = {
    //     fundID: true 
    //   };

    let PublicProfileView = true;

    //   if (item && !item?.fundID) {
    //     PublicProfileView = false; 
    //   }



    const getFundsSubscriptions = async () => {
        const data = await getFundSubscriptions(fundDetails?.fundID);
        if (data.responseCode === 200) {
            debugger
            setAllInvestors(data.responseData.filter(u => u.investorID === secureLocalStorage.getItem("userId") && u.subscriptionStatus !== "Rejected" && u.subscriptionStatus !== "Cancelled"));
        }
        else {
            console.log('unable to load funds')
        }
    }

    useEffect(() => {
        getFundsSubscriptions()
    }, [])

    const renderSubscribeButtonLabel = () => {
        if (allInvestors?.length > 0) {
            if (allInvestors?.some(u => (u.fundID === fundDetails?.fundID && u.subscriptionStatus === fundSubscriptionStatues.COMPLIANCE_REVIEW)
                || (u.fundID === fundDetails?.fundID && u.subscriptionStatus === fundSubscriptionStatues.FM_REVIEW)
                || (u.fundID === fundDetails?.fundID && u.subscriptionStatus === fundSubscriptionStatues.APPROVED)
            )) {
                return 'MY INVESTMENT'
            }
            else if (allInvestors?.some(u =>
                u.fundID === fundDetails?.fundID &&
                u.subscriptionStatus === fundSubscriptionStatues.Awaiting_Online_Processing)) {
                return 'SUBSCRIBED'
            }
            else if (allInvestors?.some(u =>
                u.fundID === fundDetails?.fundID &&
                u.subscriptionStatus === fundSubscriptionStatues.INVESTOR_SIGN_PENDING &&
                u.isOfflineSubscription !== true)) {
                return "SUBSCRIBE"
            }
            else if (allInvestors?.some(u =>
                u.fundID === fundDetails?.fundID &&
                u.subscriptionStatus === fundSubscriptionStatues.REJECTED)) {
                return 'SUBSCRIBE'
            }
            else {
                return 'SUBSCRIBE'
            }
        }
    }


    return (
        <div>
            {
                allInvestors ? <>             <Grid sx={firstBoxStyle} className='whiteCard' >
                <Grid container spacing={4} xs={12} marginLeft={1} >
                    <Grid item xs={6} sx={{ ...colorwhite, ...fontsmall }}>
                        <Grid>
                            Status
                        </Grid>
                        <Grid sx={fontbold}>
                            {/* {fundDetail?.fundStatusName} */}
                            {fundDetails?.fundStatusName}
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sx={{ ...colorwhite, ...fontsmall }}>
                        <Grid >
                            Remaining
                        </Grid>
                        <Grid sx={fontbold}>
                            {(((fundDetails?.fundSize - fundDetails.capitalCommitted) / fundDetails?.fundSize) * 100)?.toFixed(2)}%
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12} sx={{...colorwhite,...fontsmall}} marginTop={'-20px'} >
                      Accepting Investments: $0K - $0K
                </Grid> */}
                    {PublicProfileView ?
                        <Grid item xs={11} marginBottom={4} sx={textalignright}>
                            {secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD &&
                                (
                                    <Button
                                        component="label"
                                        variant="contained"
                                        style={
                                            (fundStatusName === 'Closed to New Investors' || fundStatusName === 'Launching Soon' || fundStatusName === 'Closed' || fundStatusName === 'Terminated' || (renderSubscribeBtnLabel !== undefined ? renderSubscribeBtnLabel !== 'SUBSCRIBE' :  renderSubscribeButtonLabel() !== 'SUBSCRIBE' ))
                                                ? { width: '160px', backgroundColor: 'gray', color: 'black' }
                                                : { width: '160px', backgroundColor: '#F9C023', color: 'black' }
                                        }
                                        onClick={handlesubscribe}
                                        disabled={fundStatusName === 'Closed to New Investors' || fundStatusName === 'Launching Soon' || fundStatusName === 'Closed' || fundStatusName === 'Terminated' || (renderSubscribeBtnLabel !== undefined ? renderSubscribeBtnLabel !== 'SUBSCRIBE' :  renderSubscribeButtonLabel() !== 'SUBSCRIBE' )}
                                    >
                                          {renderSubscribeBtnLabel === undefined ? renderSubscribeButtonLabel()  : renderSubscribeBtnLabel}
                                    </Button>
                                )
                            }
                        </Grid>
                        : null}


                </Grid>
            </Grid>

            <Grid xs={12} sx={secondBoxStyle} >
                <Grid container spacing={2} xs={12} marginLeft={2} className='portfolio-box'>
                    {/*      <Grid item xs={12} className='font-size-xsmall portfolioAssetType'>
                        Portfolio By Asset Type
                    </Grid>
                    <Grid item container xs={12} className='portfolio-row'>
                        <Grid item xs={5}>
                           Equity
                        </Grid>
                        <Grid item xs={6} sx={textalignright}>
                           70% 
                        </Grid>
                    </Grid>
                      <hr style={linestyle}/>
                    <Grid item container xs={12} >
                        <Grid item xs={5}>
                            Credit
                        </Grid>
                        <Grid item xs={6} sx={textalignright}>
                             30%
                        </Grid>
                    </Grid>
                          <hr style={linestyle}/> */}
                    <Grid item xs={12} className='font-size-xsmall portfolioAssetType'>
                        Fund Metrics
                    </Grid>
                    <Grid item container xs={12} >
                        <Grid item xs={5}>
                            Investment Strategy
                        </Grid>
                        <Grid item xs={6} sx={textalignright}>
                            {/* {fundDetails.vintage ? fundDetails.vintage : 2025} */}
                            {fundDetails?.strategyName}
                        </Grid>
                    </Grid>
                    <hr style={linestyle} />
                    <Grid item container xs={12} >
                        <Grid item xs={5}>
                            Vintage Year
                        </Grid>
                        <Grid item xs={6} sx={textalignright}>
                            {/* {fundDetails.vintage ? fundDetails.vintage : 2025} */}
                            {fundDetails?.vintage}
                        </Grid>
                    </Grid>
                    <hr style={linestyle} />
                    <Grid item container xs={12} >
                        <Grid item xs={5}>
                            Target Raise
                        </Grid>
                        <Grid item xs={6} sx={textalignright}>
                            {ConvertToUSCurrency(fundDetails.fundSize ?? 0)}
                        </Grid>
                    </Grid>
                    <hr style={linestyle} />
                    <Grid item container xs={12} >
                        <Grid item xs={5}>
                            Capital Commited
                        </Grid>
                        <Grid item xs={6} sx={textalignright}>
                            {ConvertToUSCurrency(fundDetails.capitalCommitted ?? 0)}
                        </Grid>
                    </Grid>
                    <hr style={linestyle} />
                    <Grid item container xs={12} >
                        <Grid item xs={5}>
                            Capital Called
                        </Grid>
                        <Grid item xs={6} sx={textalignright}>
                            {ConvertToUSCurrency(fundDetails.capitalCalled ?? 0)}
                        </Grid>
                    </Grid>
                    <hr style={linestyle} />
                    <Grid item container xs={12}>
                        <Grid item xs={5}>
                            Number of Investments
                        </Grid>
                        <Grid item xs={6} sx={textalignright}>
                            {fundDetails.portfolioCompaniesCount ?? 0}
                        </Grid>
                    </Grid>
                    <hr style={linestyle} />
                    <Grid item container xs={12}>
                        <Grid item xs={5}>
                            Target Industry / Sector
                        </Grid>
                        <Grid item xs={6} sx={textalignright}>
                            {fundDetails.targetIndustry}
                        </Grid>
                    </Grid>
                    <hr style={lastHrStyle} />
                </Grid>
            </Grid></> : 'loading..'
            }
        </div>
    )
}

export default FundDetailStatusBox