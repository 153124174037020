
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { DataGrid,GridColumnMenu, GridToolbar } from '@mui/x-data-grid';
import { Box } from "@mui/material";
import { ArrowDropDown , ArrowDropUp} from "@mui/icons-material"
import '../MUICustomDataGridStyles.css'; 
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

const AIPDataGrid = ({ columns, 
                       rows, 
                       handleRowClick, 
                       onRowsSelectionHandler, 
                       checkboxSelection = false, 
                       disableMultipleRowSelection = false,
                       initiallySelectedRows,
                       isRowSelectable,
                       disableRowSelectionOnClick,
                       noRowsMessage, 
                       onCellClick,
                       fundperformanceheader,
                       sortByLatestFirst,
                       showCustomToolBar = false,
                       csvFileName = ""
                      }) => {
        let sortedRows = sortByLatestFirst === false ? rows : [...rows]?.sort((a, b) => b.id - a.id);
        const muiTheme = createTheme({
            typography: {
                fontFamily: 'Cresta, Normal',
               
            },
            palette: {
                background: {
                    default: '#f5f5f5',
                    paper: '#ffffff',
                },
            },
        });

        
const CustomColumnMenu = (props) => {
  return (
  
    <GridColumnMenu 
    sx={{   marginTop: '30px' }}
      {...props}
      slots={{
        columnMenuColumnsItem: null,
        columnMenuSortItem: null,
      }}
    />
  );
};
const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
    <Box sx={{ flexGrow: 1 }} />
    <GridToolbarExport
      slotProps={{
        tooltip: { title: 'Export data' },
        button: { variant: 'outlined' },
      }}
      printOptions={{ disableToolbarButton: true }} 
      csvOptions={{ fileName: csvFileName }}
    />
  </GridToolbarContainer>
  );
}

    return (
        <Box sx={{ height: '100%', width: '100%', top: 50}}>
            <ThemeProvider theme={muiTheme}>
                <DataGrid 
                    rows={sortedRows}
                    columns={columns}
                    rowHeight={39}
                    hideFooterPagination={true}
                    disableColumnMenu={false}
                    hideFooter={true}
                    onRowClick={handleRowClick}
                    Tooltip
                    sx={{
                        '.MuiDataGrid-iconButtonContainer': {
                            visibility: 'visible',
                        },
                        '.MuiDataGrid-sortIcon': {
                            opacity: 'inherit !important',
                        },
                        '.MuiTableSortLabel-icon': {
                            opacity: 0,
                        },
                        '.MuiDataGrid-columnHeaderTitle': {

                            fontFamily: 'var(--font-cresta-normal)'
                        },
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                            width: '0.1em',
                          },
                          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
                            background: '#f1f1f1',
                          },
                          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                            backgroundColor: '#1F61C5',
                            border: '4px solid #1F61C5', 
                            borderRadius: '8px',
                          },
                          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
                            background: '#555',
                          },
                        
                       
                    }}
                    rowSelectionModel={initiallySelectedRows}
                    checkboxSelection={checkboxSelection}
                    disableMultipleRowSelection={true}
                    disableRowSelectionOnClick={disableRowSelectionOnClick}
                    isRowSelectable={isRowSelectable}
                    onCellClick={onCellClick}
                    onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                    slots={{
                      columnSortedDescendingIcon: ArrowDropDown,
                      columnSortedAscendingIcon: ArrowDropUp,
                      columnMenu: CustomColumnMenu,
                      toolbar: showCustomToolBar ? CustomToolbar : "",
                    }}
                    columnHeaderHeight={fundperformanceheader ? 0 : 40}
                    localeText={{ noRowsLabel: noRowsMessage ? noRowsMessage : "No Data" }}
                />
            </ThemeProvider>
        </Box>
    );
};

export default AIPDataGrid;