import React, { useState, useEffect } from 'react';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import AIPDataGrid from '../../common/DataGrid/AIPDataGrid';
import { getUsers, reSendEmailNotificationToUser } from '../Services/TabsData';
import { ToastContainer, toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
import { AppDashboards, FeatureAccess, RolesAndPermissions } from '../../../utils/enum';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddUser from '../popups/AddUser';

const Users = (props) => {

  //#region props
  const { appRoles } = props;

  //#region variables
  const [usersData, setUsersData] = useState([]);
  const [open,setOpen] = useState(false);

  //#region api get calls
  const getAllUsers = async () => {
    const data = await getUsers();
    if (data?.responseCode == 200) {
       
      setUsersData(data?.responseData?.filter(a => a.userRoleName !== AppDashboards.INV_DASHBOARD));
    } else {
      toast.warning(data?.responseData,
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
  }

  //#region api post calls
  const handleReSendClick = async (user) => {
    const data = await reSendEmailNotificationToUser(user);
    if (data.responseCode === 200) {
      toast.success("Email sent successfully",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });

    } else {
      toast.warning("Email sent failed",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }

  }

  //#region grid columns
  const Columns = [
    {
      id: 1,
      field: 'userFullName',
      headerName: 'USER NAME',
      width: 300,
    },
    {
      id: 2,
      field: 'userRoleName',
      headerName: 'ROLE NAME',
      width: 300,
    },
    {
      id: 3,
      field: 'userEmailAddress',
      headerName: 'EMAIL ADDRESS',
      width: 300,
    },
    {
      id: 4,
      field: '',
      headerName: 'ACTIONS',
      width: 150,
      renderCell: (params) => {
        if (params.row.ROLENAME !== "Investor" && !params.row.isRegistrationCompleted) {
          return (
            <ForwardToInboxIcon
              sx={{ cursor: 'pointer' }}
              onClick={() => handleReSendClick(params.row)}
            />
          );
        }
        return null;
      },
    },
  ];

  //#region click events
  const handleAddUserClick = () => {
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
  }

  //#region useEffect
  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <div className='flexspacebetween'>
      <div className='space-between'>
        <div>
        <h4>Users</h4>
        </div>
       
        <div>
          <Button
            variant="outlined"
            className='btn-primary'
            onClick={()=>{handleAddUserClick()}}>
            <AddIcon/>
            ADD USER
          </Button>
        </div>
       
      </div>
      <div className='white-card margin-top-5 height-400-scroll'>
        <AIPDataGrid 
          columns={Columns} 
          rows={usersData?.map((user) => {
            return {
              id: user.userId, ...user
            }
          }) || []}
          onRowsSelectionHandler={() => { }}/>
      </div>
        {
          open && <AddUser open={open} rolesDropDown={appRoles} onClose={handleClose} getAllUsers={getAllUsers}/>
        }
      <ToastContainer />
    </div>
  );
};

export default Users;
