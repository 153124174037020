import React, { useEffect, useState } from 'react'
import { Grid, MenuItem, TextField } from '@mui/material';
import secureLocalStorage from 'react-secure-storage';
import SelectField from '../../common/input-fields/SelectField';
import { yearsDropDownValues } from '../../common/FundPerformance/apiDataTemplate';
import { getFundTransactions, getInvestmentRelatedTransactions, getInvestorTransactions } from '../../common/FundTransactions/services';
import { FieldVariants } from '../../common/TextInput/appInputenum';
import CurrencyIndicator from '../../common/DataGrid/CurrencyIndicator';
import AIPDataGrid from '../../common/DataGrid/AIPDataGrid';
import { transactionTypesEnum } from '../services/apiDataTemplate';

const InvestorTransactionsGrid = (props) => {

  //#region props
  const { investorID,type,gridHeight=500 } = props; 

  //#region initialdata
  const filterInitialData= {
    'selectedYear': 2024,
  }

  //#region variables
  const [filtersData,setFiltersData] = useState(filterInitialData);
  const [investorTransactions,setInvestorTransactions] = useState([]);
  const AccountTransactionColums = [
    {
      "id": 1,
      "field": "transactionType",
      "headerName": "Type",
      "flex": 1
    },
    {
      "id": 2,
      "field": "fundName",
      "headerName": "Name",
      "flex": 1
    },
    {
      "id": 3,
      "field": "investorName",
      "headerName": "Investor Name",
      "flex": 1
    },
    {
      "id": 4,
      "field": "amount",
      "headerName": "Amount",
      "flex": 1,
      renderCell: (params) => {
        return <CurrencyIndicator params={params} />
      },
    },
    {
        "id": 4,
        "field": "transactionDate",
        "headerName": "Date",
        "flex": 1
      }
  ]

  //#region change events
  const handleFiltersChange = (name,value) => {
    setFiltersData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
  }

  //#region api get calls
  const getTransactions = async (selectedYear) => {
        let data = "";
        const transactionType= "All";
        const fundID= secureLocalStorage.getItem("FundId");
        if(type === transactionTypesEnum.INVESTOR_TRANSACTIONS){
          data = await getInvestorTransactions(transactionType,investorID,selectedYear);
        }
        else{
          data = await getInvestmentRelatedTransactions(transactionType,investorID,selectedYear,fundID);
        }
        if (data.responseCode === 200) {
            setInvestorTransactions(data.responseData);
        }
        else {
            //console.log('unable to fetch data)
        }
  }

  //#region useeffect
  useEffect(()=>{
    getTransactions(filtersData.selectedYear);
  },[filtersData])

  //#region return
  return (
    <Grid container className='space-between'>
            <Grid item>
            <h6>Account Transactions</h6>
            </Grid>
            <Grid item className='fundTrans-selectors'>
               <SelectField 
                  name="selectedYear"
                  label="Year"
                  variant={FieldVariants?.OUTLINED}
                  value={filtersData?.selectedYear}
                  options={yearsDropDownValues}
                  onChange={(name, value) => handleFiltersChange(name, value)} />
            </Grid>
        
        <div style={{height : gridHeight,width : "100%"}}>
            <AIPDataGrid
                columns={AccountTransactionColums}
                rows={investorTransactions?.length > 0 ?
                        investorTransactions?.map((item,index) => {
                           return{
                            id: index,
                            ...item
                           }
                        })  
                        : []} />
        </div>
    </Grid>
  )
}

export default InvestorTransactionsGrid