import axios from 'axios';
import resolve from '../../../common/resolve';
import secureLocalStorage from 'react-secure-storage';
import axiosApiCall from '../../../Services/axiosApiCall';
import { getAPIResponse } from '../../../../utils/common';

export async function getQuestionBank(investorType) {
    return await axiosApiCall.get(`/v1/QuestionBank/GetQuestionBank?questionType=${encodeURIComponent(investorType)}`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function updateOnboardingQuestions(formDataBody) {
    return await axiosApiCall.put(`/v1/QuestionBank/UpdateOnboardingQuestions?createdUser=${secureLocalStorage.getItem("userId")}`, formDataBody)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function getOnbordingQuestionPatterns() {
    return await axiosApiCall.get(`/v1/MasterData/GetListItemsByType?listType=OnboardingQuestionPatterns`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}
