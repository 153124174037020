import React from "react";
import { Box } from "@mui/system";
import Grid from "@mui/system/Unstable_Grid/Grid";
import MiniDrawer from "../common/navigation-bar/NavBarGrid";
import { getrootBackgroundColorConfig } from "../../state/slices/colorConfigSlice";
import { useSelector } from "react-redux";
import secureLocalStorage from "react-secure-storage";
import { useState } from "react";
import StackView from "../common/StackView/StackView";
import { useNavigate } from "react-router-dom";
import { CurrencyFormatter } from "../../utils/Formater";
import { useEffect } from "react";
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';

const ComplianceOfficer2 = () => {
  const navigate = useNavigate();
  const [allinvestors, setAllInvestors] = useState();
  const [approvalUserDetails, setApprovalUserDetails] = useState([]);


  const [investorProfile, setInvestorProfile] = useState({});
  const firstName = (investorProfile?.personalInformation?.firstName ?? "").charAt(0).toUpperCase() + (investorProfile?.personalInformation?.firstName ?? "").slice(1);
  const lastName = (investorProfile?.personalInformation?.lastName ?? "").charAt(0).toUpperCase() + (investorProfile?.personalInformation?.lastName ?? "").slice(1);
  const fullName = `${firstName} ${lastName}`;
  secureLocalStorage.setItem("fullName", fullName);

  const onCustomerClickstatus = (params) => {
    navigate("/compliance1", { state: { selectedRow: params.row } });
  };

  const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);
  const onCustomerClick = (params) => {
    console.log(params.row);
    navigate("/compliance1", { state: { selectedRow: params.row } });
  };
  const customCellRenderer = (params) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{
        width: 28,
        height: 28,
        borderRadius: "50%",
        background: "lightgray",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: 8,
      }}>
        <img
          src="/icons/icon-awesomebuilding.svg"
          alt="Image"
          style={{ width: 12 }}
        />
      </div>
      {params.value}
    </div>
  );

 
  const NewInvestorsColumns = [
    {
      "id": 1,
      "field": "investor",
      "headerName": "Name",
      "width": 270,
      renderCell: customCellRenderer,
    },
    {
      "id": 2,
      "field": "investorType",
      "headerName": "Type",
      "width": 200
    },
    {
      "id": 3,
      "field": "fundName",
      "headerName": "FundName",
      "width": 300
    },
    {
      "id": 4,
      "field": "subscribed",
      "headerName": "Amount",
      "width": 150
    },
    {
      "id": 5,
      "field": "subscriptionStatus",
      "headerName": "Subscription Status",
      "width": 180
    },
   ]

   const conditionalColumn = {
    "id": 6,
    "field": "subscriptionType",
    "headerName": "Subscription Type",
    "width": 180
  };

  const showSubscriptionTypeColumn = true;

  // const InvestorsColumns = [
  //   {
  //     "id": 1,
  //     "field": "investor",
  //     "headerName": "Name",
  //     "width": 270,
  //     renderCell: customCellRenderer,
  //   },
  //   {
  //     "id": 2,
  //     "field": "investorType",
  //     "headerName": "Type",
  //     "width": 200
  //   },
  //   {
  //     "id": 3,
  //     "field": "fundName",
  //     "headerName": "FundName",
  //     "width": 300
  //   },
  //   {
  //     "id": 4,
  //     "field": "subscribed",
  //     "headerName": "Amount",
  //     "width": 150
  //   },
  //   {
  //     "id": 5,
  //     "field": "subscriptionStatus",
  //     "headerName": "Subscription Status",
  //     "width": 180
  //   },
  //   {
  //     "id": 6,
  //     "field": "subscriptionType",
  //     "headerName": "Subscription Type",
  //     "width": 180
  //   }
  // ]


  const UsersKycAmlColumns = [
    {
      "id": 1,
      "field": "name",
      "headerName": "Name",
      "width": 200
    },
    {
      "id": 2,
      "field": "type",
      "headerName": "Type",
      "width": 200
    },
    {
      "id": 3,
      "field": "kycStatus",
      "headerName": "KYC Status",
      "width": 300
    },
    {
      "id": 4,
      "field": "amlStatus",
      "headerName": "AML Status",
      "width": 300
    }
  ]


  const fetchDataforAllFunds = async () => {
  
  };

  const fetchKycAmlUserApprovalDetails = async () => {
   
  };


  useEffect(() => {
    fetchDataforAllFunds();
    fetchKycAmlUserApprovalDetails();
  }, []);

  const UserPendingKYCAMLData = approvalUserDetails?.map((user) => ({
    id: user.userId,
    name: user.name,
    type: user.type,
    kycStatus: user.kycStatus,
    amlStatus: user.amlStatus,
  })) ?? [];

  const AllInvestorsRowsData = allinvestors
    ? allinvestors
      .filter((fund) => fund.subscriptionStatus !== "Approved" && fund.subscriptionStatus !== "Rejected" && fund.isOfflineSubscription === false)
      .map((fund) => ({
        id: fund.subscriptionsID,
        investor: fund.investor,
        investorType: fund.investorType ?? "Individual Investor",
        fundName: fund.fundName,
        subscribed: CurrencyFormatter(fund.subscribed),
        subscriptionStatus: fund.subscriptionStatus,
        subscriptionID: fund.subscriptionsID,
        fundID: fund.fundID,
        investorID: fund.investorID,
        isOfflineSubscription: fund.isOfflineSubscription,
        subscriptionType: fund.isOfflineSubscription ? "Offline" : "Online",
        offlineFile: fund.offlineFile,
        offlineFilePath: fund.offlineFilePath,
        fundLogo: fund.fundLogo,
        userProfileIcon: fund.userProfileIcon
      }))
    : [];

  const AllInvestorsApprovedData = allinvestors
    ? allinvestors
      .filter((fund) => fund.subscriptionStatus === "Approved" && fund.isOfflineSubscription === false)
      .map((fund) => ({
        id: fund.subscriptionsID,
        investor: fund.investor,
        investorType: fund.investorType ?? "Individual Investor",
        fundName: fund.fundName,
        subscribed: CurrencyFormatter(fund.subscribed),
        subscriptionStatus: fund.subscriptionStatus,
        subscriptionID: fund.subscriptionID,
        fundID: fund.fundID,
        investorID: fund.investorID,
        isOfflineSubscription: fund.isOfflineSubscription,
        offlineFile: fund.offlineFile,
        offlineFilePath: fund.offlineFilePath,
        fundLogo: fund.fundLogo,
        userProfileIcon: fund.userProfileIcon
      }))
    : [];

  const AllInvestorsRejectedData = allinvestors
    ? allinvestors
      .filter((fund) => fund.subscriptionStatus === "Rejected" || fund.subscriptionStatus === "Cancelled" && fund.isOfflineSubscription === false)
      .map((fund) => ({
        id: fund.subscriptionsID,
        investor: fund.investor,
        investorType: fund.investorType ?? "Individual Investor",
        fundName: fund.fundName,
        subscribed: CurrencyFormatter(fund.subscribed),
        subscriptionStatus: fund.subscriptionStatus,
        subscriptionID: fund.subscriptionID,
        fundID: fund.fundID,
        investorID: fund.investorID,
        isOfflineSubscription: fund.isOfflineSubscription,
        offlineFile: fund.offlineFile,
        offlineFilePath: fund.offlineFilePath,
        fundLogo: fund.fundLogo,
        userProfileIcon: fund.userProfileIcon
      }))
    : [];
  return (
    <div style={{ backgroundColor: rootBackColorConfig.color }} className="wrapper3" >
      <Box sx={{ flexGrow: 1 }} >
        <Grid container>
          <Grid container>
            <Grid item container xs={12} className="font-size-xsmall font-size-color welcomeuser">
              Welcome Back, {secureLocalStorage.getItem("fullName") ?? fullName}
            </Grid>
            <h1>
              Compliance Officer Dashboard
            </h1>
          </Grid>
          <Grid item container xs={12} padding={2} marginTop={'10px'} className="investorgrid">
            <Grid item xs={12} md={12} lg={12}>
              <b>Investor List​</b>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div>
                <StackView
                  columns={showSubscriptionTypeColumn ? [...NewInvestorsColumns, conditionalColumn] : NewInvestorsColumns}
                  rows={AllInvestorsRowsData}
                  handleRowClick={onCustomerClickstatus}
                  onRowsSelectionHandler={() => { }}
                />
              </div>
            </Grid>
          </Grid>
          <Grid item container xs={12} padding={2} marginTop={'10px'} className="investorgrid">
            <Grid item xs={12} >
              <b>Approved</b>
            </Grid>
            <Grid item xs={12}>
              <div>
                <StackView
                  columns={NewInvestorsColumns}
                  rows={AllInvestorsApprovedData}
                  handleRowClick={onCustomerClick}
                  onRowsSelectionHandler={() => { }}
                />
              </div>
            </Grid>
          </Grid>
          <Grid item container xs={12} padding={2} marginTop={'10px'} className="investorgrid">
            <Grid item xs={12}>
              <b>Rejected</b>
            </Grid>
            <Grid item xs={12}>
              <div>
                <StackView
                  columns={NewInvestorsColumns}
                  rows={AllInvestorsRejectedData}
                  handleRowClick={onCustomerClick}
                  onRowsSelectionHandler={() => { }}
                />
              </div>
            </Grid>
          </Grid>
          <Grid item container xs={12} padding={2} marginTop={'10px'} className="investorgrid" >
            <Grid item xs={12}>
              <b> KYC/AML Pending List</b>
            </Grid>
            <Grid item xs={12}>
              <div>
                <StackView
                  columns={UsersKycAmlColumns}
                  rows={UserPendingKYCAMLData}
                  handleRowClick={() => { }}
                  onRowsSelectionHandler={() => { }}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default ComplianceOfficer2;
