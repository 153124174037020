import { FunctionComponent, useCallback } from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./FundDetailInvestorRelatio.module.css";
import AIPDataGrid from "../../common/DataGrid/AIPDataGrid";
import jsondata from './FundDetailInvestorRelations.json';
import secureLocalStorage from "react-secure-storage";
import CurrencyIndicator from "../../common/DataGrid/CurrencyIndicator";
import { TableCell, Typography } from "@mui/material";
import DashboardSelect2 from "../../common/Select/DashboardSelect2";
import { checkFeaturePermissions } from "../../../utils/common";
import { FeatureAccess, RolesAndPermissions } from "../../../utils/enum";
import ContextMenu from "../../common/ContextMenu/ContextMenu";
import moment from "moment";
import Checkbox from '@mui/material/Checkbox';
import { Grid } from '@mui/material';
import { getFundSubscriptions } from "../services/DashboardService";

const FundDetailInvestorRelatio = () => {
  const navigate = useNavigate();
  const [isExpanded, setExpendState] = useState(false)
  const [investortab, setinvestortab] = useState(true);
  const [showRejected, setShowRejected] = useState(false);

  const fundID = secureLocalStorage.getItem("FundId");
  const [selectedRow, setSelectedRow] = useState(null);

  const [ccdata, setccData] = useState();

  const getSubscribers = async (showRejected) => {
    const data = await getFundSubscriptions(fundID);
    if (data.responseCode === 200) {
      const subs = showRejected === true
        ? data.responseData.filter(u => u.subscriptionStatus === "Rejected")
        : data.responseData.filter(u => u.subscriptionStatus !== "Pending Review" && u.subscriptionStatus !== "Rejected" && u.subscriptionStatus !== "Cancelled")
      setccData(subs)
    }
    else {

    }
  }

  useEffect(() => {
    getSubscribers(showRejected);
  }, [showRejected]);



  const ccgridrows = ccdata
    ?
    // showRejected 
    // ? ccdata.filter((ccrows) => (ccrows.subscriptionStatus === "Rejected" || ccrows.subscriptionStatus === "Cancelled"))
    // .map((ccrows) => ({
    //   id: ccrows.subscriptionsID,
    //   investorID: ccrows.investorID,
    //   fundID: ccrows.fundID,
    //   subscriptionsID: ccrows.subscriptionsID,
    //   investor: ccrows.investor,
    //   subscriptionStatus: ccrows.subscriptionStatus,
    //   TYPE: ccrows.investorType === 0 || ccrows.investorType === "0"  ? '-' : ccrows.investorType ,
    //   subscribed: ccrows.subscribed,
    //   "CALL AMOUNT": 0,
    //   CONTRIBUTED: ccrows.contributed,
    //   UNFUNDED: ccrows.unFunded,
    //   NAV: ccrows.nav,
    //   DISTRIBUTIONS: ccrows.distributions,
    //   OWNERSHIP: ccrows.ccOwnership,
    //   RECEIVED: ccrows.received,
    //   FEE: ccrows.fee,
    //   CARRY: ccrows.carry,
    //   UPDATE: moment(ccrows.updatedDate).format("MM-DD-YYYY"),
    //   isOfflineSubscription: ccrows.isOfflineSubscription,
    //   "DATA RECEIVED": ccrows.isOfflineSubscription ? 0 : ccrows.dataReceived,
    //   field: "subscriptionTYPE",
    //   headerName: "SUBSCRIPTION TYPE",
    //   investorType: ccrows.userInvestorType,
    //   width: 110,
    //   // subscriptionTYPE: ccrows.isOfflineSubscription ? "Online" : "Offline",
    //   subscriptionTYPE: ccrows.subscriptionStatus === "Approved" ||
    //     ccrows.subscriptionStatus === "Rejected"
    //     ? "-"
    //     : ccrows.isOfflineSubscription
    //       ? "Offline"
    //       : "Online",
    // }))
    // : //.filter((ccrows) => ccrows.subscriptionStatus !== "Pending Review" && ccrows.subscriptionStatus !== "Rejected" && ccrows.subscriptionStatus !== "Cancelled")
    ccdata.filter(u => u.subscriptionStatus !== 'Awaiting Online Processing')
      .map((ccrows) => ({
        id: ccrows.subscriptionsID,
        investorID: ccrows.investorID,
        fundID: ccrows.fundID,
        subscriptionsID: ccrows.subscriptionsID,
        investor: ccrows.investor,
        subscriptionStatus: ccrows.subscriptionStatus.includes('Under Compliance Review') ? 'Under Review' : ccrows.subscriptionStatus,
        TYPE: ccrows.investorType === 0 || ccrows.investorType === "0" ? '-' : ccrows.investorType,
        subscribed: ccrows.subscribed,
        "CALL AMOUNT": 0,
        CONTRIBUTED: ccrows.contributed,
        UNFUNDED: ccrows.unFunded,
        NAV: ccrows.nav,
        DISTRIBUTIONS: ccrows.distributions,
        OWNERSHIP: ccrows.ccOwnership,
        RECEIVED: ccrows.received,
        FEE: ccrows.fee,
        CARRY: ccrows.carry,
        UPDATE: moment(ccrows.updatedDate).format("MM-DD-YYYY"),
        isInvestorVerified: ccrows.isInvestorVerified,
        isOfflineSubscription: ccrows.isOfflineSubscription,
        "DATA RECEIVED": ccrows.isOfflineSubscription ? 0 : ccrows.dataReceived,
        field: "subscriptionTYPE",
        headerName: "SUBSCRIPTION TYPE",
        investorType: ccrows.userInvestorType,
        width: 110,
        // subscriptionTYPE: ccrows.isOfflineSubscription ? "Online" : "Offline",
        subscriptionTYPE: ccrows.subscriptionStatus === "Approved" ||
          ccrows.subscriptionStatus === "Rejected"
          ? "-"
          : ccrows.isOfflineSubscription
            ? "Offline"
            : "Online",
      }))
    : [];


  const handleStatusNavigation = (STATUS, params) => {
    if(STATUS !== 'Rejected'){
      if (STATUS === "Approved") {
        secureLocalStorage.setItem("FundId", params?.row?.fundID);
        secureLocalStorage.setItem("investorId", params?.row?.investorID);
        navigate("/Investor-Overview-Page",
          { state: { userId: { itemId: params?.row?.investorID, fullName: params?.row?.investor } } });
      } else {
        navigate("/subscription-details", { state: { FundId: { itemId: params?.row?.fundID }, subscriptionId: { subscriptionID: params.row.subscriptionsID }, status : {status:params.row.subscriptionStatus}, selectedRow: params.row } });
      }
    }
  }

  const [selectedLPCommunicationYear, setSelectedLPCommunicationYear] = useState('');
  const [selectedFundraisingYear, setSelectedFundraisingYear] = useState('');
  const [selectedInvestorMeetingsYear, setSelectedInvestorMeetingsYear] = useState('');

  const years = [
    { id: 1, label: '2020', value: 2020 },
    { id: 2, label: '2021', value: 2021 },
    { id: 3, label: '2022', value: 2022 },
    { id: 4, label: '2023', value: 2023 },
  ];
  const handleFundraisingStatus = (year) => {
    setSelectedFundraisingYear(year);
  };
  const handleInvestorMeetings = (year) => {
    setSelectedInvestorMeetingsYear(year);
  };
  const handleLPCommunication = (year) => {
    setSelectedLPCommunicationYear(year);
  };

  const MenuItemsBottom = [
  ]


  const creditTypeColumns = [
    {

      "id": 1,

      "field": "investor",

      "headerName": "INVESTOR NAME",

      "width": 150

    },
    {

      "id": 2,

      "field": "subscriptionStatus",

      "headerName": "STATUS",

      "width": 150,
      renderCell: (params) => {
        return (
          <TableCell onClick={() => handleStatusNavigation(params.value, params)}>
            {params.value}
          </TableCell>
        );
      }

    },
    {

      "id": 3,

      "field": "TYPE",

      "headerName": "TYPE",

      "width": 110

    },
    {

      "id": 4,

      "field": "investorType",

      "headerName": "INVESTOR TYPE",

      "width": 110

    },
    {

      "id": 16,

      "field": "subscriptionTYPE",

      "headerName": "SUBSCRIPTION",

      "width": 110

    },
    {

      "id": 5,

      "field": "subscribed",

      "headerName": "INVESTED",

      "width": 110,
      renderCell: (params) => {
        return <CurrencyIndicator params={params} />
      }

    },
    {

      "id": 8,

      "field": "NAV",

      "headerName": "NAV",

      "width": 90

    },
    {

      "id": 9,

      "field": "DISTRIBUTIONS",

      "headerName": "DISTRIBUTIONS",

      "width": 110

    },
    {

      "id": 10,

      "field": "OWNERSHIP",

      "headerName": "OWNERSHIP",

      "width": 90

    },
    {

      "id": 11,

      "field": "RECEIVED",

      "headerName": "RECEIVED",

      "width": 90

    },
    {

      "id": 12,

      "field": "FEE",

      "headerName": "FEE",

      "width": 70

    },
    {

      "id": 13,

      "field": "CARRY",

      "headerName": "CARRY",

      "width": 90

    },
    {

      "id": 14,

      "field": "UPDATE",

      "headerName": "UPDATE",

      "width": 110

    },
    {

      "id": 15,

      "field": "ACTIONS",

      "headerName": "ACTIONS",

      "width": 110,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {params.row.TYPE === 'Action' ? <ContextMenu /> : '-'}
          </div>
        );
      },



    },
  ]

  const RelColumns = [
    {

      "id": 1,

      "field": "investor",

      "headerName": "INVESTOR NAME",

      "width": 150

    },
    {

      "id": 2,

      "field": "subscriptionStatus",

      "headerName": "STATUS",

      "width": 150,
      renderCell: (params) => {
        return (
          <TableCell onClick={() => handleStatusNavigation(params.value, params)}>
            {params.value}
          </TableCell>
        );
      }

    },
    {

      "id": 3,

      "field": "TYPE",

      "headerName": "TYPE",

      "width": 110

    },
    {

      "id": 4,

      "field": "investorType",

      "headerName": "INVESTOR TYPE",

      "width": 110

    },
    {

      "id": 16,

      "field": "subscriptionTYPE",

      "headerName": "SUBSCRIPTION TYPE",

      "width": 110

    },
    {

      "id": 5,

      "field": "subscribed",

      "headerName": "SUBSCRIBED",

      "width": 110,
      renderCell: (params) => {
        return <CurrencyIndicator params={params} />
      }

    },
    {

      "id": 6,

      "field": "CONTRIBUTED",

      "headerName": "CONTRIBUTED",

      "width": 120

    },
    {

      "id": 7,

      "field": "UNFUNDED",

      "headerName": "UNFUNDED",

      "width": 100

    },
    {

      "id": 8,

      "field": "NAV",

      "headerName": "NAV",

      "width": 90

    },
    {

      "id": 9,

      "field": "DISTRIBUTIONS",

      "headerName": "DISTRIBUTIONS",

      "width": 110

    },
    {

      "id": 10,

      "field": "OWNERSHIP",

      "headerName": "OWNERSHIP",

      "width": 90

    },
    {

      "id": 11,

      "field": "RECEIVED",

      "headerName": "RECEIVED",

      "width": 90

    },
    {

      "id": 12,

      "field": "FEE",

      "headerName": "FEE",

      "width": 70

    },
    {

      "id": 13,

      "field": "CARRY",

      "headerName": "CARRY",

      "width": 90

    },
    {

      "id": 14,

      "field": "UPDATE",

      "headerName": "UPDATE",

      "width": 110

    },
    {

      "id": 15,

      "field": "ACTIONS",

      "headerName": "ACTIONS",

      "width": 110,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {params.row.TYPE === 'Action' ? <ContextMenu /> : '-'}
          </div>
        );
      },



    },


  ]
  return (
    // <div className={styles.fundDetailInvestorRelatio}>
    <div>
      <h4>Investor Relations </h4>

      <Grid container spacing={2} marginTop={1}>
        <Grid item xs='12' sm='12' md='6' lg='6'>
          <div className="whiteCard">
            <div className="space-between">
              <h6>Fundraising Status</h6>
              <DashboardSelect2
                options={years}
                type="years"
                label={'Year'}
                value={selectedFundraisingYear}
                onChange={handleFundraisingStatus} />
            </div>
            <div style={{ height: '95%' }}>
              <AIPDataGrid
                columns={jsondata.InvestorRelationsColumns}
                rows={jsondata.InvestorRelationsRows}
                onRowsSelectionHandler={() => { }} />
            </div>
          </div>
          {checkFeaturePermissions(RolesAndPermissions.LP_COMMUNICATION_AND_REPORTING, FeatureAccess.READ)
            &&
            <div className="whiteCard mt16">
              <div className="space-between">
                <h6>LP Communication and Reporting</h6>
                <DashboardSelect2
                  options={years}
                  type="years"
                  label={'Year'}
                  value={selectedLPCommunicationYear}
                  onChange={handleLPCommunication} />
              </div>
              <div style={{ height: '95%' }}>
                <AIPDataGrid
                  columns={jsondata.LPCommunicationColumns}
                  rows={jsondata.LPCommunicationRows}
                  onRowsSelectionHandler={() => { }} />
              </div>
            </div>
          }
        </Grid>
        {
          checkFeaturePermissions(RolesAndPermissions.INVESTOR_MEETINGS_AND_EVENTS, FeatureAccess.READ) &&
          <Grid item xs='12' sm='12' md='6' lg='6'>
            <div className={styles.fundPerfSectionCont}>
              <div className={styles.secondaryStatsWrapper}>
                <h6>Upcoming Investor Meetings and Events</h6>
                <DashboardSelect2
                  type="years"
                  options={years}
                  label={'Year'}
                  value={selectedInvestorMeetingsYear}
                  onChange={handleInvestorMeetings}
                />
              </div>
              <div style={{ height: '95%' }}>
                <AIPDataGrid
                  columns={jsondata.UpcomingInvestorColumns}
                  rows={jsondata.UpcomingInvestorRows}
                  onRowsSelectionHandler={() => { }} />
              </div>
            </div>
          </Grid>
        }
      </Grid>

      <div className="whiteCard mt16">
        <Grid container xs={12} md={12}>
          <Grid item xs={9} md={9}>
            <h6>Investors</h6>
          </Grid>
          <Grid item xs={3} md={3} container justifyContent="flex-end">
            <Checkbox className="mtmin5"
              checked={showRejected}
              onChange={() => setShowRejected(!showRejected)}
              color="primary"
              inputProps={{ 'aria-label': 'Show Rejected' }}
            />
            <span style={{ fontSize: '14px' }}>Show Rejected</span>
          </Grid>

        </Grid>
        <Grid container xs={12} md={12}>
          <Grid item xs={12} style={{ height: '400px', width: '1000px' }}>
            <AIPDataGrid
              columns={(secureLocalStorage.getItem('FundTypeName') === "Private Equity Fund" ? RelColumns : creditTypeColumns)}
              rows={ccgridrows}
              onRowsSelectionHandler={() => { }}
            />
          </Grid>
        </Grid>

      </div>
    </div>
  );
};

export default FundDetailInvestorRelatio;
