import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import resolve from '../../common/resolve';
import Configurations from '../../../Configurations';
import axiosApiCall from '../../Services/axiosApiCall';
import { getAPIResponse } from '../../../utils/common';
import { ToastContainer, toast } from 'react-toastify';

const header = { headers: { 'TenantID': secureLocalStorage.getItem("tenantID") } }

export async function getInvestorsList() {
    return await axiosApiCall.get(`/v1/UserRole/GetTenantUserDetails?roleDashboard=investor`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getUserList() {
    return await axiosApiCall.get(`/v1/UserRole/GetApplicationUserRoles?tenantID=${secureLocalStorage?.getItem("tenantID")}&roleDashboard=Fund Manager,PortFolio Company,Firm,Investor`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function UsersToShareVDRFolder() {
    return await axiosApiCall.get(`/v1/UserRole/GetApplicationUserRoles?tenantID=${secureLocalStorage?.getItem("tenantID")}&roleDashboard=Fund Manager,Investor,Legal Compliance Officer,PortFolio Company,Firm`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getTenantRoles(isSystemRole = 0) {
    return await axiosApiCall.get(`/v1/UserRole/GetTenantRoles?tenantID=${secureLocalStorage.getItem("tenantID")}&isSystemRole=${isSystemRole}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getInvestorsTypes(investorType) {
    return await axiosApiCall.get(`/v1/MasterData/GetListItemsByType?listType=${investorType}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getSubInvestorsTypes(investorTypeID) {
    return await axiosApiCall.get(`/v1/InvestorDetails/GetInvestorSubTypeForInvestorType?investorTypeID=${investorTypeID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getAllFunds() {
    return await axiosApiCall.get(`/v1/Fund/GetAllTenantFunds`, header)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getnonSubscribedInvestors(fundID,InvestorTypeID) {
    return await axiosApiCall.get(`/v1/InvestorDetails/GetNonSubscribedInvestorForFund?fundID=${fundID}&investorTypeID=${InvestorTypeID}`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}
export async function CreateNewInvestor(formDataBody) {
    return await axiosApiCall.post(`/v1/User/CreateTenantUser?tenantID=${secureLocalStorage.getItem("tenantID")}`, formDataBody)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function updateExcistingInvestor(formDataBody) {
    return await axiosApiCall.put(`/v1/InvestorDetails/MapInvestorsToFund`, formDataBody)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function SendEmailFromApp(data) {
    return await axiosApiCall.post(`/v1/EmailService/SendEmailFromApp`, data)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

//bulk upload services
export async function downloadBulkUploadTemplate() {
    return await axiosApiCall.get(`/v1/BulkUpload/DownloadBulkUploadInvestorDocument`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export function BulkUploadFilePosting(data) {
    try {
        const response = axios.post(`${Configurations.apiBaseUri}/v1/BulkUpload/BulkUploadInvestor?tenantID=${secureLocalStorage.getItem("tenantID")}`, data);
        return response;
    }
    catch (error) {
        return error
    }
}

export async function BulkUploadDataSave(data) {
    return await axiosApiCall.post(`/v1/BulkUpload/SaveBulkUploadInvestors?tenantID=${secureLocalStorage.getItem("tenantID")}`, data)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function nonNaturalBulkUploadPosting(data) {
    return await axiosApiCall.post(`/v1/BulkUpload/BulkUploadNonNaturalInvestor?tenantID=${secureLocalStorage.getItem("tenantID")}`, data)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function deleteDraftNotifications(notificationID) {
    return await axiosApiCall.delete(`/v1/AppNotifications/DeleteAppNotifications?appNotificationToUserID=${notificationID}&userID=0&deliveryStatus=0`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function deleteSentNotifications(notificationID) {
    return await axiosApiCall.delete(`/v1/AppNotifications/DeleteAppNotifications?appNotificationToUserID=${notificationID}&userID=0&deliveryStatus=1`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}


export function nonNaturalBulkUpload(data) {
    try {
        const response = axios.post(`${Configurations.apiBaseUri}/v1/BulkUpload/BulkUploadNonNaturalInvestor?tenantID=${secureLocalStorage.getItem("tenantID")}`, data);
        return response;
    }
    catch (error) {
        return error
    }
}

export async function getUserAppNotifications() {
    return await axiosApiCall.get(`/v1/AppNotifications/GetUserAppNotifications?notifiedUserID=${secureLocalStorage.getItem('userId')}&deliveryStatus=1`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getAppNotifications(crntFundID, crntInvestorid) {
    return await axiosApiCall.get(`/v1/AppNotifications/GetAppNotifications?FundID=${crntFundID}&InvestorID=${crntInvestorid}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}