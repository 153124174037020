import React from 'react'
import { Grid, Button } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import TextInput from '../../common/TextInput/TextInput';
import FirstComponent from '../../common/DatePicker/DatePicker';
import AIPDataGrid from '../../common/DataGrid/AIPDataGrid';
import { useCallback } from 'react';
// import jsondata from '../../dashboards/Distributions/DistributionsDetail.json'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import ActionButton from '../../common/ActionButton/ActionButton';
import DialogActions from '@mui/material/DialogActions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import secureLocalStorage from 'react-secure-storage';
//import { BoxAlignCenter } from '../../CommonCss';
import { BoxbackgroundWhite } from '../../CommonCss';
import { checkFeaturePermissions } from '../../../utils/common';
import { RolesAndPermissions } from '../../../utils/enum';
import { FeatureAccess } from '../../../utils/enum';
import { useState } from 'react';
import { formatAsUSACurrency } from '../../common/CurrencyFormater/formatAsUSACurrency';
import GridCurrencyFormater from '../../common/CurrencyFormater/GridCurrencyFormater';
//import CurrencyIndicator from '../../common/DataGrid/CurrencyIndicator';
//import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import { margin } from '@mui/system';
function AddingDistributions({ isEditing, handleEditClick, distributionDetails,
  setDistributiondeleteClick, handleSaveClick, distributionAmount, beforeDistribution,
  navBeforeDistribution, errors, onDelete, handleDistributionChange, handleNavDistributionChange,
  formData, DistributionInvestorRows, onRowsSelectionHandler, handleInputChange,
  showSave, showEdit, executedStatus, AddingDistributions, distributionAmountChange, beforeDistributionChange }) {

  const [distributionStatus, setDistributionStatus] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };
  const handleDeleteClick = () => {
    onDelete();
  }




  // const aligndistrubutioncenter = {
  //   marginLeft: '30px', marginRight: '20px',
  // }


  // useEffect(() => {
  //   console.log('distributionStatus:', distributionStatus);
  // }, [distributionStatus]);

  const DistributionsColumns = [

    {

      "id": 1,

      "field": "investor",

      "headerName": "INVESTORS",

      "width": 160

    },

    {

      "id": 2,

      "field": "investorType",

      "headerName": "TYPE",

      "width": 160

    },

    {

      "id": 3,

      "field": "ytdDistributionAmount",

      "headerName": "YTD DISTRIBUTION AMOUNT",

      "width": 160,
      renderCell: (params) => {
        return <GridCurrencyFormater params={params} />
      }

    },

    {

      "id": 4,

      "field": "subscribed",

      "headerName": "SUBSCRIBED",

      "width": 160,
      renderCell: (params) => {
        return <GridCurrencyFormater params={params} />
      }

    },

    {

      "id": 5,

      "field": "contribution",

      "headerName": "CONTRIBUTION",
      "width": 160,

      renderCell: (params) => {
        return <GridCurrencyFormater params={params} />
      }

    },

    {

      "id": 6,

      "field": "fdOwnership",

      "headerName": "OWNERSHIP",


      renderCell: (params) => {
        return (
          <div>
            %{params.row.fdOwnership}
          </div>
        );
      }


    },

    {

      "id": 7,

      "field": "currentDistributionAmount",

      "headerName": "CURRENT DISTRIBUTION AMOUNT",
      "width": 120,

      renderCell: (params) => {
        return <GridCurrencyFormater params={params} />
      }

    },

    {

      "id": 8,

      "field": "ifdStatus",

      "headerName": "STATUS",



    }

  ]


  const initiallySelectedRows = DistributionInvestorRows?.filter((row) => row?.fdOwnership > 0)?.map((row) => row?.id);

  const handleDeleteConfirmed = useCallback(() => {
    const secondApiData = distributionDetails;
    secondApiData.distributionStatus = 'Delete'
   

    closeDialog();
    toast.warning("Distribution has been Deleted", {
      position: toast.POSITION.TOP_RIGHT,
      theme: "colored",
    });

    setTimeout(() => {
      navigate("/marketplace/fund-detail-services-1");
    }, 2500); // 1000 milliseconds delay

  }, [navigate]);;


  return (
    <div>
      <Grid container xs={12} className='whiteCard'>
        <div className='height-750-noscroll'>
          <Grid container style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
            <Grid item>
              <div className='Capital-call-Active-Draft Capital-call-Active-Border'>
                <div className='Capital-call-Active-text'>
                  {formData.statusoftheDistribution === 0 ? "Draft" : formData.statusoftheDistribution === 2 ? "Active" : "Executed"}
                  {/* {formData?.statusoftheDistribution} */}
                  {/* {currentstatus} */}
                </div>
              </div>
            </Grid>
            <Grid style={{ display: 'flex', flexDirection: 'row' }}>

              <Grid item>
                {formData.statusoftheDistribution !== 0 && formData.statusoftheDistribution !== 3 &&
                  <Button className="btn-primary" variant='outlined' onClick={openDialog}>
                    <DeleteIcon />
                    Delete
                  </Button>}

              </Grid>
              <Dialog open={isDialogOpen} onClose={closeDialog}>
                <DialogTitle>Are you sure you want to delete this Distribution?</DialogTitle>
                <DialogActions>
                  <ActionButton label='Yes' onClick={handleDeleteConfirmed} />
                  <ActionButton variant='outlined' label='No' onClick={closeDialog} />
                  {/* <Button onClick={handleDeleteConfirmed} variant="contained" color="primary">
                          Yes
                        </Button>
                        <Button onClick={closeDialog} variant="contained" color="secondary">
                          No
                        </Button> */}
                </DialogActions>
              </Dialog>
              {formData.statusoftheDistribution !== 3 &&
                <Grid item>
                  {
                    checkFeaturePermissions(RolesAndPermissions.Distributions, FeatureAccess.UPDATE) &&
                    <div>
                      <div className='edit-text'>
                        {showSave &&
                          (
                            <Button onClick={handleSaveClick} className='btn-primary' variant='contained' >
                              <SaveIcon />
                              Save
                            </Button>
                          )
                        }
                        {showEdit &&
                          (
                            <Button onClick={handleEditClick} className='btn-primary' variant='contained'>
                              < EditIcon />
                              Edit
                            </Button>
                          )
                        }
                      </div>
                    </div>
                  }
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid xs={12} container style={{ margin: '20px 0px' }}>
            <Grid
              item
              sx={{
                border: (errors.distributionAmount ? '1px solid red' : '1px solid #C0CDD9'),
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                height: '107px',
                borderRadius: '8px',
                marginRight: '25px',
                width: '48.8%',
              }}
            >
              <div className='pricebox'>
                <Grid xs={12}>
                  <div className='ValueName'>
                    <span className="RequiredSymbol">
                      Total Distribution Amount
                    </span>
                  </div>
                </Grid>
                <Grid xs={12}>
                  <div className='valueInput'>
                    {showSave ? (
                      <input
                        type="text"
                        className="border-0 w-100"
                        name="distributionAmount"
                        placeholder="Distribution Amount"
                        // value={distributionAmount}
                        value={distributionAmount}
                        onChange={(event) => handleInputChange('distributionAmount', event.target.value)}


                      />
                    ) : (
                      <span>{formatAsUSACurrency(distributionAmount)}</span>
                    )}
                  </div>
                </Grid>
              </div>
            </Grid>
            <Grid
              item
              sx={{
                border: (errors.beforeDistribution ? '1px solid red' : '1px solid #C0CDD9'),
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                height: '107px',
                borderRadius: '8px',
                width: '49%',
              }}
            >
              <div className='pricebox'>
                <Grid xs={12}>
                  <div className='ValueName'>
                    <span className="RequiredSymbol">
                      NAV Before Distribution
                    </span>
                  </div>
                </Grid>
                <Grid xs={12}>
                  <div className='valueInput'>
                    {showSave ? (
                      <input
                        type="text"
                        className="border-0 w-100"
                        name="beforeDistribution"
                        placeholder="Before Distribution"
                        /* value={`${navBeforeDistribution}`} */
                        value={navBeforeDistribution}
                        onChange={(event) => handleInputChange('beforeDistribution', event.target.value)}

                      />
                    ) : (
                      <span>{formatAsUSACurrency(navBeforeDistribution)}</span>
                    )}
                  </div>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <div className={!showSave ? "disablediv" : "regularDiv"}>
            <Grid container xs={12} sx={{ marginTop: '30px' }}>
              <Grid item xs={3} style={{ marginLeft: '10px' }}>
                <FirstComponent
                  fieldLabel="Distribution Date" fieldName="distributionDate"
                  isSaveEnabled={handleEditClick}
                  defaultValue={formData.distributionDate}
                  variant='standard'
                  onhandleChange={(fieldName, value) => handleInputChange(fieldName, value)} fullWidth
                  errormessage={errors.distributionDate || undefined}
                />
              </Grid>
              <Grid xs={1}>
              </Grid>
              <Grid item xs={3} >
                {/* <TextInput label="Distribution Number/ID" name="distributionNumber/ID"
          defaultValue={formData.distributionNumber} 
          
        onChange={date => handleInputChange('distributionNumber/ID', date)}fullWidth */}
                <TextInput
                  label="Distribution Number/ID"
                  name="distributionNumber"
                  defaultValue={formData.distributionNumber}
                  onChange={(name, value) => handleInputChange(name, value)}
                  errormessage={errors.distributionNumber || undefined}
                />
              </Grid>
              <Grid xs={1}>
              </Grid>
              <Grid item xs={3}>
                <TextInput label="Currency" name="currency"
                  defaultValue={formData.currency}
                  type="select"
                  required={true}
                  options={[
                    { label: 'USD', value: 'USD' }]}
                  onChange={(name, value) => handleInputChange(name, value)}
                  errormessage={errors.currency || undefined}
                />
              </Grid>
              <Grid container xs={12}>
                <Grid item xs={3} style={{ marginLeft: '10px', marginTop: '20px' }}>
                  <FirstComponent
                    fieldLabel="Payment Date" fieldName="paymentDate"
                    required={true}
                    isSaveEnabled={handleEditClick}
                    defaultValue={formData.paymentDate}
                    variant='standard'
                    onhandleChange={(fieldName, value) => handleInputChange(fieldName, value)} fullWidth
                    errormessage={errors.paymentDate || undefined}
                  />
                </Grid>
                <Grid xs={1}>
                </Grid>
                <Grid item xs={3} style={{ marginTop: '20px' }} >
                  <TextInput label="Distribution Source" name="distributionSource"
                    required={true}
                    defaultValue={formData.distributionSource}
                    type="select"
                    options={[
                      { label: 'Investment profits', value: 1 },
                      { label: 'Redemptions', value: 2 },
                      { label: 'Interest and Dividends ', value: 3 },
                      { label: 'Capital Gains ', value: 4 },
                      { label: 'Reinvestment of Profits ', value: 5 }]}
                    onChange={(name, value) => handleInputChange(name, value)}
                    errormessage={errors.distributionSource || undefined}
                  />
                </Grid>
                <Grid xs={1}>
                </Grid>

                <Grid item xs={3} style={{ marginTop: '20px' }} >
                  <TextInput
                    label="Status of the Distribution"
                    name="statusoftheDistribution"
                    defaultValue={formData?.statusoftheDistribution === 0 ? "Draft" : formData?.statusoftheDistribution === 2 ? "Active" : "Executed"}
                    // defaultValue= {distributionStatus === 0 ? 'Draft' : distributionStatus === 2 ? 'Active' : 'Executed'}
                    tabIndex={1000}
                    required={true}
                    editable={false}
                  />
                </Grid>

                <Grid container xs={12}>
                  <Grid item xs={3} style={{ marginLeft: '10px', marginTop: '20px' }}>
                    <TextInput label="Payment Method" name="paymentMethod"
                      defaultValue={formData.paymentMethod}
                      required={true}
                      type="select"
                      options={[
                        { label: 'Wire Transfer', value: 1 },
                        { label: 'Cheque Payment', value: 2 },
                        { label: 'ACH', value: 3 },
                        { label: 'EFT', value: 4 }]}
                      onChange={(name, value) => handleInputChange(name, value)}
                      errormessage={errors.paymentMethod || undefined}
                    />
                  </Grid>
                  <Grid xs={1}>
                  </Grid>
                  <Grid item xs={3} style={{ marginTop: '20px' }} >
                    <TextInput
                      label="Distribution Type"
                      name="distributionType"
                      type="select"
                      required={true}
                      defaultValue={formData.distributionType}
                      options={[
                        { label: 'Realized Gains', value: 2 },
                        { label: 'Return of capital', value: 1 },
                        { label: 'Dividends', value: 3 }
                      ]}
                      onChange={(name, value) => handleInputChange(name, value)}
                      errormessage={errors.distributionType || undefined}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div className={!showSave ? "disablediv" : "regularDiv"}>
            <Grid container >
              <Grid item xs={12} style={{ marginTop: '60px', height: '200px', overflowY: 'auto' }} >
                <div className="colorIcon6" >
                  <AIPDataGrid
                    columns={DistributionsColumns}
                    rows={DistributionInvestorRows}
                    checkboxSelection={true}
                    onRowsSelectionHandler={onRowsSelectionHandler}
                    initiallySelectedRows={initiallySelectedRows}
                    errormessage={errors.statusoftheDistribution || undefined}
                  />
                </div>
              </Grid>
            </Grid>
          </div>

        </div>
      </Grid>
    </div>
  )
}

export default AddingDistributions
