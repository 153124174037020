import React from 'react'
import NavigationBar from '../../../common/navigation-bar/NavigationBar'
import '../../../Generalize.css';
import FundmanagerDetails from './FundmanagerDetails';
import { useState } from 'react';
import ManagementTeam from '../CreateFundModal/ManagementTeam';
import InvestmentStrategy from '../CreateFundModal/InvestmentStrategy';
import FeesExpenses from '../CreateFundModal/FeesExpenses';
import { useEffect } from 'react';
import VDRoom from '../../../common/VirtualDataRoom/VDRoom';
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandMore from "@mui/icons-material/ExpandMore";
import '../../../common/NestedList/styles.css'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';

import DeleteIcon from '@mui/icons-material/Delete';
import { ExpandLess } from '@mui/icons-material';

import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { getrootBackgroundColorConfig } from '../../../../state/slices/colorConfigSlice';
import MiniDrawer from '../../../common/navigation-bar/NavBarGrid'

import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';

import { getFundDetails } from './Services/updatefunddetails';
import { getRoles } from '../../../FundManagerDashboard/Services/FundNewsService';
import { createManagementTeam, updateManagementteams, createFeesandExpenses, updateFeesandExpenses, deleteFeesandExpenses, deleteManagementteamemeber } from '../../services/MarketplaceService';
import { json } from 'd3';
import { AppDashboards } from '../../../../utils/enum';

const Accordians = (props) => {
  const Component = props.Component;
  const [editable, setEditable] = useState(false);
  const [openIndex, setOpenIndex] = React.useState(-1);
  const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);

  const handleClick = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };


  const [InitialData, setInitialData] = useState("");
  const [fundData, setFundData] = useState("");
  const FundName = secureLocalStorage.getItem("FundName");
  const fundId = secureLocalStorage.getItem("FundId");
  const { list = [] } = props;

  const updatedList = props.list.map((item, i) => ({
    ...item,
    editable: false,
  }));

  const [items, setItems] = useState(updatedList);

  useEffect(() => {

    const updatedList = props.list.map((item, i) => ({
      ...item,
      editable: false,
    }));
    setItems(updatedList);
  },
    [props.list])

  const handleClickEdit = (index) => {
    /*      */
    const updatedList = items.map((item, i) => ({
      ...item,
      editable: i === index ? true : "",
    }));
    setItems(updatedList);

  };

  const handleClickCancel = (index) => {
    const updatedList = items.map((item, i) => ({
      ...item,
      editable: i === index ? false : "",
    }));
    setItems(updatedList);
    setOpenIndex();
  };


  const getNewFundDetails = async (index) => {

    const data = await getFundDetails();
    if (data.responseCode === 200) {
      console.log(data.responseData);

      props.setFundData(data.responseData);

      const updatedList = data.responseData.fundManagementTeams.map((item, i) => ({
        ...item,
        editable: i === index ? false : "",
      }));

      setItems(updatedList, () => {
        console.clear();

        console.log(items);
      });
    } else {
      // Handle error if needed
    }
  }




  const handleClickSave = async (index, data, getFundDetails) => {

    /* setOpenIndex(index); */

    const fundId = secureLocalStorage.getItem("FundId");

    if (props.componentName == 'managementteams') {

      const requestData = {
        "fundManagementTeamID": data.fundManagementTeamID,
        "fundID": fundId,
        "firstName": data.firstName,
        "lastName": data.lastName,
        "description": data.description,
        "title": data.title,
        "emailAddress": data.emailAddress,
        "addressLine1": data.addressLine1,
        "addressLine2": data.addressLine2,
        "phoneNumber": data.phoneNumber,
        "city": "",
        "state": 0,
        "country": 0,
        "zipCode": "",
        "managementTeamStaff": data.managementTeamStaff,
        "authorizedSignatory": data.authorizedSignatory
      }

      if (requestData.fundManagementTeamID == 0) {
        const response = await createManagementTeam(requestData);
        if (response.responseCode === 200) {
          const updatedArrayList = props.list.filter(item => item.fundManagementTeamID !== 0);
          props.setArrayData([...updatedArrayList, response.responseData]);
          const updatedData = {
            ...response.responseData,
            editable: false
          };
          const updatedList = items.filter(item => item.fundManagementTeamID !== 0);
          setItems([...updatedList, updatedData]);
          toast.success("Member Successfully Added",
            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
          getFundDetails();
          const updatedManagementStaffDropdowns = managementstaffdropdowns.map(option => {
            if (option.value === response.responseData.managementTeamStaff) {
              return { ...option, isdisabled: true };
            }
            return option;
          });
          setManagementStaffDropdowns(updatedManagementStaffDropdowns);

        }
        else {
          toast.error("Member not Created Please fill the required fields",
            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
      }
      else {
        const response = await updateManagementteams(data, requestData);
        if (response.responseCode === 200) {
          const updatedItems = items.map(item => {
            if (item.fundManagementTeamID === data.fundManagementTeamID) {
              return {
                ...requestData
              };
            }
            return item; // Leave other items unchanged
          });
          /*   */

          props.setArrayData(updatedItems);

          const updatedList = updatedItems.map((item, i) => ({
            ...item,
            editable: i === index ? false : "",
          }));

          setItems(updatedList);
          const dontuse = updatedItems
            .filter((item) => item && item.managementTeamStaff)
            .map((item) => item.managementTeamStaff);

          const UnusedIDs = [loggedInUserId, ...dontuse]

          const updatedManagementStaffDropdowns = managementstaffdropdowns.map(option => {
            return {
              ...option,
              isdisabled: UnusedIDs.includes(option.value),
            };
          });


          setManagementStaffDropdowns(updatedManagementStaffDropdowns);

          getFundDetails()
          toast.success("Successfully Saved",
            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
          console.log("Success");
        }
        else {
          toast.error("Member not Updated Please try again",
            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
      }
    }
    else if (props.componentName == 'feesandexpenses') {

      const requestData = {
        "id": data.id,
        "fundID": fundId,
        "managementFees": data.managementFees,
        "performanceFeesOrCarriedInterest": data.performanceFeesOrCarriedInterest,
        "additionalFeesAndExpenses": data.additionalFeesAndExpenses
      }

      if (requestData.id == 0) {
        const response = await createFeesandExpenses(requestData);
        if (response.responseCode === 200) {
          const updatedArrayList = props.list.filter(item => item.id !== 0);
          props.setArrayData([...updatedArrayList, response.responseData]);
          const updatedData = {
            ...response.responseData,
            editable: false
          };
          const updatedList = items.filter(item => item.id !== 0);
          setItems([...updatedList, updatedData]);
          toast.success("Fees and Expenses Added Successfully",
            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
        else {
          toast.error("Fees and Expenses not Created Please try again",
            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
      }

      else {
        const response = await updateFeesandExpenses(data, requestData);
        if (response.responseCode === 200) {
          const updatedItems = items.map(item => {
            if (item.id === data.id) {
              return {
                ...requestData
              };
            }
            return item; // Leave other items unchanged
          });
          const updatedList = updatedItems.map((item, i) => ({
            ...item,
            editable: i === index ? false : "",
          }));
          toast.success("Successfully Saved",
            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
          /*  console.log("Success"); */
        }
        else {
          toast.error("Fees and Expenses not Updated Please try again",
            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
      }
    }


    const updatedList = items.map((item, i) => ({
      ...item,
      editable: i === index ? false : "",
    }));



    setItems(updatedList, () => {
      console.clear();

      console.log(items);
    });


  };

  const ondelete = async (index, data) => {
    /*      */



    if (props.componentName == 'managementteams') {
      const response = await deleteManagementteamemeber(data);
      //#TODO: Geeting error While Deleted Need to solve this bug
      if (response.responseCode === 200) {

        const updatedItems = items.filter(item => item.fundManagementTeamID !== data.fundManagementTeamID);
        const updatedList = props.list.map((item, i) => ({
          ...item,
          editable: false,
        }));
        setItems(updatedList);
        props.setArrayData(updatedItems);
        /* console.log('Folder deleted successfully');

        const updatedManagementStaffDropdowns = managementstaffdropdowns.map(option => {
          if (option.value === data.managementTeamStaff) {
              return { ...option, isdisabled: false };
          }
          return option;
         });

         setManagementStaffDropdowns(updatedManagementStaffDropdowns); */

        toast.success("Successfully Deleted",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });

      }
      else {
        toast.error("Error deleting please try again",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      }
    }

    else if (props.componentName == 'feesandexpenses') {
      const response = await deleteFeesandExpenses(data);
      if (response.responseCode === 200) {
        const updatedItems = items.filter(item => item.id !== data.id);
        const updatedList = props.list.map((item, i) => ({
          ...item,
          editable: false,
        }));
        setItems(updatedList);
        props.setArrayData(updatedItems);
        console.log('Folder deleted successfully');
        toast.success("Successfully Deleted",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      }
      else {
        toast.error("Error deleting please try again",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      }
    }
  }

  const [Details, setDetails] = useState(props.data);

  useEffect(() => {
    return () => {
      props.updateData(Details);
    }
  }, []);

  const [managementstaffdropdowns, setManagementStaffDropdowns] = useState(null);
  const loggedInUserId = secureLocalStorage.getItem("userId");


  const managementStaffDetails = async () => {
    /*   */

    if (props.componentName == 'managementteams') {
      const data = await getRoles();
      if (data.responseCode === 200) {
        const dontuse = props.list
          .filter((item) => item && item.managementTeamStaff)
          .map((item) => item.managementTeamStaff);

        const UnusedIDs = [loggedInUserId, ...dontuse]
        const managementStaffOptions = data.responseData?.filter(a => a.userRoleName !== AppDashboards.INV_DASHBOARD)?.map(user => ({
          label: user.userFullName,
          value: user.userId,
          isdisabled: false,
          title: user.userRoleName,
          description: user.briefDescription,
        }));
        /*   */
        setManagementStaffDropdowns(managementStaffOptions);
      } else {
        console.log("Error fetching user details");
      }
    }
    else {
      return
    }
  };

  useEffect(() => {
    console.log(props.list)
    /*   */
    managementStaffDetails();
  }, []);

  const [accName, setaccName] = useState("");

  useEffect(() => {
    const name = managementstaffdropdowns?.find((fmitem) => {
      return props.list[0].managementTeamStaff === fmitem.value;
    })?.label || ""
    setaccName(name);
  }, [managementstaffdropdowns, props.list])


  const isFundMatured = secureLocalStorage.getItem("selectedFund")?.FundMaturity;;
  const isFundTerminated = secureLocalStorage.getItem("selectedFund")?.FundTermination;

  console.log('listitems', props.list)
  return (
    <div>
      <List
        sx={{ width: "100%" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            {/* Nested List Items */}
          </ListSubheader>
        } >
        {items !== undefined && items !== null && items.map((item, index) => (
          <div
            key={index}
            style={{
              border: '1px solid #ccc',
              marginLeft: '10px',
              marginRight: '10px',
              marginTop: '10px',
              paddingTop: '5px',
              paddingBottom: '5px',
              borderRadius: '5px',
              backgroundColor: 'white',
            }}
          >
            <ListItemButton
              sx={{
                "&.MuiButtonBase-root.MuiListItemButton-root": {
                  // Override max-width here
                  maxWidth: "none !important",
                  background: "white",
                },
              }}
            >
              {props.componentName === 'managementteams' && (
                <ListItemText
                  primary={
                    (props.list[index]?.managementTeamStaff && props.managementStaffData) ?
                      props.managementStaffData.find(fmitem => props.list[index].managementTeamStaff === fmitem.value)?.label :
                      ""
                  }
                />

              )}
              {props.componentName === 'feesandexpenses' && (
                <ListItemText primary={`Primary Expenses`} />
              )}
              {openIndex === index ? (
                <Button sx={{ color: 'black' }}>
                  <ExpandLess close={openIndex === index} onClick={() => handleClick(index)} />
                </Button>
              ) : (
                <div>
                  <Button
                    onClick={() => handleClick(index)}
                    sx={{ color: 'black' }}
                  >
                    <ExpandMore open={openIndex === index} />
                  </Button>
                  {!isFundTerminated && !isFundMatured ?
                    <Button
                      onClick={() => { ondelete(index, props.list[index]) }}
                      sx={{ color: 'black' }}
                      disabled={props.list.length === 1}
                    >
                      <DeleteIcon />
                    </Button> : null}
                </div>
              )}
            </ListItemButton>
            <Collapse in={openIndex === index} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {openIndex === index && (
                  <div style={{ marginLeft: '15px', width: '94%' }}>
                    {/* Render the dynamic component */}
                    {props.componentName === 'feesandexpenses' && (
                      <Component
                        data={props.list[index]}
                        updateData={props.updateData}
                        isedit={true}
                        handleClickEdit={handleClickEdit}
                        handleClickCancel={handleClickCancel}
                        handleClickSave={handleClickSave}
                        openIndex={openIndex}
                        index={index}
                        editable={item.editable}
                        setEdit={setEditable}
                        getFundDetails={props.getFundDetails}
                      />
                    )}
                    {
                      props.componentName === 'managementteams' && managementstaffdropdowns && (
                        <Component
                          data={props.list[index]}
                          updateData={props.updateData}
                          isedit={true}
                          handleClickEdit={handleClickEdit}
                          handleClickCancel={handleClickCancel}
                          handleClickSave={handleClickSave}
                          openIndex={openIndex}
                          index={index}
                          editable={item.editable}
                          setEdit={setEditable}
                          getFundDetails={props.getFundDetails}
                          managementStaffData={managementstaffdropdowns}
                          allManagementDetails={props.list}
                        />
                      )
                    }


                  </div>
                )}
              </List>
            </Collapse>
          </div>
        ))}
      </List>
    </div>
  )
}

export default Accordians