
import { AxiosHeaders } from "axios";
import secureLocalStorage from "react-secure-storage";

// return the user data from the session storage
export const getUser = () => {
  const userStr = sessionStorage.getItem("user");
  if (userStr)
    //return JSON.parse(userStr);
    return { name: "Vinodh" };
  else return null;
};

// return the token from the session storage
export const getToken = () => {
  return sessionStorage.getItem("token") || null;
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("user");
};

// set the token and user from the session storage
export const setUserSession = (token, user) => {
  sessionStorage.setItem("token", token);
  sessionStorage.setItem("user", JSON.stringify(user));
};


export const checkFeaturePermissions = (featureName, featureAccess) => {

  if (secureLocalStorage.getItem("userrole") == "Investor") {
    return true;
  }

  const rolesAndPermissions = JSON.parse(secureLocalStorage.getItem("RolesAndPermissions"));
  const data = rolesAndPermissions?.filter(rp => rp.featureName == featureName);
  let result = false;
  if (data?.length == 0) {
    return true;
  }

  if (data != null && data?.length > 0) {
    if (featureAccess == "CREATE") {
      result = data[0].hasWriteAccess;
    }
    else if (featureAccess == "READ") {
      result = data[0].hasReadAccess;
    }
    else if (featureAccess == "UPDATE") {
      result = data[0].hasUpdateAccess;
    }
    else if (featureAccess == "DELETE") {
      result = data[0].hasDeleteAccess;
    }
  }
  return result;
}

export const getAPIResponse = (response) => {
  let result = {
    responseCode: 0,
    responseData: "",
    headers:''
  }
  if (response?.code === "ERR_BAD_REQUEST") {
    result.responseCode = response.response.status;
    result.responseData = response.response.data;
    result.headers = response.response.headers
  }
  else {
    result.responseCode = response.status;
    result.responseData = response.data;
    result.headers = response.headers
  }
  return result;
}
