export const OBQdataTemplate = 
{
        "id": 0,
        "QuestionName": "",
        "QuestionType": "TextQ",
        "isActive": "",
        "AsktoExcistingInvestors": "",
        "type": "text",
        "questionlabel" : "" ,
        "questionShortName": "",
        "multipleoptions": false,
        "setValidations": false,
        "allowalphabets": true,
        "allownumbers": true,
        "maximumcharacterslength": ""
  }

  export const OBQMCQoptionsdataTemplate = 
  {
        "questionAnswerID": 0,
        "questionBankID": 0,
        "answer": "",
        "description": "",
        "createdBy": "",
        "createdDate": "2023-12-15T05:53:05.774Z",
        "updatedBy": "",
        "updatedDate": "2023-12-15T05:53:05.774Z",
        "isActive": false,
        "softDelete": false
    }

  export const OBMCQdataTemplate = 
  {
      "questionBankID": 0,
      "questionName": "",
      "answers": "",
      "multipleAnswers": false,
      "questionTypeId": 0,
      "softDelete": false,
      "step": 0,
      "shortName": "",
      "showInInvestProfile": true,
      "description": "",
      "footerText": "",
      "footerToggleDescription": "",
      "createdBy": "",
      "createdDate": "2023-12-15T05:53:05.774Z",
      "updatedBy": "",
      "updatedDate": "2023-12-15T05:53:05.774Z",
      "isActive": true,
      "askToExisitingInvestor": true,
      "isText": true,
      "bookmarks": "",
      "onboardingQuestionPattern": 0,
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 100,
      "questionAnswer": [
      {
      "questionAnswerID": 0,
      "questionBankID": 0,
      "answer": "",
      "description": "",
      "createdBy": "",
      "createdDate": "2023-12-15T05:53:05.774Z",
      "updatedBy": "",
      "updatedDate": "2023-12-15T05:53:05.774Z",
      "isActive": true,
      "softDelete": false
      }
      ]      
}

export const OBTextdataTemplate = 
  {
      "questionBankID": 0,
      "questionName": "",
      "answers": "",
      "multipleAnswers": false,
      "questionTypeId": 1,
      "softDelete": false,
      "step": 0,
      "shortName": "",
      "showInInvestProfile": true,
      "description": "",
      "footerText": "",
      "footerToggleDescription": "",
      "createdBy": "",
      "createdDate": "2023-12-15T05:53:05.774Z",
      "updatedBy": "",
      "updatedDate": "2023-12-15T05:53:05.774Z",
      "isActive": true,
      "askToExisitingInvestor": true,
      "isText": true,
      "bookmarks": "",
      "onboardingQuestionPattern": 0,
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 100,
      "questionAnswer": [
            {
            "questionAnswerID": 0,
            "questionBankID": 0,
            "answer": "",
            "description": "",
            "createdBy": "",
            "createdDate": "2023-12-15T05:53:05.774Z",
            "updatedBy": "",
            "updatedDate": "2023-12-15T05:53:05.774Z",
            "isActive": true,
            "softDelete": false
            }
            ]        
}