import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Grid } from '@mui/material';
import { useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';

const ResetConfirmation = (props) => {

    const { open, onClose,onAccept } = props;
    const [loading, setLoading] = useState(false);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle className='text-center'>
                <InfoIcon className='info-i' />
                <b>Confirmation</b>
            </DialogTitle>
            <DialogContent>
                Are you sure you want to reset all roles features ?
            </DialogContent>
            <DialogActions>
                <div className='space-between'>
                    <div>
                        <Button variant='outlined' onClick={onClose} label='Cancel'>Cancel</Button>
                    </div>
                    <div className='margin-left-10'>
                        <Button
                            label='Ok'
                            loading={loading}
                            variant='contained'
                            onClick={onAccept}>Ok</Button>
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    )
}
export default ResetConfirmation;