import axios from "axios";
import axiosApiCall from "../../Services/axiosApiCall";
import { getAPIResponse } from "../../../utils/common";



export async function GetFundSubscriptions(fundID){

  return await axiosApiCall.get(`/v1/FundSubscription/GetFundSubscriptions?fundID=${fundID}`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
  });
}

export async function GetInvestorFundSubscriptionDetails(fundID, investorID, subscriptionID){

  return await axiosApiCall.get(`/v1/FundSubscription/GetInvestorFundSubscriptionDetails?fundID=${fundID}&InvestorID=${investorID}&SubscriptionID=${subscriptionID}`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
  });
}

export async function UpdateSubscriptionQuestionAnswers(data) {
  return await axiosApiCall.put(`/v1/FundSubscription/UpdateInvestorSubscriptionQuestions`,data)
  .then(res => {
      return getAPIResponse(res);
  }).catch(error => {
      return getAPIResponse(error);
  });
}

export async function SubscribetoFundWithFiles(data) {
  return await axiosApiCall.post(`/v1/FundSubscription/SubscribeToFundWithFiles`,data)
  .then(res => {
      return getAPIResponse(res);
  }).catch(error => {
      return getAPIResponse(error);
  });
}

export async function ReSubmitSubscription(cnrtFundID,crntSubscriptionID) {
  return await axiosApiCall.put(`/v1/FundSubscription/ReSubmitSubscription?fundID=${cnrtFundID}&subscriptionID=${crntSubscriptionID}`)
  .then(res => {
      return getAPIResponse(res);
  }).catch(error => {
      return getAPIResponse(error);
  });
}



export async function submitOfflineSubscription(fundId,InvestorID,subscriptionID,isOffLineSubscription,requestData) {
  return await axiosApiCall.post(`/v1/FundSubscription/Subscribe?fundid=${fundId}&investorID=${InvestorID}&subscriptionID=${subscriptionID}&capitalCommitted=0&isOffLineSubscription=${isOffLineSubscription}&submitSubscription=false`, requestData)
      .then(res => {
          return getAPIResponse(res);
      }).catch(error => {
          return getAPIResponse(error);
      });
}

export async function FinalUpdateSubscriptionStatus(SubscriptionID,isApproved,isFundManagerAction,signedenvelopeId,userID) {
  return await axiosApiCall.post(`/v1/FundSubscription/UpdateSubscriptionStatus?FundSubscriptionID=${SubscriptionID}&fundSubscriptionStatus=${isApproved}&isFundManager=${isFundManagerAction}&envelopID=${signedenvelopeId}&ActionByUserID=${userID}`)
      .then(res => { 
          return getAPIResponse(res);
      }).catch(error => {
         
          return getAPIResponse(error);
      });
}





