import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import resolve from '../../../common/resolve';
import axiosApiCall from '../../../Services/axiosApiCall';
import { getAPIResponse } from '../../../../utils/common';

const header = { headers: { 'TenantID': secureLocalStorage.getItem("tenantID") } }

export async function getRepotingFolders() {
    return await axiosApiCall.get(`/v1/Servicing/GetMappedReportingDocumentTypes`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}
export async function getDocumentTypeForInvestorReporting() {
    return await axiosApiCall.get(`/v1/VirtualDataRooms/GetDocumentTypesByVDRType?VDRTypes=InvestorReporting`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}
export async function getReportTypeData() {
    return await axiosApiCall.get(`/v1/Servicing/GetReportTypeData`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getRepotingFoldersbyInvestorID(userID) {
    return await axiosApiCall.get(`/v1/Servicing/GetMappedReportingDocumentTypes?investorID=${userID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getRepotingFoldersbyFundID(fundID) {
    return await axiosApiCall.get(`/v1/Servicing/GetMappedReportingDocumentTypes?fundID=${fundID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getRepotingDocumentsbyFundID(fundID,documentTypeID) {
    return await axiosApiCall.get(`/v1/Servicing/GetReportDocument?FundID=${fundID}&DocumentTypeID=${documentTypeID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getRepotingDocuments(documentID) {
    return await axiosApiCall.get(`/v1/Servicing/GetReportDocument?DocumentTypeID=${documentID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getInvetsorRepotingDocuments(documentID) {
    return await axiosApiCall.get(`/v1/Servicing/GetReportDocumentByInvestor?documentTypeID=${documentID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getFileExcistsorNot(document,reportTypeIds) {
    return await axiosApiCall.get(`/v1/Servicing/CheckDocumentsExistBeforeAdding?FundID=${document.fundID}&YearNumber=${document.yearNumber}&QuarterNumber=${document.quarterNumber}&ReportDocumentListIDs=${reportTypeIds}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getDownloadDocument(fileID) {
    /* return await axiosApiCall.get(`/v1/VirtualDataRooms/DownloadVDRItem?vdrID=${fileID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        }); */     
}

export async function getFundsbyDashboard() {
    return await axiosApiCall.get(`/v1/Fund/GetFundsForDashboard?userId=${secureLocalStorage.getItem("userId")}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        }
        );
}

export async function postDocumnet(formData) {
    return await axiosApiCall.post(`/v1/Servicing/UploadReportDocument`, formData)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}


export async function deleteDocumnet(data) {
    return await axiosApiCall.delete(`/v1/VirtualDataRooms/DeleteVDRItem?vdrID=${data?.vdrItemID}&fundID=${data?.fundID}&subscriptionID=0&PortfolioID=0`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function deleteVDRItem(vdrID) {
    return await axiosApiCall.delete(`/v1/VirtualDataRooms/DeleteVDRItem?vdrID=${vdrID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function UpdateDocumnet(data, formData) {
    return await axiosApiCall.put(`/v1/VirtualDataRooms/ReplaceVDRItem?vdrID=${data.vdrItemID} `, formData)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}
