import React, { useState , useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DialogActions, DialogContent } from '@mui/material';
import TextInput from '../../../common/TextInput/TextInput';
import LinearProgress from '@mui/material/LinearProgress';
import "../../CompanyDetail.module.css"
import Text from '../../../common/Text/Text';
import ActionButton from '../../../common/ActionButton/ActionButton';
import Text2 from '../../../common/Text/Text2';
import { UpdateLegalCounselDetails } from '../../Services/CompaniesDetails';
import secureLocalStorage from 'react-secure-storage';
import { useEffect } from 'react';

function LegalCounsel(props) {
    const [open, setOpen] = useState(true);
    const componentRef = useRef(null);
    useEffect(() => {
        componentRef.current.scrollIntoView({ behavior: 'smooth' });
    }, []);
   
    const [errors, setErrors] = useState({});


  const handleChange = (field, value) => {
    const validationResult = validateField(field, value);
        if (!validationResult.isValid) {
            setErrors(prevErrors => ({ ...prevErrors, [field]: validationResult.error }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, [field]: null }));    
           
            // props.updateData(currState);
        } 
            props.data[field] = value;
            props.setapiData(props.data);
            validateAllErrors();
     
  };

    const validateField = (field, value) => {
    
      const validationRules = {
        legalCounselFirmName: (value, currState) => {
              value = value.trim(); 
              
              const isValid = /^[A-Za-z\s]+$/.test(value);
          
              return {
                  isValid,
                  error: isValid ? null : `Please enter a Valid Name`,
              };
          },
          legalCounselName: (value, currState) => {
            value = value.trim(); 
            
            const isValid = /^[A-Za-z\s]+$/.test(value);
        
            return {
                isValid,
                error: isValid ? null : `Please enter a Valid Name`,
            };
        },
        legalCounselZipCode: (value, currState) => {
            value = value.trim();
        
            const isValid = /^\d{5}(-\d{4})?$/.test(value);
        
            return {
                isValid,
                error: isValid ? null : 'Please enter a valid ZIP Code (Example:12345-6789 or 12345)',
            };
        },
        legalCounselContactPhone: (value, currState) => {
            value = value.trim();
            const isValid = /^\d{10}$/.test(value); 
            return {
                isValid,
                error: isValid ? null :  "Please enter a valid phone number (up to 10 digits)",
            };
        },
        legalCounselEmail: (value, currState) => {
            value = value.trim();
            const isValid = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}(?:\.[A-Za-z]+)*$/.test(value);
            return {
                isValid,
                error: isValid ? null : "Please enter a valid email address",
                };
            },
        
      };
  
      return validationRules[field] ? validationRules[field](value) : { isValid: true, error: null };
  };

    const MandatoryFieldErrors = (field, value) => {
        const fieldsToKeep = ['legalCounselFirmName','legalCounselAddress1','legalCounselState','legalCounselAddress2','legalCounselZipCode',
        'legalCounselCity','legalCounselCountry','legalCounselName','legalCounselContactPhone','legalCounselEmail'];

        const trimmedValues = { ...props.data };

        const filteredFields = fieldsToKeep.map((field) => {
            if (trimmedValues.hasOwnProperty(field)) {
            return { field, value: trimmedValues[field] };
            }
            return null;
        }).filter(Boolean);
        const Mandatory = filteredFields.map(({ field, value }) => ({
            field,
            value: value === "" ? null : value,
        }));
        const EmptyFields = Mandatory.filter(entry => entry.value === "" || entry.value === null).map(entry => entry.field);
        const error = `This field is required.`;
        EmptyFields.map((item)=>{
            /*   */
            setErrors(prevErrors => ({ ...prevErrors, [item]: error }));
        })
    };

    useEffect(()=>{
         
    if(props.Onboardingsteperrors === true){
        MandatoryFieldErrors();
    }
    },[props.Onboardingsteperrors])

    const validateAllErrors = () =>{
        const errorsArray = Object.values(errors);
            const hasErrors = errorsArray.some(error => error !== null);

            const fieldsToAdd = ['legalCounselFirmName','legalCounselAddress1','legalCounselState','legalCounselAddress2','legalCounselZipCode',
                                'legalCounselCity','legalCounselCountry','legalCounselName','legalCounselContactPhone','legalCounselEmail'];

            const trimmedValues = { ...props.data };

            const filteredValues = Object.keys(trimmedValues)
            .filter(field => fieldsToAdd.includes(field))
            .reduce((obj, key) => {
                obj[key] = trimmedValues[key];
                return obj;
            }, {});

            console.log(filteredValues);


            const Mandatory =  Object.values(filteredValues);
            const MandatoryFields = Mandatory.some(error => error === null || error === "");

            if (hasErrors || MandatoryFields) {
                secureLocalStorage.setItem("PCLegalCounsel", true);
            } else {
                secureLocalStorage.setItem("PCLegalCounsel", false);
            }
    }

    useEffect(()=>{
        validateAllErrors();
    },[])
  
      
      return (
        <div ref={componentRef}>
          
            <div className="fundRow">
                <div className="fundDetail">
                    <TextInput 
                        label="Name of Firm" 
                        name="legalCounselFirmName"
                        required={true}
                        type="Onlytext"
                        defaultValue={props?.data?.legalCounselFirmName} 
                        errormessage={errors.legalCounselFirmName || undefined}
                        onChange={(name, value)=>handleChange(name, value)}/>
                </div>
               
            </div>
            <div className="fundRow">
                <div className="fundDetail">
                <h11>Firm Address</h11>
                </div>
            </div>

            <div className="fundRow">
                <div className="fundDetail">
                    <TextInput 
                        label="Address Line1" 
                        name="legalCounselAddress1"
                        type="limit"
                        required={true}
                        defaultValue={props?.data?.legalCounselAddress1} 
                        errormessage={errors.legalCounselAddress1 || undefined}
                        editable={props.editable}
                        onChange={(name, value)=>handleChange(name, value)}/>
                </div>
                <div className="fundDetail">
                <TextInput 
                       type="select"
                        label="State" 
                        name="legalCounselState"
                        required={true}
                        defaultValue={props?.data?.legalCounselState} 
                        errormessage={errors.legalCounselState || undefined}
                        options={props.FiltersData?.stateProvince?.map(state => ({ label: state.title, value: state.stateProvinceId }))}
                        onChange={(name, value)=>handleChange(name, value)}/>
                </div>
            </div>
           
            <div className="fundRow">
                <div className="fundDetail">
                    <TextInput 
                        label="Address Line2" 
                        name="legalCounselAddress2"
                        type="limit"
                        required={true}
                        defaultValue={props?.data?.legalCounselAddress2}
                        errormessage={errors.legalCounselAddress2 || undefined}
                        editable={props.editable}
                        onChange={(name, value)=>handleChange(name, value)}/>
                </div>
                <div className="fundDetail">
                    <TextInput 
                        label="Zip Code" 
                        name="legalCounselZipCode"
                        required={true}
                        type="number"
                        defaultValue={props?.data?.legalCounselZipCode} 
                        editable={props.editable}
                        onChange={(name, value)=>handleChange(name, value)}
                        errormessage={errors.legalCounselZipCode || undefined}
                        />
                </div>
            </div>
            <div className="fundRow">
                <div className="fundDetail">
                    <TextInput 
                        type="select"
                        label="City" 
                        name="legalCounselCity"
                        required={true}
                        defaultValue={props?.data?.legalCounselCity} 
                        errormessage={errors.legalCounselCity || undefined}
                        options={props.FiltersData?.city?.map(city => ({ label: city.title, value: city.cityId }))}
                        onChange={(name, value)=>handleChange(name, value)}/>
                </div>
                <div className="fundDetail">
                <TextInput 
                       type="select"
                        label="Country" 
                        name="legalCounselCountry"
                        required={true}
                        defaultValue={props?.data?.legalCounselCountry} 
                        errormessage={errors.legalCounselCountry || undefined}
                        options={props.FiltersData?.country?.map(country => ({ label: country.title, value: country.countryId }))}
                        onChange={(name, value)=>handleChange(name, value)}/>
                        </div>
            </div>
            <div className="fundRow">
                <h11>Name of Counsel</h11>
            </div>
            <div className="fundRow">
                <div className="fundDetail">
                <TextInput 
                        label="First Name" 
                        name="legalCounselName"
                        type="Onlytext"
                        required={true}
                        defaultValue={props?.data?.legalCounselName} 
                        editable={props.editable}
                        errormessage={errors.legalCounselName || undefined}
                        onChange={(name, value)=>handleChange(name, value)}/>
                </div>
                <div className="fundDetail">
                <TextInput 
                        label="Last Name" 
                        name="lastname"
                        type="Onlytext"
                        // required={true} // need to be implemenented in future sprints
                        defaultValue={props?.data?.lastname} 
                        editable={props.editable}
                        onChange={(name, value)=>handleChange(name, value)}/>
                </div>
            </div>
            <div className="fundRow">
                <div className="fundDetail">
                <TextInput 
                        label="Phone Number" 
                        name="legalCounselContactPhone"
                        required={true}
                        defaultValue={props?.data?.legalCounselContactPhone} 
                        editable={props.editable}
                        onChange={(name, value)=>handleChange(name, value)}
                        errormessage={errors.legalCounselContactPhone || undefined}
                        />
                </div>
                <div className="fundDetail">
                <TextInput 
                        label="Email" 
                        name="legalCounselEmail"
                        required={true}
                        defaultValue={props?.data?.legalCounselEmail} 
                        editable={props.editable}
                        onChange={(name, value)=>handleChange(name, value)}
                        errormessage={errors.legalCounselEmail || undefined}
                        />
                </div>
            </div>
            <div className="fundRow">
                <div className="fundDetail">
                    <ActionButton 
                        variant="outlined"
                        label="ADD MORE" 
                        startIconName="ControlPointOutlined" 
                        onClick={()=>{}}
                        styleProps={{
                            borderColor: '#b7b7b7',
                            color: '#b7b7b7',
                            fontSize: '14px',
                        }}/>
                </div>
            </div>
            
        </div>
      );
}
export default LegalCounsel;