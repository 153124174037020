export const  convertStringToArray = (input) => {
    if (Array.isArray(input)) {
        return input;
    }

    if (typeof input === 'string' && input.length > 0) {
        return input.split(',');
    }

    return [input];
}

export const convertNamesToArray = (input) => {
    if (input === null || input === "") {
      return null;
    }
  
    if (Array.isArray(input)) {
      return input;
    }
  
    if (typeof input === 'string' && input.length > 0) {
      // Split the input string by ","
      const firstLevelSplit = input.split(',');
  
      // Map over the first level split array
      const result = firstLevelSplit.map((entry, index) => {
        // Split each entry by "_"
        const secondLevelSplit = entry.split('_');
  
        // Return an object with 'id', 'firstname', and 'lastname' properties
        return {
          id: index + 1,
          firstname: secondLevelSplit[0],
          lastname: secondLevelSplit[1],
          errorfirstname: false,
          errorlastname: false
        };
      });
  
      return result;
    }
  
    return [input];
  };
  

export const convertArrayToNames = (input) => {
    if (Array.isArray(input) && input.length > 0) {
        // Map over the input array
        const result = input.map((item, index) => {
            // Concatenate 'firstname' and 'lastname' properties with "_"
            return `${item.firstname}_${item.lastname}`;
        });

        // Join the array elements with ","
        return result.join(',');
    }

    return input;
};


export const convertArrayToString = (arr) => {
    if (!Array.isArray(arr) || arr.length === 0) {
        return "";
    }

    const onlyNames = arr.map((item) => item.data);
    return onlyNames.join(',');
}