//## Importing what we need in this component

import React from 'react'
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import Text from '../common/Text/Text'
import { useEffect, useState, useCallback } from 'react'
import Button from '@mui/material/Button';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import { ToastContainer, toast } from 'react-toastify';
import FirstComponent from '../common/DatePicker/DatePicker';
import AIPDataGrid from '../common/DataGrid/AIPDataGrid';
import 'react-toastify/dist/ReactToastify.css';
import CapitalCall from './AddingCapitalCall';
import StackView from '../common/StackView/StackView';
import SendIcon from '@mui/icons-material/Send';
import { GetCapitalCallsForFund, GetCapitalCallByID, postCapitalcallData } from './Services/CapitalCall'
import secureLocalStorage from 'react-secure-storage'
import { useLocation, useNavigate } from "react-router-dom";
import { getrootBackgroundColorConfig } from '../../state/slices/colorConfigSlice'
import { useSelector } from "react-redux";
import { HeaderTemplates, PageHeader } from '../common/PageHeader/PageHeader'
import moment from 'moment'
import TextInput from '../common/TextInput/TextInput'
import { ResendNotification } from './Services/CapitalCall'
import { checkFeaturePermissions } from '../../utils/common'
import { RolesAndPermissions } from '../../utils/enum'
import { FeatureAccess } from '../../utils/enum'
import GridCurrencyFormater from '../common/CurrencyFormater/GridCurrencyFormater'
import { Typography } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { isActive } from '@tiptap/core';

function CapitalCallActivity() {

  //## Region for Variables & Dialog Open Close , Navigation events
  const navigate = useNavigate();
  const location = useLocation();
  const fundname = secureLocalStorage.getItem("FundName")
  const [PreviousCapitalCallsForFund, SetPreviousCapitalCallsForFund] = useState(null);
  const [isPrevCallshaveActive, setIsPrevCallshaveActive] = useState(false)
  const [AddingCC, SetAddingCC] = useState(false)
  const [PreviousCC, SetPreviousCC] = useState(true);
  const [isAddCallClick, setIsAddCallClick] = React.useState(false)
  const [CapitalcallDetails, setCapitalcallDetails] = useState(null);
  const [rowccnumber, setrowccnumber] = useState(0);
  const [formRefresh, setformRefresh] = useState(true)
  const [EmergencySituation, setEmergencysituation] = useState(null)
  const [totalCapitalCalled, setTotalCapitalCalled] = useState(null);
  const [dataRetrived, setdataRetrived] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [totalCommittedCapital, settotalCommittedCapital] = useState(0);
  const [formData, setFormData] = useState({});
  const [selectedIds, setSelectedIds] = useState([]);
  const [originalCCInvestors, setOriginalCCInvestors] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [NotificationType, setNotificationType] = useState('')
  const [errors, setErrors] = useState({});
  const [status, setstatus] = useState(null)
  const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);
  const [showSave, setshowSave] = useState(false);
  const [showEdit, setshowEdit] = useState(false);
  const [savebtnDisabled, setSavebtnDisabled] = useState(false);
  const fundDetails = location?.state?.fundDetails
  const capitalCalled = location?.state?.fundDetails.capitalCalled
  const capitalCommitted = location?.state?.fundDetails?.capitalCommitted

  const isFundMatured = secureLocalStorage.getItem("selectedFund")?.FundMaturity;;
  const isFundTerminated = secureLocalStorage.getItem("selectedFund")?.FundTermination;
  const [isReasonValid, setIsReasonValid] = useState(true); //validation hook for resend notifcation reason field
  const [addedAmount, setAddedAmount] = useState(null)
  const [addedcommitedCapital, setaddedcommitedCapital] = useState(null)


  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setNotificationType('')
    setIsReasonValid(true)
    fetchData()
  };

  const onDraftStatusClick = (params, prevcc) => {
    setformRefresh(false)
    const idgenrated = true
    setrowccnumber(params?.row?.id)
    AddCallClick((prevcc ? (params?.row?.id) : 0), idgenrated)
    setstatus(params?.row?.Status)
    setIsAddCallClick(false);
    if (params?.row?.status === 'Draft' || params?.row?.status === 'Active') {
      SetPreviousCC(false);
    }
  };

  const BackArrowClick = useCallback(() => {
    SetAddingCC(false);
    SetPreviousCC(true);
    setIsAddCallClick(false);

    if (PreviousCC) {
      navigate("/fund-detail-services-1");
    }
  }, [navigate]);

  const handleEditClick = () => {
    setIsEditing(true);
    setshowSave(true);
    setshowEdit(false);
    fetchData();
  };

  //## Service Calls
  const onPopupConfirmClick = async () => {
    if (CapitalcallDetails?.ccStatusID === 3) {
      if (NotificationType === '') {
        setIsReasonValid(false)
      } else {
        const data = await ResendNotification(NotificationType, secureLocalStorage.getItem('FundId'), rowccnumber, formData?.paymentDueDate, EmergencySituation)
        if (!data.error) {
          toast.success('Notification Sent Successfully', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            style: {
              marginTop: '100px',
            },
          });
          setNotificationType('')
        } else {
          toast.error('Error Occured', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            style: {
              marginTop: '100px',
            },
          });
        }
        setNotificationType('')
        SetAddingCC(false);
        SetPreviousCC(true);
        setIsDialogOpen(false);
      }
    } else {
      onEditSaveClick(false);
      SetAddingCC(false);
      SetPreviousCC(true);
      setIsDialogOpen(false);
    }
  }

  const fetchData = async () => {
    try {
      const responseData = await GetCapitalCallsForFund(secureLocalStorage.getItem("FundId"));
      if (responseData.responseCode === 200) {
        SetPreviousCapitalCallsForFund(responseData.responseData);
        setAddedAmount(responseData?.responseData.reduce((total, call) => total + call.ccTotalFundsReceived, 0));
        setaddedcommitedCapital(responseData.responseData[0]?.committedCapital)
        if (responseData.responseData.some(u => u.capitalCallStatus === 'Active')) {
          setIsPrevCallshaveActive(true)
        } else {
          setIsPrevCallshaveActive(false)
        }
      } else {
        console.log('error happened');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const AddCallClick = async (rowID, idgenrated) => {
    if (rowID === 0) {
      setformRefresh(false)
      setSelectedIds([])
      setFormData([])
      setrowccnumber(0)
    }
    const data = await GetCapitalCallByID(secureLocalStorage.getItem("FundId"), rowID);
    if (data.responseCode === 200) {
      setCapitalcallDetails(data.responseData);
      setTotalCapitalCalled(data?.responseData.eccTotalCapitalCalled)
      settotalCommittedCapital(data?.responseData.committedCapital)
      setOriginalCCInvestors(data.responseData);
      const newData = {
        totalCCcalled: data.responseData.ccTotalCapitalCalled,
        targetRaise: data?.responseData.fundSize,
        capitalCallNumber: idgenrated ? data?.responseData.ccSerialNumber : null,
        currency: data.responseData.ccCurrency,
        purposeOfCall: data?.responseData.ccPurpose,
        paymentDueDate: data?.responseData.ccPaymentDueDate,
        statusOfCapitalCall: data?.responseData.capitalCallStatus,
        CCDate: data?.responseData.ccDate,
        fundsRecieved: data?.responseData.ccTotalFundsReceived
      };
      setshowSave(rowID > 0 ? false : true);
      setshowEdit(rowID > 0 ? true : false);
      setFormData(newData);
      SetAddingCC(true);
      SetPreviousCC(false);
      setIsAddCallClick(true);
      setTimeout(() => {
        setformRefresh(true)
      }, 2000);
      setdataRetrived(true)
    }
    else {
      // console.log('error happend')
    }

  }
  useEffect(() => {
    fetchData();
  }, []);

  //## Validations & Form Submission

  const validateField = (field, value) => {
    if (field === 'totalCCcalled') {
      handleTotalCapitalCalledChange(field, value)
    }

    const validationRules = {
      purposeOfCall: (value, currState) => {
        const isAlphabetic = /^[a-zA-Z\s]+$/.test(value.trim());
        const isEmpty = !value.trim();

        return {
          isValid: !isEmpty && isAlphabetic,
          error: isEmpty
            ? 'Please enter the Purpose of a Call'
            : !isAlphabetic
              ? 'Please enter only alphabets for the Purpose of a Call'
              : null,
        };
      },

      currency: (value, currState) => {
        const isEmpty = !value.trim();

        return {
          isEmpty,
          error: isEmpty ? 'Please enter the Currency' : null,
        };
      },

      targetRaise: (value, currState) => {
        const isEmpty = !value.trim();

        if (isEmpty) {
          return {
            isEmpty,
            error: 'Please enter a Valid Target Raise.',
          };
        }

        const isNumeric = /^[1-9]\d*(\.\d+)?$/.test(value);

        if (!isNumeric || parseFloat(value) <= 0) {
          return {
            isEmpty: false,
            error: 'Please enter a positive numeric value for Target Raise that is greater than zero.',
          };
        }

        return {
          isEmpty: false,
          error: null,
        };
      },

      totalCCcalled: (value, currState) => {
        const isEmpty = !value.trim();

        if (isEmpty) {
          return {
            isEmpty,
            error: 'Please enter a Valid Total Capital Called.',
          };
        }

        const trimmedValue = value.replace(/^0+/, '');
        const isNumeric = /^(0|[1-9]\d*)(\.\d+)?$/.test(trimmedValue);
        if (!isNumeric || parseFloat(value) <= 0) {
          return {
            isEmpty: false,
            error: 'Please enter a positive numeric value for Total Capital Called.',
          };
        }

        return {
          isEmpty: false,
          error: null,
        };
      },

    };

    return validationRules[field] ? validationRules[field](value) : { isEmpty: true, error: null };
  };
  const MandatoryFieldErrors = () => {

    const fieldsToKeep = ['totalCCcalled', 'currency', 'purposeOfCall', 'CCDate', 'paymentDueDate'];

    let Mandatory = fieldsToKeep?.map((field) => ({
      field,
      value: field === "currency" ? "USD" : formData[field],
    }));

    const EmptyFields = Mandatory?.filter(entry => entry?.value === ""
      || entry?.value === null
      || entry?.value === undefined
      || entry?.value === 0)?.map(entry => entry?.field);



    const error = `This field is required.`;

    if (EmptyFields?.length > 0) {
      EmptyFields?.forEach((item) => {
        setErrors(prevErrors => ({ ...prevErrors, [item]: error }));
      });
    }


    // console.log(errors);
    return EmptyFields;
  };

  const onEditSaveClick = async (isActive = false) => {
    const updatedData = {
      "fundID": secureLocalStorage?.getItem("FundId"),
      "capitalCallID": rowccnumber,
      "fundName": secureLocalStorage?.getItem("FundName"),
      fundSize: formData?.targetRaise,
      "targetRaise": CapitalcallDetails?.targetRaise,
      ccTotalFundsReceived: formData?.fundsRecieved,
      "committedCapital": totalCommittedCapital,
      "ccTotalCapitalCalled": formData?.totalCCcalled,
      "ccTotalRemainingCapital": totalCommittedCapital - formData?.totalCCcalled,
      "capitalCallStatus": isActive === true ? "Active" : "Executed",
      "ccStatusID": isActive === true ? 2 : 3,
      "ccSerialNumber": formData?.capitalCallNumber,
      "ccCurrency": formData?.currency,
      "ccPurpose": formData?.purposeOfCall,
      ccNotes: CapitalcallDetails?.ccNotes,
      "ccDate": formData?.CCDate,
      "ccPaymentDueDate": formData?.paymentDueDate,
      "noOfInvestors": selectedIds.length,
      "fundManagerID": secureLocalStorage.getItem("userId"),
      "fundManager": "Fund Manager",
      "fundManagerEmail": secureLocalStorage.getItem("userEmail"),

      "capitalCallInvestors": updatedCCGridRows?.filter(u => u?.ccOwnership > 0)
    };

    try {
      if (rowccnumber > 0 && isActive === false &&
        validateSubmition(updatedData)
      ) {
        const secondApiData = updatedData;
        const secondApiResponse = await postCapitalcallData(secondApiData)

        if (secondApiResponse.responseCode === 200) {
          // console.log('Second API call successful');
          fetchData()
          setrowccnumber(0)

        } else {
          toast.error('Something went wrong , please try again', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            style: {
              marginTop: '100px',
            },
          });
          fetchData()
          setrowccnumber(0)

        }
      } else if ((isActive === true)
        && validateSubmition(updatedData)
      ) {
        // console.log("data to be posted: ", updatedData);

        const response = await postCapitalcallData(updatedData)

        if (response.responseCode === 200) {
          // console.log('Data posted successfully');

          SetAddingCC(false);
          SetPreviousCC(true);
          fetchData();
        } else {
          toast.error('Something went wrong , please try again', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            style: {
              marginTop: '100px',
            },
          });
        }
      } else {
        toast.error('Please provide mandatory field values', {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          style: {
            marginTop: '100px',
          },
        });
        setIsEditing(true);
        setshowSave(false);
      }
    } catch (error) {
      // console.error('An error occurred:', error);
    }


  }

  //## Field Input Change Eventas
  const handleInputChange = (name, value) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));

    // console.log(`Handling input change for field: ${name}, value: ${value}`);
    if (name === 'totalCCcalled') {
      handleTotalCapitalCalledChange(name, value)
    }
    else {
      const validationResult = validateField(name, value);

      if (!validationResult.isValid) {
        // console.log(`Validation failed for field: ${name}, error: ${validationResult.error}`);
        setErrors(prevErrors => ({ ...prevErrors, [name]: validationResult.error }));
      } else {
        // console.log(`Validation passed for field: ${name}`);
        setErrors(prevErrors => ({ ...prevErrors, [name]: null }));
      }
    }
  };

  const handleNotificationChange = (field, value) => {
    setNotificationType(value);
    setIsReasonValid(value !== '');
  };

  const handleEmergencySituationChange = (field, value) => {
    setEmergencysituation(value);
  }

  const targetraiseChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  }
  const handleTotalCapitalCalledChange = (field, value) => {
    const crntValue = value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));

    calculateOwnershipForSelectedRows(selectedIds, crntValue)
  };

  //## Caluculation Part
  const onRowsSelectionHandler = (ids) => {
    setSelectedIds(ids);
    calculateOwnershipForSelectedRows(ids, formData?.totalCCcalled);

  };
  const calculateOwnershipForSelectedRows = (selectedIds, totalcapitalcalled) => {
    /* const totalSelectedSubscribed = selectedIds?.reduce((total, id) => {
      const selectedRow = ccgridrows?.find((row) => row?.id === id);
      return total + (selectedRow ? selectedRow?.subscribed : 0);
    }, 0); */
    const totalSelectedUnfunded = selectedIds?.reduce((total, id) => {
      const selectedRow = ccgridrows?.find((row) => row?.id === id);
      return total + (selectedRow ? selectedRow?.unfundedAmount : 0);
    }, 0);
    const updatedInvestors = originalCCInvestors?.capitalCallInvestors?.map((row) => {
      const isSelected = selectedIds?.includes(row?.investorFundSubscriptionsID);
      const MainTotalCapitalCalled = totalcapitalcalled ? totalcapitalcalled : totalCapitalCalled
      const callAmountPercentage = ((row?.unfundedAmount / totalSelectedUnfunded))
      const SelectedInvestorCallAmount = isSelected ? (MainTotalCapitalCalled * callAmountPercentage) : 0
      if (isSelected) {
        if (SelectedInvestorCallAmount > row.unfundedAmount) {
          toast.error("Investor call amount cannot exceed unfunded amount", {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            style: {
              marginTop: '100px',
            },
          });
        }
        setSavebtnDisabled(SelectedInvestorCallAmount > row.unfundedAmount);
      }
      return {
        ...row,
        ccOwnership: isSelected
          ? ((row?.unfundedAmount / totalSelectedUnfunded) * 100) : 0,
        callAmount: SelectedInvestorCallAmount
      };
    });
    setCapitalcallDetails({ ...originalCCInvestors, capitalCallInvestors: updatedInvestors });
  };

  const validateSubmition = (updatedData) => {
    return updatedData.capitalCallInvestors.length !== 0
  };

  const handleSaveClick = () => {
    const mandatoryFieldErrors = MandatoryFieldErrors();
    const errorsArray = Object.values(errors);
    const hasErrors = errorsArray.some(error => error !== null);

    if (mandatoryFieldErrors?.length > 0 || hasErrors) {
      toast.error("Please review the form and ensure all fields are filled out correctly", {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
        style: {
          marginTop: '100px',
        },
      });
    } else if (initiallySelectedRows.length === 0) {
      toast.error("Please select Investors", {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
        style: {
          marginTop: '100px',
        },
      });

    } else {
      setIsEditing(false);
      onEditSaveClick(true);
      setshowSave(false);
      setshowEdit(true);
      fetchData();
    }
  };

  //## DataGrid Rows & Columns Data Binding
  const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'date', headerName: 'Date', width: 150 },
    { field: 'status', headerName: 'Status', width: 150 },
    {
      field: 'targetRaise', headerName: 'Target Raise', width: 150, renderCell: (params) => {
        return <GridCurrencyFormater params={params} />
      }
    },
    {
      field: 'subscribedCommitted', headerName: 'Subscribed/Committed', width: 150, renderCell: (params) => {
        return <GridCurrencyFormater params={params} />
      }
    },
    {
      field: 'totalContributed', headerName: 'Total Contributed', width: 150, renderCell: (params) => {
        return <GridCurrencyFormater params={params} />
      }
    },
    {
      field: 'totalReceived', headerName: 'Total Received', width: 150, renderCell: (params) => {
        return <GridCurrencyFormater params={params} />
      }
    },
    { field: 'numInvestors', headerName: '# of Investors', width: 150 },
  ];

  const rows = PreviousCapitalCallsForFund ? PreviousCapitalCallsForFund?.map(ccrows => ({
    id: ccrows?.capitalCallID,
    date: moment(ccrows?.ccDate)?.format("MM-DD-YYYY"),
    status: ccrows?.capitalCallStatus,
    targetRaise: ccrows?.fundSize,
    subscribedCommitted: ccrows?.committedCapital,
    totalContributed: ccrows?.ccTotalFundsReceived,
    totalReceived: ccrows?.ccTotalFundsReceived,
    numInvestors: ccrows?.noOfInvestors
  })) : [];

  const ccInvestorcolumns = [

    {

      "id": 1,
      "field": "investorName",
      "headerName": "INVESTOR NAME",
      "width": 110
    },

    {
      "id": 3,
      "field": "investorType",
      "headerName": "INVESTOR TYPE",
      "width": 110
    },

    {
      "id": 2,
      "field": "capitalCallPaymentStatus",
      "headerName": "STATUS",
      "width": 110
    },



    {
      "id": 4,
      "field": "callAmount",
      "headerName": "CALL AMOUNT",
      "width": 160,
      renderCell: (params) => {
        return <GridCurrencyFormater params={params} />
      }



    },

    {
      "id": 5,
      "field": "subscribed",
      "headerName": "SUBSCRIBED",
      "width": 110,
      renderCell: (params) => {
        return <GridCurrencyFormater params={params} />
      }

    },

    {
      "id": 6,
      "field": "contributedAmount",
      "headerName": "CONTRIBUTED",
      "width": 110,
      renderCell: (params) => {
        return <GridCurrencyFormater params={params} />
      }

    },

    {
      "id": 7,
      "field": "unfundedAmount",
      "headerName": "UNFUNDED",
      "width": 110,
      renderCell: (params) => {
        return <GridCurrencyFormater params={params} />
      }

    },

    {
      "id": 8,
      "field": "ccOwnership",
      "headerName": "OWNERSHIP",
      "width": 110,
      renderCell: (params) => {
        return (
          <div>
            %{params?.row?.ccOwnership}
          </div>
        );
      }


    },
    {
      "id": 9,
      "field": "ccReceivedAmount",
      "headerName": "RECEIVED",
      "width": 110,
      renderCell: (params) => {
        return <GridCurrencyFormater params={params} />
      }

    },
    {
      "id": 10,
      "field": "dateReceived",
      "headerName": "DATE RECEIVED",
      "width": 110
    }
  ];

  const ccgridrows = CapitalcallDetails ? CapitalcallDetails?.capitalCallInvestors?.map(ccrows => ({
    fundID: ccrows?.fundID,
    capitalCallID: rowccnumber,
    investorFundSubscriptionsID: ccrows?.investorFundSubscriptionsID,
    id: ccrows.investorFundSubscriptionsID,
    investorID: ccrows?.investorID,
    ccInvstrID: ccrows?.ccInvstrID,
    investorName: ccrows?.investorName,
    capitalCallPaymentStatusID: ccrows?.capitalCallPaymentStatusID,
    capitalCallPaymentStatus: ccrows?.capitalCallPaymentStatus,
    investorType: ccrows?.investorType === "0" ? "--" : ccrows?.investorType,
    callAmount: (ccrows?.callAmount),
    "subscribed": (ccrows?.subscribed),
    "contributedAmount": (ccrows?.contributedAmount),
    "unfundedAmount": (ccrows?.unfundedAmount),
    "ccOwnership": ccrows?.ccOwnership,
    ccReceivedAmount: (ccrows?.ccReceivedAmount),
    "dateReceived": formData?.statusOfCapitalCall === 'Executed' && ccrows?.dateReceived !== null ? moment(ccrows?.dateReceived).format("MM-DD-YYYY") : null
  })) : [];

  const updatedCCGridRows = ccgridrows?.map((row) => {
    const { id, ...rest } = row;
    return rest;
  });

  const initiallySelectedRows = ccgridrows?.filter((row) => row?.ccOwnership > 0)?.map((row) => row?.id); //## Selected Investor Checkboxes in Grid

  // console.log('currencyvalue', formData?.currency)
  return (
    <div style={{ backgroundColor: rootBackColorConfig.color }} className='mt-page'>
      <PageHeader
        onBtnClick={BackArrowClick}
        headerImage={secureLocalStorage.getItem("selectedFund")?.IconImage}
        //btnLabel={props?.buttonName}
        //btnIcon={props?.buttonIcon}
        template={HeaderTemplates?.Standard}
        fundName={fundname}
        customBackgroundImage={secureLocalStorage.getItem("selectedFund")?.BGImage ? secureLocalStorage.getItem("selectedFund")?.BGImage : '/DefaultBackgroundImage.png'}
      />
      <div className='capitalCallSec'>
        <Grid container>
          <Grid item xs={8} >
            <h3>Capital Call Activity</h3>
          </Grid>
          <Grid item xs={4}>
            <div style={{ display: "flex", float: "right" }} >

              {CapitalcallDetails?.ccStatusID === 3 && AddingCC === true ? (
                <div> <Button variant="contained" className='btn-primary'
                  startIcon={<SendIcon />}
                  onClick={openDialog}
                >
                  Resend Notification
                </Button></div>
              ) : (
                <div>
                  {AddingCC && (
                    <Button
                      variant="outlined"
                      color="primary"
                      size="medium"
                      startIcon={<SendIcon />}
                      disabled={!isEditing || formData.statusOfCapitalCall === "Draft"}
                      sx={{
                        backgroundColor: !isEditing || formData.statusOfCapitalCall === "Draft" ? 'gray' : '#1f61c5',
                        color: 'white',
                        marginRight: '5px',
                        '&:hover': {
                          backgroundColor: !isEditing || formData.statusOfCapitalCall === "Draft" ? 'gray' : '#1f61c5',
                          color: 'white',
                        },
                        '&:focus': {
                          backgroundColor: !isEditing || formData.statusOfCapitalCall === "Draft" ? 'gray' : '#1f61c5',
                          color: 'white',
                        },
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      onClick={openDialog}
                    >
                      EXECUTE CALL
                    </Button>
                  )}
                </div>
              )}

            </div>
          </Grid>
        </Grid>
      </div>
      <Dialog open={isDialogOpen} onClose={closeDialog} >
        <DialogTitle>
          <Text label={CapitalcallDetails?.ccStatusID === 3 ? 'Resend Notifications' : 'Confirmation'} />
        </DialogTitle>
        <DialogContent>
          {CapitalcallDetails?.ccStatusID === 3 &&
            <div>
              <strong>Reason <span className="RequiredSymbol"></span></strong>
              <TextInput
                type='select'
                defaultValue={'Select'}
                options={[
                  { label: 'Missed Notifications', value: 'Missed Notifications' },
                  { label: 'Deadline Extension', value: 'Deadline Extension' },
                  { label: 'Regulatory Changes', value: 'Regulatory Changes' },
                  { label: 'Emergency Situations', value: 'Emergency Situations' }]}
                onChange={(name, value) => handleNotificationChange(name, value)}
              />
              {!isReasonValid && (
                <div style={{ color: 'red', marginTop: '5px' }}>Please select a reason</div>
              )}
            </div>
          }
          <br></br>
          <strong>Capital Call ID</strong> : {rowccnumber}
          <br></br>
          <strong>Target Raise Amount</strong> : {formData.targetRaise}
          <br></br>
          <strong>Purpose</strong> : {formData?.purposeOfCall}
          <br></br> <br></br>

          <div className={CapitalcallDetails?.ccStatusID === 2 ? "disablediv" : ''}>  <FirstComponent
            fieldLabel={<span className="RequiredSymbol">Payment Due Date</span>}
            fieldName="paymentDueDate"
            isSaveEnabled={true}
            defaultValue={formData?.paymentDueDate}
            variant='standard'
            onhandleChange={(fieldName, value) => handleInputChange(fieldName, value)}
          /></div>

          <br></br> <br></br>
          {NotificationType === "Emergency Situations" &&
            <TextInput
              multiline
              label={'Emergency Situations'}
              defaultValue={EmergencySituation ? " " : EmergencySituation}
              onChange={(name, value) => handleEmergencySituationChange(name, value)}
            />
          }
          <div style={{ overflow: 'scroll !important' }}>
            <AIPDataGrid rows={ccgridrows?.filter((row) => row?.ccOwnership > 0) ?? []} columns={ccInvestorcolumns} isRowSelectable={(params) => ((params.row.ccOwnership) < 0)}
              onRowsSelectionHandler={onRowsSelectionHandler} checkboxSelection={true} initiallySelectedRows={initiallySelectedRows}
            />
          </div>
          <br></br> <br></br>
          <strong>Action Summary:</strong>
          <br></br>
          Capital call notices will be sent to the selected Investors. once executed, this action cannot be undone.
          <br></br> <br></br>
          <strong>Do you want to proceed with the Capital Call?</strong>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} className='btn-primary' variant='outlined'>
            <CancelIcon />
            Cancel
          </Button>
          <Button onClick={onPopupConfirmClick} className='btn-primary' variant='contained'>
            <CheckCircleOutlineIcon />
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {AddingCC && dataRetrived &&
        <Grid container xs={12}>
          <Grid item xs={12}>
            <CapitalCall
              onRowClick={onDraftStatusClick}
              isAddCallClick={isAddCallClick}
              savebtnDisabled={savebtnDisabled}
              rows={rows} columns={columns}
              isEditing={isEditing}
              rowccnumber={rowccnumber}
              formRefresh={formRefresh}
              onEditSaveClick={onEditSaveClick}
              CapitalcallDetails={CapitalcallDetails}
              formData={formData}
              handleEditClick={handleEditClick}
              handleSaveClick={handleSaveClick}
              setIsEditing={setIsEditing}
              handleInputChange={handleInputChange}
              totalCapitalCalled={totalCapitalCalled}
              setTotalCapitalCalled={setTotalCapitalCalled}
              ccgridrows={ccgridrows}
              totalCommittedCapital={totalCommittedCapital}
              settotalCommittedCapital={settotalCommittedCapital}
              onRowsSelectionHandler={onRowsSelectionHandler}
              selectedIds={selectedIds}
              handleTotalCapitalCalledChange={handleTotalCapitalCalledChange}
              targetraiseChange={targetraiseChange}
              status={status}
              showSave={showSave}
              showEdit={showEdit}
              SetAddingCC={SetAddingCC}
              AddCallClick={AddCallClick}
              SetPreviousCC={SetPreviousCC}
              fetchData={fetchData}
              errors={errors}
              setErrors={setErrors}
            />

          </Grid>
        </Grid>
      }
      <div className='capitalCallSec'>
        <Grid container className='whiteCard' style={{ padding: '16px' }}>
          <Grid item xs={6}>
            <h6> Previous Capital Calls</h6>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            {isPrevCallshaveActive === false && !isFundMatured && !isFundTerminated && checkFeaturePermissions(RolesAndPermissions.CAPITAL_CALL, FeatureAccess.CREATE) && parseFloat(capitalCalled) !== parseFloat(capitalCommitted)  && (
              <Button
                variant="outlined"
                color="primary"
                size="medium"
                startIcon={<ControlPointOutlinedIcon />}
                onClick={() => AddCallClick(0, false)}>
                ADD CALL
              </Button>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: '10px' }}>
            <div className='margin-top-10'>
              {PreviousCapitalCallsForFund === null ? 'No Previous Capital Calls'
                : checkFeaturePermissions(RolesAndPermissions.CAPITAL_CALL, FeatureAccess.READ) && (
                  <StackView
                    rows={rows.filter(u => u.status !== "Draft")}
                    columns={columns}
                    handleRowClick={(row) => { onDraftStatusClick(row, true) }}
                    norowsMessage={"No Capital call raised"}
                    onRowsSelectionHandler={() => { }}
                  />
                )}
            </div>
          </Grid>
        </Grid>
      </div>
      <ToastContainer />

    </div>
  )
}

export default CapitalCallActivity