import React, { useState } from 'react';
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, TextField, Radio, Button, MenuItem } from '@mui/material';
import secureLocalStorage from 'react-secure-storage';
import FirstComponent from '../../common/DatePicker/DatePicker';
import { toast } from 'react-toastify';
import ActionButton from '../../common/ActionButton/ActionButton';
import FileUploadBtn from '../../common/FileUploadButton/FileUploadBtn';
import { uploadFileTypes } from '../../common/FileUploadButton/uploadComponentenum';
import CloseIcon from '@mui/icons-material/Close';
import { postNewUser } from '../Services/TabsData';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import CancelIcon from '@mui/icons-material/Cancel';
import ALTTextField from '../../common/input-fields/ALTTextField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { validationsConstants } from '../../../utils/AppConstants';
import SelectField from '../../common/input-fields/SelectField';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import DateField from '../../common/input-fields/DateField';
import { textFormatTypeEnum } from '../../common/TextInput/appInputenum';
import moment from 'moment/moment';
import dayjs from 'dayjs';

const AddUser = (props) => {

  //#region props
  const { open, onClose, rolesDropDown, getAllUsers } = props;

  //#region initialData
  const initialData = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    userRoleId: '',
    birthDate: '',
    phoneNo: '',
    description: '',
    profileIcon: '',
  }

  //#region variables
  const [loading, setLoading] = useState(false);
  const today = new Date();
  const hundredYearsAgo = new Date(today.getFullYear() - 100, today.getMonth(), today.getDate());
  const rolesDetails = rolesDropDown.filter(role => role.roleName !== "Portfolio Manager" && role.roleName !== "Investor");

  //#region change events
  const handleFileUpload = (name, file) => {
    if (file) {
      const formData = new FormData();
      formData.append(name, file, file.name);
      handleChange(name, file);
    }
    else {
      handleChange(name, '');
    }
  }

  //#region change events
  const handleChange = (name, value) => {
    if(name == "birthDate" && 
       dayjs(moment(value)).format("YYYY-MM-DD hh:mm:ss") == '1970-01-01 05:30:00')
    {
        value = '';
    }
    userData.setFieldValue(name, value);
  }

  //#region api post call
  const handleSubmit = async () => {
    setLoading(true);
    const matchingRole = rolesDetails.find((item) => item.roleId === userData.values.userRoleId);
    const roleName = matchingRole ? matchingRole.description : null;
    const requestBody = {
      userId: 0,
      userFirstName: userData.values.firstName,
      userLastName: userData.values.lastName,
      userFullName: `${userData.values.firstName} ${userData.values?.lastName}`,
      userEmailAddress: userData.values.emailAddress,
      phoneNumber: userData.values.phoneNo,
      dateOfBirth: userData.values.birthDate || null,
      briefDescription: userData.values.description,
      tenantGUID: secureLocalStorage?.getItem("tenantID"),
      userRoleID: userData.values.userRoleId,
      userRoleName: roleName,
      userRoles: [
        {
          "userId": 0,
          "roleId": userData.values.userRoleId,
          "roleName": roleName,
          "isPrimary": true
        }
      ],
    };
    const formData = new FormData();
    formData.append('profileImage', userData.values.profileIcon);
    formData.append('tenantuserData', JSON.stringify(requestBody));
    const result = await postNewUser(formData);
    if (result.responseCode === 200) {
      getAllUsers();
      toast.success("User Created Successfully",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      setLoading(false);
      onClose();
    }
    else if (result.responseCode === 422) {
      toast.error("Unable to create the User",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      setLoading(false);
      onClose();
    }
    else if (result.responseCode === 409) {
      toast.warning("This email id is already registered",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      setLoading(false);
      onClose();
    }
    else {
      toast.warning("Unable to create the User",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      setLoading(false);
      onClose();
    }
  }

  //#region formik validations
  const addUserValidationSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(validationsConstants.REQUIRED)
      .matches(/^[A-Za-z\s]+$/, validationsConstants.ONLY_ALPHABETS),
    lastName: yup
      .string()
      .required(validationsConstants.REQUIRED)
      .matches(/^[A-Za-z\s]+$/, validationsConstants.ONLY_ALPHABETS),
    emailAddress: yup
      .string()
      .required(validationsConstants.REQUIRED)
      .email(validationsConstants.EMAIL_ADDRESS),
    userRoleId: yup
      .string()
      .required(validationsConstants.REQUIRED),
    birthDate: yup
      .date()
      .max(today, 'Please select a valid Date of Birth')
      .min(hundredYearsAgo, 'Please select a valid Date of Birth')
      .nullable(),
    phoneNo: yup.string().when([], (phoneNo, schema) => {
      if (userData?.values?.phoneNo && userData.values.phoneNo.length > 0) {
        return schema.test('is-us-phone', validationsConstants.US_PHONE_NUMBER, value => {
          const usPhoneRegex = /^(\(\d{3}\) ?|\d{3}-)\d{3}-\d{4}$/;
          return usPhoneRegex.test(value);
        });
      }
      return schema;
    }),
  });

  const userData = useFormik({
    initialValues: initialData,
    validationSchema: addUserValidationSchema,
    onSubmit: (values) => {
      handleSubmit()
    },
  });

  //#region return
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>
        <div className='space-between'>
          <h6>Add User</h6>
          <div>
            <CloseIcon onClick={onClose} cursor='pointer' />
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={userData.handleSubmit} novalidate="novalidate">
          <div className='space-between'>
            <div></div>
            <div>
              <FileUploadBtn className='uploadbtn'
                name='profileIcon'
                label="Upload Profile Picture"
                fileType={uploadFileTypes.IMAGE}
                required={false}
                defaultFile={userData.values.profileIcon}
                onChange={(name, value) => handleFileUpload(name, value)} />
            </div>
          </div>
          <div className='margin-top-15'>
            <ALTTextField
              name="firstName"
              label="First Name"
              value={userData?.values?.firstName}
              onChange={(name, value) => handleChange(name, value)}
              required={true}
              onBlur={userData.handleBlur}
              error={userData.touched.firstName && Boolean(userData.errors.firstName)}
              errorMessage={userData.touched.firstName && userData.errors.firstName} />
          </div>
          <div className='margin-top-15'>
            <ALTTextField
              name="lastName"
              label="Last Name"
              value={userData?.values?.lastName}
              onChange={(name, value) => handleChange(name, value)}
              required={true}
              onBlur={userData.handleBlur}
              error={userData.touched.lastName && Boolean(userData.errors.lastName)}
              errorMessage={userData.touched.lastName && userData.errors.lastName} />
          </div>
          <div className='margin-top-15'>
            <ALTTextField
              name="emailAddress"
              label="Email"
              value={userData?.values?.emailAddress}
              onChange={(name, value) => handleChange(name, value)}
              required={true}
              onBlur={userData.handleBlur}
              error={userData.touched.emailAddress && Boolean(userData.errors.emailAddress)}
              errorMessage={userData.touched.emailAddress && userData.errors.emailAddress} />
          </div>
          <div className='margin-top-15'>
            <DateField
              name="birthDate"
              label="Date Of Birth"
              value={userData?.values?.birthDate}
              onChange={(name, value) => handleChange(name, value)}
              required={false}
              disableFuture={true}
              onBlur={userData.handleBlur}
              error={userData.touched.birthDate && Boolean(userData.errors.birthDate)}
              errorMessage={userData.touched.birthDate && userData.errors.birthDate} />
          </div>
          <div className='margin-top-15'>
            <ALTTextField
              name="phoneNo"
              label="Phone Number"
              textFormatType={textFormatTypeEnum.US_PHONE_NUMBER}
              value={userData?.values?.phoneNo}
              onChange={(name, value) => handleChange(name, value)}
              onBlur={userData.handleBlur}
              error={userData.touched.phoneNo && Boolean(userData.errors.phoneNo)}
              errorMessage={userData.touched.phoneNo && userData.errors.phoneNo} />
          </div>
          <div className='margin-top-15'>
            <SelectField
              name="userRoleId"
              label="Select Role"
              value={userData?.values?.userRoleId}
              onChange={(name, value) => handleChange(name, value)}
              options={rolesDetails?.map(item => ({ value: item.roleId, label: item.roleName })) || []}
              required={true}
              onBlur={userData.handleBlur}
              error={userData.touched.userRoleId && Boolean(userData.errors.userRoleId)}
              errorMessage={userData.touched.userRoleId && userData.errors.userRoleId} />
          </div>
          <div className='margin-top-15'>
            <ALTTextField
              name="description"
              placeholder={`Brief Description about their role , resposnsibilities and fund history`}
              variant='outlined'
              multiline={true}
              rows={6}
              value={userData?.values?.description || []}
              onChange={(name, value) => handleChange(name, value)}
              required={true}
              onBlur={userData.handleBlur}
              charactersMaxLength={4000}
              error={userData.touched.description && Boolean(userData.errors.description)}
              errorMessage={userData.touched.description && userData.errors.description} />
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant='outlined'
          className='btn-primary'
          onClick={onClose}
          startIcon={<CancelIcon />}
          loadingPosition="start">
          <p className={`actionButtonLabel`}>Cancel</p>
        </LoadingButton>
        <LoadingButton
          variant='contained'
          className='btn-primary'
          loading={loading}
          onClick={userData.handleSubmit}
          startIcon={<TouchAppIcon />}
          loadingPosition="start">
          <p className={`actionButtonLabel`}>SUBMIT</p>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default AddUser;
