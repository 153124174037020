import React from 'react'
import Paper from '@mui/material/Paper';
import { Grid } from "@mui/material";
import BasicTabs from '../../PortfolioCompanies/components/TabSwitch';
import MiniDrawer from '../../common/navigation-bar/NavBarGrid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import TextInput from "../../common/TextInput/TextInput";
import styles from "../FundManagerUserProfile.module.css";
import userprofilestyles from "../userprofile1.module.css";
import Divider from '@mui/material/Divider';
import { useState, useEffect } from 'react';
import { getInvestorDetails } from './Services/Investorinfo';
import secureLocalStorage from 'react-secure-storage';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import './styles.jsx'
import UserProfilePopUp from '../components/UserProfilePopUp';
import { cursorpointer } from './styles.jsx';
import { UpdateProfileInvestmentInformation } from './Services/Investorinfo';
import { ToastContainer, toast } from 'react-toastify';
import OBQPopup from './OBQPopup.jsx';
import CancelIcon from '@mui/icons-material/Cancel';
import { convertStringToArray } from '../../common/Functions/ConvertStringtoArray.jsx';
import { getInvestorUserProfile, getobqQuestionOptions } from '../services/UserProfileService.js';
import ActionButton from '../../common/ActionButton/ActionButton.jsx';

const InvestorInvestmentinfo = (props) => {
  const [investmentData, setinvestmentData] = useState(props.data?.investmentProfile);
  const [userProfileData,setUserProfileData] = useState(null);

  const renderInvestmentDetail = (shortName, answer, item) => {
    return (
      <div className={styles.investmentDetailWrapper}>
        <div>
          {/* {<span className="requiredField">{shortName}</span>} */}
          {shortName}
        </div>
        <div className={styles.flexSpaceBetween}>
          <div className={styles.contentContainer}>
            {item.multipleAnswers ? 
              (item.answerValue ? item.answerValue : null) : (
              item.answer ? item.answer : null
            )}
          </div>
           {
              props.fromFm  ? null : <EditOutlinedIcon /* sx={{marginTop: '-10px'}} */ />
           }
        </div>
      </div>
    )
  }

  //PopUp
  const [showPopup, setShowPopup] = useState(false);
  const [popupdetails, setPopUpDetails] = useState();
  const [popupoptions, setPopUpOptions] = useState();

  const onPopupClick = async (details, options) => {

    try {
      const response = await getobqQuestionOptions(details);
      /*   */
      if(response?.responseCode === 200){
        setPopUpOptions(response?.responseData);
      }
      else {
        throw new Error('Network response was not ok'); 
        setPopUpOptions("");
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }

    setShowPopup(true);
    setPopUpDetails(details)
    console.log(popupdetails)
  }
  const handlePopUpCancel = () => {
    // Close the popup without removing the item
    setShowPopup(false);
  };



  const [isSaveEnabled, setSaveEnabled] = useState(false);

  const onEditSaveButtonClick = () => {
    if (isSaveEnabled) {
      handleSave();
    }
    props.setisSaveEnabled(true);
    setSaveEnabled(isSaveEnabled => !isSaveEnabled);
  };

  const onCancel = () => {
    /* setinvestmentData(props.initialdata?.investmentProfile);  */
    props.ChangeTabIndex(2);
    props.setisSaveEnabled(false);
    setSaveEnabled(false);
  };

  const handleSave = async () => {
    try {
      const requestData = {
        "personalInformation": props.data?.personalInformation,
        "bankingInformation": null,
        "investmentProfile": investmentData
      };
      /*   */
      toast.warning("Saving data, please wait...", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
      props.setData({
        "personalInformation": props.data?.personalInformation,
        "bankingInformation": props.data?.bankingInformation,
        "investmentProfile": investmentData
      });
      const result = await UpdateProfileInvestmentInformation(requestData, props.data?.personalInformation.userId);
      /*   */
      /* props.setisSaveEnabled(false); */
      if (result.status === 200) {
        /* props.setData(result.data); */
        /* toast.dismiss(); */
        toast.success("Successfully Saved", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "colored",
        });
        props.setisSaveEnabled(false);
        onCancel();
      } else {
        toast.dismiss();
        toast.error("Unable to Save the details", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "colored",
        });
        props.setisSaveEnabled(false);
      }
    } catch (error) {

    }
  }

  //#region api get calls
  const getUserProfileDetails = async() => {
    const data = await getInvestorUserProfile(secureLocalStorage.getItem("userEmail"));
    if(data.responseCode === 200){
       setUserProfileData(data?.responseData);
    }
    else{
      setUserProfileData(null);
    }
  }

  const updateExcistingDetails = (userProfileDetails) => {
    props.setData({
      "personalInformation": props.data?.personalInformation,
      "bankingInformation": props.data?.bankingInformation,
      "investmentProfile": userProfileDetails
    });
  }

  useEffect(()=>{
    getUserProfileDetails();
  },[])

  return (
    <div className={props.fromFm ? 'investmentprofile-fm' : ''}>
      {
        <div>
          {
            props.fromFm ? null :
              <Grid item xs={12} marginBottom={2}>
                <div className='space-between'>
                  <div>

                  </div>
                  <div className='space-between'>
                    {/* <div style={{ marginRight: '5px' }}>
                      {
                        isSaveEnabled &&
                        <ActionButton variant='outlined' className='btn-primary'
                          onClick={() => onCancel()} 
                          label="Cancel"
                          icon={<CancelIcon />}>
                         
                        </ActionButton>
                      }

                    </div>
                    <div>
                      <ActionButton variant='contained' className='btn-primary' 
                        icon={isSaveEnabled ? <SaveIcon /> : <EditIcon />}
                        onClick={() => onEditSaveButtonClick()} 
                        label={isSaveEnabled ? 'Save' : 'Edit'} 
                       >
                      </ActionButton>
                    </div> */}
                  </div>
                </div>
              </Grid>
          }

          <Box className='whiteCard'>

            {
              props.fromFm ? null :
                <Grid container spacing={2} xs={12} marginTop={1}>

                  <Grid item sm={2} md={0.7} lg={1} marginTop={-1} marginLeft={1}>

                    <img
                      className={userprofilestyles.avatarIcons}
                      alt=""
                      src={props.selectedImage  ? props.selectedImage  : '/icons/defaultpropic.jpg'}
                      />
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>

                    <TextInput
                      defaultValue={props.data?.personalInformation?.firstName}
                      label="First Name"
                      editable={false}
                      required={true}
                      onChange={() => { }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <TextInput
                      defaultValue={props.data?.personalInformation?.lastName}
                      label="Last Name"
                      editable={false}
                      required={true}
                      onChange={() => { }}
                    />
                  </Grid>

                </Grid>
            }
            <Grid container xs={12}>
              {investmentData?.map((item, index) => (
                <Grid xs={12} sm={4} md={4} onClick={() => onPopupClick(item, item.questionID)}>
                  {renderInvestmentDetail(item?.shortName, item?.answer, item)}
                </Grid>
              ))}
            </Grid>

            {/* {showPopup && (
                        <UserProfilePopUp PopupQuestion={popupdetails} PopupOptions={popupoptions} onCancel={handlePopUpCancel} investment={investmentData}
                          onOptionSelected={(index, option) => { handleOptionSelected(index, option) }}
                        />
                      )} */}

            {showPopup && (
              <OBQPopup data={popupdetails} investmentData={investmentData} setData={setinvestmentData}
                onCancel={handlePopUpCancel} popupoptions={popupoptions}
                open={showPopup} userProfileData={userProfileData} updateExcistingDetails={updateExcistingDetails}  getUserProfileDetails={getUserProfileDetails}
              />
            )}

          </Box>
          <ToastContainer />
        </div>

      }
    </div>
  )
}

export default InvestorInvestmentinfo