import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MarkAsRead, getNotificationsbyUserID } from '../../Services/GroupsServices';
import secureLocalStorage from 'react-secure-storage';
import { Grid, LinearProgress } from '@mui/material';
import IndividualNotification from './IndividualNotification';

const NotificationsInbox = () => {
  //#region variables
  const [notifications,setNotifications] = useState(null);
  
  //#region api get calls
  const getNotifcations = async() => {
        const data = await getNotificationsbyUserID(secureLocalStorage.getItem('userId'));
        if (data.responseCode === 200) {
            setNotifications(data.responseData);
        }
        else{
            setNotifications([]);
            /* console.error('Error fetching data:', error); */
        }
  }

  //#regionn useEffect
   useEffect(()=>{
        getNotifcations();
    },[])

  return (
    <div>
        {
            notifications ? 
                <div>
                   {notifications.length > 0 ?
                        notifications.map((item,index) => (
                            <div key={index}>
                              <IndividualNotification notification={item}/>
                            </div>
                        ))
                   : 
                   <div className='whiteCard'>
                     No notifications available
                    </div>} 
                </div>
             : <LinearProgress />
        }
    </div>
  )
}

export default NotificationsInbox