import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

function LoginContainer({ children }) {
  const navigate = useNavigate();
  return (
    <>
    <div className="signup_wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4 col-lg-6 bg-blue">
            <div className="reg-logo">
              <a href="/" className="cursor-pointer logoreg">
                <img src="/Alt360-Logo-white.png" />
              </a>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 col-xs-12 order-2 order-md-2">{children}</div>
        </div>
      </div>
      </div>
    </>
  );
}
export default LoginContainer;
