
import axiosApiCall from "../../Services/axiosApiCall";
import { getAPIResponse } from "../../../utils/common";
import secureLocalStorage from "react-secure-storage";

//secureLocalStorage.setItem("tenantID"
export async function getGroupsList(tenantID) {
    return await axiosApiCall.get(`/v1/UserGroup/list?tenantGUID=${tenantID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getInvestorsandGroupsList() {
    return await axiosApiCall.get(`/v1/UserRole/GetTenantInvestorsAndGroups?tenantGUID=${secureLocalStorage.getItem("tenantID")}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getGroupByID(id) {
    return await axiosApiCall.get(`/v1/UserGroup/Get?id=${id}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function CreateGroup(userGroup) {
    return await axiosApiCall.post(`/v1/UserGroup/create`, userGroup)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function updateGroupData(data) {
    return await axiosApiCall.post(`/v1/UserGroup/update`, data)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function PostNewNotifications(data) {
    return await axiosApiCall.post(`/v1/AppNotifications/LogAppNotificationDetails`, data)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}
export async function UpdateExistingDraftData(data) {
    return await axiosApiCall.post(`/v1/AppNotifications/LogAppNotificationDetails`, data)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getDraftNotifications() {
    return await axiosApiCall.get(`/v1/AppNotifications/GetUserAppNotifications?notifiedUserID=0&deliveryStatus=0`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function notificationsLength(userid) {
    return await axiosApiCall.get(`/v1/AppNotifications/GetUserAppNotifications?notifiedUserID=${userid}&deliveryStatus=1`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getNotificationsbyUserID(userid) {
    return await axiosApiCall.get(`/v1/AppNotifications/GetUserAppNotifications?notifiedUserID=${userid}&deliveryStatus=1`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}


export async function getSentNotifications() {
    return await axiosApiCall.get(`/v1/AppNotifications/GetUserAppNotifications?notifiedUserID=0&deliveryStatus=1`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getDraftandSentCount() {
    return await axiosApiCall.get(`/v1/AppNotifications/GetStatusWiseNotificationCount`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function MarkAsRead(NotificationID,UserID) {
    return await axiosApiCall.post(`/v1/AppNotifications/MarkNotificationAsRead?AppNotificationDetailID=${NotificationID}&AppNotificationToUserID=${UserID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function deleteGroupMember(memberID) {
    return await axiosApiCall.delete(`/v1/UserGroup/groupmember/delete?groupMemberID=${memberID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}
