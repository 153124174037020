import React, { useEffect } from 'react'
import { HeaderTemplates, PageHeader } from '../../common/PageHeader/PageHeader'
import FilterBox from '../../marketplace/components/FilterBox'
import { useState } from 'react'
import { getrootBackgroundColorConfig } from '../../../state/slices/colorConfigSlice'
import { useDispatch, useSelector } from 'react-redux';
// import '../styles.css';
import { addFavourite, getCompareFunds, getFavouriteFundsSlice, updateAllFunds } from '../../../state/slices/MarketPlaceSlice'
import { getFavoriteFunds, getMarketPlaceFunds } from '../services/mpservices'
import { getAllFunds } from '../../../state/slices/MarketPlaceSlice'
import FundCardView from './FundCardView'
import MPFilterBox from './MPFilterBox'
import { CircularProgress, Grid, Button, LinearProgress } from '@mui/material'
import secureLocalStorage from 'react-secure-storage'
import FundListView from './FundListView'
import { getFundsView } from '../../../state/slices/MarketPlaceSlice'
import { MarketPlaceView } from '../../../utils/enum'
import { getFundSubscriptions } from '../services/mpservices'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getFundFilterData } from '../../marketplace/services/MarketplaceService'
import { fundTypesEnum, minimumInvestmentOptions, privateCreditSortOptions, sortOptions, termOptions } from '../jsonData'
import { Position } from '@react-pdf-viewer/core'
import AddIcon from '@mui/icons-material/Add';
import CircleIcon from '../../chatbot/Icon'
const MarketplaceDescription = "Connecting investors with a wide range of non-traditional investment opportunities, while providing a transparent and accessible environment for investors to discover, evaluate, and participate inalternative investment options beyond the traditional stock and bond markets."

const MarketPlaceMain = () => {

    //#region variables
    const allFundsDetails = useSelector(getAllFunds);
    const [filteredFunds, setfilteredFunds] = useState([]);
    const [allFunds, setAllFunds] = useState(null);
    const [allInvestors, setAllInvestors] = useState([]);
    const [visibleItems, setVisibleItems] = useState(6);
    const [filterOptions, setFilterOptions] = useState(null);
    const comparefunds = useSelector(getCompareFunds);
    const favoritefunds = useSelector(getFavouriteFundsSlice);
    const [disableLoadMorebtn,setDisableLoadMorebtn] = useState(false)
    const [selectedFundType,setSelectedFundType] = useState(null);

    //#region redux variables
    const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);
    const currView = useSelector(getFundsView);
    const [view, setView] = useState(null);
    const dispatch = useDispatch();

    //#region functions
  const currFundType = (options) => {
    const selectedType = options.find(option => option.isSelected === true);
    return selectedType?.label
  }

    //#region click events
    const handleloadMore = () => {
        setVisibleItems(visibleItems + 6);
    };

    //#region api get calls
    const getFunds = async () => {
        const data = await getMarketPlaceFunds();
        if (data.responseCode === 200) {
            dispatch(updateAllFunds({ value: data.responseData/* .filter(u => u.fundStatusName !== 'Terminated') */ }));
            const filterFunds = data.responseData.filter(item => item.favourite === true);
            filterFunds.forEach(favFund => {
                dispatch(addFavourite({ fund: favFund }));
            });
            if(allFunds === null){
                setAllFunds(data.responseData);
            }
        } else {
            console.log('Unable to load funds');
        }
    }

    const getFundsSubscriptions = async () => {
        const data = await getFundSubscriptions();
        if (data.responseCode === 200) {
            setAllInvestors(data.responseData.filter(u => u.investorID === secureLocalStorage.getItem("userId") && u.subscriptionStatus !== "Rejected" && u.subscriptionStatus !== "Cancelled"));
        }
        else {
            console.log('unable to load funds')
        }
    }

    const getFundFilterCall = async (resetFundType) => {
        //#here reset fund type contain already selected values of fundtype 
        const data = await getFundFilterData();
        if (data.responseCode === 200) {
            const transformedData = {};
            // Convert the array of objects into objects with 'label' and 'value' properties
            Object.entries(data.responseData).forEach(([key, value]) => {
                transformedData[key] = value.map(item => {
                    // Find the first property that exists in the item
                    const labelKey = Object.keys(item).find(prop => prop.includes('Name') || prop.includes('targetIndustry'));
                    const valueKey = Object.keys(item).find(prop => prop.includes('Id') || prop.includes('ID'));
                    // Return an object with label and value properties
                    return {
                        label: labelKey ? item[labelKey] : '',
                        value: valueKey ? item[valueKey] : '',
                        isSelected: false
                    };
                });
            });
            transformedData.termOptions = termOptions;
            transformedData.sortOptions = sortOptions;
            transformedData.minimumInvestmentOptions = minimumInvestmentOptions;

            //#here we are using reset fund bcoz we need to reset all previous filters except fundtype
            if(resetFundType){
                transformedData.fundType = resetFundType;
                const fundType = currFundType(resetFundType);//here it is fund type options data
                if(fundType === fundTypesEnum.PRIVATE_EQUITY || fundType === fundTypesEnum.VENTURE_CAPITAL){
                    transformedData.sortOptions = sortOptions;
                }
                else if(fundType === fundTypesEnum.PRIVATE_CREDIT || fundType === fundTypesEnum.REAL_ESTATE_FUND){
                    transformedData.sortOptions = privateCreditSortOptions;
                }
                else{
                    transformedData.sortOptions = sortOptions;
                }
            }
            transformedData.fundType = transformedData.fundType?.filter((item)=>item.value != -1)
            setFilterOptions(transformedData);
        } else {
            // Handle error
        }
    }

    //#region useeffect
    useEffect(() => {
        getFundFilterCall();
        getFunds();
        getFundsSubscriptions();
    }, [])

   /*  useEffect(() => {
        setAllFunds(allFundsDetails.value);
    }, [allFundsDetails]) */

    useEffect(() => {
        setView(currView);
    }, [currView])

    useEffect(() => {
        //#region filters start
        const fundTypeFilteredFunds = selectedFundType === null ? allFundsDetails.value :
           allFundsDetails?.value?.filter((item) => {
            // Get the selected fund types
            const currfType = filterOptions?.fundType?.find((type) => type.value === selectedFundType)?.label;
            if(item.fundTypeName === currfType){
                return item;
            }
        });

        if (fundTypeFilteredFunds?.length > 0) {

            //#region variables
            let filteredFundTypes = [];
            let filteredIndustryFunds = [];
            let filteredStrategyFunds = [];
            let sortVariable = "";

            let termValue = filterOptions ? filterOptions["termOptions"].value : -1;
            let investmentValues = filterOptions ? filterOptions["minimumInvestmentOptions"] : {};

            //#region fund types
            //#region fund subtypes
            //#region industry
            const selectedIndustries = filterOptions?.industry?.filter((item) => item.isSelected === true);
            if (selectedIndustries?.length > 0) {
                filteredIndustryFunds = fundTypeFilteredFunds?.filter((item) => {
                    const matchingIndustries = selectedIndustries.filter((industry) => {
                        return industry.label === item.targetIndustry;
                    });
                    return matchingIndustries.length > 0;
                });
            }

            //#region strategy
            const selectedStrategies = filterOptions?.strategy?.filter((item) => item.isSelected === true);
            if (selectedStrategies?.length > 0) {
                filteredStrategyFunds = fundTypeFilteredFunds?.filter((item) => {
                    const matchingIndustries = selectedStrategies?.filter((industry) => {
                        return industry.label === item.strategyName;
                    });
                    return matchingIndustries.length > 0;
                });
            }

            //#region sorting
            const selectedSorting = filterOptions?.sortOptions?.find((item) => item.isSelected === true);
            if (selectedSorting) {
                if (selectedSorting?.label === "Oldest to Newest" ||
                    selectedSorting?.label === "Newest to Oldest") {
                    sortVariable = "fundID";
                }
                else if (selectedSorting?.label === "Min. Investment low to high" ||
                    selectedSorting?.label === "Min. Investment high to low") {
                    sortVariable = "minimumInvestmentAmount";
                }
                else if (selectedSorting?.label === "Term low to high" ||
                    selectedSorting?.label === "Term high to low") {
                    sortVariable = "investmentPeriodCriteria";
                }
                else {
                    sortVariable = "";
                }
            }
            else {
                sortVariable = "";
            }

           /*  //#region end */
            if (filteredIndustryFunds || filteredStrategyFunds || sortVariable !== "") {
                let filteredFunds = [];
                filteredFunds = fundTypeFilteredFunds?.filter((item) => {
                    const industryMatch = filteredIndustryFunds?.length > 0 && filteredIndustryFunds.includes(item);
                    const strategyMatch = filteredStrategyFunds?.length > 0 && filteredStrategyFunds.includes(item);
                    if (filteredIndustryFunds?.length > 0 && filteredStrategyFunds?.length > 0) {
                        return industryMatch && strategyMatch;
                    } else {
                        return industryMatch || strategyMatch;
                    }
                });
                const isFiltersApplied = filteredIndustryFunds?.length > 0 && filteredStrategyFunds?.length > 0;

                if (filteredFunds && filteredFunds.length > 0 || isFiltersApplied) {
                    //#region sorting in selected funds
                    if (sortVariable !== "") {
                        //sorting by descending
                        if (selectedSorting?.label === "Oldest to newest" ||
                            selectedSorting?.label === "Min. Investment low to high" ||
                            selectedSorting?.label === "Term low to high") {
                            filteredFunds = [...filteredFunds]?.sort((a, b) => a[sortVariable] - b[sortVariable]);  // for number
                        }
                        //sorting by ascending
                        else if (selectedSorting?.label === "Newest to Oldest" ||
                            selectedSorting?.label === "Min. Investment high to low" ||
                            selectedSorting?.label === "Term high to low") {
                            filteredFunds = [...filteredFunds]?.sort((a, b) => b[sortVariable] - a[sortVariable]);  // for number
                        }
                    }
                    //#region term filter
                    if (termValue >= 1) {
                        filteredFunds = filteredFunds.filter(fund => fund["investmentPeriodCriteria"] <= termValue);
                        if (filteredFunds.length === 0) {
                            filteredFunds = [];
                        }
                    }
                    //#region investment filter
                    if (investmentValues?.minimumvalue >= 0 && investmentValues?.maximumvalue >= 1) {
                        filteredFunds = [...filteredFunds]?.filter(fund =>
                            fund.minimumInvestmentAmount >= investmentValues.minimumvalue &&
                            fund.minimumInvestmentAmount <= investmentValues.maximumvalue
                        ).sort((a, b) => a.minimumInvestmentAmount - b.minimumInvestmentAmount);
                    }
                    setAllFunds(filteredFunds);
                }
                else {
                    //#region sorting in all funds
                    if (fundTypeFilteredFunds?.length > 0) {
                        let allFunds = [...fundTypeFilteredFunds]; // Create a new array to ensure modifiability
                        let mpfunds = [];
                        if (sortVariable !== "") {
                            // Sorting by descending
                            if (selectedSorting?.label === "Oldest to Newest" ||
                                selectedSorting?.label === "Min. Investment low to high" ||
                                selectedSorting?.label === "Term low to high") {
                                mpfunds = allFunds.sort((a, b) => a[sortVariable] - b[sortVariable]); // for number
                            }
                            // Sorting by ascending
                            else if (selectedSorting?.label === "Newest to Oldest" ||
                                selectedSorting?.label === "Min. Investment high to low" ||
                                selectedSorting?.label === "Term high to low") {
                                mpfunds = allFunds.sort((a, b) => b[sortVariable] - a[sortVariable]); // for number
                            }
                        }
                        //#region term filter
                        if (termValue >= 1) {
                            mpfunds = allFunds.filter(fund => fund["investmentPeriodCriteria"] <= termValue);
                            if (mpfunds?.length === 0) {
                                mpfunds = [];
                            }
                        }
                        //#region investment filter
                        if (investmentValues?.minimumvalue >= 0 && investmentValues?.maximumvalue >= 1) {
                            mpfunds = [...allFunds]?.filter(fund =>
                                fund.minimumInvestmentAmount >= investmentValues.minimumvalue &&
                                fund.minimumInvestmentAmount <= investmentValues.maximumvalue
                            ).sort((a, b) => a.minimumInvestmentAmount - b.minimumInvestmentAmount);
                        }
                        const isAnyFilterApplied = filterOptions?.industry?.some((item) => item.isSelected === true) ||
                            filterOptions?.strategy?.some((item) => item.isSelected === true) ||
                            filterOptions?.sortOptions?.some((item) => item.isSelected === true) ||
                            investmentValues?.minimumvalue >= 0 && investmentValues?.maximumvalue >= 1 ||
                            termValue >= 1

                        setAllFunds(isAnyFilterApplied ? mpfunds : fundTypeFilteredFunds);
                    }
                }
            }
            else {
                setAllFunds(fundTypeFilteredFunds);
            }
        }
        else{
            setAllFunds([]);
        }
        //#region filters end
    }, [filterOptions,selectedFundType])

    useEffect(()=> {
        if((visibleItems >= (allFunds?.filter(item => parseInt(item.visibility)).length))){
            setDisableLoadMorebtn(true)
        }
    },[visibleItems])

    //#region return
    return (
        <div style={{ backgroundColor: rootBackColorConfig.color }}>
            <PageHeader
                primaryTitle="Investment Marketplace"
                description={MarketplaceDescription}
                template={HeaderTemplates.BASIC} />
            {
                filterOptions ? 
                <>
                    <MPFilterBox 
                       filterOptions={filterOptions} 
                       setFilterOptions={setFilterOptions}
                       selectedFundType={selectedFundType}
                       setSelectedFundType={setSelectedFundType} 
                       resetFundTypeFilters = {getFundFilterCall} /> 
                    <div>
                        <>
                            {view && view === MarketPlaceView.BOX_VIEW ?
                                <>
                                    <div className='fund-view-mp mp-wrapper'>
                                        {
                                            allFunds && allInvestors &&
                                                allFunds?.filter(item => parseInt(item.visibility) === 1)?.length > 0
                                                ? allFunds?.filter(item => parseInt(item.visibility) === 1)?.slice(0, visibleItems)?.map((item, index) => (
                                                    <div key={index}>
                                                        <FundCardView
                                                            fundDetails={item}
                                                            allFunds={allFundsDetails.value}
                                                            allInvestors={allInvestors}
                                                            comparefunds={comparefunds}
                                                            favoritefunds={favoritefunds}
                                                            getFunds={getFunds} />
                                                    </div>
                                                )) :
                                                allFunds === null ? <CircularProgress /> :
                                                    "No funds"
                                        }
                                    </div>
                                    {
                                        allFunds && allFunds.length > 6 && visibleItems < allFunds?.length &&
                                        <div className='mp-wrapper align-items-center'>
                                            <Button
                                                component="label"
                                                variant="contained"
                                                className="btn-primary"
                                                startIcon={<AddIcon/>}
                                                disabled={disableLoadMorebtn}
                                                onClick={() => handleloadMore()}>
                                                LOAD MORE
                                            </Button>
                                        </div>
                                    }
                                </>
                                :    
                                <div className='list-view-mp wrapper margin-top-40'>
                                    {
                                        allFunds && allInvestors ?
                                                <div >
                                                    <FundListView
                                                        allFunds={allFunds}
                                                        allInvestors={allInvestors}
                                                        filterOptions={filterOptions}
                                                        selectedFundType={selectedFundType}
                                                        getFunds={getFunds}
                                                        comparefunds={comparefunds}
                                                        favoritefunds={favoritefunds} />
                                                </div>
                                                :
                                            allFunds === null ? <CircularProgress /> : "No funds"
                                    }
                                </div>
                            }
                        </>
                    </div>
                </> : 
                <Grid>
                    <LinearProgress />
                </Grid>
            }
                  <CircleIcon/>
        </div>
    )
}

export default MarketPlaceMain