import axios from 'axios';
import resolve from '../../../../common/resolve';
import secureLocalStorage from 'react-secure-storage';
import axiosApiCall from '../../../../Services/axiosApiCall';
import { getAPIResponse } from '../../../../../utils/common';

const fundId = secureLocalStorage.getItem("FundId");

export async function getFundDetails() {
    return await axiosApiCall.get(`/v1/Fund/GetFundById?fundID=${fundId}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}