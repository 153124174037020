import { ReactRenderer } from "@tiptap/react";
import tippy from "tippy.js";
import SuggestionList from "./SuggestionList";
import secureLocalStorage from "react-secure-storage";

const DOM_RECT_FALLBACK = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  x: 0,
  y: 0,
  toJSON() {
    return {};
  },
};

export const mentionSuggestionOptions = {
  items: async ({ query }) => {
    let suggestions = secureLocalStorage.getItem('EmailTemplateSuggestions');
    if (!suggestions) {
      return [];
    }
    const options = suggestions.split(',').map((option, index) => ({
      mentionLabel: option.trim(),
      id: index.toString()
    }));

    const filteredOptions = options.filter(option =>
      option.mentionLabel.toLowerCase().startsWith(query.toLowerCase())
    );

    return Promise.resolve(filteredOptions);
  },

  render: () => {
    let component;
    let popup;

    return {
      onStart: (props) => {
        component = new ReactRenderer(SuggestionList, {
          props,
          editor: props.editor,
        });

        popup = tippy("body", {
          getReferenceClientRect: () =>
            props.clientRect?.() ?? DOM_RECT_FALLBACK,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "bottom-start",
        })[0];
      },

      onUpdate: (props) => {
        component?.updateProps(props);

        popup?.setProps({
          getReferenceClientRect: () =>
            props.clientRect?.() ?? DOM_RECT_FALLBACK,
        });
      },

      onKeyDown: (props) => {
        if (props.event.key === "Escape") {
          popup?.hide();
          return true;
        }

        if (!component?.ref) {
          return false;
        }

        return component.ref.onKeyDown(props);
      },

      onExit: () => {
        popup?.destroy();
        component?.destroy();

        popup = undefined;
        component = undefined;
      },
    };
  },
};
