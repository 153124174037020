import React, { useEffect, useState } from 'react'
import ContextMenu from '../../../common/ContextMenu/ContextMenu';
import { UpdateEmailTemplate } from './Services/EventManagementResponse';
import EditIcon from "@mui/icons-material/Edit";
import RestoreIcon from '@mui/icons-material/Restore';
import { Divider } from '@mui/material';
import Switch from '@mui/material/Switch';
import EditEmailPopup from './Popups/EditEmailPopup';
import { ToastContainer, toast } from 'react-toastify';
import { ResetEmailTemplate } from './Services/EventManagementResponse';

const EmailsView = (props) => {

  //#region props
  const { EmailtemplatesDetails, isAccordianExpanded, EmailtemplatesData, emailTemplateID, setExpanded, getExcistingGroups, setComponentRefresh } = props;
  const [openPopup, setOpenPopup] = useState(false);
  const [EmailDetails, setEmailDetails] = useState([])
  const [isChecked, setIsChecked] = useState(EmailtemplatesDetails?.isActive);

  //#region variables

  const initialData = {
    emailTemplateID: EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).emailTemplateID,
    "emailTemplateName": EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).emailTemplateName,
    emailSubject: EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).emailSubject,
    emailBody: EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).emailBody,
    suggestions: EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).arguments,
    "description": EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).description,
    "arguments": EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).arguments,
    "createdBy": EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).createdBy,
    "createdDate": EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).createdDate,
    "updatedBy": EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).updatedBy,
    "updatedDate": EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).updatedDate,
    "templateGroupID": EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).templateGroupID,
    "groups": EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).groups,
    "templateSubGroupID": EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).templateSubGroupID,
    //"subgroups":EmailtemplatesData.find(u=>u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).subgroups,
    "isActive": EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).isActive,
    "initialEmailBody": EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).initialEmailBody
  }
  const handleChange = async (event) => {
    //setComponentRefresh(false)
    setIsChecked(event.target.checked);
    initialData.isActive = event.target.checked
    try {
      const data = await UpdateEmailTemplate(initialData);
      if (data.responseCode === 200) {
        // setLoading(false)
        // setComponentRefresh(true)
        toast.success("Email Template Updated Successfully",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        onClose();
        setExpanded(false)
        getExcistingGroups();
      } else {
        //setComponentRefresh(true)
        toast.error("Something went wrong, please try again",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        // setLoading(false);
        setExpanded(false)
        onClose();
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error
      toast.error("Something went wrong, please try again",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      // setLoading(false);
      onClose();
    }
  };




  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  //#region click events
  const onOpen = () => {
    const initialData = {
      id: EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).emailTemplateID,
      "emailTemplateName": EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).emailTemplateName,
      subject: EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).emailSubject,
      body: EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).emailBody,
      suggestions: EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).arguments,
      "description": EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).description,
      "arguments": EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).arguments,
      "createdBy": EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).createdBy,
      "createdDate": EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).createdDate,
      "updatedBy": EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).updatedBy,
      "updatedDate": EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).updatedDate,
      "templateGroupID": EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).templateGroupID,
      "groups": EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).groups,
      "templateSubGroupID": EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).templateSubGroupID,
      "subgroups": EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).subgroups,
      "isActive": EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).subgroups = isChecked,
      "initialEmailBody": EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).initialEmailBody
    }
    setEmailDetails(initialData)
    setOpenPopup(true);
  }

  const onClose = () => {
    setOpenPopup(false);
    // setComponentRefresh(false)
    // setTimeout(() => {
    //   setComponentRefresh(true)
    // }, 50);
  }

  //## Restore Email Template Service Call
  const handleResetEmailtemplateClick = async () => {
    setComponentRefresh(false)
    const data = await ResetEmailTemplate(EmailtemplatesData.find(u => u.emailTemplateID === EmailtemplatesDetails.emailTemplateID).emailTemplateID)
    if (data.responseCode === 200) {
      setComponentRefresh(true)
      toast.success("Email Template has been restored Successfully",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      onClose();
    }
    else {
      setComponentRefresh(true)
      toast.error("Something went wrong , please try again",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      onClose();
    }

  }
  //#region context menu
  const MenuItemsTop = [
    {
      id: 1,
      label: 'Edit Email',
      icon: <EditIcon />,
      onClick: onOpen
    },
    {
      id: 2,
      label: 'Restore Email Template',
      icon: <RestoreIcon />,
      onClick: handleResetEmailtemplateClick
    },
  ]

  return (
    <div className=''>
      <div className='groupmembers-wrapper'>
        {
          <div >
            <Divider sx={{ color: 'black', opacity: 1 }} />
            <div className='member-child space-between'>
              <div className='members-details'>
                <div>
                  <img className="members-icon" src="/icons/defaultpropic.jpg" alt="User Profile" />
                </div>
                <div>
                  {EmailtemplatesDetails?.emailTemplateName}
                </div>
              </div>
              <div className='space-between'>
                <div><Switch {...label}
                  checked={isChecked}
                  onChange={handleChange}
                /></div>
                <div>
                  <ContextMenu MenuItemsTop={MenuItemsTop} />
                </div>
              </div>
            </div>
            <Divider sx={{ color: 'black', opacity: 1 }} />
          </div>
        }
      </div>
      {
        openPopup &&
        <EditEmailPopup open={openPopup} onClose={onClose} EmailDetails={EmailDetails} getExcistingGroups={getExcistingGroups} setComponentRefresh={setComponentRefresh} />
      }

    </div>
  )
}

export default EmailsView