import React, { useState } from "react";
import InvestmentPlace from "./InvestmentData";
const MarketplaceMain = () => {
	return(
		<div className='page'>
            <InvestmentPlace />
		</div>
	);
};


export default MarketplaceMain;