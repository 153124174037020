import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import BasicTabs from "./TabSwitch";
import PerformanceTab from "./PerformanceTab";
import CompanyOverview from "./CompanyOverview";
import InvestmentStructure from "./InvestmentStructure";
import { PortfolioVDR } from "./PortfolioVDR";
import { useState } from "react";
import '../styles.css';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { HeaderTemplates, PageHeader } from "../../common/PageHeader/PageHeader";
import { useSelector } from "react-redux";
import { getrootBackgroundColorConfig } from "../../../state/slices/colorConfigSlice";
import { getCompanybyID } from "../Services/CompaniesDetails";
import { useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import stylesfortab from '../../dashboards/FundDetailDashboards/OverView.module.css';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PieChartIcon from '@mui/icons-material/PieChart';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import StorageIcon from '@mui/icons-material/Storage';

const PortfolioDetailPage = () => {
  const navigate = useNavigate();
  const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);
  const onBackArrowButtonClick = useCallback(() => {
    navigate("/marketplace/portfolio-page");
  }, [navigate]);
  const userRole = secureLocalStorage.getItem("userrole");
  const isPortfolioCompany = userRole === "Portfolio Company";  
  const [isSaveEnabled, setSaveEnabled] = useState(false);

  const onEditSaveButtonClick = () => {
    if (isSaveEnabled) {
    }
    setSaveEnabled(isSaveEnabled => !isSaveEnabled);
  };

  const [CompanyDetails, setCompanyDetails] = useState([]);
  const getCompaniesDetails = async () => {
    const companyID = secureLocalStorage.getItem("companyID");
    const data = await getCompanybyID(companyID);
    if (data.responseCode === 200) {
      console.log(data.responseData);
      secureLocalStorage.setItem("companyName", data?.data?.companyName);
      setCompanyDetails(data.responseData);
    } else {
      console.log("data.responseData");
    }
  }

  useEffect(() => {
    getCompaniesDetails();
  }, []);

  const createcompany = secureLocalStorage.getItem("CreateCompany");

  const Tabs = [
    {
      id: 1,
      name: 'Company Overview',
      component: <CompanyOverview
        isSaveEnabled={createcompany}
        setSaveEnabled={setSaveEnabled}
        data={createcompany ? [] : CompanyDetails}
      />,
      MUIIcon : true,
      icon:  <InfoOutlinedIcon/>
    },
    {
      id: 2,
      name: 'Investment Structure',
      component: <InvestmentStructure
        isSaveEnabled={createcompany}
        setSaveEnabled={setSaveEnabled}
        data={createcompany ? [] : CompanyDetails} />,
        MUIIcon : true,
      icon:  <PieChartIcon/>
    },
    {
      id: 3,
      name: 'Performance',
      component: <PerformanceTab
        isSaveEnabled={createcompany}
        setSaveEnabled={setSaveEnabled}
        data={createcompany ? [] : CompanyDetails} />,
        MUIIcon : true,
      icon:  <SignalCellularAltIcon/>
    },
    {
      id: 4,
      name: 'Data Room',
      component: <PortfolioVDR />,
      MUIIcon : true,
      icon:  <StorageIcon/>
    }
  ]

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
  }));

  const [currTabIndex,setCurrTabindex] = useState(0);

    const ChangeTabIndex = (newValue) =>{
      if(isSaveEnabled === true){
        alert("Please Save the Data ")
      }
      else{
        setCurrTabindex(newValue);
      }
    }
    


  return (
    <div style={{backgroundColor: rootBackColorConfig.color }} >
      <PageHeader
        template={HeaderTemplates.CLASSIC}
        onBtnClick={onEditSaveButtonClick}
        btnIcon={isSaveEnabled ? <SaveIcon /> : <EditIcon />}
        btnLabel={isSaveEnabled ? 'Save' : 'Edit'}
        primaryImage="/capitalimages/image-5@2x.png"
        primaryTitle={secureLocalStorage.getItem("FundName")}
        secondaryTitle={CompanyDetails?.companyName}
        secondaryImage="/capitalimages/mask-group-382@2x.png"
      />
      <div className="wrapper2">
         <BasicTabs tabs={Tabs} data={CompanyDetails} isSaveEnabled={isSaveEnabled} currtab={currTabIndex} setCurrTabindex={ChangeTabIndex}/>
      </div>
    </div>
  );
};

export default PortfolioDetailPage;
