import React, { useEffect, useRef, useState } from 'react'
import secureLocalStorage from 'react-secure-storage'
import { HeaderTemplates, PageHeader } from '../../../common/PageHeader/PageHeader'
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import DistributionsActivity from './DistributionsActivity';
import { useNavigate } from 'react-router-dom';
import AddDistributionMain from './AddDistributionMain';
import { distributionPagesEnum, previouDistributionsJsonData } from '../apiDataTemplate';
import { getRoles } from '../../../FundManagerDashboard/Services/FundNewsService';
import { getFundDistributions } from '../../services/services';
import { toast, ToastContainer } from 'react-toastify';

//#this component is to display distribution page from fund services 
const DistibutionsModel = () => {

  //#region variables
  const navigate = useNavigate();
  const [currPage, setCurrPage] = useState(distributionPagesEnum.DISTRIBUTION_ACTIVITY);
  const [selectedDistribution, setSelectedDistribution] = useState(0);
  const [previousDistributions, setPreviousDistributions] = useState();
  const [distributionsDetails, setDistributionsDetails] = useState([]);
  const addEditRef = useRef(null);

  //#region click events
  const onBackClick = () => {
    if (currPage === distributionPagesEnum.ADD_EDIT_DISTRIBUTION) {
      setCurrPage(distributionPagesEnum.DISTRIBUTION_ACTIVITY);
    } else {
      navigate(-1)
    }
  }

  //#region api get calls
  const getPreviosDistributions = async () => {
    const fundId = secureLocalStorage.getItem("FundId");
    const data = await getFundDistributions(fundId, 0);
    if (data.responseCode === 200) {

      setPreviousDistributions(data.responseData);
    }
    else {
      //console.log('unable to fetch data)
    }
  }

  //#region useeffect
  useEffect(() => {
    getPreviosDistributions();
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, [currPage, addEditRef]);


  //#region render components
  const renderPage = () => {
    switch (currPage) {
      case distributionPagesEnum.DISTRIBUTION_ACTIVITY:
        return <DistributionsActivity
          setCurrPage={setCurrPage}
          previousDistributions={previousDistributions}
          setPreviousDistributions={setPreviousDistributions}
          setSelectedDistribution={setSelectedDistribution} />
      case distributionPagesEnum.ADD_EDIT_DISTRIBUTION:
        return <AddDistributionMain
          setCurrPage={setCurrPage}
          previousDistributions={previousDistributions}
          setPreviousDistributions={setPreviousDistributions}
          selectedDistribution={selectedDistribution}
          setSelectedDistribution={setSelectedDistribution} />
      default:
        return "Invalid Step"
    }
  }

  //#region return
  return (
    <div>
      <div>
        <PageHeader
          headerImage={secureLocalStorage.getItem("selectedFund")?.IconImage}
          template={HeaderTemplates?.Standard}
          fundName={secureLocalStorage.getItem("FundName")}
          customBackgroundImage={secureLocalStorage.getItem("selectedFund")?.BGImage ? secureLocalStorage.getItem("selectedFund")?.BGImage : '/DefaultBackgroundImage.png'}
          isNavigate={onBackClick} />
      </div>
      <div>
        {currPage === distributionPagesEnum.ADD_EDIT_DISTRIBUTION ?
          <div ref={addEditRef}>
            <AddDistributionMain
              setCurrPage={setCurrPage}
              distributionsDetails={distributionsDetails}
              previousDistributions={previousDistributions}
              getPreviosDistributions={getPreviosDistributions}
              setPreviousDistributions={setPreviousDistributions}
              selectedDistribution={selectedDistribution}
              setSelectedDistribution={setSelectedDistribution} />
          </div> : ""
        }
        <div>
          <DistributionsActivity
            currPage={currPage}
            setCurrPage={setCurrPage}
            previousDistributions={previousDistributions}
            distributionsDetails={distributionsDetails}
            setDistributionsDetails={setDistributionsDetails}
            getPreviosDistributions={getPreviosDistributions}
            setPreviousDistributions={setPreviousDistributions}
            setSelectedDistribution={setSelectedDistribution} />
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default DistibutionsModel