import React, { useEffect, useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import ActionButton from '../../../common/ActionButton/ActionButton';
import { bankingInformationFields, firmDetailsFields } from '../../services/fieldsData';
import { Grid } from '@mui/material';
import { uploadFileTypes } from '../../../common/FileUploadButton/uploadComponentenum';
import InputField from '../../../common/input-fields/InputField';
import FileUploadBtn from '../../../common/FileUploadButton/FileUploadBtn';
import { validationsConstants } from '../../../../utils/AppConstants';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ALTTextField from '../../../common/input-fields/ALTTextField';
import { getKeyValuePairs } from '../../../portfolio-companies/services/services';

const FirmDetails = (props) => {

  //#region props
  const { firmDetails,getTenantDetails } = props;

  //#region variables
  const [isSaveEnabled,setIsSaveEnabled] = useState(false);
  const [loading,setLoading] = useState(false);
  const [fieldsDetails, setFieldsDetails] = useState(firmDetailsFields);

  //#region change events
  const handleChange = (name, value) => {
    firmInformationDetails.setFieldValue(name, value);
  }

  //#region click events
  const onEditClick = () => {
    setIsSaveEnabled(true);
  }

  const onCancelClick = () => {
    setIsSaveEnabled(false);
    getTenantDetails();
  }

  const handleValidationSubmit = async () => {
    const errors = validationSchema(true)(firmInformationDetails.values);
  
    if (Object.keys(errors).length === 0) {
      firmInformationDetails.handleSubmit();
    } else {
    }
  };

  //#region api get calls
  const getDropDownDetails = async () => {

    const firmTypeData = await getKeyValuePairs('FirmType');
    const registrationBodyData = await getKeyValuePairs('RegulatoryComplianceStatus');
    const structureOptionsData = await getKeyValuePairs('FirmStructure');

    let firmTypeDropDownOptions = []
    let registrationBodyOptions = []
    let structureOptions = []
    
    if(firmTypeData?.responseCode === 200){
      firmTypeDropDownOptions = firmTypeData.responseCode === 200
          ? firmTypeData.responseData?.map(option => ({ label: option.listItemValue, value: option.listItemID })) || []
          : [];
    }

    if(registrationBodyData?.responseCode === 200){
      registrationBodyOptions = registrationBodyData.responseCode === 200
          ? registrationBodyData.responseData?.map(option => ({ label: option.listItemValue, value: option.listItemID })) || []
          : [];
    }
    if(structureOptionsData?.responseCode === 200){
      structureOptions = structureOptionsData.responseCode === 200
          ? structureOptionsData.responseData?.map(option => ({ label: option.listItemValue, value: option.listItemID })) || []
          : [];
    }

    const updatedFields = fieldsDetails.map(field => {
      if (field.name === 'firmTypeId') {
        return { ...field, options: firmTypeDropDownOptions };
      }
      else if (field.name === "regulatoryComplianceStatusId") {
        return { ...field, options: registrationBodyOptions };
      }
      else if (field.name === "firmStructureId") {
        return { ...field, options: structureOptions };
      }
      return field;
    });
    
    setFieldsDetails(updatedFields);
} 

  //#region formik validations
  const validationSchema = (isSubmitting) => {
    return (values) => {
      const errors = {};
      
      fieldsDetails?.forEach((item) => {
        if (item?.required) {
          const value = values[item.name];
          if (!value || (typeof value === 'string' && value.trim() === "") || value === 0) {
            errors[item.name] = validationsConstants.REQUIRED;
          }
        }
      });

      if(isSubmitting){
        Object.keys(errors).forEach(field => {
          firmInformationDetails?.setFieldTouched(field, true, false); 
        });
      }
// debugger
      return errors;
    };
  };
  
  const firmInformationDetails = useFormik({
    initialValues: {
      ...firmDetails
    },
    validate: validationSchema(), 
    onSubmit: (values) => {
      onCancelClick()
    },
  });

  //#region useeffect
  useEffect(()=>{
    getDropDownDetails();
  },[])

  useEffect(()=>{
    firmInformationDetails.setValues(firmDetails)
    getDropDownDetails();
  },[firmDetails])

  //#region return
  return (
    <div className='child-margin-15'>
      {
        <>
          <div className='investor-save-cancel-btns space-between'>
            <div></div>
            <div className='child-row-margin-10'>
              {
                isSaveEnabled ? 
                <>
                  <ActionButton 
                    label="CANCEL"
                    icon={<CancelIcon />}
                    disabled={loading}
                    variant='outlined'
                    onClick={onCancelClick}/>
                  <ActionButton
                      label="SAVE"
                      loading={loading}
                      icon={<SaveIcon />}
                      onClick={handleValidationSubmit} />
                </> : 
                <>
                  <ActionButton 
                    label="EDIT"
                    icon={<EditIcon />}
                    onClick={onEditClick}/>
                </>
              }
            </div>
          </div>
          <form className='white-card child-margin-15' novalidate="novalidate">
            <Grid container spacing={2} alignItems="center">
              <Grid item lg='4' md='4' sm='12' xs='12'>
                    <FileUploadBtn 
                          name="selectedImage"
                          label="UPLOAD PROFILE ICON"
                          defaultFile={firmDetails?.profileIcon ? firmDetails?.profileIcon : '/icons/defaultpropic.jpg'}
                          fileType={uploadFileTypes.IMAGE}
                          readOnly={true}
                          fromWhiteLabeling={isSaveEnabled ? true : false}
                          isSize={true}
                          onChange={() => {}}/>
                </Grid>
              {
                fieldsDetails?.map((field)=>{
                    return(
                      <>
                       {
                         field?.isMultiline ? 
                         <Grid item xs='12'>
                            <ALTTextField 
                                type={field.type}
                                name={field.name}
                                label={field.label}
                                variant="standard"
                                multiline={true}
                                rows={4}
                                required={field.required}
                                options={field?.options?.length > 0 ? field?.options : []}
                                value={firmInformationDetails?.values?.[field.name] || ''}
                                onChange={(name, value) => handleChange(name, value)}
                                readOnly={!isSaveEnabled}
                                onBlur={firmInformationDetails.handleBlur}
                                error={firmInformationDetails.touched?.[field.name] && Boolean(firmInformationDetails?.errors?.[field.name])}
                                errorMessage={firmInformationDetails?.touched?.[field.name] && firmInformationDetails?.errors?.[field.name]} />
                         </Grid> : 
                        <Grid item lg='4' md='4' sm='6' xs='12'>
                         <InputField 
                             textFormatType={field.formatType || ''}
                             type={field.type}
                             name={field.name}
                             label={field.label}
                             variant="standard"
                             rows={1}
                             required={field.required}
                             options={field?.options?.length > 0 ? field?.options : []}
                             value={firmInformationDetails?.values?.[field.name] || ''}
                             onChange={(name, value) => handleChange(name, value)}
                             readOnly={!isSaveEnabled}
                             onBlur={firmInformationDetails.handleBlur}
                             error={firmInformationDetails.touched?.[field.name] && Boolean(firmInformationDetails?.errors?.[field.name])}
                             errorMessage={firmInformationDetails?.touched?.[field.name] && firmInformationDetails?.errors?.[field.name]}/>
                        </Grid>
                       }
                      </>
                    )
                })
              }
            </Grid>
         </form> 
        </>
      }
    </div>
  )
}

export default FirmDetails