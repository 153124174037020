import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchAuth, getAuth } from "../../../state/slices/authSlice";
import SiteHero from "../../../components/Site/SiteHero";
import SiteInvestingDetails from "../../../components/Site/SiteInvestingDetails";
import CircleIcon from "../../chatbot/Icon";
const Home = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAuth());
  }, [dispatch]);

  return (
    <>
      <SiteHero />
      <main id="main">
        <SiteInvestingDetails />
      </main>
      <CircleIcon/>   
    </>
  );
};

export default Home;
