import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../FundDetailPortfolio.module.css";
import DashboardHeader from "../../dashboards/DashboardHeader";
import MiniDrawer from "../../common/navigation-bar/NavBarGrid";
import { useState,useEffect } from "react";
import moment from "moment";
import CircleIcon from "../../chatbot/Icon";
import secureLocalStorage from "react-secure-storage";
import AddCompany from "./PopUps/AddCompany";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getportfoliosDetails } from "../Services/CompaniesDetails";
import { GetCompanyFitlerDetails } from "../Services/CompaniesDetails";
import NotifyPopupModel from "./PopUps/NotifyPopupModel";
import { v4 as uuid } from 'uuid';
import { checkFeaturePermissions } from "../../../utils/common";
import { FeatureAccess, RolesAndPermissions } from "../../../utils/enum";
import { Typography } from "@mui/material";
import { fundTypesEnum } from "../../marketplace-dashboard/jsonData";

const PortfolioPage= (props) => {

  //#region props
  const { fundDetails } = props;

  //#region variables
  const fundTypeName = fundDetails?.fundTypeName;
  const [newUuid, setNewUuid] = useState("0");
  const navigate = useNavigate();

  const moment = require('moment');
 
  const [portfoliotab,setPortfoliotab]=useState(true);
 

  const fundId = secureLocalStorage.getItem("FundId");

  const [selectedItem, setSelectedItem] = useState(null);

  const handleclickOpenfund = (itemid) =>
  {

      if(selectedItem == itemid){
        setSelectedItem(null);
      }
      else{
        setSelectedItem(itemid);
      }
  }

  

  const [openPopup,setOpenpopup] = useState(true);

  const[ispopupopen,setispopupopen] = useState(false);

  const [notifyPopupopen,setNotifyPopupopen] = useState (false);
 
  const onClicknavigate = (companyId) =>
  {
    secureLocalStorage.setItem("companyID", companyId);
    secureLocalStorage.setItem("CreateCompany", false);
    navigate('/portfolio-company-overview');
  }

  const isFundMatured = secureLocalStorage.getItem("selectedFund")?.FundMaturity;;
  const isFundTerminated = secureLocalStorage.getItem("selectedFund")?.FundTermination;

  const onClickopen = () =>{
    setNewUuid(uuid());
    setispopupopen(true);
  }

  const [portfolioCompanies,setPortfoliocompanies]=useState([]);

  const getCompaniesDetails = async () => {
    const fundID = secureLocalStorage.getItem("FundId");
    const data = await getportfoliosDetails(fundID);
    if (data.responseCode === 200) {
      console.log(data.responseData);
      const reversedData = [...data.responseData].reverse();
      setPortfoliocompanies(reversedData);      
    } else {
      setPortfoliocompanies([]);
      console.log("data.responseData");
    }
  }
  
  useEffect(() => {
    getCompaniesDetails();
  }, [notifyPopupopen]);
  
  const PortfolioButton = 
    {
      name: "Add Company",
      iconSrc: "/icons/add-icon.png"
    }

  return (
    <>
    {
      portfolioCompanies && 
        checkFeaturePermissions(RolesAndPermissions.PORTFOLIO_COMPANY,FeatureAccess.READ) &&
         <div>
           <h4> Portfolio Companies</h4>
           <div>
             <div className={`${styles.flexCenter} ${styles.portfoliTableHeader}`}>
               <div className={styles.flexGrowTwo}></div>
              <div className={styles.flexGrowOne}>Sector</div>
     
               <div className={styles.flexGrowOne}>Location</div>
                <div className={styles.flexGrowOne}>Investment Stage</div>
                <div className={styles.flexGrowOne}>
                    Original investment amount​
                  </div>
                <div className={styles.flexGrowOne}>Status</div>
                <div className={styles.flexGrowOne}>{`Ownership Concentration `}</div>
              </div>
     
              { portfolioCompanies?.length > 0 ? 
                <div>
               {portfolioCompanies?.map((item) => (
                   <div
                     className={`${styles.flexCenter} ${styles.portCompany}`}
                     // className={`${styles.companyProfile} ${selectedItem === item.companyId ? styles.selected : ''}`}
                     onClick={() => onClicknavigate(item.companyId)}
                   >
                     <div className={styles.flexGrowTwo}>
                       <img  alt="" src='/capitalimages/mask-group-112@2x.png' />
                       <div className={styles.flexSpaceAround}>
                         <div >{item.companyName}</div>
                         <div className={styles.companyFounded}>Founded: {moment(item.yearFounded).format("YYYY")}</div>
                       </div>
                     </div>
                   <b className={styles.flexGrowOne}>{item.sectorName}</b>
                   <b className={styles.flexGrowOne}>{item.companyHQCountryName}</b>
                   <b className={styles.flexGrowOne}>{item.investmentStageName}</b>
                   <b className={styles.flexGrowOne}>${item.investmentCommitment?.toLocaleString()}</b>
                   <b className={styles.flexGrowOne}>{item.companyStatusName}</b>
                   <b className={styles.flexGrowHalf}>{item.ownershipPercentage}%</b>
                   <b className={styles.flexGrowHalf}><ArrowForwardIosIcon fontSize="medium" sx={{color: 'gray'}}/></b>
                 </div>
               ))}
               </div> :
               <b className="align-items-center margin-top-70">
                  No {fundTypeName === fundTypesEnum.REAL_ESTATE_FUND ? "Assets" : "Portfolio Companies"}
                </b>
              }
              
             </div>
              {ispopupopen && <AddCompany ispopupopen={ispopupopen} setispopupopen={setispopupopen} setNotifyPopupopen={setNotifyPopupopen}/>}
              {notifyPopupopen && <NotifyPopupModel handleCancelModel={(data) => { setNotifyPopupopen(data) }} setNotifyPopupopen={setNotifyPopupopen} newUuid={newUuid}/>}
             </div>
    } 
    </>
  );
};

export default PortfolioPage;
