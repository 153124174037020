import axiosApiCall from "../../Services/axiosApiCall";
import { getAPIResponse } from "../../../utils/common";
import secureLocalStorage from "react-secure-storage";

export async function getAutoCurations() {
    return await axiosApiCall.get(`v1/SuggestedArticle/GetAutoCurationDetails`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getFundTags() {
    return await axiosApiCall.get(`/v1/Fund/GetFundClassSubTypes`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getFundNames() {
    return await axiosApiCall.get(`/v1/Fund/GetFundsForDashboard?userId=${secureLocalStorage.getItem("userId")}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function createNewArticle(data) {
    return await axiosApiCall.post(`/v1/SuggestedArticle/CreateSuggestedArticle`,data)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function updateNewsArticle(data) {
    return await axiosApiCall.put(`/v1/SuggestedArticle/UpdateSuggestedArticle`,data)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getAllNewsArticles() {
    return await axiosApiCall.get(`/v1/SuggestedArticle/GetSuggestedArticles`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getNewsArticleByID(articleID) {
    return await axiosApiCall.get(`/v1/SuggestedArticle/GetSuggestedArticleByID?suggestedArticleID=${articleID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}