import React from 'react'
import Box from '@mui/material/Box';
import { Grid } from "@mui/material";
import secureLocalStorage from 'react-secure-storage';

function FundBanner(props) {

  const fundname = secureLocalStorage.getItem("FundName")
  return (
    <div>
       <Box sx={{ flexGrow: 1 }} marginLeft={'30px'} overflowx={'hidden'}>
            <Grid container spacing={1}>
                  {/* Body content */}
                  <Grid container spacing={2}>
                    <Grid item xs={14} style={{marginLeft: '-34px',marginRight: '-34px'}} className="height-200-noscroll background-light-gray">
                     {/* s */}
                       <Grid xs={12}>
                          <div style={{display: 'flex',paddingLeft: '15px',justifyContent: 'space-between'}}>
                            <div style={{display: 'flex'}} onClick={props.BackArrowClick}>
                                <img
                                  className="cursor-pointer"
                                  alt=""
                                  src="/capitalimages/back-arrow-button1.svg"
                                  style={{marginTop: '-35px'}}
                                />
                                <div style={{display: 'flex',backgroundColor: 'white',borderRadius: '8px',marginLeft: '15px',height: '100px',width: '170px',justifyContent: 'center'}}> 
                                  <img style={{width: '100px',height: '52px',marginTop: '25px'}} alt="" src="/capitalimages/mask-group-382@2x.png" />
                                </div>
                                <div className="banner-heading" style={{marginLeft: '15px',marginTop: '25px'}}>{fundname}</div>
                              </div>
                           
                            </div>
                          </Grid> 
                    </Grid>    
                  </Grid>
            </Grid>
          
          </Box>
    </div>
  )
}

export default FundBanner