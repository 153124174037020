// YearsDropDownValues.js

// Get the current year
const currentYear = new Date().getFullYear();

// Generate options dynamically from the current year to 1950
const prevYearsValues = [];
for (let year = currentYear; year >= 1950; year--) {
    prevYearsValues.push({ value: year, label: year.toString() });
}

export default prevYearsValues;
