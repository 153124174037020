import React from 'react';
import TextInput from '../TextInput/TextInput';
import secureLocalStorage from 'react-secure-storage';
import { useState } from 'react';
import { useEffect } from 'react';
import { Dialog, DialogActions, DialogTitle, DialogContent, Tooltip } from '@mui/material';
import { Button } from "@mui/material";
import { Grid } from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import IconButton from '@mui/material/IconButton';
import Indicator from '../DataGrid/Indicator';
import AIPDataGrid from '../DataGrid/AIPDataGrid';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useNavigate } from "react-router-dom";
import { AppDashboards } from '../../../utils/enum';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RuleIcon from '@mui/icons-material/Rule';
import { Typography } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { getAppNotifications } from '../../InvestorRelations/Services/IRServices';


export default function AppNotifications({ isInvestorNameRequired = false, crntFundID, crntInvestorid, disableSubscriptionButton }) {

  const navigate = useNavigate();
  const [fundNotifications, setFundNotifications] = useState(null);
  const isOptionSelected = (value) => selectedOption === value;
  const [amount, setAmount] = useState('');
  const [openInvDialog, setopenInvDialog] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [error, setError] = useState('');
  const [openDialog, setOpenDialog] = useState(false);

  const actionsString = "Accept/Cancel";
  const underlineStyle = {
    textDecoration: 'underline',
  };
  const handleRadioChange = (value) => {
    setSelectedOption((prev) => (prev === value ? null : value));
    setAmount(0);
  };

  const handleCloseDialog = async () => {
    setOpenDialog(false);
  };

  const handleAmountChange = (name, value) => {
    if (name === 'EnterSubscriptionAmount') {
      setAmount(value);
      setError('');
    }
  };

  const handleAmountChange1 = (name, value) => {
    if (name === 'fundsize') {
      setAmount(value);
      setError('');
    }
  };
  const [selectedIds, setSelectedIds] = useState([]);
  const currentFundSize = selectedIds?.row?.fundSize
  const handleCellClick = (params, event) => {
    const { field } = params;

    if (params.row.Actions === "Accept/Cancel") {
      if (crntFundID === 0) {
        setopenInvDialog(true);
      }
      else {
        setOpenDialog(true);
      }
    } else if (params.row.Actions === "View") {
      navigate('/subscription-details', {
        state: {
          disableSubscriptionButton: { disableSubscriptionButton },
          subscriptionID: params.row.subscriptionsID,
          investorID: params.row.investorId
        }

      });
      secureLocalStorage.setItem('FundId', params.row.fundID)
      secureLocalStorage.setItem('InvestorAndFMNotificationsRow', params.row)

    }
    setSelectedIds(params)
  };

  const SubscriptionStatus = {
    UNDER_REVIEW: "Under Review",
    UNDER_COMPLIANCE_REVIEW: "Under Compliance Review",
  };
  const getSubscriptionStatus = (user) => {
    const userRole = secureLocalStorage.getItem('userrole');
    const userStatus = user.entityStatus;

    if (userRole === AppDashboards.INV_DASHBOARD) {
      return userStatus.includes("Review") ? SubscriptionStatus.UNDER_REVIEW : userStatus;
    }

    if (userRole === AppDashboards.FM_DASHBOARD) {
      return userStatus.includes("Under Compliance Review") ? SubscriptionStatus.UNDER_REVIEW : userStatus;
    }

    return user.entityStatus;
  };

  const handleSubmitclick = async () => {
    if (crntFundID === 0 && isOptionSelected('Reduce the amount and accept') === false) {
      setError('');
    }
    else if (crntInvestorid === 0 && isOptionSelected('Increase fund size') === false) {
      setError('');
      setAmount(0);
    }
    else {
      const enteredAmount = parseFloat(amount);
      if (crntFundID === 0) {
        if (isNaN(enteredAmount) || enteredAmount > minInvTobeProvided) {
          setError('add the minimum investment amount for the fund to subscribe ');
          return;
        }
      }
      else {
        if (isNaN(enteredAmount) || enteredAmount <= currentFundSize) {
          setError('Fund size must be greater than the current fund size');
          return;
        }
      }
    }

    if (error === '') {

    }
    setopenInvDialog(false);
    setOpenDialog(false);
  }

  const fundId = secureLocalStorage.getItem("FundId");

  const fetchDataForNotifications = async () => {
    const response = await getAppNotifications(crntFundID, crntInvestorid);

    if (response.responseCode === 200) {
      setFundNotifications(response.responseData?.map(user => {

        let actionsString = "";
        let actionsIcon = "";
        if (user.entityType === "Subscription") {
          if (user.notificationType === 'Investor Review'
            && secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD) {
            actionsString = "Accept/Cancel";
            actionsIcon = <Tooltip title="Accept/Cancel"><RuleIcon /></Tooltip>;
          }
          else if (user.notificationType === 'FM Review'
            && secureLocalStorage.getItem('userrole') === AppDashboards.FM_DASHBOARD) {
            actionsString = "Accept/Cancel";
            actionsIcon = <Tooltip title="Accept/Cancel"><RuleIcon /></Tooltip>;
          } else {
            actionsString = "View";
            actionsIcon = <Tooltip title="View"><VisibilityIcon /></Tooltip>;
          }
        }

        return {
          "id": user.notificationID,
          "UserName": user.notifiedUserName,
          "FundName": user.fundName,
          "Status": getSubscriptionStatus(user),
          "Type": user.entityType,
          "Actions": actionsString,
          "ActionsIcon": actionsIcon,
          "entityAmount": user.entityAmount,
          "committedCapital": user.committedCapital,
          "fundSize": user.fundSize,
          "currentfundId": user.fundId,
          "NotificationType": user.notificationType,
          'subscriptionsID': user.entityID,
          'fundID': user.fundId,
          investorId: user.notifiedUserID,
          fundTypeName: user.fundTypeName
        };
      })
      );
    }
  };

  useEffect(() => {
    fetchDataForNotifications();
  }, []);

  const minInvTobeProvided = (selectedIds?.row?.fundSize - selectedIds?.row?.committedCapital)
  const AmountCalc = (selectedIds?.row?.entityAmount) - (selectedIds?.row?.fundSize - selectedIds?.row?.committedCapital)
  const NotificationsColumns = [
     ...(isInvestorNameRequired === true ? [{
       "id": 1,
       "field": "UserName",
       "headerName": "Investor Name",
       "width": 120,
       "renderCell": (params) => {
         return <Indicator params={params} />
      }
     }] : []),
     ...(isInvestorNameRequired === false ? [ {
      "id": 2,
      "field": "FundName",
      "headerName": "Fund Name",
      "width": 170,
      "renderCell": (params) => {
        return <Indicator params={params} />
      }
    }] : []),
    ...(isInvestorNameRequired === false ? [{
      "id": 3,
      "field": "fundTypeName",
      "headerName": "Fund Type",
      "width": 140,
    }] : []),
    {
      "id": 4,
      "field": "NotificationType",
      "headerName": "Notification Type",
      "width": 140,
      "renderCell": (params) => {
        return <Indicator params={params} />
      }
    },
    {
      "id": 5,
      "field": "Status",
      "headerName": "Status",
      "width": 140,
      "renderCell": (params) => {
        return <Indicator params={params} />
      }
    },
    {
      "id": 6,
      "field": "Actions",
      "headerName": "Actions",
      "width": 70,
      "renderCell": (params) => {
        return (
          <div style={{ marginLeft: "10px" }}>
            {params.row.ActionsIcon}
          </div>
        );
      }
    }
  ];



  return (
    <>
      {/* {fundNotifications && fundNotifications.length > 0 ? (
        <DataGridPro
          columns={NotificationsColumns}
          rows={fundNotifications ? [...fundNotifications].sort((a, b) => new Date(b.id) - new Date(a.id)) : []}
          onRowClick={(ids, field) => handleCellClick(ids, field)}
          handleRowClick={(ids, field) => handleCellClick(ids, field)}
          onRowsSelectionHandler={() => { }}
          hideFooter
        />
      ) : (
        <p style={{ color: 'gray' }}> No notifications available</p>
      )} */}
       <DataGridPro
          columns={NotificationsColumns}
          rows={fundNotifications ? [...fundNotifications].sort((a, b) => new Date(b.id) - new Date(a.id)) : []}
          onRowClick={(ids, field) => handleCellClick(ids, field)}
          handleRowClick={(ids, field) => handleCellClick(ids, field)}
          onRowsSelectionHandler={() => { }}
          hideFooter
        />
    </>

  );
}
