import React from 'react'
import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Grid, Typography } from '@mui/material';
import ActionButton from '../common/ActionButton/ActionButton';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import InfoIcon from '@mui/icons-material/Info';
function WhiteLabelingConfigConfirmation(props) {
  return (
    <div>
    <Dialog open={props.confirmation} onClose={props.onClose}>
    <DialogTitle>
      <h6> <InfoIcon className='info-i'/> Confirmation </h6>
      
    </DialogTitle>
    <DialogContent>
        <p>Are you sure you want to save the changes?</p>
    </DialogContent>
    <div > 
      <div className='alignright' >
        <ActionButton
         variant="outlined"
          icon={<RotateLeftIcon />}
          label='No' styleProps={{margin:'5px 5px 10px 5px'}} onClick={props.onClose}/>
        <ActionButton
          icon={<TouchAppIcon />}
          label='Yes' styleProps={{margin:'5px 10px 10px 10px'}} onClick={() => props.handleApplyConfig()}/>
      </div>
    </div>
    </Dialog>
  </div>
    
  )
}

export default WhiteLabelingConfigConfirmation