
import axios from "axios";
import axiosApiCall from "../../../Services/axiosApiCall";
import { getAPIResponse } from "../../../../utils/common";

export async function getSubscriptionComments(subscriptionID) {
    return await axiosApiCall.get(`/v1/FundSubscription/GetSubscriptionComments?subscriptionID=${subscriptionID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function AddComments(requestData) {
    return await axiosApiCall.post(`/v1/FundSubscription/AddSubscriptionComments`, requestData)
        .then(res => {

            return getAPIResponse(res);
        }).catch(error => {

            return getAPIResponse(error);
        });
}