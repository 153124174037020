export const foulWords = [
    "arsehole",
    "asshat",
    "asshole",
    "big black cock",
    "bitch" ,
    "bloody",
    "blowjob",
    "bugger",
    "bullshit",
    "chicken shit",
    "clusterfuck",
    "cock" ,
    "cocksucker",
    "coonass",
    "cornhole", 
    "cox–Zucker machine",
    "cracker",
    "cunt",
    "dafuq",
    "damn",
    "dick" ,
    "enshittification",
    "faggot",
    "feck",
    "fuck",
    "fuck her right in the pussy",
    "fuck Joe biden",
    "fuck, marry, kill",
    "fuckery",
    "grab 'em by the pussy",
    "healslut",
    "motherfucker",
    "nigga",
    "nigger",
    "paki" ,
    "poof",
    "poofter",
    "prick", 
    "pussy",
    "ratfucking",
    "retard",
    "russian warship, go fuck yourself",
    "shit",
    "shit happens",
    "shithouse",
    "shitter",
    "shut the fuck up",
    "shut the hell up",
    "slut",
    "son of a bitch",
    "spic",
    "twat",
    "wanker",
    "whore",
]

export const reservedWords = [
    'user',
    'investor',
    'admin',
    'alt'
]