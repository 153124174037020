import { getAPIResponse } from "../../../../utils/common";
import axiosApiCall from "../../../Services/axiosApiCall";

export async function getConfiguredQuestionsBasedOnQuestionType(investorTypeName) {
    return await axiosApiCall.get(`/v1/QuestionBank/GetConfiguredQuestionsBasedOnQuestionType?investorType=${investorTypeName}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}