import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Grid } from "@mui/material";
import MiniDrawer from "../../common/navigation-bar/NavBarGrid";
import styles from '../CompanyDetail.module.css'
import BasicTabs from "./TabSwitch";
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import Divider from '@mui/material/Divider';
import AIPDataGrid from '../../common/DataGrid/AIPDataGrid';
import jsonData from '../jsonData';
import '../styles.css'
import { useState } from 'react';
import { useEffect } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ArticleIcon from '@mui/icons-material/Article';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import NewsDialog from '../../FundNews/Components/NewsDialog';
import { saveAs } from 'file-saver';
import DeleteIcon from '@mui/icons-material/Delete';
import { InitialPortfolioDataTemplate } from '../portfolioData';
import MultipleFileUploadButton from '../../common/FileUploadButton/MultipleFileUpload';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { GetCompanyFitlerDetails } from '../Services/CompaniesDetails';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { getCompanybyID } from '../Services/CompaniesDetails';
import secureLocalStorage from 'react-secure-storage';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

import CircleIcon from '../../chatbot/Icon';
import moment from 'moment/moment';
import { convertStringToArray } from './CommonFunctions/PortfolioFunctions';
import { convertArrayToString } from './CommonFunctions/PortfolioFunctions';
import FirstComponent from '../../common/DatePicker/DatePicker';
import { checkFeaturePermissions } from '../../../utils/common';
import { FeatureAccess, RolesAndPermissions } from '../../../utils/enum';
import { Typography } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
const InvestmentStructure = (props) => {

    const [InvestmentData, setInvestmentData] = useState();

    const [InvestmentMembers, setInvestmentMembers] = useState([]);

    const getCompaniesDetails = async () => {
        const companyID = secureLocalStorage.getItem("companyID");

        const data = await getCompanybyID(companyID);

        if (data.responseCode === 200) {
            console.log(data.responseData);
            setInvestmentData(data.responseData);
            const apiMembers = convertStringToArray(data.responseData.investorGroupMembers);

                const addIDS = apiMembers.map((member, index) => ({
                    id: index,
                    data: member
                }));
                
                setInvestmentMembers(addIDS);
        } else {
            console.log("Error fetching company data");
        }
    }

    useEffect(() => {
        getCompaniesDetails();
    }, []);

    const [currFile, setCurrFile] = useState();

    // const Item = styled(Paper)(({ theme }) => ({
    //     backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    //     ...theme.typography.body2,
    //     padding: theme.spacing(1),
    //     textAlign: "left",
    //   }));

    const [isSaveEnabled, setSaveEnabled] = useState(props.isSaveEnabled);

    const [isopenDialog, setOpenDialog] = useState(false);

    const [investmentthesis, setInvestmentThesis] = useState([{
        "id": 1,
        "name": "2023 Investment Thesis",
        "src": "/Sample.pdf"
    }]);

    const [investmentterms, setInvestmentterms] = useState([
        {
            "id": 1,
            "name": "2023 Investment Terms",
            "src": "/Sample.pdf"
        }
    ])

    const onCloseDialog = () => {
        setOpenDialog(false);
    }

    const openDocumentDialog = (src) => {
        setCurrFile(src);
        setOpenDialog(true);
    }

    const handleDownload = async (name, src) => {
        try {
            const response = await fetch(src); // Assuming this endpoint serves the PDF file
            const blob = await response.blob();
            saveAs(blob, `${name}`);
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };

    const handleDelete = (data) => {
        const filteredData = investmentthesis.filter((item) => item.id !== data.id);
        setInvestmentThesis(filteredData);
    }

    const handleDeleteterms = (data) => {
        const filteredData = investmentterms.filter((item) => item.id !== data.id);
        setInvestmentterms(filteredData);
    }

    const [thesisFile, setThesisFile] = useState({});
    const [termFile, setTermFile] = useState({});

    const handleFileUpload = (name, file) => {
        if (file) {
            name === "ThesisFile" ? setThesisFile(file) : setTermFile(file);
            const formData = new FormData();
            formData.append(name, file, file.name);

            const reader = new FileReader();
            reader.onload = (e) => {
                const fileDataURL = e.target.result;
                /* handleChange(name, file); */
                const newfile = {
                    "id": file.name,
                    "name": file.name,
                    "src": fileDataURL
                }
                if (name === 'TermsFile') {
                    setInvestmentterms([...investmentterms, newfile])
                }
                else {
                    setInvestmentThesis([...investmentthesis, newfile]);
                }

            };
            reader.readAsDataURL(file);
        }
    }


    const handleChange = (field, value, datatype) => {
        const re = /^[0-9\b]+$/;
        if (datatype === "numeric") {
            if (!re.test(value)) {
                return;
            }
        }
        const currState = { ...InvestmentData };
        currState[field] = value; 
        setInvestmentData(currState); 
    }

    const [FiltersData, setFiltersData] = useState([]);

    const getFiltersData = async () => {

        const data = await GetCompanyFitlerDetails();
        if (data.responseCode === 200) {
            console.log(data.responseData);
            setFiltersData(data.responseData);
        } else {
            console.log("data.responseData");
        }
    }

    //INVESTMENT MEMBERS

    const handleAddMore = () => {
        const addmember = {
            id: InvestmentMembers.length > 0 ? InvestmentMembers[InvestmentMembers.length - 1].id + 1 : 1,
            data: ""
        }        
        setInvestmentMembers([...InvestmentMembers, addmember]);
    }

    const handleDeleteInvestmentMembers = (data) => {
        const updatedMembers = InvestmentMembers.filter(member => member.id !== data.id);
        setInvestmentMembers(updatedMembers);
    }

    const handleChangeMembers = (field, value, member) => {
        const updatedMembers = InvestmentMembers.map(item => {
            if (item.id === member.id) {
                return {
                    ...item,
                    data : value
                };
            }
            return item;
        });
        setInvestmentMembers(updatedMembers);
    }




    useEffect(() => {
        getFiltersData();
    }, []);


    useEffect(() => {
        props.setSaveEnabled(isSaveEnabled);
    }, [isSaveEnabled]);

    const handleSave = (investmentData) => {
        const companyID = secureLocalStorage.getItem("companyID");
        if (isSaveEnabled) {
            const requestData = {
                "companyId": companyID,
                "industryId": investmentData.industryId || 1,
                "sectorId": investmentData.sectorId || 1,
                "companyTypeId": 1,
                "investmentClosingDate": investmentData.investmentClosingDate,
                "investmentCommitment": investmentData.investmentCommitment || 1,
                "initialInvestmentAmount": investmentData.initialInvestmentAmount || 1,
                "investedCapitalToDate":investmentData.investedCapitalToDate || 1,
                "investmentMultiple": investmentData.investmentMultiple || 1,
                "distributionsFromCompany": investmentData.distributionsFromCompany || 1,
                "investmentThesis": investmentData.investmentThesis || "",
                "investmentStageId": investmentData.investmentStageId || 1,
                "useOfFunds": investmentData.useOfFunds || "",
                "investmentTerms": investmentData.investmentTerms || "",
                "investorGroupOwnership": investmentData.investorGroupOwnership || 1,
                "investorGroupMembers": convertArrayToString(InvestmentMembers) || "",
                "valuationAtClosing": investmentData.valuationAtClosing || 1,
                "valuationMultiple": investmentData.valuationMultiple || 1,
                "valuationDate": investmentData.valuationDate,
                "currentValuation": investmentData.currentValuation || 1,
                "managementOwnerShipPercentage": investmentData.managementOwnerShipPercentage || 1,
                "ownershipPercentage": investmentData.ownershipPercentage || 1,
                "ownershipStructure": investmentData.ownershipStructure || 1,
                "reportedValue": investmentData.reportedValue || 1,
                "unitsParValue": investmentData.unitsParValue || 1,
                "exitStatusId": investmentData.exitStatusId || 1,
                "exitDate": investmentData.exitDate,
                "realizedProceeds": investmentData.realizedProceeds || 1,
            }
            const formDataBody = new FormData();
            formDataBody.append("company", JSON.stringify(requestData));
            if(thesisFile?.name !== undefined){
            formDataBody.append('thesisFile', thesisFile , thesisFile?.name);
            }
            if(termFile?.name !== undefined){
            formDataBody.append('termsFile', termFile , termFile?.name);
            }
        }
        setSaveEnabled(isSaveEnabled => !isSaveEnabled);
    }

    return (
        <div>
            <Grid item xs={12} marginBottom={1}>
                <div className='space-between'>
                   <Typography variant='h5'>Investment Structure </Typography>
                       
                   
                    <div>
                    {
   checkFeaturePermissions(RolesAndPermissions.PORTFOLIO_COMPANY, FeatureAccess.UPDATE ) &&
                        <Button variant='outlined' className='btn-primary'
                            startIcon={isSaveEnabled ? <SaveIcon /> : <EditIcon />}
                            onClick={() => handleSave(InvestmentData)}>
                            {isSaveEnabled ? 'Save' : 'Edit'}
                        </Button>
}
                    </div>
                </div>
            </Grid>
            <Box className='background-color-white' sx={{ padding: '16px' }}>
                <Grid container spacing={2} columnSpacing={4} marginTop={'20px'}>

                    <Grid item xs={12} md={6} >

                        {jsonData.investmentstructure.map((data, index) => {

                            return (
                                <React.Fragment key={index}>
                                    <Divider sx={{ color: 'gray', opacity: '1' }} />
                                    <div className='porfolio-subheadings'>
                                        <div className='font-cresta'>{data.name}</div>
                                        {
                                            data.type == "select" ?
                                                (
                                                    <select
                                                        value={InvestmentData && InvestmentData[data.apiName]}
                                                        name={data.apiName}
                                                        className={isSaveEnabled ? "fieldClassSave-dropdown" : "fieldClass-dropdown"}
                                                        disabled={!isSaveEnabled}
                                                        style={{ appearance: isSaveEnabled ? 'auto' : 'none' }}
                                                        onChange={(event) => handleChange(event.currentTarget.name, event.currentTarget.value, data.responseDatatype)}
                                                    >
                                                        {InvestmentData && InvestmentData[data.apiName] === null ?
                                                            <option value="" ></option> : null
                                                        }
                                                        {FiltersData[data.dropdown]?.map((option) => (
                                                            <option key={option.id} value={option[data.dropdownvalue]}>{option[data.dropdownlabel]}</option>
                                                        ))}
                                                    </select>                                                  
                                                      ) : (
                                                     data.type === 'date' ? 
                                                        <FirstComponent 
                                                          defaultValue={InvestmentData && moment(InvestmentData[data.apiName]).format('YYYY-MM-DD')}
                                                          fieldName={data.apiName}
                                                          className={isSaveEnabled ? 'fieldClassSave' : 'fieldClass'}
                                                          readOnly={!isSaveEnabled}
                                                          manualAlignment = {true}
                                                          variant='standard'
                                                          isSaveEnabled={isSaveEnabled}
                                                          onhandleChange={(label, value) => handleChange(data.apiName, value, data.responseDatatype)}
                                                        />
                                                        :
                                                        <input
                                                            value={InvestmentData && InvestmentData[data.apiName]}
                                                            name={data.apiName}
                                                            className={isSaveEnabled ? "fieldClassSave" : "fieldClass"}
                                                            readOnly={!isSaveEnabled}
                                                            onChange={(event) => handleChange(event.currentTarget.name, event.currentTarget.value, data.responseDatatype)}
                                                        />
                                                )
                                        }
                                    </div>
                                </React.Fragment>
                            );
                        })}
                        <Divider sx={{ color: 'gray', opacity: '1' }} />
                        <Divider sx={{ color: 'gray', opacity: '1' }} />
                        <div className='porfolio-subheadings font-size-color'>
                            TRANSACTIONS
                        </div>
                        <Divider sx={{ color: 'gray', opacity: '1' }} />
                        <div className='height-400-scroll'>
                            <AIPDataGrid
                                columns={jsonData.AccountTransactionsColumns}
                                rows={jsonData.AccountTransactionsRows}
                                onRowsSelectionHandler={() => { }} />
                        </div>
                    </Grid>

                    <Grid item xs={12} md={6} >

                        {jsonData.startegy.map((data, index) => (
                            <React.Fragment key={index}>
                                <Divider sx={{ color: 'gray', opacity: '1' }} />
                                <div className='porfolio-subheadings'>
                                    <div className='font-cresta'>{data.name}</div>
                                    {
                                        data.type == "select" ?
                                            (
                                                <select
                                                    value={InvestmentData && InvestmentData[data.apiName]}
                                                    name={data.apiName}
                                                    className={isSaveEnabled ? "fieldClassSave-dropdown" : "fieldClass-dropdown"}
                                                    disabled={!isSaveEnabled}
                                                    style={{ appearance: isSaveEnabled ? 'auto' : 'none' }}
                                                    onChange={(event) => handleChange(event.currentTarget.name, event.currentTarget.value, data.responseDatatype)}
                                                >
                                                    {InvestmentData && InvestmentData[data.apiName] === null ?
                                                        <option value="" ></option> : null
                                                    }
                                                    {FiltersData[data.dropdown]?.map((option) => (
                                                        <option key={option.id} value={option[data.dropdownvalue]}>{option[data.dropdownlabel]}</option>
                                                    ))}
                                                </select>

                                            ) : (
                                                    data.type === 'date' ? 
                                                   <FirstComponent 
                                                    defaultValue={InvestmentData && moment(InvestmentData[data.apiName]).format('YYYY-MM-DD')}
                                                    fieldName={data.apiName}
                                                    className={isSaveEnabled ? 'fieldClassSave' : 'fieldClass'}
                                                    readOnly={!isSaveEnabled}
                                                    isSaveEnabled={isSaveEnabled}
                                                    manualAlignment = {true}
                                                    variant='standard'
                                                    onhandleChange={(label, value) => { handleChange(data.apiName, value, data.responseDatatype)}}
                                                    />
                                                    :
                                                    <input
                                                        value={InvestmentData && InvestmentData[data.apiName]}
                                                        name={data.apiName}
                                                        className={isSaveEnabled ? "fieldClassSave" : "fieldClass"}
                                                        readOnly={!isSaveEnabled}

                                                        onChange={(event) => handleChange(event.currentTarget.name, event.currentTarget.value, data.responseDatatype)}
                                                    />
                                            )
                                    }
                                    {/* <input
                                        value={InvestmentData && InvestmentData[data.apiName]}
                                        name={data.apiName}
                                        style={{marginRight: '5px'}}
                                        className={isSaveEnabled ? "fieldClassSave" : "fieldClass"}
                                        readOnly={!isSaveEnabled}
                                        onClick={(event) => {
                                            const input = event.target;
                                            const length = input.value.length;
                                            input.setSelectionRange(length, length); 
                                        }}
                                        onChange={(event) => handleChange(event.currentTarget.name, event.currentTarget.value)}
                                /> */}
                                </div>
                            </React.Fragment>
                        ))}


                        <Divider sx={{ color: 'gray', opacity: '1' }} />
                        <div className='font-size-color porfolio-subheadings'>
                            INVESTMENT THESIS
                        </div>
                        {isSaveEnabled &&
                            <div className='font-size-color porfolio-subheadings'>
                                <MultipleFileUploadButton
                                    name="investmentthesis"
                                    label="ADD DOCUMENT"
                                    closeicon='false'
                                    onChange={(name, value) => handleFileUpload("ThesisFile", value)}
                                />
                            </div>
                        }
                        {investmentthesis?.map((data, index) => (
                            <React.Fragment key={index}>

                                <div className='porfolio-arrayheadings'>
                                    <div className='space-between'>
                                        <div><TextSnippetIcon sx={{ marginTop: '-6px', width: '27px', height: '33px' }} /></div>
                                        <div className='margin-left-10' >{data.name}</div>
                                    </div>
                                    <div className='space-between'>
                                        <Tooltip className='micons' onClick={() => openDocumentDialog(data.src)} title="View" arrow>
                                             <IconButton>
                                                 <VisibilityIcon />
                                             </IconButton>
                                             </Tooltip>
                                        <Tooltip className='micons' onClick={() => handleDownload(data.name, data.src)} title="Download" arrow>
                                             <IconButton>
                                                 <FileDownloadIcon />
                                             </IconButton>
                                             </Tooltip>

                                        {isSaveEnabled &&
                                             <Tooltip className='deleteicon' onClick={() => handleDelete(data)} title="Delete" arrow>
                                             <IconButton>
                                                 <DeleteIcon />
                                             </IconButton>
                                             </Tooltip>
                                        }
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}

                        <Divider sx={{ color: 'gray', opacity: '1' }} />
                        <div className='font-size-color porfolio-subheadings'>
                            INVESTMENT TERMS
                        </div>
                        {isSaveEnabled &&
                            <div className='font-size-color porfolio-subheadings'>
                                <MultipleFileUploadButton
                                    name="investmentterms"
                                    label="ADD DOCUMENT"
                                    onChange={(name, value) => handleFileUpload("TermsFile", value)}
                                />
                            </div>
                        }
                        {investmentterms.map((data, index) => (
                            <React.Fragment key={index}>

                                <div className='porfolio-arrayheadings'>
                                    <div className='space-between'>
                                        <div><TextSnippetIcon sx={{ marginTop: '-6px', width: '27px', height: '33px' }} /></div>
                                        <div className='margin-left-10' >{data.name}</div>
                                    </div>
                                    <div className='space-between'>
                                        <Tooltip className='micons' onClick={() => openDocumentDialog(data.src)} title="View" arrow>
                                                <IconButton>
                                                    <VisibilityIcon />
                                                </IconButton>
                                                </Tooltip>
                                        <Tooltip className='micons' onClick={() => handleDownload(data.name, data.src)} title="Download" arrow>
                                                <IconButton>
                                                    <FileDownloadIcon />
                                                </IconButton>
                                                </Tooltip>
                                        {isSaveEnabled &&
            
                                                <Tooltip className='deleteicon' onClick={() => handleDeleteterms(data)} title="Delete" arrow>
                                                <IconButton>
                                                    <DeleteIcon />
                                                </IconButton>
                                                </Tooltip>
                                        }
                                    </div>
                                </div>

                            </React.Fragment>
                        ))}

                        <Divider sx={{ color: 'gray', opacity: '1' }} />
                        <div className='porfolio-subheadings font-size-color'>
                            INVESTOR GROUP MEMBERS
                        </div>
                        {InvestmentMembers.map((member, index) => (
                            <React.Fragment key={index}>
                                <Divider sx={{ color: 'gray', opacity: '1' }} />
                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px' }}>
                                            <input
                                                value={member.data}
                                                name={member.data}
                                                className={isSaveEnabled ? "fieldClassSave" : "fieldClass-members"}
                                                readOnly={!isSaveEnabled}
                                                onChange={(event) => handleChangeMembers(event.currentTarget.name, event.currentTarget.value, member)}
                                            />
                                        {isSaveEnabled ?
                                         <Tooltip className='deleteicon' onClick= {() => handleDeleteInvestmentMembers(member)} title="Delete" arrow>
                                         <IconButton>
                                             <DeleteIcon />
                                         </IconButton>
                                         </Tooltip> : null
                                        }  
                                    </div>
                            </React.Fragment>
                        ))}
                        <Divider sx={{ color: 'gray', opacity: '1' }} />
                        <div className='porfolio-subheadings font-size-color'>
                            {isSaveEnabled &&
                                <Button variant='outlined' className='btn-primary'
                                  onClick={handleAddMore}>
                                    <AddCircleOutlineIcon /> ADD MORE
                                </Button>
                            }
                        </div>
                    </Grid>

                </Grid>
                <ToastContainer />
                {isopenDialog && <NewsDialog open={isopenDialog} onClose={onCloseDialog} itemData={currFile} />}
            </Box>
            <CircleIcon />
        </div>

    )
}

export default InvestmentStructure