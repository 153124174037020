import { Link } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';
import Google from '../../third-party/Google';
import CircleIcon from "../../chatbot/Icon";
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import AppleIcon from '@mui/icons-material/Apple';
import GoogleIcon from '@mui/icons-material/Google';
import { fontSize } from "@mui/system";
import AuthenticationPage from "../../AppAuthentication/AuthenticationPage";
import { msalInstance } from "../../Site/SiteHeader";
import { useMsal } from "@azure/msal-react";
import { v4 as uuidv4 } from 'uuid';
import { b2cPolicies, googleRequest, msalConfig } from "../../AppAuthentication/authConfig";
import { PublicClientApplication } from "@azure/msal-browser";



const CreateAccount = () => {
const instance = new PublicClientApplication(msalConfig);
const googleSignUp = () => {
  instance.loginRedirect({
    authority: b2cPolicies.authorities.signUp.authority,
    scopes: googleRequest.scopes
  }).then(response => {
    debugger
    console.log(response);
    // Handle successful login response
  }).catch(error => {
    console.error(error);
  });
  // // OAuth provider URL (e.g., Google, GitHub)
  // const oauthProviderUrl = 'https://accounts.google.com/o/oauth2/v2/auth';

  // // Your OAuth client ID
  // const clientId = '615613587127-h4fdas53g79ri79499vph5hu803olsju.apps.googleusercontent.com';

  // // Redirect URI to which the OAuth provider will send the user after authorization
  // const redirectUri = 'https://alt360.b2clogin.com/alt360.onmicrosoft.com/oauth2/authresp';

  // // Scope of access requested
  // const scope = 'openid profile email';

  // // Generate a unique state parameter
  // const generateState = () => {
  //   const uniqueState = uuidv4(); // Generate a unique UUID
  //   // setState(uniqueState); // Store it in component state
  //   return uniqueState;
  // };

  // const stateParam = generateState(); // Generate and get the state parameter
  // const authUrl = `${oauthProviderUrl}?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scope)}&response_type=code&state=profile`;

  // // Redirect the user to the OAuth authorization URL
  // window.location.href = authUrl;
}
  return (
    <div className="regwithothersbox">
         <img className="res-logo" src="/Alt360-Logo-Royal-SM.png" alt="Alt360" />
            <div className="signup-header">
              <h3 className="login-heading">Create an Account</h3>
              <p>
                Start building a portfolio tailored to your unique goals.
              </p>
            </div>
            <div className="SignupWith">
              <Link className="btn" to="/create-account-with-email">
                <LocalPostOfficeIcon className="singupicons"/>
                  SIGN UP WITH EMAIL
              </Link>

              <button className="btn" onClick={() => googleSignUp()}>
            <GoogleIcon alt="google-logo" />
            Sign Up With Google
          </button>
             
              {/* <button className="btn">
                <AppleIcon/>
                  SIGN UP WITH APPLE
              </button> */}
            </div>
            <div className="recaptcha text-center">
              Already have an account?
              <span
                className="signup-LoginLink">
                <AuthenticationPage msalInstance={msalInstance} />
              </span>
            </div>
            <div className="google-recaptcha">
              This site is protected by reCAPTCHA and the
              <p> Google<a href="#"> Privacy Policy </a>and <a href="#"> Terms of Service </a>apply.</p>
            </div>
          <CircleIcon/>   
        </div>
   
  );
};

export default CreateAccount;
