import * as React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useEffect } from 'react';

function updateRowPosition(initialIndex, newIndex, rows) {
  return new Promise((resolve) => {
    setTimeout(() => {
      const rowsClone = [...rows];
      const row = rowsClone.splice(initialIndex, 1)[0];
      rowsClone.splice(newIndex, 0, row);
      resolve(rowsClone);
    }, Math.random() * 500 + 100); // simulate network latency
  });
}

export default function RowOrderingGrid(props) {
  
  const { columns, rowsorder, setRowsOrder, handleRowClick ,setRowsordertrack,isSaveEnabled } = props;
  const [gridRows, setGridRows] = React.useState(rowsorder || []);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (rowsorder && rowsorder.length > 0) {
      
      setGridRows(rowsorder);
    }
  }, [rowsorder]);

  const handleRowOrderChange = async (params) => {
    setLoading(true);
    const newRows = await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      gridRows,
    );

    setGridRows(newRows);
    setRowsOrder(newRows); // Update the original data
    const extractedQuestionIds = newRows.map((item) => item.questionBankID);
    setRowsordertrack(extractedQuestionIds);
     
    setLoading(false);
  };

  


  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGridPro
        columns={columns}
        rows={gridRows}
        Tooltip
        rowReordering={isSaveEnabled}
        onRowOrderChange={handleRowOrderChange}
        onRowClick={(params, event) => {
          if (event.target.type === 'checkbox') {
            return;
          } else {
            handleRowClick(params, event);
          }
        }}
        localeText={{ noRowsLabel: "Loading..." }}
        getRowId={(row) => row.step}
        hideFooter
      />
    </div>
  );
}
