import React, { useEffect, useState } from 'react'
import { HeaderTemplates, PageHeader } from '../../common/PageHeader/PageHeader'
import secureLocalStorage from 'react-secure-storage';
import TabsComponment from '../../common/Tabs/TabsComponment';
import SubscriberOverview from './SubscriberOverview';
import IndividualInformation from '../../user-profiles/components/investor-user-profile/IndividualInformation';
import { getInvestorDetailsbyUserID } from '../../KYCVerification/services/services';
import BankingInformation from '../../user-profiles/components/investor-user-profile/BankingInformation';
import InvestmentProfile from '../../user-profiles/components/investor-user-profile/InvestmentProfile';
import { getKeyValuePairs } from '../../portfolio-companies/services/services';

const SubsciberMain = () => {

  //#region variables
  const investorID = secureLocalStorage.getItem("subscriberID")
  const userIcon = secureLocalStorage.getItem("InvestorprofilePic"); 
  const [selectedTab,setSelectedTab] = useState(0);
  const [investorProfile, setInvestorProfile] = useState(null);
  const [investorTypesValues,setInvestorTypesValues] = useState([]);

  //#region api get calls
  const getInvestorInfo = async () => {
    const data = await getInvestorDetailsbyUserID(investorID);
    if (data.responseCode === 200) {
      setInvestorProfile(data.responseData);
    } else {
      // Handle error if needed
    }
  }

  const getInvestorTypes = async() => {
    const data = await getKeyValuePairs("InvestorType");
    if(data.responseCode === 200){
       setInvestorTypesValues(data?.responseData);
    }
    else{
      setInvestorTypesValues([]);
    }
  }

  //#region useeffect
  useEffect(()=>{
    getInvestorInfo();
    getInvestorTypes();
  },[])

  //#region tabs
  const tabs = [
    {
        key : 0,
        icon : "",
        label : "OVERVIEW",
        component : <SubscriberOverview  />
    },
    {
        key : 1,
        icon : "",
        label : "PERSONAL INFORMATION",
        component : <IndividualInformation 
                       investorDetails={investorProfile} 
                       isKycVerification={true}
                       isViewOnly={true}
                       investorTypesValues={investorTypesValues} />
    },
    // {
    //     key : 2,
    //     icon : "",
    //     label : "KYC / AML",
    //     component : "Under Construction"
    // },
    {
        key : 2,
        icon : "",
        label : "BANKING INFORMATION",
        component : <BankingInformation 
                        investorDetails={investorProfile} 
                        isKycVerification={true}
                        isViewOnly={true}
                        investorTypesValues={investorTypesValues} />
    },
    {
        key : 3,
        icon : "",
        label : "INVESTMENT PROFILE",
        isBackgroundColor: true,
        component : <InvestmentProfile 
                         isViewOnly={true} 
                         investorDetails={investorProfile} 
                         investorTypesValues={investorTypesValues}/>
    },
  ]

  //#regtion return
  return (
    <div>
        <PageHeader
            title="Auditing Overview"
            template={HeaderTemplates.USER}
            userIcon={userIcon !== "https://alt360beta.blob.core.windows.net/images/" ? userIcon : "/icons/defaultpropic.jpg"}/>
        <div className='wrapper'>
           <TabsComponment tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        </div>
    </div>
  )
}

export default SubsciberMain