import React from 'react';
import { useState, useEffect } from 'react';
import { getGroupByID } from '../../../Services/GroupsServices';
import TextInput from '../../../../common/TextInput/TextInput';
import '../styles.css'
import { Divider, Avatar, Grid, Paper, Link } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import ContextMenu from '../../../../common/ContextMenu/ContextMenu';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EmailIcon from '@mui/icons-material/Email';
import GroupsPopup from './Popups/GroupsPopup';
import { getInvestorsList } from '../../../Services/IRServices';
import { deleteGroupMember } from '../../../Services/GroupsServices';
import { ToastContainer, toast } from 'react-toastify';

const GroupDetailsView = (props) => {

    //#region Props from Parent
    const { groupID, isAccordianExpanded, navigateCustomEmail, entityTypeName } = props;

    //#region useState Variables
    const [groupDetails, setGroupDetails] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [editPopup, setEditPopup] = useState(false);
    const [investorsList, setInvestorsList] = useState(null);

    //#region events

    const handleCloseEditPopup = () => {
        setEditPopup(false);
        setSelectedGroup(null);
    }

    const handleEdit = (item) => {
        setSelectedGroup(item);
        setEditPopup(true);
    }

    const naviagetoCustomEmail = (item) => {

        navigateCustomEmail(0, item?.groupMemberUserID);
    }

    //#region update API Call
    const handleDelete = async (item) => {
        /* console.log('Delete'); */
        const data = await deleteGroupMember(item?.groupMemberID);
        if (data?.responseCode === 200) {
            getGroupDetails();
            toast.success("Group Member Removed Successfully",
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        } else {
            toast.warning("Unable to Remove the Group Member",
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
    }

    //#region ContextMenuItems
    const MenuItemsTop = [
        {
            id: 1,
            label: 'Notify User',
            icon: <EmailIcon />,
            onClick: naviagetoCustomEmail
        },
        {
            id: 2,
            label: 'Remove User',
            icon: <DeleteIcon />,
            onClick: handleDelete
        }
    ]

    //#region service GET Calls 
    const getGroupDetails = async () => {
        const data = await getGroupByID(groupID);
        if (data?.responseCode === 200) {
            setGroupDetails(data?.responseData);
        } else {
            /* toast.warning(data?.responseData,
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" }); */
        }
    }

    const getInvestors = async () => {
        const data = await getInvestorsList();
        if (data?.responseCode === 200) {
            setInvestorsList(data?.responseData);
        } else {
            /* console.log(data?.responseData); */
        }
    }


    //#region useEffect 
    useEffect(() => {
        if (isAccordianExpanded) {
            getGroupDetails();
        }
        getInvestors();
    }, [isAccordianExpanded]);

    return (
        <div>
            {
                groupDetails ?
                    <div className='groupview-wrapper'>
                        <div>
                            <TextInput
                                type='text'
                                name="groupDescription"
                                /* label="Group Description" */
                                multiline
                                rows={3}
                                editable={false}
                                defaultValue={groupDetails?.userGroupDescription}
                                onChange={() => { }} />
                        </div>
                        <div className='groupmembers-wrapper'>
                            {
                                groupDetails?.userGroupMembers?.map((member) => {
                                    return (
                                        <div>
                                            <Divider sx={{ color: 'black', opacity: 1 }} />
                                            <div className='member-child space-between'>
                                                <div className='members-details'>
                                                    <div>
                                                        <img className="members-icon" src="/icons/defaultpropic.jpg" alt="User Profile" />
                                                    </div>
                                                    <div>
                                                        {member?.groupMemberName}
                                                    </div>
                                                </div>
                                                {
                                                    entityTypeName?.toLowerCase() === "fund"
                                                        ?
                                                        <div>
                                                        </div>
                                                        :
                                                        <div>
                                                            <ContextMenu MenuItemsTop={MenuItemsTop} ItemDetails={member} />
                                                        </div>
                                                }
                                            </div>
                                            <Divider sx={{ color: 'black', opacity: 1 }} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className='groupmembers-btn'>
                            {
                                entityTypeName?.toLowerCase() !== "fund" &&
                                <Button variant="outlined" className="btn-primary" style={{ cursor: 'pointer' }} onClick={() => { handleEdit(groupDetails) }}>
                                    <AddIcon />
                                    <span>Add More</span>
                                </Button>
                            }
                        </div>
                    </div> :
                    <div>
                        Loading...
                    </div>
            }
            {
                editPopup && selectedGroup && <GroupsPopup
                    open={editPopup}
                    data={selectedGroup}
                    isNewGroup={false}
                    onClose={handleCloseEditPopup}
                    refreshData={() => { }}
                    refresGroupDetails={getGroupDetails}
                    investorsList={investorsList} />
            }
            <ToastContainer />
        </div>
    )
}

export default GroupDetailsView