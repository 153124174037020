import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Grid } from '@mui/material';
import { useState, useEffect } from 'react';
import ActionButton from '../../../../common/ActionButton/ActionButton'
import RichTextInput from '../../../../common/rich-text-input/RichTextInput';
import { toast } from 'react-toastify';
import { validationMessages, validationsTypeEnum } from '../../../../common/TextInput/appInputenum';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { UpdateEmailTemplate } from '../Services/EventManagementResponse';
import secureLocalStorage from 'react-secure-storage';
import { ResetEmailTemplate } from '../Services/EventManagementResponse';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ConfirmationPopup from './ConfirmationPopup';
const EditEmailPopup = (props) => {

    //#region props
    const { open, onClose, getExcistingGroups, EmailDetails, setComponentRefresh } = props;

    //#region variables
    const [loading, setLoading] = useState(false);
    const [emailDetails, setEmailDetails] = useState({ ...EmailDetails });
    const [isChangesDone, setIsChangesDone] = useState(false);
    secureLocalStorage.setItem("EmailTemplateSuggestions", EmailDetails.suggestions);
    const [confirmationPopup, setConfirmationPopup] = useState(false);

    //## function to Replace PlaceHolders(which we got from api) with Mentions 
    const replacePlaceholdersWithMentions = (text) => {
        return text.replace(/{(\d+)}/g, (_, p1) => {
            const placeholders = EmailDetails.arguments.split(',');
            const index = parseInt(p1);
            if (index < placeholders.length) {
                const label = placeholders[index].trim();
                return `<span data-type="mention" data-id="${index}" data-label="${label}">@${label}</span>`;
            } else {
                return `@${p1}`;
            }
        });
    };

    //## Replace placeholders with mentions in the initial values
    const initialSubject = replacePlaceholdersWithMentions(emailDetails.subject);
    const initialBody = replacePlaceholdersWithMentions(emailDetails.body);

    //#region change events
    const handleChange = (name, value) => {
        setIsChangesDone(true);
        setEmailDetails({
            ...emailDetails,
            [name]: value
        });
    }

    //#region click events
    const onCancel = () => {
        if (isChangesDone) {
            onOpenPopUp();
        }
        else {
            onClose();
        }
    }

    const onOpenPopUp = () => {
        setConfirmationPopup(true);
    }

    const onClosePopup = (isClose) => {
        if (isClose) {
            onClose();
        }
        else {
            setConfirmationPopup(false);
        }
    }

    //## Update API Request Body 
    const UpdatetemplateRequestBody = {
        emailTemplateID: emailDetails.id,
        emailTemplateName: emailDetails.emailTemplateName,
        emailSubject: replaceAtWithId(emailDetails.subject),
        emailBody: replaceAtWithId(emailDetails.body),
        "description": emailDetails.description,
        "arguments": emailDetails.arguments,
        "createdBy": emailDetails.createdBy,
        "createdDate": emailDetails.createdDate,
        "updatedBy": emailDetails.updatedBy,
        "updatedDate": emailDetails.updatedDate,
        "templateGroupID": emailDetails.templateGroupID,
        "groups": emailDetails.groups,
        "templateSubGroupID": emailDetails.templateSubGroupID,
        "isActive": emailDetails.isActive,
        "initialEmailBody": emailDetails.initialEmailBody
    };

    //## function to replace mentions with placeholders ID 
    function replaceAtWithId(text) {
        const regex = /<span[^>]*data-id="([^"]+)"[^>]*>.*?<\/span>/g;
        const updatedText = text.replace(regex, (_, p1) => `{${p1}}`);
        return updatedText;
    }

    // Function to handle the save & reset actions and interacting with api 
    const handleSubmit = async () => {
        setLoading(true)
        const data = await UpdateEmailTemplate(UpdatetemplateRequestBody)
        if (data.responseCode === 200) {
            setLoading(false)
            toast.success("Email Template Updated Successfully",
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            onClose();
            getExcistingGroups()
        }
        else {
            toast.error("Something went wrong , please try again",
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            setLoading(false);
            onClose();
        }

        getExcistingGroups()
    }

    const handleResetEmailtemplateClick = async () => {
        setComponentRefresh(false)
        const data = await ResetEmailTemplate(emailDetails.id)
        if (data.responseCode === 200) {
            setComponentRefresh(true)
            toast.success("Email Template has been reset Successfully",
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            onClose();
        }
        else {
            setComponentRefresh(true)
            toast.error("Something went wrong , please try again",
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            onClose();
        }

    }

    //#region validations
    const [noofValidFields, setNoofValidFields] = useState({ validfields: 0 });
    const [checkIfValid, setcheckIfValid] = useState(false);
    let count = 0;   //to Show Toast Message only once

    const checknoofValidFields = (isValid, name, defaultValue) => {
        const TotalFiledsToValidate = 2;
        count = ++count;
        noofValidFields['validfields'] = isValid ? noofValidFields['validfields'] + 1 : noofValidFields['validfields'];
        if (noofValidFields['validfields'] === TotalFiledsToValidate) {
            noofValidFields['validfields'] = 0;
            count = 0;
            //Post API
            handleSubmit();
        } else {
            if (count === TotalFiledsToValidate) {
                toast.warning("Please verify the input fields",
                    { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored", style: { zIndex: 9999 } });
                count = 0;
            }
        }
    };

    const handleSave = () => {
        setcheckIfValid(true);
        noofValidFields['validfields'] = 0;
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>
                Edit Email Template
            </DialogTitle>
            <DialogContent>
                <div className='margin-top-15'>
                    <div>Subject</div>
                    <div className='margin-top-5'>
                        <RichTextInput
                            variant="outlined"
                            name="subject"
                            defaultValue={initialSubject}
                            onChange={(name, value) => handleChange(name, value)}
                            hideTextControllers={true}
                            validateField={true}
                            validationType={validationsTypeEnum.REQUIRED}
                            validationMessage={validationMessages.REQUIRED}
                            checkIfValid={checkIfValid}
                            checknoofValidFields={checknoofValidFields}
                            setcheckIfValid={setcheckIfValid} />
                    </div>
                </div>
                <div className='margin-top-15'>
                    <div>Body</div>
                    <div className='margin-top-5'>
                        <RichTextInput
                            variant="outlined"
                            name="body"
                            defaultValue={initialBody}
                            onChange={(name, value) => handleChange(name, value)}
                            validateField={true}
                            validationType={validationsTypeEnum.REQUIRED}
                            validationMessage={validationMessages.REQUIRED}
                            checkIfValid={checkIfValid}
                            checknoofValidFields={checknoofValidFields}
                            setcheckIfValid={setcheckIfValid} />
                    </div>
                </div>
                <div className='space-between margin-top-15'>
                    <div className='space-between margin-5'>
                        <div className='margin-left-10'>
                            <ActionButton
                                label='Reset'
                                icon={<RestartAltIcon />}
                                onClick={handleResetEmailtemplateClick} />
                        </div>
                    </div>
                    <div className='space-between margin-5'>
                        <div>
                            <ActionButton variant='outlined' disabled={loading} onClick={onCancel} label='Cancel'
                                icon={<CancelIcon />}
                            />
                        </div>
                        <div className='margin-left-10'>
                            <ActionButton
                                label='Save'
                                icon={<SaveIcon />}
                                loading={loading}
                                onClick={handleSave} />
                        </div>
                    </div>
                </div>
            </DialogContent>
            {
                confirmationPopup &&
                <ConfirmationPopup
                    open={confirmationPopup}
                    onClose={onClosePopup} />
            }
        </Dialog>
    )
}
export default EditEmailPopup;
