import TextInput from "../../common/TextInput/TextInput";
import styles from "../FundManagerUserProfile.module.css";
import userprofilestyles from "../userprofile1.module.css";


export const FundUserBankingInfo = (props) => {
    const {investorProfile} = props;
    return(
         <div className={`${styles.overviewWrapper} disablediv`}>
                <div className={`${styles.fundStatusWrapper} ${styles.flexGap}`}>
                    <div className={styles.flexOneThird}>
                        <div className={styles.inputWrapper}>
                        <TextInput
                            defaultValue={investorProfile?.bankingInformation?.branchLocation}
                            label="Branch Location"
                            onChange={() => {}}
                        />
                        </div>
                        <div className={styles.inputWrapper}>
                        <TextInput
                            defaultValue={investorProfile?.bankingInformation?.addressLine1}
                            label="Address Line 1"
                            onChange={() => {}}
                        />
                        </div>
                        <div className={styles.inputWrapper}>
                        <TextInput
                            defaultValue={investorProfile?.bankingInformation?.addressLine2}
                            label="Address Line 2"
                            onChange={() => {}}
                        />
                        </div>
                    </div>
                    <div className={styles.flexOneThird}>
                        <div className={styles.inputWrapper}>
                        <TextInput
                            defaultValue={investorProfile?.bankingInformation?.accountNo}
                            label="Account Number"
                            onChange={() => {}}
                        />
                        </div>
                        <div className={styles.inputWrapper}>
                        <TextInput
                            defaultValue={investorProfile?.bankingInformation?.branchLocation}
                            label="City"
                            onChange={() => {}}
                        />
                        </div>
                        <div className={styles.inputWrapper}>
                        <TextInput
                            defaultValue="NY"
                            label="State"
                            onChange={() => {}}
                        />
                        </div>
                    </div>
                    <div className={styles.flexOneThird}>
                        <div className={styles.inputWrapper}>
                        <TextInput
                            defaultValue={investorProfile?.bankingInformation?.branchLocation}
                            label="Branch Location"
                            onChange={() => {}}
                        />
                        </div>
                        <div className={styles.inputWrapper}>
                        <TextInput
                            defaultValue={investorProfile?.bankingInformation?.zipCode}
                            label="Zip Code"
                            type="zipcode"
                            onChange={() => {}}
                        />
                        </div>
                    </div>
                </div>
            </div>
    )
}