import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    subscriber: {
        firstName: "",
        lastName: "",
        entityType: "",
        capital: "",
    }
}

const subscribeSlice = createSlice({
  name: "subscribe",
  initialState,
  reducers: {
    updateSubscriber: (state, action) => {
      const { field, value } = action.payload;
      state[`${field}`] = value;
    },
  },
});
export const { updateSubscriber } = subscribeSlice.actions;
export default subscribeSlice.reducer;
