import axiosApiCall from "../../Services/axiosApiCall";
import { getAPIResponse } from "../../../utils/common";
import secureLocalStorage from "react-secure-storage";

export async function getInvestorSubscribedFunds(investorID) {
    return await axiosApiCall.get(`/v1/Fund/GetFundByInvestorId?investorID=${investorID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}