import React, { useState } from 'react';
import TextInput from '../../../common/TextInput/TextInput'
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useEffect } from 'react';

function NotifyPortifolio(props) {
    const [notifyPortifolio, setNotifyPortifolio] = useState(props.data || {});
    
    const handleChange=(field, value)=>{
        const currState = notifyPortifolio;
        currState[field] = value;
        setNotifyPortifolio(currState);
    }

    useEffect(() => {
      props?.setData(notifyPortifolio);
    },[notifyPortifolio])
  
      return (
        <div> 
            <div className="fundRow">
                <div className="fundDetail">
                    <TextInput 
                        label="Company Name" 
                        name="companyname"
                        defaultValue={props?.data?.companyname || ''}
                        type="Onlytext"
                        required={true}
                        onChange={(name, value)=>handleChange(name, value)}/>
                </div>
                <div className="fundDetail">
                   
                    <TextInput
                        type="select"
                        label="Industry/Sector"
                        name='sectorid'
                        defaultValue={props?.data?.sectorid}
                        required={true}
                        options={
                        props?.FiltersData?.sectorFocus?.map((option) => ({
                            label: option?.title,
                            value: option?.sectorFocusId,
                        })) || []
                        }
                        onChange={(name, value) => handleChange(name, value)} />               
                 </div>
            </div>
            <div className='fundRow'>
                       <h7>Primary Contact Information</h7>
            </div>
            <div className="fundRow">
                <div className="fundDetail">
                    <TextInput 
                        label="First Name" 
                        name="firstName"
                        type="Onlytext"
                        defaultValue={props?.data?.firstName || ''}
                        required={true}
                        editable={props.editable}
                        onChange={(name, value)=>handleChange(name, value)}/>
                </div>
                <div className="fundDetail">
                    <TextInput 
                        label="Last Name" 
                        name="lastName"
                        type="Onlytext"
                        defaultValue={props?.data?.lastName || ''}
                        required={true}
                        editable={props.editable}
                        onChange={(name, value)=>handleChange(name, value)}/>
                </div>
            </div>
            <div className="fundRow">
                <div className="fundDetail">
                    <TextInput 
                        label="Phone Number" 
                        name="phonenumber"
                        symbol=""
                        type="phoneNumber"
                        defaultValue={props?.data?.phonenumber || ''}
                        required={true}
                        editable={props.editable}
                        onChange={(name, value)=>handleChange(name, value)}/>
                </div>
                <div className="fundDetail">
                    <TextInput 
                        label="Email" 
                        name="email"
                        defaultValue={props?.data?.email || ''}
                        required={true}
                        editable={props.editable}
                        onChange={(name, value)=>handleChange(name, value)}/>
                </div>
              </div>
              <div className="fundRow">
                <div className="fundDetail">
                <Button 
                    style={{ backgroundColor: 'white', color: 'gray', border: '2px solid #B7B7B7' }}
                    >
                    <AddCircleOutlineIcon /> 
                    <span className='margin-left-5'>ADD MORE</span>
                </Button>
                </div>
              </div>
  
        </div>
      );
}
export default NotifyPortifolio;