import secureLocalStorage from "react-secure-storage"

export const distributionStatusEnum = {
    DRAFT: 'Draft',
    ACTIVE: 'Active',
    EXECUTED: 'Executed'
}

export const initialAddDistributionValues = {
    "fundId": secureLocalStorage.getItem("FundId"),
    "fundName": "",
    "distributionTierID": 0,
    "distributionAmount": 0,
    "nav": 0,
    "fdStatusID": 0,
    "distributionID": 0,
    "tierID": 0,
    "dtAllocationType": 0,
    "dtAllocationPercentage": 0,
    "dtAllocationAmount": 0,
    "dtDate": new Date(),
    "dtPaymentDate": new Date(),
    "dtSerialNumber": "",
    "dtCurrency": "",
    "dtSourceID": 0,
    "distributionSource": null,
    "dtStatusID": 0,
    "distributionStatus": "",
    "dtPaymentMethodID": 0,
    "paymentMethod": "",
    "dtTypeID": 0,
    "distributionType": "",
    "distributionInvestors": []
  }
export const previouDistributionsJsonData = [
    {
        "distributionID": 1,
        "createdData": new Date(),
        "distributionStatus": distributionStatusEnum.ACTIVE,
        "distributionAmount": 1000,
        "nooftiers": 2,
        "tierId": 1,
        "tierName": "D1 Tier 1",
        "tierdistributiondate": new Date(),
        "tierstatus": distributionStatusEnum.ACTIVE,
        "allocatedAmount": 200,
        "tiertype": "Normal",
        "noofinvestors": "",
        "totalDistributionAmount": '',
        "navdistributionamount": '',
        "distributionMethod": '',
        "fixedallocation": '',
        "percentageallocation": '',
        "calculatedpercentageamount": '',
        "tier": '',
        "distributiondate": '',
        "currency": "",
        "paymentdate": '',
        "distributionsource": '',
        "statusofdistribution": '',
        "paymentmethod": '',
        "distributiontype": ''
    },
    {
        "distributionID": 1,
        "createdData": new Date(),
        "distributionStatus": "Executed",
        "distributionAmount": 1000,
        "nooftiers": 2,
        "tierId": 2,
        "tierName": "D1 Tier 2",
        "tierdistributiondate": new Date(),
        "tierstatus": distributionStatusEnum.EXECUTED,
        "allocatedAmount": 300,
        "tiertype": "Normal",
        "noofinvestors": "",
        "totalDistributionAmount": '',
        "navdistributionamount": '',
        "distributionMethod": '',
        "fixedallocation": '',
        "percentageallocation": '',
        "calculatedpercentageamount": '',
        "tier": '',
        "distributiondate": '',
        "currency": "",
        "paymentdate": '',
        "distributionsource": '',
        "statusofdistribution": '',
        "paymentmethod": '',
        "distributiontype": ''
    },
    {
        "distributionID": 2,
        "createdData": new Date(),
        "distributionStatus": "Executed",
        "distributionAmount": 2000,
        "nooftiers": 1,
        "tierId": 3,
        "tierName": "D2 Tier 1",
        "tierdistributiondate": new Date(),
        "tierstatus": distributionStatusEnum.ACTIVE,
        "allocatedAmount": 200,
        "tiertype": "Normal",
        "noofinvestors": "",
        "totalDistributionAmount": '',
        "navdistributionamount": '',
        "distributionMethod": '',
        "fixedallocation": '',
        "percentageallocation": '',
        "calculatedpercentageamount": '',
        "distributiondate": '',
        "currency": "",
        "paymentdate": '',
        "distributionsource": '',
        "statusofdistribution": '',
        "paymentmethod": '',
        "distributiontype": ''
    },
    /* {
        "distributionID": 3,
        "createdData": new Date(),
        "distributionStatus": "Executed",
        "distributionAmount": 2000,
        "nooftiers": 1,
        "tierId": 3,
        "tierName": "D1 Tier 1",
        "tierdistributiondate": new Date(),
        "tierstatus": 'Active',
        "allocatedAmount": 200,
        "tiertype": "Normal",
        "noofinvestors": ""
    } */
]

export const tireDummyRows = [
    {
        id: 1,
        tier: 'tier 1',
        allocatedamount: '100000',
        tierstatus: 'Active',
        investors: 'Mohan',
        distributiontype: 'pending',
        distributionamount: '10000',
        subscribed: 'yes',
        contribution: '100000',
        ownership: 'Distribution',
        currDistributionAmount: '2000',
    },
    {
        id: 2,
        tier: 'tier 1',
        allocatedamount: '100000',
        tierstatus: 'Executed',
        investors: 'Mohan',
        distributiontype: 'pending',
        distributionamount: '10000',
        subscribed: 'yes',
        contribution: '100000',
        ownership: 'Distribution',
        currDistributionAmount: '2000',
    }
]

export const alltiresDummyRows = [
    {
        id: 1,
        date: new Date(),
        tier: 'tier 1',
        allocatedamount: '100000',
        tierstatus: 'Active',
        tiertype: 'Normal',
        noofinvestors: '5',
    },
    {
        id: 2,
        date: new Date(),
        tier: 'tier 2',
        allocatedamount: '100000',
        tierstatus: 'Executed',
        tiertype: 'Normal',
        noofinvestors: '15',
    }
]

export const distributionsJsonDATA = [
    {
        id: 1,
        distributionName: 'First Distribution'
    },
    {
        id: 2,
        distributionName: 'Second Distribution'
    }
]

export const distributionPagesEnum = {
    DISTRIBUTION_ACTIVITY: 'distribution activity',
    ADD_EDIT_DISTRIBUTION: 'add/edit distribution'
}



export const distributionMethodEnum = {
    FIXED_ALLOCATION: "Fixed",
    PERCENTAGE_ALLOCATION: "Percentage"
}

export const currencyDropdownDetails = [
    {label : 'USD',value : "USD"},
]

export const distributionDropdownDetails = [
    {label : 'Active',value : distributionStatusEnum.ACTIVE},
    {label : 'Draft',value : distributionStatusEnum.DRAFT},
    {label : 'Executed',value : distributionStatusEnum.EXECUTED},
]


export const distributionMethodValues = [
    {
        label: "Fixed Allocation",
        value: distributionMethodEnum.FIXED_ALLOCATION
    },
    {
        label: "Percentage Allocation",
        value: distributionMethodEnum.PERCENTAGE_ALLOCATION
    }
]