import axios from 'axios';
import resolve from '../../common/resolve.js';
import axiosApiCall from '../../Services/axiosApiCall.js';
import { getAPIResponse } from '../../../utils/common.js';

export async function getFundFilterData() {
    return await axiosApiCall.get(`/v1/Fund/GetFundFilterData`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function createFund(formDataBody) {
    return await axiosApiCall.post(`/v1/Fund/CreateFund`, formDataBody)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function updateFund(formDataBody) {
    return await axiosApiCall.put(`/v1/Fund/UpdateFund`, formDataBody)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function createManagementTeam(requestData) {
    return await axiosApiCall.post(`/v1/Fund/CreateManagementTeam`, requestData)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function updateManagementteams(data,requestData) {
    return await axiosApiCall.put(`/v1/Fund/UpdateManagementTeam?fundManagementTeamID=${data.fundManagementTeamID}`, requestData)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function createFeesandExpenses(requestData) {
    return await axiosApiCall.post(`/v1/Fund/CreateFeesExpenses`, requestData)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function updateFeesandExpenses(data,requestData) {
    return await axiosApiCall.put(`/v1/Fund/UpdateFeesExpenses?feesAndExpensesID=${data.id}`, requestData)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function deleteManagementteamemeber(data) {
    return await axiosApiCall.delete(`/v1/Fund/DeleteManagementTeam?managementTeamID=${data.fundManagementTeamID}&fundID=${data.fundID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function deleteFeesandExpenses(data) {
    return await axiosApiCall.delete(`/v1/Fund/DeleteFeesExpenses?feesAndExpensesID=${data.id}&fundID=${data.fundID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function CreateInvestmentStrategy(requestData) {
    return await axiosApiCall.post(`/v1/Fund/CreateInvestmentStrategy`, requestData)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function updateInvestmentStrategy(requestData) {
    return await axiosApiCall.put(`/v1/Fund/UpdateInvestmentStrategy`, requestData)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

