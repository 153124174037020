import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Grid } from '@mui/material';
import { useState } from 'react';
import ALTTextField from '../../common/input-fields/ALTTextField';
import ActionButton from '../../common/ActionButton/ActionButton';
import Text from '../../common/Text/Text';
import RecommendIcon from '@mui/icons-material/Recommend';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
//#This popup displays when fund manager wants to approve the investor subscription with out compltinion of compliance review
const FMSubscriptionConfirmationPopup = (props) => {

    //#region props
    const { open, onClose, isApproved, updateSubscriptionStatus, approveBtnLoading } = props;

    //#region variables
    const [reason, setReason] = useState()

    //#region change events
    const handleChange = (name, value) => {
        setReason(value);
    }

    //#region click events
    const onConfirmClick = () => {
        updateSubscriptionStatus(isApproved, reason)
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>
                <Text label='Confirmation' />
            </DialogTitle>
            <DialogContent>
                <p>
                    By proceeding, you confirm that you have reviewed the investor's subscription request and are satisfied with its accuracy and completeness. You will be required to electronically sign the investor agreement. Do you wish to proceed and sign the agreement?                    </p>
                {/*  <div className=''>
                   <ALTTextField 
                      name="reason"
                      value={reason}
                      multiline={true}
                      rows={4}
                      onChange={(name,value)=>handleChange(name,value)}/>
                </div> */}
            </DialogContent>
            <DialogActions>
                <div className='space-between'>
                    <div>
                        <ActionButton variant='outlined' disabled={approveBtnLoading} icon={<CancelIcon />} onClick={onClose} label='Cancel' />
                    </div>
                    <div className='margin-left-10'>
                        <ActionButton
                            label='CONFIRM'
                            loading={approveBtnLoading}
                            startIconName=''
                            icon={<CheckCircleIcon />}
                            onClick={onConfirmClick} />
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    )
}
export default FMSubscriptionConfirmationPopup;