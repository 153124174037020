import React, { useEffect, useState } from 'react'
import InvestorInvestmentsGrid from '../../investor-dashboard/components/InvestorInvestmentsGrid';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import FundPerformance from '../../common/FundPerformance/FundPerformance';
import { chartTypes, PerformancePages } from '../../common/FundPerformance/apiDataTemplate';
import secureLocalStorage from 'react-secure-storage';
import AppNotifications from '../../common/AppNotification/AppNotificationComponent';
import FundNews from '../../common/FundNews/FundNews';
import { GetSuggestedArticlesByInvestorID } from '../../FundManagerDashboard/Services/FundNewsService';
import InvestorTransactionsGrid from './InvestorTransactionsGrid';
import { transactionTypesEnum } from '../services/apiDataTemplate';
import { getInvestorSubscribedFunds } from '../../investor-dashboard/services/services';

const SubscriberOverview = () => {

  //#region variables  
  const investorID = secureLocalStorage.getItem("subscriberID")
  const [fundNewsData,setFundNewsData] = useState();
  const [subscribedFunds, setSubscribedFunds] = useState();

  //#region api get call
  const getFundNews = async () => {
    const data = await GetSuggestedArticlesByInvestorID(investorID);
    if (data.responseCode === 200) {
      setFundNewsData(data.responseData);
    }
    else {
      setFundNewsData([]);
    }
  }

  const getFundDetails = async () => {
    const data = await getInvestorSubscribedFunds(investorID);
    if (data.responseCode === 200) {
       setSubscribedFunds(data.responseData.filter(u=>u.subscriptionStatus !== "Investor Sign Pending"));
    }
    else {
      setSubscribedFunds([])
    }
  }

  //#region useEffect
  useEffect(() => {
    getFundNews();
    getFundDetails();
  }, []);

  //#region return
  return (
    <Box className='mt2-page'>
        <Grid xs={12}  className='margin-top-15 margin-bottom-20'>
            <InvestorInvestmentsGrid investorID={investorID} />
        </Grid>
        <Grid container className='margin-bottom-20' spacing={2}>
            <Grid item xs={12} md={6}>
              <div className="white-card">
                <FundPerformance 
                     type={PerformancePages.SUBSCRIBER_VIEW} 
                     allFundsDetails={subscribedFunds} defaultChart={chartTypes.IRR_BENCHMARK} />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div className="white-card">
                    <h6>Notifications</h6>
                    <div className='height-525'>
                       <AppNotifications crntFundID={0} crntInvestorid={investorID} />
                    </div>
                </div>
            </Grid>
        </Grid>
        <Grid container className='margin-bottom-20' spacing={2}>
            <Grid item xs={12} md={6}>
              <div className="white-card height-525">
                <FundNews FundNewsData={fundNewsData} role={"FundManager"} FundNewsByUserID={true} />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div className="white-card height-525">
                  <InvestorTransactionsGrid type={transactionTypesEnum.INVESTOR_TRANSACTIONS} gridHeight={450} investorID={investorID} />
                </div>
            </Grid>
        </Grid>
    </Box>
  )
}

export default SubscriberOverview