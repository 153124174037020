import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import json from "../../common/commonJsonData.json";
const DashboardSelect2 = (props) => {
  const { options, label, value, onChange,type } = props;

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  
  const years = [
    { id: 1, label: '2024', value: 2024 },
    { id: 2, label: '2023', value: 2023 },
    { id: 3, label: '2022', value: 2022 },
    { id: 4, label: '2021', value: 2021 },
    // Add more years as needed
  ];

  const updateOptions = type === "years" ? years : options;
  

  return (
    <FormControl>
      <InputLabel sx={{ top: -10 }}>{label}</InputLabel>
      <Select
        label={label}
        value={value}
        onChange={handleChange}
        displayEmpty
        style={{ width:85, height: 33, borderRadius: 5, marginBottom:10,fontSize:12}} // Adjust the width as needed
      >
        {updateOptions?.map((option) => (
          <MenuItem key={option.id} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DashboardSelect2;
