import React from 'react';
import Box from '@mui/material/Box';
import { Grid } from "@mui/material";
import VDRoom from '../../common/VirtualDataRoom/VDRoom'
import CircleIcon from '../../chatbot/Icon';
import { Typography } from "@mui/material";

export const PortfolioVDR = () => {
  return (
    <div>
        <Typography marginBottom={1} variant='h5'>Data Room</Typography>
        <Box className='background-color-white' sx={{ padding: '16px' }}>
                <VDRoom  showToggle={true} VDRType={'Portfolio'}/>
            </Box>
            <CircleIcon/>
    </div>
  )
}
