import React from "react";
import { useState, useEffect } from "react";
import { InputTypes } from "../../../common/TextInput/appInputenum";
import AppTextInput from "../../../common/TextInput/AppTextField";
import { validationsTypeEnum } from "../../../common/TextInput/appInputenum";
import { validationMessages } from "../../../common/TextInput/appInputenum";
import { fundTypesEnum } from "../../../marketplace-dashboard/jsonData";
import { filter } from "d3";

const FundType = (props) => {

    //#region variables
    const initialFundDetails = {
        fundTypeID: '',
        fundName: '',
        fundTypeName: '',
        fundStaff: [],
        //fundName: '',
        // iconImage: '',
        // bgImage: '',
        fundDescription: '',
        investmentStrategyId: '',
        fundTargetIndustryId: '',
        geographicFocusId: '',
        fundSize: '',
        minimumInvestmentAmount: '',
        fundStatus: '',
        vintage: 2024,
        targettedIRR: '',
        investmentPeriodCriteria: '',
        liquidity: '',
        visibility: '0',
        fundManager: '',
        legalStructureID: '',
        ...props.data,
    }

    // debugger

    const [FundTypeDetails, setFundTypeDetails] = useState(initialFundDetails);
    const [noofValidFields, setNoofValidFields] = useState({ validfields: 0 });
    const [checkIfValid, setcheckIfValid] = useState(false);

    //#region events
    const handleChange = (field, value) => {
        const currState = { ...FundTypeDetails };
        if (field === 'fundName') {
            currState[field] = value;
            setFundTypeDetails(currState)
        }

        if (field === 'fundTypeID') {
            const currFundType = props.fundFilterData.fundType.find(u => u.fundTypeID === value).fundTypeName
            if (currFundType === fundTypesEnum.PRIVATE_CREDIT) {
                currState["investmentPeriodCriteria"] = 100
            } else {
                currState["investmentPeriodCriteria"] = ""
            }
            currState[field] = value
            currState['fundTypeName'] = currFundType;
            setFundTypeDetails(currState)
        }
    }

    //#region validations
    const checknoofValidFields = (isValid, name, defaultValue) => {
        // debugger
        //const dynamicFieldsCount = fundDetails?.isFirmAsGP ? 0 : fundManagers?.length;
        const TotalFiledsToValidate = 2;
        noofValidFields['validfields'] = isValid ? noofValidFields['validfields'] + 1 : noofValidFields['validfields'];
        if (noofValidFields['validfields'] === TotalFiledsToValidate) {
            noofValidFields['validfields'] = 0;
            props.setFundDetailErrors(false);
            props.setStep(currStep => currStep + 1);
        }
    };

    useEffect(() => {
        if (props.FundDetailerrors === true) {
            handleSave();
        }
    }, [props.FundDetailerrors])

    const handleSave = () => {
        setcheckIfValid(true);
        noofValidFields['validfields'] = 0;
    }

    //#region useffect
    useEffect(() => {
        props?.updateData(FundTypeDetails);
    }, [FundTypeDetails]);


    return (
        <div>
            <div className="fundRow">
                <div className="fundDetail">
                    <AppTextInput
                        type={InputTypes.SELECT}
                        label="Fund Type"
                        name="fundTypeID"
                        defaultValue={FundTypeDetails?.fundTypeID}
                        options={
                            props.fundFilterData?.fundType?.filter?.((option)=> option.fundTypeID != -1)
                            .map(option => ({
                            label: option?.fundTypeName,
                            value: option?.fundTypeID,
                        }))}
                        onChange={(name, value) => handleChange(name, value)}
                        validateField={true}
                        validationType={validationsTypeEnum.REQUIRED}
                        validationMessage={validationMessages.REQUIRED}
                        checkIfValid={checkIfValid}
                        checknoofValidFields={checknoofValidFields}
                        setcheckIfValid={setcheckIfValid}
                    />
                </div>
                <div className="fundDetail">
                    <AppTextInput
                        textFormatType="text"
                        label={"Fund Name"}
                        name="fundName"
                        defaultValue={FundTypeDetails?.fundName}
                        onChange={(name, value) => handleChange(name, value)}
                        validateField={true}
                        validationType={validationsTypeEnum.REQUIRED}
                        validationMessage={validationMessages.REQUIRED}
                        checkIfValid={checkIfValid}
                        checknoofValidFields={checknoofValidFields}
                        setcheckIfValid={setcheckIfValid}
                    />
                </div>
            </div>
        </div>
    )
}

export default FundType