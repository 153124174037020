import React, { useState } from 'react';
import { Dialog,DialogActions, DialogContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Rating from '@mui/material/Rating';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { ButtonGroup, Button, ToggleButton, ToggleButtonGroup } from "react-bootstrap";


const CustomGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  padding: theme.spacing(2),
  borderRadius: '8px',
  width:"90%",
  marginTop:'10px',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.2), 0px 3px 8px rgba(0, 0, 0, 0.24) inset'

}));


function Thankyou(props) {
  const [open, setOpen] = useState(true);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    props.handleCancelModel(false);
  };

  const onnextclick = () => {
    props.setActiveStep(props.activeStep + 1);
  };

  const onprevclick = () => {
    props.setActiveStep(props.activeStep - 1);
  };
  return (
    <div>
      <Dialog open={open}>
        <DialogContent>
          <div style={{ textAlign: 'center', color: 'blue' }}>
            <h1>Thank You!</h1>
          </div>
          <div style={{ textAlign: 'center' }}>
            <h5>You have Completed Your Portfolio Company Update for the {props.companyName}</h5>
          </div>
          <div style={{ textAlign: 'center' }}>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin sit amet odio accumsan arcu
              posuere venenatis quis nec mauris. Nam consequat ullamcorper nisl, et blandit dolor blandit eget.
            </p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '30px' }}>
            <Stepper orientation="vertical">
              <Step>
                <StepLabel>Update Submitted</StepLabel>
              </Step>
              <Step>
                <StepLabel>Approved by Firm</StepLabel>
              </Step>
              <Step>
                <StepLabel>Documents Sent to Your Inbox</StepLabel>
              </Step>
            </Stepper>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <CustomGrid container spacing={2} justifyContent="center">
                <Typography variant="h6" sx={{ marginTop: '-10px', marginRight: '10px' }}>
                  Please rate your AIP experience
                </Typography>
                <Rating name="rating" defaultValue={0} max={5} sx={{ marginTop: '-6px' }} />
              </CustomGrid>
              <Button color="primary" style={{ marginTop: '10px',height:'50px', width: '100px' }} onClick={handleClose}>
                Close
              </Button>
            </div>
                      
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Thankyou;
