import axios from "axios";
import resolve from "../../../../common/resolve";
import axiosApiCall from "../../../../Services/axiosApiCall";
import { getAPIResponse } from "../../../../../utils/common";

export async function getApprovedSubscribersListForFund(fundID) {
    return await axiosApiCall.get(`/v1/FundSubscription/GetFundSubscriptions?fundID=${fundID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getK1ReportDocumentsForFund(fundID,InvestorID) {
    return await axiosApiCall.get(`/v1/Servicing/GetK1ReportDocument?FundID=${fundID}&InvestorID=${InvestorID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function addNewK1Document(formDataBody) {
    return await axiosApiCall.post(`/v1/Servicing/UploadK1ReportDocument`, formDataBody)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}
