import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { ToastContainer, toast } from 'react-toastify';
import { LinearProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import EmailsView from './EmailsView';
import '../InvestorCommunications/styles.css';
import { useEffect } from 'react';

const EventSubGroupsAccordion = (props) => {
    const { emailTemplates, EmailtemplatesData, getExcistingGroups, setComponentRefresh } = props;

    const [expanded, setExpanded] = useState(null);
    const [groupedByEmailSubgroups, setGroupedByEmailSubgroups] = useState({});

    const MenuItemsTop = [
        {
            id: 1,
            label: 'Edit SubGroup',
            onClick: () => { }
        },
    ];

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : null);
    };

    // Filter and group email templates by valid subgroups
    useEffect(() => {
        // Group email templates by subgroups
        const groupEmailsBySubgroups = () => {
            return emailTemplates.reduce((acc, cur) => {
                const subgroup = cur.subgroups;
                if (!acc[subgroup]) {
                    acc[subgroup] = [];
                }
                acc[subgroup].push(cur);
                return acc;
            }, {});
        };

        // Call the grouping function and store the result in state
        setGroupedByEmailSubgroups(groupEmailsBySubgroups());
    }, [emailTemplates]);


    return (
        <div className='notifications-accordion-wrapper'>
            {Object.keys(groupedByEmailSubgroups).length === 0 ? "No Sub Groups Found!" :
                Object.entries(groupedByEmailSubgroups).map(([subgroup, items]) => (
                    <div key={subgroup} className='accordion-spacing space-between' style={{ marginBottom: '16px' }}>
                        <div className='sub-group-acc-width'>
                            <Accordion
                                expanded={expanded === subgroup}
                                onChange={handleAccordionChange(subgroup)}
                                style={{ border: '1px lightgray solid' }}
                            >
                                <AccordionSummary
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                                >
                                    <div>{subgroup}</div>
                                    <div style={{ marginLeft: 'auto' }}>
                                        {expanded === subgroup ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {items.map(item => (
                                        <EmailsView
                                            key={item.emailTemplateID}
                                            setExpanded={setExpanded}
                                            getExcistingGroups={getExcistingGroups}
                                            emailTemplateID={item.emailTemplateID}
                                            EmailtemplatesData={EmailtemplatesData}
                                            subGroupID={item.templateSubGroupID}
                                            EmailtemplatesDetails={item}
                                            isAccordionExpanded={expanded === subgroup}
                                            setComponentRefresh={setComponentRefresh}
                                        />
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        {/* <div className='margin-top-9'>
                            <ContextMenu MenuItemsTop={MenuItemsTop} />
                        </div> */}
                    </div>
                ))
            }
            <ToastContainer />
        </div>
    );
};

export default EventSubGroupsAccordion;
