import React from 'react'
import '../../../Generalize.css';
import { useState } from 'react';
import { useEffect } from 'react';
import '../../../common/NestedList/styles.css'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
import ActionButton from '../../../common/ActionButton/ActionButton';

import { useSelector } from "react-redux";
import { getrootBackgroundColorConfig } from '../../../../state/slices/colorConfigSlice';
import Accordians from './Acoordians';
import { getRoles } from '../../../FundManagerDashboard/Services/FundNewsService';
import { AppDashboards } from '../../../../utils/enum';
import { getFundbyId } from '../../../investor-fund-subscription/services/services';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const ManageTeams = (props) => {

  //#region variables
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [arraydata,setArrayData] = useState(props.data);
  const isFundMatured = secureLocalStorage.getItem("selectedFund")?.FundMaturity;
  const isFundTerminated = secureLocalStorage.getItem("selectedFund")?.FundTermination;

  //#region click events
  const handleCheckboxChange = (index) => {
    setSelectedIndex(index); // Update the selected index for the checkbox
  }

  const addMember= (arraydata) =>{ 
       if(props.name == 'managementteams'){
        if (arraydata === null || arraydata === undefined || arraydata.length === 0) {
          
          setArrayData([
            {
              "fundManagementTeamID": 0,
              "fundID": 0,
              "firstName": "",
              "lastName": "",
              "description": "",
              "title": "",
              "emailAddress": "",
              "addressLine1": "",
              "addressLine2": "",
              "phoneNumber": "",
              "city": "",
              "state": 0,
              "country": 0,
              "zipCode": "",
              "managementTeamStaff": 0,
              "authorizedSignatory": false
            }
          ]);
        } else if ( arraydata.length > 0) {
          setArrayData(prevTeamMembers => [
            ...prevTeamMembers,
            {
              "fundManagementTeamID": 0,
              "fundID": 0,
              "firstName": "",
              "lastName": "",
              "description": "",
              "title": "",
              "emailAddress": "",
              "addressLine1": "",
              "addressLine2": "",
              "phoneNumber": "",
              "city": "",
              "state": 0,
              "country": 0,
              "zipCode": "",
              "managementTeamStaff": 0,
              "authorizedSignatory": false
            }
          ]);
        }
        
       }
       else if(props.name == 'feesandexpenses'){
        if (arraydata === null || arraydata.length === 0) {
          setArrayData([
            {
              "id": 0,
              "fundID": 0,
              "managementFees": '',
              "performanceFeesOrCarriedInterest": '',
              "additionalFeesAndExpenses": ''
            }
          ]);
        }
      else if ( arraydata.length > 0) {
        setArrayData(prevTeamMembers => [
          ...prevTeamMembers,
          {
            "id": 0,
            "fundID": 0,
            "managementFees": '',
              "performanceFeesOrCarriedInterest": '',
              "additionalFeesAndExpenses": ''
          }
        ]);
      }
       }
  }

  const DeleteMember = () => {
    const lastMember = props.list[props.list.length - 1];
  
    if (lastMember) {
      const updatedList = props.list.slice(0, -1);
      props.setList(updatedList);
    }
  };

  //#region useeffect
  useEffect(()=>{
      return ()=>{
         props.updateData(arraydata);
      }
  }, [arraydata]);

  //#region return
  return (
    <div>
      
        <div className="fundRow no-margin" style={{marginBottom: '10px',marginRight: '10px'}}>
          <div>
            
          </div>
          <div>
             {!isFundTerminated && !isFundMatured  ? 

                    (<ActionButton 
                      variant="outlined"
                      label={props.ButtoonLabel} 
                      startIconName="ControlPointOutlined" 
                      onClick={() => addMember(arraydata)}
                      styleProps={{
                          borderColor: '#4287f5 !important',
                          color: '#4287f5 !important',
                          fontSize: '14px',
                      }}/>) :

                      (props.name == 'managementteams' ?  
                        <div>
                          <div>
                            
                          </div>
                          <div>
                            <ActionButton 
                                variant="outlined"
                                label={props.ButtoonLabel} 
                                startIconName="ControlPointOutlined" 
                                onClick={() => addMember(arraydata)}
                                styleProps={{
                                    borderColor: '#4287f5 !important',
                                    color: '#4287f5 !important',
                                    fontSize: '14px',
                                }}/>
                          </div>
                        </div> : 
                          null)
             }
                    
                </div>
         </div>
         {
            (arraydata && arraydata.length > 0) &&
            <Accordians 
              list={arraydata} 
              ondelete={DeleteMember} 
              updateData={props.updateData} 
              componentName={props.name} 
              selectedIndex ={selectedIndex}
              setSelectedIndex = {setSelectedIndex}
              handleCheckboxChange = {handleCheckboxChange}
              Component={props.Component} 
              getFundDetails={props.getFundDetails}
              setArrayData={setArrayData}
              managementStaffData= {props.managementStaffData?.filter(a => a.userRoleName !== AppDashboards.INV_DASHBOARD)}
            />
          }
    </div>
  )
}

export default ManageTeams