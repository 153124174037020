import { FunctionComponent, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./FundDetailCompliance.module.css";
import AIPDataGrid from "../../common/DataGrid/AIPDataGrid";
import jsondata from './FundDetailCompliances.json';
import { useState } from "react";
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import DashboardSelect2 from "../../common/Select/DashboardSelect2";
import { checkFeaturePermissions } from "../../../utils/common";
import { FeatureAccess, RolesAndPermissions } from "../../../utils/enum";
import { Grid, Typography } from "@mui/material";
import secureLocalStorage from "react-secure-storage";
import { getKycAmlDetailsbyFundID } from "../../KYCVerification/services/services";

const FundDetailCompliance = () => {

  //#region varibales
  const navigate = useNavigate(); 
  const [investorsList,setInvestorsList] = useState(null);
  const [selectedComplianceYear, setSelectedComplianceYear] = useState('');
  const [selectedKYCYear, setSelectedKYCYear] = useState('');
  const Columns = [
    { field: 'fullName', headerName: 'INVESTOR NAME', width: 300, },
    { field: 'type', headerName: 'INVESTOR TYPE', width: 200,
      renderCell: (params) => {
         return "Individual Investor"
      } 
    },
    { field: 'kycStatusName', headerName: 'KYC', width: 200 },
    { field: 'amlStatusName', headerName: 'AML', width: 200 },
  ]
  const years = [
    { id: 1, label: '2020', value: 2020 },
    { id: 2, label: '2021', value: 2021 },
    { id: 3, label: '2022', value: 2022 },
    { id: 4, label: '2023', value: 2023 },
  ];


  //#region click events
  const handleComplianceYearChange = (year) => {
    setSelectedComplianceYear(year);
  };
  const handleKYCYearChange = (year) => {
    setSelectedKYCYear(year);
  };
  const onInvestorClick = (params) => {
    secureLocalStorage.setItem("KYCInvestorUserId", params.row.id);
    secureLocalStorage.setItem("KYCInvestorEmailAddress", params.row.useremail);
    secureLocalStorage.setItem("KYCInvestorDetails", params.row);
    /* navigate("/kyc-aml-verification"); */
    navigate(`/kyc-aml-verification/details?investorId=${params.row.id}&readonly=${true}`);
    window.scrollTo(0, 0);
  }

  //#region api get calls
  const getInvestors = async () => {
    const fundID = secureLocalStorage.getItem("FundId")
    const data = await getKycAmlDetailsbyFundID(fundID);
    if (data?.responseCode === 200) {
      const reversedData = data.responseData;
      setInvestorsList(reversedData);
    } else { 
      // Handle error if needed
    }
  }

  //#region useeffect
  useEffect(()=>{
    getInvestors();
  },[])
  
  return (
    <div>
      <h4>Compliance </h4>
      <Grid container xs='12' spacing={2} marginTop={1} style={{ margin: '0px auto' }}>
        {
          checkFeaturePermissions(RolesAndPermissions.COMPLIANCE_ALERTS, FeatureAccess.READ) &&
          <Grid item xs='12' md='6' style={{ paddingLeft: '0px' }} >
            <div className={styles.fundPerfSectionCont}>
              <div className={styles.secondaryStatsWrapper}>
                <h6>Compliance Alerts</h6>

                <DashboardSelect2
                  options={years}
                  type="years"
                  label={'Year'}
                  value={selectedComplianceYear}
                  onChange={handleComplianceYearChange}
                />
              </div>
              <div style={{ height: '85%' }}>
                <AIPDataGrid
                  columns={jsondata.ComplianceAlertColumns}
                  rows={jsondata.ComplianceAlertRows}
                  onRowsSelectionHandler={() => { }} />
              </div>

            {/*   <button
                className='seemore mt5'
                onClick={() => navigate("/marketplace/PerformancePage")}>
                See More  <ArrowForwardOutlinedIcon />

              </button> */}
            </div>
          </Grid>
        }
        {
          checkFeaturePermissions(RolesAndPermissions.KYC_AML_DOCUMENTATION, FeatureAccess.READ) &&
          <Grid item xs='12' md='6' className="res-grid">
            <div className={styles.fundPerfSectionCont}>
              <div className={styles.secondaryStatsWrapper}>
                <h6>KYC/AML Documentation Status for LPs</h6>

                <DashboardSelect2
                  options={years}
                  type="years"
                  label={'Year'}
                  value={selectedKYCYear}
                  onChange={handleKYCYearChange}
                />
              </div>
              <div style={{ height: '85%' }}>
                  <AIPDataGrid
                        columns={Columns}
                        rows={investorsList ? investorsList?.map((user)=>{
                          return{
                            id: user.userId,
                            ...user
                          }
                        }) : []}
                        handleRowClick={onInvestorClick}
                        onRowsSelectionHandler={() => { }}
                        sortByLatestFirst = {false}
                      />
              </div>

              {/* <button
                className='seemore mt5'
                onClick={() => navigate("/marketplace/PerformancePage")}>
                See More <ArrowForwardOutlinedIcon />
              </button> */}
            </div>
          </Grid>
        }
      </Grid>
    </div>
  );
};

export default FundDetailCompliance;
