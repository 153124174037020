// Function to convert a number to US currency format
export const ConvertToUSCurrency = (value) => {
    // Convert value to integer if it's a string and possible to convert
    const number = parseInt(value, 10);
    
    // Check if the converted value is not a number
    if (isNaN(number)) {
        return 0;
    }
    
    // Convert number to US currency format
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    }).format(number);
};

export const ConvertToPercentage = (value, decimals) => {
    // Convert value to a number if possible
    const number = parseFloat(value);
    
    // Check if the converted value is not a number
    if (isNaN(number)) {
        // Return the original value if it's a string, preserving its format
        return typeof value === 'string' ? `${value}` : '0%';
    }
    
    // Convert number to percentage format with specified decimals
    return `${number.toFixed(decimals)}%`;
};


