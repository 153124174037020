import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LensIcon from '@mui/icons-material/Lens';
import { LinearProgress } from '@mui/material';

const FundSubscriptionStatusBar = (props) => {

  //#region initialData
  const { statusName, statusColor } = props;

  //#region variables
  const steps = [
    'Subscription Initiated',
    'Subscription Reviewed',
    'Subscription Approved',
    'Funds Received',
    'Investment Active',
    'Distributions Made',
    'Investment Matured',
    'Redemption Processed',
    'Investment Closed',
  ];

  const [currStep, setCurrStep] = useState(1);

  //#region functions
  const stepIcon = (stepNo) => {
    if (stepNo === currStep) {
      return <LensIcon sx={{ height: '24px', width: '24px' }} />;
    }
    else if (stepNo < currStep) {
      return <CheckCircleIcon sx={{ height: '24px', width: '24px' }} />;
    }
    else {
      return ""
    }
  }

  const stepColor = (stepNo) => {
    if (stepNo === currStep) {
      if (statusColor === "RED") {
        return "#FF0101";
      }
      else {
        return "#FDDA0D";
      }
    }
    else if (stepNo < currStep) {
      return "green";
    }
    else {
      return ""
    }
  }

  //#region styles
  const getIconStyles = (index) => ({
    width: stepIcon(index) === "" ? "20px" : "",
    height: stepIcon(index) === "" ? "24px" : "",
  });

  //#region useeffect
  useEffect(() => {
    if (statusName) {
      const findCurrStep = steps?.findIndex((item) => item === statusName)
      setCurrStep(findCurrStep === -1 ? 0 : (statusColor === "RED" ? findCurrStep : findCurrStep + 1))
    }
  }, [statusName, statusColor])

  return (
    <Box sx={{ width: '100%' }} marginTop={3}>{
      currStep ?
        <Stepper activeStep={currStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel 
               icon={stepIcon(index)}
               sx={{ color: stepColor(index) }}
               StepIconProps={{
                style: getIconStyles(index),
               }} >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        :
        <LinearProgress />
    }
    </Box>
  )
}

export default FundSubscriptionStatusBar