import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import resolve from '../../common/resolve';
import axiosApiCall from '../../Services/axiosApiCall';
import { getAPIResponse } from '../../../utils/common';


export async function getAuditDetails() {
    return await axiosApiCall.get(`/v1/Auditing/GetAuditDetails`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
    });
}

export async function getInvestorProfileAuditing(selectedUserID) {
    return await axiosApiCall.get(`/v1/Auditing/GetUsersAuditing?userID=${selectedUserID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
    });
}

export async function getSubscriptionAuditing(selectedUserID) {
    return await axiosApiCall.get(`/v1/Auditing/GetSubscriptionAuditing?userID=${selectedUserID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
    });
}

export async function getFundAuditing(selectedID) {
    return await axiosApiCall.get(`/v1/Auditing/GetFundAuditing?fundID=${selectedID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
    });
}

export async function getPCAuditing(selectedID) {
    return await axiosApiCall.get(`/v1/Auditing/GetPortfolioCompanyAuditing?companyID=${selectedID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
    });
}