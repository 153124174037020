import React, { useEffect } from 'react';
import { css, Global } from "@emotion/react";



const ThemeSelector = ({ fontSize, fontFamily }) => {
  useEffect(() => {
    if (fontSize === 1) {
      import('../content/Small.css')
    }
    else if (fontSize === 2) {
      import('../content/Medium.css')
    }
    else if (fontSize === 3) {
      import('../content/Large.css')
    }
    else {

    }
  }, [fontSize]);

  return <Global styles = { css `body,h1,h6,, .MuiDataGrid-cellContent , .MuiDataGrid-cell--withRenderer , .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1 , .vtab-item , .css-1u4x7km-MuiTypography-root , .MuiButtonBase-root , .MuiDataGrid-columnHeaderTitleContainerContent ,MuiTableCell-root, .MuiTypography-root , { font-family: ${fontFamily} !important; }` } />;
};


export default ThemeSelector;