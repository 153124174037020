import React from "react";
const SiteHero = () => {
  return (
    <section id="hero" className="hero d-flex align-items-center section-bg">
      <div className="container">
        <div className="row justify-content-between gy-5">
          <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center align-items-center align-items-lg-start text-center text-lg-start">
            <h2>It's Good to Have Alternatives</h2>
            <p>
              With more than $225 billion in AUM* across real estate, private
              equity, private credit and more, we offer a comprehensive range of
              alternatives solutions.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SiteHero;
