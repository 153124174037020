import React from 'react';
import './UnderConstruction.css';


const UnderConstruction = () => {
 return (
    <div className="underConstruction-container">
      <h1 className='underConstruction-heading'>Under Construction</h1>
      <p className='underConstruction-content'>We are currently working on this section of our website. Please check back soon!</p>
      {/* <div className="link-container">
        <a href="https://www.example.com">Home</a>
        <a href="https://www.example.com/contact">Contact</a>
      </div> */}
    </div>
 );
};

export default UnderConstruction;