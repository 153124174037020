import React from 'react';
import MiniDrawer from '../../common/navigation-bar/NavBarGrid';
import DashboardHeader from '../DashboardHeader';
import styles from './PartnersCapital.module.css';
import { FunctionComponent, useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CurrencyFormatter } from '../../../utils/Formater';
import AIPDataGrid from '../../common/DataGrid/AIPDataGrid';
import jsonData from './partners.json';
import secureLocalStorage from 'react-secure-storage';


function PartnersCapital({ isExpanded, setExpandState }) {
  const navigate = useNavigate();
  const onBackArrowButton1Click = useCallback(() => {
    navigate("/marketplace/fund-detail-services-1");
  }, [navigate]);
  const [selectedFundName, setSelectedFundName] = useState('Nexus Health Equity Fund');
  
  
  const Servicingcolumns = [
    {
      field: "commitment",
      headerName: "Commitment",
    
    },
    {
      field: "commitmentCalled",
      headerName: "Commitment Called",
      
    },
    {
      field: "recallableDistributions",
      headerName: "Recallable Distributions",
    },
    {
      field: "unfundedCommitment",
      headerName: "Unfunded Commitment",
    },
    {
      field: "percentageOfPartnership",
      headerName: "Percentage of Partnership",
      
    },
  ];
  const Capitalcolumns = [
    {
      field: "emptyHeader",
      headerName: "",
      width: "300",
      sortable: false,
    },
    {
      field: "QuartertoDate",
      headerName: "Quarter to Date",
      width: "180",
    },
    {
      field: "YeartoDate",
      headerName: "Year to Date",
      width: "180",
    },
    {
      field: "ExceptiontoDate",
      headerName: "Exception to Date",
      width: "150",
    },
  ];

  const [data, setData] = useState([]);
  const [servicingData, setServicingData] = useState([]);

  const [capitalData, setCapitalData] = useState(null);
  
  const [selectedFundId, setSelectedFundId] = useState(1);
  const [specificData, setSpecificData] = useState({});
  

  
  const fundId = secureLocalStorage.getItem("FundId");
  
  const fetchDataForSelectedFund = async (fundName) => {
   
  };
  
  

 const handleFundNameClick = (fundName, fundId) => {
    setSelectedFundName(fundName);
    setSelectedFundId(fundId);
    fetchDataForSelectedFund(fundId);
  };

  
  useEffect(() => {
   fetchDataForSelectedFund()
  }, [fetchDataForSelectedFund]);
  

  
  
  const [fund, setFund] = useState();



  
  const [capital, setCapital] = useState([]);

  useEffect(() => {
  }, []);
  
  

 

 
  
  
  
  return (
    <div className={styles.PartnersCapital}>
      <div className={styles.PartnersCapitalChild}>
       
        <div className={styles.information}>
          <p>Investor Name</p>
          <p>Vertice Buyout Fund I</p>
          <p>Unaudited Capital Account statement</p>
          <p>For The Period Ended December 31, 2018</p>
        </div>
        <div className={styles.horizontalLineContainer}>
          <hr className={styles.horizontalLine} />
         </div>
         <div className={styles.textContainer}>
            <p className={styles.heading}>Big Bear Retirement Fund</p>
            <div className={styles.commitmentSummaryContainer}>
              <p className={styles.commitmentSummaryBox}>Commitment Summary</p>
                
            </div>
            <table className={styles.dataTable}>
  <div>
    <div className={styles.dataRow} style={{ margin: '0 20px' }}>
      <div className={styles.dataCell} >
        <div className={styles.label}>Commitment:</div>
        <div className={styles.value}>{capitalData?.commitment || '0'}</div>
      </div>
      <div className={styles.dataCell}>
        <div className={styles.label}>Commitment Called:</div>
        <div className={styles.value}>{capitalData?.commitmentCalled || '0'}</div>
      </div>
      <div className={styles.dataCell}>
        <div className={styles.label}>Recallable Distributions:</div>
        <div className={styles.value}>{capitalData?.recallableDistributions || '0'}</div>
      </div>
      <div className={styles.dataCell}>
        <div className={styles.label}>Unfunded Commitment:</div>
        <div className={styles.value}>{capitalData?.unfundedCommitment || '0'}</div>
      </div>
      <div className={styles.dataCell}>
        <div className={styles.label}>Percentage of Partnership:</div>
        <div className={styles.value}>{capitalData?.percentageOfPartnership || '0'}</div>
      </div>
    </div>
  </div>
</table>

    <div className={styles.commitmentSummaryContainer1}>
              <p className={styles.commitmentSummaryBox1}>Statement Of Partner's Capital</p>
            </div>
            <div style={{margin: '0 auto', marginTop: '-85px', width: '70%' }}>
           {/* <AIPDataGrid columns={Capitalcolumns} rows={capital}  /> */}
           <AIPDataGrid
              columns={Capitalcolumns}
              rows={capital.length > 0 ? capital : [
            {
              id: '0',
              emptyHeader: '0',
              QuartertoDate: '0',
              YeartoDate: '0',
              ExceptiontoDate: '0'
            }
  ]}
/>

           </div>
        </div>
      </div>
      <div>
        
       <DashboardHeader FundName={secureLocalStorage.getItem("FundName")} showFullName={false} />
     
      </div>
    </div>
  );
}

export default PartnersCapital;
