import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    IconButton,
    Button
} from '@mui/material';
import { Close } from '@mui/icons-material';
import TextInput from '../../common/TextInput/TextInput';
import Text from '../../common/Text/Text';
import ActionButton from '../../common/ActionButton/ActionButton';
import RemovingInvestor from './RemovingInvestor';


function SelectedInvestorData(props) {

    //#region props 
    const { open, onClose, BulkUploadSaveData } = props;

    //#region variables
    const [RemoveInvestorwarning, setRemoveInvestorwarning] = useState(false);

    //#region click events
    const RemoveInvestorClick = () => {
        setRemoveInvestorwarning(true);
    }
    const RemoveInvestorCancelClick = () => {
        setRemoveInvestorwarning(false);
    }
    return (
        <Dialog open={open} fullWidth>
            <div>
                {
                    RemoveInvestorwarning ?
                        <RemovingInvestor RemoveInvestorCancelClick={RemoveInvestorCancelClick} /> :
                        <div>
                            <DialogTitle>
                                <Grid container alignItems="center">
                                    <Grid item xs={10}>
                                        <Text label="Investors" />
                                    </Grid>
                                    <Grid item xs={2} container justifyContent="flex-end">
                                        <IconButton onClick={onClose}>
                                            <Close />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent className='disablediv'>
                                <div className='fundRow'>Review Selected Investor's Profile Information</div>
                                <div className='fundRow'>
                                    <TextInput label="Firstname" defaultValue={BulkUploadSaveData[0]?.firstName} />
                                </div>
                                <div className='fundRow'>
                                    <TextInput label="Lastname" defaultValue={BulkUploadSaveData[0]?.lastName} />
                                </div>
                                <div className='fundRow'>
                                    <TextInput label="Email Address" defaultValue={BulkUploadSaveData[0]?.emailAddress} />
                                </div>
                                <div className='fundRow'>
                                    <TextInput label="Address Line1" defaultValue={BulkUploadSaveData[0]?.addressLine1} />
                                </div>
                                <div className='fundRow'>
                                    <TextInput label="Address Line2" defaultValue={BulkUploadSaveData[0]?.addressLine2} />
                                </div>
                                <div className='fundRow'>
                                    <TextInput label="State" defaultValue={BulkUploadSaveData[0]?.state} />
                                </div>
                                <div className='fundRow'>
                                    <TextInput label="City" defaultValue={BulkUploadSaveData[0]?.city} />
                                </div>
                                <div className='fundRow'>
                                    <TextInput label="Zipcode" defaultValue={BulkUploadSaveData[0]?.zipCode} />
                                </div>
                                <div className='fundRow'>
                                    <TextInput label="Annual Pre-tax Income" defaultValue={BulkUploadSaveData[0]?.annualPreTaxIncome} />
                                </div>
                                <div className='fundRow'>
                                    <TextInput label="Number of Dependants" defaultValue={BulkUploadSaveData[0]?.numberOfDependents} />
                                </div>
                                <div className='fundRow'>
                                    <TextInput label="Employer Status" defaultValue={BulkUploadSaveData[0]?.employerStatus} />
                                </div>
                                <div className='fundRow'>
                                    <TextInput label="Employer" defaultValue={BulkUploadSaveData[0]?.employer} />
                                </div>
                                <div className='fundRow'>
                                    <TextInput label="Occupation" defaultValue={BulkUploadSaveData[0]?.occupation} />
                                </div>
                                <div className='fundRow'>
                                    <TextInput label="Spouse Annual Pre-Tax Income" defaultValue={BulkUploadSaveData[0]?.spouseAnnualPreTaxIncome} />
                                </div>
                                <div className='fundRow'>
                                    <TextInput label="Spouse Has IIA Account" defaultValue={BulkUploadSaveData[0]?.spouseHasIIAAccount} />
                                </div>
                                <div className='fundRow'>
                                    <TextInput label="Federal Tax Bracket" defaultValue={BulkUploadSaveData[0]?.federalTaxBracket} />
                                </div>
                                <div className='fundRow'>
                                    <TextInput label="Household Investable Assets" defaultValue={BulkUploadSaveData[0]?.householdInvestableAssets} />
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <div className="space-between">
                                    <div>
                                        <Button
                                            color='primary'
                                            component="label"
                                            variant="Text"
                                            onClick={onClose}
                                        >
                                            ok
                                        </Button>
                                    </div>

                                </div>
                            </DialogActions>
                        </div>
                }

            </div>

        </Dialog>
    )
}

export default SelectedInvestorData