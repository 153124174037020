import React from 'react'
import { useState, useEffect } from 'react';
import { getGroupsList } from '../../../Services/GroupsServices';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Accordian from '../../../../common/Accordian/Accordian';
import '../styles.css'
import secureLocalStorage from 'react-secure-storage';
import { getInvestorsandGroupsList } from '../../../Services/GroupsServices';
import AddIcon from '@mui/icons-material/Add';
import Button from "@mui/material/Button";
import AddGroups from '../../AddGroups';
import GroupsPopup from './Popups/GroupsPopup';
import { getInvestorsList } from '../../../Services/IRServices';
import { ToastContainer, toast } from 'react-toastify';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import GroupDetailsView from './GroupDetailsView';
import ContextMenu from '../../../../common/ContextMenu/ContextMenu';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EmailIcon from '@mui/icons-material/Email';
import { getGroupByID } from '../../../Services/GroupsServices';
import GroupDeletePopup from './Popups/GroupDeletePopup';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { LinearProgress } from '@mui/material';

const GroupsMain = (props) => {

    //#region Props from Parent
    const { navigateCustomEmail } = props; //to move to custom email tab

    //#region useState Variables
    const [excistinggroups, setExcistingGroups] = useState(null);
    const [InvestorsGroupsList, setInvestorsGroupsList] = useState(null);
    const [selectedPeriod, setSelectedPeriod] = React.useState('5');
    const [investorsList, setInvestorsList] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [deletedGroup, setDeletedGroup] = useState(null);
    const [expanded, setExpanded] = useState({});

    //#region Popup Variables
    const [addGroupPopup,setAddGroupPopup] = useState(false);
    const [editPopup,setEditPopup] = useState(false);
    const [deletePopup,setDeletePopup] = useState(false);

    //#region dropdown options
    const Options = [
        { value: '5', label: 'Any Time' },
        { value: '1', label: 'Older than a Week' },
        { value: '2', label: 'Older than a Month' },
        { value: '4', label: 'Older than a 6 Months' },
        { value: '3', label: 'Older than a Year' },
    ]

    //#region events
    const handleChange = (event) => {
        setSelectedPeriod(event.target.value);
    };

    const handleOpenPopup = () => {
        setAddGroupPopup(true);
    }

    const handleClosePopup = () => {
        setAddGroupPopup(false);
    }

    const handleCloseEditPopup = () => {
        setEditPopup(false);
        setSelectedGroup(null);
    }

    const handleEdit = (item) => {
        getGroupDetails(item);
        setEditPopup(true);
    }

    const handleDelete = (item) => {
        setDeletePopup(true);
        setDeletedGroup(item);
    }

    const handleDeletePopupClose = () => {
        setDeletePopup(false);
        setDeletedGroup(null);
    }

    const naviagetoCustomEmail = (item) => {
        navigateCustomEmail(0,item?.userGroupID);
    }

    //#region Accordians events
    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded({ ...expanded, [panel]: isExpanded });
    };

    const handleCloseAllAccordians = () => {
        setExpanded({});
        getExcistingGroups();
    }

    //#region Context Menu Items
    const MenuItemsTop = [
        {
            id: 1,
            label: 'Edit Group',
            icon: <EditIcon />,
            onClick: handleEdit
        },
        {
            id: 1,
            label: 'Notify Group',
            icon: <EmailIcon />,
            onClick: naviagetoCustomEmail
        },
        {
            id: 2,
            label: 'Delete Group',
            icon: <DeleteIcon />,
            onClick: handleDelete
        }
    ]

    
    //#region GET API Calls
    const getExcistingGroups = async () => {
        const data = await getGroupsList(secureLocalStorage.getItem('tenantID'));
        if (data?.responseCode == 200) {
            setExcistingGroups(data?.responseData)
        } else {
            setExcistingGroups([])
        }
    }
 
     const getGroupDetails = async (item) => {
        const data = await getGroupByID(item?.userGroupID);
        if (data?.responseCode === 200) {
            setSelectedGroup(data?.responseData);
        } else {
            /* toast.warning(data?.responseData,
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" }); */
        }
    }
    
    const getInvestorsGroupsList = async () => {
        const data = await getInvestorsandGroupsList();
        if (data?.responseCode === 200) {
            setInvestorsGroupsList(data?.responseData);
        } else {
            /* toast.warning(data?.responseData,
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" }); */
        }
    }

    const getInvestors = async () => {
        const data = await getInvestorsList();
        if (data?.responseCode === 200) {
            setInvestorsList(data?.responseData);
        } else {
            /* console.log(data?.responseData); */
        }
    }

    //#region useEffect 
    useEffect(() => {
        getExcistingGroups();
        getInvestors();
        getInvestorsGroupsList();
    }, []);

    //#region Accordian View
    const accordianEndView = (item,isExpanded) => {
        return (
            <div className='space-between'>
               <div className='mr4 reciepients-count'>
                  Recipients : {item?.noOfGroupMembers}
               </div>
               <div>
                {
                    isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />
                }
               </div>
            </div>
        )
    };

    return (
        <div className='notifications-accordian-wrapper'>
            <div className='space-between'>
                <div>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedPeriod}
                        onChange={handleChange}
                        sx={{ width: '230px', height: '35px', padding: '5px' }}>
                        {
                            Options ? Options?.map((item) => {
                                return (
                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                )
                            }) : null
                        }
                    </Select>
                </div>
                <div>
                    <Button variant="outlined" className="btn-primary" style={{ cursor: 'pointer'}} onClick={handleOpenPopup}>
                    <AddIcon/>
                    <span>Add Group</span>
                    </Button>
                </div>
            </div>
            {
                excistinggroups && InvestorsGroupsList ? excistinggroups.length === 0 ? "No Groups Found!" :
                    excistinggroups?.map((item) => {
                        return (
                            <div key={item.userGroupID} className='space-between'>
                                <div className='groupview-accordian'>
                                    <Accordion expanded={expanded[item.userGroupID] || false}
                                                onChange={handleAccordionChange(item.userGroupID)}
                                                style={{border:'1px lightgray solid'}}>
                                        <AccordionSummary
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                                sx={{ display: 'flex', justifyContent: 'space-between' }} >
                                                <div>{item?.userGroupName}</div>
                                                <div style={{ marginLeft: 'auto' }}>
                                                    {accordianEndView(item,expanded[item.userGroupID])}
                                                </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                                <GroupDetailsView 
                                                    groupID={item.userGroupID} 
                                                    isAccordianExpanded={expanded[item.userGroupID]} 
                                                    navigateCustomEmail={navigateCustomEmail} 
                                                    entityTypeName={item.entityTypeName}/>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                                {item.entityTypeName?.toLowerCase() === "fund" ? <div className='margin-right-30'></div>
                                :
                                <div className='margin-top-9'>
                                   <ContextMenu MenuItemsTop={MenuItemsTop} ItemDetails={item}/>
                                </div>}
                            </div>
                        )
                    }) : <div>
                           <LinearProgress />
                        </div>
            }
            {
                addGroupPopup && <GroupsPopup 
                                    open={addGroupPopup}
                                    data={[]}
                                    isNewGroup={true}
                                    onClose={handleClosePopup}
                                    refreshData={getExcistingGroups} 
                                    investorsList={investorsList}/>
            }
            {
                editPopup && selectedGroup && <GroupsPopup 
                                    open={editPopup}
                                    data={selectedGroup}
                                    isNewGroup={false}
                                    onClose={handleCloseEditPopup}
                                    refreshData={getExcistingGroups} 
                                    refresGroupDetails={handleCloseAllAccordians}
                                    investorsList={investorsList}/>
            }
            {
                deletePopup && deletedGroup && <GroupDeletePopup 
                                                    open={deletePopup}
                                                    onClose={handleDeletePopupClose}
                                                    data={deletedGroup}
                                                    refreshData={getExcistingGroups} />
            }
            <ToastContainer />
        </div>
    )
}

export default GroupsMain