import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Grid } from '@mui/material';
import { useState } from 'react';
import ActionButton from '../../../../../common/ActionButton/ActionButton';
import InfoIcon from '@mui/icons-material/Info';
import { updateGroupData } from '../../../../Services/GroupsServices';
import { ToastContainer, toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import TouchAppIcon from '@mui/icons-material/TouchApp';
const GroupDeletePopup = (props) => {

    //#region Props from Parent
    const { open, onClose,data,refreshData } = props;

    //#region useState Variables
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState(data);

    //#region Update API Calls
    const handleDelete = async () => {
        setLoading(true);
        if (details) {
            details["isDeleted"] = true;
        }
        console.log(details);
        const data = await updateGroupData(details);
        if (data?.responseCode === 200) {
            setLoading(false);
            onClose();
            refreshData();
            toast.success(`Group Delete Sucessfully`,
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
        else {
            setLoading(false);
            onClose();
            toast.warning(`Unable to Delete the Group`,
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
            <h6> <InfoIcon className='info-i' /> Confirmation</h6>
            </DialogTitle>
            <DialogContent>
                Are you sure you want to delete the group?
            </DialogContent>
            <DialogActions>
                <div className='space-between'>
                    <div>
                        <ActionButton className='btn-primary' variant='outlined' onClick={onClose} label='Cancel' icon={<CancelIcon/>}/>
                    </div>
                    <div className="margin-left-10">
                        <ActionButton className='btn-primary'
                            label='Ok'
                            loading={loading}
                            icon={<TouchAppIcon/>}
                            onClick={handleDelete} />
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    )
}
export default GroupDeletePopup;