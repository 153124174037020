import { FunctionComponent, useCallback ,useState , useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ReportingQuarterlyReport.module.css";
import MiniDrawer from "../../common/navigation-bar/NavBarGrid";
import DashboardHeader from "../DashboardHeader";
import { DataGrid } from '@mui/x-data-grid';
import { CurrencyFormatter } from "../../../utils/Formater";
import CircleIcon from "../../chatbot/Icon";
const ReportingQuarterlyReport = () => {
    const [isExpanded, setExpendState] = useState(false);
    const [servicestab, setServicestab] = useState(true);
  const navigate = useNavigate();

  const onBackArrowButton1Click = useCallback(() => {
    navigate("/marketplace/fund-detail-services-1");
  }, [navigate]);
  const columns = [
    { field: 'header', headerName: 'NAV Reconciliation and Summary of Fees, Expenses & Incentive Allocation', width: 550 },
    { field: 'value1', headerName: 'QTD (Oct-15 - Dec-15)', width: 180 },
    { field: 'value2', headerName: 'YTD (JAN -15 - Dec-15)', width: 180 },
    { field: 'value3', headerName: 'Since Inception( Oct-15 - Dec-15)', width: 180 },
  ];
  const rowHeight = 40;
  const [data, setData] = useState(null);
 
  useEffect(() => {
    
  }, []);
  const firstrows = data
  ? data.map(rows => ({
      id: rows.quarterlyReportID,
      header: rows.qrProperty,
      value1: CurrencyFormatter(rows.qrqtdValue),
      value2: CurrencyFormatter(rows.qrytdValue),
      value3: CurrencyFormatter(rows.qrAllTimeValue),
      isMainField: rows.isMainField, 

    })) 
  : [];


  return (
    <div className={styles.reportingQuarterlyReport}>
      <div className={styles.reportingQuarterlyReportChild} />
      <div className={styles.editSaveButton}>
        <div className={styles.editSaveButtonChild} />
        <div className={styles.print}>Print</div>
        <img
          className={styles.iconMaterialPrint}
          alt=""
          src="/capitalimages/icon-materialprint.svg"
        />
      </div>
      <div className={styles.editSaveButton1}>
        <div className={styles.editSaveButtonChild} />
        <div className={styles.download}>Download</div>
        <img
          className={styles.iconMaterialFileDownload}
          alt=""
          src="/capitalimages/icon-materialfiledownload.svg"
        />
      </div>
      
      <div className={styles.capitalAccountStatementParent}>
        <b className={styles.capitalAccountStatement}>
          Capital Account Statement
        </b>
        <img
          className={styles.moreVertFill0Wght400Grad0Icon}
          alt=""
          src="/capitalimages/more-vert-fill0-wght400-grad0-opsz48.svg"
        />

<div className = {styles.lineParent}>
<DataGrid
  rows={firstrows}
  columns={columns.map(column => ({
    ...column,
    headerAlign: 'center',
    headerClassName: params =>
      params.field === 'header' && params.rows?.isMainField ? 'header-cell-spaced' : '',
  }))}
  disableColumnMenu
  autoHeight
  rowHeight={rowHeight}
  pagination
  pageSize={firstrows.length}
  components={{
    Footer: () => null,
  }}
/>

      </div>
      </div>

 
      <CircleIcon/>
      <div className={styles.maskGroup35Group}>
        
        <div className={styles.nexusHealthEquityFundParent}>
          <div className={styles.nexusHealthEquity}>
            Nexus Health Equity Fund
          </div>
          <img
  className={styles.backArrowButton1}
  alt=""
  src="/capitalimages/back-arrow-button.svg"
  onClick={onBackArrowButton1Click}
  style={{ filter: 'brightness(0) invert(1)' }} // Apply a white color filter
/>

          <div className={styles.maskGroup38Container}>
            <img
              className={styles.maskGroup381}
              alt=""
              src="/capitalimages/mask-group-381@2x.png"
            />
          </div>
        </div>
      </div>
    
    </div>
  );
};

export default ReportingQuarterlyReport;