import { Box, Button, Card, Grid } from '@mui/material';
import React, { useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const VideosPage = (props) => {

  //#region props
  const {setActiveBodySection} = props;

  //#region variables
  const videos = [
    {
        title: 'Add Fund',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-AddFund.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/InvestorRegistrationProcess.jpg',
        componentTxt: 'videos'
    },
    {
        title: 'Calendar',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-Calendar.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/Thumbnail2.png',
        componentTxt: 'videos'
    },
    {
        title: 'Compare Funds',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-CompareFunds.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/Thumbnail2.png',
        componentTxt: 'videos'
    },
    {
        title: 'Compliance Officer Dashboard',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-ComplianceOfficerDashboard.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/Thumbnail2.png',
        componentTxt: 'videos'
    },
    {
        title: 'Favorite Funds',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-FavouriteFunds.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/Thumbnail2.png',
        componentTxt: 'videos'
    },
    {
        title: 'FundManager Dashboard',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-FundManagerDashboard.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/Thumbnail2.png',
        componentTxt: 'videos'
    },
    {
        title: 'Initiating CapitalCalls to Investors',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-InitiatingCapitalCallstoInvestors.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/Thumbnail2.png',
        componentTxt: 'videos'
    },
    {
        title: 'Initiating Distributions to Investors',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-InitiatingDistributionstoInvestors.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/Thumbnail2.png',
        componentTxt: 'videos'
    },
    {
        title: 'Investor-MyInvestments',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-Investor-_MyInvestments.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/Thumbnail2.png',
        componentTxt: 'videos'
    },
    {
        title: 'Investor Dashboard',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-InvestorDashboard.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/Thumbnail2.png',
        componentTxt: 'videos'
    },
    {
        title: 'Investor Registration Process',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-InvestorRegistrationProcess.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/Thumbnail2.png',
        componentTxt: 'videos'
    },
    {
        title: 'Invite Investors',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-InviteInvestors.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/Thumbnail2.png',
        componentTxt: 'videos'
    },
    {
        title: 'K-1s Reports',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-K-1sReports.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/Thumbnail2.png',
        componentTxt: 'videos'
    },
    {
        title: 'Marketplace',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-Marketplace.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/Thumbnail2.png',
        componentTxt: 'videos'
    },
    {
        title: 'New Subscriber',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-NewSubscriber.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/Thumbnail2.png',
        componentTxt: 'videos'
    },
    {
        title: 'Onboarding and Profiling',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-OnboardingandProfiling.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/Thumbnail2.png',
        componentTxt: 'videos'
    },
    {
        title: 'Subscription Process',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-SubscriptionProcess.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/Thumbnail2.png',
        componentTxt: 'videos'
    },
    {
        title: 'Suggested Readings',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-SuggestedReadings.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/Thumbnail2.png',
        componentTxt: 'videos'
    },
  ];

  //#region click events
  const handleBackToList = (article) => {
        setActiveBodySection(null)
  };  

  //#region return
  return (
    <div className='article-container'>
            <Box>
                <Button 
                 onClick={() => handleBackToList('article')}> 
                 <ArrowBackIcon  className='helpbackicon'/>
                  Go Back to Main Page 
                </Button>
                <Grid container spacing={4} marginTop={1}>
                    {videos?.map((video, index) => (
                        <Grid item xs={12} sm={6} md={3} lg={3} key={index} className='articleVedio'>
                            <Card>
                                <Box className="videoSec">
                                    <video
                                        //autoPlay
                                        loop
                                        muted
                                        controls
                                        poster={video.poster}
                                        style={{ width: '100%' }}
                                    >
                                        <source
                                            src={video.url}
                                            type="video/mp4"
                                        />
                                        Your browser does not support the video tag.
                                    </video>
                                </Box>
                            </Card>
                            <h6 className='videotitle'>{video.title}</h6>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </div>
  )
}

export default VideosPage