export const firmWideChartData = [
    {
        "label": "PE Funds",
        "value": 175000
    },
    {
        "label": "VC Funds",
        "value": 250000
    },
    {
        "label": "Hedge Funds",
        "value": 65000
    },
    {
        "label": "Real Estate Funds",
        "value": 250000
    },
    {
        "label": "Fund of Funds",
        "value": 760000
    }
]