import Configurations from '../../../../Configurations';
import axiosApiCall from '../../../Services/axiosApiCall';
import { getAPIResponse } from '../../../../utils/common';

export async function LogMessengerMessages(formDataBody) {
    return await axiosApiCall.post(`/api/InstantMessages/LogMyInstantMessages`, formDataBody)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}


export async function MarkAsReadMessengerMessages(fromuser,touser) {
    return await axiosApiCall.post(`/api/InstantMessages/MarkInstantMessagesAsRead?fromUser=${fromuser}&toUser=${touser}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}



export async function GetReadMessengerMessages(fromuser,touser) {
    return await axiosApiCall.get(`/api/InstantMessages/GetInstantMessages?fromUser=${fromuser}&toUser=${touser}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}