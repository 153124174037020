import { LogLevel } from '@microsoft/signalr';
import { HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import React, { useEffect, useState } from 'react';
import secureLocalStorage from 'react-secure-storage';
import Configurations from '../../../Configurations';
import MessengerModel from './MessengerModel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { configure } from '@testing-library/react';

const AppMessenger = (props) => {
    const [connection, setConnection] = useState();
    const [messages, setMessages] = useState([]);
    const [messageTo, setMessageTo] = useState();
    const userName = secureLocalStorage.getItem("userName");
    const [messagesList, setMessagesList] = useState([]);
    const [users, setUsers] = useState([]);
    const [showMessenger, setShowMessenger] = useState(false);

    const joinRoom = async (user, room) => {
        try {
            const connection = new HubConnectionBuilder()
                .withUrl("https://alt360internalmessenger.azurewebsites.net/chat", {
                })
                .configureLogging(LogLevel.Information)
                .build();

            connection.on("ReceiveMessage", (user, message) => {
                if (message.indexOf("hide") !== 0 && message.split('#').length > 1) {
                    if (message.split('#')[1] === secureLocalStorage.getItem("userName")) {
                        setMessages(messages => [...messages, { user, message: message.split('#')[3] }]);
                        setMessagesList(messagesList => [...messagesList,
                        {
                            state: message.split('#')[0],
                            to_user: message.split('#')[1],
                            from_user: message.split('#')[2],
                            messageReceived: message.split('#')[3],
                        }]);
                    }
                } else {
                    if (user === "MyChat Bot") {
                        setMessages(messages => [...messages, { user, message }]);
                        setMessagesList(messagesList => [...messagesList,
                        {
                            state: '',
                            to_user: '',
                            from_user: '',
                            messageReceived: message
                        }]);
                    }
                }
            });

            connection.on("UsersInRoom", (users) => {
                const uniqueUsers = Array.from(new Set(users));
                setUsers(uniqueUsers);
            });
            // connection.onclose(e => {
            //     setConnection();
            //     setMessages([]);
            //     setUsers([]);
            // });

            await connection.start();
            await connection.invoke("JoinRoom", { user, room });
            setConnection(connection);

        } catch (e) {
            console.log(e);
        }
    };

    const sendMessage = async (message) => {
        try {
            await connection.invoke("SendMessage", `Show#${messageTo}#${secureLocalStorage.getItem('userName')}#${message}`);
        } catch (e) {
            console.log(e);
        }
    };

    const closeConnection = async () => {
        try {
            await connection.stop();
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        console.log('global chat group', Configurations.getTenantName)
        joinRoom(userName, 'alt360');
    }, []);

    return (
        <div>
            {connection && users.length > 0 ?
                <>
                    <div onClick={() => setShowMessenger(true)}>
                        <QuestionAnswerIcon sx={props.styles} />
                    </div>
                    <Dialog open={showMessenger} onClose={() => setShowMessenger(false)} className="chat-popup" fullScreen sx={{ marginTop: '15% !important' }}>
                        <DialogTitle>ALT360 Messenger</DialogTitle>
                        <DialogContent sx={{ overflowY: 'hidden' }}>
                            <div className='mb1 font-size-xsmall font-size-color welcomeuser'>
                            Welcome , {secureLocalStorage.getItem("fullName")}
                            </div>
                            <MessengerModel
                                setMessageTo={setMessageTo}
                                onlineUsers={users}
                                sendMessage={sendMessage}
                                setMessagesList={setMessagesList}
                                messagesList={messagesList}
                            />
                        </DialogContent>
                        <DialogActions></DialogActions>
                    </Dialog>
                </>
                : <></>}
        </div>
    );
};

export default AppMessenger;
