import React, { useState } from 'react';
import TextInput from '../../../common/TextInput/TextInput';
import "../../CompanyDetail.module.css"
import ActionButton from '../../../common/ActionButton/ActionButton';
import { useRef } from 'react';
import { convertNamesToArray } from '../CommonFunctions/PortfolioFunctions';
import { convertArrayToNames } from '../CommonFunctions/PortfolioFunctions';

function BoardMembers(props) {

    const [open, setOpen] = useState(true)
    const componentRef = useRef(null);

      const handleChange = (name, value,index,error) => {
        const digits = value.replace(/\D/g, '');
        BoardMembersArray[index][name] = value;
        BoardMembersArray[index][name] = value;
       
        const ArrayNames = convertArrayToNames(BoardMembersArray);
        props.data["boardMembers"] = ArrayNames;
        props.setapiData(props.data);
      };

      const handleChangeNewMembers = (name, value,index) => {
        newBoardMembers[index][name] = value;
        const ArrayNames = convertArrayToNames(newBoardMembers);
        props.data["boardMembers"] = ArrayNames;
        props.setapiData(props.data);
      };
 
      const excistingMembers = convertNamesToArray(props?.data?.boardMembers)

      const [BoardMembersArray,setBoardMembersArray] = useState(excistingMembers);

      const [newBoardMembers, setNewBoardMembers] = useState([
        { id: 1 ,firstname: '', lastname: '' ,errorfirstname: false,errorlastname: false}
      ]);

      const handleAddMore = () => {
        if(BoardMembersArray!=null){
            const lastId = BoardMembersArray.length > 0 ? BoardMembersArray[BoardMembersArray.length - 1].id : 0;
            setBoardMembersArray([...BoardMembersArray, { id: lastId + 1, firstname: '', lastname: '' }]);
        }
        else{
          const lastId = newBoardMembers.length > 0 ? newBoardMembers[newBoardMembers.length - 1].id : 0;
          setNewBoardMembers([...newBoardMembers, { id: lastId + 1, firstname: '', lastname: '' }]);
        }
      };
      return (
        <div ref={componentRef}>
          {
                BoardMembersArray === null && newBoardMembers.length > 0 ?
                newBoardMembers.map((item, index) => {
                    return (
                        <div className="fundRow" key={index}>
                            <div className="fundDetail">
                                <TextInput 
                                    type="Onlytext"
                                    label="First Name" 
                                    name="firstname"
                                    required={true}
                                    defaultValue={item.firstname}
                                    onChange={(name, value) => handleChangeNewMembers(name, value,index)}
                                />
                            </div>
                            <div className="fundDetail">
                              <TextInput 
                                      type="Onlytext"
                                      label="Last Name" 
                                      name="lastname"
                                      required={true}
                                      defaultValue={item.lastname}
                                      onChange={(name, value) => handleChangeNewMembers(name, value,index)}
                                  />
                            </div>
                        </div>
                    );
                }
                ) :  BoardMembersArray.map((item, index) => {
                  return (
                      <div className="fundRow" key={index}>
                          <div className="fundDetail">
                              <TextInput 
                                  type="Onlytext"
                                  label="First Name" 
                                  name="firstname"
                                  required={true}
                                  defaultValue={item.firstname}
                                  onChange={(name, value) => handleChange(name, value,index)}
                                 
                              />
                          </div>
                          <div className="fundDetail">
                            <TextInput 
                                    type="Onlytext"
                                    label="Last Name" 
                                    name="lastname"
                                    required={true}
                                    defaultValue={item.lastname}
                                    onChange={(name, value) => handleChange(name, value,index)}
                                />
                          </div>
                      </div>
                  );
              })
            }
          
          <div className="fundRow">
                <div className="fundDetail">
                    <ActionButton 
                        variant="outlined"
                        label="ADD MORE" 
                        startIconName="ControlPointOutlined" 
                        onClick={handleAddMore}
                        styleProps={{
                            borderColor: '#b7b7b7',
                            color: '#b7b7b7',
                            fontSize: '14px',
                        }}/>
                </div>
            </div>

        </div>
      );
}
export default BoardMembers;