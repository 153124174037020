import axiosApiCall from "../../Services/axiosApiCall";
import { getAPIResponse } from "../../../utils/common";
import secureLocalStorage from "react-secure-storage";

export async function getFundbyId(fundID) {
    return await axiosApiCall.get(`/v1/Fund/GetFundById?fundID=${fundID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        })
};

export async function getUserQuestionBank(investorId,questionType) {
    return await axiosApiCall.get(`/v1/FundSubscription/getUserQuestionBank?investorID=${investorId}&questionType=${questionType}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        })
};

export async function getFundSubscriptionDetails(fundID, investorId, subscriptionId) {
    return await axiosApiCall.get(`/v1/FundSubscription/Get?fundid=${fundID}&investorID=${investorId}&subscriptionID=${subscriptionId}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        })
};

export async function saveSubscriptionAnswers(formBody) {
    return await axiosApiCall.post(`/v1/FundSubscription/SaveSubscriptionAnswers`, formBody)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        })
};

export async function SubscribeFund(fundID, investorID, subscriptionID, capitalCommitted, isOffListSubscription, submitAndESign, formBody) {
    return await axiosApiCall.post(`/v1/FundSubscription/Subscribe?fundid=${fundID}&investorID=${investorID}&subscriptionID=${subscriptionID}&capitalCommitted=${capitalCommitted}&isOffLineSubscription=${isOffListSubscription}&submitSubscription=${submitAndESign}`, formBody)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        })
};

export async function downloadOfflineSubscriptionForm(subscriptionID) {
    return await axiosApiCall.get(`/v1/FundSubscription/DownloadOfflineSubscription?subscriptionID=${subscriptionID}`, {
        responseType: 'blob',
    })
    .then(res => {
        return getAPIResponse(res)
    }).catch(error => {
        return getAPIResponse(error)
    })
}

export async function downloadsubscriptiontemplate(fundid,investorid,isprefilledform) {
    return await axiosApiCall.get(`/v1/FundSubscription/DownloadSubscriptionTemplate?FundID=${fundid}&InvestorID=${investorid}&IsPrefilledDocument=${isprefilledform}`)
    .then(res => {
        return getAPIResponse(res)
    }).catch(error => {
        return getAPIResponse(error)
    })
}

