import React, { useEffect, useState } from 'react'
import { initialCompanyDetails } from '../../APIDataTemplate';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button, IconButton, MenuItem, TextField, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import ActionButton from '../../../common/ActionButton/ActionButton';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import AddCompanyManager from '../../popups/AddCompanyManager'
import secureLocalStorage from 'react-secure-storage';
import { createPortfolioCompany, getPCCompanybyID, updatePCCompanyDetails } from '../../services/services';
import { ToastContainer, toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { convertStringToArray, convertStringToArrayWithEmptyArray } from '../../../common/Functions/ConvertStringtoArray';
import DeleteIcon from '@mui/icons-material/Delete';

const PCCompanyOverview = (props) => {

    //#region props
    const { dropDownsData, companyDetails, setCompanyDetails } = props;

    //#region inital data
    const boardMemebrsInitialData = [{
        id: 1,
        data: ""
    }]

    //#region variables
    const [isNewCompany, setNewCompanyStatus] = useState(companyDetails === 0 ? true : false);//Need to change the status after sucessfully creating the company
    const [isSaveEnabled, setSaveEnabled] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [boardMembers, setBoardMembers] = useState([]);

    //#region click events
    const onOpenPopup = () => {
        setOpenPopup(true);
    }
    const onPopupClose = () => {
        setOpenPopup(false)
    }
    const onEditClick = () => {
        setSaveEnabled(true);
    }
    const onSaveClick = () => {
        if (isNewCompany) {
            createNewCompany();
        }
        else {
            updateCompanyDetails();
        }
    }
    const onResetClick = () => {
        overviewValues.resetForm();
        setSaveEnabled(false);
    }

    //#region functions
    function convertArrayToString(arr) {
        if (!Array.isArray(arr) || arr.length === 0) {
            return "";
        }

        const onlyNames = arr.map((item) => item.data);
        return onlyNames.join(',');
    }

    //#region change events
    const handleAddMore = () => {
        const addmember = {
            id: boardMembers.length > 0 ? boardMembers[boardMembers.length - 1].id + 1 : 1,
            data: ""
        }
        setBoardMembers([...boardMembers, addmember]);
    }

    const handleDelete = (data) => {
        const updatedMembers = boardMembers.filter(member => member.id !== data.id);
        setBoardMembers(updatedMembers);
    }

    const handleChangeMembers = (ev) => {
        const { name, value } = ev.target;
        const updatedMembers = boardMembers.map(item => {
            if (item.id === parseInt(name)) {
                return {
                    ...item,
                    data: value
                };
            }
            return item;
        });
        setBoardMembers(updatedMembers);
    }

    //#region api get calls
    const getCompaniesDetails = async (companyID) => {
        const data = await getPCCompanybyID(companyID);
        if (data.responseCode === 200) {
            setCompanyDetails(data.responseData);
            secureLocalStorage.setItem("companyID", data.responseData.companyId);
            /*TODO: Need to ResetForm after Updating the company   */
            overviewValues.resetForm(data.responseData);
        } else {
            /* console.log("Error Fetching Details"); */
        }
    }

    //#region api post calls
    const createNewCompany = async () => {
        setLoading(true);
        const fundID = secureLocalStorage.getItem("FundId");
        overviewValues.values.boardMembers = convertArrayToString(boardMembers)
        const data = await createPortfolioCompany(fundID, overviewValues.values);
        if (data.responseCode === 200) {
            setNewCompanyStatus(false);
            setLoading(false);
            secureLocalStorage.setItem(data.responseData);
            getCompaniesDetails(data.responseData);
            toast.success("Portfolio Company Created Successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                theme: "colored",
            });
        }
        else {
            setLoading(false);
            toast.warning("Unable to Create Company", {
                position: toast.POSITION.BOTTOM_RIGHT,
                theme: "colored",
            });
        }
    }

    //#region api update calls
    const updateCompanyDetails = async () => {
        setLoading(true);
        overviewValues.values.companyStatusId = 1;
        overviewValues.values.boardMembers = convertArrayToString(boardMembers);
        const data = await updatePCCompanyDetails(overviewValues.values);
        if (data.responseCode === 200) {
            getCompaniesDetails(data.responseData);
            setLoading(false);
            toast.success("Company Details Updated Successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                theme: "colored",
            });
            setSaveEnabled(false);
        }
        else {
            setLoading(false);
            toast.warning("Unable to Update Company Details", {
                position: toast.POSITION.BOTTOM_RIGHT,
                theme: "colored",
            });
            setSaveEnabled(false);
        }
    }

    //#region useeffect
    useEffect(() => {
        const apiMembers = isNewCompany ? convertStringToArrayWithEmptyArray(initialCompanyDetails.boardMembers) : convertStringToArrayWithEmptyArray(companyDetails.boardMembers);
        const addIDS = Array.isArray(apiMembers) && apiMembers.length > 0
            ? apiMembers.map((member, index) => ({ id: index, data: member }))
            : [];debugger
        setBoardMembers(addIDS?.length > 0 ? addIDS : boardMemebrsInitialData );
    }, [companyDetails]);




    //#region formik validations
    const overviewValidationSchema = yup.object().shape({
        companyName: yup
            .string()
            .required('Company Name is required')
            .matches(/^[A-Za-z\s]+$/, 'Company Name must contain only alphabets'),
        sectorId: yup
            .string()
            .test(
                'not-zero',
                'This Field is required',
                value => value !== '0')
            .required('This Field is required')
    });

    const overviewValues = useFormik({
        enableReinitialize: true,
        initialValues: isNewCompany ? initialCompanyDetails : companyDetails,
        validationSchema: overviewValidationSchema,
        onSubmit: (values) => {
            onSaveClick(values);
        },
    });

    return (
        <div className='display-column-margin-10'>
            <form onSubmit={overviewValues.handleSubmit} novalidate="novalidate">
                <div className='margin-top-15 addPortofolio-co'>
                <div className='add-pc-Manager'>
                            {
                                companyDetails?.portofolioCompanyManagerID === 0 ?
                                    <Button
                                        label="ADD COMPANY MANAGER"
                                        variant="outlined"
                                        className="btn-primary"
                                        startIcon={<AddIcon />}
                                        onClick={onOpenPopup}>
                                      Add Portfolio Company Manager  
                                    </Button> : ""
                            }
                        </div>
                    <div className='space-between justifyrightAlign'>
                        
                        {
                            isNewCompany ?
                                <LoadingButton
                                    variant='contained'
                                    size="large"
                                    startIcon={<SaveIcon />}
                                    loading={loading}
                                    type='submit'
                                    loadingPosition="start">
                                    <p className={`actionButtonLabel`}>SUBMIT</p>
                                </LoadingButton> :
                                isSaveEnabled ?
                                    <div className='space-between'>
                                        <div>
                                            <Button
                                                label="CANCEL"
                                                variant="outlined"
                                                className="btn-primary"
                                                startIcon={<CancelIcon />}
                                                onClick={onResetClick}>
                                                CANCEL
                                            </Button>
                                        </div>
                                        <div className='margin-left-5'>
                                            <LoadingButton
                                                variant='contained'
                                                size="large"
                                                startIcon={<SaveIcon />}
                                                loading={loading}
                                                type='submit'
                                                loadingPosition="start">
                                                <p className={`actionButtonLabel`}>SAVE</p>
                                            </LoadingButton>
                                        </div>
                                    </div> :
                                    <Button
                                        label="EDIT"
                                        variant="contained"
                                        className="btn-primary"
                                        startIcon={<EditIcon />}
                                        onClick={onEditClick}>
                                        EDIT
                                    </Button>
                        }
                    </div>
                </div>
                <div className='margin-top-15 child-margin-15'>
                    {/* //#region head Quaters  */}
                    <div className='head-quaters white-card'>
                    <div className='margin-bottom-10' style={{marginTop:'10px'}}> <div className='text-field'>
                    <TextField
                        id="companyName"
                        name="companyName"
                        label="Company Name"
                        variant='standard'
                        required={true}
                        fullWidth
                        value={overviewValues?.values?.companyName}
                        onChange={overviewValues.handleChange}
                        InputLabelProps={{
                            shrink: true,
                            focused: true,
                        }}
                        InputProps={{
                            readOnly: isNewCompany ? false : !isSaveEnabled,
                        }}
                        onBlur={overviewValues.handleBlur}
                        error={overviewValues.touched.companyName && Boolean(overviewValues.errors.companyName)}
                        helperText={overviewValues.touched.companyName && overviewValues.errors.companyName} />
                </div></div>
                        <h6>Head Quarters Address</h6>
                        <div className=' margin-top-10'>
                            <div className='text-fields-row'>
                                <div className='text-field'>
                                    <TextField
                                        select
                                        id="sectorId"
                                        name="sectorId"
                                        label="Industry/Sector"
                                        variant='standard'
                                        required={true}
                                        fullWidth
                                        value={overviewValues?.values?.sectorId}
                                        onChange={overviewValues.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            focused: true,
                                        }}
                                        InputProps={{
                                            readOnly: isNewCompany ? false : !isSaveEnabled,
                                        }}
                                        onBlur={overviewValues.handleBlur}
                                        error={overviewValues.touched.sectorId && Boolean(overviewValues.errors.sectorId)}
                                        helperText={overviewValues.touched.sectorId && overviewValues.errors.sectorId}>
                                        {dropDownsData && dropDownsData?.sectorFocus.map((item) => (
                                            <MenuItem key={item.sectorFocusId} value={item.sectorFocusId}>
                                                {item.title}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className='text-field'>
                                    <TextField
                                        select
                                        id="companyHQCountry"
                                        name="companyHQCountry"
                                        label="Country"
                                        variant='standard'
                                        fullWidth
                                        value={overviewValues?.values?.companyHQCountry}
                                        onChange={overviewValues.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            focused: true,
                                        }}
                                        InputProps={{
                                            readOnly: isNewCompany ? false : !isSaveEnabled,
                                        }}>
                                        {dropDownsData && dropDownsData?.country.map((item) => (
                                            <MenuItem key={item.countryId} value={item.countryId}>
                                                {item.title}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className='text-field'>
                                    <TextField
                                        select
                                        id="companyHQState"
                                        name="companyHQState"
                                        label="State"
                                        variant='standard'
                                        fullWidth
                                        value={overviewValues?.values?.companyHQState}
                                        onChange={overviewValues.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            focused: true,
                                        }}
                                        InputProps={{
                                            readOnly: isNewCompany ? false : !isSaveEnabled,
                                        }}>
                                        {dropDownsData && dropDownsData?.stateProvince.map((item) => (
                                            <MenuItem key={item.stateProvinceId} value={item.stateProvinceId}>
                                                {item.title}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </div>
                            <div className='text-fields-row'>
                                <div className='text-field'>
                                    <TextField
                                        select
                                        id="companyHQCity"
                                        name="companyHQCity"
                                        label="City"
                                        variant='standard'
                                        fullWidth
                                        value={overviewValues?.values?.companyHQCity}
                                        onChange={overviewValues.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            focused: true,
                                        }}
                                        InputProps={{
                                            readOnly: isNewCompany ? false : !isSaveEnabled,
                                        }}>
                                        {dropDownsData && dropDownsData?.city.map((item) => (
                                            <MenuItem key={item.cityId} value={item.cityId}>
                                                {item.title}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className='text-field'>
                                    <TextField
                                        id="companyHQZipCode"
                                        name="companyHQZipCode"
                                        label="Zip Code"
                                        variant='standard'
                                        fullWidth
                                        value={overviewValues?.values?.companyHQZipCode}
                                        onChange={overviewValues.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            focused: true,
                                        }}
                                        InputProps={{
                                            readOnly: isNewCompany ? false : !isSaveEnabled,
                                        }} />
                                </div>
                                <div className='text-field'>
                                    <TextField
                                        id="companyHQAddress1"
                                        name="companyHQAddress1"
                                        label="Address"
                                        variant='standard'
                                        fullWidth
                                        value={overviewValues?.values?.companyHQAddress1}
                                        onChange={overviewValues.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            focused: true,
                                        }}
                                        InputProps={{
                                            readOnly: isNewCompany ? false : !isSaveEnabled,
                                        }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* //#region legal-counsel  */}
                    <div className='legal-counsel white-card'>
                        <h6>Legal Counsel</h6>
                        <div className=' margin-top-10'>
                            <div className='text-fields-row'>
                                <div className='text-field'>
                                    <TextField
                                        id="legalCounselFirmName"
                                        name="legalCounselFirmName"
                                        label="Legal Firm Name"
                                        variant='standard'
                                        fullWidth
                                        value={overviewValues?.values?.legalCounselFirmName}
                                        onChange={overviewValues.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            focused: true,
                                        }}
                                        InputProps={{
                                            readOnly: isNewCompany ? false : !isSaveEnabled,
                                        }} />
                                </div>
                                <div className='text-field'>
                                    <TextField
                                        id="legalCounselName"
                                        name="legalCounselName"
                                        label="Counsel Name"
                                        variant='standard'
                                        fullWidth
                                        value={overviewValues?.values?.legalCounselName}
                                        onChange={overviewValues.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            focused: true,
                                        }}
                                        InputProps={{
                                            readOnly: isNewCompany ? false : !isSaveEnabled,
                                        }} />
                                </div>
                                <div className='text-field'>
                                    <TextField
                                        id="legalCounselContactPhone"
                                        name="legalCounselContactPhone"
                                        label="Counsel Phone Number"
                                        variant='standard'
                                        fullWidth
                                        value={overviewValues?.values?.legalCounselContactPhone}
                                        onChange={overviewValues.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            focused: true,
                                        }}
                                        InputProps={{
                                            readOnly: isNewCompany ? false : !isSaveEnabled,
                                        }} />
                                </div>
                            </div>
                            <div className='text-fields-row'>
                                <div className='text-field'>
                                    <TextField
                                        id="legalCounselEmail"
                                        name="legalCounselEmail"
                                        label="Counsel Email Address"
                                        variant='standard'
                                        fullWidth
                                        value={overviewValues?.values?.legalCounselEmail}
                                        onChange={overviewValues.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            focused: true,
                                        }}
                                        InputProps={{
                                            readOnly: isNewCompany ? false : !isSaveEnabled,
                                        }} />
                                </div>
                                <div className='text-field'>
                                    <TextField
                                        select
                                        id="legalCounselCountry"
                                        name="legalCounselCountry"
                                        label="Country"
                                        variant='standard'
                                        fullWidth
                                        value={overviewValues?.values?.legalCounselCountry}
                                        onChange={overviewValues.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            focused: true,
                                        }}
                                        InputProps={{
                                            readOnly: isNewCompany ? false : !isSaveEnabled,
                                        }}>
                                        {dropDownsData && dropDownsData?.country.map((item) => (
                                            <MenuItem key={item.countryId} value={item.countryId}>
                                                {item.title}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className='text-field'>
                                    <TextField
                                        select
                                        id="legalCounselState"
                                        name="legalCounselState"
                                        label="State"
                                        variant='standard'
                                        fullWidth
                                        value={overviewValues?.values?.legalCounselState}
                                        onChange={overviewValues.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            focused: true,
                                        }}
                                        InputProps={{
                                            readOnly: isNewCompany ? false : !isSaveEnabled,
                                        }}>
                                        {dropDownsData && dropDownsData?.stateProvince.map((item) => (
                                            <MenuItem key={item.stateProvinceId} value={item.stateProvinceId}>
                                                {item.title}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </div>
                            <div className='text-fields-row'>
                                <div className='text-field'>
                                    <TextField
                                        id="legalCounselZipCode"
                                        name="legalCounselZipCode"
                                        label="Zip Code"
                                        variant='standard'
                                        fullWidth
                                        value={overviewValues?.values?.legalCounselZipCode}
                                        onChange={overviewValues.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            focused: true,
                                        }}
                                        InputProps={{
                                            readOnly: isNewCompany ? false : !isSaveEnabled,
                                        }} />
                                </div>
                                <div className='text-field'>
                                    <TextField
                                        id="legalCounselAddress1"
                                        name="legalCounselAddress1"
                                        label="Address"
                                        variant='standard'
                                        fullWidth
                                        value={overviewValues?.values?.legalCounselAddress1}
                                        onChange={overviewValues.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            focused: true,
                                        }}
                                        InputProps={{
                                            readOnly: isNewCompany ? false : !isSaveEnabled,
                                        }} />
                                </div>
                                <div className='text-field'>
                                    <TextField
                                        select
                                        id="legalCounselCity"
                                        name="legalCounselCity"
                                        label="City"
                                        variant='standard'
                                        fullWidth
                                        value={overviewValues?.values?.legalCounselCity}
                                        onChange={overviewValues.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            focused: true,
                                        }}
                                        InputProps={{
                                            readOnly: isNewCompany ? false : !isSaveEnabled,
                                        }}>
                                        {dropDownsData && dropDownsData?.city.map((item) => (
                                            <MenuItem key={item.cityId} value={item.cityId}>
                                                {item.title}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* //#region accounting-firm  */}
                    <div className='accounting-firm white-card'>
                        <h6>Accounting Firm</h6>
                        <div className=' margin-top-10'>
                            <div className='text-fields-row'>
                                <div className='text-field'>
                                    <TextField
                                        id="accountantFirmName"
                                        name="accountantFirmName"
                                        label="Accountant Firm Name"
                                        variant='standard'
                                        fullWidth
                                        value={overviewValues?.values?.accountantFirmName}
                                        onChange={overviewValues.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            focused: true,
                                        }}
                                        InputProps={{
                                            readOnly: isNewCompany ? false : !isSaveEnabled,
                                        }} />
                                </div>
                                <div className='text-field'>
                                    <TextField
                                        id="accountantName"
                                        name="accountantName"
                                        label="Accountant Name"
                                        variant='standard'
                                        fullWidth
                                        value={overviewValues?.values?.accountantName}
                                        onChange={overviewValues.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            focused: true,
                                        }}
                                        InputProps={{
                                            readOnly: isNewCompany ? false : !isSaveEnabled,
                                        }} />
                                </div>
                                <div className='text-field'>
                                    <TextField
                                        id="accountantContactPhone"
                                        name="accountantContactPhone"
                                        label="Accountant Phone Number"
                                        variant='standard'
                                        fullWidth
                                        value={overviewValues?.values?.accountantContactPhone}
                                        onChange={overviewValues.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            focused: true,
                                        }}
                                        InputProps={{
                                            readOnly: isNewCompany ? false : !isSaveEnabled,
                                        }} />
                                </div>
                            </div>
                            <div className='text-fields-row'>
                                <div className='text-field'>
                                    <TextField
                                        id="accountantEmail"
                                        name="accountantEmail"
                                        label="Accountant Email Address"
                                        variant='standard'
                                        fullWidth
                                        value={overviewValues?.values?.legalCounselEmail}
                                        onChange={overviewValues.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            focused: true,
                                        }}
                                        InputProps={{
                                            readOnly: isNewCompany ? false : !isSaveEnabled,
                                        }} />
                                </div>
                                <div className='text-field'>
                                    <TextField
                                        select
                                        id="accountantFirmCountry"
                                        name="accountantFirmCountry"
                                        label="Country"
                                        variant='standard'
                                        fullWidth
                                        value={overviewValues?.values?.accountantFirmCountry}
                                        onChange={overviewValues.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            focused: true,
                                        }}
                                        InputProps={{
                                            readOnly: isNewCompany ? false : !isSaveEnabled,
                                        }}>
                                        {dropDownsData && dropDownsData?.country.map((item) => (
                                            <MenuItem key={item.countryId} value={item.countryId}>
                                                {item.title}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className='text-field'>
                                    <TextField
                                        select
                                        id="accountantFirmState"
                                        name="accountantFirmState"
                                        label="State"
                                        variant='standard'
                                        fullWidth
                                        value={overviewValues?.values?.accountantFirmState}
                                        onChange={overviewValues.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            focused: true,
                                        }}
                                        InputProps={{
                                            readOnly: isNewCompany ? false : !isSaveEnabled,
                                        }}>
                                        {dropDownsData && dropDownsData?.stateProvince.map((item) => (
                                            <MenuItem key={item.stateProvinceId} value={item.stateProvinceId}>
                                                {item.title}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </div>
                            <div className='text-fields-row'>
                                <div className='text-field'>
                                    <TextField
                                        id="accountantFirmZipCode"
                                        name="accountantFirmZipCode"
                                        label="Zip Code"
                                        variant='standard'
                                        fullWidth
                                        value={overviewValues?.values?.accountantFirmZipCode}
                                        onChange={overviewValues.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            focused: true,
                                        }}
                                        InputProps={{
                                            readOnly: isNewCompany ? false : !isSaveEnabled,
                                        }} />
                                </div>
                                <div className='text-field'>
                                    <TextField
                                        id="accountantFirmAddress1"
                                        name="accountantFirmAddress1"
                                        label="Address"
                                        variant='standard'
                                        fullWidth
                                        value={overviewValues?.values?.legalCounselAddress1}
                                        onChange={overviewValues.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            focused: true,
                                        }}
                                        InputProps={{
                                            readOnly: isNewCompany ? false : !isSaveEnabled,
                                        }} />
                                </div>
                                <div className='text-field'>
                                    <TextField
                                        select
                                        id="accountantFirmCity"
                                        name="accountantFirmCity"
                                        label="City"
                                        variant='standard'
                                        fullWidth
                                        value={overviewValues?.values?.accountantFirmCity}
                                        onChange={overviewValues.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            focused: true,
                                        }}
                                        InputProps={{
                                            readOnly: isNewCompany ? false : !isSaveEnabled,
                                        }}>
                                        {dropDownsData && dropDownsData?.city.map((item) => (
                                            <MenuItem key={item.cityId} value={item.cityId}>
                                                {item.title}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* //#region leadership-team  */}
                    <div className='leadership-team white-card'>
                        <h6>LeadershipTeam</h6>
                        <div className=' margin-top-10'>
                            <div className='text-fields-row'>
                                <div className='text-field'>
                                    <TextField
                                        id="ceoName"
                                        name="ceoName"
                                        label="CEO Name"
                                        variant='standard'
                                        fullWidth
                                        value={overviewValues?.values?.ceoName}
                                        onChange={overviewValues.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            focused: true,
                                        }}
                                        InputProps={{
                                            readOnly: isNewCompany ? false : !isSaveEnabled,
                                        }} />
                                </div>
                                <div className='text-field'>
                                    <TextField
                                        id="ceoEmail"
                                        name="ceoEmail"
                                        label="CEO Email Address"
                                        variant='standard'
                                        fullWidth
                                        value={overviewValues?.values?.ceoEmail}
                                        onChange={overviewValues.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            focused: true,
                                        }}
                                        InputProps={{
                                            readOnly: isNewCompany ? false : !isSaveEnabled,
                                        }} />
                                </div>
                                <div className='text-field'>
                                    <TextField
                                        id="ceoPhone"
                                        name="ceoPhone"
                                        label="CEO Phone Number"
                                        variant='standard'
                                        fullWidth
                                        value={overviewValues?.values?.ceoPhone}
                                        onChange={overviewValues.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            focused: true,
                                        }}
                                        InputProps={{
                                            readOnly: isNewCompany ? false : !isSaveEnabled,
                                        }} />
                                </div>
                            </div>
                            <div className='text-fields-row'>
                                <div className='text-field'>
                                    <TextField
                                        id="cfoName"
                                        name="cfoName"
                                        label="CFO Name"
                                        variant='standard'
                                        fullWidth
                                        value={overviewValues?.values?.cfoName}
                                        onChange={overviewValues.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            focused: true,
                                        }}
                                        InputProps={{
                                            readOnly: isNewCompany ? false : !isSaveEnabled,
                                        }} />
                                </div>
                                <div className='text-field'>
                                    <TextField
                                        id="cfoEmail"
                                        name="cfoEmail"
                                        label="CFO Email Address"
                                        variant='standard'
                                        fullWidth
                                        value={overviewValues?.values?.cfoEmail}
                                        onChange={overviewValues.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            focused: true,
                                        }}
                                        InputProps={{
                                            readOnly: isNewCompany ? false : !isSaveEnabled,
                                        }} />
                                </div>
                                <div className='text-field'>
                                    <TextField
                                        id="cfoPhone"
                                        name="cfoPhone"
                                        label="CFO Phone Number"
                                        variant='standard'
                                        fullWidth
                                        value={overviewValues?.values?.cfoPhone}
                                        onChange={overviewValues.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            focused: true,
                                        }}
                                        InputProps={{
                                            readOnly: isNewCompany ? false : !isSaveEnabled,
                                        }} />
                                </div>
                            </div>
                            <div className='text-fields-row'>
                                <div className='text-field'>
                                    <TextField
                                        id="otherContactPhone"
                                        name="otherContactPhone"
                                        label="Other Contact"
                                        variant='standard'
                                        fullWidth
                                        value={overviewValues?.values?.otherContactPhone}
                                        onChange={overviewValues.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            focused: true,
                                        }}
                                        InputProps={{
                                            readOnly: isNewCompany ? false : !isSaveEnabled,
                                        }}
                                    />
                                </div>
                                <div className='text-field'>
                                    <TextField
                                        id="otherContactEmail"
                                        name="otherContactEmail"
                                        label="Other Contact Email Address"
                                        variant='standard'
                                        fullWidth
                                        value={overviewValues?.values?.otherContactEmail}
                                        onChange={overviewValues.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            focused: true,
                                        }}
                                        InputProps={{
                                            readOnly: isNewCompany ? false : !isSaveEnabled,
                                        }}
                                    />
                                </div>
                                <div className='text-field'></div>
                            </div>
                        </div>
                    </div>
                    {/* //#region portfolio-manager  */}
                    {
                        companyDetails?.portofolioCompanyManagerID && companyDetails?.portofolioCompanyManagerID !== 0 ?
                            <div className='portfolio-manager white-card'>
                                <h6>Portfolio Company Manager</h6>
                                <div className=' margin-top-10'>
                                    <div className='text-fields-row'>
                                        <div className='text-field'>
                                            <TextField
                                                id="firstName"
                                                name="pcManager.firstName"
                                                label="First Name"
                                                variant='standard'
                                                fullWidth
                                                value={overviewValues?.values?.pcManager?.firstName}
                                                onChange={overviewValues.handleChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    focused: true,
                                                }}
                                                InputProps={{
                                                    readOnly: true,
                                                }} />
                                        </div>
                                        <div className='text-field'>
                                            <TextField
                                                id="lastName"
                                                name="pcManager.lastName"
                                                label="Last Name"
                                                variant='standard'
                                                fullWidth
                                                value={overviewValues?.values?.pcManager?.lastName}
                                                onChange={overviewValues.handleChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    focused: true,
                                                }}
                                                InputProps={{
                                                    readOnly: true,
                                                }} />
                                        </div>
                                        <div className='text-field'>
                                            <TextField
                                                id="email"
                                                name="pcManager.emailAddress"
                                                label="EmailAddress"
                                                variant='standard'
                                                fullWidth
                                                value={overviewValues?.values?.pcManager?.emailAddress}
                                                onChange={overviewValues.handleChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    focused: true,
                                                }}
                                                InputProps={{
                                                    readOnly: true,
                                                }} />
                                        </div>
                                    </div>
                                    <div className='text-fields-row'>
                                        <div className='text-field'>
                                            <TextField
                                                id="phonenumber"
                                                name="pcManager.phoneNo"
                                                label="Phone Number"
                                                variant='standard'
                                                fullWidth
                                                value={overviewValues?.values?.pcManager?.phoneNo}
                                                onChange={overviewValues.handleChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    focused: true,
                                                }}
                                                InputProps={{
                                                    readOnly: true,
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ''
                    }
                    {/* //#region board-represntation  */}
                    <div className='board-represntation child-margin-15 white-card'>
                        <h6>Board Representation</h6>
                        <div>
                            <div>
                                {
                                    boardMembers.length > 0 ?
                                        <>
                                            {
                                                boardMembers.map((member, index) => {
                                                    return (
                                                        <div className='display-row width-percent-50 margin-top-10'>
                                                            <div className='text-field' key={index}>
                                                                <TextField
                                                                    id={member.data}
                                                                    name={member.id}
                                                                    value={member.data}
                                                                    label="Name"
                                                                    variant='standard'
                                                                    fullWidth
                                                                    onChange={handleChangeMembers}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                        focused: true,
                                                                    }}
                                                                    InputProps={{
                                                                        readOnly: isNewCompany ? false : !isSaveEnabled,
                                                                    }} />
                                                            </div>
                                                            <div>
                                                                {isSaveEnabled || isNewCompany ?
                                                                    <div className='margin-top-10'>
                                                                        <Tooltip className='deleteicon' onClick={() => handleDelete(member)} title="Delete" arrow>
                                                                            <IconButton>
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </div> : null

                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </> : null
                                }
                            </div>
                        </div>
                        <div>
                            <ActionButton
                                label="ADD MORE"
                                variant="outlined"
                                className="btn-primary"
                                icon={<AddIcon />}
                                disabled={isNewCompany ? false : !isSaveEnabled}
                                onClick={handleAddMore}>
                            </ActionButton>
                        </div>
                    </div>
                </div>
            </form>
            {
                openPopup &&
                <AddCompanyManager open={openPopup} onClose={onPopupClose} companyDetails={companyDetails} getCompaniesDetails={getCompaniesDetails} />
            }
            <ToastContainer />
        </div>
    )
}

export default PCCompanyOverview
