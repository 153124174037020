import React, { useState, useEffect } from 'react';
import styles from "../MarketplaceLookFeel2.module.css";
import { useNavigate } from "react-router-dom";
import { RolesAndPermissions, FeatureAccess, AppRoles, AppDashboards } from '../../../utils/enum';
import { checkFeaturePermissions } from '../../../utils/common';
import secureLocalStorage from "react-secure-storage";
import CircleIcon from '../../chatbot/Icon';
import { useSelector } from 'react-redux';
import { getrootBackgroundColorConfig } from '../../../state/slices/colorConfigSlice';

const FundView = ({ isExpanded, filters, searchData }) => {
  const [visibleItems, setVisibleItems] = useState(6);
  const [data, setData] = useState(null);
  const [items, setItems] = useState();
  const [fundDetails, setfundDetails] = useState();
  const [AllInvestors, setAllInvestors] = useState([]);
  

  const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);
 
  const navigate = useNavigate();


  const loadMore = () => {
    setVisibleItems(visibleItems + 6);
  };

  const handleViewDetail = (item,itemIdObj, fundname,fundStatusName, disableSubscriptionButton,event ) => {
    if (event) {
      event.stopPropagation();
    }

   

    let parsedFundId;
    try {
      parsedFundId = JSON.parse(itemIdObj);
    } catch (error) {
      console.error("Error parsing fundDetail:", error);
    }
    console.clear()

    console.log(parsedFundId)

    secureLocalStorage.setItem("FundId", parsedFundId);

    secureLocalStorage.setItem("FundName", fundname);

    const havepermissiontoread =  checkFeaturePermissions(RolesAndPermissions.FUND, FeatureAccess.READ);

    if (secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD) {
      /* navigate('/fund-details', { parsedFundId }, { fundStatusName }); */
      navigate('/fund-details', {
        state: {
          FundID: parsedFundId,
          InvestorID: secureLocalStorage.getItem("userId"),
          SubscriptionID: 0,
          navigatefrommarketplace: true,
          disableSubscriptionButton:disableSubscriptionButton
        }
      });
      
    } else if (havepermissiontoread) {
      console.log(item)
       
      
      const SelectedFund = {
        "FundMaturity": item.isFundMatured,
        "FundTermination": item.fundTerminated,
        BGImage: item.bgImage,
        IconImage: item.iconImage,
      }
      secureLocalStorage.setItem("navigatingFromMarketPlace", true);
      secureLocalStorage.setItem("selectedFund", SelectedFund);
      navigate('/marketplace/OverviewPage', { parsedFundId });
    }
    
    secureLocalStorage.setItem("FundId", JSON.stringify(parsedFundId));
    secureLocalStorage.setItem('fundStatusName',fundStatusName);
    window.scrollTo(0, 0);
  };
 
  const handlesubscribe = (item,itemId, fundname, event) => {
    if (event) {
      event.stopPropagation();
    }
    secureLocalStorage.setItem("FundName", fundname);
    secureLocalStorage.setItem("FundId", itemId);
    secureLocalStorage.setItem("FundSPIcon", item.iconImage);
    secureLocalStorage.setItem("fundDescription", item.fundDescription);

   /*  console.log(item); */
    /*   */
    navigate('/investmentProfile', { state: { FundId: { itemId } } });
    window.scrollTo(0, 0);
  };

  // this use case is used to fetch the fund data for respective users
  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        'TenantID': secureLocalStorage.getItem('tenantID')
      },
    };
    const queryParams = {
      userId: secureLocalStorage.getItem("userId"),
      fundFilter: JSON.stringify(filters),
      visibility: 'show',
    }
    const queryString = new URLSearchParams(queryParams).toString();
    console.log(JSON.stringify(filters));
    
    const fetchDataforAllFunds = async () => {
     
    };

    fetchDataforAllFunds();

  }, []);

  // This useeffect is to filter the fund based on filter options
  useEffect(() => {
    let data = fundDetails;
    if (data?.length > 1) {
      let sortVariable = "";
      if (filters?.sorting === "Oldest to newest" ||
        filters?.sorting === "Newest to Oldest") {
        sortVariable = "fundID";
      }
      if (filters?.sorting === "Min. Investment low to high" ||
        filters?.sorting === "Min. Investment high to low") {
        sortVariable = "minimumInvestmentAmount";
      }
      if (filters?.sorting === "Term low to high" ||
        filters?.sorting === "Term high to low") {
        sortVariable = "fundSize";
      }
      //sorting by descending
      if (filters?.sorting === "Oldest to newest" ||
        filters?.sorting === "Min. Investment low to high" ||
        filters?.sorting === "Term low to high") {
        // data = data.sort((a, b) => a[sortVariable] > b[sortVariable] ? 1 : -1); // for string
        data = [...data]?.sort((a, b) => a[sortVariable] - b[sortVariable]);  // for number
      }

      //sorting by ascending
      if (filters?.sorting === "Newest to Oldest" ||
        filters?.sorting === "Min. Investment high to low" ||
        filters?.sorting === "Term high to low") {
        // data = data.sort((a, b) => a[sortVariable] > b[sortVariable] ? -1 : 1);  // for string
        data = [...data]?.sort((a, b) => b[sortVariable] - a[sortVariable]);  // for number
      }
    }
    const filteredData = searchData?.length >= 5 ? data?.filter(obj => obj.title?.toLocaleLowerCase()?.includes(searchData?.toLocaleLowerCase())
      || obj.liquidity?.toLocaleLowerCase()?.includes(searchData?.toLocaleLowerCase())
      || obj.fundType?.toLocaleLowerCase()?.includes(searchData?.toLocaleLowerCase())) : data;

    const filteredItem = filteredData?.filter((item) => {
      return (filters?.fundTypes?.length > 0 ||
        filters?.industries?.length > 0 ||
        filters?.fundManagers?.length > 0)
        ?
        (
          filters?.fundTypes?.some(type => type?.toLowerCase() === item?.fundTypeName?.toLowerCase()) ||
          filters?.industries?.some(industry => industry?.toLowerCase() === item?.targetIndustry?.toLowerCase()) ||
          filters?.fundManagers?.some(manager => manager?.toLowerCase() === item?.fundCompanyName?.toLowerCase())
        )
        :
        item;
    });
    setItems(filteredItem);
    console.log(filteredItem);
  }, [filters, searchData])

  const investorid = secureLocalStorage.getItem("userId");

  const handleClickcompare = (itemId) => {
    setItems((prevItems) => {
      const updatedItems = prevItems?.map((item) => {
        if (item?.fundID === itemId) {
          return { ...item, compare: !item?.compare }; // Toggle the compare state
        }
        return item;
      });

      const compareItems = updatedItems
        .filter((item) => item?.compare);

      const selectedItems = updatedItems
        .filter((item) => item?.compare)
        .map((item) => item?.fundID);

      localStorage.setItem('selectedItems', JSON.stringify(selectedItems));
      localStorage.setItem('CompareItems', JSON.stringify(compareItems));

      return updatedItems;
    });
  };
  
  const handlefavorite = (itemId) => {
   
  };

  return (
    <div>
      <div className={styles.marketplaceLookFeel}>
        <div  style={{backgroundColor: rootBackColorConfig.color}} className={
          isExpanded
            ? `${styles.container} ${styles.containerExpanded}`
            : styles.container
        }>
          {items?.filter(item => !item.fundTerminated)?.slice(0, visibleItems)?.map((item, index) => (
            <div className={styles.cardcontainer} key={index}>
              <div className={item.isFundMatured ? styles.mediumCardBgParentgray : styles.mediumCardBgParent1}>
                <div className={styles.mediumCardBg}>
                  <div className={styles.bg} />
                  <div className={styles.component21294}>
                    <div className={styles.mediumCardBg}>
                      <div className={styles.buttonNormalItem} />
                      {
                        checkFeaturePermissions(RolesAndPermissions.FUND_SUBSCRIPTION,FeatureAccess.READ  ) 
                        && 
                        <button className={styles.subscribe4}
                        
                        // onClick={() => {
                        //   if (!item.isFundMatured) {
                        //     handlesubscribe(item,item.fundID, item.fundName);
                        //   }
                        // }}

                        onClick={() => {handlesubscribe(item,item.fundID, item.fundName)}}

                        disabled={
                          secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD ?
                           (item?.isFundMatured  
                            || secureLocalStorage.getItem("InvestorFundIDs")?.includes(item?.fundID)
                            || item.fundStatusName === 'Closed to New Investors' || item.fundStatusName === 'Launching Soon' || item.fundStatusName === 'Closed' || item.fundStatusName === 'Terminated'
                            || AllInvestors.some(u=>u.fundID === item?.fundID)
                            ) :
                           true
                          //  (item?.isFundMatured )
                       } 
                     
                      >
                        {AllInvestors.some(u=>u.fundID === item?.fundID) ? "Subscribed" : "Subscribe"}
                        
                      </button>
                      
                    }
                    </div>
                  </div>
                  <div className={styles.privateEquityParent2}>
                    <div className={styles.privateEquity4}>{item.fundTypeName}</div>
                    <b className={styles.consumerGoodsRetailEnhancem4}>
                      {item.fundName}
                    </b>
                    <div className={styles.loremIpsumDolor4}>
                      {item.fundDescription}
                    </div>
                    <div className={styles.targetReturn}>TARGET RETURN</div>
                    <b className={styles.b}>{item.targettedIRR}%</b>
                    <div className={styles.minimum4}>MINIMUM</div>
                    <b className={styles.b9}>${item.minimumInvestmentAmount?item.minimumInvestmentAmount.toLocaleString():''}</b>
                    <div className={styles.liquidity4}>LIQUIDITY</div>
                    <b className={styles.quarterly4}>{item.liquidityProvision}</b>
                    <img
                      className={styles.favoriteButtonIcon}
                      alt=""
                      src={item?.favourite === true ? '/MarketPlaceImages/favouriteicon1.svg' : '/favorite-button.svg'}
                      onClick={() => handlefavorite(item.fundID)}
                    />
                    <img
                      className={styles.addToCompare}
                      alt="" title='Compare'
                      src={item?.compare ? '/add-to-compare1.svg' : '/add-to-compare.svg'}
                      onClick={() => handleClickcompare(item?.fundID)}
                    />

                  </div>
                  <div className={styles.component212131}>
                    <div className={styles.mediumCardBg}>
                      <div className={styles.buttonNormalItem} />
                  {checkFeaturePermissions(RolesAndPermissions.FUND,FeatureAccess.READ)
                  &&
                      <button className={styles.viewDetail}

                        onClick={() => handleViewDetail(item,item.fundID, item.fundName , item.fundStatusName, AllInvestors.some(u=>u.fundID === item?.fundID))}
                      >
                        View Detail
                      </button>
}
                    </div>
                  </div>

                </div>
                <img
                  className={styles.maskGroup36}
                  alt=""
                  src={item?.bgImage === null || !item?.bgImage?.includes('https') ? '/DefaultBackgroundImage.png' : item?.bgImage}
                />
                <div className={styles.image4} />
                <div className={styles.label4}>
                  <b className={styles.labelcontent4}>{item.fundStatusName}</b>
                </div>
                <span className='prod-on-logo'>
                <img alt=""
                  src={item?.iconImage === null || !item?.iconImage?.includes('https') ? "/DefaultLogo.png" : item?.iconImage}
                />
                </span>
              </div>
            </div>
          ))}
          {visibleItems < data?.length && items.length > 5 && (
          <div style={{width:'100%'}}>  <button id="loadbtn" className={styles.loadMore} onClick={loadMore}>Load More...</button> </div>
          )}

        </div>
       
        <CircleIcon/>
      </div>
    </div>
  );
};

export default FundView;