import axios from 'axios';
import resolve from '../../common/resolve.js';
import axiosApiCall from '../../Services/axiosApiCall.js';
import { getAPIResponse } from '../../../utils/common.js';
import secureLocalStorage from 'react-secure-storage';


export async function getUserProfileData(emailAddress) {
    return await axiosApiCall.get(`/v1/UserProfile/GetUserProfile?emailAddress=${emailAddress}`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function getUser(emailAddress, password, userRole) {
    return await axiosApiCall.get(`/v1/User/GetUser?emailAddress=${emailAddress}&password=${password}&userRole=${userRole}&origin=${window.location.origin}`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}


export async function getUserByEmail(emailAddress, userRole) {
    return await axiosApiCall.get(`/v1/User/GetUserByOnlyEmail?emailAddress=${emailAddress}&origin=${window.location.origin}`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function getRolesAndPermissionsByEmail(emailAddress) {
    return await axiosApiCall.get(`/v1/UserRole/GetUserRolesAndPermissions?emailAddress=${emailAddress}`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function getOnBoardingDetails(emailAddress) {
    return await axiosApiCall.get(`/v1/UserProfile/GetUserProfile?emailAddress=${emailAddress}`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function getPreviousOnboardingDetails(emailAddress) {
    return await axiosApiCall.get(`/v1/User/GetUserDetails?emailAddress=${emailAddress}`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function getTenantDetails(emailAddress) {
    return await axiosApiCall.get(`/v1/Tenant/GetAllSystemTenantDetails`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function updateExcistingOBQAnswer(data) {
    return await axiosApiCall.put(`/v1/UserProfile/UpdateUserProfile`, data)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function CreateOBQAnswer(data) {
    return await axiosApiCall.post(`/v1/UserProfile/Create`, data)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

//Check for excisting user
export async function updateUserName(requestedBody) {
    return await axiosApiCall.put(`/v1/User/UpdateUserNameForTenant`,requestedBody)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function updateUserNamewithInvestorTypes(requestBody) {
    return await axiosApiCall.put(`/v1/User/UpdateUserNameForTenant`,requestBody)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function checkUserNameExcists(username) {
    return await axiosApiCall.get(`/v1/User/CheckUserNameExistsToTenant?userName=${username}`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function checkUserNameExcistsThroughHorizon(username) {
    return await axiosApiCall.get(`/v1/User/CheckUserNameExistsThroughOrigin?userName=${username}&origin=${window.location.origin}`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function setUser(data) {
    return await axiosApiCall.post(`/v1/User/SetUser?origin=${window.location.origin}`, data)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function updateChangePassword(password) {
    return await axiosApiCall.patch(`/v1/User/UpdateUserProfilePassword?userId=${secureLocalStorage.getItem("tenantUserId")}&password=${password}`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function updateInvestorRegistartionStatus(status) {
    return await axiosApiCall.put(`/v1/User/UpdateUserRegistrationStatus?emailAddress=${secureLocalStorage.getItem("userEmail")}&isRegistrationStatus=${status}`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function updateUserRegistrationStatus(emailId) {
    return await axiosApiCall.put(`/v1/User/UpdateUserRegistrationStatus?emailAddress=${emailId}&isRegistrationStatus=true`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function getTenantCustomCSS(emailId) {
    return await axiosApiCall.get(`/v1/UserRole/GetTenantCustomCSS?tenantGUID=${secureLocalStorage.getItem("tenantID")}`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function signUpAndSignInWithGoogle(data) {
    return await axiosApiCall.post(`/v1/User/SignUpAndSignInWithGoogle?origin=${window.location.origin}`, data)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}