import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { DataGrid , GridColumnMenu} from '@mui/x-data-grid';
import { Box } from "@mui/material";
import { ArrowDropDown , ArrowDropUp} from "@mui/icons-material"
import './Stack.css'; // Path to your custom CSS file

const CustomColumnMenu = (props) => {
  return (
  
    <GridColumnMenu 
    sx={{   marginTop: '30px' }}
      {...props}
      slots={{
        columnMenuColumnsItem: null,
        columnMenuSortItem: null,
      }}
    />
  );
};

const StackView = ( { columns, rows, handleRowClick, onRowsSelectionHandler,norowsMessage} )  => {
  let sortedRows = [...rows]?.sort((a, b) => b.id - a.id)
  const muiTheme = createTheme({
    // typography: {
    //     fontFamily: 'Cresta, Normal',
    //     fontSize: 14,
    // },
    palette: {
        background: {
            default: '#f5f5f5', // Default background color for most components
            paper: '#ffffff', // Background color for paper-like components (dialogs, cards, etc.)
        },
    },
    
});


  return (
    <div className='stackViewWrapper mt2'>
         <Box sx={{ height: '420px', width: '100%', }}>
         <style>
        {`
          /* Hide the scrollbar */
          ::-webkit-scrollbar {
            width: 0.5em;
          }
          
          ::-webkit-scrollbar-thumb {
            background-color: transparent; /* Change this to the background color you want */
          }
        `}
      </style>
            <ThemeProvider theme={muiTheme}>
                <DataGrid
                    rows={sortedRows}
                    columns={columns}
                    rowHeight={39}
                    hideFooterPagination={true}
                    disableColumnMenu={false}
                    hideFooter={true}
                    onRowClick={handleRowClick}
                    classes={{
                      row: 'custom-row', 
                      cell: 'custom-cell',
                    }}
                    slots={{
                      columnSortedDescendingIcon: ArrowDropDown,
                      columnSortedAscendingIcon: ArrowDropUp,
                      columnMenu: CustomColumnMenu,
                    }}
                    localeText={{ noRowsLabel: norowsMessage || "no rows" }}
                />
            </ThemeProvider>
        </Box>
    </div>
  )
}

export default StackView