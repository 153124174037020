import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import propTypes from 'prop-types';
import './styles.css';

const Modal =(props)=> {

    const { open, maxWidth, scroll} = props;

    return (
    <div>
      <Dialog
        open={open}
        fullWidth
        maxWidth={maxWidth}
        scroll={scroll}
      >
        { props.children }
      </Dialog>
    </div>
  );
}

Modal.propTypes={
    open: propTypes.bool,
    maxWidth: propTypes.oneOf(['xs','sm', 'md','lg','xl']),
    scroll: propTypes.oneOf(['paper', 'body']),
}

Modal.defaultProps={
    open: false,
    maxWidth: 'md',
    scroll: 'paper',  
}

export default Modal;