import { useState, useEffect, memo, useRef } from "react";
import FileUploadButton from "../../../common/FileUploadButton/FileUploadButton";
import Text from "../../../common/Text/Text";
import '../CreateFundModal/styles.css';
import json from '../../filterbox.json'
import secureLocalStorage from "react-secure-storage";
import { getFundFilterData } from "../../../marketplace/services/MarketplaceService";
import { convertStringToArray } from "../../../common/Functions/ConvertStringtoArray";
import Button from '@mui/material/Button';
import ActionButton from "../../../common/ActionButton/ActionButton";
import AppTextInput from "../../../common/TextInput/AppTextField";
import { validationsTypeEnum, InputTypes, validationMessages, textFormatTypeEnum } from "../../../common/TextInput/appInputenum";
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import SwitchButtonField from "../../../common/input-fields/SwitchButtonField";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { TextField } from "@mui/material";
import AutoSelect from "../../../common/TextInput/AutoSelect";
import { FundTypes } from "../CreateFundModal/InitialFundData";

const FundManagerDetails = (props) => {
    //#region intialdata
    const InitialData = {
        fundStaffeg: convertStringToArray(props.data.fundStaff),
        ...props.data
    }

    //#region variables
    const [FundFilterData, setFundFilterData] = useState();
    const [fundDetails, setFundDetails] = useState(InitialData);
    const [isChangeshappen, setIsChangeshappen] = useState(false);
    const componentRef = useRef(null);
    const [iconClearFile, setIconClearFile] = useState(false);
    const [bgClearFile, setBgClearFile] = useState(false);
    const [selectedManagers, setSelectedManagers] = useState({});
    const [error, setErrors] = useState({})



    const iFundManagers = fundDetails?.fundStaffeg.map((item, index) => {
        const matchingStaffDataItem = props?.fundStaffData?.find(dataItem => dataItem.value === item);
        return {
            id: index + 1,
            userID: item,
            description: matchingStaffDataItem?.briefDescription || ''
        };
    });
    const [fundManagers, setFundManagers] = useState([...iFundManagers]);
    

    const [isMultifieldsValid, setMultifieldsValid] = useState(true);
    const isFundMatured = secureLocalStorage.getItem("selectedFund")?.FundMaturity;
    const isFundTerminated = secureLocalStorage.getItem("selectedFund")?.FundTermination;
    const [noofValidFields, setNoofValidFields] = useState({ validfields: 0 });
    const [checkIfValid, setcheckIfValid] = useState(false);

    //#region functions
    const generateUniqueID = (existingIDs) => {
        const maxID = Math.max(...existingIDs, 0); // Find the maximum existing ID
        const newID = maxID + 1;
        return newID;
    };

    //#region change events
    const handleClearFileICON = () => {
        setIconClearFile(true);
        handleChange("iconImage", "");
    };

    const handleClearFileBG = () => {
        setBgClearFile(true);
        handleChange("bgImage", "");
    };

    const handleFileUpload = (name, file) => {
        if (file) {

            setBgClearFile(false);
            const formData = new FormData();
            formData.append(name, file, file?.name);
            /*  formData.append("iconImage", file, fundDetails.iconImage); */
            handleChange(name, file);
            /*  handleChange("iconImage", file);  */
        }
    }

    const handleFileUploadicon = (name, file) => {
        if (file) {
            setIconClearFile(false);
            const formData = new FormData();
            formData.append(name, file, file.name);
            handleChange(name, file);
        }
    }

    const handleChangeFM = (field, value) => {
        const findManager = props?.fundStaffData?.find((item) => item.value === value);
        const matchid = field.match(/\d+/);
        const findIndex = matchid ? fundManagers.findIndex((item) => item.id == matchid) : -1;
        console.log(fundManagers)

        const currState = [...fundManagers];
        if (findIndex !== -1 && findIndex < currState.length) {
            currState[findIndex] = {
                ...currState[findIndex],
                userID: value,
                description: findManager?.briefDescription || '',
            };
        } else if (currState.length === 1) {
            currState[0].userID = value;
            currState[0].description = findManager.briefDescription;
        } else {
            console.error("Invalid index or findIndex not found");
        }


        //Updating the disable state in dropdow so there are no duplicates
        console.log(currState)
        const extractids = currState.map((item) => item.userID);
        console.log(props?.fundStaffData)
        props.setFundStaffDropdown((prevItems) =>
            prevItems.map((item) =>
                extractids.includes(item.value) || item.value === fundDetails?.fundManagerID
                    ? { ...item, disabled: true }
                    : { ...item, disabled: false }
            )
        );

        //set the fundStaff Value
        const idarray = currState.map((item) => item.userID)

        handleChange('fundStaffeg', idarray)
        // Set the state with the modified copy
        setFundManagers(currState);
    }

    const handleChange = (field, value) => {
        // debugger
        const currState = { ...fundDetails };
        if (field !== 'investmentStrategyId' && field !== 'fundTargetIndustryId' && field !== 'geographicFocusId' && field !== "liquidity") {
            currState[field] = value;
        }
        if (field === 'fundSize' || field === 'minimumInvestmentAmount') {
            const fundSize = parseInt(currState['fundSize']);
            const minimumInvestmentAmount = parseInt(currState['minimumInvestmentAmount']);
            if (fundSize < minimumInvestmentAmount) {
                setMultifieldsValid(false);
            }
            else {
                setMultifieldsValid(true);
            }
        }
        if (field === 'investmentStrategyId') {
            // debugger
            if (!FundFilterData.strategy.some(option => option.strategyName === value) && !FundFilterData.strategy.some(option => option.investmentStrategyID === value)) {
                if (FundFilterData.strategy.some(option => option.investmentStrategyID === -1)) {
                    FundFilterData.strategy = FundFilterData.strategy.filter(option => option.investmentStrategyID !== -1);
                    FundFilterData.strategy.push({
                        investmentStrategyID: -1, strategyName: value, strategyDescription: null
                    });
                    currState[field] = -1;
                    currState["StrategyName"] = value
                    setFundDetails(currState);
                } else {
                    FundFilterData.strategy.push({
                        investmentStrategyID: -1, strategyName: value, strategyDescription: null
                    });
                    currState[field] = -1;
                    currState["StrategyName"] = value
                    setFundDetails(currState);
                }

                ///const currState = { ...fundDetails };
            } else {
                currState[field] = value;
                currState["StrategyName"] = FundFilterData.strategy.find(option => option.investmentStrategyID === value).strategyName
                setFundDetails(currState);
            }
            if (value !== '') {
                setErrors({ ...error, investmentStrategyId: false })
            } else {
                setErrors({ ...error, investmentStrategyId: true })
            }
        }
        if (field === 'fundTargetIndustryId') {

            if (FundFilterData.industry.some(option => option.targetIndustry === value) && !FundFilterData.industry.some(option => option.fundTargetIndustryID === value)) {
                if (FundFilterData.industry.some(option => option.fundTargetIndustryID === -1)) {
                    FundFilterData.industry = FundFilterData.industry.filter(option => option.fundTargetIndustryID !== -1);
                    FundFilterData.industry.push({
                        fundTargetIndustryID: -1, targetIndustry: value, strategyDescription: null
                    });
                    currState[field] = -1;
                    currState["TargetIndustry"] = value
                    setFundDetails(currState);
                } else {
                    FundFilterData.industry.push({
                        fundTargetIndustryID: -1, targetIndustry: value, strategyDescription: null
                    });
                    currState[field] = -1;
                    currState["TargetIndustry"] = value
                    setFundDetails(currState);
                }
            } else {
                currState[field] = value;
                currState["TargetIndustry"] = FundFilterData.industry.find(option => option.fundTargetIndustryID === value).targetIndustry
                setFundDetails(currState);
            }

            if (value !== '') {
                setErrors({ ...error, fundTargetIndustryId: false })
            } else {
                setErrors({ ...error, fundTargetIndustryId: true })
            }
        }
        if (field === 'geographicFocusId') {
            if (!FundFilterData.geographicFocus.some(option => option.geographicFocusName === value) && !FundFilterData.geographicFocus.some(option => option.geographicFocusId === value)) {
                if (FundFilterData.geographicFocus.some(option => option.geographicFocusId === -1)) {
                    FundFilterData.geographicFocus = FundFilterData.geographicFocus.filter(option => option.geographicFocusId !== -1);
                    FundFilterData.geographicFocus.push({
                        geographicFocusId: -1, geographicFocusName: value, strategyDescription: null
                    });
                    currState[field] = -1;
                    currState["GeographicFocusName"] = value
                    setFundDetails(currState);

                } else {
                    FundFilterData.geographicFocus.push({
                        geographicFocusId: -1, geographicFocusName: value, strategyDescription: null
                    });
                    currState[field] = -1;
                    currState["GeographicFocusName"] = value
                    setFundDetails(currState);
                }
            } else {
                currState[field] = value;
                currState["GeographicFocusName"] = FundFilterData.geographicFocus.find(option => option.geographicFocusId === value).geographicFocusName
                setFundDetails(currState);
            }

            if (value !== '') {
                setErrors({ ...error, geographicFocusId: false })
            } else {
                setErrors({ ...error, geographicFocusId: true })
            }
        }
        if (field === 'InvestmentCycle') {
            if (!FundFilterData.liquidityProvision.some(option => option.liquidityProvisionName === value) && !FundFilterData.liquidityProvision.some(option => option.liquidityProvisionId === value)) {
                if (FundFilterData.liquidityProvision.some(option => option.liquidityProvisionId === -1)) {
                    FundFilterData.liquidityProvision = FundFilterData.liquidityProvision.filter(option => option.liquidityProvisionId !== -1);
                    FundFilterData.liquidityProvision.push({
                        liquidityProvisionId: -1, liquidityProvisionName: value, strategyDescription: null
                    });
                    currState[field] = -1;
                    currState['LiquidityProvision'] = value
                    setFundDetails(currState);
                } else {
                    FundFilterData.liquidityProvision.push({
                        liquidityProvisionId: -1, liquidityProvisionName: value, strategyDescription: null
                    });
                    currState[field] = -1;
                    currState['LiquidityProvision'] = value
                    setFundDetails(currState);
                }
            } else {
                currState[field] = value;
                currState["LiquidityProvision"] = FundFilterData.liquidityProvision.find(option => option.liquidityProvisionId === value).liquidityProvisionName
                setFundDetails(currState);
            }

            if (value !== '') {
                setErrors({ ...error, liquidityProvisionId: false })
            } else {
                setErrors({ ...error, liquidityProvisionId: true })
            }
        }
        setIsChangeshappen(true);
        setFundDetails(currState);
        props?.updateData(currState);
    };

    //#region click events
    const addFundManager = () => {
        if (/* fundManagers.length > 5 ||  */!props.editable && isFundMatured) {
            return;
        }

        const existingIDs = fundManagers.map(manager => manager.id);
        const newManager = { id: generateUniqueID(existingIDs), userID: "", description: "" };
        setFundManagers([...fundManagers, newManager]);
    };

    const deleteFundManager = (fundmanager) => {
        if (fundManagers.length <= 1) {
            return;
        }
        const remainingfm = fundManagers.filter((item) => item.id !== fundmanager.id);
        const idarray = remainingfm.map((item) => item.userID)
        handleChange('fundStaffeg', idarray)
        setFundManagers(remainingfm);
        props.setFundStaffDropdown((prevItems) =>
            prevItems.map((item) =>
                item.value === fundmanager.userID ? { ...item, disabled: false } : item
            )
        );
    }

    //#region validations
    let count = 0;   //to Show Toast Message only once
    const checknoofValidFields = (isValid, name, defaultValue) => {
        count = ++count;
        const dynamicFieldsCount = fundDetails?.isFirmAsGP ? 0 : fundManagers?.length;
        const TotalFiledsToValidate = 7 + dynamicFieldsCount;
        const errors = {}
        if (fundDetails?.investmentStrategyId === '') {
            errors.investmentStrategyId = true
        }
        if (fundDetails?.fundTargetIndustryId === '') {
            errors.fundTargetIndustryId = true
        }
        if (fundDetails?.geographicFocusId === '') {
            errors.geographicFocusId = true
        }
        if (fundDetails.InvestmentCycle === '') {
            errors.liquidityProvisionId = true
        }
        setErrors(errors)
        noofValidFields['validfields'] = isValid ? noofValidFields['validfields'] + 1 : noofValidFields['validfields'];
        if (noofValidFields['validfields'] === TotalFiledsToValidate) {
            noofValidFields['validfields'] = 0;
            if (isMultifieldsValid) {
                props.handleClickSave(false);
            }
            setIsChangeshappen(false);
            count = 0;
        }
        else {
            if (count === TotalFiledsToValidate) {
                toast.warning("Please Verify the input fields",
                    { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored", style: { zIndex: 9999 } });
                count = 0;
            }
        }
    };

    const handleSave = () => {
        if (isChangeshappen || fundManagers?.length !== iFundManagers?.length) {
            setcheckIfValid(true);
            if (!isMultifieldsValid) {
                /* alert('Target Raised should be greater than Minimum Investment Amount'); */
                toast.warning(`Target Raise should be greater than Minimum Investment Amount`,
                    { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            }
            noofValidFields['validfields'] = 0;
        }
        else {
            props.setEdit(false);
            toast.success("Successfully Saved",
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
    }

    //#region useeffect
    useEffect(() => {
        const currState = { ...fundDetails };
        props?.setFundData((prev) => ({ ...prev, FundDetails: currState }));
    }, []);

    useEffect(() => {
        componentRef.current.scrollIntoView({ behavior: 'smooth' });
        const getFundFilters = async () => {
            const data = await getFundFilterData();
            if (data.responseCode === 200) {
                setFundFilterData(data.responseData)
            }
            else {
            }
        }
        getFundFilters();
    }, []);

    useEffect(() => {
        const initialSelections = {};
        fundManagers.forEach(item => {
            if (item.userID) {
                initialSelections[item.id] = item.userID;
            }
        });
        setSelectedManagers(initialSelections);
    }, [fundManagers]);

    //#region return
    return (
        <div ref={componentRef}>
            <div className="fundRow no-margin">
                <div>

                </div>
                <div>
                    <div className="btns">
                        {
                            !isFundTerminated ? (props.editable ? (
                                <>
                                    <div>
                                        <ActionButton variant="outlined" className="btn-primary"
                                            onClick={() => props.handleClickCancel(props.index, props.setEdit)}
                                            label={"CANCEL"}
                                            startIconName="Cancel" />
                                    </div>
                                    <div className="margin-left-5">
                                        <ActionButton variant="contained" className="btn-primary"
                                            onClick={() => handleSave()}
                                            loading={props.loadingbtn}
                                            label={"SAVE"}
                                            startIconName="Save"
                                            styleProps={{
                                                padding: '8px 36px'
                                            }}
                                        />
                                    </div>
                                </>
                            ) : (
                                <ActionButton variant="contained" className="btn-primary"
                                    onClick={() => props.handleClickEdit(props.index, props.setEdit)}
                                    startIconName="Edit"
                                    label={"EDIT"}
                                >
                                </ActionButton>
                            )) : null
                        }

                        {/* {!isFundTerminated && (
                       
                    )} */}

                    </div>
                </div>
            </div>
            <div className="fundRow">
                <div className="fundDetail disablediv">
                    <AppTextInput
                        textFormatType={textFormatTypeEnum.REQUIRED}
                        type={InputTypes.SELECT}
                        name="fundTypeName"
                        label="Fund Type"
                        defaultValue={fundDetails?.fundTypeID}
                        options={
                            FundFilterData?.fundType?.filter?.((option)=> option.fundTypeID != -1)
                            .map(option => ({
                            label: option?.fundTypeName,
                            value: option?.fundTypeID,
                        }))}
                        onChange={(name, value) => handleChange(name, value)}
                        disabled={!props.editable || isFundMatured}
                        validateField={true}
                        validationType={validationsTypeEnum.REQUIRED}
                        validationMessage={validationMessages.REQUIRED}
                        checkIfValid={checkIfValid}
                        checknoofValidFields={checknoofValidFields}
                        setcheckIfValid={setcheckIfValid}
                    />
                </div>
            </div>
            <div className="fundRow">
                <div className="fundDetail">
                    <AppTextInput
                        textFormatType={textFormatTypeEnum.REQUIRED}
                        name="fundName"
                        label="Fund Name"
                        defaultValue={fundDetails?.fundName}
                        onChange={(name, value) => handleChange(name, value)}
                        disabled={!props.editable || isFundMatured}
                        validateField={true}
                        validationType={validationsTypeEnum.REQUIRED}
                        validationMessage={validationMessages.REQUIRED}
                        checkIfValid={checkIfValid}
                        checknoofValidFields={checknoofValidFields}
                        setcheckIfValid={setcheckIfValid}
                    />
                </div>

                <div className="fundDetail fundLogoWrapper">
                    <FileUploadButton
                        name="iconImage"
                        label="UPLOAD LOGO"
                        defaultFile={fundDetails?.iconImage}
                        clearFile={iconClearFile}
                        isImage={true}
                        disabled={!props.editable && fundDetails?.iconImage === "" || fundDetails?.iconImage === null ? true : false}
                        onChange={(name, value) => handleFileUploadicon(name, value)} />
                    {props.editable && !isFundMatured && fundDetails?.iconImage !== "" && <img src="/icons/wrongbtn.png" className="clearbtn" onClick={handleClearFileICON}></img>}
                </div>

            </div>
            <div>
                <div className="fundBackgroundLabel">Image for the background of Fund Detail page</div>

                <div className="fundLogoWrapper">
                    <FileUploadButton
                        name="bgImage"
                        label="UPLOAD IMAGE"
                        defaultFile={fundDetails?.bgImage}
                        clearFile={bgClearFile}
                        isImage={true}
                        disabled={!props.editable && fundDetails?.bgImage === "" || fundDetails?.bgImage === null ? true : false}
                        onChange={(name, value) => handleFileUpload(name, value)} />
                    {props.editable && !isFundMatured && fundDetails?.bgImage !== "" && <img src="/icons/wrongbtn.png" className="clearbtn" onClick={handleClearFileBG}></img>}
                </div>
            </div>
            <div className="fundRow">
                <AppTextInput
                    textFormatType="text"
                    name="fundDescription"
                    placeholder="Brief Description About the Fund"
                    multiline={true}
                    rows={3}
                    variant="outlined"
                    charactersMaxLength={500}
                    defaultValue={fundDetails?.fundDescription}
                    onChange={(name, value) => handleChange(name, value)}
                    disabled={!props.editable || isFundMatured}
                    validateField={false}
                />
            </div>
            <div className="fundRow space-between">
                <div><Text label="Fund manager / General Partner (GP)" /></div>
                <div>

                </div>
            </div>

            <div className="fundRow space-between res-spacebetween2">
                <div>
                    <SwitchButtonField
                        name="isFirmAsGP"
                        label="Firm as a fund manager"
                        value={fundDetails?.isFirmAsGP}
                        onChange={(name, value) => handleChange(name, value)}
                        disabled={!props.editable} />
                </div>
                <div>
                    <ActionButton
                        variant="outlined" className='btn-primary'
                        icon={<AddIcon />}
                        label={"Add Fund Manager"}
                        disabled={fundDetails?.isFirmAsGP || !props.editable}
                        onClick={() => addFundManager()}
                    />
                </div>
            </div>

            {/* FundManagers */}
            {!(fundDetails?.isFirmAsGP) && props?.fundStaffData &&
                fundManagers?.map((item, index) => (
                    <div key={item.id} className="fundManagerRow">
                        <div className="fundManager">
                            <AppTextInput
                                type={InputTypes.SELECT}
                                label="Fund Manager"
                                name={`fundmanager${item.id}`}
                                defaultValue={item?.userID === 0 ? '' : item?.userID}
                                options={props?.fundStaffData
                                    ? props?.fundStaffData.map(option =>
                                    ({
                                        label: option?.label, value: option?.value,
                                        isdisabled: Object.values(selectedManagers).includes(option?.value) && selectedManagers[item.id] !== option?.value
                                    }))
                                    : []
                                }
                                disabled={!props.editable || fundDetails?.isFirmAsGP}
                                onChange={(name, value) => handleChangeFM(name, value)}
                                required={true}
                                validateField={true}
                                validationType={validationsTypeEnum.REQUIRED}
                                validationMessage={validationMessages.REQUIRED}
                                checkIfValid={checkIfValid}
                                checknoofValidFields={checknoofValidFields}
                                setcheckIfValid={setcheckIfValid}
                            />
                        </div>
                        <div className="fundManager-Description">
                            {/* <input
                                name='Manager Description'
                                placeholder="Manager Description"
                                disabled={true}
                                value={item.description}
                                /> */}
                            <TextField
                                fullWidth
                                variant="outlined"
                                multiline={true}
                                name='ManagerDescription'
                                value={item?.description}
                                rows={1}
                                placeholder="Manager Description"
                                InputProps={{
                                    readOnly: true,
                                }}
                                onChange={(name, value) => handleChange(name, value)}
                                validateField={false}
                            />
                        </div>
                        <div className="fundManager-Delete">
                            {
                                index === 0 ? "" :
                                    <Button
                                        onClick={() => { deleteFundManager(item) }}
                                        sx={{ color: 'black' }}
                                        disabled={!props.editable || fundDetails?.isFirmAsGP}>
                                        <HighlightOffIcon />
                                    </Button>
                            }

                        </div>
                    </div>
                ))
            }
            <div className="fundRow"><Text label="What is the fund’s investment strategy" /></div>
            <div className="fundRow">
                <div className="fundDetail">
                    {FundFilterData &&
                        <AutoSelect
                            type={InputTypes.AUTOSELECT}
                            name="investmentStrategyId"
                            label="Investment Strategy"
                            defaultValue={fundDetails?.investmentStrategyId}
                            options={FundFilterData?.strategy?.map(option => ({ label: option.strategyName, value: option.investmentStrategyID }))}
                            onChange={(name, value) => handleChange(name, value)}
                            disabled={!props.editable || isFundMatured}
                            validateField={true}
                            variant="standard"
                            required={true}
                            error={error.investmentStrategyId}
                            validationType={validationsTypeEnum.REQUIRED}
                            validationMessage={validationMessages.REQUIRED}
                            checkIfValid={checkIfValid}
                            checknoofValidFields={checknoofValidFields}
                            setcheckIfValid={setcheckIfValid} />}
                </div>
                <div className="fundDetail">
                    {FundFilterData &&
                        <AutoSelect
                            type={InputTypes.SELECT}
                            name="fundTargetIndustryId"
                            variant="standard"
                            label="Target Industry/Sector"
                            defaultValue={fundDetails?.fundTargetIndustryId}
                            options={FundFilterData?.industry?.map(option => ({ label: option.targetIndustry, value: option.fundTargetIndustryID }))}
                            onChange={(name, value) => handleChange(name, value)}
                            disabled={!props.editable || isFundMatured}
                            validateField={true}
                            validationType={validationsTypeEnum.REQUIRED}
                            validationMessage={validationMessages.REQUIRED}
                            checkIfValid={checkIfValid}
                            checknoofValidFields={checknoofValidFields}
                            setcheckIfValid={setcheckIfValid} />}
                </div>
            </div>
            <div className="fundRow">
                <div className="fundDetail">
                    {FundFilterData &&
                        <AutoSelect
                            type={InputTypes.SELECT}
                            name="geographicFocusId"
                            variant="standard"
                            label="Geographic Focus"
                            defaultValue={fundDetails?.geographicFocusId}
                            options={FundFilterData?.geographicFocus?.map(option => ({ label: option.geographicFocusName, value: option.geographicFocusId }))}
                            onChange={(name, value) => handleChange(name, value)}
                            disabled={!props.editable || isFundMatured}
                            validateField={true}
                            validationType={validationsTypeEnum.REQUIRED}
                            validationMessage={validationMessages.REQUIRED}
                            checkIfValid={checkIfValid}
                            checknoofValidFields={checknoofValidFields}
                            setcheckIfValid={setcheckIfValid} />}
                </div>
                <div className="fundDetail">
                    <AppTextInput
                        textFormatType={textFormatTypeEnum.US_CURRENCY}
                        name="fundSize"
                        label="Target Raise"
                        defaultValue={fundDetails?.fundSize}
                        onChange={(name, value) => handleChange(name, value)}
                        disabled={!props.editable || isFundMatured}
                        validateField={true}
                        validationType={validationsTypeEnum.NON_NEGATIVE}
                        validationMessage={validationMessages.NON_NEGATIVE}
                        checkIfValid={checkIfValid}
                        checknoofValidFields={checknoofValidFields}
                        setcheckIfValid={setcheckIfValid}
                    />
                </div>
            </div>
            <div className="fundRow">
                <div className="fundDetail">
                    <AppTextInput
                        textFormatType={textFormatTypeEnum.US_CURRENCY}
                        name="minimumInvestmentAmount"
                        label="Minimum Investment Amount"
                        defaultValue={fundDetails?.minimumInvestmentAmount}
                        onChange={(name, value) => handleChange(name, value)}
                        disabled={!props.editable || isFundMatured}
                        validateField={true}
                        validationType={validationsTypeEnum.NON_NEGATIVE}
                        validationMessage={validationMessages.NON_NEGATIVE}
                        checkIfValid={checkIfValid}
                        checknoofValidFields={checknoofValidFields}
                        setcheckIfValid={setcheckIfValid}
                    />
                </div>
                <div className="fundDetail">
                    {FundFilterData &&
                        <AppTextInput
                            type={InputTypes.SELECT}
                            name="fundStatus"
                            label="Fund Status"
                            defaultValue={fundDetails?.fundStatus}
                            options={FundFilterData?.fundStatus?.map(option => ({ label: option.fundStatusName, value: option.fundStatusId }))}
                            onChange={(name, value) => handleChange(name, value)}
                            disabled={!props.editable || isFundMatured}
                            validateField={true}
                            validationType={validationsTypeEnum.REQUIRED}
                            validationMessage={validationMessages.REQUIRED}
                            checkIfValid={checkIfValid}
                            checknoofValidFields={checknoofValidFields}
                            setcheckIfValid={setcheckIfValid} />}
                </div>
            </div>
            <div className="fundRow">
                <div className="fundDetail">
                    {FundFilterData &&
                        <AppTextInput
                            type={InputTypes.SELECT}
                            name="vintage"
                            label="Vintage Year"
                            defaultValue={fundDetails?.vintage}
                            options={json?.Years?.map(option => ({ label: option.name, value: option.id }))}
                            onChange={(name, value) => handleChange(name, value)}
                            disabled={!props.editable || isFundMatured}
                            validateField={true}
                            validationType={validationsTypeEnum.REQUIRED}
                            validationMessage={validationMessages.REQUIRED}
                            checkIfValid={checkIfValid}
                            checknoofValidFields={checknoofValidFields}
                            setcheckIfValid={setcheckIfValid} />}
                </div>
                <div className="fundDetail">
                    {FundFilterData && fundDetails.fundTypeName === FundTypes.PRIVATE_CREDIT ? <div className="fundDetail">
                        {FundFilterData &&
                            <AppTextInput
                                type={InputTypes.SELECT}
                                name="legalStructureID"
                                label="Legal Structure"
                                defaultValue={fundDetails?.legalStructureID}
                                options={FundFilterData?.legalStructure?.map(option => ({ label: option.legalStructureName, value: option.legalStructureID }))}
                                onChange={(name, value) => handleChange(name, value)}
                                disabled={!props.editable || isFundMatured}
                                validateField={true}
                                validationType={validationsTypeEnum.REQUIRED}
                                validationMessage={validationMessages.REQUIRED}
                                checkIfValid={checkIfValid}
                                checknoofValidFields={checknoofValidFields}
                                setcheckIfValid={setcheckIfValid} />}
                    </div> :
                        <AppTextInput
                            textFormatType={textFormatTypeEnum.ONLY_NUMBERS}
                            name="investmentPeriodCriteria"
                            label="Term"
                            defaultValue={fundDetails?.investmentPeriodCriteria}
                            onChange={(name, value) => handleChange(name, value)}
                            disabled={!props.editable}
                            validateField={false}
                            charactersMaxLength={2} />}

                </div>
                {/* <div className="fundDetail">
                    <AppTextInput
                        textFormatType={textFormatTypeEnum.PERCENTAGE}
                        name="targettedIRR"
                        label="Target Return"
                        defaultValue={fundDetails?.targettedIRR}
                        onChange={(name, value) => handleChange(name, value)}
                        disabled={!props.editable || isFundMatured}
                        validateField={true}
                        validationType={validationsTypeEnum.PERCENTAGE}
                        validationMessage={validationMessages.PERCENTAGE}
                        checkIfValid={checkIfValid}
                        checknoofValidFields={checknoofValidFields}
                        setcheckIfValid={setcheckIfValid}
                    />
                </div> */}
            </div>
            <div className="fundRow">
                <div className="fundDetail">
                    {FundFilterData &&
                        <AutoSelect
                            type={InputTypes.SELECT}
                            name="investmentCycle"
                            label="Liquidity"
                            variant="standard"
                            defaultValue={fundDetails?.investmentCycle}
                            options={FundFilterData?.liquidityProvision?.map(option => ({ label: option.liquidityProvisionName, value: option.liquidityProvisionId }))}
                            onChange={(name, value) => handleChange(name, value)}
                            disabled={!props.editable || isFundMatured}
                            validateField={true}
                            validationType={validationsTypeEnum.REQUIRED}
                            validationMessage={validationMessages.REQUIRED}
                            checkIfValid={checkIfValid}
                            checknoofValidFields={checknoofValidFields}
                            setcheckIfValid={setcheckIfValid} />}
                </div>
                <div className="fundDetail">
                    <AppTextInput
                        type={InputTypes.SELECT}
                        name="visibility"
                        label="Visibility"
                        defaultValue={fundDetails?.visibility}
                        options={json.Visibility.map(option => ({ label: option.name, value: option.value }))}
                        onChange={(name, value) => handleChange(name, value)}
                        disabled={!props.editable || isFundMatured}
                        validateField={true}
                        validationType={validationsTypeEnum.REQUIRED}
                        validationMessage={validationMessages.REQUIRED}
                        checkIfValid={checkIfValid}
                        checknoofValidFields={checknoofValidFields}
                        setcheckIfValid={setcheckIfValid} />
                </div>
            </div>
            <div className="fundRow">
                {
                    fundDetails.fundTypeName === FundTypes.PRIVATE_CREDIT ? <></> : <div className="fundDetail">
                        {FundFilterData &&
                            <AppTextInput
                                type={InputTypes.SELECT}
                                name="legalStructureID"
                                label="Legal Structure"
                                defaultValue={fundDetails?.legalStructureID}
                                options={FundFilterData?.legalStructure?.map(option => ({ label: option.legalStructureName, value: option.legalStructureID }))}
                                onChange={(name, value) => handleChange(name, value)}
                                disabled={!props.editable || isFundMatured}
                                validateField={true}
                                validationType={validationsTypeEnum.REQUIRED}
                                validationMessage={validationMessages.REQUIRED}
                                checkIfValid={checkIfValid}
                                checknoofValidFields={checknoofValidFields}
                                setcheckIfValid={setcheckIfValid} />}
                    </div>
                }
            </div>
        </div>
    )
}

export default memo(FundManagerDetails);