import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { useSelector } from "react-redux";
import MessangerModal from '../instant-messenger/components/messengerModal';
import { getHeaderColorConfig } from '../../state/slices/colorConfigSlice';


const AzureChatBot = () => {
  const headerColorConfig = useSelector(getHeaderColorConfig);
    return (
        <div className='page'>
            {/* <MessengerModel/> */}
        </div>
        
    );
};

export default AzureChatBot;
