import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DialogActions, DialogContent,Button } from '@mui/material';
import TextInput from '../../../common/TextInput/TextInput';
import { minWidth } from '@mui/system';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import "../../CompanyDetail.module.css"
import Text from '../../../common/Text/Text';
import ActionButton from '../../../common/ActionButton/ActionButton';
import VDRoom from '../../../common/VirtualDataRoom/VDRoom';
import Text2 from '../../../common/Text/Text2';
import { useRef } from 'react';



function UploadDocumentation(props) {

    const [open, setOpen] = useState(true);

    const componentRef = useRef(null);
    useEffect(() => {
        componentRef.current.scrollIntoView({ behavior: 'smooth' });
    }, []);
 
    const handleOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
      const onnextclick = () => {
        props.setActiveStep(props.activeStep+1)
      }
      const onprevclick = () => {
        props.setActiveStep(props.activeStep-1)
      }
      
      return (
        <div  ref={componentRef}>
              <VDRoom VDRType={'Portfolio'}/>
              <Button
                component="label"
                variant="contained"
                style={{ width: '160px' }}
                onClick={() => { 
                  props.onSaveClick();
                }}
              >
                Save
            </Button>
        </div>
      );
}
export default UploadDocumentation;