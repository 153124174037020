import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./state/store";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import App from "./App";


const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

// Disabling console.log other than development and test environments
if (!process.env.NODE_ENV === 'development' || !process.env.NODE_ENV === 'test') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

root.render(
    <Provider store={store}>
      <App/>
    </Provider>
);
