import React, { useCallback, useState, useEffect } from "react";
import userprofilestyles from "../userprofile1.module.css";
// import NavigationBar from "../../common/navigation-bar/NavigationBar";
import UserProfilePopUp from "./UserProfilePopUp";
import 'react-image-crop/dist/ReactCrop.css';
import { RolesAndPermissions, FeatureAccess } from '../../../utils/enum';
import { checkFeaturePermissions } from '../../../utils/common';
import { updateUserProfileIconData } from '../services/UserProfileService';
import { ToastContainer, toast } from 'react-toastify';
import CircleIcon from "../../chatbot/Icon";
import secureLocalStorage from "react-secure-storage";
import { CurrencyFormatter } from "../../../utils/Formater";
import TextInput from "../../common/TextInput/TextInput";
import CurrencyInput from 'react-currency-input-field';
import CancelIcon from '@mui/icons-material/Cancel';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import SaveIcon from '@mui/icons-material/Save';

import MiniDrawer from '../../common/navigation-bar/NavBarGrid'
import { Button } from "react-bootstrap";


const UserProfile = () => {
  const [isExpanded, setExpendState] = useState(false);
  const [value, setValue] = React.useState(100);

  function handleOptionSelect(option) {
    document.querySelector(`.${userprofilestyles.fieldClass}`).innerText = option;
  }

  //PopUp
  const [showPopup, setShowPopup] = useState(false);
  const [popupdetails, setPopUpDetails] = useState();
  const [popupoptions, setPopUpOptions] = useState();

  const onPopupClick = async (details, options) => {
   
  }
  const handlePopUpCancel = () => {
    // Close the popup without removing the item
    setShowPopup(false);
  };
  const [investorProfile, setInvestorProfile] = useState({});
  const [investmentData, setinvestmentData] = useState([]);
  const [isPersonalInfoClicked, setIsPersonalInfoClicked] = useState(true);
  const [isBankingInfoClicked, setIsBankingInfoClicked] = useState(false);
  const [isInvestmentProfileClicked, setIsInvestmentProfileClicked] = useState(false);
  const [isSubscriptionClick,setIsSubscriptionClicked] = useState(false);
  const [editsMade, setEditsMade] = useState(false);

  const [isSaveEnabled, setSaveEnabled] = useState(false);

  const customStyles = {
    input: {
      border: 'none', // Remove border
      borderBottom: '1px solid #0A1A27', // Add a bottom border
      outline: 'none', // Remove the focus outline
      padding: '5px', // Add padding for spacing
      width: '100%', // Set the width as needed
    },
  }

  useEffect(() => {
    fetchData();
  }, []);

  const emailId = secureLocalStorage.getItem("userEmail");

  const fetchData = async () => {
   
  }

 
  
  //Profile Details
  const [profileData, setProfileData] = useState({
    userId: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    password: '',
    userRoleId: '',
    isActive: '',
    softDelete: '',
    trustedContact: '',
    taxFillingContact: '',
    annualPreTaxIncome: '',
    numberOfDependents: '',
    employerStatus: '',
    employer: '',
    occupation: '',
    spouseAnnualPreTaxIncome: '',
    spouseHasIIAAccount: '',
    federalTaxBracket: '',
    householdInvestableAssets: '',
    phoneNo: '',
    addressLine1: '',
    addressLine2: '',
    zipCode: '',
    createdBy: '',
    createdDate: '',
    updatedBy: '',
    updatedDate: '',
    tenantName: '',
    tenantURL: '',
    tenantGUID: ''
  });

  useEffect(() => {
    if (investorProfile?.personalInformation) {
      const { userId, firstName, lastName, emailAddress, password, userRoleId,
        isActive,
        softDelete,
        trustedContact,
        taxFillingContact,
        annualPreTaxIncome,
        numberOfDependents,
        employerStatus,
        employer,
        occupation,
        spouseAnnualPreTaxIncome,
        spouseHasIIAAccount,
        federalTaxBracket,
        householdInvestableAssets,
        phoneNo,
        addressLine1,
        addressLine2,
        zipCode,
        createdBy,
        createdDate,
        updatedBy,
        updatedDate,
        tenantName,
        tenantURL,
        tenantGUID,
        cityMasterID,
        stateMasterID,
        profileIcon } = investorProfile.personalInformation;

      const data = {
        userId: userId || '',
        firstName: firstName || '',
        lastName: lastName || '',
        emailAddress: emailAddress || '',
        password: password || '',
        userRoleId: userRoleId || '',
        isActive: isActive || '',
        softDelete: softDelete || 'false',
        trustedContact: trustedContact || '',
        taxFillingContact: taxFillingContact || '',
        annualPreTaxIncome: annualPreTaxIncome || '',
        numberOfDependents: numberOfDependents || '',
        employerStatus: employerStatus || '',
        employer: employer || '',
        occupation: occupation || '',
        spouseAnnualPreTaxIncome:spouseAnnualPreTaxIncome || '',
        spouseHasIIAAccount: spouseHasIIAAccount || '',
        federalTaxBracket: federalTaxBracket || '',
        householdInvestableAssets: householdInvestableAssets || '',
        phoneNo: phoneNo || '',
        addressLine1: addressLine1 || '',
        addressLine2: addressLine2 || '',
        zipCode: zipCode || '',
        createdBy: createdBy || '',
        createdDate: createdDate || '',
        updatedBy: updatedBy || '',
        updatedDate: updatedDate || '',
        tenantName: tenantName || '',
        tenantURL: tenantURL || '',
        tenantGUID: tenantGUID || '',
        cityMasterID : cityMasterID || '',
        stateMasterID : stateMasterID || ''
        // Add other fields here
      };
      setProfileData(data);
      setInitialProfileData(data);

    }
  }, [investorProfile]);

  const [city, setCity] = useState('Hyderabad');

  const handleInputCityChange = (event) => {
    setCity(event.target.value);
  };

  //Banking Information
  const [bankingInformation, setBankingInformation] = useState({
    userBankDetailsID: 0,
    userId: 0,
    bankName: '',
    accountNo: '',
    branchLocation: '',
    bankAddress: '',
    cityMasterId: 0,
    stateMasterId: 0,
    zipCode: '',
    addressLine1: '',
    addressLine2: '',
    softDelete: false, // Set the default value to false
    createdBy: '',
    createdDate: new Date(),
    updatedBy: '',
    updatedDate: new Date()
  });

  useEffect(() => {
    if (investorProfile?.bankingInformation) {
      const {
        userBankDetailsID,
        userId,
        bankName,
        accountNo,
        branchLocation,
        bankAddress,
        cityMasterId,
        stateMasterId,
        zipCode,
        addressLine1,
        addressLine2,
        createdBy,
        createdDate,
        updatedBy,
        updatedDate
      } = investorProfile.bankingInformation;

      const softDelete = investorProfile.bankingInformation?.softDelete || false; // Update the softDelete field separately

      const data = {
        userBankDetailsID: userBankDetailsID || 0,
        userId: userId || 0,
        bankName: bankName || '',
        accountNo: accountNo || '',
        branchLocation: branchLocation || '',
        bankAddress: bankAddress || '',
        cityMasterId: cityMasterId || 0,
        stateMasterId: stateMasterId || 0,
        zipCode: zipCode || '',
        addressLine1: addressLine1 || '',
        addressLine2: addressLine2 || '',
        softDelete: softDelete, // Update the softDelete field separately
        createdBy: createdBy || '',
        createdDate: createdDate || '',
        updatedBy: updatedBy || '',
        updatedDate: updatedDate || ''
        // Add other fields here
      };
      setBankingInformation(data);
      setInitialBankingInformation(data);
    }
  }, [investorProfile]);



  //InvestmentProfileDetails
const [initialInvestData,setInitialInvestData] = useState([])
  const [investmentProfileDetails, setInvestmentProfileDetails] = useState({
    questionID: '',
    question: '',
    shortName: '',
    questionAnswerID: '',
    answer: '',
    description: '',
    showInInvestProfile: ''
    // Add other fields here
  });
  const [selectedOptions, setSelectedOptions] = useState(investmentData);
  const [originalInvestmentData, setOriginalInvestmentData] = useState([]);


  const handleOptionSelected = (questionID, selectedOptions) => {
    setOriginalInvestmentData([...investmentData]);

    const updatedInvestmentData = investmentData.map(item => {
      if (item.questionID === questionID) {
        return { ...item, answer: selectedOptions.answer, questionAnswerID: selectedOptions.questionAnswerID };
      }
      return item;
    });

    setinvestmentData(updatedInvestmentData);
    setShowPopup(false);
  };

  console.log("updated", investmentData)
  useEffect(() => {
    if (investorProfile?.investmentProfile) {
      const { questionID,
        question,
        shortName,
        questionAnswerID,
        answer,
        description,
        showInInvestProfile } = investorProfile.investmentProfile[0];

      setInvestmentProfileDetails({
        questionID: questionID || '',
        question: question || '',
        shortName: shortName || '',
        questionAnswerID: questionAnswerID || '',
        answer: answer || '',
        description: description || '',
        showInInvestProfile: showInInvestProfile || '',
      });
      setInitialInvestData({
        questionID: questionID || '',
        question: question || '',
        shortName: shortName || '',
        questionAnswerID: questionAnswerID || '',
        answer: answer || '',
        description: description || '',
        showInInvestProfile: showInInvestProfile || '',
      });
    }
  }, [investorProfile]);

  const handleSave = async () => {
    console.log("open");
    console.log(investmentData);
  
    let success = true; // Flag to check if both API calls are successful
  
  
  
    // Display a single toast for both API calls
    // if (success && editsMade) {
    //   toast.success("Successfully Saved", {
    //     position: toast.POSITION.TOP_RIGHT,
    //     theme: "colored",
    //     style: {
    //       marginTop: "50px",
    //     },
    //   });
    // }
    if (true) {
      toast.success("Successfully Saved", {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored",
        style: {
          marginTop: "50px",
        },
      });
    }
    
  
    console.log(profileData?.isActive);
    console.log(profileData?.numberOfDependents);
    console.log(bankingInformation?.softDelete);
    console.log("close");
    setEditsMade(false);
  };
  
  const handleEdit = () => {
    setEditsMade(true); 
    
  };


  //Shifting between Tabs
  const onPersonalInfoClickedClick = () => {
    setIsPersonalInfoClicked(true);
    setIsBankingInfoClicked(false);
    setIsInvestmentProfileClicked(false);
    setIsSubscriptionClicked(false);
  }

  const onBankingInfoClick = () => {
    setIsPersonalInfoClicked(false);
    setIsBankingInfoClicked(true);
    setIsInvestmentProfileClicked(false);
    setIsSubscriptionClicked(false);
  }

  const onInvestmentProfileClick = () => {
    setIsPersonalInfoClicked(false);
    setIsBankingInfoClicked(false);
    setIsInvestmentProfileClicked(true);
    setIsSubscriptionClicked(false);
  }
  const onSubscriptionClick = () => {
    setIsPersonalInfoClicked(false);
    setIsBankingInfoClicked(false);
    setIsInvestmentProfileClicked(false);
    setIsSubscriptionClicked(true);
    
  
  }

  const onClickEditButton = () => {
    setSaveEnabled(isSaveEnabled => !isSaveEnabled);
  };

  const onClickSaveButton = async () => {
    try {
      await handleSave();
      setSaveEnabled(false);
    } catch (error) {
      console.error(error);
    }
  };

  const dependentsOptions = [
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    // Add more options as needed
  ];

  const spouseIncomeOptions = [
    { value: 'Yes', label: 'Yes' },
    /*  { value: 'No', label: 'No' }, */
    // Add more options as needed
  ];




  const handleInputChangeProfileData = (event, fieldName) => {
    const updatedProfileData = {
      ...profileData,
      [fieldName]: event.target ? event.target.value : '',
    };
    setProfileData(updatedProfileData);
  };

  const handleInputChangeBankInformation = (event, fieldName) => {
    const updatedbankingInformation = {
      ...bankingInformation,
      [fieldName]: event.target.value,
    };
    setBankingInformation(updatedbankingInformation);
  };
  const [isDialogOpen, setIsDialogOpen] = useState(false);


  const handleDialogOpen = () => {
    if (isSaveEnabled) {
      setIsDialogOpen(true);
    }
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleSaveImage = () => {
    // Logic to save the cropped image to your backend (if necessary)
    handleDialogClose();
  };

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    console.log(URL.createObjectURL(file));
    // Process the file and update the selectedImage state
    setSelectedImage(URL.createObjectURL(file));
    
    const formDataBody = new FormData();
    formDataBody.append("file", file);
    await updateUserProfileIconData(formDataBody, profileData?.emailAddress);
  };

  // Remove the object wrapping for initial data
  const [initialProfileData, setInitialProfileData] = useState({ ...profileData });
  const [initialBankingInformation, setInitialBankingInformation] = useState({ ...bankingInformation });

  // Inside the onCancelClick function
  const onCancelClick = () => {
    setProfileData(initialProfileData);
    setinvestmentData([...originalInvestmentData]);
    setBankingInformation(initialBankingInformation);
    
    
  };
  const [step, setStep] = useState(1);
  const [QuestionBank, setQuestionBank] = useState([]);

    const [CountryStateCityList, setCountryStateCityList] = useState();
    const [countrieslist,setCountriesList] = useState();
    const [stateslist, setStatesList] = useState();
    const [citieslist, setcitieslist] = useState();
    const [bankcitieslist, setbankcitieslist] = useState();

  useEffect(()=> {
    /*   */
    updateCitiesList(profileData.stateMasterID);
  },[profileData.stateMasterID] ) 
  
  const updateCitiesList = (selectedState) => {
    const selectedStateID = parseInt(selectedState, 10);
    /*   */
    if (selectedState) {
      const cities = CountryStateCityList?.cities
        ?.filter(city => city.stateMasterID === selectedStateID)
        ?.map(city => ({
          cityName: city.cityName,
          cityID: city.cityMasterID
        }));
  
      // Add a placeholder option
      cities.unshift({
        cityName: 'Select an option',
        cityID: ''
      });
  
      setcitieslist(cities);
    } else {
      setcitieslist([]);
    }
  };
  

  useEffect(()=> {
    updatebankCitiesList(bankingInformation.stateMasterId);
  },[bankingInformation.stateMasterId] ) 

  const updatebankCitiesList = (selectedState) => {
    const selectedStateID = parseInt(selectedState, 10);
    /*   */
    if (selectedState) {
      const cities = CountryStateCityList.cities
        .filter(city => city.stateMasterID === selectedStateID)
        .map(city => ({
          cityName: city.cityName,
          cityID: city.cityMasterID
        }));
  
      // Add a placeholder option
      cities.unshift({
        cityName: 'Select an option',
        cityID: ''
      });
  
      setbankcitieslist(cities);
    } else {
      setbankcitieslist([]);
    }
  };
  
  /*   */
  
    
    

  return (
    <div className={userprofilestyles.userProfile1}>
      <div className={isExpanded ? userprofilestyles.userProfile1Expanded : ''}>
        <div className={userprofilestyles.userProfile1Child} />
        <div className={userprofilestyles.userProfile1Item} />
        <div className={userprofilestyles.userProfile1Inner} />
          <label className={userprofilestyles.avatarLabel}>
            <input
              type="file"
              accept="image/*"
              className={userprofilestyles.avatarInput}
              onChange={handleImageUpload}
              style={{ display: 'none' }}
            />
            <img
              className={userprofilestyles.avatarIcon}
              alt=""
            src={selectedImage ? selectedImage : '/icons/defaultpropic.jpg'}
            />
            <div className={userprofilestyles.avatarText}>EDIT</div>
          </label>
          
  <div hidden={isSubscriptionClick}>
    {isSaveEnabled ? (
      <Button variant="contained" className="btn-primary" onClick={onClickSaveButton}>
       <SaveIcon/>
       Save
      </Button>
    ) : (
      <Button variant="contaied" className="btn-primary" onClick={onClickEditButton}>
       <ModeEditOutlineOutlinedIcon/>
        Edit
      </Button>
    )}
  </div>

        <div hidden={isSubscriptionClick}>
        <Button variant="outlined" className="btn-primary" onClick={onCancelClick}>
        <CancelIcon />
        Cancel
        </Button>
        </div>

        <div hidden={!isPersonalInfoClicked}>
          <div className={userprofilestyles.firstName} style={{marginRight:'15px'}}>
            <div className={userprofilestyles.divLabel}>First Name</div>
            <input
              className={userprofilestyles.fieldClass}
              value={profileData?.firstName}
              readOnly={!isSaveEnabled}
              onChange={(event) => handleInputChangeProfileData(event, 'firstName')}
            />
          </div>
          <div className={userprofilestyles.lastName}>
            <div className={userprofilestyles.divLabel}>Last Name</div>
            <input
              className={userprofilestyles.fieldClass}
              value={profileData?.lastName}
              readOnly={!isSaveEnabled}
              onChange={(event) => handleInputChangeProfileData(event, 'lastName')}
            />
          </div>
          <div className={userprofilestyles.label8}>
            <div className={userprofilestyles.divLabel}>Email Address</div>
            <img className={userprofilestyles.pathIcon} alt="" src="/UserProfile/path-297.svg" />
            <input
              className={userprofilestyles.fieldClass}
              value={investorProfile?.personalInformation?.emailAddress}
              readOnly={!isSaveEnabled}
            />
          </div>
          <div className={userprofilestyles.label13}>
            <div className={userprofilestyles.divLabel}>Phone Number</div>
            <input
              className={userprofilestyles.fieldClass}
              value={profileData?.phoneNo}
              readOnly={!isSaveEnabled}
              onChange={(event) => handleInputChangeProfileData(event, 'phoneNo')}
            />

          </div>

          <div className={userprofilestyles.label1}>
            <div className={userprofilestyles.divLabel}>Address Line 1</div>
            <input
              className={userprofilestyles.fieldClass}
              value={profileData?.addressLine1}
              readOnly={!isSaveEnabled}
              onChange={(event) => handleInputChangeProfileData(event, 'addressLine1')}
            />
          </div>
          <div className={userprofilestyles.label5}>
            <div className={userprofilestyles.divLabel}>Address Line 2</div>
            <input
              className={userprofilestyles.fieldClass}
              value={profileData?.addressLine2}
              readOnly={!isSaveEnabled}
              onChange={(event) => handleInputChangeProfileData(event, 'addressLine2')}
            />
          </div>
          <div className={userprofilestyles.label9}>
            <div className={userprofilestyles.divLabel}>City</div>
            <img className={userprofilestyles.pathIcon} alt="" src="/UserProfile/path-297.svg" />
            <select
                  className={userprofilestyles.fieldClass}
                  value={profileData?.cityMasterID} // Set the default value here
                  disabled={!isSaveEnabled}
                  onChange={(event) => handleInputChangeProfileData(event, 'cityMasterID')}
                >
                  {citieslist?.map((option) => (
                    <option key={option.cityID} value={option.cityID}>{option.cityName}</option>
                  ))}
                </select>
          </div>
          <div className={userprofilestyles.labelGroup}>
            <div className={userprofilestyles.boundary}>
              <div className={userprofilestyles.divLabel}>State</div>
              <select
                  className={userprofilestyles.fieldClass}
                  value={profileData?.stateMasterID} // Set the default value here
                  disabled={!isSaveEnabled}
                  onChange={(event) => handleInputChangeProfileData(event, 'stateMasterID')}
                >
                  {stateslist?.map((option) => (
                    <option key={option.stateID} value={option.stateID}>{option.stateName}</option>
                  ))}
                </select>

              
              
            </div>
          </div>
          <div className={userprofilestyles.label2}>
            <div className={userprofilestyles.divLabel}>Zip Code</div>
            <input
              className={userprofilestyles.fieldClass}
              value={profileData?.zipCode}
              readOnly={!isSaveEnabled}
              onChange={(event) => handleInputChangeProfileData(event, 'zipCode')}
            />
          </div>
          <div className={userprofilestyles.label6}>
            <div className={userprofilestyles.divLabel}>Tax Filing Contact</div>
            <input
              className={userprofilestyles.fieldClass}
              value={profileData?.taxFillingContact}
              readOnly={!isSaveEnabled}
              onChange={(event) => handleInputChangeProfileData(event, 'taxFillingContact')}
            />
          </div>
          <div className={userprofilestyles.label10}>
          <div className={userprofilestyles.divLabel}>Annual Pre Tax Income</div>
 
        <CurrencyInput
        className={userprofilestyles.fieldClass}
        name="annualPreTaxIncome"
        value={profileData?.annualPreTaxIncome}
        decimalsLimit={2} // Customize as needed
        prefix="$" // Set the currency symbol as a prefix
              
        disabled={!isSaveEnabled}
        onValueChange={(value) => {
          setProfileData({ ...profileData, annualPreTaxIncome: value });
        }}
        style={customStyles.input}
		/>
   
  
</div>
          <div className={userprofilestyles.labelParent}>
            <div className={userprofilestyles.boundary}>
              <div className={userprofilestyles.divLabel}>Number of Dependents</div>
              <select
                className={userprofilestyles.fieldClass}
                value={profileData?.numberOfDependents}
                disabled={!isSaveEnabled}
                onChange={(event) => handleInputChangeProfileData(event, 'numberOfDependents')}
              >
                {dependentsOptions.map((option) => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
          </div>

          <div className={userprofilestyles.label14}>
            <div className={userprofilestyles.divLabel}>Employer Status</div>
            <input
              className={userprofilestyles.fieldClass}
              value={profileData?.employerStatus}
              readOnly={!isSaveEnabled}
              onChange={(event) => handleInputChangeProfileData(event, 'employerStatus')}
            />
          </div>

          <div className={userprofilestyles.label15}>
            <div className={userprofilestyles.divLabel}>Employer</div>
            <input
              className={userprofilestyles.fieldClass}
              value={profileData?.employer}
              readOnly={!isSaveEnabled}
              onChange={(event) => handleInputChangeProfileData(event, 'employer')}
            />
          </div>

          <div className={userprofilestyles.label16}>
            <div className={userprofilestyles.divLabel}>Occupation</div>
            <input
              className={userprofilestyles.fieldClass}
              value={profileData?.occupation}
              readOnly={!isSaveEnabled}
              onChange={(event) => handleInputChangeProfileData(event, 'occupation')}
            />
          </div>
        <div className={userprofilestyles.label3}>
          <div className={userprofilestyles.divLabel}>Spouse Annual Income</div>
          <CurrencyInput
          className={userprofilestyles.fieldClass}
          name="spouseAnnualPreTaxIncome"
          value={profileData?.spouseAnnualPreTaxIncome}
          decimalsLimit={2} // Customize as needed
          prefix="$" // Set the currency symbol as a prefix
          disabled={!isSaveEnabled}
          onValueChange={(value) => {
          setProfileData({ ...profileData, spouseAnnualPreTaxIncome: value });
        }}
        style={customStyles.input}
        
		/>
    <span/>
</div>
            <div className={userprofilestyles.label7}>
            <div className={userprofilestyles.divLabel}>Spouse Has IIA Account</div>
            <select
              className={userprofilestyles.fieldClass}
              value={profileData?.spouseHasIIAAccount}
              disabled={!isSaveEnabled}
              onChange={(event) => handleInputChangeProfileData(event, 'spouseHasIIAAccount')}
            >
              {spouseIncomeOptions.map((option) => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>

          <div className={userprofilestyles.label11}>
            <div className={userprofilestyles.divLabel}>Federal Tax Bracket</div>
            <input
              className={userprofilestyles.fieldClass}
              value={profileData?.federalTaxBracket}
              readOnly={!isSaveEnabled}
              onChange={(event) => handleInputChangeProfileData(event, 'federalTaxBracket')}
            />
          </div>
          <div className={userprofilestyles.label12}>
            <div className={userprofilestyles.divLabel}>Household Investable Assets</div>
            <input
              className={userprofilestyles.fieldClass}
              value={profileData?.householdInvestableAssets}
              readOnly={!isSaveEnabled}
              onChange={(event) => handleInputChangeProfileData(event, 'householdInvestableAssets')}
            />
          </div>
        </div>
        <div hidden={!isBankingInfoClicked}>
          <div className={userprofilestyles.firstNameLabel}>{investorProfile?.personalInformation?.firstName} {investorProfile?.personalInformation?.lastName}</div>
          <div className={userprofilestyles.lastNameLabel}></div>
          <div className={userprofilestyles.label8}>
            <div className={userprofilestyles.divLabel}>Bank Name</div>
            <input
              className={userprofilestyles.fieldClass}
              value={bankingInformation?.bankName}
              readOnly={!isSaveEnabled}
              onChange={(event) => handleInputChangeBankInformation(event, 'bankName')}
            />

          </div>

          <div className={userprofilestyles.label6}>
            <div className={userprofilestyles.divLabel}>Account No.</div>
            <input
              className={userprofilestyles.fieldClass}
              value={bankingInformation?.accountNo}
              readOnly={!isSaveEnabled}
              onChange={(event) => handleInputChangeBankInformation(event, 'accountNo')}
            />

          </div>
          <div className={userprofilestyles.label7}>
            <div className={userprofilestyles.divLabel}>Branch Location</div>
            <input
              className={userprofilestyles.fieldClass}
              value={bankingInformation?.branchLocation}
              readOnly={!isSaveEnabled}
              onChange={(event) => handleInputChangeBankInformation(event, 'branchLocation')}
            />

          </div>

          <div className={userprofilestyles.label13}>
            <div className={userprofilestyles.divLabel}>Address Line 1</div>
            <input
              className={userprofilestyles.fieldClass}
              value={bankingInformation?.addressLine1}
              readOnly={!isSaveEnabled}
              onChange={(event) => handleInputChangeBankInformation(event, 'addressLine1')}
            />

          </div>



          <div className={userprofilestyles.label10}>
            <div className={userprofilestyles.divLabel}>City</div>
              <select
                  className={userprofilestyles.fieldClass}
                  value={bankingInformation?.cityMasterId} // Set the default value here
                  disabled={!isSaveEnabled}
                  onChange={(event) => handleInputChangeBankInformation(event, 'cityMasterId')}
                >
                  {bankcitieslist?.map((option) => (
                    <option key={option.cityID} value={option.cityID}>{option.cityName}</option>
                  ))}
                </select>

          </div>

          <div className={userprofilestyles.label11}>
            <div className={userprofilestyles.divLabel}>Zip Code</div>
            <input
              className={userprofilestyles.fieldClass}
              value={bankingInformation?.zipCode}
              readOnly={!isSaveEnabled}
              onChange={(event) => handleInputChangeBankInformation(event, 'zipCode')}
            />
          </div>

          <div className={userprofilestyles.label1}>
            <div className={userprofilestyles.divLabel}>Address Line 2</div>
            <input
              className={userprofilestyles.fieldClass}
              value={bankingInformation?.addressLine2}
              readOnly={!isSaveEnabled}
              onChange={(event) => handleInputChangeBankInformation(event, 'addressLine2')}
            />
          </div>


          <div className={userprofilestyles.labelParent}>
            <div className={userprofilestyles.boundary}>
              <div className={userprofilestyles.divLabel}>State</div>
              <select
                className={userprofilestyles.fieldClass}
                value={bankingInformation?.stateMasterId} // Set the default value here
                disabled={!isSaveEnabled}
                onChange={(event) => handleInputChangeBankInformation(event, 'stateMasterId')}
              >
                {stateslist?.map((option) => (
                    <option key={option.stateID} value={option.stateID}>{option.stateName}</option>
                  ))}
              </select>
            </div>
          </div>

        </div>

        {/* Investment Tab */}
        <div hidden={!isInvestmentProfileClicked}>
          <div className={userprofilestyles.firstNameLabel}>{investorProfile?.personalInformation?.firstName} {investorProfile?.personalInformation?.lastName}</div>
          <div className={userprofilestyles.lastNameLabel}></div>
          <div style={{ display: 'grid',
  gridTemplateColumns: 'repeat(3, minmax(310px, 0fr))', // Change 3 to the number of items per row you want
  gap: '50px',
  position: 'absolute' }}>

            {/* Render multiple buttons */}
            {investmentData &&
              investmentData.map((item, index) => {

                { console.log(investmentData) }
                { console.log("questionid", selectedOptions) }


                const selectedItem = selectedOptions[index];
                const selectedAnswer = selectedItem ? selectedItem.answer : item.answer;


                return (
                  <div

                    className={userprofilestyles.onboardingConfirmButton}
                    onClick={() => onPopupClick(item, item.questionID)}
                  >
                    <div
                      className={userprofilestyles.onboardingConfirmButtonChild}
                      onClick={() => handleOptionSelected(item.questionID, item)}
                    />
                    <div className={userprofilestyles.investedInAlternatives}>{item.shortName}</div>
                    <div className={userprofilestyles.no}>{selectedAnswer}</div>
                    <img
                      className={userprofilestyles.editFill0Wght400Grad0Opsz4Icon}
                      alt=""
                      src="/UserProfile/edit-fill0-wght400-grad0-opsz48.svg"
                    />
                  </div>
                );
              })}
          </div>
        </div>
     
        <div className={userprofilestyles.rectangleDiv} />
        <div className={userprofilestyles.userProfileChild} />
        {
           checkFeaturePermissions(RolesAndPermissions.INVESTOR_PROFILE,FeatureAccess.READ) &&
        <div className={userprofilestyles.tabButton1} onClick={onPersonalInfoClickedClick}>
          <div className={isPersonalInfoClicked ? userprofilestyles.tabButtonChild : userprofilestyles.tabButtonItem} />
          <div className={userprofilestyles.personalInformation}>Personal Information</div>
          <img
            className={userprofilestyles.iconMaterialInfo}
            alt=""
            src="/UserProfile/icon-materialinfo.svg"
          />
        </div>
}
{
          checkFeaturePermissions(RolesAndPermissions.INVESTOR_PROFILE,FeatureAccess.READ) && 
        <div className={userprofilestyles.tabButton2} onClick={onBankingInfoClick}>
          <div className={isBankingInfoClicked ? userprofilestyles.tabButtonChild : userprofilestyles.tabButtonItem} />
          <div className={userprofilestyles.bankingInformation}>Banking Information</div>
          <img
            className={userprofilestyles.accountBalanceFill0Wght400Icon}
            alt=""
            src="/UserProfile/account-balance-fill0-wght400-grad0-opsz48.svg"
          />
        </div>
}
{
          checkFeaturePermissions(RolesAndPermissions.INVESTOR_PROFILE,FeatureAccess.READ)  && 
        <div className={userprofilestyles.tabButton3} onClick={onInvestmentProfileClick}>
          <div className={isInvestmentProfileClicked ? userprofilestyles.tabButtonChild : userprofilestyles.tabButtonItem} />
          <div className={userprofilestyles.investmentProfile}>Investment Profile</div>
          <img
            className={userprofilestyles.financeChipFill0Wght400GraIcon}
            alt=""
            src="/UserProfile/finance-chip-fill0-wght400-grad0-opsz48-1.svg"
          />
        </div>
}
        <div className={userprofilestyles.investorProfile}>Investor Profile
          : {profileData?.firstName} {profileData?.lastName}
        </div>
       
       
        <div hidden={!isSubscriptionClick}>
          <div className={userprofilestyles.firstNameLabel}>{investorProfile?.personalInformation?.firstName} {investorProfile?.personalInformation?.lastName}</div>
        </div>
        <div className={userprofilestyles.tabButton4} onClick={onSubscriptionClick}>
        <div className={isSubscriptionClick ? userprofilestyles.tabButtonChild : userprofilestyles.tabButtonItem} />
        <div className={userprofilestyles.bankingInformation}>Subscription Details</div>
        </div>
        {/* POPUp */}
        {showPopup && (
          <UserProfilePopUp PopupQuestion={popupdetails} PopupOptions={popupoptions} onCancel={handlePopUpCancel} investment={investmentData}
            onOptionSelected={(index, option) => { handleOptionSelected(index, option) }}
          />
        )}
        {/* <NavigationBar isExpanded={isExpanded} setExpendState={setExpendState} /> */}
        <CircleIcon/>   
        <ToastContainer />
        <div hidden={!isSubscriptionClick}>
        <div className={userprofilestyles.subscription}>
       </div>
       </div>
      </div>
    </div>

    
        
  );
};

export default UserProfile;