import React, { useState } from 'react'
import { submitOfflineSubscription } from '../../../InvestorFundSubscription/Services/Services'
import { Button, Grid } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download';
import secureLocalStorage from 'react-secure-storage';
import EditIcon from '@mui/icons-material/Edit';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { ToastContainer, toast } from 'react-toastify';
import ActionButton from '../../../common/ActionButton/ActionButton';
import FileUploadButton from '../../../common/FileUploadButton/FileUploadButton';
import { useNavigate } from 'react-router-dom';
import { downloadsubscriptiontemplate } from '../../services/services';

const OfflineSubscriptionPage = (props) => {

  const investorId = secureLocalStorage.getItem("userId");
  const [loading, setLoading] = useState(false);
  const FundName = secureLocalStorage.getItem("FundName")
  const [file, setFile] = useState();
  const navigate = useNavigate();


  const BackToMarketPlace = () => {
    navigate("/marketplace");
    setFile();
  };
  const OnofflineProccessDownloadClick = () => {
    downloadFile()
  }

  const downloadFile = async () => {
    const data = await downloadsubscriptiontemplate(props.investorId, props.fundID, false)
    if (data.responseCode === 200) {
      const contentDisposition = data.headers['content-disposition'];

      // Extract the filename using regex
      const filenameMatch = contentDisposition && contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
      let filename = 'downloaded_file.docx'; // Fallback filename

      if (filenameMatch && filenameMatch[1]) {
        filename = filenameMatch[1].replace(/['"]/g, '');
      }

      // Convert response data to a Blob
      const blob = new Blob([data.responseData], { type: 'application/octet-stream' });

      // Create a download link
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;

      document.body.appendChild(link);
      link.click();

      // Cleanup
      link.remove();
      window.URL.revokeObjectURL(url);
    }
  };

  const downloadblankform = async () => {
    const data = await downloadsubscriptiontemplate(props.investorId, props.fundID, false)
    if (data.responseCode === 200) {
      const contentDisposition = data.headers['content-disposition'];

      // Extract the filename using regex
      const filenameMatch = contentDisposition && contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
      let filename = 'downloaded_file.docx'; // Fallback filename

      if (filenameMatch && filenameMatch[1]) {
        filename = filenameMatch[1].replace(/['"]/g, '');
      }

      // Convert response data to a Blob
      const blob = new Blob([data.responseData], { type: 'application/octet-stream' });
      // Create a download link
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;

      document.body.appendChild(link);
      link.click();

      // Cleanup
      link.remove();
      window.URL.revokeObjectURL(url);
    }

  };

  const handleRqFileUpload = (name, file) => {
    if (file instanceof File) {
      setFile(file);
    } else {
      setFile(null)
    }
  };


  const handleFormofflineSubmit = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('fileList', file);
    const requestedData = props?.investorSubscriptionDetails.questionBankAndSteps.userQuestionAndAnswers
      /* ?.filter((question) =>
        (question.userProvidedAnswer !== null && question.userProvidedAnswer !== "") ||
        (question.multiChoiceQuestionAnswerIDs !== null && question.multiChoiceQuestionAnswerIDs.length > 0) ||
        (question.questionAnswerID !== null && question.questionAnswerID !== 0)) */
      ?.map((item) => ({
        "UserProfileID": item?.subscriptionID === 0 ? 0 : item?.userProfileID,
        "QuestionBankID": item?.questionBankID,
        "QuestionName": item?.questionName,
        "UserID": investorId,
        "fundID": 0,
        "SubscriptionID": item?.subscriptionID || 0,
        "QuestionPatternTypeID": item?.questionPatternTypeID,
        "QuestionPatternType": item?.questionPatternType,
        "QuestionAnswerID": item?.questionAnswerID || 0,
        "UserProvidedAnswerinText": item?.userProvidedAnswer || "",
        "multiChoiceQuestionAnswerIDs": item?.multiChoiceQuestionAnswerIDs || [],
        /* "UploadedFile": item?.uploadedFile || '', */
      })) || [];

    formData.append('userProvidedAnswers', JSON.stringify(requestedData))
    const response = await submitOfflineSubscription(secureLocalStorage.getItem("FundId"), investorId, 0, true, formData);
    if (response.responseCode === 200) {
      toast.success("Offline Subscription successful.",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" })
      //setLoading(false);
      //props.getSubscriptionDetails()
      navigate("/marketplace");
    } else {
      toast.warning("Something Went Wrong , Please Try Again",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" })
      setLoading(false);
    }
  }

  const PDFWarningMessage = () => {
    toast.warning("Invalid file type. Please upload a PDF file.", { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" })
  }

  return (
    <div>
      <Grid>
        <Grid container xs={12} md={12} spacing={2} marginTop="20px" >
          <Grid item xs={12} md={3.5}
            onClick={OnofflineProccessDownloadClick}
          >
            <Button
              component="label"
              variant="contained"
              className='btn-primary'>
              <DownloadIcon />
              Download prefilled form
            </Button>
          </Grid>
          <Grid item xs={12} md={3.5}
            onClick={downloadblankform}
          >
            {/* <div className="option-box">
                 <div className="option"> <DownloadIcon className="color"/> <span className='icon-text'> <b>Download blank form</b></span></div>
               </div> */}
            <Button
              component="label"
              variant="contained"
              className='btn-primary'>
              <DownloadIcon />
              Download blank form
            </Button>
          </Grid>
          <Grid item xs={12} md={3.5}>
            <div>
              <FileUploadButton
                name="uploadSignedForm"
                label="Upload Signed Form"
                defaultFile={file}
                pdffileonly = {true}
                PDFWarningMessage = {() => PDFWarningMessage()}
                onChange={(name, value) => handleRqFileUpload(name, value)}
              />
            </div>

          </Grid>
        </Grid>
        <Grid container marginTop="20px">
          <div>
            <h1>Offline Subscription Instructions</h1>
            <p>The prefilled form will contain your personal, investment, and accreditation details as provided in the Subscription section of your investor profile.</p>

            <p>Before uploading your subscription agreement form, please ensure:</p>

            <ul>
              <li>The form is fully completed, and all required fields are signed and dated.</li>
              <li>Any additional supporting documentation is included, such as identity verification, accreditation evidence, etc. as required by the fund.</li>
              <li>You only upload the final PDF version of the signed form, not a blank template.</li>
            </ul>

            <p>The signed form and documents can be uploaded once completed. Your subscription will then be reviewed by the compliance team before the <br />investment process is finalized.</p>

            <p>Please reach out if you need any assistance with the offline subscription process. We are happy to help guide you through the requirements.</p>
          </div>
        </Grid>
        <Grid container marginTop="20px" justifyContent="flex-end">
          <ActionButton className='btn-primary' marginBottom="20px"
            component="label"
            variant="outlined"
            // style={{ width: '200px', backgroundColor: 'white', color: 'gray', marginRight: '10px' }}
            onClick={BackToMarketPlace}
            icon={<KeyboardBackspaceIcon />}
            label="Back To MarketPlace"
          >
          </ActionButton>
          <div className='ml2 resp'>
            <ActionButton
              icon={<TouchAppIcon />}
              label='Submit'
              variant="contained"
              className='btn-primary'
              loading={loading}
              onClick={handleFormofflineSubmit}
              disabled={file != null ? false : true}
            />
          </div>
        </Grid>
      </Grid>
      <Grid>
        {/*  <Grid container sx={{ justifyContent: "flex-end" }} >
                      <a href="#" download >
                        <Button className='download-btn'
                          component="label"
                          variant="contained"
                        >
                           <DownloadIcon />
                              Download File
                        </Button>
                      </a>
                    </Grid> */}
      </Grid>
      <ToastContainer />
    </div>
  )
}

export default OfflineSubscriptionPage