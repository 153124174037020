export const validationsConstants = {
  ONLY_ALPHABETS: 'This Field Only Accepts Alphabets',
  ONLY_NUMBERS: 'Only Numbers allowed',
  US_PHONE_NUMBER: 'Please enter valid Phone Number',
  MIN_CHARACTERS: 'Please provide a brief description (minimum 50 characters).',
  MAX_CHARACTERS: 'Exceeded Minimum Characters',
  BETWEEN_CHARACTERS: 'Characters should be between 5 to 10',
  PERCENTAGE: 'Please enter valid Percentage',
  EMAIL_ADDRESS: 'please enter valid email address',
  REQUIRED: 'This Field is required',
  URL: 'Please enter valid url',
  AGE_VALIDATION: 'The Age should be Above 18',
  ONLY_PDF_FILE: 'Please upload a PDF file',
  ONLY_CSV_FILE: 'Please upload a csv or excel file',
  NON_NEGATIVE: 'value should be greater than 0',
  GREATER_THAN_ZERO: 'Value must be greater than zero',
  TIN: 'Invalid Tax Identification Number(TIN)',
  PASSWORD_MATCH: "The passwords you entered don't match. Please try again.",
  ACCOUNT_NUMBER: "Please enter valid account number",
  FUTURE_DATE: "Date cannot be in the future.",
  DOB: "Please select a valid Date of Birth",
  PASSWORD: "Password must be 8-16 characters long and contain 3 out of 4 of the following one lowercase letter, one uppercase letter, one digit, and one special character.",
  USER_NAME: <ul>
    <li>Username must be at least 6 characters long.</li>
    <li>Username cannot exceed 35 characters.</li>
    <li>Username can only contain letters, numbers and one underscore (_)</li>
    <li>Username cannot contain more than 5 consecutive identical numbers</li>
    <li>Username Should contain at least one alphabet.</li>
    <li>Username Should not contain any Reserved or Foul Words</li>
  </ul>,
}

export const subscriptionValidationMessages = {
  COMMITED_CAPITAL_MINIMUM: 'The Commited Capital Must be greater than Minimum Investment Amount',
  COMMITED_CAPITAL_MAXIMUM: 'The Commited Capital Must be less than Target Raise',
}

export const subscriptioToastMessages = {
  SUBSCRIPTION_SUCESSFUL: 'Fund Subscribed Successfully',
  SUBSCRIPTION_FAILED: 'unable to subscribe the fund',
  INVESTOR_SIGN_FAILED: 'unable to navigate to e-sign document',
  STEPS_LOADING_FAILED: 'unable to load the subscription steps data',
  VALIDATION_CHECK: 'please verify mandatory fields',
  STOP_SUBSCRIPTION: 'You cannot proceed with Subscription as you have chosen this option',
  SAVE_CANCEL_WARNING: 'please save or cancel the data',
  STEP_DETAILS_SUCCESS: 'Step details updated successfully',
  STEP_DETAILS_FAILED: 'unable to update the step data'
}

export const userProfileToastMessages = {
  INFORMATION_SUCESSFUL: 'Details Updated Successfully',
  INFORMATION_FAILED: 'error updating details,please try again.',
  ATTEMPTS_REACHED: "You have exceeded your attempts. You can retry changing your email address after 24 hours.",
  EXCISTING_EMIAL: "Email already exists",
  OTP_ERROR: "The OTP entered is not correct.",
  PROFILE_IMG_ERROR: "Unable to Upload Profile Icon",
  VERIFICATION_SUCCESS: "Status updated successfully",
  VERIFICATION_FAILED: "Unable to update the status",
  KYC_SUCCESSFUL: "Details updated successfully",
  KYC_FAILED: "Unable to update the details",
}

export const InvestorReportingToastMessages = {
  UPLOAD_DOCUMENT: "Please upload the doucument",
}

export const VirtualDataRoomToastMessages = {
  NOT_FOUND : "No Data Found",
  ACTION_FAILED : 'unable to perform the action',
  FILE_SUCCESS_DOWNLOAD :'Successfully Downloaded !!',
  FILE_ALREADY_EXISTS :'You have selected the node which has the same file you are trying to upload',
  SHARING_FILE_ALREADY_EXISTS : 'You have selected the node which has the same file you are trying to',
  FOLDER_ALREADY_EXISTS : 'You have selected the node which has the same folder you are trying to',
  DOCUMENTTYPES_NOT_FOUND : 'Document types not found',
  NO_USERS_FOUND_TO_SHARE_FOLDER : 'No users found to share',
  FILE_DELETE_SUCCESS: "File Deleted Successfully",
  FILE_DELETE_FAILED: "unable to delete the file"
}