import React, { Component } from 'react';

class CustomCaptcha extends Component {
  constructor() {
    super();
    this.state = {
      num1: 0,
      num2: 0,
      userAnswer: '',
      correctAnswer: 0,
      result: '',
    };
  }

  componentDidMount() {
    this.generateCaptcha();
  }

  generateCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10) + 1;
    const num2 = Math.floor(Math.random() * 10) + 1;
    const correctAnswer = num1 + num2;

    this.setState({
      num1,
      num2,
      correctAnswer,
      userAnswer: '',
      result: '',
    });
  };

  validateCaptcha = () => {
    const { userAnswer, correctAnswer } = this.state;
    if (parseInt(userAnswer, 10) === correctAnswer) {
      this.setState({ result: 'CAPTCHA passed! Form submission allowed.' });
    } else {
      this.setState({ result: 'Incorrect CAPTCHA. Please try again.' });
      this.generateCaptcha(); // Reset the CAPTCHA
    }
  };

  handleAnswerChange = (e) => {
    this.setState({ userAnswer: e.target.value });
  };

  render() {
    const { num1, num2, userAnswer, result } = this.state;

    return (
      <div>
        <span>{num1} + {num2} = </span>
        <input type="text" value={userAnswer} onChange={this.handleAnswerChange} />
        <button onClick={this.validateCaptcha}>Submit</button>
        <p>{result}</p>
      </div>
    );
  }
}

export default CustomCaptcha;
