import axios from 'axios';
import resolve from '../../common/resolve';
import secureLocalStorage from 'react-secure-storage';
import { getAPIResponse } from '../../../utils/common';
import axiosApiCall from '../../Services/axiosApiCall';


export async function getportfoliosDetails(fundID) {
    return await axiosApiCall.get(`/v1/PortfolioCompany/GetPortfolioCompaniesByFund?fundID=${fundID}`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function getCompanybyID(companyID) {
    return await axiosApiCall.get(`/v1/PortfolioCompany/GetPortfolioCompaniesByCompany?companyID=${companyID}`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function getCompanyDetailsbyPCmanagerID(companyID) {
  
}

export async function GetCompanyFitlerDetails() {
    return await axiosApiCall.get(`/v1/PortfolioCompany/GetCompanyFitlerDetails`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function UpdateCompanyProfileDetails(data) {
    
  
}

export async function updateLeadershipTeamDetails(data) { } 

export async function UpdateLegalCounselDetails(data) { }

export async function UpdateAccountingDetails(data) {}

export async function UpdateBoardMembersDetails(data) {
    }
   