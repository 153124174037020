import React, { useRef, useState } from "react";
import styled from "styled-components";
import SiteHeader from "../components/Site/SiteHeader";

function MainSiteContainer({ children }) {
  return (
    <>
      <SiteHeader />
      {children}
      {/* <SiteFooter /> */}
    </>
  );
}

const StyledContainer = styled.div`
  background-color: "#000000";
  height: 100vh;
`;

export default MainSiteContainer;
