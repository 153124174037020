import React from "react";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { Box } from "@mui/system";
import { getrootBackgroundColorConfig } from "../../../state/slices/colorConfigSlice";
import { useSelector } from "react-redux";
import { BoxbackgroundWhite } from "../../CommonCss";
import AIPDataGrid from "../../common/DataGrid/AIPDataGrid";
import { HeaderTemplates, PageHeader } from "../../common/PageHeader/PageHeader";
import secureLocalStorage from "react-secure-storage";
import { useEffect } from "react";
import { useState } from "react";


function PartnersCapitalAccountReports() {
    const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);
    const [capitalData, setCapitalData] = useState(null);

    const Capitalcolumns = [
        {
            field: "emptyHeader",
            headerName: "",
            width: "300",
            sortable: false,
        },
        {
            field: "QuartertoDate",
            headerName: "Quarter to Date",
            width: "180",
        },
        {
            field: "YeartoDate",
            headerName: "Year to Date",
            width: "180",
        },
        {
            field: "ExceptiontoDate",
            headerName: "Exception to Date",
            width: "150",
        },
    ];

    const Capitalrows = [
        {
          id: 1,
          emptyHeader: "00",
          QuartertoDate: "100",
          YeartoDate: "350",
          ExceptiontoDate: '100',
    
        },
        {
          id: 2,
          emptyHeader: "00",
          QuartertoDate: "100",
          YeartoDate: "350",
          ExceptiontoDate: '100',
        },
        {
          id: 3,
          emptyHeader: "00",
          QuartertoDate: "100",
          YeartoDate: "350",
          ExceptiontoDate: '100',
        },
        {
            id: 4,
            emptyHeader: "00",
            QuartertoDate: "100",
            YeartoDate: "350",
            ExceptiontoDate: '100',
        },
        {
            id: 5,
            emptyHeader: "00",
            QuartertoDate: "100",
            YeartoDate: "350",
            ExceptiontoDate: '100',
        },
        {
          id: 6,
          emptyHeader: "00",
          QuartertoDate: "100",
          YeartoDate: "350",
          ExceptiontoDate: '100',
        },
  
     ];

      const fundId = secureLocalStorage.getItem("FundId");
  

  const [capital, setCapital] = useState([]);

  useEffect(() => {
  }, []);


    return (
        <div style={{ backgroundColor: rootBackColorConfig.color }} className="page">
            <PageHeader
                template={HeaderTemplates.Standard}
                fundName={secureLocalStorage.getItem("FundName")}
                headerImage={secureLocalStorage.getItem("selectedFund")?.IconImage}
                customBackgroundImage={secureLocalStorage.getItem("selectedFund")?.BGImage ? secureLocalStorage.getItem("selectedFund")?.BGImage : '/DefaultBackgroundImage.png'}

            />
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sx={BoxbackgroundWhite} marginTop={'10px'} width={'95%'} marginLeft={'30px'} spacing={2}>
                        <Grid container direction="column" spacing={1} fontSize={'20px'} fontWeight={'bold'}>
                            <Grid item style={{ textAlign: 'center' }}>Investor Name</Grid>
                            <Grid item style={{ textAlign: 'center' }}>Vertice Buyout Fund I</Grid>
                            <Grid item style={{ textAlign: 'center' }}>Unaudited Capital Account statement</Grid>
                            <Grid item style={{ textAlign: 'center' }}>For The Period Ended December 31, 2018</Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container direction="column" spacing={1} marginTop={'50px'}>
                                <Grid item style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>Big Bear Retirement Fund</Grid>
                                <Grid item style={{ textAlign: 'center', fontSize: '20px', marginTop: '20px' }}>Commitment Summary</Grid>
                                <Grid container justifyContent="center">
                                <Grid item xs={12} maxWidth="400px"> {/* Adjust maxWidth as needed */}
                                    <Grid container direction="column" spacing={2}>
                                    <Grid item style={{ textAlign: 'left' }}>Commitment: 0</Grid>
                                    <Grid item style={{ textAlign: 'left' }}>Commitment Called: 0</Grid>
                                    <Grid item style={{ textAlign: 'left' }}>Recallable Distributions: 0</Grid>
                                    <Grid item style={{ textAlign: 'left' }}>Unfunded Commitment: 0</Grid>
                                    <Grid item style={{ textAlign: 'left' }}>Percentage of Partnership: 0</Grid>
                                    </Grid>
                                </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} sx={{ fontSize: '24px', fontWeight: 'bold', marginTop: '30px' ,marginLeft: '40px' }}>
                            Statement Of Partner's Capital
                        </Grid>
                        <Grid container spacing={2} marginTop= {'10px'}  marginLeft={'200px'} >
                            <Grid item xs={12}>
                                <AIPDataGrid
                                    columns={Capitalcolumns}
                                    rows={Capitalrows}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Box>
        </div>
    )
};

export default PartnersCapitalAccountReports