import { useState } from 'react';
/* Need to Import Action button */
import ActionButton from '../../../../common/ActionButton/ActionButton';
import '../FirmDetails.css';
import Text from '../../../../common/Text/Text';
import TextInput from '../../../../common/TextInput/TextInput';
import { TopNavBar } from '../../../../common/navigation-bar/TopNavBar';
import secureLocalStorage from 'react-secure-storage';
import { ToastContainer, toast } from 'react-toastify';
import { foulWords,reservedWords } from '../../../foulwords';
import { checkUserNameExcists } from '../../../services/RegistrationProfilingService';
import AppTextInput from '../../../../common/TextInput/AppTextField';
import FirstComponent from '../../../../common/DatePicker/DatePicker';
import FileUploadButton from '../../../../common/FileUploadButton/FileUploadButton';
import DatePickerField from '../../../../common/DatePicker/DatePickerField';
import moment from "moment/moment";
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { msalConfig } from '../../../../AppAuthentication/authConfig';
import { PublicClientApplication } from '@azure/msal-browser';
import { DatePickerInputTypes, FieldVariants, textFormatTypeEnum, validationMessages, validationsTypeEnum } from '../../../../common/TextInput/appInputenum';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { validationsConstants } from '../../../../../utils/AppConstants';
const FirmOnboardingProfile = (props) => {

  //#region props
  const { handleNavigation , firmUserDetails ,setFirmUserDetails} = props;

  //#region variables
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [saveAndnextClicked, setSaveAndNextClicked] = useState(false);
  const msalInstance = new PublicClientApplication(msalConfig);
  
  //#region handleChange Events
  const handleChange = (field, value) => {
    if(field == "dateOfBirth" && 
       dayjs(moment(value)).format("YYYY-MM-DD hh:mm:ss") == '1970-01-01 05:30:00')
    {
        value = '1900-01-01T00:00:00';
    }
      setFirmUserDetails({
        ...firmUserDetails,
        [field]: value
      });
  }

  const handleFileUpload = (name, file) => {
    /*  ; */
    if (file) {
        const formData = new FormData();
        formData.append(name, file, file.name);
        handleChange(name, file);
    }
}

  //#region api get calls
  const checkUserName = async () => {
    setSaveAndNextClicked(true)
    setLoading(true);
    const data = await checkUserNameExcists(firmUserDetails.userName);
    setLoading(false);
    if (data?.responseCode == 200) {
      toast.error("UserName already exists", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    }
    else {
      handleNavigation(true);
    }
  }

  //#region validations
  const [noofValidFields, setNoofValidFields] = useState({ validfields: 0 });

  const [checkIfValid, setcheckIfValid] = useState(false);

  let count = 0; // to check 
  const checknoofValidFields = (isValid, name, defaultValue) => {
        count = ++count;
        const TotalFiledsToValidate = 7;
        noofValidFields['validfields'] = isValid ? noofValidFields['validfields'] + 1 : noofValidFields['validfields'];
        if (noofValidFields['validfields'] === TotalFiledsToValidate) {
            noofValidFields['validfields'] = 0;
            if(count === TotalFiledsToValidate){
              checkUserName();
              count = 0;
            }
        }
    };

  const handleNext = () => {
        setcheckIfValid(true);
        noofValidFields['validfields'] = 0;
  }

  //#region click events
  const onCancel = () => {
    msalInstance.logoutRedirect({ postLogoutRedirectUri: "/?login_page=true" });
  }

  return (
    <div>
       <TopNavBar fullname={secureLocalStorage.getItem("fullName")} hideMenuButton={true} />
        <div className='fmonboarding onboard-resp'>
          <h1 className='text-center'>
            Welcome. Lets get started!
          </h1>
          <h6 className='text-center'>
            We just need a little information, so that we can customize your experience.
          </h6>
          <div className='whiteCard firm-onboarding-wrapper'>
            <div className='firmprofilerow'>
                <div>
                  <Text label="Your Information" />
                </div>
            </div>
            <div className='firmprofilerow margin-top-10 space-between'>
                  <div></div>
                  <FileUploadButton
                      name="profileIcon"
                      label="Upload Profile Picture"
                      defaultFile={firmUserDetails.profileIcon}
                      isImage={true}
                      fromWhiteLabeling={true}
                      onChange={(name, value) => handleFileUpload(name, value)} 
                      />
            </div>
            <div className='firmprofilerow'>
                 <AppTextInput
                      name='tenantName'
                      label="Entity Name"
                      defaultValue={firmUserDetails?.tenantName}
                      disabled={true}
                      onChange={(name, value) => handleChange(name, value)}
                      validateField={false}/>
            </div>
            <div className='firmprofilerow'>
                <div className='firmOnboardingchild'>
                    <AppTextInput
                        name='firstName'   
                        label="First Name"
                        defaultValue={firmUserDetails?.firstName}
                        onChange={(name, value) => handleChange(name, value)}
                        validateField={true}
                        validationType={validationsTypeEnum.ONLY_ALPHABETS}
                        validationMessage={validationMessages.ONLY_ALPHABETS}
                        checkIfValid={checkIfValid}
                        checknoofValidFields={checknoofValidFields}
                        setcheckIfValid={setcheckIfValid} />
                </div>
                <div className='firmOnboardingchild'>
                    <AppTextInput
                        name='lastName'   
                        label="Last Name"
                        defaultValue={firmUserDetails?.lastName}
                        onChange={(name, value) => handleChange(name, value)}
                        validateField={true}
                        validationType={validationsTypeEnum.ONLY_ALPHABETS}
                        validationMessage={validationMessages.ONLY_ALPHABETS}
                        checkIfValid={checkIfValid}
                        checknoofValidFields={checknoofValidFields}
                        setcheckIfValid={setcheckIfValid} />
                </div>
            </div>
            <div className='firmprofilerow'>
                <div className='firmOnboardingchild'>
                    <AppTextInput
                        name='emailAddress'   
                        label="Work Email"
                        defaultValue={firmUserDetails?.emailAddress}
                        onChange={(name, value) => handleChange(name, value)}
                        validateField={true}
                        validationType={validationsTypeEnum.EMAIL_ADDRESS}
                        validationMessage={validationMessages.EMAIL_ADDRESS}
                        disabled={true}
                        checkIfValid={checkIfValid}
                        checknoofValidFields={checknoofValidFields}
                        setcheckIfValid={setcheckIfValid} />
                </div>
                <div className='firmOnboardingchild'>
                    <AppTextInput
                        name='userName'   
                        label="User Name"
                        defaultValue={firmUserDetails?.userName}
                        onChange={(name, value) => handleChange(name, value)}
                        validateField={true}
                        validationType={firmUserDetails?.userName.length > 0 ? validationsTypeEnum.USER_NAME : validationsTypeEnum.REQUIRED}
                        validationMessage={firmUserDetails?.userName.length > 0 ? validationMessages.USER_NAME : validationMessages.REQUIRED}
                        checkIfValid={checkIfValid}
                        checknoofValidFields={checknoofValidFields}
                        setcheckIfValid={setcheckIfValid} />
                </div>
            </div>
            <div className='firmprofilerow'>
                <div className='firmOnboardingchild'>
                    <DatePickerField 
                            type={DatePickerInputTypes.DATE}
                            name='dateOfBirth'   
                            label="Date of Birth"
                            isActive={true}
                            variant={FieldVariants.STANDARD}
                            defaultValue={firmUserDetails?.dateOfBirth !==  '1900-01-01T00:00:00' ? firmUserDetails?.dateOfBirth : ''}
                            onChange={(name, value) => handleChange(name, value)}
                            validateField={true}
                            validationType={validationsTypeEnum.FUTURE_VALIDATION}
                            validationMessage={validationsConstants.FUTURE_DATE}
                            checkIfValid={checkIfValid}
                            maxDate={dayjs().subtract(0, 'day')}
                            checknoofValidFields={checknoofValidFields}
                            setcheckIfValid={setcheckIfValid} />
                </div>
                <div className='firmOnboardingchild'>
                    <AppTextInput
                        textFormatType={textFormatTypeEnum.US_PHONE_NUMBER}
                        name='phoneNo'   
                        label="Phone Number"
                        defaultValue={firmUserDetails?.phoneNo}
                        onChange={(name, value) => handleChange(name, value)}
                        validateField={true}
                        validationType={validationsTypeEnum.US_PHONE_NUMBER}
                        validationMessage={validationMessages.US_PHONE_NUMBER}
                        checkIfValid={checkIfValid}
                        checknoofValidFields={checknoofValidFields}
                        setcheckIfValid={setcheckIfValid} />
                </div>
            </div>
            <div className='firmprofilerow'>
                    <AppTextInput
                        name='briefDescription'   
                        label="Brief Description"
                        variant='outlined'
                        multiline={true}
                        rows={3}
                        defaultValue={firmUserDetails?.briefDescription}
                        validationType={validationsTypeEnum.REQUIRED}
                        validationMessage={validationMessages.REQUIRED}
                        checkIfValid={checkIfValid}
                        charactersMaxLength={4000}
                        checknoofValidFields={checknoofValidFields}
                        setcheckIfValid={setcheckIfValid}
                        onChange={(name, value) => handleChange(name, value)}
                        validateField={true} />
            </div>
            <div className='firmprofilerow space-between'>
                <div></div>
                <div className='space-between'>
                  <div>
                    <ActionButton
                         icon={<CancelIcon/>}
                            label="Cancel"
                            variant="outlined" 
                            className="btn-primary" 
                            onClick={() => onCancel()}
                            />
                  </div>
                  <div className='margin-left-10'>
                    <ActionButton
                       icon={<SaveIcon/>} 
                        label="Next"
                        variant="contained" 
                        className="btn-primary"
                        loading={loading}
                        onClick={() => handleNext()}
                        />
                  </div>
                </div>
            </div>
          </div>
        </div>
        <ToastContainer />
    </div>
  )
}
export default FirmOnboardingProfile;