import React from "react";
import Grid from "@mui/system/Unstable_Grid/Grid";
import secureLocalStorage from "react-secure-storage";
import { Box } from "@mui/system";
import { getrootBackgroundColorConfig } from "../../../state/slices/colorConfigSlice";
import { useSelector } from "react-redux";
import { BoxbackgroundWhite } from "../../CommonCss";
import AIPDataGrid from "../../common/DataGrid/AIPDataGrid";
import { HeaderTemplates, PageHeader } from "../../common/PageHeader/PageHeader";
import { useState } from "react";
import { useEffect } from "react";
import { CurrencyFormatter } from "../../../utils/Formater";
import moment from "moment";


function BalanceSheetReports() {
  const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);
  const [capitalData, setCapitalData] = useState(null);

  const fundId = secureLocalStorage.getItem("FundId");
  
  const fetchDataForSelectedFund = async (fundName) => {
   
  };
  
  const handleFundNameClick = (fundName, fundId) => {
   fetchDataForSelectedFund(fundId);
  };

  const [assets, setAssets] = useState([]);
  useEffect(() => {
    
  }, []);
  
  const assetsWithIds = assets.map((row) => ({
    ...row,
    id: row.uniqueID.toString(), 
    emptyHeader: row.entityTitle,
    emptyHeader1: CurrencyFormatter(row.cpeValue),
    emptyHeader2: CurrencyFormatter(row.pyeValue),
  }));
  const [liabilities, setLiabilities] = useState([]);
  useEffect(() => {
    
  }, []);



  const Balancescolumns = [
    {
      field: "emptyHeader",
      headerName: "",
      width: "350",
      sortable: false,
    },
    {
      field: "emptyHeader1",
      headerName: "",
      width: "220",
      sortable: false,
    },
    {
      field: "emptyHeader2",
      headerName: "",
      width: "200",
      sortable: false,
    },

  ];

  const Balancesrows = [
    {
      id: 1,
      emptyHeader: "00",
      emptyHeader1: "100",
      emptyHeader2: "350",

    },
    {
      id: 2,
      emptyHeader: "00",
      emptyHeader1: "100",
      emptyHeader2: "350",
    },
    {
      id: 3,
      emptyHeader: "00",
      emptyHeader1: "100",
      emptyHeader2: "350",
    },
    {
      id: 4,
      emptyHeader: "00",
      emptyHeader1: "100",
      emptyHeader2: "350",
    },
    {
      id: 5,
      emptyHeader: "00",
      emptyHeader1: "100",
      emptyHeader2: "350",
    },
    {
      id: 6,
      emptyHeader: "00",
      emptyHeader1: "100",
      emptyHeader2: "350",
    },



  ];



  return (
    <div style={{ backgroundColor: rootBackColorConfig.color }} className="page">
      <PageHeader
        template={HeaderTemplates.Standard}
        fundName={secureLocalStorage.getItem("FundName")}
        headerImage={secureLocalStorage.getItem("selectedFund")?.IconImage}
        customBackgroundImage={secureLocalStorage.getItem("selectedFund")?.BGImage ? secureLocalStorage.getItem("selectedFund")?.BGImage : '/DefaultBackgroundImage.png'}

      />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item container xs={12} marginTop={'10px'} width={'95%'} marginLeft={'30px'} spacing={2} sx={BoxbackgroundWhite}>
            <Grid item xs={10}>
              QUARTERLY REPORTING STANDARDS
            </Grid>
            <Grid item xs={2}>
              SAMPLE REPORTS
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="column" spacing={1} marginTop={'50px'}>
                <Grid item style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>Balance Sheet</Grid>
                <Grid item>
                  <Grid container direction="column" spacing={2}>
                    <Grid item style={{ textAlign: 'center' }}>Report Title:0</Grid>
                    <Grid item style={{ textAlign: 'center' }}>Report Date:0 {capitalData && capitalData.reportDate}</Grid>
                    <Grid item style={{ textAlign: 'center' }}>Is Audited:0</Grid>
                    <Grid container xs={12} justifyContent="flex-end" spaing={2}>
                      <Grid item >Current Period End:000-99-45</Grid>
                      <Grid item >Prior Year End Audited:000-99-45</Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} sx={{ fontSize: '24px', fontWeight: 'bold', marginTop: '30px', marginLeft: '30px' }}>
              Assets
            </Grid>
            <Grid container spacing={2} >
              <Grid item xs={12} marginLeft={'200px'} >
                <AIPDataGrid
                  columns={Balancescolumns}
                  rows={Balancesrows}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} sx={{ fontSize: '24px', fontWeight: 'bold', marginTop: '30px', marginLeft: '30px' }}>
              LIABILITIES AND PARTNERS CAPITAL
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} marginLeft={'200px'}>
                <AIPDataGrid
                  columns={Balancescolumns}
                  rows={Balancesrows}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
};

export default BalanceSheetReports