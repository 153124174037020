import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import MultipleSelectDropdown from '../Multi-Select-Dropdown/MultipleSelectDropdown';
import json from '../commonJsonData.json'

import { useState } from 'react';
import { useEffect } from 'react';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import './styles.css'
import NewsDialog from '../../FundNews/Components/NewsDialog';

import RemovePopup from '../../marketplace/components/RemovePopup';

import ContextMenu from '../ContextMenu/ContextMenu';
import ControlPointIcon from '@mui/icons-material/ControlPoint';

import { getRoles, getSuggestedArticlesByUserId } from '../../FundManagerDashboard/Services/FundNewsService';

import { Navigate, useNavigate } from 'react-router-dom';

import { getAutoCurationDetails } from '../../FundNews/Services/DropDownService';
import CircleIcon from '../../chatbot/Icon';
import InputMultiSelect from '../Multi-Select-Dropdown/InputMultiSelect';
import secureLocalStorage from 'react-secure-storage';
import { AppDashboards, AppRoles } from '../../../utils/enum';
import { checkFeaturePermissions } from '../../../utils/common';
import { FeatureAccess, RolesAndPermissions } from '../../../utils/enum';
import { MicNone } from '@mui/icons-material';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import ArticleIcon from '@mui/icons-material/Article';
import { FieldVariants } from '../TextInput/appInputenum';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';

const FundNews = (props) => {
  
  //#region props
  const { FundNewsData } = props;
  
  //#region click events
  const navigate = useNavigate();
  const [fundNews,setFundNews] = useState();
  const [AutoCurationData, setAutoCurationData] = useState();
  const [selectedFilters,setSelectedFilteredNews] = useState(null);
  const [newsData, setNewsData] = useState(null);
  const [MenuItemsTop, setMenuItemsTop] = useState([]);
  const MenuItemsBottom = []

  //#region change events
  const handleChangeDropdown = (ev) => {
    const { name,value } = ev.target;
    setSelectedFilteredNews(value)
  }

  //#region click events
  const openDialog = (item) => {
    /* console.clear(); */
    /*   */

    if (item.uploadFile == true && item.addLink == false) {
      /* setSelectedItem(item); */
      window.location.href = item.articleUploadFileName;
      /* onDownloadFile(item); */
    } else {

      const url = item.articleLinkName.startsWith('http://') || item.articleLinkName.startsWith('https://')
        ? item.articleLinkName
        : `http://${item.articleLinkName}`;

      const screenWidth = window.screen.availWidth;
      const screenHeight = window.screen.availHeight;

      const windowWidth = 1000;
      const windowHeight = 600;

      const left = (screenWidth - windowWidth) / 2;
      const top = (screenHeight - windowHeight) / 2;

      const newWindow = window.open(
        url,
        "PopupWindow",
        `width=${windowWidth},height=${windowHeight},left=${left},top=${top}`
      );

      if (newWindow) {
        newWindow.opener = null;
      }

    }
  };

  const onDownloadFile = async (file) => {
    try {
      const response = await fetch(file?.articleUploadFileName);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const fileExtension = file.articleUploadFileName.split('.').pop();
      const a = document.createElement('a');
      a.href = url;
      a.download = `${file.articleTitle}.${fileExtension}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      toast.warning("unable to downlaod the file",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
  };

  //#region api get calls
  const getFundNews = async () => {
    const userId = secureLocalStorage.getItem("userId")
    const data = await getSuggestedArticlesByUserId(userId);
    if (data.responseCode === 200) {
      setFundNews(data.responseData);
    }
    else {
      //console.log('unable to fetch data)
    }
  }
  

  //#region useeffect
  useEffect(() => {
    if (secureLocalStorage.getItem("userrole") !== AppDashboards.INV_DASHBOARD) {
      setMenuItemsTop([
        {
          id: 7,
          label: "Add Readings",
          icon: <ControlPointIcon />,
          onClick: () => {
            navigate("/suggested-readings");
          }
        }
      ]);
    } else {
      setMenuItemsTop([]);
    }
  }, []);

  useEffect(()=>{
    if(FundNewsData){
      setFundNews(FundNewsData)
    }else{
      // getFundNews();
    }
  },[FundNewsData])


  //#region stylings
  const Item = styled(Paper)(({ theme }) => ({
    /* backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff", */
    ...theme.typography.body2,
    padding: theme.spacing(1),

  }));

  //#region return
  return (
    <div>
        <div className='space-between align-item-center'>
          <h6>Suggested Readings</h6>
          <div className='child-row-margin-5'>
             {/*  <div className='width-150'>
                    <FormControl fullWidth>
                      <InputLabel sx={{ marginTop: '-7px'}}>Topics</InputLabel>
                      <Select
                          multiple
                          name='selectedYear'
                          label="Topics"
                          size='small'
                          fullWidth
                          variant={FieldVariants?.OUTLINED}
                          value={selectedFilters}
                          onChange={handleChangeDropdown}>
                          {AutoCurationData?.map((item) => (
                              <MenuItem key={item.value} value={item.value}>
                                  {item.label}
                              </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
              </div> */}
              {
                secureLocalStorage.getItem("userrole") !== AppDashboards.INV_DASHBOARD && 
                <div>
                    <ContextMenu MenuItemsTop={MenuItemsTop} MenuItemsBottom={MenuItemsBottom} />
                </div> 
              }
          </div>
        </div>
        <Grid container spacing={1} style={{ boxShadow: 'none'}}>
            <Item style={{ boxShadow: 'none', width: '100%',marginTop: '14px' }}>
              <Divider variant="inset" style={{margin: '0px'}}/>
              <div className='suggestedReadings'>
              {checkFeaturePermissions(RolesAndPermissions.SUGGESTED_READINGS, FeatureAccess.READ) ? (
                 fundNews && fundNews.length > 0 ? (
                <List>
                  {fundNews?.map((item, index) => (
                    <div key={index} onClick={() =>  openDialog(item)} style={{ cursor: 'pointer' }}>
                      <ListItem className='suggestedItemsDash' alignItems="flex-start">
                        <ListItemAvatar>
                          {item?.uploadFile === false ? 
                              <InsertLinkIcon sx={{ width: '24px', height: '24px' }}/> : 
                              <ArticleIcon sx={{ width: '24px', height: '24px' }}/>} 
                        </ListItemAvatar>
                        <ListItemText
                          primary={item?.articleTitle}
                          secondary={<React.Fragment>
                            <Typography
                              sx={{ display: 'inline' }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              {item?.articleSubtitle}
                            </Typography>
                            <br />
                            <Typography
                              sx={{ display: 'inline' }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              By {item?.articleAuthor}
                            </Typography>
                          </React.Fragment>} />
                      </ListItem>
                     {/*  {index < data.length - 1} */}
                    </div>
                  ))}
                </List>
                  ) : (
                    <p style={{color: 'gray' }}>No suggestions available</p>
                    )
                    ) : (
                  <p style={{marginTop:'5px', color:'red'}}>   <LockPersonIcon style={{ marginLeft: '5px' }} />
                    You do not have the permission to view the Suggested Readings
                  </p>
                )
}
              </div>
            
            </Item>
        </Grid>
      <CircleIcon />
    </div>

  );
}


export default FundNews;