import React, { useEffect, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

//#this componetn will display irr and benchmark chart
function LinearInterpolationGraph(props) {

  const { selectedYear,selectedFund } = props;

  const [jsonData, setJsonData] = useState([]);

  const generateRandomValue = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

  const generateRandomData = () => {
    const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
    const currentYear = new Date().getFullYear();
    const startYear = selectedYear || currentYear;

    let data = [];
    for (let i = 0; i < 12; i++) { // Generate data for 12 quarters (3 years)
      const year = startYear + Math.floor(i / 4);
      const quarter = quarters[i % 4];
      const x = `${quarter} ${year}`;
      const y1 = generateRandomValue(10, 30);
      const y2 = generateRandomValue(10, 30);
      data.push({ x, y1, y2 });
    }

    return data;
  };

  useEffect(() => {
    setJsonData(generateRandomData());
  }, [selectedYear,selectedFund]);
  

  return (
    <div style={{ width: '100%', height: '480px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={jsonData} margin={{ top: 20, right: 30, left: 0, bottom: 40 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="x"
            tick={{ fontSize: 13 }}
          />
          <YAxis
            tick={{ fontSize: 12 }}
            domain={[0, 30]}
          />
          <Tooltip />
          <Legend
            iconType="circle" // Use circle icons for the legend items
            iconSize={10} // Adjust the size of the legend icons
            wrapperStyle={{
              backgroundColor: 'rgba(255, 255, 255, 0.8)', // Legend background color
              borderRadius: '10px', // Adjust the border radius to make it round
              padding: '10px', // Adjust padding as needed
              margin:'10px'
            }}
          />
          <Line type="linear" dataKey="y1" stroke="#add8e6" name="My Fund" strokeWidth={4}  dot={{ r: 6}} />
          <Line type="linear" dataKey="y2" stroke="#005a8d" name="The Benchmark" strokeWidth={4} dot={{ r: 6 }} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default LinearInterpolationGraph;
