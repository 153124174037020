// HelpAndSupportFAQ.jsx
import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Container, Box, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const faqs = [
    {
        question: "What is a fund?",
        answer: "A fund is a pool of money collected from many investors to invest in securities, bonds, money market instruments, and other assets."
    },
    {
        question: "What Is an Alternative Investment?",
        answer: "An alternative investment is an investment in any asset class excluding stocks, bonds, and cash."
    },
    {
        question: "Who can invest in an AIF?",
        answer: "AIFs can be invested in by accredited investors, including high-net-worth individuals and institutions."
    },
    {
        question: "What is the Risk vs. Return in Alternative Investments?",
        answer: "Alternative investments can offer higher returns but typically come with higher risks compared to traditional investments."
    },
    {
        question: "What is the role of the fund manager in an AIF?",
        answer: "The fund manager is responsible for making investment decisions and managing the investment portfolio of the AIF."
    },
    {
        question: "What are the Pros and Cons of Alternative Investment?",
        answer: "Pros include diversification and potential high returns. Cons include higher risk, less liquidity, and less regulatory oversight."
    }
];


const HelpAndSupportFAQ = (props) => {

  //#region props
  const {setActiveBodySection } = props

  //#region click events
  const handleBackToList = () => {
    setActiveBodySection(null)
  };  

  //#rgeion return
    return (
        <div className='help-Faqs'>
            <Box>
                <Button 
                 onClick={() => handleBackToList()}> 
                 <ArrowBackIcon  className='helpbackicon'/>
                  Go Back to Main Page 
                </Button>
                <h2 className='margin-top-10'> FAQs</h2>
                {faqs.map((faq, index) => (
                    <Accordion key={index} sx={{ mb: 2 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} className='help-accordian'>
                            <h6>{faq.question}</h6>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{faq.answer}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </div>
    );
};

export default HelpAndSupportFAQ;

