import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';

const CheckBoxRenderingDemo = (props) => {

  const {handleChange,name,value,label,params,isdisabled,description,readOnly=false} = props;

  const [checkedState, setCheckedState] = useState(value);

  const HandleCheckboxChange = () => {
    // Toggle the checked state for the specific row
    setCheckedState(!checkedState);
    if(params){
       
      handleChange(params,name,!checkedState);
    }
    else{
      handleChange(name,!checkedState);
    }
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          checked={checkedState}
          onChange={HandleCheckboxChange}
          disabled={isdisabled}
          readOnly={readOnly}
        />
        <div>
          <div>{label}</div>
          {
            description && description !== ""? 
            <div className='optiondescription'>
              {description}
            </div> : null
          }
          </div>
      </div>
    </>
  
    
  );
};

export default CheckBoxRenderingDemo;
