import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import UploadIcon from '@mui/icons-material/CloudUploadOutlined';
import './styles.css';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { propTypes } from 'react-bootstrap/esm/Image';

const MultipleFileUploadButton = (props) => {
    const {
        onChange,
        disabled,
        label,
        name,
        defaultFile,
        clearFile,
        isImage,
        fromWhiteLabeling
    } = props;

    const [file, setFile] = useState(null);

    const handleFileChange = (ev) => {
        const { name, files } = ev.target;
        if (!isImage) {
            setFile(files[0]);  // Create a preview URL for the uploaded file
        }
        else {
            setFile(URL.createObjectURL(files[0]));
        }
        onChange(name, files[0]);
        setFile(null);
    };

    const handleClearFile = () => {
        setFile(null);
        onChange(name, null);
    }

    useEffect(() => {
        if (clearFile) {
            setFile(null);
        } else if (defaultFile) {
            if (isImage) {
                if (typeof defaultFile === "object") {
                    setFile(URL.createObjectURL(defaultFile))
                }
                else {
                    setFile(defaultFile)
                }
            }
            else {
                setFile(defaultFile);
            }
        }
    }, [clearFile, defaultFile]);

    const handleWrapperClick = () => {
        !file && document.getElementById(`${name}_fileupload`).click();
    };

    return (
        <div
            className={`fileUploadButton ${disabled && 'fileInputDisabled'}`}
            onClick={handleWrapperClick}
        >
                    <>
                        <UploadIcon />
                        <input
                            className='inputfile'
                            id={`${name}_fileupload`}
                            type="file"
                            name={name}
                            onChange={handleFileChange}
                            hidden
                        />
                        <label>{label || 'Upload Document'}</label>
                    </>
                
        </div>
    );
};

MultipleFileUploadButton.propTypes = {
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    defaultFile: PropTypes.string,
    clearFile: PropTypes.bool,
    isImage: propTypes.bool,
    fromWhiteLabeling: propTypes.bool
};

MultipleFileUploadButton.defaultProps = {
    disabled: false,
    label: "upload",
    defaultFile: null,
    clearFile: false,
    isImage: false,
    fromWhiteLabeling: false
};

export default MultipleFileUploadButton;
