import {useCallback,useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Grid } from "@mui/material";
import OnBoardingStepContainer from "./OnBoardingStepContainer";
import CircleIcon from "../../chatbot/Icon";
import secureLocalStorage from "react-secure-storage";
import { getCompanybyID } from "../Services/CompaniesDetails";
import { PageHeader } from "../../common/PageHeader/PageHeader";
import { HeaderTemplates } from "../../common/PageHeader/PageHeader";
const PortifolioOnboarding= () => {

  const navigate = useNavigate();
  const userRole = secureLocalStorage.getItem("userrole");
  const isPortfolioCompany = userRole === "Portfolio Company";  
  const onBackArrowButtonClick = useCallback(() => {
    const userRole = secureLocalStorage.getItem("userrole");
  
    if (userRole === "Portfolio Company") {
      navigate("/portfolio-company-overview");
    } else {
      navigate("/portfolio-page");
    }
  }, [navigate]);
  const [CompanyDetails, setCompanyDetails] = useState([]);
  const getCompaniesDetails = async () => {
    const companyID = secureLocalStorage.getItem("companyID");
    const data = await getCompanybyID(companyID);
    if (data.responseCode === 200) {
      console.log(data.responseData);
      secureLocalStorage.setItem("companyName", data?.data?.companyName);
      setCompanyDetails(data.responseData);
    } else {
      console.log("data.responseData");
    }
  }

  useEffect(() => {
    getCompaniesDetails();
  }, []);


  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
  }));

  return (
    <div  className="page">
       <PageHeader
          showBackButton={true}
          template={HeaderTemplates.Standard} 
          fundName={secureLocalStorage.getItem("FundName")}
          headerImage='/capitalimages/mask-group-382@2x.png'
          companyName={CompanyDetails?.companyName}
        />
       <Box sx={{ flexGrow: 1 }} marginLeft={'30px'} overflowx={'auto'}>
            <Grid container spacing={1}>
                  <Grid container spacing={2}>

                    {/* <Grid item xs={14} style={{marginLeft: '-34px',marginRight: '-34px'}} className="height-200-noscroll background-light-blue"> */}
                       {/* <Grid xs={4}>
                        <div style={{display: 'flex',paddingLeft: '15px'}}>
                        {isPortfolioCompany ? null : (

                            <img
                              className="cursor-pointer"
                              alt=""
                              src="/capitalimages/back-arrow-button1.svg"
                              onClick={onBackArrowButtonClick}
                              style={{marginTop: '-35px'}}
                            /> */}
                        {/* )} */}
                            {/* <div style={{display: 'flex',borderRadius: '8px',marginLeft: '15px',height: '110px',alignItems: 'center'}}>  */}
                              {/* <img alt="" src="/capitalimages/image-5@2x.png" /> */}
                           {/* {CompanyDetails?.companyName}

                            </div>

                            {secureLocalStorage.getItem("FundName")}
                          </div>
                          </Grid>  */}
                    {/* </Grid> */}
                    <OnBoardingStepContainer/>
                  </Grid>

            </Grid>
          
          </Box>
        <CircleIcon/>
    </div>
  );
};

export default PortifolioOnboarding;
