import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { Box } from "@mui/system";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from '@mui/icons-material/Delete';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import { getrootBackgroundColorConfig } from "../../../../state/slices/colorConfigSlice";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import secureLocalStorage from "react-secure-storage";
import { AppDashboards } from "../../../../utils/enum";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { MarkAsRead } from "../../Services/GroupsServices";
import moment from 'moment-timezone'; // Import Moment.js with Timezone support
import EastIcon from '@mui/icons-material/East';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';
import { getUserAppNotifications } from "../../Services/IRServices";
function NotificationItem({ content, timestamp, notification, type, notificationDetailsID, notificationHasAction,props, notificationURL }) {
     
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const notificationRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const contentHeight = notificationRef.current.clientHeight;
        const lineHeight = parseInt(window.getComputedStyle(notificationRef.current).lineHeight);

        if (contentHeight > lineHeight * 1) {
            setShowMore(true);
        } else {
            setShowMore(false);
        }
    }, [notification]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        handleClose();
    };

    const handleMute = () => {
        handleClose();
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
        handleClose();
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };



    // const handleNotificationClick = useCallback(() => {
    //     const userRole = secureLocalStorage.getItem('userrole');
    //     if (userRole === AppDashboards.INV_DASHBOARD) {
    //         if (type === "Personal information" ||
    //             type === "Marketplace" ||
    //             type === "Banking information" ||
    //             type === "Investment profile" ||
    //             type === "Subscription details") {
    //             console.log("Navigating to user profile...");
    //             if (type === "Marketplace") {
    //                 console.log("Navigating to Marketplace...");
    //                 navigate('/marketplace');
    //             } else {
    //                 navigate('/user-profile');
    //             }
    //         } else {
    //             console.log("Notification type does not require navigation.");
    //         }
    //     } else {
    //         console.log("User does not have the required role for navigation.");
    //     }
    // }, [navigate, type]);

    //#to Post as Mark As read when User clicked on Notification and redirect to the respective page
    const handleNotificationClick = useCallback(async () => {
        const userId = secureLocalStorage.getItem("userId");
        const response = await MarkAsRead(notificationDetailsID, userId);
        if (response?.responseCode === 200) {
            const userRole = secureLocalStorage.getItem('userrole');
            if (userRole === AppDashboards.INV_DASHBOARD) {
                let selectedTabIndex = 0;
                if(notificationHasAction === true){
                    switch (type) {
                        case "Personal information":
                          selectedTabIndex = 0;
                          navigate('/user-profile', { state: { selectedTab: selectedTabIndex } });
                          break;
                        case "Banking information":
                          selectedTabIndex = 1;
                          navigate('/user-profile', { state: { selectedTab: selectedTabIndex } });
                          break;
                        case "Investment profile":
                          selectedTabIndex = 2;
                          navigate('/user-profile', { state: { selectedTab: selectedTabIndex } });
                          break;
                        case "Subscription details":
                          selectedTabIndex = 3;
                          navigate('/user-profile', { state: { selectedTab: selectedTabIndex } });
                          break;
                        case "Marketplace":
                          navigate('/marketplace');
                          break;
                        case "All funds from marketplace":
                          secureLocalStorage.setItem("FundId", parseInt(notificationURL.match(/\d+$/)[0]));
                          navigate('/fund-details', {
                            state: {
                              FundID: parseInt(notificationURL.match(/\d+$/)[0]),
                              InvestorID: secureLocalStorage.getItem("userId"),
                              SubscriptionID: 0,
                              navigatefrommarketplace: true,
                              disableSubscriptionButton: false
                            }
                          });
                          break;
                        default:
                          selectedTabIndex = 0;
                          navigate('/all-notifications');
                          break;
                      }
                }
                else{
                    handleOpenDialog();
                }
            }
            else if (userRole === AppDashboards.CO_DASHBOARD ||
                userRole === AppDashboards.FM_DASHBOARD) {
                switch (type) {
                    case "Notify User on Investor Comments":
                    case "Notify User on KYCAML Update":
                        navigate(notificationURL);
                        break;
                }
            } else {
                console.log("User does not have the required role for navigation.");
            }
        } else {
            console.log("Error in marking as read");
        }
    }, [navigate, type, notificationDetailsID]);



    console.log("Clicked on notification content:", content);
    const notificationMaxLength = 150;
    const truncatedNotification = (notification?.length > notificationMaxLength ? notification?.slice(0, notificationMaxLength) + '...' : notification);

    return (
        <>
            <Card style={{ padding: '16px' }}>
                <CardContent style={{ display: "flex", justifyContent: "space-between", padding: '0px' }}>
                    <Grid item xs={12} style={{ padding: '0px' }}>
                        <div className="notificationCardHeading" onClick={handleNotificationClick} title="View Details">{content}</div>
                    </Grid>
                    <div onClick={handleOpenDialog}></div>
                    <div style={{ display: "flex", alignItems: "center" }} hidden={content === "No notifications available"}>
                        <Grid item >
                            <div style={{ marginRight: "8px", whiteSpace: "nowrap" }}>{timestamp}</div>
                        </Grid>
                        <Grid item >
                            <IconButton onClick={handleClick}>
                                <MoreVertIcon />
                            </IconButton>
                        </Grid>
                       
                       <Menu
                       anchorEl={anchorEl}
                       open={Boolean(anchorEl)}
                       onClose={handleClose}
                       anchorOrigin={{
                           vertical: "bottom",
                           horizontal: "right",
                       }}
                       transformOrigin={{
                           vertical: "top",
                           horizontal: "right",
                       }}
                   
                   >
                       <MenuItem onClick={handleDelete}>
                           <DeleteIcon style={{ marginRight: "8px" }} /> Delete notification
                       </MenuItem>
                       <MenuItem onClick={handleMute}>
                           <NotificationsOffIcon style={{ marginRight: "8px" }} /> Turn off notifications
                       </MenuItem>
                   </Menu>
                    </div>
                </CardContent>
                <div ref={notificationRef} style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: "3",
                    //maxHeight: maxHeight, // Set the height equivalent to 3 lines

                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    wordBreak:'break-word',
                }}>
                    {truncatedNotification}
                </div>
                {showMore && (
                    <span onClick={handleOpenDialog} className="seemore"> See More <EastIcon /> </span>
                )}
            </Card>
            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" sx={{ height: 'auto' }}>
                <DialogTitle>  <IconButton onClick={handleCloseDialog} style={{ float: 'right' }} title="Close">
                    < CloseIcon />
                </IconButton>
                </DialogTitle>
                <DialogContent>
                    <p style={{ fontWeight: 'bold', textDecoration: 'underline' }}>{content}</p>
                    <p style={{ wordWrap: 'break-word', }}>{notification}</p>
                    <div>
                            {truncatedNotification?.fileNames?.split(',')?.map((file, index) => (
                                <React.Fragment key={index}>
                                    <a
                                        href={truncatedNotification?.containerPath + "/" + truncatedNotification?.notificationDetailsID + "/" + file.trim()}
                                        target="_blank"
                                    >
                                        {file}
                                    </a>
                                    <br />
                                </React.Fragment>
                            ))}
                        </div>
                </DialogContent>
                <DialogActions>


                </DialogActions>
            </Dialog>
        </>
    );
}

function Notifications() {
    const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);
    const [notifications, setNotifications] = useState([]);
    const notifiedUserID = secureLocalStorage.getItem("userId");
    // ${notifiedUserID}

    const getAppNotificationDetails = async () => {
        const data = await getUserAppNotifications();
        if(data.responseCode === 200){
            setNotifications(data.responseData);
        }
        else{

        }
    };

    useEffect(() => {
        
        getAppNotificationDetails();
    }, []);


    const calculateRelativeTime = (date) => {
        const timeGapToIST = moment.duration(5, 'hours').add(30, 'minutes'); // Time gap between UTC and IST
        const notificationUpdatedDateIST = moment(date).add(timeGapToIST); // Convert to IST
        return notificationUpdatedDateIST.fromNow(); // Calculate relative time from now
    };

    return (
        <div>
            <Box sx={{ flexGrow: 1 }} className="mb4">
                <Grid container spacing={2}>
                    <Grid item container xs={12}>
                        {
                            notifications && notifications.length > 0 ? (
                                notifications.map((notification, index) => (
                                    <Grid item xs={12} key={index}>
                                        <NotificationItem
                                            content={notification.notificationSubject}
                                            timestamp={calculateRelativeTime(notification.updatedDate)}
                                            notification={notification.notificationBody.replace(/<[^>]*>/g, '')}
                                            type={notification.notificationType}
                                            notificationURL={notification.notificationURL}
                                            notificationDetailsID={notification.notificationDetailsID}
                                    notificationHasAction={notification.notificationHasAction}
                                        />
                                    </Grid>
                                ))
                            ) : (
                                <Grid item xs={12}>
                                    <NotificationItem
                                        content="No notifications available"
                                        timestamp="" // You can leave the timestamp empty or set it to any default value you want.
                                        notification=""
                                        type="" // You can leave the type empty or set it to any default value you want.
                                        notificationURL=""
                                        notificationDetailsID=""
                                    />
                                </Grid>
                            )
                        }

                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}

export default Notifications;
