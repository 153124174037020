import React, { useState } from 'react'
import { HeaderTemplates, PageHeader } from '../../../common/PageHeader/PageHeader'
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { ComponentsTab } from '../../../common/Tabs/ComponentsTab';
import { getPCCompanybyID, getPCDropDownDetails } from '../../services/services';
import secureLocalStorage from 'react-secure-storage';
import { useEffect } from 'react';
import { useSelector } from "react-redux";
import { getrootBackgroundColorConfig } from '../../../../state/slices/colorConfigSlice';
import PCCompanyOverview from './PCCompanyOverview';
import InvestmentStructureMain from './InvestmentStructureMain';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PieChartIcon from '@mui/icons-material/PieChart';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import StorageIcon from '@mui/icons-material/Storage';
import { LinearProgress } from '@mui/material';
import PerformanceTab from '../../../PortfolioCompanies/components/PerformanceTab';
import VDRoom from '../../../common/VirtualDataRoom/VDRoom';
import TabsComponment from '../../../common/Tabs/TabsComponment';
import { AppDashboards } from '../../../../utils/enum';
import BusinessIcon from '@mui/icons-material/Business';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
const PortfolioMain = () => {

  //#region variables
  const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);
  const [isSaveEnabled,setSaveEnabled] = useState(false);
  const [selectedTab,setSelectedTab] = useState(0);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [dropDownsData, setDropDownsData] = useState(null);
  const [tabs,setTabs]= useState(null);
  const [secondaryTabs,setSecondaryTabs] = useState(
    [
      {
        key : 1,
        icon : <MonetizationOnIcon />,
        label : "INVESTMENT",
        component : <InvestmentStructureMain />
    },
    {
        key : 2,
        icon : <SignalCellularAltIcon />,
        label : "PERFORMANCE",
        component : <PerformanceTab />
    },
  ])

  //#region click events
  const onSaveClick = () => {
    setSaveEnabled(!isSaveEnabled);
  }

  //#region api get calls
  const getCompaniesDetails = async () => {
    const companyID = secureLocalStorage.getItem("companyID") || 0;
    const data = await getPCCompanybyID(companyID);
    if (data.responseCode === 200) {
      console.log(data.responseData);
      secureLocalStorage.setItem("companyName", data?.responseData?.companyName);
      setCompanyDetails(data.responseData);
    } else {
      //if company is new
      setCompanyDetails(0);
      console.log("Error Fetching Details");
    }
  }

  const getDropDownDetails = async () => {
    const data = await getPCDropDownDetails();
    if (data.responseCode === 200) {
        setDropDownsData(data.responseData);
    }
    else {
        //console.log('unable to fetch data)
    }
  }

  //#region useEffect
  useEffect(() => {
    getDropDownDetails();
    getCompaniesDetails();
  }, []);
  
  useEffect(()=>{
    if(companyDetails || companyDetails === 0 ){
      const primaryTab = [
        {
          key : 0,
          icon : <BusinessIcon/>,
          label : "COMPANY OVERVIEW",
          component :  <PCCompanyOverview companyDetails={companyDetails} setCompanyDetails={setCompanyDetails} dropDownsData={dropDownsData}/>
        }
      ]
      const finalTabs = companyDetails === 0 ? [...primaryTab] : [...primaryTab,...secondaryTabs,
        {
          key : 3,
          icon : <StorageIcon/>,
          label : "DATA ROOM",
          component : <VDRoom cnrtFundID={secureLocalStorage.getItem("FundId")} crntPortfolioid={companyDetails?.companyId} crntSubscriptionID={0} VDRType="Portfolio"/>
        },
      ]
      setTabs(finalTabs)
    }
  },[companyDetails,dropDownsData])
  

  return (
    <div style={{backgroundColor: rootBackColorConfig.color }} >
      <PageHeader
        template={HeaderTemplates.Standard}
        onBtnClick={onSaveClick}
        hideBackButton={secureLocalStorage.getItem("userrole") === AppDashboards.FM_DASHBOARD ? false : true}
        headerImage={companyDetails?.fundLogo}
        fundName={companyDetails?.fundName}
        customBackgroundImage={companyDetails?.fundImage ? companyDetails?.fundImage : '/DefaultBackgroundImage.png'}
      />
      <div className="wrapper2">
         {
          tabs ? <TabsComponment tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab}/> : 
          <div><LinearProgress /></div>
         }
      </div>
    </div>
  )
}

export default PortfolioMain