import { useEffect } from "react";
import styles from "./FundDetailPerformance.module.css";
import { useState } from "react";
import AIPDataGrid from "../../common/DataGrid/AIPDataGrid";
import DashboardSelect from "../../common/Select/DashboardSelect";
import secureLocalStorage from "react-secure-storage";
import { Typography } from "@mui/material";
import ChartSelector from "../../common/DataChart/ChartSelector";
import FundPerformance from "../../common/FundPerformance/FundPerformance";
import { PerformancePages } from "../../common/FundPerformance/apiDataTemplate";

const FundDetailPerformance = (props) => {

  //#region props
  const { fundDetails } = props;

  //#region variables
  const [selectedChart, setSelectedChart] = useState();
  
  return (
    <>
    <div className=" mt3">
      <h4>Performance</h4>
      <div className="whiteCard mt2">
        <FundPerformance fundTypeName={fundDetails?.fundTypeName} />
      </div>
    </div>
    </>
  );
};

export default FundDetailPerformance;
