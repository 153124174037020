import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { ToastContainer, toast } from 'react-toastify';
import Accordian from '../../../common/Accordian/Accordian';
import { useState, useEffect } from 'react';
import { getSentNotifications } from '../../Services/GroupsServices';
import CustomEmail from '../CustomEmail';
import './styles.css';
import { CommunicationType } from '../../../../utils/enum';
import { getInvestorsandGroupsList } from '../../Services/GroupsServices';
import { LinearProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import ContextMenu from '../../../common/ContextMenu/ContextMenu';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteSentNotifications } from '../../Services/IRServices';
import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from '@mui/icons-material/Cancel';


const SentNotifications = (props) => {
  const [sentNotifications, setSentNotifications] = useState(null);
  const [investorsList, setInvestorsList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [notificationToDelete, setNotificationToDelete] = useState(null);

  const getSentItems = async () => {
    const data = await getSentNotifications();
    if (data?.responseCode == 200) {
      setSentNotifications(data?.responseData.filter(u => 
        u.notificationType === "Internal Custom Notification" ||
        u.notificationType === "Marketplace" ||
        u.notificationType === "All funds from marketplace" ||
        u.notificationType === "Personal information" ||
        u.notificationType === "Banking information" ||
        u.notificationType === "Investment profile" ||
        u.notificationType === "Subscription details"
      ));
      setLoading(false);
    } else {
      toast.warning(data?.responseData, { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      setLoading(false);
    }
  }

  const getInvestors = async () => {
    const data = await getInvestorsandGroupsList();
    if (data?.responseCode === 200) {
      setInvestorsList(data?.responseData);
    } else {
      // Handle error if needed
    }
  }

  useEffect(() => {
    getSentItems();
    getInvestors();
  }, []);

  const renderBody = (item) => (
    <div>
      <CustomEmail 
        CommunicationType={CommunicationType.SentNotifications}
        SelectedSentNotificationfromParent={item?.notificationSubject}
        selectedSentItemFromParent={selectedSentItemFromParent}
        selectedActionItem = {item?.notificationOnID}
        InvestorsItems={investorsList}
        SentNotificationsDetails={sentNotifications}
        setSentNotifications={setSentNotifications}
      />
    </div>
  );

  const [selectedSentItemFromParent, setSelectedSentItemFromParent] = useState(null);

  const handleChangeData = (item) => {
    setSelectedSentItemFromParent(item?.notificationSubject);
  }

  const [selectedPeriod, setSelectedPeriod] = useState('5');

  const handleChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

  const Options = [
    { value: '5', label: 'Any Time' },
    { value: '1', label: 'Older than a Week' },
    { value: '2', label: 'Older than a Month' },
    { value: '4', label: 'Older than 6 Months' },
    { value: '3', label: 'Older than a Year' },
  ];

  //#region Context Menu Items
  const MenuItemsTop = [
    {
      id: 1,
      label: 'Delete',
      icon: <DeleteIcon />,
      onClick: (item) => {
        setNotificationToDelete(item);
        setOpenDialog(true);
      },
    }
  ];

  const handleDelete = async () => {
    const data = await deleteSentNotifications (notificationToDelete.notificationDetailsID)
    if(data.responseCode === 200){
      setOpenDialog(false);
      getSentItems();
      props.getNotificationsCount()
      toast.success('Notification deleted successfully!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'colored',
      });
    }else{
      toast.warning('Something went wrong,please try again!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'colored',
      });
    }
   
  };

  return (
    <div className='notifications-accordian-wrapper'>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedPeriod}
        onChange={handleChange}
        sx={{ width: '230px', height: '35px', padding: '5px' }}
      >
        {Options.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {sentNotifications && investorsList ? (
        sentNotifications.length === 0 ? (
          'No Sent Notifications Found!'
        ) : (
          sentNotifications.map((item) => (
            <div key={item.notificationSubject} className='space-between'>
              <div className='groupview-accordian'>
                <Accordian
                  title={item?.notificationSubject}
                  body={renderBody(item)}
                  onChange={() => handleChangeData(item)}
                />
              </div>
              <div className='margin-top-9'>
                <ContextMenu MenuItemsTop={MenuItemsTop} ItemDetails={item} />
              </div>
            </div>
          ))
        )
      ) : (
        <div>
          <LinearProgress />
        </div>
      )}

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
         <DialogTitle>
               
                <h6> <InfoIcon className='info-i' /> Confirmation</h6>
            </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this sent notification?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className='btn-primary2' variant='outlined' onClick={() => setOpenDialog(false)}> <CancelIcon/> Cancel</Button>
          <Button className='btn-primary' variant='contained' onClick={handleDelete} autoFocus>
           <DeleteIcon/> Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SentNotifications;
