import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./FirmWideDashboard.module.css";
import NavigationBar from "../common/navigation-bar/NavigationBar";
import { useState, useEffect } from "react";
import jsonData from './firmwide.json'
import AIPDataGrid from "../common/DataGrid/AIPDataGrid";
import AIPChart from "../common/DataChart/AIPChart";
import MultipleSelectDropdown from "../common/Multi-Select-Dropdown/MultipleSelectDropdown";
import JsonData from '../common/commonJsonData.json';
import DashboardSelect from "../common/Select/DashboardSelect";
import { CurrencyFormatter } from "../../utils/Formater";
import secureLocalStorage from "react-secure-storage";
import CircleIcon from "../chatbot/Icon";
import DashboardSelect2 from "../common/Select/DashboardSelect2";
import { formatAsUSACurrency } from "../common/CurrencyFormater/formatAsUSACurrency";
const FirmWideDashboardAI = () => {
  const navigate = useNavigate();

  const [isExpanded, setExpendState] = useState(false);

  const onThomasSmithTextClick = useCallback(() => {
    navigate("/user");
  }, [navigate]);

  const rowclick = (params) => {
  };
  const fundClick = (params) => {
    secureLocalStorage.setItem("FundName", params.row.FundName);
    secureLocalStorage.setItem("FundId", params?.id);
    navigate("/marketplace/OverviewPage");
  };
  const investorClick = (params) => {
    navigate("/fm-user-profile/overview", { state: { "subscriberEmail": params.row.InvestoryEmailAddress } });
  };

  const [allfunds, setAllFunds] = useState();
  const [allinvestors, setAllInvestors] = useState();
  const [allfundamangers, setAllFundManagers] = useState();
  const [selectedYear, setSelectedYear] = useState('');
  const years = [
    { id: 1, label: '2020', value: 2020 },
    { id: 2, label: '2021', value: 2021 },
    { id: 3, label: '2022', value: 2022 },
    { id: 4, label: '2023', value: 2023 },
    // Add more years as needed
  ];
  const handleYearChange = (year) => {
    setSelectedYear(year);
    // You can perform additional actions here when the year changes
  };
  useEffect(() => {
    fetchData();
  }, []);

    const [investorProfile, setInvestorProfile] = useState({});
	  const [investmentData, setinvestmentData] = useState([]);

    const emailId = secureLocalStorage.getItem("userEmail");
    const firstName = (investorProfile?.personalInformation?.firstName ?? "").charAt(0).toUpperCase() + (investorProfile?.personalInformation?.firstName ?? "").slice(1);
    const lastName = (investorProfile?.personalInformation?.lastName ?? "").charAt(0).toUpperCase() + (investorProfile?.personalInformation?.lastName ?? "").slice(1);
    const fullName = `${firstName} ${lastName}`;
  
  
  
    const fetchData = async () => {
    
    }

  //Fetching API Data For Subscribers
  useEffect(() => {
  }, []);

  const AllFundsRowsData = allfunds ? allfunds.map(fund => ({
    "id": fund.fundID,
    "FundName": fund.fundName,
    "Status": fund.fundStatusName,
    "Committed": formatAsUSACurrency(fund.capitalCommitted),
    "Fundeded": formatAsUSACurrency(fund.fundSize),
    "IRR": fund.targettedIRR,
    "PortfolioCompanies": fund.portfolioCompaniesCount == null ? 0 : fund.portfolioCompaniesCount,
    "FundManager": fund.fundManager
  })) : [];

  const AllInvestorsRowsData = allinvestors ? allinvestors.map(fund => ({
    "id": fund.investoryID,
    "InvestorName": fund.investoryName,
    "AUM": formatAsUSACurrency(fund.aum),
    "Committed": formatAsUSACurrency(fund.comittedAmount),
    "Fundeded": formatAsUSACurrency( fund.fundedAmount),
    "IRR": fund.irr,
    "NoOfFunds": fund.noOfFunds,
    "FundManager": fund.fundManager,
    "InvestoryEmailAddress": fund.investoryEmailAddress
  })) : [];

  const AllFundManagersRowsData = allfundamangers ? allfundamangers.map(fund => ({
    "id": fund.fundManagerSummaryID,
    "ManagerName": fund.fundManager,
    "AUM":formatAsUSACurrency(fund.aum),
    "Committed":formatAsUSACurrency( fund.committedAmount),
    "Fundeded": formatAsUSACurrency(fund.funded),
    "IRR": fund.irr,
    "NoOfFunds": fund.noofFunds
  })) : [];

  return (
    <div className={isExpanded ? `${styles.firmWideDashboard} ${styles.firmWideDashboardExpanded}` : styles.firmWideDashboard}>
      <div className={styles.firmWideTitle1}>
        Welcome Back, {fullName}
      </div>
      <div className={styles.firmWideTitle2}>Firm-wide Dashboard</div>

      <div className={styles.firmWideDashboard1} >
        <div className={styles.dashboardQuestions}>Total Number of Funds</div>
        <b className={styles.dashboardAnswers}>234</b>
      </div>
      <div className={styles.firmWideDashboard2} >
        <div className={styles.dashboardQuestions}>Total Committed</div>
        <b className={styles.dashboardAnswers}>$2,450,679,045.00</b>
      </div>
      <div className={styles.firmWideDashboard3} >
        <div className={styles.dashboardQuestions}>Target Raise</div>
        <b className={styles.dashboardAnswers}>$1,945,720,540.00</b>
      </div>
      <div className={styles.firmWideDashboard4} >
        <div className={styles.dashboardQuestions}>Gross IRR</div>
        <b className={styles.dashboardAnswers}>24.5%</b>
      </div>
      <div className={styles.groupContainer}>
        <div className={styles.firmPerformanceParent}>
          <b className={styles.firmPerformance}>Firm Performance</b>
          <div className={styles.ringChart1Icon}>
            <AIPChart
              series={jsonData.chartData.map((i) => i.value)}
              labels={jsonData.chartData.map((i) => i.label)}
              text={"Total investment made till date"}
            /></div>

          <div className={styles.selectboxfilter}>
          
            <div className={styles.selectGraph}> 
            <DashboardSelect2
        options={years}
        label={'Year'}
        value={selectedYear}
        onChange={handleYearChange}
      />            </div>
  
          </div>
          <div className={styles.selectboxfilter1}>
            <MultipleSelectDropdown options={JsonData.SelectGraph}/>
            
          </div>
        </div>
      </div>
      <div className={styles.allfundsgrid}>
        <b className={styles.allFunds}>All Funds</b>
        <div className={styles.AllFundsGridTop}>
          <AIPDataGrid
            columns={jsonData.AllFundsColumns}
            rows={AllFundsRowsData}
            handleRowClick={fundClick}
            onRowsSelectionHandler={() => { }} />
        </div>
        {console.log(allfunds)}
      </div>
      <div className={styles.firmWideDashboardAllInvestor}>
        <b className={styles.allFunds}>All Investors</b>
        <div className={styles.AllFundsGridTop}>
          <AIPDataGrid
            columns={jsonData.InvestorsColumns}
            rows={AllInvestorsRowsData}
            handleRowClick={investorClick}
            onRowsSelectionHandler={() => { }} />
        </div>
      </div>
      <div className={styles.firmWideDashboardAllFundManager}>
        <b className={styles.allFunds}>All Fund Managers</b>
        <div className={styles.AllFundsGridTop} >
          <AIPDataGrid
            columns={jsonData.ManagerColumns}
            rows={AllFundManagersRowsData}
            onRowsSelectionHandler={() => { }} />
        </div>
      </div>
      <NavigationBar isExpanded={isExpanded} setExpendState={setExpendState} currentpage={9}/>
      <CircleIcon/>
    </div>
  );
};

export default FirmWideDashboardAI;
