export default global = {
    window : {
       variables : {
          apiBaseUri: process.env.REACT_APP_APIBASEURI,
          tenantID: process.env.REACT_APP_TENANTID,
          tenantName: process.env.REACT_APP_TENANTNAME,
          policyPrefix: process.env.REACT_APP_POLICYPREFIX,
          clientID: process.env.REACT_APP_CLIENTID,
          appInsightInstumentationKey: process.env.REACT_APP_APPLICATIONINSIGHTS_INSTRUMENTATIONKEY
       }
   }
}
