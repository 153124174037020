import axiosApiCall from "../../Services/axiosApiCall";
import { getAPIResponse } from "../../../utils/common";

export async function getSubscribersList(fundid) {
    return await axiosApiCall.get(`/v1/FundSubscription/GetFundSubscriptions?fundID=${fundid}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export const ManualSubscriptionErrorMessages = {
    SUBSCRIPTION_SUCESSFUL: 'Fund Subscribed Successfully',
    SUBSCRIPTION_FAILED: 'unable to subscribe the fund',
    INVESTOR_SIGN_FAILED: 'unable to navigate to e-sign document',
    STEPS_LOADING_FAILED: 'unable to load the subscription steps data',
    VALIDATION_CHECK: 'please verify mandatory fields',
    STOP_SUBSCRIPTION: 'You cannot proceed with Subscription as you have chosen this option',
    SAVE_CANCEL_WARNING: 'please save or cancel the data',
    STEP_DETAILS_SUCCESS: 'Step details updated successfully',
    STEP_DETAILS_FAILED: 'unable to update the step data',
    UNABLE_TO_LOAD : 'Unable to load the data , please try again',
    DATA_NOT_FOUND:'No offline subscriptions found'
  }
