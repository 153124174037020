import React, { useEffect, useState } from 'react'
import BasicTab from '../../../administration/TabComponent'
import EventSubGroupsAccordian from './EventSubGroupsAccordian';
import { getEmailTemplatesGroupsList } from './Services/EventManagementResponse';
import { ToastContainer, toast } from 'react-toastify';

const EventManagementMain = () => {

  //#region variables
  const [currentTab, setCurrentTab] = useState(0);
  const [EmailtemplatesData, setEmailtemplatesData] = useState(null)
  const [componentRefresh, setComponentRefresh] = useState(true)

  //## Service calls
  const getExcistingGroups = async () => {
    const data = await getEmailTemplatesGroupsList();
    if (data?.responseCode == 200) {
      setEmailtemplatesData(data?.responseData)
    } else {
      toast.warning(data?.responseData,
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
  }

  useEffect(() => {
    getExcistingGroups()
  }, [])

  useEffect(() => {
    setComponentRefresh(false)
    setComponentRefresh(true)
    getExcistingGroups()
  }, [currentTab])

  useEffect(() => {
    getExcistingGroups()
  }, [componentRefresh])


  //## function to filter duplicates
  function groupByEmailGroups(templates) {
    const groupedEmails = {};
    templates?.forEach(template => {
      if (!groupedEmails[template.groups]) {
        groupedEmails[template.groups] = [];
      }
      groupedEmails[template.groups].push(template);
    });
    return groupedEmails;
  }

  const groupedByEmailGroups = groupByEmailGroups(EmailtemplatesData);
  const tabs = Object.keys(groupedByEmailGroups).map((group, index) => ({
    id: index + 1,
    name: <label>{group}</label>,
    label: 'new notification',
    component: <EventSubGroupsAccordian emailTemplates={groupedByEmailGroups[group]} getExcistingGroups={getExcistingGroups} setComponentRefresh={setComponentRefresh} EmailtemplatesData={EmailtemplatesData} /> // Pass templates to component
  }));

  //#region end
  return (
    <div className='event-res-tabs'>
      {
        EmailtemplatesData && componentRefresh ? <div className='vTabs_L'>
          <h4 className='mb3'>Event Response Management</h4>
          {
            tabs &&
            <BasicTab
              tabs={tabs}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              isVertical='vertical'
            // backgroundcolor={true}
            />
          }
        </div> : "loading...."
      }
    </div>
  )
}

export default EventManagementMain