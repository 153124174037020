export const transactionTypeOptions = [
    {label : 'All' , value: 'All'},
    {label : 'Capital Call' , value: 'CapitalCall'},
    {label : 'Distribution' , value: 'Distribution'},
    {label : 'Portfolio Company' , value: 'PortofolioCompany'},
]

export const realEstateTransactionTypeOptions = [
    {label : 'All' , value: 'All'},
    {label : 'Capital Call' , value: 'CapitalCall'},
    {label : 'Distribution' , value: 'Distribution'},
    {label : 'Assets' , value: 'PortofolioCompany'},
]


export const PrivateCreditTransactionTypeOptions = [
    {label : 'All' , value: 'All'},
    {label : 'Distribution' , value: 'Distribution'},
    {label : 'Portfolio Company' , value: 'PortofolioCompany'},
]