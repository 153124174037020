import React, { useCallback } from "react";
import MiniDrawer from "../common/navigation-bar/NavBarGrid";
import { TopNavBar } from "../common/navigation-bar/TopNavBar";
import { Outlet } from "react-router-dom";
import { Button } from "@mui/material";
import secureLocalStorage from "react-secure-storage";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
function NonActiveUserLogin() {
  const msalInstance = new PublicClientApplication(msalConfig);

  const handleOkButtonClick  = () => {
    secureLocalStorage.setItem("fullName","");
    secureLocalStorage.clear();
    msalInstance.logoutRedirect({ postLogoutRedirectUri: "/?login_page=true" });

  };

  return (<div style={{ display: 'flex' }}>
    {/* <MiniDrawer /> */}
    <div style={{ width: '100%' }}>
      <TopNavBar  fullname={secureLocalStorage.getItem("fullName")} hideMenuButton={true}/>
      <div className="alertMessage">
      <h1> <ReportProblemIcon fontSize="large"/> You cannot login</h1>
      <p> Please click the activation link sent to your registered Email ID and then try again.</p> 
      <Button variant="contained" className="btn-primary" onClick={handleOkButtonClick}>
         <ArrowBackIcon/>
         Go Back
        </Button>
      </div>
      
  
     
    </div>
  </div>
  )
}

export default NonActiveUserLogin
