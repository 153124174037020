const Indicator = (params) => {
    return (
        <>
            {
                typeof params.params.value === 'number'
                && params.params.value > 0
                && <div className="red-indicator"></div>
            }
            {
                typeof params.params.value === 'string'
                && (params.params.value === "Legal Review" || 
                    params.params.value === "InProgress" || 
                    params.params.value === "2nd Reminder" || 
                    params.params.value === "Pending Review" || 
                    params.params.value === "On Hold" || 
                    params.params.value === "Open" || 
                    params.params.value === "Under Compliance Review" || 
                    params.params.value === "Under Fund Manager Review"|| 
                    params.params.value === "Investor Sign Pending"|| 
                    params.params.value === "Newly Open")
                && <div className="yellow-indicator"></div>
            }
            {
                typeof params.params.value === 'string'
                && (params.params.value === "Signed" || 
                    params.params.value === "Complete" || 
                    params.params.value === "Closed to New Investors" || 
                    params.params.value === "Inactive")
                && <div className="gray-indicator"></div>
            }
            {
                typeof params.params.value === 'string'
                && (params.params.value === "Docs to Sign" || 
                    params.params.value === "FM Approval" || 
                    params.params.value === "Rejected" || 
                    params.params.value === "Waiting on Funds"|| 
                    params.params.value === "Liquidating"|| 
                    params.params.value === "Terminated")
                && <div className="red-indicator"></div>
            }
            {
                typeof params.params.value === 'string'
                && (params.params.value === "Submitted" || 
                    params.params.value === "Approved" || 
                    params.params.value === "Profile Process" || 
                    params.params.value === "Merged")
                && <div className="green-indicator"></div>
            }
            {
                params.params.row.FundMaturity === true || params.params.row.FundTermination == true? (
                    <div style={{ color: 'gray' }}>{params.params.value}</div>
                ) : (
                    params.params.value
                )
                }
        </>
    );
}

export default Indicator;