import React, { useState, useEffect } from 'react';
import { getInvestorsandGroupsList } from '../../Services/GroupsServices';
import { Checkbox, Chip, Grid, Typography, Button } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';

function InstantMessenger() {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [investorsList, setInvestorsList] = useState([]);
  const [selectedInvestors, setSelectedInvestors] = useState([]);

  const getInvestors = async () => {
    const data = await getInvestorsandGroupsList();
    if (data?.responseCode === 200) {
      setInvestorsList(data?.responseData);

    } else {
      // Handle error if needed
    }
  }

  useEffect(() => {
    getInvestors();

  }, []);

  const onAutoCompleteChange = (e, value) => {
    const latestSelection = value?.map(u => u.id);

    const newItems = investorsList.filter(u => latestSelection?.includes(u.id));
    setSelectedInvestors(investorsList.filter(u => latestSelection?.includes(u.id)));
  }

  const handleSendMessage = () => {
    if (inputMessage.trim() !== '') {
      const newMessage = {
        text: inputMessage.trim(),
        sentByUser: true,
        timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      };
      setMessages([...messages, newMessage]);
      setInputMessage('');
    }
  };

  const styles = {
    messengerContainer: {
      width: '100%',
      border: '1px solid #ccc',
      borderRadius: '8px',
      overflow: 'hidden',
    },
    chatHeader: {
      backgroundColor: '#075e54',
      color: 'white',
      padding: '10px',
    },
    chatMessages: {
      padding: '10px',
      //overflowY: 'auto',
      //maxHeight: '600px',
      minHeight: '200px',
    },
    message: {
      marginBottom: '10px',
      padding: '8px',
      borderRadius: '8px',
      maxWidth: '70%',
    },
    messageSent: {
      backgroundColor: '#dcf8c6',
      alignSelf: 'flex-end',
      color: '#000', // Text color for sent messages
    },
    messageReceived: {
      backgroundColor: '#fff',
      color: '#000', // Text color for received messages
    },
    timestamp: {
      fontSize: '12px',
      color: '#888',
    },
    messageInput: {
      display: 'flex',
      alignItems: 'center',
      padding: '10px',
    },
    input: {
      flex: '1',
      padding: '8px',
      border: '1px solid #ccc',
      borderRadius: '20px',
      marginRight: '10px',
    },
    button: {
      backgroundColor: '#3778f0',
      color: 'white',
      border: 'none',
      padding: '8px 20px',
      borderRadius: '20px',
      cursor: 'pointer',
    },
  };

  return (

    <div style={styles.messengerContainer}>
      <div>
        <Autocomplete
          multiple
          id="emailTo"
          size="small"
          sx={{ width: "100%" }}
          options={investorsList}
          getOptionLabel={(option) => option?.displayName}
          //disabled={props.CommunicationType === CommunicationType.SentNotifications}
          value={selectedInvestors}
          onChange={onAutoCompleteChange}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option?.displayName}
                size="small"
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label="Recipients"
              placeholder="Recipients"
            />
          )}
        />
      </div>
      <div style={styles.chatMessages}>
        {messages.map((message, index) => (
          <div key={index} style={{ ...styles.message, ...(message.sentByUser ? styles.messageSent : styles.messageReceived) }}>
            <p>{message.text}</p>
            <span style={styles.timestamp}>{message.timestamp}</span>
          </div>
        ))}
      </div>
      <div style={styles.messageInput}>
        <input
          type="text"
          placeholder="Type a message..."
          style={styles.input}
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
        />
        <button style={styles.button} onClick={handleSendMessage}><SendIcon sx={{ width: '70px', height: "25px" }} /></button>
      </div>
    </div>



  );
}

export default InstantMessenger;
