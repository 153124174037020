import axios from 'axios';
import resolve from '../../common/resolve';
import axiosApiCall from '../../Services/axiosApiCall';
import { getAPIResponse } from '../../../utils/common';
import secureLocalStorage from 'react-secure-storage';
import { AppDashboards } from '../../../utils/enum';


export async function getRoles(roleManager = "") {
    return await axiosApiCall.get(`/v1/UserRole/GetTenantUserDetails?roleDashboard=${roleManager}`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function getFundsSubscribers(period) {
    return await axiosApiCall.get(`/v1/InvestorDetails/GetInvestorDetails?userId=${secureLocalStorage.getItem("userId")}&filterValue=${period}`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function getAllFunds() {
    return await axiosApiCall.get(`/v1/Fund/GetFundsForDashboard?userId=${secureLocalStorage.getItem("userId")}`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function getuserDetailsbyEmail(emailId) {
    return await axiosApiCall.get(`/v1/User/GetUserDetails?emailAddress=${emailId}`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function getSuggestedArticlesByUserID(subscriberID) {
    return await axiosApiCall.get(`/v1/SuggestedArticle/GetSuggestedArticlesByUserID?userID=${subscriberID}`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function GetSuggestedArticlesByInvestorID(userId) {
    return await axiosApiCall.get(`/v1/SuggestedArticle/GetSuggestedArticlesByUserID?userID=${userId}&isInvestor=true`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function getSuggestedArticlesByFundID(fundID) {
    return await axiosApiCall.get(`/v1/SuggestedArticle/GetSuggestedArticlesByFundID?fundID=${fundID}`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function getSuggestedArticlesByUserId(userId) {
    const isInvestor = secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD
    return await axiosApiCall.get(`/v1/SuggestedArticle/GetSuggestedArticlesByUserID?userID=${userId}&isInvestor=${isInvestor}`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}