// HelpAndSupportFooter.jsx
import React from 'react';
import { Box, Typography, Grid, Link } from '@mui/material';

const HelpAndSupportFooter = () => {
  return (
    <Box className="helpsupport-footer">
      <div className='help-footer-container'>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4} lg={4}>
        <span className="helpsupport-footerlogo">
          <img src="/Alt360-Logo-white.png" alt="Alt360 Logo"  />
          </span>
         <p className='copyrights'> © 2024 ALL RIGHTS RESERVED.</p>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <h5>Company</h5>
          <Link href="#">About</Link>
          <Link href="#">Careers</Link>
          <Link href="#">Contact Us</Link>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <h5>Invest</h5>
          <Link href="#">Accredited Investors</Link>
          <Link href="#">Institutions</Link>
          <Link href="#">Browse Companies</Link>
        </Grid>
      </Grid>
      </div>
    </Box>
  );
};

export default HelpAndSupportFooter;
