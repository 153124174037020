import React, { useRef, useState, useEffect } from 'react';
import './styles.css';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const TabsComponment = (props) => {

  //#region props
  const { tabs, selectedTab, setSelectedTab,isScrollable=false } = props;

  //#region variables
  const tabsListRef = useRef(null);
  const [leftArrowActive, setLeftArrowActive] = useState(false);
  const [rightArrowActive, setRightArrowActive] = useState(false);

  //#region click events
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const handleRightArrowClick = () => {
    tabsListRef.current.scrollLeft += 200;
  };

  const handleLeftArrowClick = () => {
    tabsListRef.current.scrollLeft -= 200;
  };

  //#region useeffect
  useEffect(() => {
    const manageIcons = () => {
      if (tabsListRef.current.scrollLeft >= 20) {
        setLeftArrowActive(true);
      } else {
        setLeftArrowActive(false);
      }
  
      let maxScrollValue = tabsListRef.current.scrollWidth - tabsListRef.current.clientWidth - 20;
      if (tabsListRef.current.scrollLeft >= maxScrollValue) {
        setRightArrowActive(false);
      } else {
        setRightArrowActive(true);
      }
    };
  
    const tabsList = tabsListRef.current;
  
    // Call manageIcons initially to set the arrow states on the first render
    manageIcons();
  
    tabsList.addEventListener('scroll', manageIcons);
  
    return () => {
      tabsList.removeEventListener('scroll', manageIcons);
    };
  }, []);
  

  //#region styles
  const iconstyles = {
    width: '34px !important',
    height: '34px !important',
    color: 'white',
  };

  //#region return
  return (
    <>
<div className='commontabs'>
      <div className={isScrollable ? "scrollable-tabs-container max-width-view-87" : "scrollable-tabs-container"}>
        <div className={`left-arrow ${leftArrowActive ? 'active' : ''}`} onClick={handleLeftArrowClick}>
          <KeyboardArrowLeftIcon sx={iconstyles} />
        </div>
        <ul ref={tabsListRef} className="s-tab-container">
          {tabs?.map((tab, index) => (
            <li
              key={index}
              className={selectedTab === tab?.key ? 'active' : ''}
              onClick={() => handleTabClick(tab?.key)}
            >
              <div className="tab-icon">{tab.icon}</div>
              <div className="tabLabel">{tab.label}</div>
            </li>
          ))}
        </ul>
        <div className={`right-arrow ${rightArrowActive ? 'active' : ''}`} onClick={handleRightArrowClick}>
          <ChevronRightIcon sx={iconstyles} />
        </div>
      </div>
    
      <div>
        <div className={tabs[selectedTab]?.isBackgroundColor ? 'white-card margin-top-15' : 'margin-top-15'}>
          {tabs[selectedTab]?.component}
        </div>
      </div>
      </div>
    </>
  );
};

export default TabsComponment;
