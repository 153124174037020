export const PerformancePages = {
    FM_DASHBOARD: 'fm-dashboard',
    FM_OVERVIEW: 'fm-overview',
    FUND_PUBLIC_VIEW: 'public-view',
    FUND_FILTERS: 'funds-filter',
    SUBSCRIBER_VIEW: 'subsriber-view'
}

export const chartTypes = {
    PERFORMANCE_HISTORY : "Performance history",
    J_CURVE : "J - curve analysis",
    NAV_CASHFLOW : "NAV and Cashflow",
    IRR_BENCHMARK : "IRR and benchmark",
    FUND_TVPI : "Fund TVPI/RVPI/DPI",
    MONTHLY_TOTAL_RETURNS:"Monthly Total Returns",
    HISTORICAL_NAV:"Historical NAV Per Share",
    PER_SHARE_DISTRIBUTION:"$ Per Share Distribution"
}

export const chartsDropDownValues = [
    { label: 'Performance History', value: chartTypes.PERFORMANCE_HISTORY },
    { label: 'J-Curve Analysis', value: chartTypes.J_CURVE },
    { label: 'NAV and Cashflow', value: chartTypes.NAV_CASHFLOW },
    { label: 'IRR and Benchmark', value: chartTypes.IRR_BENCHMARK },
    { label: 'Fund TVPI/RVPI/DPI', value: chartTypes.FUND_TVPI },
    { label: 'Monthly Total Returns', value: chartTypes.FUND_TVPI },
    { label: 'HISTORICAL_NAV', value: chartTypes.FUND_TVPI },
    { label: 'PER_SHARE_DISTRIBUTION', value: chartTypes.FUND_TVPI }
]

 
export const yearsDropDownValues = (() => {
    const currentYear = new Date().getFullYear();
    const yearList = [];

    for (let i = 0; i < 7; i++) {
        const year = currentYear - i;
        yearList.push({ label: `${year}`, value: year });
    }

    return yearList;
})();
