import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import FileUploadButton from '../FileUploadButton/FileUploadButton';
import secureLocalStorage from 'react-secure-storage';
import ActionButton from '../ActionButton/ActionButton';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
const DialogPopup = (props) => {

    const { open, onClose, onAccept ,setSupportedFile,SupportedFile,loading, isFundTerminated} = props;

    // const isFundTerminated = secureLocalStorage.getItem("selectedFund")?.FundTermination;


  // Define custom styles for the dialog paper
  const dialogPaperStyles = {
    
  };

  const handleFileUpload = (name, file) => {
    if (file) { 
        const formData = new FormData();
        formData.append(name, file, file.name);
        handleChange(name, file);
    }
    else{
        handleChange(name, file);
    }
}


  const handleChange = (field, value) => {
     /*   */
     setSupportedFile(value);
  }
    

  return (
    <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{ style: dialogPaperStyles }}>
            
            <DialogTitle id="alert-dialog-title"> 
                   {isFundTerminated ? 'Fund Reinstate' : 'Fund Termination'}
            </DialogTitle>

                <DialogContent sx={{marginTop: '10px'}}>
                      {isFundTerminated ? `Please confirm the reinstatement of fund and attach a signed document, preferably on fund letterhead,
               explaining the reason for reinstating the fund and providing supporting evidence.` :
      `Please confirm the termination of the fund and attach a signed document,
                 preferably on fund letterhead, explaining the reason for termination and providing supporting evidence`}
                </DialogContent>
                
                <DialogContent sx={{marginTop: '-10px'}}>
                    <FileUploadButton
                            name="document"
                            label="UPLOAD DOCUMENT"
                            defaultFile={SupportedFile}
                            pdffileonly = {true}
                            onChange={(name, value) => handleFileUpload(name, value)} 
                            />
                </DialogContent>

                <DialogContent sx={{marginTop: '-10px'}}>
                {isFundTerminated ? (
                    <div>
                        <b>*Important Note:</b> Once the reinstatement is confirmed, the restrictions on the Fund Manager's privileges will be removed. A notification email, including the attached document explaining the reinstatement of the fund, will be sent to all fund investors.
                    </div>
                ) : (
                    <div>
                        <b>*Important Note:</b> Once the termination is confirmed, the Fund Manager's privileges will be restricted. A notification email, including the attached document that explains the termination, will be sent to all fund investors.
                    </div>
                )}

                  </DialogContent>
                    
               

            <DialogActions>
            <Button 
                      onClick={onClose} 
                      variant="outlined"
                      disabled={loading} className="btn-primary">
                    <CancelIcon/>
                    Cancel
                    </Button>
                <ActionButton 
                    onClick={onAccept} 
                    disabled={SupportedFile!=null ? false : true} 
                    loading={loading}
                    icon={<CheckCircleIcon/>}
                    label="Confirm"
                    styleProps={{
                        padding: '8px 30px'
                    }} />
                   
            </DialogActions>
    </Dialog>
  )
}

export default DialogPopup