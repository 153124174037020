export const InputTypes = {
    Text: 'text',
    SELECT : 'select',
    MULTI_SELECT : 'multiselect',
    AUTOSELECT:'AUTOSELECT'
}

export const DatePickerInputTypes = {
    DATE: 'onlydate',
    DATE_AND_TIME : 'dateandtime',
}

export const FieldVariants = {
    STANDARD: 'standard',
    OUTLINED : 'outlined',
    FILLED : 'filled',
 }

 export const textFormatTypeEnum = {
    US_PHONE_NUMBER: 'usPhoneNumberFormat',
    US_CURRENCY: 'usCurrencyFormat',
    ZIPCODE: 'zipcode',
    PERCENTAGE: 'percentage',
    ALPHA_NUMERIC: 'alphaNumeric',
    ONLY_ALPHABETS: 'onlyAlphabets',
    ONLY_NUMBERS: 'onlyNumbers',
    EMAIL_ADDRESS: 'emailaddress',
    USER_NAME: 'username',
    REQUIRED: 'required',
    TIN: 'tax identification number',
    PASSWORD: 'password',
    NONE: ''
}

 

export const validationsTypeEnum = {
   BETWEEN_CHARACTERS: 'betweenCharLimit',
   ZIP_CODE: 'zipCode',
   MIN_CHARACTERS: 'minCharLimit',
   MAX_CHARACTERS: 'maxCharLimit',
   ONLY_ALPHABETS: 'onlyAlphabets',
   ONLY_NUMBERS: 'onlyNumbers',
   US_PHONE_NUMBER: 'usPhoneNumberFormat',
   PERCENTAGE: 'percentage',
   EMAIL_ADDRESS: 'emailaddress',
   USER_NAME: 'username',
   AGE_VALIDATION: 'ageValidation',
   FUTURE_VALIDATION: 'nofuturedates',
   NON_NEGATIVE: 'nonNegative',
   REQUIRED: 'required',
   ONLY_PDF_FILE: 'pdfFile',
   ALPHA_NUMERIC : 'alphaNeumric',
   URL : 'url',
   TIN: 'tax identification number',
   ACCOUNT_NUMMBER: 'accountnumber'
}

export const validationMessages = {
    ONLY_ALPHABETS: 'This Field Only Accepts Alphabets',
    ONLY_NUMBERS: 'This Field Only Accepts Postive Numeric Value',
    US_PHONE_NUMBER: 'Please enter valid Phone Number',
    MIN_CHARACTERS: 'Please provide a brief description (minimum 50 characters).',
    MAX_CHARACTERS: 'Exceeded Minimum Characters',
    BETWEEN_CHARACTERS: 'Characters should be between 5 to 10',
    PERCENTAGE: 'Please enter valid Percentage',
    EMAIL_ADDRESS: 'please enter valid email address',
    REQUIRED: 'This field is required',
    AGE_VALIDATION: 'The Age should be Above 18',
    ONLY_PDF_FILE: 'Please upload a PDF file',
    ONLY_CSV_FILE: 'Please upload a csv or excel file',
    NON_NEGATIVE: 'value should be greater than 0',
    ALPHA_NUMERIC : 'This Field Only Accepts alphanumeric',
    TIN: 'Invalid Tax Identification Number(TIN)',
    USER_NAME: <ul>
                  <li>Username must be at least 6 characters long.</li>
                  <li>Username cannot exceed 35 characters.</li>
                  <li>Username can only contain letters, numbers and one underscore (_)</li>
                  <li>Username cannot contain more than 5 consecutive identical numbers</li>
                  <li>Username Should contain at least one alphabet.</li>
                  <li>Username Should not contain any Reserved or Foul Words</li>
                </ul>,
}


export const textFormatTypesDropDown = [
    { label: 'All Characters', value: 'alphaNumeric' },
    { label: 'Phone Number', value: 'usPhoneNumberFormat' },
    { label: 'Currency', value: 'usCurrencyFormat' },
    { label: 'Zip Code', value: 'zipcode' },
    { label: 'Percentage', value: 'percentage' },
   /*  { label: 'PHONE NUMBER', value: 'alphaNumeric' }, */
    { label: 'Allow Only Alphabets', value: 'onlyAlphabets' },
    { label: 'Allow Only Numbers', value: 'onlyNumbers' },
    { label: 'Email Address', value: 'emailaddress' },
];

export const dateValidationTypeValues = {
    NO_FUTURE_DATES: "no-future-dates-allowed",
    ALLOW_FUTURE_DATES: "allow-future-dates"
};
  

/* US_PHONE_NUMBER: 'usPhoneNumberFormat',
    US_CURRENCY: 'usCurrencyFormat',
    ZIPCODE: 'zipcode',
    PERCENTAGE: 'percentage',
    ALPHA_NUMERIC: 'alphaNumeric',
    ONLY_ALPHABETS: 'onlyAlphabets',
    ONLY_NUMBERS: 'onlyNumbers',
    EMAIL_ADDRESS: 'emailaddress',
    USER_NAME: 'username',
    REQUIRED: 'required',
    TIN: 'tax identification number',
    PASSWORD: 'password',
    NONE: '' */