import { useCallback, useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import React from "react";
import { Box } from "@mui/system";
import BasicTab from "../TabComponent";
import MiniDrawer from "../../common/navigation-bar/NavBarGrid";
import { getrootBackgroundColorConfig } from "../../../state/slices/colorConfigSlice";
import { Grid } from "@mui/material";
import User from "./Users";
import Roles from "./Roles";
import Featues from "./Featues";
import RoleFeatures from "./RoleFeatures";
import { WhiteLabeling } from "../../WhiteLabeling/WhiteLabeling";
import { checkFeaturePermissions } from "../../../utils/common";
import { RolesAndPermissions, FeatureAccess, AppRoles, AppDashboards } from "../../../utils/enum";
import secureLocalStorage from "react-secure-storage";
import ChangePassword from "./ChangePassword";
import OnboardingQuestions from "../InvestorOnBoardingQues/Components/OnboardingQuestions";
import OnBoardingQuesModel from "../InvestorOnBoardingQues/Components/OnBoardingQuesModel";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getHeaderColorConfig } from "../../../state/slices/colorConfigSlice";
import { ComponentsTab } from "../../common/Tabs/ComponentsTab";
import { Key } from "@mui/icons-material";
import GroupIcon from '@mui/icons-material/Group';
import Users from "./Users";
import TabsComponment from "../../common/Tabs/TabsComponment";
import SubscriptionQuestionsMain from "../investor-subscription-questions/components/SubscriptionQuestionsMain";
import CircleIcon from "../../chatbot/Icon";
import { getRoles } from "../../FundManagerDashboard/Services/FundNewsService";
import { getTenantRoleFeatures, getTenantRoles } from "../Services/TabsData";
import KYCAMLMain from "../kyc-aml-configuration/components/KYCAMLMain";
import { HeaderTemplates, PageHeader } from "../../common/PageHeader/PageHeader";
import WorkIcon from '@mui/icons-material/Work';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import BadgeIcon from '@mui/icons-material/Badge';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import LockIcon from '@mui/icons-material/Lock';
import SubscriptionQuestionsModel from "../investor-subscription-questions/components/SubscriptionQuestionsModel";
const AdministrationMain = () => {

  //#region varibales
  const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);
  const headerColorConfig = useSelector(getHeaderColorConfig);
  const [roleNames, setroleNames] = useState([]);
  const [selectedTab,setSelectedTab] = useState(0);
  const [Tabs,setTabs] = useState(null);
  const investorTabs = [
      {
        key : 0,
        icon : <LockIcon/>,
        label : "CHANGE PASSWORD",
        isBackgroundColor: true,
        component : <ChangePassword />
      }
    ]
  const firmTabs = [
    {
        key : 0,
        icon : <GroupIcon/>,
        label : "USERS",
        component : <Users appRoles={roleNames} />
    },
    {
        key : 1,
        icon : <WorkIcon/>,
        label : "ROLES",
        component : <Roles appRoles={roleNames} refreshRoles={setroleNames} />
    },
    {
        key : 2,
        icon : <FeaturedPlayListIcon/>,
        label : "FEATURES",
        isBackgroundColor: false,
        component : <Featues />
    },
    {
        key : 3,
        icon : <BadgeIcon/>,
        label : "ROLE FEATURES",
        isBackgroundColor: false,
        component : <RoleFeatures appRoles={roleNames ?? roleNames} />
    },
    {
        key : 4,
        icon : <BrandingWatermarkIcon/>,
        label : "BRANDING",
        isBackgroundColor: false,
        component : <WhiteLabeling />
    },
    {
      key : 5,
      icon : <ManageAccountsIcon/>,
      label : "KYC / AML",
      isBackgroundColor: false,
      component : <KYCAMLMain />
    },
    {
      key : 6,
      icon : <FactCheckIcon/>,
      label : "Subscription Questions",
      isBackgroundColor: false,
      component : <SubscriptionQuestionsModel />
    },
    {
      key : 7,
      icon : <ListAltIcon/>,
      label : "ONBOARDING QUESTIONS",
      isBackgroundColor: false,
      component : <OnBoardingQuesModel />
    },
    {
        key : 8,
        icon : <LockIcon/>,
        label : "CHANGE PASSWORD",
        isBackgroundColor: false,
        component : <ChangePassword />
    },
  ]

  //#region api get calls
  const getRoleName = async () => {
    const data = await getTenantRoles();
    if (data.responseCode === 200) {
      // TODO : we need to show roles other than investors here
      setroleNames(data?.responseData?.filter(role => role.roleName?.toLowerCase() != "investor" || "Portfolio Manager"));
    }
    else {
      setroleNames([]);
    }
  }

  //#region useEffect
  useEffect(()=>{
    getRoleName();
  }, []);

  useEffect(() => {
    const havepermission = checkFeaturePermissions(RolesAndPermissions.SITE_ADMINISTRATION,FeatureAccess.READ);
    if (secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD || 
        secureLocalStorage.getItem("userrole") === AppDashboards.PC_DASHBOARD) {
      setTabs(investorTabs)
    }
    else if(havepermission){
      setTabs(firmTabs)
    }
    else{
      setTabs(investorTabs)
    }
  }, [roleNames]);

  //#region return
  return (
    <div style={{ backgroundColor: rootBackColorConfig.color }} className="page">
        <PageHeader
            title="Application Settings"
            template={HeaderTemplates.NORMAL_VIEW}
            isHideBackbtn={true}
            isShowButton={false}/>
        <div className="wrapper margin-top-10">
          <Box overflowx={'auto'} >
            <Grid container >
                <Grid container>
                  {Tabs && 
                      <Grid xs={12} >
                        <TabsComponment tabs={Tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} isScrollable={true} />
                      </Grid>}
                </Grid>
            </Grid>
          </Box>
        </div>
        <CircleIcon/>
    </div>
  )

};

export default AdministrationMain;