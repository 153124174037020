
import axiosApiCall from '../../Services/axiosApiCall';
import { getAPIResponse } from '../../../utils/common';

export async function getFirmWideDashboardDetails() {
    return await axiosApiCall.get(`/v1/FirmWide/GetFirmWideDetails`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}