import React from 'react'
import stylesfortab from '../../dashboards/FundDetailDashboards/OverView.module.css'
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useState , useEffect} from 'react';

import secureLocalStorage from 'react-secure-storage';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import { HeaderTemplates, PageHeader } from '../../common/PageHeader/PageHeader';
import { Tabs } from '../../common/Tabs/Tabs';


export const FundUserTabTemplateType={
    OVERVIEW: 'overview',
    PERSONALINFO: 'personal-info',
    BANKINGINFO: 'banking-info',
    INVESTMENTPROFILE: 'investment-profile',
  }

const FundUserHeader = (props) => {

  const navigate = useNavigate();
  const params = useParams();

  const [currentTab,setCurrentTab]=useState(props.selectedTab);
  
  const handleTabSelection = (selectedTabValue) => {
    setCurrentTab(selectedTabValue)
    navigate(`/fm-user-profile/${selectedTabValue}`);
  };

 
  const FundName = props.FundName; // Access the FundName prop
  /* console.clear(); */

  /* let parsedFundId;
  try {
    parsedFundId = JSON.parse(fundDetail);
  } catch (error) {
    console.error("Error parsing fundDetail:", error);
  } */

  /* const fundId = parsedFundId?.FundId;  */

  console.log(FundName);

  const editfund = () =>{
    navigate('/edit-fund-page');
  }

  const FundUserTabsConfig=[
    {
      icon: <InfoOutlinedIcon />,
      label: 'OVERVIEW',
      key: FundUserTabTemplateType.OVERVIEW
    },
    {
      icon: <PersonOutlineOutlinedIcon  />,
      label: 'PERSONAL INFORMATION',
      key: FundUserTabTemplateType.PERSONALINFO
    },
    {
      icon: <AccountBalanceOutlinedIcon  />,
      label: 'BANKING INFORMATION',
      key: FundUserTabTemplateType.BANKINGINFO
    },
    {
      icon: <MonetizationOnOutlinedIcon  />,
      label: 'INVESTMENT PROFILE',
      key: FundUserTabTemplateType.INVESTMENTPROFILE
    }, 
  ]

  const navigateBackto = () => {
        navigate("/FundManager-Dashboard")
  }
  
  return (
    <div>
         <PageHeader 
          template={HeaderTemplates.USER} 
          isNavigate={navigateBackto}
          
        />
        <div className={stylesfortab.dashboardTabsCont}>
          <div className={stylesfortab.dashboardTabsSubCont}>
            <Tabs tabSelected={FundUserTabsConfig.findIndex(tab=>tab.key==params.detail)} tabs={FundUserTabsConfig} onSelectTab={handleTabSelection} />
          </div>
        </div>
    </div>
  )
}

export default FundUserHeader