import React, { useEffect, useState } from 'react'
import AIPDataGrid from '../DataGrid/AIPDataGrid';
import CurrencyIndicator from '../DataGrid/CurrencyIndicator';
import prevYearsValues from '../Functions/YearsData';
import { FieldVariants } from '../TextInput/appInputenum';
import { Grid, MenuItem, TextField } from '@mui/material';
import { getFundTransactions } from './services';
import secureLocalStorage from 'react-secure-storage';
import { PrivateCreditTransactionTypeOptions, realEstateTransactionTypeOptions, transactionTypeOptions } from './apiDataTemplate';
import { fundTypesEnum } from '../../marketplace-dashboard/jsonData';

const FundTransactions = (props) => {

  //#region props
  const { fundTypeName } = props;

  //#region initialdata
  const filterInitialData = {
    'selectedYear': 2024,
    'transactionType': 'All'
  }

  //#region variables
  const [filtersData, setFiltersData] = useState(filterInitialData);
  const [fundTransactions, setFundTransactions] = useState([]);
  const AccountTransactionColums = [
    {
      "id": 1,
      "field": "transactionDate",
      "headerName": "Date",
      "width": 205
    },
    {
      "id": 2,
      "field": "transactionType",
      "headerName": "Transaction type",
      "width": 225
    },
    {
      "id": 3,
      "field": "portfolioCompanyName",
      "headerName": fundTypeName === fundTypesEnum.REAL_ESTATE_FUND ? "Asset name" : "Portfolio Company name",
      "width": 315
    },
    {
      "id": 4,
      "field": "amount",
      "headerName": "Amount",
      "width": 205,
      renderCell: (params) => {
        return <CurrencyIndicator params={params} />
      }
    }
  ]

  //#region change events
  const handleFiltersChange = (ev) => {
    const { name, value } = ev.target;
    setFiltersData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  //#region api get calls
  const getTransactions = async (transactionType, selectedYear) => {
    const fundID = secureLocalStorage.getItem("FundId");
    const data = await getFundTransactions(fundID, transactionType, selectedYear);
    if (data.responseCode === 200) {
      setFundTransactions(data.responseData);
    }
    else {
      //console.log('unable to fetch data)
    }
  }

  //#region useeffect
  useEffect(() => {
    getTransactions(filtersData.transactionType, filtersData.selectedYear);
  }, [filtersData])

  //#region render fucntions //TOD0:Need to use this function in Tranmsaction Type Dropdown
  const renderTransactionDropsOptions = () => {
    return (
      <>
        {fundTypeName === fundTypesEnum.PRIVATE_CREDIT ?
          PrivateCreditTransactionTypeOptions.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>)) :
          fundTypeName === fundTypesEnum.REAL_ESTATE_FUND ?
            realEstateTransactionTypeOptions?.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>)) :
            transactionTypeOptions?.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
      </>
    )
  }

  return (
    <Grid container className='space-between'>
      <Grid item>
        <h6>Fund Transactions</h6>
      </Grid>
      <Grid item className='fundTrans-selectors'>
        <TextField className='transYear'
          select
          name='selectedYear'
          label="Year"
          size='small'
          fullWidth
          variant={FieldVariants?.OUTLINED}
          value={filtersData?.selectedYear}
          onChange={handleFiltersChange}>
          {prevYearsValues?.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField className='transType'
          select
          name='transactionType'
          label="Transaction Type"
          size='small'
          fullWidth
          variant={FieldVariants?.OUTLINED}
          value={filtersData?.transactionType}
          onChange={handleFiltersChange}>
          {fundTypeName === fundTypesEnum.PRIVATE_CREDIT ?
            PrivateCreditTransactionTypeOptions.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>)) :
            fundTypeName === fundTypesEnum.REAL_ESTATE_FUND ?
              realEstateTransactionTypeOptions?.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>)) :
              transactionTypeOptions?.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
        </TextField>
      </Grid>

      <div className='transGrid'>
        <AIPDataGrid
          columns={AccountTransactionColums}
          rows={fundTransactions?.length > 0 ?
            fundTransactions?.map((item, index) => {
              return {
                id: index,
                ...item
              }
            })
            : []} />
      </div>
    </Grid>
  )
}

export default FundTransactions