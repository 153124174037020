import axios from 'axios';
import resolve from '../../../common/resolve';
import secureLocalStorage from 'react-secure-storage';
import axiosApiCall from '../../../Services/axiosApiCall';
import { getAPIResponse } from '../../../../utils/common';



export  async function getInvestorDetails(emailId) {
    try {
        const res = await axiosApiCall.get(`/v1/User/GetUserDetails?emailAddress=${emailId}`);
        return getAPIResponse(res);
    } catch (error) {
        return getAPIResponse(error);
    }
}

export  async function UpdateProfileInformation(data,userId) {
    try {
        const res = await axiosApiCall.put(`/v1/User/UpdateProfileDetails?userId=${userId}`, data);
        return getAPIResponse(res);
    } catch (error) {
        return getAPIResponse(error);
    }
}
            
export async function CreateBankingInformation(data) {
    try {
        const res = await axiosApiCall.post(`/v1/UserBankDetails/SetUserBankDetails`, data);
        return getAPIResponse(res);
    } catch (error) {
        return getAPIResponse(error);
    }
  }

              
export  async function UpdateProfileBankInformation(data,userId) {
  try {
        const res = await axiosApiCall.put(`/v1/User/UpdateProfileBankDetails?userId=${userId}`, data);
        return getAPIResponse(res);
    } catch (error) {
        return getAPIResponse(error);
    }
}


              
export  function UpdateProfileInvestmentInformation(data,userId) {
    try {     
    const response = axios.put(`/v1/User/UpdateUserInvestmentDetails?userId=${userId}`, data); 
     /*   */   
     return response;   
    }
      catch (error) {  
           console.error('Error updating Profile Information:', error);  
              throw error;   } }