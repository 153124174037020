import React, { useState } from "react";
import { Link } from "react-router-dom";
import CircleIcon from "../../chatbot/Icon";
import secureLocalStorage from "react-secure-storage";
const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailSuccess, setEmailSuccess] = useState("");
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onClick = () => {
    setEmailError('');

    if (!email || !/^[^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*@\w+(\.\w+)+$/.test(email)) {
      setEmailError("Please enter valid email address");
    } else {
      setEmailError("");
    }

  }

  return (
    <div className="regwithothersbox">
        <img className="res-logo" src="/Alt360-Logo-Royal-SM.png" alt="Alt360" />
            <div className="signup-header">
              <h3>Forgot Password?</h3>
            </div>
            <p className="text-danger text-center"> {emailError} </p>
            <p className="text-success text-center"> {emailSuccess} </p>
            <div className="reg-form">
              <div className="inputGroup">
                <input
                  type="Email Address"
                  placeholder="Email Address"
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>
              <button 
                className="btn-orange"
                  onClick={onClick}>
                  RESET PASSWORD
                </button>
              
            </div>
            <div className="google-recaptcha">
              This site is protected by reCAPTCHA and the
              <p> Google<a href="#"> Privacy Policy </a> and <a href="#"> Terms of Service </a> apply.
               </p>
            </div>
            <CircleIcon/>   
      
        
        </div>
      
  );
};
export default ForgotPassword;
