import { Link } from "react-router-dom";
import { useState } from "react";
import CircleIcon from "../../chatbot/Icon";
const CreateNewPswd = () => {
  const [data, setData] = useState({
  });
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };
  return (
    <div className="login d-flex align-items-center py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-9 col-lg-8 mx-auto">
            <div className="login-header">
              <h3 className="login-heading text-center">
                
              Create a new password
              </h3>
            </div>
            <div className="login-form">
              <div className="d-flex position-relative my-3 inputGroup text-center">
              <input
                 type={passwordShown ? "text" : "password"}
                 className="border-0 flex-grow-1"
                 placeholder="Password"
                 name="password"
                 value={data.password}
                 onChange={handleInputChange}

                  
                />
                <i
                      id="togglePassword"
                      onClick={togglePasswordVisibility}
                    >
                      {passwordShown ? (
                        <img src="icons/eye-open.svg" alt="Eye Open" className="eye-open-icon" />
                      ) : (
                        <img src="icons/eye-close.svg" alt="Eye Closed" className="eye-closed-icon" />
                      )}
                    </i>
              
               
              </div>
              <div className="d-flex position-relative my-3 inputGroup text-center">
                <input
                  type={confirmPasswordShown ? "text" : "password"}
                  className="border-0 w-100"
                  placeholder="Confirm Password"
                  name="confirmpassword"
                  value={data.confirmpassword}
                  onChange={handleInputChange}
                />
                <i
                      id="toggleConfirmPassword"
                      onClick={toggleConfirmPasswordVisibility}
                    >
                      {confirmPasswordShown ? (
                        <img src="icons/eye-open.svg" alt="Eye Open" className="eye-open-icon" />
                      ) : (
                        <img src="icons/eye-close.svg" alt="Eye Closed" className="eye-closed-icon" />
                      )}
                    </i>
                
                
              </div>
              <Link
                className="btn w-100 my-1 shadow-none my-4 btn-login"
                to="/onboarding-process"
              >
                <span className="text-decoration-none w-100">
                  CONFIRM PASSWORD
                </span>
              </Link>
              </div>
            <div className="recaptcha1 mt-5 text-center">
              This site is protected by reCAPTCHA and the
              <p> Google
             <a className="privacy_TC-links" href="#"> Privacy Policy </a>
             and 
              <a className="privacy_TC-links" href="#"> Terms of Service </a>
              Apply
               </p>
            </div>
          <CircleIcon/>
        </div>
        </div>
        </div>
        </div>
        
      
      
      
                      
  
    
  );
};

export default CreateNewPswd;
