import React from 'react'
import TextInput from '../../../common/TextInput/TextInput';
import { useState } from 'react';
import '../firmuserprofile.css';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import FileUploadButton from '../../../common/FileUploadButton/FileUploadButton';
import { getFirmStructureDetails } from '../services/firmdetaailsservices';
import { getFirmTypeDetails } from '../services/firmdetaailsservices';
import { getRegulatoryComplianceDetails } from '../services/firmdetaailsservices';
import { useEffect } from 'react';
import { regulatorOptions } from '../enum';
import { updateFirmDetails } from '../services/firmdetaailsservices';
import { ToastContainer, toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
import ActionButton from '../../../common/ActionButton/ActionButton';
import { width } from '@mui/system';

const FirmDetails = (props) => {

  const { initalDetails, setInitialDetails, getTenantDetails, firmStructureDropdown,
    firmTypeDropdown,
    regulatoryComplianceDropdown,
    otherOptionID,
  } = props;

   

  const [loading, setLoading] = useState(false);
  const [componentRefresh,setComponentRefresh] = useState(true)
  /*   const initialDetails = {
      firmName: '',
      firmTypeId: '',
      firmLogo: '',
      firmId: '',
      registrationNumber: '',
      regulatoryComplianceStatusId: '',
      firmDescription: '',
      firmAddress: '',
      website: '',
      firmDescription: '',
      firmStructure: ''
    }; */

  const [firmDetails, setFirmDetails] = useState(initalDetails);
  const [isSaveEnabled, setisSaveEnabled] = useState(false);

  //onEditSaveButtonClick
  const onEditSaveButtonClick = () => {
    if (isSaveEnabled) {
      handleSubmit();
      return
    }
    setisSaveEnabled(!isSaveEnabled);
    props.setisSaveEnabled(!isSaveEnabled);
  };

  //onCancel
  const onCancel = () => {
    setComponentRefresh(false)
    setisSaveEnabled(false);
    props.setisSaveEnabled(false);
    setErrors({});
    setTimeout(() => {
      setComponentRefresh(true)
      setFirmDetails(initalDetails)
    }, 500);
  };

  //Validations
  const [errors, setErrors] = useState({});

  const MandatoryFieldErrors = () => {
    const fieldsToKeep = ['firmName', 'firmTypeId', 'firmLogo', 'firmId', 'registrationNumber', 'regulatoryComplianceStatusId', 'firmDescription', 'firmAddress', 'website', 'firmDescription', 'firmStructure'];
    const trimmedValues = { ...firmDetails };

    const filteredFields = fieldsToKeep.map((field) => {
      if (trimmedValues.hasOwnProperty(field)) {
        return { field, value: trimmedValues[field] };
      }
      return null;
    }).filter(Boolean);

    const Mandatory = filteredFields.map(({ field, value }) => ({
      field,
      value: value === "" ? null : value,
    }));

    console.log(Mandatory);
    const EmptyFields = Mandatory.filter(entry => entry.value === "" || entry.value === null).map(entry => entry.field);
    const error = `This field is required.`;
    //  
    EmptyFields.length > 0 && EmptyFields.map((item) => {
      //           
      setErrors(prevErrors => ({ ...prevErrors, [item]: error }));
    })
  };

  const validateField = (field, value) => {
    const validationRules = {
      firmName: (value, currState) => {
        const isValid = /^[A-Za-z ]+$/.test(value);
        return {
          isValid,
          error: isValid ? null : `Please Enter a valid name with only alphabets`
        };
      },
      phoneNo: (value, currState) => {
        value = value.trim();
        const isValid = /^\d{10}$/.test(value);
        return {
          isValid,
          error: isValid ? null : "Please enter a valid phone number (up to 10 digits)",
        };
      },
      firmDescription: (value, currState) => {
        value = value.trim();
        const isValid = value.length >= 1 && value.length <= 200;
        return {
            isValid,
            error: isValid ? null : "Please enter a firm description between 1 and 200 characters",
        };    
      },
    };
    return validationRules[field] ? validationRules[field](value) : { isValid: true, error: null };
  };

  const handleChange = (field, value) => {
    const validationResult = validateField(field, value);
    if (!validationResult.isValid) {
      setErrors(prevErrors => ({ ...prevErrors, [field]: validationResult.error }));
    } else {
      setErrors(prevErrors => ({ ...prevErrors, [field]: null }));
      setFirmDetails({
        ...firmDetails,
        [field]: value
      });
    }
  }

  const handleFileUpload = (name, file) => {
    if (file) {
      const formData = new FormData();
      formData.append(name, file, file.name);
      handleChange(name, file);
    }
  }

  const handleSubmit = async() => {

    setLoading(true);
    const requestBody =
    {
      "firmId": firmDetails.firmId,
      "firmName": firmDetails.firmName,
      "firmTypeId": firmDetails.firmTypeId,
      "firmLogo": "",
      "registrationNumber": firmDetails.registrationNumber,
      "regulatoryComplianceStatusId": firmDetails.regulatoryComplianceStatusId,
      "firmDescription": firmDetails.firmDescription,
      "website": firmDetails.website,
      "jurisdiction": firmDetails.jurisdiction,
      "firmStructureId": firmDetails.firmStructureId,
      "firmAddress": firmDetails.firmAddress,
      "tenantGUID": secureLocalStorage.getItem("tenantID"),
      "regulatoryComplianceOtherStatus": firmDetails.regulatoryComplianceOtherStatus || "",
    }

    const includedFields = ['firmName', 'firmType', 'regulatoryBody', 'firmDescription', 'firmStructure'];

    const Mandatoryerrors = Object.entries(firmDetails).some(([key, value]) =>
      includedFields.includes(key) && (value === null || value === '' || value === 0)
    );


    const errorsArray = Object.values(errors);
    const hasErrors = errorsArray.some(error => error !== null);

    if (Mandatoryerrors || hasErrors) {
      console.error("Error updating profile:", errors);
      MandatoryFieldErrors();
      setLoading(false);
      toast.warning("Please fill Mandatory Values", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    } else {
      const formData = new FormData();
      formData.append('firmDetails', JSON.stringify(requestBody));
      if (firmDetails.firmLogo !== null && firmDetails.firmLogo !== undefined && firmDetails.firmLogo !== "") {
        if (firmDetails.firmLogo instanceof Blob) {
          formData.append('firmFile', firmDetails.firmLogo, firmDetails.firmLogo.name);
        }
      }
      const data = await updateFirmDetails(formData);
      /*   */
      if (data.responseCode !== 200) {
        toast.warning("Entity Details Update Failed",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        setLoading(false);
        setisSaveEnabled(false);
        props.setisSaveEnabled(false);
      }
      else {
        toast.success("Entity Details Update Sucessfully",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        setLoading(false);
        getTenantDetails();
        setisSaveEnabled(false);
        props.setisSaveEnabled(false);
      }
    }
  }


  return (
    <div>
      {
        componentRefresh &&       <div>
        <div className='space-between'>
          <div></div>
          <div className='space-between'>
            <div style={{ marginRight: '5px' }}>
              {isSaveEnabled &&
                <ActionButton className='btn-primary'
                  icon={<CancelIcon />}
                  onClick={() => onCancel()}
                  variant='outlined'
                  label='Cancel'
                >
                  
                </ActionButton>
              }
            </div>
            <div>
              <ActionButton className='btn-primary'
                loading={loading}
                icon={isSaveEnabled ? <SaveIcon /> : <EditIcon />}
                onClick={() => onEditSaveButtonClick()}
                variant='contained'
                label={isSaveEnabled ? 'Save' : 'Edit'}>
              </ActionButton>
  
            </div>
          </div>
        </div>
        <div className={isSaveEnabled ? "whiteCard margin-top-15" : "whiteCard margin-top-15 disablediv"}>
          <div className='firmprofilerow margin-top-15'>
            <div className='firmprofilechild'>
              <TextInput
                type="text"
                name="firmName"
                label="Firm Name"
                defaultValue={firmDetails?.firmName}
                required={true}
                editable={false}
                onChange={(name, value) => handleChange(name, value)}
                errormessage={errors.firmName || undefined}
              />
            </div>
            <div className='firmprofilechild'>
              <TextInput
                type="select"
                name="firmTypeId"
                label="Firm Type"
                defaultValue={firmDetails?.firmTypeId}
                required={true}
                options={firmTypeDropdown
                  ? firmTypeDropdown?.map(option => ({ label: option?.listItemValue, value: option?.listItemID }))
                  : []
                }
                onChange={(name, value) => handleChange(name, value)}
                errormessage={errors.firmTypeId || undefined}
              />
            </div>
            <div className='firmprofilechild'>
              <FileUploadButton
                name="firmLogo"
                label='Firm logo'
                onChange={(name, value) => handleFileUpload(name, value)}
                isImage={true}
                defaultFile={firmDetails?.firmLogo}
                fromWhiteLabeling={true}
                isSize={true} style={{ marginRight: '' }} />
            </div>
          </div>
          <div className='firmprofilerow'>
            <div className='firmprofilechild'>
              <TextInput
                type="text"
                name="firmId"
                label="Entity ID"
                defaultValue={firmDetails?.tenantGUID}
                required={true}
                editable={false}
                onChange={(name, value) => handleChange(name, value)}
                errormessage={errors.firmId || undefined}
              />
            </div>
            <div className='firmprofilechild'>
              <TextInput
                type="text"
                name="registrationNumber"
                label="Firm Registration Number"
                editable={false}
                required={true}
                defaultValue={firmDetails?.registrationNumber}
                onChange={(name, value) => handleChange(name, value)}
                errormessage={errors.registrationNumber || undefined}
              />
            </div>
            <div className='firmprofilechild'>
              <TextInput
                type="select"
                name="regulatoryComplianceStatusId"
                required={true}
                label="Regulatory Body"
                defaultValue={firmDetails?.regulatoryComplianceStatusId}
                options={regulatoryComplianceDropdown
                  ? regulatoryComplianceDropdown?.map(option => ({ label: option?.listItemValue, value: option?.listItemID }))
                  : []
                }
                onChange={(name, value) => handleChange(name, value)}
                errormessage={errors.regulatoryComplianceStatusId || undefined}
              />
            </div>
          </div>
          <div className='firmprofilerow'>
            <div className='firmprofilechild'>
              <TextInput
                type="text"
                name="jurisdiction"
                label="Firm Jurisdiction"
                defaultValue={firmDetails?.jurisdiction}
                onChange={(name, value) => handleChange(name, value)}
                errormessage={errors.jurisdiction || undefined}
              />
            </div>
            <div className='firmprofilechild'>
              <TextInput
                type="text"
                name="firmAddress"
                label="Address"
                defaultValue={firmDetails?.firmAddress}
                onChange={(name, value) => handleChange(name, value)}
                errormessage={errors.firmAddress || undefined}
              />
            </div>
            <div className='firmprofilechild'>
              <TextInput
                type="text"
                name="website"
                label="Website URL"
                defaultValue={firmDetails?.website}
                onChange={(name, value) => handleChange(name, value)}
                errormessage={errors.website || undefined}
              />
            </div>
          </div>
          <div className='firmprofilerow'>
            <div className='firmprofilechild'>
              <TextInput
                type="select"
                name="firmStructureId"
                label="Firm Structure"
                required={true}
                defaultValue={firmDetails?.firmStructureId}
                options={firmStructureDropdown
                  ? firmStructureDropdown?.map(option => ({ label: option?.listItemValue, value: option?.listItemID }))
                  : []
                }
                onChange={(name, value) => handleChange(name, value)}
                errormessage={errors.firmStructureId || undefined}
              />
            </div>
            {otherOptionID &&
              firmDetails.regulatoryComplianceStatusId === otherOptionID && <div className='firmprofilechild'>
                <TextInput
                  type="text"
                  name="otherregulatoryBody"
                  label="Other Option"
                  defaultValue={firmDetails?.otherregulatoryBody}
                  required={true}
                  onChange={(name, value) => handleChange(name, value)}
                  errormessage={errors.otherregulatoryBody || undefined}
                />
              </div>
            }
            <div className='firmprofilechild'>
  
            </div>
          </div>
          <div className='firmprofilerow'>
            <div style={{ width: "100%" }}>
            <TextInput  
              type="text"
              name="firmDescription"
              label="Firm Description"
              multiline={true}
              required={true}
              rows={4}
              defaultValue={firmDetails?.firmDescription}
              onChange={(name, value) => handleChange(name, value)}
              errormessage={errors.firmDescription || undefined}
            />
            </div>
          </div>
        </div>
        <ToastContainer />
        </div>
      }
    </div>
  )
}

export default FirmDetails