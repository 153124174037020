import resolve from '../../common/resolve.js';
import axiosApiCall from '../../Services/axiosApiCall.js';
import { getAPIResponse } from '../../../utils/common.js';
import secureLocalStorage from 'react-secure-storage';

export async function updateUserProfileIconData(formDataBody, emailAddress) {
    return await axiosApiCall.post(`/v1/UserProfile/UploadUserProfileImage?emailAddress=${emailAddress}`, formDataBody)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getobqQuestionOptions(details) {
    return await axiosApiCall.get(`/v1/QuestionBank/GetQuestionAnswerDetailsOnStep?step=${details.step}&questionType=${secureLocalStorage.getItem("investorType")}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getInvestorUserProfile(emailAddress) {
    return await axiosApiCall.get(`/v1/UserProfile/GetUserProfile?emailAddress=${emailAddress}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getOtpID(updateEmailAddress = "") {
    return await axiosApiCall.get(`/v1/User/SendOTPForEmailUpdate?updateEmailAddress=${updateEmailAddress}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function PostOTP(data) {
    return await axiosApiCall.put(`/v1/User/VerifyOTPAndEmailUpdate`, data)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}



export async function getOTPAttemptsIn24Hours() {
    return await axiosApiCall.get(`/v1/User/OTPAttemptsIn24Hours`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}