import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { getCompanybyID } from '../Services/CompaniesDetails';
import { GetCompanyFitlerDetails } from '../Services/CompaniesDetails';
import secureLocalStorage from 'react-secure-storage';
import { OnBoardingPopupModel } from './OnBoardingPopups/OnBoardingPopupModel';
import { getCompanyDetailsbyPCmanagerID } from '../Services/CompaniesDetails';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import  Avatar from '@mui/material/Avatar';
import { height } from '@mui/system';
import DoneIcon from '@mui/icons-material/Done';
import Thankyou from './OnBoardingPopups/Thankyou';

const StepItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: '60px',
  border: '1px solid black',
  textAlign: 'left',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: '16px',

}));




const OnBoardingStepContainer = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(null);
  const [stepTitle, setStepTitle] = useState("");
  const [companyProfileDetails, setCompanyProfileDetails] = useState();
  const [OpenDialog, setOpenDialog] = useState(false);

  const handleStepClick = (currstep, title) => {
    setActiveStep(currstep);
    setStepTitle(title);
    setOpenDialog(true);
  }

  const [stepsData,setStepsData] = useState([
    { stepno: 1, text: 'Step 1', step: 'Company Profile',isValidated: false },
    { stepno: 2, text: 'Step 2', step: 'Leadership Team',isValidated: false },
    { stepno: 3, text: 'Step 3', step: 'Legal Counsel',isValidated: false },
    { stepno: 4, text: 'Step 4', step: 'Accounting Firm',isValidated: false },
    { stepno: 5, text: 'Step 5', step: 'Board Members' ,isValidated: false},
    { stepno: 6, text: 'Step 6', step: 'Upload Due Diligence Documentation' ,isValidated: false},
  ]);
  
  const updateIsValidated = (maxStep) => {
    const stepNumbers = Array.from({ length: maxStep }, (_, index) => index + 1);
  
    const updatedSteps = stepsData.map((step) => {
      if (stepNumbers.includes(step.stepno)) {
        return { ...step, isValidated: true };
      }
      return step;
    });
  
    console.log(updatedSteps);
    setStepsData(updatedSteps);
  };
  
  

  const [FiltersData, setFiltersData] = useState();
  const getFiltersData = async () => {
    const data = await GetCompanyFitlerDetails();
    if (data.responseCode === 200) {
      console.log(data.responseData);
      setFiltersData(data.responseData);
    } else {
      console.log("data.responseData");
    }
  }

  const getDetailsbyPCID = async () => {
    /* const companyID = secureLocalStorage.getItem("companyID"); */
    const data = await getCompanyDetailsbyPCmanagerID(secureLocalStorage.getItem("userId"));
    if (data.responseCode === 200) {
      console.log(data.responseData);
      // const companyDetails = data.responseData;
      setCompanyProfileDetails(
        data.responseData
      );

      updateIsValidated(data.responseData.currentOnboardingStep);

    } else {
      console.log("data.responseData");
    }
  }

  useEffect(() => {
    getFiltersData();
    getDetailsbyPCID();
  }, []);

  const buttonStyleGreen = {
    backgroundColor: 'green',
    border: `1px solid black`,
    /* color: theme.palette.info.main */
    width: '25px',
    height: '25px'
  };

  const InitialbuttonStyle = {
    backgroundColor: 'white',
    border: `1px solid black`,
    /* color: theme.palette.info.main */
    width: '25px',
    height: '25px'
  };



  return (
    <Box className='background-color-white' sx={{ width: '300%', padding: '50px', marginTop: '-40px' }}>
      <h5>Portfolio Company Onboarding</h5>
      <h10>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin sit amet odio accumsan arcu posuere venenatis quis nec mauris. Nam consequat ullamcorper nisl, et blandit dolor blandit eget. Pellentesque id dictum erat. Vivamus risus odio, gravida vitae laoreet et, laoreet et arcu. Nullam felis erat, efficitur id gravida porttitor, aliquet non metus. Sed imperdiet id ex ut auctor. Aenean nisi felis, suscipit laoreet cursus eget, auctor ut ipsum. Nullam ipsum magna, elementum a volutpat vitae.</h10>

      <Grid container rowSpacing={1} columnSpacing={{ xs: 3, sm: 3, md: 4 }} sx={{ marginTop: '30px' }}>
            {stepsData.map((step, index) => (
              <Grid item xs={6} key={index} onClick={() => handleStepClick(step.stepno, step.step)} sx={{ cursor: 'pointer' }}>

                <StepItem >
                  <div>
                  <Avatar style={step.isValidated ? buttonStyleGreen : InitialbuttonStyle}>
                      <DoneIcon />
                  </Avatar>

                      </div>
                          <div>
                            <div style={{ marginLeft: '-220px' }}>{step.text}</div>
                            <div style={{ marginLeft: '-220px' }}>{step.step}</div>
                          </div>
                        <div>
                  </div>
                </StepItem>

              </Grid>
            ))}

      <Grid xs={12} sx={{ marginTop: '20px', justifyContent: 'flex-end' }}>
        <div className="space-between">
          <div>
           
          </div>
          <div >
            <Button
                component="label"
                variant="contained"
                style={{ width: '160px', backgroundColor: 'white', color: 'gray',marginRight: '10px'}}
              >
                CANCEL
              </Button>
              
            <Button
              component="label"
              variant="contained"
              style={{ width: '160px' }}
              disabled={stepsData?.filter(a => a.isValidated)?.length < 5}
              onClick={() => { 
                navigate('/portfoliodetails');
              }}
            >
              SUBMIT
            </Button>
          </div>
        </div>
      </Grid>

      
      </Grid>

      {companyProfileDetails ? OpenDialog && <OnBoardingPopupModel currstep={activeStep} setActiveStep={setActiveStep}
        handleCancelModel={(data) => { setOpenDialog(data) }}
        data={companyProfileDetails} FiltersData={FiltersData}
        title={stepTitle} stepsData={stepsData} updateIsValidated={updateIsValidated} companyName={companyProfileDetails?.companyName}/> : null}

      {/* {activeStep===7 && <Thankyou handleCancelModel={(data) => { setOpenDialog(data) }}/>} */}

    </Box>
  );
}

export default OnBoardingStepContainer;