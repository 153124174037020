import React from "react";
import AuthenticationPage from "./AuthenticationPage";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../AppAuthentication/authConfig";
import ReactLoading from 'react-loading';
import MiniDrawer from "../common/navigation-bar/NavBarGrid";
import { TopNavBar } from "../common/navigation-bar/TopNavBar";
import { Outlet } from "react-router-dom";

export const msalInstance = new PublicClientApplication(msalConfig);

function UserDashboard() {
  return (<div style={{ width:'100%' }}>
    {/* <MiniDrawer />
    <div style={{ width: '100%' }}>
      <TopNavBar />
        <div className="loading-container">
          <ReactLoading type="spin" color="#0000FF" height={100} width={100} />
        </div>
        <div className="alertMessage">
          Please wait while we are loading application...
        </div>
        <AuthenticationPage msalInstance={msalInstance} />
      </div>
      <Outlet /> */}
      <div className="login">

<div className="leftbg">
<span>
  <img src="/Alt360-Logo-white.png" alt="Alt360" />
  </span>
</div>
<div className="loadspace">
<img className="res-logo" src="/Alt360-Logo-Royal-SM.png" alt="Alt360" />
        <div className="alertMessageLoding">
          Please wait while we are loading application...
        </div>
        <div className="loadingdiv">
          <ReactLoading type="spin" color="#0000FF" height={100} width={100} />
        </div>
        <AuthenticationPage msalInstance={msalInstance}/>
        
</div>
 



</div>
  </div>
  )
}

export default UserDashboard
