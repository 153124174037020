import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import resolve from '../../common/resolve';
import axiosApiCall from '../../Services/axiosApiCall';
import { getAPIResponse } from '../../../utils/common';

export async function getMarketPlaceFunds() {
    return await axiosApiCall.get(`/v1/Fund/GetFund?userId=${secureLocalStorage.getItem("userId")}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getFundSubscriptions() {
    return await axiosApiCall.get(`/v1/FundSubscription/GetFundSubscriptions?fundID=0`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function updateFavouriteFund(itemId,isfavorite) {
    return await axiosApiCall.put(`/v1/Fund/UpdateFavoriteFund?investorID=${secureLocalStorage.getItem("userId")}&fundID=${itemId}&isFavoriteFund=${isfavorite}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getFavoriteFunds() {
    return await axiosApiCall.get(`/v1/Fund/GetFavoriteFunds?investorID=${secureLocalStorage.getItem("userId")}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}