import React, { useRef, useState } from "react";
import styled from "styled-components";
import SiteFooter from "../components/Site/SiteFooter";
import SiteLoginHeader from "../components/Site/SiteLoginHeader";

function OnboardingContainer({ children ,
  //  isSearchEnable
   }) {
  return (
    <>
      <SiteLoginHeader 
      // isSearchEnable={isSearchEnable}
      />
      {children}
      {/* <SiteFooter /> */}
    </>
  );
}

export default OnboardingContainer;
