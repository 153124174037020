import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BoxbackgroundWhite } from '../../CommonCss';
import CommentSectionBox from '../../common/CommnetSections/CommentSectionBox';
import { jsoncomments } from './commentsjson';
import InputAdornment from '@mui/material/InputAdornment';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import TextInput from '@mui/material/TextField';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ActionButton from '../../common/ActionButton/ActionButton';
import UploadMultiplefiles from '../Popups/UploadMultiplefiles';
import SendIcon from '@mui/icons-material/Send';
import { getFolders } from '../../subscriptionpages/services/DropdownOptions';
import { getkyccomments } from '../services/services';
import secureLocalStorage from 'react-secure-storage';
import LinearProgress from '@mui/material/LinearProgress';
import { AMLStatusValues, KycAmlStuatus , KycStatusvalues } from '../enum';
import { newComment } from '../services/APIDataTemplate';
import { postkycComment } from '../services/services';
import { ToastContainer, toast } from 'react-toastify';
import { getFolderswithtypeid } from '../../subscriptionpages/services/DropdownOptions';
import FileUploadButton from '../../common/FileUploadButton/FileUploadButton';
import { checkFeaturePermissions } from '../../../utils/common';
import { RolesAndPermissions, FeatureAccess } from '../../../utils/enum';
import CancelIcon from '@mui/icons-material/Cancel';
import { shadow } from 'pdfjs-dist';

const DiscussionBox = (props) => {

  //#region props
  const { setRefreshDetails } = props;

  const [folderNames, setFolderNames] = useState([]);
  const [previousComments, setPreviousComments] = useState(null);
  const [refreshComments,setRefreshComments] = useState(true)
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);
  const [showpopup, setShowpopup] = useState(false);
  const investorDetails = secureLocalStorage.getItem("KYCInvestorDetails");
  const [files, setFiles] = useState([
    {
      id: 1,
      file: "",
    }
  ]);

  //ClickEvent
  const handleSendData = async () => {
    setLoading(true);
    /*     const responsedata = {...newComment}
        responsedata.userComments = comment; */
    const requestBody = {
      "commentID": 0,
      "userComments": comment,
      "commentByUserID": secureLocalStorage.getItem("userId"),
      "commentBy": secureLocalStorage.getItem("fullName"),
      "commentOn": new Date(),
      "entityID": investorDetails?.id,
      "entityTypeID": 3,
      "entityType": "Investor",
      "userIcon": "",
    }
    const formData = new FormData();
    if (files.length > 0) {
      files.forEach((file) => {
        if (file.file !== "") {
          formData.append('fileList', file.file, `${file.path}/${file.file.name}`);
        }
      });
    }
    formData?.append("appComment", JSON.stringify(requestBody));
    if(comment !== ''){
      const response = await postkycComment(formData);
      setLoading(true)
      if (response?.responseCode === 200) {
        setLoading(false);
        toast.success("Comment Posted", { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        
        getComments();
        if(setRefreshDetails){
          setRefreshDetails(true);
          setTimeout(() => {
            setRefreshDetails(false);
          }, 1000);
        }
      } else {
        toast.error("Unable to Post Comment", { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        setLoading(false);
      }
      onCancel();
      setLoading(false);
    }
    else{
      toast.warning("Please fill the data", { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      setLoading(false);
    }
  }

  //Popup Relates
  const onClose = () => {
    setShowpopup(false);
    setFiles([
      {
        id: 1,
        file: "",
        path: ""
      }
    ]);
  }
  const onSubmit = () => {
    setShowpopup(false);
  }
  const onOpen = () => {
    getAllFolderNames();
    setShowpopup(true);
  }

  //handlechange
  const handleChange = (field, value) => {
    setComment(value);
  }

  //handleAddComment
  const onCancel = () => {
    setComment('');
    setFiles([
      {
        id: 1,
        file: "",
        path: ""
      }
    ]);
  }

  //API's
  const getComments = async () => {
    const investorID = investorDetails?.id;
    const data = await getkyccomments(investorID);
    if (data?.responseCode === 200) {
      const reversedData = data.responseData;
      setPreviousComments(data.responseData);
    } else {
      // Handle error if needed
    }
  }

  //fileuplaod
  const handleFileUpload = (name, file) => {
    const findIndex = files.findIndex((item) => item.id === parseInt(name));
    if (file) {
      const formData = new FormData();
      formData.append(name, file, file.name);
      setFiles((prevItems) => {
        return prevItems.map((item) => {
          if (item.id === parseInt(name)) {
            item.file = file;
          }
          return item;
        });
      });
    }
    else{
      setFiles((prevItems) => {
        return prevItems.map((item) => {
          if (item.id === parseInt(name)) {
            item.file = "";
          }
          return item;
        });
      });
    }
  }

  const getAllFolderNames = async () => {
    try {
      const kyctypeid = 2;
      const data = await getFolderswithtypeid("KYCAML");
      if (data.responseCode === 200) {
        const namesAndIds = data.responseData.map((item) => ({
          folderName: item.documentType.replace(/[()?]/g, ''),
          folderID: item.documentTypeID,
          parentFolderID: item.defaultFolderID,
        }));
        setFolderNames(namesAndIds);
      } else {
        // Handle error
      }
    } catch (error) {
      console.error('Error fetching folder names:', error);
    }
  };

  useEffect(() => {
    getComments();
  }, [])


  useEffect(()=>{
    setRefreshComments(false)
    setTimeout(() => {
      setRefreshComments(true)
    }, 500);
  },[props.investorProfileKyc,props.investorProfileAml])

  return (
    refreshComments && <>    <Grid container xs={12} marginTop={2}>
    <Grid item container xs={12} >
    <h6>  Notes and Comments </h6>
    </Grid>
    <Grid item container xs={12} rowGap={2}>
      <Grid item xs={12}>
        <TextInput
          placeholder="Enter your discussion"
          multiline
          name="Enter your discussion"
          value={comment}
          rows={3}
          minRows={5}
          disabled={( ((props?.investorProfileKyc === KycStatusvalues.VERIFIED) && (props?.investorProfileAml === AMLStatusValues.VERIFIED)) )}
          maxRows={5}
          onChange={(e) => setComment(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" style={{ position: 'absolute', top: 20, right: 10 }} disablePointerEvents={((props.investorProfileKyc === KycStatusvalues.VERIFIED) && (props.investorProfileAml === AMLStatusValues.VERIFIED))}>
                <AttachFileIcon
                  sx={{ width: '25px', height: '25px', color: ((props?.investorProfileKyc === KycStatusvalues.VERIFIED) && (props?.investorProfileAml === AMLStatusValues.VERIFIED)) ? 'gray' : "black", cursor: 'pointer !important' }}
                  onClick={onOpen}
                />
              </InputAdornment>
            ),
          }}
          sx={{ width: '100%' }}
        />
      </Grid>
      <Grid container xs={12}>
        <Grid item xs={9.5}>
          {
            files.some((file) => file.file !== "") ? (
              <div>
                <div>Attached Files</div>
                {files.map((file) => (
                  file.file !== "" ? (
                    <div className="fundRow" key={file.id}>
                      <div className="">
                        <FileUploadButton
                          name={file.id}
                          defaultFile={file.file}
                          label="UPLOAD DOCUMENT"
                          onChange={(name, value) => handleFileUpload(name, value)}
                        />
                      </div>
                    </div>
                  ) : null
                ))}
              </div>
            ) : null
          }
        </Grid>
   
        <Grid container xs={12} className='btnsGroup' justifyContent="flex-end">
          <ActionButton variant="outlined" 
                        className="btn-primary" 
                       onClick={onCancel}
                       icon={<CancelIcon/>} 
                       label="Cancel"
                       disabled={!checkFeaturePermissions(RolesAndPermissions.KYC_AML_Verification, FeatureAccess.UPDATE) || (props.currKYCStatus === KycStatusvalues.VERIFIED) && (props.curramlStatus === AMLStatusValues.VERIFIED) || ((props.investorProfileKyc === KycStatusvalues.VERIFIED) && (props.investorProfileAml === AMLStatusValues.VERIFIED))} />
          <ActionButton variant="contained" 
                        className="btn-primary" 
                        loading={loading}
                        onClick={() => handleSendData()} 

                     icon={<SendIcon/>}
                        label="Send" 
                        disabled={(!checkFeaturePermissions(RolesAndPermissions.KYC_AML_Verification, FeatureAccess.UPDATE)) || (props.currKYCStatus === KycStatusvalues.VERIFIED) && (props.curramlStatus === AMLStatusValues.VERIFIED) || ((props.investorProfileKyc === KycStatusvalues.VERIFIED) && (props.investorProfileAml === AMLStatusValues.VERIFIED))}
                         />
        </Grid>
      </Grid>
    </Grid>
    <Grid item container xs={12}>
      {previousComments ? (
        previousComments.map((comment) => (
          <CommentSectionBox
            commentid={comment?.commentID}
            name={comment?.commentBy}
            profileimg={"/icons/defaultpropic.jpg"}
            description={comment?.userComments}
            attachedlinks={comment?.commentAttachments}
            date={comment?.commentOn}
          />
        ))
      ) : (
        <LinearProgress />
      )}
    </Grid>
    {
      showpopup && <UploadMultiplefiles open={showpopup}
        onClose={onClose} files={files}
        onSubmit={onSubmit}
        folderNames={folderNames}
        setFiles={setFiles} />
    }
    <ToastContainer />
  </Grid></>

  )
}

export default DiscussionBox