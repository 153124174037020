import React, { useState } from 'react';
import TextInput from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ActionButton from '../ActionButton/ActionButton';
import { Grid, Link } from '@mui/material';
import AttachmentDocument from './AttachmentDocument';
import secureLocalStorage from 'react-secure-storage';
import { AccountCircle } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import { AddComments, getSubscriptionComments } from './Services/AppDiscussions';
import { deepOrange, deepPurple } from '@mui/material/colors';
import { useEffect } from 'react';
import CommentSectionBox from '../CommnetSections/CommentSectionBox';
import UploadMultiplefiles from '../../KYCVerification/Popups/UploadMultiplefiles';
import FileUploadButton from '../FileUploadButton/FileUploadButton';
import { ToastContainer, toast } from 'react-toastify';
import { getFolders } from '../../subscriptionpages/services/DropdownOptions';
import axiosApiCall from '../../Services/axiosApiCall';
import CancelIcon from '@mui/icons-material/Cancel';
import SendIcon from '@mui/icons-material/Send';
import { de } from 'date-fns/locale';
import { getFundSubscriptionDetails } from '../../investor-fund-subscription/services/services';
import { AppDashboards } from '../../../utils/enum';

const AppDiscussionComponent = (props) => {

  //#region props
  const { refreshComponents, readOnly = false } = props;

  //#region variables
  const [text, setText] = useState('');
  const [comments, setComments] = useState(null);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedFolderName, setSelectedFolderName] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [latestFile, setLatestFile] = useState(null)
  const fullName = secureLocalStorage.getItem('fullName')
  const firstLetter = fullName.charAt(0);
  const [showpopup, setShowpopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const intialFiles = {
    id: 1,
    file: "",
    path: ""
  }
  const [files, setFiles] = useState([intialFiles]);
  const SubscriptionID = props?.subscriptionID
  const [folderNames, setFolderNames] = useState([]);

  //#region change events
  const handleFileUpload = (name, file) => {
    const findIndex = files.findIndex((item) => item.id === parseInt(name));
    if (file) {
      const formData = new FormData();
      formData.append(name, file, file.name);
      setFiles((prevItems) => {
        return prevItems.map((item) => {
          if (item.id === parseInt(name)) {
            item.file = file;
          }
          return item;
        });
      });
    }
    else {
      setFiles((prevItems) => {
        return prevItems.map((item) => {
          if (item.id === parseInt(name)) {
            item.file = "";
          }
          return item;
        });
      });
    }
  }

  //#region click events
  const onfilesSubmit = () => {
    setShowpopup(false);
  }

  const onClose = () => {
    setShowpopup(false);
    setFiles([
      {
        id: 1,
        file: "",
        path: ""
      }
    ]);
  }

  const onOpen = () => {
    setShowpopup(true);
  }

  const handleCancel = () => {
    setText('');
  };

  //#region api update calls
  const handleSave = async () => {
    const formDataBody = new FormData();
    setLoading(true);
    if (text.trim() !== '') {
      setComments((prevComments) => [...prevComments, text]);
      setText('');
    }

    if (files.length > 0) {
      files.forEach((file) => {
        if (file.file !== "") {
          formDataBody.append('fileList', file.file, `${file.path}/${file.file.name}`);
        }
      });
    }
    const requestBody = {
      "commentID": 0,
      "userComments": text,
      "commentByUserID": secureLocalStorage.getItem("userId"),
      "commentBy": secureLocalStorage.getItem("fullName"),
      "commentOn": "2023-12-19T13:54:11.622Z",
      "entityID": props?.subscriptionID,
      "entityTypeID": 0,
      "entityType": "string",
      "commentAttachments": [
        {
          "vdrItemID": 0,
          "attachmentID": 0,
          "fileName": "string",
          "filePath": "string",
          "containerURL": "string"
        }
      ]
    };
    formDataBody.append("appComment", JSON.stringify(requestBody));
    if (requestBody.userComments !== '') {
      const data = await AddComments(formDataBody)
      if (data.responseCode === 200) {
        setComments(data.responseData);
        /*  props.setCount(props.Count + 1); */
        if (refreshComponents) {
          refreshComponents()
        }
        setSelectedFolderName('')
        console.log('Comment saved successfully!');
        setFiles([intialFiles]);
        setLoading(false);
        toast.success("Comment Posted", { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      } else {
        setLoading(false);
        toast.error("Unable to Post Comment", { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      }
    }
    else {
      setLoading(false);
      toast.warning("Please Fill the data", { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }

  };

  //#region api get calls


  const fetchData = async () => {
    try {
      const data = await getSubscriptionComments(SubscriptionID);
      if (data.responseCode === 200) {
        setComments(data.responseData);
      } else {
        console.log('error happened');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const getAllFolderNames = async () => {
    try {
      const data = await getFolders();
      if (data.responseCode === 200) {
        const namesAndIds =

          props?.subscriptionStatus == "Approved" ?

            data.responseData.filter(u => u.documentType === "Investment related").map((item) => ({
              folderName: item.documentType.replace(/[()?]/g, ''),
              folderID: item.documentTypeID,
              parentFolderID: item.defaultFolderID,
            }))
            :
            data.responseData.filter(u => u.isSubscriptionLevel === true).map((item) => ({
              folderName: item.documentType.replace(/[()?]/g, ''),
              folderID: item.documentTypeID,
              parentFolderID: item.defaultFolderID,
            }));

        setFolderNames(namesAndIds);
      } else {
        // Handle error
      }
    } catch (error) {
      console.error('Error fetching folder names:', error);
    }
  };

  //#region useeffect
  useEffect(() => {
    fetchData();
    getAllFolderNames();
  }, []);

  const renderComments = () => {
    if(secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD){
      if(comments?.length > 0){
        return true
      }else{
        return false
      }
    }else {
      return true
    }
  }
 // console.log('condition', secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD ? ((comments?.length > 0) ? true : false ): true)
  //#region return
  return (

    <div>
      {comments ?
        <div>
          <>
            {
              renderComments () &&
              <>
                <Grid container>
                  <Grid item xs={12}>
                    <TextInput
                      placeholder="Enter your discussion"
                      multiline
                      name="Enter your discussion"
                      value={text}
                      rows={3}
                     // disabled={secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD ? ((comments.length > 0) ? false : true ): false}
                      minRows={5}
                      maxRows={5}
                      onChange={(e) => setText(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" style={{ position: 'absolute', top: 20, right: 10 }}>
                            <AttachFileIcon
                              sx={{ width: '25px', height: '25px', color: 'black', cursor: 'pointer !important' }}
                              onClick={onOpen}
                            />
                          </InputAdornment>
                        ),
                      }}
                      sx={{ width: '100%' }}
                    />
                  </Grid>
                </Grid>
                <br />
                <Grid container>
                  <Grid item xs={6}>
                    {
                      files.some((file) => file.file !== "") ? (
                        <div>
                          <div>Attached Files</div>
                          {files.map((file) => (
                            file.file !== "" ? (
                              <div className="fundRow" key={file.id}>
                                <div className="">
                                  <FileUploadButton
                                    name={file.id}
                                    defaultFile={file.file}
                                    label="UPLOAD DOCUMENT"
                                    onChange={(name, value) => handleFileUpload(name, value)}
                                  />
                                </div>
                              </div>
                            ) : null
                          ))}
                        </div>
                      ) : null
                    }



                  </Grid>


                  <Grid item xs={12} className='btnsGroup res-btnGroup'>
                    <ActionButton
                      variant="outlined" className="btn-primary"
                      icon={<CancelIcon />}
                      onClick={handleCancel} label="Close"
                      disabled={loading} />
                    <ActionButton variant="contained" className="btn-primary"
                      icon={<SendIcon />}
                      disabled={readOnly}
                      loading={loading}
                      onClick={() => handleSave(comments.length - 1)} label="Send" />
                  </Grid>
                </Grid>
                <br />
              </>
            }
          </>
          <Grid container marginBottom={'20px'}>
            <Grid item xs={12}>
              {comments.map((discussion) => (
                <div className='margin-top-10'>
                  <CommentSectionBox
                    commentid={discussion?.commentID}
                    name={discussion?.commentBy}
                    profileimg={"/icons/defaultpropic.jpg"}
                    description={discussion?.userComments}
                    attachedlinks={discussion?.commentAttachments}
                    date={discussion?.commentOn}
                  />
                </div>
              ))}
            </Grid>
          </Grid>
          {/* <AttachmentDocument
            open={isDialogOpen}
            onClose={handleDialogClose}
            onSave={(documentName, commentIndex, file) => handleAttachSave(documentName, commentIndex, file)}
            onFileUploadComplete={(uploadedFile) => handleFileUploadComplete(uploadedFile, comments.length)}
            commentIndex={comments.length - 1} selectedFolderName={selectedFolderName} setSelectedFolderName={setSelectedFolderName}
          /> */}
          {
            showpopup && <UploadMultiplefiles open={showpopup}
              onClose={onClose} files={files}
              onSubmit={onfilesSubmit}
              folderNames={folderNames}
              setFiles={setFiles} />
          }
        </div>
        : 'loading...'}
      <ToastContainer />
    </div>

  );
};

export default AppDiscussionComponent;
