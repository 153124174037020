import { createSlice } from "@reduxjs/toolkit";

const InitialData = {
    userDetails: {
        userEmail: "", // Logged in user email address
        tenantID: "", // present logged in tenant guid
        userId: "", // logged in user id 
        firstName: "", // logged in user firstName
        lastName: "", // logged in user lastName
        fullName: "", // logged in user fullName
        AssignedUserID: "", // logged in user role name
    },
    profileIcon: ""
}

const userDetailsSlice = createSlice({
    name: "userDetails",
    initialState: InitialData,
    reducers : {
        updateProfileIcon : (state,action) =>{
              state["profileIcon"] = action.payload.value
        },
        updateUserData: (state, action) => {
            state.userDetails[action.payload.field] = action.payload.value
        }
    }
})

export const {updateProfileIcon} = userDetailsSlice.actions;
export const {updateUserData} = userDetailsSlice.actions;
export const getProfileIcon = (state) => state.userDetails.profileIcon;
export const getUserDetails = (state) => state.userDetails.userDetails;
export default userDetailsSlice.reducer; 