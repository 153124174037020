import secureLocalStorage from "react-secure-storage";
import axiosApiCall from "../../Services/axiosApiCall";
import { getAPIResponse } from "../../../utils/common";


export async function getInvestorProfileDetails(emailId) {
    return await axiosApiCall.get(`/v1/User/GetUserDetails?emailAddress=${emailId}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function updateUserProfile(data,userId) {
    return await axiosApiCall.put(`/v1/User/UpdateProfileDetails?userId=${userId}`, data)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}