export const ReportsNames = [
    {
      id: 1,
      name: 'Balance Sheet',
      relatedFiles : [
        {
          id: 1,
          name: 'Q4 Accordian',
          component: "AssetsComponent",
          documnets : [
            {
              id: 1,
              fundName: 'Fund 1',
                file : "abc.pdf"
            },
            {
              id: 2,
              fundName: 'Fund 2',
              file : "abc1.pdf"
            },
            {
              id: 3,
              fundName: 'Fund 3',
              file : "abc1.pdf"
            }
          ]
        },
         {
          id: 1,
          name: 'Q3 Accordian',
          component: "AssetsComponent",
          documnets : [
            {
                id: 1,
                fundName: 'Fund 1',
                file : "abc.pdf"
            },
            {
              id: 2,
              fundName: 'Fund 2',
              file : "abc1.pdf"
            }
          ]
        },
      ]
    },
    {
      id: 2,
      name: 'Investment',
      relatedFiles : [
        {
          id: 1,
          name: 'Q4 Accordian',
          component: "AssetsComponent",
          documnets : [
            {
              id: 1,
              fundName: 'Fund 1',
                file : "abc.pdf"
            },
            {
              id: 2,
              fundName: 'Fund 2',
              file : "abc1.pdf"
            }
          ]
        },
         {
          id: 1,
          name: 'Q3 Accordian',
          component: "AssetsComponent",
          documnets : [
            {
                id: 1,
                fundName: 'Fund 1',
                file : "abc.pdf"
            },
            {
              id: 2,
              fundName: 'Fund 2',
              file : "abc1.pdf"
            }
          ]
        },
        {
          id: 1,
          name: 'Q2 Accordian',
          component: "AssetsComponent",
          documnets : [
            {
                id: 1,
                fundName: 'Fund 1',
                file : "abc.pdf"
            },
            {
              id: 2,
              fundName: 'Fund 2',
              file : "abc1.pdf"
            }
          ]
        },
      ]
    },
    {
      id: 3,
      name: 'Operations',
      relatedFiles : [
        {
          id: 1,
          name: 'Q4 Accordian',
          component: "AssetsComponent",
          documnets : [
            {
              id: 1,
              fundName: 'Fund 1',
                file : "abc.pdf"
            },
            {
              id: 2,
              fundName: 'Fund 2',
              file : "abc1.pdf"
            }
          ]
        },
         {
          id: 1,
          name: 'Q3 Accordian',
          component: "AssetsComponent",
          documnets : [
            {
                id: 1,
                fundName: 'Fund 1',
                file : "abc.pdf"
            },
            {
              id: 2,
              fundName: 'Fund 2',
              file : "abc1.pdf"
            }
          ]
        },
      ]
    },
    {
      id: 4,
      name: 'Cash Flow',
      relatedFiles : [
        {
          id: 1,
          name: 'Q4 Accordian',
          component: "AssetsComponent",
          documnets : [
            {
              id: 1,
              fundName: 'Fund 1',
                file : "abc.pdf"
            },
            {
              id: 2,
              fundName: 'Fund 2',
              file : "abc1.pdf"
            }
          ]
        },
         {
          id: 1,
          name: 'Q3 Accordian',
          component: "AssetsComponent",
          documnets : [
            {
                id: 1,
                fundName: 'Fund 1',
                file : "abc.pdf"
            },
            {
              id: 2,
              fundName: 'Fund 2',
              file : "abc1.pdf"
            }
          ]
        },
        {
          id: 1,
          name: 'Q2 Accordian',
          component: "AssetsComponent",
          documnets : [
            {
                id: 1,
                fundName: 'Fund 1',
                file : "abc.pdf"
            },
            {
              id: 2,
              fundName: 'Fund 2',
              file : "abc1.pdf"
            }
          ]
        },
      ]
    },
    {
      id: 5,
      name: 'Changes in Partner’s Capital',
      relatedFiles : [
        {
          id: 1,
          name: 'Q4 Accordian',
          component: "AssetsComponent",
          documnets : [
            {
              id: 1,
              fundName: 'Fund 1',
                file : "abc.pdf"
            },
            {
              id: 2,
              fundName: 'Fund 2',
              file : "abc1.pdf"
            }
          ]
        },
         {
          id: 1,
          name: 'Q3 Accordian',
          component: "AssetsComponent",
          documnets : [
            {
                id: 1,
                fundName: 'Fund 1',
                file : "abc.pdf"
            },
            {
              id: 2,
              fundName: 'Fund 2',
              file : "abc1.pdf"
            }
          ]
        },
      ]
    },
    {
      id: 6,
      name: 'Footnotes',
      relatedFiles : [
        {
          id: 1,
          name: 'Q4 Accordian',
          component: "AssetsComponent",
          documnets : [
            {
              id: 1,
              fundName: 'Fund 1',
                file : "abc.pdf"
            },
            {
              id: 2,
              fundName: 'Fund 2',
              file : "abc1.pdf"
            }
          ]
        },
         {
          id: 1,
          name: 'Q3 Accordian',
          component: "AssetsComponent",
          documnets : [
            {
                id: 1,
                fundName: 'Fund 1',
                file : "abc.pdf"
            },
            {
              id: 2,
              fundName: 'Fund 2',
              file : "abc1.pdf"
            }
          ]
        },
      ]
    },
    {
      id: 7,
      name: 'Portfolio Summary',
      relatedFiles : [
        {
          id: 1,
          name: 'Q4 Accordian',
          component: "AssetsComponent",
          documnets : [
            {
              id: 1,
              fundName: 'Fund 1',
                file : "abc.pdf"
            },
            {
              id: 2,
              fundName: 'Fund 2',
              file : "abc1.pdf"
            }
          ]
        },
         {
          id: 1,
          name: 'Q3 Accordian',
          component: "AssetsComponent",
          documnets : [
            {
                id: 1,
                fundName: 'Fund 1',
                file : "abc.pdf"
            },
            {
              id: 2,
              fundName: 'Fund 2',
              file : "abc1.pdf"
            }
          ]
        },
      ]
    },
    {
      id: 8,
      name: 'Detailed Schedule',
      relatedFiles : [
        {
          id: 1,
          name: 'Q4 Accordian',
          component: "AssetsComponent",
          documnets : [
            {
              id: 1,
              fundName: 'Fund 1',
                file : "abc.pdf"
            },
            {
              id: 2,
              fundName: 'Fund 2',
              file : "abc1.pdf"
            }
          ]
        },
         {
          id: 1,
          name: 'Q3 Accordian',
          component: "AssetsComponent",
          documnets : [
            {
                id: 1,
                fundName: 'Fund 1',
                file : "abc.pdf"
            },
            {
              id: 2,
              fundName: 'Fund 2',
              file : "abc1.pdf"
            }
          ]
        },
      ]
    },
    {
      id: 9,
      name: 'Company Update',
      relatedFiles : [
        {
          id: 1,
          name: 'Q4 Accordian',
          component: "AssetsComponent",
          documnets : [
            {
              id: 1,
              fundName: 'Fund 1',
                file : "abc.pdf"
            },
            {
              id: 2,
              fundName: 'Fund 2',
              file : "abc1.pdf"
            }
          ]
        },
         {
          id: 1,
          name: 'Q3 Accordian',
          component: "AssetsComponent",
          documnets : [
            {
                id: 1,
                fundName: 'Fund 1',
                file : "abc.pdf"
            },
            {
              id: 2,
              fundName: 'Fund 2',
              file : "abc1.pdf"
            }
          ]
        },
      ]
    },
    {
      id: 10,
      name: 'Fees & Expenses',
      relatedFiles : [
        {
          id: 1,
          name: 'Q4 Accordian',
          component: "AssetsComponent",
          documnets : [
            {
              id: 1,
              fundName: 'Fund 1',
                file : "abc.pdf"
            },
            {
              id: 2,
              fundName: 'Fund 2',
              file : "abc1.pdf"
            }
          ]
        },
         {
          id: 1,
          name: 'Q3 Accordian',
          component: "AssetsComponent",
          documnets : [
            {
                id: 1,
                fundName: 'Fund 1',
                file : "abc.pdf"
            },
            {
              id: 2,
              fundName: 'Fund 2',
              file : "abc1.pdf"
            }
          ]
        },
      ]
    }
  ]

export const quatarsdropdown= [
  {
    id : 1,
    name : "Q1"
  },
  {
    id : 2,
    name : "Q2"
  },
  {
    id : 3,
    name : "Q3"
  },
  {
    id : 4,
    name : "Q4"
  }
]

export const yearsdropdown = [
  {
    id : 2024,
    name : 2024
  },
  {
    id : 2023,
    name : 2023
  },
  {
    id : 2022,
    name : 2022
  },
  {
    id : 2021,
    name : 2021
  }
]