import { getGridStringOperators, getGridNumericOperators } from '@mui/x-data-grid-pro';
import { useEffect , useState } from 'react';
import { getFundFilterData } from '../marketplace/services/MarketplaceService';
import { ApplicationDateFilter, DropdownFilter, SubscriptionStatusFilter, InvestmentStatusFilter } from './DropdownFilter';

const stringOperators = getGridStringOperators().filter(
  (operator) => operator.value === 'contains' || operator.value === 'startsWith' || operator.value === 'endsWith'
);

const numericOperators = getGridNumericOperators().filter(
  (operator) => operator.value === '>' || operator.value === '<' || operator.value === '>=' || operator.value === '<='
);

const dropdownOperators = [
  {
    value: 'is',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.value) {
        return null;
      }
      return ({ value }) => value === filterItem.value;
    },
    InputComponent: DropdownFilter,
  },
];


const ApplicationDateOperators = [
  {
    value: 'is',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.value) {
        return null;
      }
      return ({ value }) => value === filterItem.value;
    },
    InputComponent: ApplicationDateFilter,
  },
];


export const subscriptionStatusOperator = [
  {
    value: 'is',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.value) {
        return null;
      }
      return ({ value }) => value === filterItem.value;
    },
    InputComponent: SubscriptionStatusFilter,
  },
];


export const investmentStatusOperator = [
  {
    value: 'is',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.value) {
        return null;
      }
      return ({ value }) => value === filterItem.value;
    },
    InputComponent: InvestmentStatusFilter,
  },
];

export { stringOperators, numericOperators, dropdownOperators , ApplicationDateOperators};
