import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Grid } from '@mui/material';
import AppTextInput from '../../../common/TextInput/AppTextField';
import { width } from '@mui/system';
import { MUIInputsMarginTop } from '../../../CommonCss';
import CheckBox from '@mui/icons-material/CheckBox';
import CheckBoxRenderingDemo from '../Components/CheckBoxRenderingDemo';
import { OBMCQdataTemplate } from './APIDataTemplates';
import { OBTextdataTemplate } from './APIDataTemplates';
import { FieldVariants } from '../../../common/TextInput/appInputenum';
import { validationsConstants } from '../../../../utils/AppConstants';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ALTTextField from '../../../common/input-fields/ALTTextField';
import { LoadingButton } from '@mui/lab';
import TouchAppIcon from '@mui/icons-material/TouchApp';

const AddNewTextQuestion = (props) => {

  //#region variables
  const [Open,setOpen] = useState(true);

  let currHighestStep = 0;
  props.data.forEach((item) => {
    currHighestStep = Math.max(currHighestStep, item.step);
  });
  let highestquestionBankID = 0;
  props.data.forEach((item) => {
    highestquestionBankID = Math.max(highestquestionBankID, item.questionBankID);
  });

  const NewData = {
    ...OBTextdataTemplate,
    squestionBankID: highestquestionBankID+1,
    questionTypeId: props?.investorTypeID,//Investor Type ID
    onboardingQuestionPattern: props?.textType?.toString(),//Question Pattern ID
    step: currHighestStep + 1
  };

  const [ItemData,setItemData] = useState(props.edit ? props.selecteditem : {
    ...NewData
  });

  const isoptionsDisabled = props.edit ? ItemData.isQuestionUsedByInvestor : false;

  //#region change events
  const handleChange = (name,value) => {
    questionDetails.setFieldValue(name, value);
  }
    
  const handleChangeChecbox = (name, value) => {
    questionDetails.setFieldValue(name, value) 
    if(name === "setValidations"){
      questionDetails.setFieldValue("allowAlphabets", value)
      questionDetails.setFieldValue("allowNumbers", value)
    }
    if(name === "setValidations" && !value){
      questionDetails.setFieldValue("characterLength", 0)
    }
  };

  //#region click events
  const onClose = () =>{
        setOpen(false)
        props.setSelectQuestionType(false);
  }

  const onSave = () => {
        if(props.edit){
          const updatedData = props.data.map((item) => {
            if (item.questionBankID === questionDetails.values.questionBankID) {
              return {
                ...questionDetails.values, 
              };
            }
            return item;
          });
        props.setNewData(updatedData);
        setOpen(false)
        props.setSelectQuestionType(false);
          setOpen(false)
          props.setSelectQuestionType(false);
        }
        else{
           
            /* ItemData["questionBankID"] = props.data.length+1; */
            const NewArrayData = [...props.data, questionDetails.values];
            /*   */
            
            props.setNewData(NewArrayData);
            setOpen(false);
            props.setSelectQuestionType(false);                
        }
  }

  //#region formik validations
  const obqQuestionValidationSchema = yup.object().shape({
    questionName: yup
        .string()
        .required(validationsConstants.REQUIRED),
    description: yup
        .string()
        .required(validationsConstants.REQUIRED),
    shortName: yup
        .string()
        .required(validationsConstants.REQUIRED),
    footerText: yup
        .string()
        .required(validationsConstants.REQUIRED), 
    footerToggleDescription: yup
        .string()
        .required(validationsConstants.REQUIRED),           
  });

  const questionDetails = useFormik({
    initialValues: ItemData,
    validationSchema: obqQuestionValidationSchema,
    onSubmit: (values) => {
      onSave()
    },
  });
  
  return (
    <Dialog open={Open} fullWidth>
       <DialogTitle>
          {props.edit ? "Edit a Question" : "Add a Question"} 
       </DialogTitle>
       <DialogContent>
          <form onSubmit={questionDetails.handleSubmit} novalidate="novalidate">
                  <div className='child-margin-15'>
                      <div>
                      <ALTTextField
                        name='questionName'
                        label="Question"
                        value={questionDetails.values.questionName}
                        onChange={(name, value)=>handleChange(name, value)} 
                        disabled={isoptionsDisabled}
                        required={true}
                        onBlur={questionDetails.handleBlur}
                        error={questionDetails.touched.questionName && Boolean(questionDetails.errors.questionName)}
                        errorMessage={questionDetails.touched.questionName && questionDetails.errors.questionName} />
                      </div>
                      <div>
                        <ALTTextField
                          name="description"
                          label="Question Label"
                          value={questionDetails.values.description}
                          onChange={(name, value)=>handleChange(name, value)}
                          disabled={isoptionsDisabled}
                          required={true}
                          onBlur={questionDetails.handleBlur}
                          error={questionDetails.touched.description && Boolean(questionDetails.errors.description)}
                          errorMessage={questionDetails.touched.description && questionDetails.errors.description} />
                      </div>
                      <div>
                        <ALTTextField
                            name="shortName"
                            label="Short Name"
                            value={questionDetails.values.shortName}
                            onChange={(name, value)=>handleChange(name, value)}
                            charactersMaxLength = {40}
                            disabled={isoptionsDisabled}
                            required={true}
                            onBlur={questionDetails.handleBlur}
                            error={questionDetails.touched.shortName && Boolean(questionDetails.errors.shortName)}
                            errorMessage={questionDetails.touched.shortName && questionDetails.errors.shortName} />
                      </div>
                      <div>
                        <ALTTextField
                          name="footerText"
                          label="Footer Label"
                          value={questionDetails.values.footerText}
                          onChange={(name, value)=>handleChange(name, value)}
                          disabled={isoptionsDisabled}
                          onBlur={questionDetails.handleBlur}
                          required={true}
                          error={questionDetails.touched.footerText && Boolean(questionDetails.errors.footerText)}
                          errorMessage={questionDetails.touched.footerText && questionDetails.errors.footerText} />
                      </div>
                      <div>
                          <ALTTextField
                            name="footerToggleDescription"
                            label="Footer Description"
                            value={questionDetails.values.footerToggleDescription}
                            onChange={(name, value)=>handleChange(name, value)}
                            disabled={isoptionsDisabled}
                            required={true}
                            onBlur={questionDetails.handleBlur}
                            error={questionDetails.touched.footerToggleDescription && Boolean(questionDetails.errors.footerToggleDescription)}
                            errorMessage={questionDetails.touched.footerToggleDescription && questionDetails.errors.footerToggleDescription} />
                      </div>
                  </div>
                  <div className='child-margin-10'>
                    <div>
                      <CheckBoxRenderingDemo
                        label="Set Validations"
                        name="setValidations"
                        value={questionDetails.values.setValidations}
                        handleChange={handleChangeChecbox}
                        isdisabled={isoptionsDisabled}
                        />
                    </div>
                    {
                      questionDetails.values.setValidations && 
                      <>
                        <div>
                            <CheckBoxRenderingDemo
                                label="Allow Alphabets"
                                name="allowAlphabets"
                                value={questionDetails.values.allowAlphabets}
                                handleChange={handleChangeChecbox}
                                isdisabled={isoptionsDisabled} />
                        </div>
                        <div>
                          <CheckBoxRenderingDemo
                            label="Allow Numbers"
                            name="allowNumbers"
                            value={questionDetails.values.allowNumbers}
                            handleChange={handleChangeChecbox}
                            isdisabled={isoptionsDisabled} />
                        </div>
                        <div>
                            <ALTTextField
                              name="characterLength"
                              label="Character Length"
                              placeholder="Please Enter Maximum Character Length"
                              value={questionDetails.values.characterLength}
                              disabled={isoptionsDisabled}
                              onChange={(name, value)=>handleChange(name, value)} />
                        </div>
                      </>
                    }  
                  </div>
          </form>
       </DialogContent>
       <DialogActions>
          <div className="space-between">
                      <div></div>
                      <div>
                          <Button
                            component="label"
                            variant="outlined"
                            style={{ width: '100px', backgroundColor: 'white', color: 'gray' }}
                            onClick={onClose}
                            >
                            Cancel
                        </Button>
                        <LoadingButton
                          variant='contained'
                          size="large"
                          onClick={questionDetails.handleSubmit}
                          style={{ width: '100px' , marginLeft: '5px'}}
                          loadingPosition="start">
                              <p className={`actionButtonLabel`}>SAVE</p> 
                      </LoadingButton>
                      </div>
          </div>
       </DialogActions>
    </Dialog>
  )
}

export default AddNewTextQuestion