
//This File Contains Functions to Convert time Zones

import moment from 'moment-timezone'; 

export const ConverttoIST = (date) => {
    const timeGapToIST = moment.duration(5, 'hours').add(30, 'minutes'); // Time gap between UTC and IST
    const notificationUpdatedDateIST = moment(date).add(timeGapToIST); // Convert to IST
    return notificationUpdatedDateIST.fromNow(); // Calculate relative time from now
};

export const formatDate = (dateString) => {
    if (!dateString) {
        return 'Invalid Date';
    }
    const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
};

