import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled, useTheme } from '@mui/material/styles'; // Import useTheme
import { DialogContent } from '@mui/material';
import NotifyCompany from './NotifyVDR';
import NotifyPortifolio from './NotifyPortifolio';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { height } from '@mui/system';
import NotifyPopupModel from './NotifyPopupModel';

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  fontFamily: theme.typography.fontFamily,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: 'black',
  height: '50px',
  border: '0.5px solid #ddd',
  minHeight: '60px',
  minWidth:'100px'

}));

function AddCompany(props) {

  const navigate = useNavigate();
  const [open, setOpen] = useState(props.ispopupopen);
  const theme = useTheme(); // Access the theme

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    props.setispopupopen(false);
  };

  const [notifyPortopen,setNotifyportopen] = useState (false);
  const onnotifyPortifolioClick = () => {
    /* setNotifyportopen(true);
    setOpen(false); */
    props.setispopupopen(false); 
    props.setNotifyPopupopen(true);
  }

  const onnotifyPortNextClick = () =>{
    
  }

  const onClicknavigate = () =>
  {
    navigate('/portfoliodetails');
    secureLocalStorage.setItem("companyID", 0);
    secureLocalStorage.setItem("CreateCompany", true);
  }

  return (
    <div>
      <Dialog open={props.ispopupopen} onClose={handleClose} >
        
        <DialogTitle>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                sx={{
                  position: 'absolute',
                  top: 15,
                  right: 30
                }}
              >
                <CloseIcon />
              </IconButton>
              <div className='heading'>Adding a Portfolio Company</div>
        </DialogTitle>

        <DialogContent>
            <Grid container spacing={2} >
              {/* Grid for Fund Administration Add Company */}
              <Grid item xs={12} className='cursor-pointer' onClick={onClicknavigate}>
                <StyledPaper className='addco'>
                  <div >Fund Administration Add Company Information</div>
                </StyledPaper>
              </Grid>
              
              <Grid item xs={12} className='cursor-pointer' onClick={onnotifyPortifolioClick}>
                <StyledPaper className='addco'>
                  <div>Notify Portfolio Company to Add Company Information</div>
                </StyledPaper>
              </Grid>

            </Grid>
        </DialogContent>

      </Dialog>

      {notifyPortopen && <NotifyPopupModel handleCancelModel={(data) => { setNotifyportopen(data) }}/>}
    </div>
  );
}

export default AddCompany;
