import React from 'react';
import { Toolbar, Button, Container, Box, Typography, Grid, Card, CardContent } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';


const drawerWidth = 240;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const HelpAndSupportTopBar = (props) => {
  const { setActiveBodySection, renderFAQ } = props;

 const onLinkClick = (section) => {
  setActiveBodySection(section)
 }

  return (
    <AppBar className="app-top-bar" sx={{ flexGrow: 1 }}>
      <Toolbar className="helpSupport-header cursor-pointer">
        <span className='helpSupprt-logo'>
          <img src="/logo-helpSupport.png" alt="Alt360 Logo"  onClick={() => onLinkClick('body')}/>
        </span>
        {/* <div>
          <Button color="inherit" onClick={() => onLinkClick('articles')}>Articles</Button>
          <Button color="inherit">Tutorials</Button>
          <Button color="inherit" onClick={() => onLinkClick('faq')}>FAQs</Button>
        </div> */}
      </Toolbar>
    </AppBar>
  );
}

export default HelpAndSupportTopBar;
