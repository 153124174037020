import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import SettingsIcon from '@mui/icons-material/Settings';

export default function AccountMenu({ header, menuItems, footer, anchorEl, open, handleClose, onViewMoreClick, onMenuItemClick }) {
    const handleMenuItemClick = (notifcationType, notificationDetailsID, notificationURL) => {
        onMenuItemClick(notifcationType, notificationDetailsID, notificationURL); // Call the callback function with the clicked item
        handleClose(); // Close the menu after handling the click
    };

    return (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            //onClick={handleClose}
            sx={{ marginLeft: '-100px', }}
        >
            {header && (
                <Typography variant="subtitle2" className='notifyhead'>{header} <SettingsIcon /> </Typography>
            )}
            {header && <Divider className='divider' />}
            <div className='notifybody'>
            {menuItems.map((item, index) => (
                <div key={index} className='notifyItem'>
                    <MenuItem onClick={() => handleMenuItemClick(item)}>
                        <p className='notifyname'>{item.label}</p>
                        {/* Check if relatedText exists and its length is greater than 30 characters */}
                        {item.relatedText && item.relatedText.length > 60 ? (
                            <p className='notifytext'>{`${item.relatedText.substring(0, 60)}...`}</p>
                        ) : (
                            <p className='notifytext'>{item.relatedText}</p>
                        )}
                    </MenuItem>
                </div>
            ))}
            </div>
            {footer && <Divider className='divider' />}
            {footer && (
                <MenuItem onClick={onViewMoreClick} className='notifyfooter'>
                    <Typography variant="subtitle2">{footer}</Typography>
                </MenuItem>
            )}
        </Menu>
    );
}
