import global from './variables';

export default class Configurations{
    static get apiBaseUri(){
        return global.window.variables.apiBaseUri;
    }
    static get apiBaseFrontEndUri(){
        return window.location.origin;
    }
    static get getTenantID(){
        return global.window.variables.tenantID;
    }
    static get getTenantName(){
        return global.window.variables.tenantName;
    }
    static get getClientID(){
        return global.window.variables.clientID;
    }
    static get getPolicyPrefix(){
        return global.window.variables.policyPrefix;
    }
    static get getAppInsightsInstrumentationKey(){
        return global.window.variables.appInsightInstumentationKey;
    }
}