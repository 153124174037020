import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Grid } from '@mui/material';
import { useState } from 'react';
import ActionButton from '../../common/ActionButton/ActionButton';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { formatDate } from '../../common/Functions/TimeConvestion';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

//#this popup is to exucte the distribution
const ExecuteDistributionPopup = (props) => {

   //#region props
   const { open, onClose,loading,onExecuteClick,selectedInvestors,gridColumns,distributionDetails } = props;

  return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>
              <h6>Distributions Confirmation</h6>
            </DialogTitle>
            <DialogContent>
                <div className='child-margin-10'>
                   <div>
                     <b>Distribution ID</b> : {distributionDetails?.distributionID}
                   </div>
                   <div>
                     <b>Payment Date</b> : {formatDate(distributionDetails?.dtPaymentDate)}
                   </div>
                   <div>
                    <b>Payment Method</b> : {distributionDetails?.paymentMethod}
                   </div>
                   <div>
                    <b>Distribution Type</b>  : {distributionDetails?.distributionStatus}
                   </div>
                   <div>
                    <DataGridPro
                        columns={gridColumns}
                        rows={
                            distributionDetails?.distributionInvestors?.length > 0 ?
                                distributionDetails.distributionInvestors.map((item) => {
                                    return {
                                        id: item.investorID,
                                        ...item
                                    };
                                }) : []
                        }
                        checkboxSelection
                        isRowSelectable={(params) => false}
                        rowSelectionModel={selectedInvestors || []}
                        hideFooter />
                   </div>
                   <div className='child-margin-5'>
                     <b>
                       Action Summary
                     </b>
                     <div>
                       Distributions activity notices will be sent to the selected Investors. once executed, this action cannot be undone.
                     </div>
                   </div>
                   <b>
                      Do you want to proceed with the Distributions? 
                   </b>
                </div>
            </DialogContent>
            <DialogActions>
                <div className='space-between'>
                    <div>
                        <ActionButton variant='outlined' className="btn-primary" icon={<CancelIcon />} onClick={onClose} label='Cancel' />
                    </div>
                    <div className='margin-left-10'>
                        <ActionButton
                        variant='contained' className="btn-primary"
                            label='CONFIRM'
                            loading={loading}
                            icon={<CheckCircleOutlineIcon />}
                            onClick={onExecuteClick} />
                    </div>
                </div>
            </DialogActions>
        </Dialog>
  )
}
export default ExecuteDistributionPopup;