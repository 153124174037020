import React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Checkbox,
    FormControlLabel,
    Radio,
    Button
  } from '@mui/material';
import { useState } from 'react';
import TextInput from '../../common/TextInput/TextInput';
import InviteExcistingInvestor from './InviteExcistingInvestor';
import { InviteNewInvestor } from './InviteNewInvestor';
import { getAllFunds } from '../Services/IRServices';
import { useEffect } from 'react';
import { getInvestorsList } from '../Services/IRServices';
import Grid from '@mui/material/Grid';
import RowRadioButtonsField from '../../common/RadioButtonGroup/RadiobuttonsField';

const ChooseInvestorType = (props) => {

  const {open,onClose,getInvestors,fundsDropdown} = props;

  const [selectedTab,setSelectedTab] = useState(1);

  const radibuttonLabels = [
    {label: "Existing investor",value: 1},
    {label: "New investor",value: 2},
    {label: "New investor for a specific fund",value: 3}
  ]

  const handleChange = (value) => {
    const intValue = parseInt(value, 10);
    setSelectedTab(intValue);
  }

  

  return (
    <Dialog open={open} fullWidth>
         <DialogTitle>
             <Grid xs={12}>
                <h6>Invite Investors </h6> 
             </Grid>
             <Grid container xs={12} marginTop={2}>
               <RowRadioButtonsField 
                  label={"select type"}
                  value={selectedTab}
                  options={radibuttonLabels}
                  onChange={handleChange}/>
             </Grid>
              
         </DialogTitle>
            {selectedTab === 1 ? (
                    <InviteExcistingInvestor onClose={onClose} fundsDropdown={fundsDropdown} />
                    ) : null}
                    {selectedTab === 2 || selectedTab === 3? (
                    <InviteNewInvestor onClose={onClose} type={selectedTab} getInvestors={getInvestors} fundsDropdown={fundsDropdown}/>
                    ) : null}
    </Dialog>
  )
}

export default ChooseInvestorType