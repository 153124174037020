import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { Viewer } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import { Document, Page } from 'react-pdf';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import { useState,useEffect } from 'react';

const NewsDialog = (props) => {
  const { open, onClose, itemData } = props;

  // Define custom styles for the dialog paper
  const dialogPaperStyles = {
    width: '1000px',
    maxWidth: 'none',
    height: '700px',
    maxheight: 'none',
  };

  /* const [pdfContent, setPdfContent] = useState(itemData);

  useEffect(() => {
    fetch(itemData)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        setPdfContent(url);
      });
  }, []); */
  

   
  return (
    // Use React.Fragment or just <>
    <>
        <Dialog
          open={open}
          onClose={onClose}
          PaperProps={{ style: dialogPaperStyles }}
        >
          <DialogTitle id="alert-dialog-title"></DialogTitle>
          <div style={{ padding: '16px' }}>
            <div className='ExternalFiles'>
              {/* //TODO: Need to remove check.pdf from public folder after getting API */}
              <iframe
                id="iframeId"
                src={itemData}
                className="custom-iframe"
                style={{
                  height: '430px',
                }}
              ></iframe>
            </div>
          </div>
          <DialogActions>
            <Button onClick={onClose} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>

    </>
  );
};

export default NewsDialog;
