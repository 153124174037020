export default function filterTreeData(tree, query, selectedDocumentType) {
    if (selectedDocumentType === "All" && query === "") {
        return tree;
    } else {
        return tree.reduce((result, node) => {
            // Check if the node title matches the query and if the selectedDocumentType matches or is empty
            const matches = 
                node.title.toLowerCase().includes(query.toLowerCase()) &&
                (selectedDocumentType === "All" || selectedDocumentType === "" || node.documentTypeID === parseInt(selectedDocumentType));

            if (matches) {
                result.push({ ...node });
            } else if (Array.isArray(node.children) && node.children.length > 0) {
                // Recursively filter the children nodes
                const matchedChildren = filterTreeData(node.children, query, selectedDocumentType);
                if (matchedChildren.length > 0) {
                    const clonedNode = { ...node, children: matchedChildren };
                    result.push(clonedNode);
                }
            }

            return result;
        }, []);
    }
}


