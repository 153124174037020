export const KycStatusvalues = {
    VERIFIED: "Verified",
    PENDING: "Update Required",
    INCOMPLETE: "Incomplete",
    REJECTED: "Rejected",
    UNDER_REVIEW: "Under Review",
    STAUTS_NEW: "New"
};

export const AMLStatusValues = {
    VERIFIED: "Verified",
    PENDING: "Update Required",
    INCOMPLETE: "Incomplete",
    REJECTED: "Rejected",
    UNDER_REVIEW: "Under Review",
    STAUTS_NEW: "New"
};

export const KycAMLStatusEnum = {
    VERIFIED: "Verified",
    PENDING: "Update Required",
    INCOMPLETE: "Incomplete",
    REJECTED: "Rejected",
    UNDER_REVIEW: "Under Review",
    STAUTS_NEW: "New"
};

export const KycAmlTabSwitch = {
    KYC_TAB: 0,
    AML_TAB: 1,
};

export const KycAmlStuatus = {
    KYC_STATUS: 0,
    AML_STATUS: 1,
};

/* const  getKycListItemID = (status) => {
     
    const selectedOption = kycdropdownvalues?.find(option => option.listItemValue === status);
    return selectedOption ? selectedOption.listItemID : null;
}

  const  getAmlListItemID = (status) => {
    const selectedOption = amldropdownvalues?.find(option => option.listItemValue === status);
    return selectedOption ? selectedOption.listItemID : null;
} */

//kycstatusdropdown

/* const [kycdropdownvalues, setKycdropdownvalues] = useState([]);
const [amldropdownvalues, setAmldropdownvalues] = useState([]);
const getKycDropdownValues = async() => {
    const response = await getkycdropdownvalues();
    if (response.responseCode === 200) {
        setKycdropdownvalues(response.responseData);
    }
    else{
        console.log('error');
    }
}

const getAmlDropdownValues = async() => {
const response = await getkycdropdownvalues();
if (response.responseCode === 200) {
  setAmldropdownvalues(response.responseData);
}
else{
    console.log('error');
}
} */