import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Grid, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect, useState } from 'react';
import secureLocalStorage from 'react-secure-storage';
import LoadingButton from '@mui/lab/LoadingButton';
import { ToastContainer, toast } from 'react-toastify';
import { updateCompanyManager } from '../services/services';
import { postNewUser } from '../../administration/Services/TabsData';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import ActionButton from '../../common/ActionButton/ActionButton';
import { getTenantRoles } from '../../InvestorRelations/Services/IRServices';

const AddCompanyManager = (props) => {

  //#region props
  const { open,onClose,companyDetails,getCompaniesDetails } = props;

  //#region initalData
  const initialData = {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      emailAddress: ''
  }

  //#region variables
  const [portfolioRole, setPortfolioRoles] = useState([]);
  const [loading,setLoading] = useState();

  //#region api post calls
  const createUser = async () =>{
    setLoading(true);
    const companyID = secureLocalStorage.getItem("companyID");
    const matchingRole = portfolioRole;
    const roleName = matchingRole ? matchingRole.description : null;
    const requestBody = {
      userId: 0, 
      userFirstName: managerValues.values.firstName,
      userLastName: managerValues.values.lastName,
      userFullName: `${managerValues.values.firstName} ${managerValues.values?.lastName}`,
      userEmailAddress: managerValues.values.emailAddress,
      phoneNumber: managerValues.values.phoneNumber,
      dateOfBirth: managerValues.values.birthDate || null, 
      briefDescription: "",
      tenantGUID: secureLocalStorage?.getItem("tenantID"), 
      userRoleID : portfolioRole.roleId,
      userRoleName : roleName,
      userRoles: [
        {
          "userId": 0,
          "roleId": portfolioRole.roleId,
          "roleName": roleName,
          "isPrimary": true
        }
      ],
    };
    const formData = new FormData();
    formData.append('profileImage', '');
    formData.append('tenantUserData', JSON.stringify(requestBody));
    const result = await postNewUser(formData);
    if(result?.responseCode === 200){
      const newManager = result?.responseData.find((user) => user.userEmailAddress === managerValues.values.emailAddress)
      const mappedToCompanyStatus = await updateCompanyManager(newManager?.userId,companyID);
      if(mappedToCompanyStatus.responseCode === 200){
        companyDetails.portofolioCompanyManagerID = newManager?.userId;
        getCompaniesDetails(companyID);
        setLoading(false);
        onClose();
        toast.success("User Created Successfully",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      }
      else{
        setLoading(false);
        onClose();
        toast.warning("User Created Successfully But Unable To Map to This Company",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      }
    }
    else if (result?.responseCode === 422) {
        setLoading(false);
        onClose();
      toast.error("User Already Exists. (or) Unable to create the User",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
    else if (result?.responseCode === 409) {
        setLoading(false);
        onClose();
      toast.warning("User Already Exists",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
    else {
        setLoading(false);
        onClose();
      toast.warning("Unable to create the User",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
  }

  //#region api get calls
  const getRoleNames = async () => {
    const data = await getTenantRoles(0);
    if (data.responseCode === 200) {
      const PCRole = data?.responseData?.find(role => role.roleName === "Portfolio Manager");
      setPortfolioRoles(PCRole);
    }
    else {
        setPortfolioRoles([]);
    }
  }

  //#region useeffect
  useEffect(()=>{
    getRoleNames();
  },[])

  //#region formik validations
  const managerValidationSchema = yup.object().shape({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    phoneNumber: yup
        .string()
        /* .required('This Field is required') */
        .matches(/^\d+$/, 'Phone Number must contain only digits')
        .min(10, 'Phone Number must be at least 10 digits')
        .max(10, 'Phone Number cannot exceed 10 digits'),
    emailAddress: yup
        .string()
        .required('This Field is required')
        .email('Invalid Email Address'),
    });

  const managerValues = useFormik({
            initialValues: {
                ...initialData
            },
            validationSchema: managerValidationSchema,
            onSubmit: (values) => {
                createUser();
            },
    });

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>
                <h6>Add Portfolio Company Manager</h6>
            </DialogTitle>
            <form onSubmit={managerValues.handleSubmit} novalidate="novalidate" className='margin-top-minus-10'>
                <DialogContent>
                <div className='text-fields-row'>
                        <div className='text-field-two-row'>
                            <TextField
                                id="firstName"
                                name="firstName"
                                label="First Name"
                                variant='standard'
                                fullWidth
                                value={managerValues?.values?.firstName}
                                onChange={managerValues.handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                    focused: true,
                                }}
                                required={true}
                                onBlur={managerValues.handleBlur}
                                error={managerValues.touched.firstName && Boolean(managerValues.errors.firstName)}
                                helperText={managerValues.touched.firstName && managerValues.errors.firstName} />
                        </div>
                        <div className='text-field-two-row'>
                        <TextField
                                id="lastName"
                                name="lastName"
                                label="Last Name"
                                variant='standard'
                                fullWidth
                                value={managerValues?.values?.lastName}
                                onChange={managerValues.handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                    focused: true,
                                }}
                                required={true}
                                onBlur={managerValues.handleBlur}
                                error={managerValues.touched.lastName && Boolean(managerValues.errors.lastName)}
                                helperText={managerValues.touched.lastName && managerValues.errors.lastName} />
                        </div>
                    </div>
                    <div className='text-fields-row'>
                        <div className='text-field-two-row'>
                        <TextField
                                id="emailAddress"
                                name="emailAddress"
                                label="Email Address"
                                variant='standard'
                                fullWidth
                                value={managerValues?.values?.emailAddress}
                                onChange={managerValues.handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                    focused: true,
                                }}
                                required={true}
                                onBlur={managerValues.handleBlur}
                                error={managerValues.touched.emailAddress && Boolean(managerValues.errors.emailAddress)}
                                helperText={managerValues.touched.emailAddress && managerValues.errors.emailAddress} />
                        </div>
                        <div className='text-field-two-row'>
                            <TextField
                                id="phoneNumber"
                                name="phoneNumber"
                                label="Phone Number"
                                variant='standard'
                                fullWidth
                                value={managerValues?.values?.phoneNumber}
                                onChange={managerValues.handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                    focused: true,
                                }}
                               /*  required={true} */
                                onBlur={managerValues.handleBlur}
                                error={managerValues.touched.phoneNumber && Boolean(managerValues.errors.phoneNumber)}
                                helperText={managerValues.touched.phoneNumber && managerValues.errors.phoneNumber} />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className='space-between'>
                        <div>
                            <ActionButton 
                                variant='outlined' 
                                className="btn-primary"
                                onClick={onClose} 
                                icon={<CancelIcon />}
                                label='Cancel'/>
                        </div>
                        <div className='margin-left-10'>
                            <LoadingButton
                                variant='contained'
                                className='btn-primary'
                                startIcon={<TouchAppIcon />}
                                loading={loading}
                                onClick={()=>{}}
                                type='submit'
                                loadingPosition="start">
                                <p className={`actionButtonLabel`}>SUBMIT</p>
                            </LoadingButton>
                        </div>
                    </div>
                </DialogActions>
            </form>
        </Dialog>
    )
}
export default AddCompanyManager;