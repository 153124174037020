import React from 'react'
import { formatAsUSACurrency } from '../CurrencyFormater/formatAsUSACurrency'

const CurrencyIndicator = (params) => {
  return (
    <>
       {formatAsUSACurrency(params.params.value)}
    </>
  )
}

export default CurrencyIndicator