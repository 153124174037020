import axiosApiCall from "../../Services/axiosApiCall";
import { getAPIResponse } from "../../../utils/common";
import secureLocalStorage from "react-secure-storage";
import moment from "moment/moment";

export async function getPCCompanybyID(companyID) {
    return await axiosApiCall.get(`/v1/PortfolioCompany/GetPortfolioCompaniesByCompany?companyID=${companyID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getPCTransactions(companyID) {
    return await axiosApiCall.get(`/v1/PortfolioCompany/GetPCTransaction?companyID=${companyID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getKeyValuePairs(type) {
    return await axiosApiCall.get(`/v1/MasterData/GetListItemsByType?listType=${type}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getPCDropDownDetails(type) {
    return await axiosApiCall.get(`/v1/PortfolioCompany/GetCompanyFitlerDetails`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getCompanyDetailsByID(companyID) {
    return await axiosApiCall.get(`/v1/PortfolioCompany/GetPortfolioCompaniesByCompany?companyID=${companyID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getTotalBuyQuantity(companyID, shareType) {
    return await axiosApiCall.get(`/v1/PortfolioCompany/GetPendingQuantityToSell?companyID=${companyID}&shareType=${shareType}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}


export async function createPortfolioCompany(fundID, data) {
    return await axiosApiCall.post(`/v1/PortfolioCompany/CreatePortofolioCompany?fundID=${fundID}&fundManagerID=${secureLocalStorage.getItem("userId")}&portfolioStrategyID=1`, data)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function createTransaction(companyID, data) {
    return await axiosApiCall.post(`/v1/PortfolioCompany/CreatePCTransaction?companyID=${companyID}`, data)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function updatePCCompanyDetails(data) {
    return await axiosApiCall.put(`/v1/PortfolioCompany/UpdateCompanyOverviewDetails`, data)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function updateCompanyManager(userID, companyID) {
    return await axiosApiCall.put(`/v1/PortfolioCompany/MapUserToPCAsync?companyID=${companyID}&userID=${userID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function createKeyValuePair(listType, value) {
    return await axiosApiCall.post(`/v1/MasterData/CreateMasterDataBasedOnTypeAsync?listType=${listType}&value=${value}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getPCTransactionsByFilter(companyID, filtersData, subFiltersData) {
    var year = subFiltersData.selectedYear;
    var month = subFiltersData.selectedMonth;
    var fromDt = "";
    var toDt = "";
    if (subFiltersData.selectedMonth !== '') {
        year = new Date().getFullYear();
    }
    if (subFiltersData.customFromDate !== '' || subFiltersData.customToDate !== '') {
        fromDt = moment(subFiltersData.customFromDate).format('YYYY-MM-DD');
        toDt = moment(subFiltersData.customToDate).format('YYYY-MM-DD');
    }
    var url = `/v1/PortfolioCompany/GetPCTransaction?companyID=${companyID}&Year=${year}&Month=${month}&FromDate=${fromDt}&ToDate=${toDt}&ShareType=${filtersData.selectedShareType}`

    return await axiosApiCall.get(url)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function GetPortfolioCompanyValue(companyID) {
    return await axiosApiCall.get(`/v1/PortfolioCompany/GetPortfolioCompanyValue?companyID=${companyID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function CreatePortfolioCompanyValue(companyID, data) {
    return await axiosApiCall.post(`/v1/PortfolioCompany/CreatePortfolioCompanyValue?companyID=${companyID}`, data)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}