import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import resolve from '../../common/resolve';
import axiosApiCall from '../../Services/axiosApiCall';
import { getAPIResponse } from '../../../utils/common';

export async function getScheduledEvents() {
    return await axiosApiCall.get(`/v1/CalenderEvent/Get`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
    });
}

export async function getCalendarEventbyID(eventID) {
    return await axiosApiCall.get(`/v1/CalenderEvent/GetById?calenderEventID=${eventID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
    });
}

export async function addCalendarEvent(data) {
    return await axiosApiCall.post(`/v1/CalenderEvent/Create`, data)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
    });
}

export async function updateCalendarEvent(data) {
    return await axiosApiCall.put(`/v1/CalenderEvent/Update`, data)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
    });
}

export async function deleteCalendarEvent(eventID) {
    return await axiosApiCall.delete(`/v1/CalenderEvent/Delete?calenderEventID=${eventID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
    });
}