import React, { useState , useRef , useEffect } from 'react';
import TextInput from '../../../common/TextInput/TextInput';
import "../../CompanyDetail.module.css"
import ActionButton from '../../../common/ActionButton/ActionButton';
import secureLocalStorage from 'react-secure-storage';

function LeadershipTeam(props) {
    const [open, setOpen] = useState(true);
    const componentRef = useRef(null);
    useEffect(() => {
        componentRef.current.scrollIntoView({ behavior: 'smooth' });
    }, []);

   
    const [addcallfields,setaddcallfields] = useState(false)
    const addcallclick =() => {
      setaddcallfields(true);
    }

    const [errors, setErrors] = useState({});
  
    const handleChange = (field, value) => {
      const validationResult = validateField(field, value);
          
          if (!validationResult.isValid) {
              setErrors(prevErrors => ({ ...prevErrors, [field]: validationResult.error }));
          } else {
              setErrors(prevErrors => ({ ...prevErrors, [field]: null }));    
              
              
          }
          props.data[field] = value;
          props.setapiData(props.data);
          validateAllErrors();
    };
  
  
      const validateField = (field, value) => {
      
        const validationRules = {
          ceoName: (value, currState) => {
                value = value.trim(); 
                
                const isValid = /^[A-Za-z\s]+$/.test(value);
            
                return {
                    isValid,
                    error: isValid ? null : `Please enter a Valid Name `,
                };
            },
       
            ceoPhone: (value, currState) => {
            value = value.trim();
            const isValid = /^\d{10}$/.test(value); 
            return {
                isValid,
                error: isValid ? null :  "Please enter a valid phone number (up to 10 digits)",
            };
        },
        ceoEmail: (value, currState) => {
            value = value.trim();
            const isValid = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}(?:\.[A-Za-z]+)*$/.test(value);
            return {
                isValid,
                error: isValid ? null : "Please enter a valid email address",
                };
            },
        cfoPhone: (value, currState) => {
                value = value.trim();
                const isValid = /^\d{10}$/.test(value); 
                return {
                    isValid,
                    error: isValid ? null :  "Please enter a valid phone number (up to 10 digits)",
                };
            },
        cfoEmail: (value, currState) => {
                value = value.trim();
                const isValid = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}(?:\.[A-Za-z]+)*$/.test(value);
                return {
                    isValid,
                    error: isValid ? null : "Please enter a valid email address",
                    };
                },
        otherContactPhone: (value, currState) => {
                    value = value.trim();
                    const isValid = /^\d{10}$/.test(value); 
                    return {
                        isValid,
                        error: isValid ? null :  "Please enter a valid phone number (up to 10 digits)",
                    };
                },
        otherContactEmail: (value, currState) => {
            value = value.trim();
            const isValid = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}(?:\.[A-Za-z]+)*$/.test(value);
            return {
                isValid,
                error: isValid ? null : "Please enter a valid email address",
                };
            },
    };
        return validationRules[field] ? validationRules[field](value) : { isValid: true, error: null };
    };
  
    const validateAllErrors = () =>{
      const errorsArray = Object.values(errors);
          const hasErrors = errorsArray.some(error => error !== null);
  
          const fieldsToAdd = ['ceoName','ceoPhone','ceoEmail','cfoName','cfoPhone',
                               'cfoEmail'];
  
          const trimmedValues = { ...props.data };
  
          const filteredValues = Object.keys(trimmedValues)
            .filter(field => fieldsToAdd.includes(field))
            .reduce((obj, key) => {
              obj[key] = trimmedValues[key];
              return obj;
            }, {});
  
           console.log(filteredValues);

          const Mandatory =  Object.values(filteredValues);

          const MandatoryFields = Mandatory.some(error => error === null || error === "");
          if (hasErrors || MandatoryFields) {
              secureLocalStorage.setItem("PCLeadershipTeam", true);
          } else {
              secureLocalStorage.setItem("PCLeadershipTeam", false);
          }
    }

    useEffect(()=>{
        validateAllErrors();
    },[])

    const MandatoryFieldErrors = (field, value) => {
        const fieldsToKeep = ['ceoName','ceoPhone','ceoEmail','cfoName','cfoPhone',
        'cfoEmail'];
    
          const trimmedValues = { ...props.data };
    
          const filteredFields = fieldsToKeep.map((field) => {
            if (trimmedValues.hasOwnProperty(field)) {
              return { field, value: trimmedValues[field] };
            }
            return null;
          }).filter(Boolean);
          const Mandatory = filteredFields.map(({ field, value }) => ({
            field,
            value: value === "" ? null : value,
          }));
          const EmptyFields = Mandatory.filter(entry => entry.value === "" || entry.value === null).map(entry => entry.field);
          const error = `This field is required.`;
          EmptyFields.map((item)=>{
              /*   */
              setErrors(prevErrors => ({ ...prevErrors, [item]: error }));
          })
      };
    
      useEffect(()=>{
         
      if(props.Onboardingsteperrors === true){
          MandatoryFieldErrors();
      }
    },[props.Onboardingsteperrors])
    

      return (
        <div ref={componentRef}>
        
            <div className="fundRow">
                <div className="fundDetail">
                    <TextInput 
                        label="CEO First Name" 
                        name="ceoName"
                        type="Onlytext"
                        required={true}
                        defaultValue={props?.data?.ceoName} 
                        onChange={(name, value)=>handleChange(name, value)}
                        errormessage={errors.ceoName || undefined}
                        />
                </div>
                <div className="fundDetail">
                <TextInput 
                        label="CEO Last Name" 
                        name="ceo lastname"
                        type="Onlytext"
                        // required={true} //need to be implemented in future sprints
                        onChange={(name, value)=>handleChange(name, value)}/>
                </div>
            </div>

            <div className="fundRow">
                <div className="fundDetail">
                    <TextInput 
                        label="CEO Phone Number" 
                        name="ceoPhone"
                        required={true}
                        defaultValue={props?.data?.ceoPhone}
                        editable={props.editable}
                        onChange={(name, value)=>handleChange(name, value)}
                        errormessage={errors.ceoPhone || undefined}
                        />
                </div>
                <div className="fundDetail">
                <TextInput 
                        label="CEO Email" 
                        name="ceoEmail"
                        required={true}
                        defaultValue={props?.data?.ceoEmail}
                        editable={props.editable}
                        onChange={(name, value)=>handleChange(name, value)}
                        errormessage={errors.ceoEmail || undefined}
                        />
                </div>
            </div>
            <div className="fundRow">

            </div>
            <div className="fundRow">
                <div className="fundDetail">
                    <TextInput 
                        label="CFO First Name" 
                        name="cfoName"
                        required={true}
                        type="Onlytext"
                        defaultValue={props?.data?.cfoName} 
                        errormessage={errors.cfoName || undefined}
                        editable={props.editable}
                        onChange={(name, value)=>handleChange(name, value)}/>
                </div>
                <div className="fundDetail">
                    <TextInput 
                        label="CFO Last Name" 
                        name="cfo lastname"
                        type="Onlytext"
                        editable={props.editable}
                        onChange={(name, value)=>handleChange(name, value)}/>
                </div>
            </div>
            <div className="fundRow">
                <div className="fundDetail">
                    <TextInput 
                        label="CFO Phone number" 
                        name="cfoPhone"
                        required={true}
                        defaultValue={props?.data?.cfoPhone}
                        editable={props.editable}
                        onChange={(name, value)=>handleChange(name, value)}
                        errormessage={errors.cfoPhone || undefined}
                        />
                </div>
                <div className="fundDetail">
                <TextInput 
                        label="CFO Email" 
                        name="cfoEmail"
                        required={true}
                        defaultValue={props?.data?.cfoEmail}
                        editable={props.editable}
                        onChange={(name, value)=>handleChange(name, value)}
                        errormessage={errors.cfoEmail || undefined}
                        />
                </div>
            </div>
            
    {addcallfields  && (<div>
      <div className="fundRow">
                <div className="fundDetail">
                    <TextInput 
                        type="select"
                        label="Title" 
                        name="otherContactTitle"
                        defaultValue={props?.data?.otherContactTitle} 
                        options={[
                          {label: 'Sr. Vice President', value: 'Sr. Vice President'},
                          {label: 'Jr. Vice President', value: 'Jr. Vice President'}]}
                      onChange={(name, value)=>handleChange(name, value)}/>
                </div>
                <div className="fundDetail">
                <TextInput 
                        label="CMO First Name" 
                        name="otherContactName"
                        type="Onlytext"
                        defaultValue={props?.data?.otherContactName}
                        editable={props.editable}
                        onChange={(name, value)=>handleChange(name, value)}/>
                </div>
            </div>
<div className="fundRow">
                <div className="fundDetail">
                <TextInput 
                        label="CEO Last Name" 
                        name="ceolastname"
                        type="Onlytext"
                        editable={props.editable}
                        onChange={(name, value)=>handleChange(name, value)}/>
                </div>
                <div className="fundDetail">
                <TextInput 
                        label="CFO Phone Number" 
                        name="otherContactPhone"
                        defaultValue={props?.data?.otherContactPhone} 
                        editable={props.editable}
                        onChange={(name, value)=>handleChange(name, value)}
                        errormessage={errors.otherContactPhone || undefined}
                        />
                </div>
                </div>
                <div className="fundRow">
                <div className="fundDetail">
                <TextInput 
                        label="CFO Email" 
                        name="otherContactEmail"
                        defaultValue={props?.data?.otherContactEmail}
                        editable={props.editable}
                        onChange={(name, value)=>handleChange(name, value)}
                        errormessage={errors.otherContactEmail || undefined}
                        />
                </div>
                </div>
                </div>
    )}
            <div className="fundRow">
                <div className="fundDetail">
                    <ActionButton 
                        variant="outlined"
                        label="ADD MORE" 
                        startIconName="ControlPointOutlined" 
                        onClick={addcallclick}
                        styleProps={{
                            borderColor: '#b7b7b7',
                            color: '#b7b7b7',
                            fontSize: '14px',
                        }}/>
                </div>
            </div>

        </div>
      );
}
export default LeadershipTeam;