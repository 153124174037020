import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import LinearProgress from '@mui/material/LinearProgress';
import { useNavigate } from 'react-router-dom';
import { ComponentsTab } from '../../common/Tabs/ComponentsTab';
import AudtingGrid from './AudtingGrid';
import { HeaderTemplates, PageHeader } from '../../common/PageHeader/PageHeader';
import { AuditingType, FeesExpenses, FundDetails, InvestmentStrategy, ManagementTeam } from '../audititngenum';
import { useLocation } from 'react-router-dom';
import { getFundAuditing, getInvestorProfileAuditing, getSubscriptionAuditing } from '../services/services';
import TabsComponment from '../../common/Tabs/TabsComponment';
import moment from 'moment';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { fundTypesEnum } from '../../marketplace-dashboard/jsonData';

const FundAudit = () => {

  //#region variables
  const navigate = useNavigate();

  const [fundDetailsInfo,setfundDetailsInfo] = useState(null);
  const [fundManagementInfo,setfundManagementInfo] = useState();
  const [fundInvestmentInfo,setfundInvestmentInfo] = useState(null);
  const [feesExpensesInfo,setFeesExpensesInfo] = useState(null);

  const [fundDetailsColumns,setfundDetailsColumns] = useState(null);
  const [fundManagementColumns,setfundManagementColumns] = useState();
  const [fundInvestmentColumns,setfundInvestmentColumns] = useState(null);
  const [feesExpensesColumns,setFeesExpensesColumns] = useState(null);
  const [fundName, setFundName] = useState();

  const [selectedTab,setSelectedTab] = useState(0);
  const location = useLocation();
  const { state } = location;
  const auditingType = state.auditingType;

  const columns = [
    { field: 'field', headerName: '', width: 400, },
    { field: 'value', headerName: '', width: 400 },
  ]

  //#region tabs
  const [tabs,setTabs] = useState([]);

  const FundTabs = [
    {
        key : 0,
        icon : <ReceiptLongIcon/>,
        label : "FUND DETAILS",
        component : <AudtingGrid />
    },
    {
        key : 1,
        icon : <Diversity2Icon/>,
        label : "MANAGEMENT TEAM",
        component : <AudtingGrid />
    },
    {
        key : 2,
        icon : <CurrencyExchangeIcon/>,
        label : "INVESTMENT STRATEGY",
        component : <AudtingGrid />
    },
    {
      key : 3,
      icon : <ReceiptIcon/>,
      label : "FEES & EXPENSES",
      component : <AudtingGrid />
    },
  ]

  //#region api get calls
  const getFundAuditInfo = async () => {
    const selectedID = state.auditID;
    const selectedFundType = state.auditDetails?.fundType;

    const data = await getFundAuditing(selectedID);
    
    if (data?.responseCode === 200) {
        // Extract personal information fields
        const fundDetailsInfoData = data.responseData?.fundDetailsAuditing;
        const managmentInfoData = data.responseData?.fundManagementTeamAuditing;
        const investmentInfoData = data.responseData?.fundInvestmentStrategyAuditing;
        const feesExpensesInfoData = data.responseData?.fundFeesAndExpensesAuditing;
        if(data.responseData?.fundDetailsAuditing != null){
          setFundName(data.responseData?.fundDetailsAuditing[0].fundName);
        }
        const fundDetailsFields = fundDetailsInfoData?.map((dataItem, index) => ({
            id: index,
            ...dataItem,
            updatedDate : moment(dataItem.updatedDate).format('MM-DD-YYYY'),
        }));        
        const fundDetailsColumns = fundDetailsInfoData && fundDetailsInfoData?.length > 0 ?
                                    Object.entries(fundDetailsInfoData[0]).map(([key, value], index) => ({
                                      field: key,
                                      headerName: FundDetails[key],
                                      width: 200,
                                    })) : [];

        const fundManagementInfoFields = managmentInfoData?.map((dataItem, index) => ({
          id: index,
          ...dataItem,
          updatedDate : moment(dataItem.updatedDate).format('MM-DD-YYYY'),
        }));        
        const fundManagementColumns = managmentInfoData && managmentInfoData.length > 0 ?
                                    Object.entries(managmentInfoData[0]).map(([key, value], index) => ({
                                      field: key,
                                      headerName: ManagementTeam[key],
                                      width: 200,
                                    })) : [];

        const investmentInfoFields = investmentInfoData?.map((dataItem, index) => ({
          id: index,
          ...dataItem,
          updatedDate : moment(dataItem.updatedDate).format('MM-DD-YYYY'),
        }));       
        debugger;
        const investmentInfoColumns = investmentInfoData && investmentInfoData.length > 0 ?
                                    Object.entries(investmentInfoData[0])
                                    .filter(([key, value]) => {
                                      return selectedFundType === fundTypesEnum.PRIVATE_CREDIT
                                        ? key !== "valueCreationAndOperImprovementStrategies" && key !== "exitStrategies"
                                        : true; 
                                    })                                    
                                     .map(([key, value], index) => ({
                                      field: key,
                                      headerName: InvestmentStrategy[key],
                                      width: 200,
                                    })) : [];

        const feesExpensesFields = feesExpensesInfoData?.map((dataItem, index) => ({
            id: index,
            ...dataItem,
            updatedDate : moment(dataItem.updatedDate).format('MM-DD-YYYY'),
            }));       
        const feesExpensesandColumns = feesExpensesInfoData && feesExpensesInfoData.length > 0 ?
                                        Object.entries(feesExpensesInfoData[0]).map(([key, value], index) => ({
                                        field: key,
                                        headerName: FeesExpenses[key],
                                        width: 200,
                                        })) : [];

        setfundDetailsColumns(fundDetailsColumns)
        setfundDetailsInfo(fundDetailsFields);
        setfundManagementColumns(fundManagementColumns);
        setfundManagementInfo(fundManagementInfoFields);
        setfundInvestmentColumns(investmentInfoColumns);
        setfundInvestmentInfo(investmentInfoFields);
        setFeesExpensesColumns(feesExpensesandColumns);
        setFeesExpensesInfo(feesExpensesFields);
      
    } else {
      // Handle error if needed
    }
  }

  //#region useEffect
  useEffect(()=>{
    getFundAuditInfo();
  },[])

  useEffect(()=>{
    if(auditingType === AuditingType.FUND){
      let modifiedTabs = FundTabs;
      if (modifiedTabs[0]) {
        modifiedTabs[0].component = <AudtingGrid columns={fundDetailsColumns} rows={fundDetailsInfo} fileName={`${fundName}_FundDetailsAuditData`}/>;
      }
      if (modifiedTabs[1]) {
        modifiedTabs[1].component = <AudtingGrid columns={fundManagementColumns} rows={fundManagementInfo} fileName={`${fundName}_FundManagementAuditData`}/>;
      }
      if (modifiedTabs[2]) {
        modifiedTabs[2].component = <AudtingGrid columns={fundInvestmentColumns} rows={fundInvestmentInfo} fileName={`${fundName}_FundInvestmentAuditData`}/>;
      }      
      if (modifiedTabs[3]) {
        modifiedTabs[3].component = <AudtingGrid columns={feesExpensesColumns} rows={feesExpensesInfo} fileName={`${fundName}_FundFeesExpensesAuditData`}/>;
      }   
      setTabs(modifiedTabs)
    }
  },[fundDetailsInfo])

  //#region return
  return (
    <div>
        <PageHeader
            title="Auditing Overview"
            template={HeaderTemplates.NORMAL_VIEW}/>
        <div className="wrapper3">
            {fundDetailsInfo && fundDetailsColumns ? (
                <>
              
                    <TabsComponment tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>
              
                </>
            ) : (
                <Grid container xs={12} className='whiteCard'>
                <LinearProgress />
                </Grid>
            )}
        </div>
      <ToastContainer />
    </div>
  )
}

export default FundAudit