import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import LinearProgress from '@mui/material/LinearProgress';
import { useNavigate } from 'react-router-dom';
import { ComponentsTab } from '../../common/Tabs/ComponentsTab';
import AudtingGrid from './AudtingGrid';
import { HeaderTemplates, PageHeader } from '../../common/PageHeader/PageHeader';
import { AuditingType, SubscriptionDetails, SubscriptionSteps } from '../audititngenum';
import { useLocation } from 'react-router-dom';
import { getInvestorProfileAuditing, getSubscriptionAuditing } from '../services/services';
import TabsComponment from '../../common/Tabs/TabsComponment';
import moment from 'moment';

const SubscriptionAudit = () => {

  //#region variables
  const navigate = useNavigate();

  const [subscriptionStepsInfo,setsubscriptionStepsInfo] = useState(null);
  const [subscriptionInfo,setSubscriptionInfo] = useState();
  const [subscriptionCommentsInfo,setSubscriptionCommentsInfo] = useState(null);
  const [subscriptionStepsColumns,setSubscriptionStepsColumns] = useState(null);
  const [subscriptionColumns,setSubscriptionColumns] = useState();
  const [subscriptionCommentsColumns,setsubscriptionCommentsColumns] = useState(null);

  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // Month
    const dd = String(date.getDate()).padStart(2, '0'); // Day
    const yyyy = date.getFullYear(); // Year
    return `${mm}-${dd}-${yyyy}`;
  };

  const [selectedTab,setSelectedTab] = useState(0);
  const location = useLocation();
  const { state } = location;
  const auditingType = state.auditingType;

  const columns = [
    { field: 'field', headerName: '', width: 400, },
    { field: 'value', headerName: '', width: 400 },
  ]

  //#region tabs
  const [tabs,setTabs] = useState([]);
  const SubscriptionTabs= [
    {
        key : 0,
        Icon : "",
        label : "SUBSCRIPTION STEPS",
        component : <AudtingGrid />
    },
    {
        key : 1,
        Icon : "",
        label : "SUBSCRIPTION DETAILS",
        component : <AudtingGrid />
    },
    // {
    //     key : 2,
    //     Icon : "",
    //     label : "Subscription Comments Details",
    //     component : <AudtingGrid />
    // },
  ]

  //#region api get calls
  const getSubscriptionAuditInfo = async () => {
    const selectedUserID = state.auditUserID;
    
    const data = await getSubscriptionAuditing(selectedUserID);
    
    if (data?.responseCode === 200) {
        // Extract personal information fields
        const subscriptionStepsInfoData = data.responseData?.subscriptionStepsAuditing;
        const investorSubscription = data.responseData?.investorFundSubscriptionAuditing;
        const commentsAuditing = data.responseData?.subscriptionCommentsAuditing;

        const subscriptionStepsFields = subscriptionStepsInfoData?.map((dataItem, index) => ({
            id: index,
            ...dataItem,
            updatedDate : moment(dataItem.updatedDate).format('MM-DD-YYYY'),
        }));        
        const subscriptionStepsColumns = subscriptionStepsInfoData && subscriptionStepsInfoData?.length > 0 ?
                                    Object.entries(subscriptionStepsInfoData[0]).map(([key, value], index) => ({
                                      field: key,
                                      headerName: SubscriptionSteps[key],
                                      width: 200,
                                    })) : [];

        const subscriptionInfoFields = investorSubscription?.map((dataItem, index) => ({
          id: index,
          ...dataItem,
          updatedDate : moment(dataItem.updatedDate).format('MM-DD-YYYY'),
        }));        
        const subscriptionColumns = investorSubscription && investorSubscription.length > 0 ?
                                    Object.entries(investorSubscription[0]).map(([key, value], index) => ({
                                      field: key,
                                      headerName: SubscriptionDetails[key],
                                      width: 200,
                                    })) : [];

        const subsriptionCommentsFields = commentsAuditing?.map((dataItem, index) => ({
          id: index,
          ...dataItem,
          updatedDate : moment(dataItem.updatedDate).format('MM-DD-YYYY'),
        }));       
        const subscriptionCommentsColumns = commentsAuditing && commentsAuditing.length > 0 ?
                                    Object.entries(commentsAuditing[0]).map(([key, value], index) => ({
                                      field: key,
                                      headerName: key,
                                      width: 200,
                                    })) : [];

        setSubscriptionStepsColumns(subscriptionStepsColumns)
        setsubscriptionStepsInfo(subscriptionStepsFields);
        setSubscriptionColumns(subscriptionColumns);
        setSubscriptionInfo(subscriptionInfoFields);
        setsubscriptionCommentsColumns(subscriptionCommentsColumns);
        setSubscriptionCommentsInfo(subsriptionCommentsFields);
      
    } else {
      // Handle error if needed
    }
  }

  //#region useEffect
  useEffect(()=>{
    getSubscriptionAuditInfo();
  },[])

  useEffect(()=>{
    if(auditingType === AuditingType.SUBSCRIPTION){
      let modifiedTabs = SubscriptionTabs;
      if (modifiedTabs[0]) {
        modifiedTabs[0].component = <AudtingGrid columns={subscriptionStepsColumns} rows={subscriptionStepsInfo}  fileName={`SubscriptionStepsAuditData`}/>;
      }
      if (modifiedTabs[1]) {
        modifiedTabs[1].component = <AudtingGrid columns={subscriptionColumns} rows={subscriptionInfo} fileName={`SubscriptionInfoAuditData`}/>;
      }
      if (modifiedTabs[2]) {
        modifiedTabs[2].component = <AudtingGrid columns={subscriptionCommentsColumns} rows={subscriptionCommentsInfo} />;
      }      
      setTabs(modifiedTabs)
    }
  },[subscriptionStepsInfo])

  return (
    <div>
        <PageHeader
            title="Auditing Overview"
            template={HeaderTemplates.NORMAL_VIEW}/>
        <div className="wrapper3">
            {subscriptionStepsInfo && subscriptionStepsColumns ? (
                <>
               
                    <TabsComponment tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>
              
                </>
            ) : (
                <Grid container xs={12} className='whiteCard'>
                <LinearProgress />
                </Grid>
            )}
        </div>
      <ToastContainer />
    </div>
  )
}

export default SubscriptionAudit