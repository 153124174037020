import { useCallback, useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import styles from "../FundManagerUserProfile.module.css";
import userprofilestyles from "../userprofile1.module.css";
import secureLocalStorage from "react-secure-storage";
import NavigationBar from "../../common/navigation-bar/NavigationBar";
import UserProfilePopUp from "./UserProfilePopUp";
import AIPDataGrid from "../../common/DataGrid/AIPDataGrid";
import jsondata from './Fm.json';
import moment from 'moment';
import TreeItem from '@mui/lab/TreeItem';
import FolderIcon from '@mui/icons-material/Folder';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Checkbox from '@mui/material/Checkbox';

import { FundUserOverview } from "./FundUserOverview";
import { FundUserPersonalInfo } from "./FundUserPersonalInfo";
import { FundUserBankingInfo } from "./FundUserBankingInfo";
import { FundUserInvestmentProfile } from "./FundUserInvestmentProfile";
import DashboardHeader from "../../dashboards/DashboardHeader";
import FundUserHeader from "./FundUserHeader";
import { useSelector } from "react-redux";
import { getrootBackgroundColorConfig } from "../../../state/slices/colorConfigSlice";
import Indicator from "../../common/DataGrid/Indicator";

import InvestorInvestmentinfo from "../InvestorUserProfile/InvestorInvestmentinfo";

const FundManagerUserProfile = () => {
  const [isExpanded, setExpendState] = useState(false);
  const userID = secureLocalStorage.getItem("userId");

  const navigate = useNavigate();
  const location = useLocation();
  const {detail }= useParams();
  const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);


  //PopUp
  const [showPopup, setShowPopup] = useState(false);
  const onButtonClick = () => {
    setShowPopup(true);
  }
  const handlePopUpCancel = () => {
    // Close the popup without removing the item
    setShowPopup(false);
  };

  const [isSaveEnabled, setSaveEnabled] = useState(false);
  const onEditSaveButtonClick = () => {
    setSaveEnabled(isSaveEnabled => !isSaveEnabled);
  };

  

  const onBackArrowButtonClick = useCallback(() => {
    navigate("/FundManager-Dashboard");
  }, [navigate]);

  

  const [investorProfile, setInvestorProfile] = useState({});
  const [isOverviewClicked, setIsOverviewClicked] = useState(true);
  const [isPersonalInfoClicked, setIsPersonalInfoClicked] = useState(false);
  const [isBankingInfoClicked, setIsBankingInfoClicked] = useState(false);
  const [isInvestmentProfileClicked, setIsInvestmentProfileClicked] = useState(false);

  const emailId = location?.state?.subscriberEmail;
  
  const onOverviewInfoClickedClick = () => {
    setIsOverviewClicked(true);
    setIsPersonalInfoClicked(false);
    setIsBankingInfoClicked(false);
    setIsInvestmentProfileClicked(false);
  }
  const onPersonalInfoClickedClick = () => {
    setIsOverviewClicked(false);
    setIsPersonalInfoClicked(true);
    setIsBankingInfoClicked(false);
    setIsInvestmentProfileClicked(false);
  }

  const onBankingInfoClick = () => {
    setIsOverviewClicked(false);
    setIsPersonalInfoClicked(false);
    setIsBankingInfoClicked(true);
    setIsInvestmentProfileClicked(false);
  }

  const onInvestmentProfileClick = () => {
    setIsOverviewClicked(false);
    setIsPersonalInfoClicked(false);
    setIsBankingInfoClicked(false);
    setIsInvestmentProfileClicked(true);
  }


  
  
  // const originalDate = "2023-07-13T11:32:19.2696292+05:30";
  // const formattedDate = moment(originalDate).format('DD-MM-YYYY');


  const [funddocuments,setFundDocuments]=useState();


  useEffect(() => {
    

  }, []);

  const FundDocumentsRows = funddocuments ? funddocuments.map(document => ({
    id: document.fundDocumentID,
    Name: document.title,
    Type: document.documentType,
    Date: moment(document.uploadDate).format('DD-MM-YYYY')
  })) : [];

  const [fundNotifications, setFundNotifications] = useState();

  useEffect(() => {

    const fetchData1 = async () => {

      
    };
    fetchData1();

  }, []);
  const NotificationsRowsData = fundNotifications ? fundNotifications.map(user=> ({

    // id: user.sortOrder,
    id:user.fundID,
    "UserName"  :  user.name,
    "FundName"  :  user.fundName,
    "Status"    :  user.actionName,
    // "Type"      :  user.role,
    

  })) : [];
  const NotificationsColumns = [
    {
      "id": 1,
      "field": "UserName",
      "headerName": "User Name",
      "width": 200,
      "renderCell": (params) => {
        return <Indicator params={params} />
      }
    },
    {
      "id": 2,
      "field": "FundName",
      "headerName": "Fund Name",
      "width": 190,
      "renderCell": (params) => {
        return <Indicator params={params} />
      }
    },
    {
      "id": 3,
      "field": "Status",
      "headerName": "Status",
      "width": 130,
      "renderCell": (params) => {
        return <Indicator params={params} />
      }
    },
    // {
    //   "id": 3,
    //   "field": "Type",
    //   "headerName": "Type",
    //   "width": 120,
    //   "renderCell": (params) => {
    //     return <Indicator params={params} />
    //   }
    // }
  ]

  const Performancecolumns = [
    {
      "id": 1,
      "field": "Data",
      "headerName": "",
      "width": 400
  },
  {
      "id": 2,
      "field": "Committed Capital",
      "headerName": "",
      "width": 100
  }
  ]
  const keyMapping = {
    totalCommittedFund: 'Total Commitments to Fund',
    calledCapital: 'Cumulative Paid in Capital',
    calledCapitalInPercentage:'Capital In Percentage',
    distributedCapital: 'Cumulative Distributions',
    numberOfExits: 'Number of Exits',
    nav: 'Total Net Asset Value (NAV)',
    gav: 'Gross Asset Value',
    grossIRR: 'Gross IRR',
    grossMultipleToCost: 'Gross Multiple to Cost',
    fundNetIRR: 'Fund Net IRR (after accrual for carried interest and fees)',
    distributionPaidIn: 'Distributions to Paid in Capital (DPI)',
    residualValuePaidIn: 'Residual Value to Paid in Capital (RVPI)',
    totalValuePaidIn: 'Total value to Paid in Capital (TVPI)',
    multipleOnInvestedCapital: 'Multiple of Invested Capital (MOIC)',
    fundedToCommittedRatio: 'Funded Commitment to Committed Capital',
    paidInCapitalToCommittedCapital: 'Paid in Capital to Committed Capital (PICC)',
    totalInvestedInPortfolioCompanies: 'Total Invested In Portfolio Companies',
    additionalCommitmentsToPortfolioCompanies: 'Additional Commitments To Portfolio Companies',
  };
  
  // const [performanceData, setPerformanceData] = useState(null);
  
  // const fetchDataforFundPerformanceMetrics = async () => {
  //  
  //     if (response.ok) {
        
  //       const jsonData = await response.json();
      
  //       console.log("hi", jsonData);
        
  //       let data = Object.keys(jsonData)?.map((e, index) => {
  //         let formattedKey = keyMapping[e] || e; 
  //         let ret = {
  //           'id': index,
  //           'Data': formattedKey,
  //           'Committed Capital': CurrencyFormatter(jsonData[e]) 
  //         }
  //         return ret;
  //       }); 
  //       setPerformanceData(data);
  
  //     } else {
        
  //       console.error(response);
  //       throw new Error("Failed to fetch data");
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  
  // fetchDataforFundPerformanceMetrics();
  
 

    const [tree,setTree] = useState(null);

    const options = [
      'Share',
      'Shareable Link',
      'Download',
      
      
    ];
    const ITEM_HEIGHT = 48;
   
   
    
       //treeview fetch
       useEffect(() => {
        const fetchDataforTree = async () => {
        
        };
    
        fetchDataforTree();
      }, []);  
    const [selectedNodes, setSelectedNodes] = useState([]);
  
  
    const handleCheckboxChange = (title, node) => {
      setSelectedNodes((prevSelectedNodes) => {
        // Check if the node is already in the prevSelectedNodes array
        const isSelected = prevSelectedNodes.some((selectedNode) => selectedNode.title === title);

        if (isSelected) {
          // Remove the selected node from the array
          return prevSelectedNodes.filter((selectedNode) => selectedNode.title !== title);
        } else {
          // Add the selected node to the array along with the node object
          return [...prevSelectedNodes, { title, node }];
        }
      });
    };
    
  
    const [userInput, setUserInput] = useState('');
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    
    };
    
    const handleMenuItemClick = (option) => {
      // Handle the click event for each menu item here
      console.log('Menu item clicked:', option);
  
      // Show the dialog when "Add Folder" is clicked
      if (option === 'Add Folder') {
        setOpen(true);
      }else if (option === 'Upload File') {
        setOpen1(true);
      }
    };
  
    const handleClose = () => {
      setOpen(false); 
      setAnchorEl(false);// Close the dialog
    };
    console.log(tree);
   console.log(selectedNodes);
   const handleSave = async () => {
    if (selectedNodes.length > 0) {
      const selectedNode = selectedNodes[1].node; // Assuming you are adding the folder to the first selected node
      const fullFilePath = `${selectedNode.documentPath}/${selectedNode.documentName}/${userInput}`;
      console.log(fullFilePath);
  
      // Make the API call to create the new folder
      
    } else {
      console.log('No node selected');
    }
  };
  
  
    const handleFileChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        setSelectedFile(file);
      }
    };
    
    const handleFileUpload = () => {
      console.log('Selected File:', selectedFile);
    
      // Close the dialog
      setOpen1(false);
    };
    const renderTree = (node) => (
      <TreeItem
        key={node.documentName}
        nodeId={node.documentName}
        selectedNodes={node}
        label={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={selectedNodes.some((selectedNode) => selectedNode.title === node.documentName)}
              
              onChange={() => handleCheckboxChange(node.documentName, node)}
            
  
            />
            {node.isFolder ? <FolderIcon style={{ color: '#FFE9A2' }} /> : <PictureAsPdfIcon style={{ color: '#FA0F00' }} />}
            <span style={{ marginLeft: 5 }}>{node.documentName}</span>
          </div>
        }
      >
        {Array.isArray(node.children) ? node.children.map((childNode) => renderTree(childNode)) : null}
      </TreeItem>
    );

    // const { subscriberEmail, subscriberID } = location.state || {};
    const [subscriberEmail, setSubEmail] = useState(location?.state?.subscriberEmail);
  
    const [investmentData, setinvestmentData] = useState([]);


    useEffect(() => {
        fetchData();
      }, [ ]);
    
      const fetchData = () => {
     
      }
    


  const fundUserRouteconfig= [
    {
        path: "overview",
        component: <FundUserOverview/>
    },
    {
        path: "personal-info",
        component: <FundUserPersonalInfo investorProfile={investorProfile}/>
    },
    {
        path: "banking-info",
        component: <FundUserBankingInfo investorProfile={investorProfile}/>
    },
    {
        path: "investment-profile",
        component: <InvestorInvestmentinfo data={investorProfile} fromFm={true}/>
    },
]

const FundUserInfoTag =  fundUserRouteconfig.find(el=> el.path==detail).component;
  

  return (
    <div style={{backgroundColor: rootBackColorConfig.color, height: '100%'}} className=''>
      <FundUserHeader selectedTab={detail} />
      <div className={styles.fundDetailsBody}>{FundUserInfoTag}</div>
    </div>
  );
};

export default FundManagerUserProfile;