import React from 'react'
import { useEffect, useState } from 'react';
import secureLocalStorage from 'react-secure-storage';
import { DocumentsGETType } from '../../../InvestorReporting/InvestorReportingEnum';
import CircularProgress from '@mui/material/CircularProgress';
import K1Documents from './K1Documents';
import { getApprovedSubscribersListForFund, getK1ReportDocumentsForFund } from './Services/K1Services';
import { toast } from 'react-toastify';
import { AppDashboards } from '../../../../utils/enum';

function K1Main() {
      //#region variables
  const [foldersList, setFoldersList] = useState(null);
  const [ApprovedInvestorsForFund,setApprovedInvestorsForFund] = useState(null)
  const [documents,setDocuments] = useState(null)
  const [refreshComponent,setRefreshComponent] = useState(true)
  const [serviceCalled,setserviceCalled] = useState(false)
  const [showOnlyAddDocuments,setshowOnlyAddDocuments] = useState(false)

  //#region api get calls
  const getFoldersDetails = async () => {
    const data = await getK1ReportDocumentsForFund(secureLocalStorage.getItem('FundId'),(secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD ? secureLocalStorage.getItem('userId'): 0));
    if (data?.responseCode === 200) {
      setDocuments(data?.responseData)
      setshowOnlyAddDocuments(true)
     const folderNames = data?.responseData?.map((item) => {
      return {
        name: item.year,
        relatedFiles: []
      };
    });
      const folderNamesWithComponent = folderNames?.map((folder) => {
        return {
          ...folder,
          component: <div className='accordian-wrapper'>
                         <div className="loading-container">
                            <CircularProgress />
                          </div>
                     </div>
        }
      });

      setFoldersList(folderNamesWithComponent);
      setserviceCalled(true)
    } else if (data?.responseCode === 404 ){
      setDocuments(null)
      setshowOnlyAddDocuments(true)
      setserviceCalled(true)
    }
     else { 
      setserviceCalled(true)
      toast.warning(data?.responseData,{
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
        style: {
          marginTop: '100px',
        },
      })
    }
  }

  const getApprovedSubscribers = async () => {
    const data = await getApprovedSubscribersListForFund(secureLocalStorage.getItem('FundId'))
    if(data.responseCode === 200) {
      setApprovedInvestorsForFund(data.responseData.filter(u=>u.subscriptionStatus === 'Approved'))
    }else{
      // toast.warning(data.responseData, {
      //   position: toast.POSITION.TOP_RIGHT,
      //   theme: 'colored',
      //   style: {
      //     marginTop: '100px',
      //   },
      // });
    }
  }

  //#region useEffect
  useEffect(() => {
    getFoldersDetails();
    getApprovedSubscribers()
  }, []);
  return (
    <div>
      {
         serviceCalled && refreshComponent ?   
           <K1Documents 
               documents={documents} 
               isfileUpload={true}
               showOnlyAddDocuments={showOnlyAddDocuments}
               ApprovedInvestorsForFund ={ApprovedInvestorsForFund} 
               reports={foldersList} 
               refreshComponent = {refreshComponent}
               setRefreshComponent = {setRefreshComponent}
               setFoldersList={setFoldersList} 
               getFoldersDetails={getFoldersDetails} 
               documentsFor={DocumentsGETType.FIRM_USERS}/>  : "Loading..."
      }
    
  </div>
  )
}

export default K1Main