import TextInput from "../../common/TextInput/TextInput";
import styles from "../FundManagerUserProfile.module.css";
import userprofilestyles from "../userprofile1.module.css";


export const FundUserPersonalInfo = (props) => {
    const { investorProfile } = props;

    function handleOptionSelect(option) {
        document.querySelector(`.${styles.fieldClass}`).innerText = option;
      }

    return(
            <div className={`${styles.overviewWrapper} disablediv`}>
                <div className={`${styles.fundStatusWrapper} ${styles.flexGap}`}>
                    <div className={styles.flexOneThird}>
                        <div className={styles.inputWrapper}>
                        <TextInput
                            defaultValue={investorProfile?.personalInformation?.emailAddress}
                            label="Email Address"
                            onChange={() => {}}
                        />
                        </div>
                        <div className={styles.inputWrapper}>
                        <TextInput
                            defaultValue={investorProfile?.personalInformation?.phoneNo}
                            label="Phone Number"
                            onChange={() => {}}
                        />
                        </div>
                        <div className={styles.inputWrapper}>
                        <TextInput
                            defaultValue={investorProfile?.personalInformation?.addressLine1}
                            label="Address Line 1"
                            onChange={() => {}}
                        />
                        </div>
                        <div className={styles.inputWrapper}>
                        <TextInput
                            defaultValue={investorProfile?.personalInformation?.addressLine2}
                            label="Address Line 2"
                            onChange={() => {}}
                        />
                        </div>
                        <div className={styles.inputWrapper}>
                        <TextInput
                            defaultValue='hyd'
                            label="City"
                            onChange={() => {}}
                        />
                        </div>
                        <div className={styles.inputWrapper}>
                        <TextInput
                            defaultValue='NY'
                            label="State"
                            onChange={() => {}}
                        />
                        </div>
                        <div className={styles.inputWrapper}>
                        <TextInput
                            defaultValue={investorProfile?.personalInformation?.zipCode}
                            label="Zip Code"
                            type="zipcode"
                            onChange={() => {}}
                        />
                        </div>
                    </div>
                    <div className={styles.flexOneThird}>
                        <div className={styles.inputWrapper}>
                        <TextInput
                            defaultValue={investorProfile?.personalInformation?.taxFillingContact}
                            label="Tax Filing Contact"
                            onChange={() => {}}
                        />
                        </div>
                        <div className={styles.inputWrapper}>
                        <TextInput
                            defaultValue={investorProfile?.personalInformation?.annualPreTaxIncome}
                            label="Annual Pre-Tax Income"
                            onChange={() => {}}
                        />
                        </div>
                        <div className={styles.inputWrapper}>
                        <TextInput
                            defaultValue={investorProfile?.personalInformation?.numberOfDependents}
                            label="# of Dependents"
                            onChange={() => {}}
                        />
                        </div>
                        <div className={styles.inputWrapper}>
                        <TextInput
                            defaultValue={investorProfile?.personalInformation?.employerStatus}
                            label="Employer Status"
                            onChange={() => {}}
                        />
                        </div>
                        <div className={styles.inputWrapper}>
                        <TextInput
                            defaultValue={investorProfile?.personalInformation?.employer}
                            label="Employer"
                            onChange={() => {}}
                        />
                        </div>
                        <div className={styles.inputWrapper}>
                        <TextInput
                            defaultValue={investorProfile?.personalInformation?.occupation}
                            label="Occupation"
                            onChange={() => {}}
                        />
                        </div>
                        <div className={styles.inputWrapper}>
                        <TextInput
                            defaultValue={investorProfile?.personalInformation?.spouseAnnualPreTaxIncome}
                            label="Spouse Annual Pre-Tax Income"
                            onChange={() => {}}
                        />
                        </div>
                    </div>
                    <div className={styles.flexOneThird}>
                        <div className={styles.inputWrapper}>
                        <TextInput
                            defaultValue="Yes"
                            label="Spouse Has IIA Account"
                            onChange={() => {}}
                        />
                        </div>
                        <div className={styles.inputWrapper}>
                        <TextInput
                            defaultValue={investorProfile?.personalInformation?.federalTaxBracket}
                            label="Federal Tax Bracket"
                            onChange={() => {}}
                        />
                        </div>
                        <div className={styles.inputWrapper}>
                        <TextInput
                            defaultValue={investorProfile?.personalInformation?.householdInvestableAssets}
                            label="Household Investable Assets"
                            onChange={() => {}}
                        />
                        </div>
                    </div>
                </div>
            </div>

    )
}