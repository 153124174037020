import React, { useState } from "react";
import ReactLoading from 'react-loading';
import { useNavigate } from "react-router-dom";
import "./AnalyzingScreen.css";
import CircleIcon from "../../chatbot/Icon";
import { useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const AnalyzingScreen = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const {InvestorDetails} = state;

  setTimeout(() => {
    // Replace the path below with the desired route you want to navigate to
     
    if(InvestorDetails.fundID !== 0){
      secureLocalStorage.setItem("FundId", InvestorDetails.fundID);
      navigate('/fund-details', {
        state: {
          FundID: InvestorDetails.fundID,
          InvestorID: secureLocalStorage.getItem("userId"),
          SubscriptionID: 0,
          navigatefrommarketplace: true,
          disableSubscriptionButton:false
        }
      });
    }
    else{
      navigate('/user-profile');
    }
  }, 2000); // 2000 milliseconds = 2 seconds

  return (
   
    <div className="login d-flex align-items-center py-5">
        <div className="message">
            Analyzing your profile information
        </div>
        <p className="tag">We'll make a few alternative investment recommendations for you.</p>
        <div className="loading-container">
        <ReactLoading type="spin" color="#0000FF" height={100} width={100} />
        </div>
        <CircleIcon/>   
     </div>
        
);
};
export default AnalyzingScreen;