import React from 'react'

const PercentageIndicator = (params) => {
  return (
    <>
       {params.params.value}%
    </>
  )
}

export default PercentageIndicator