import React, { useEffect } from 'react'
import FirmDetails from './FirmDetails'
import FirmUserProfile from './FirmUserProfile'
import BasicTab from '../../../administration/TabComponent'
import { FirmUserProfiletabs } from '../FirmUserProfileenum'
import { useState } from 'react'
import { Grid } from '@mui/material';
import { getrootBackgroundColorConfig } from '../../../../state/slices/colorConfigSlice'
import { useSelector } from 'react-redux'
import { getFirmDetails } from '../services/firmdetaailsservices'
import { ToastContainer, toast } from 'react-toastify';
import { getFirmTypeDetails } from '../services/firmdetaailsservices'
import { getRegulatoryComplianceDetails } from '../services/firmdetaailsservices'
import { getFirmStructureDetails } from '../services/firmdetaailsservices'
import { regulatorOptions } from '../enum'
import BasicTabs from '../../../PortfolioCompanies/components/TabSwitch'
import ReactLoading from 'react-loading';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { AppDashboards } from '../../../../utils/enum'
import secureLocalStorage from 'react-secure-storage'
import { width } from '@mui/system'
import CircleIcon from '../../../chatbot/Icon'

const FirmUserProfileModal = () => {

  //#region White Labeling
  const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);

  //#region Variables
  const [cuurrentTab, setCurrentTab] = useState(FirmUserProfiletabs.PROFILE_TAB);
  const [refreshSignal, setRefreshSignal] = useState(false);
  const [firmDetails, setFirmDetails] = useState(null);
  const [firmStructureDropdown, setFirmStructureDropdown] = useState(null);
  const [firmTypeDropdown, setFirmTypeDropdown] = useState(null);
  const [regulatoryComplianceDropdown, setRegulatoryComplianceDropdown] = useState(null);
  const [otherOptionID, setOtherOptionID] = useState(null);
  const [isSaveEnabled, setisSaveEnabled] = useState(false);

  //#region change events
  const ChangeTabIndex = async (newValue) => {
    /*   */
    if (isSaveEnabled) {
      setCurrentTab(newValue);
    }

  };

  //#region api get calls
  const getFirmStructureDropdown = async () => {
    const data = await getFirmStructureDetails();
    if (data?.responseCode == 200) {
      setFirmStructureDropdown(data?.responseData);
    } else {
      toast.warning(data?.responseData,
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      console.log("Unable to fetch getfirmStructureDetails data.")
    }
  }

  const getFirmTypeDropdown = async () => {
    const data = await getFirmTypeDetails();
    if (data?.responseCode == 200) {
      setFirmTypeDropdown(data?.responseData);
    } else {
      toast.warning(data?.responseData,
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      console.log("Unable to fetch getFirmTypeDetails data.")
    }
  }

  const getRegulatoryComplianceDropdown = async () => {
    const data = await getRegulatoryComplianceDetails();
    if (data?.responseCode == 200) {
      setRegulatoryComplianceDropdown(data?.responseData);
      const otherOption = data?.responseData.find(item => item.listItemValue === regulatorOptions.OTHER_OPTION);
      setOtherOptionID(otherOption.listItemID);
    } else {
      /* toast.warning(data?.responseData,
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" }); */
      console.log("Unable to fetch getRegulatoryComplianceDetails data.")
    }
  }

  const getTenantDetails = async () => {
    const data = await getFirmDetails();
    if (data?.responseCode == 200) {

      setFirmDetails(data?.responseData);
    } else {
      /* toast.warning(data?.responseData,
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" }); */
        setFirmDetails({});
      console.log("Unable to fetch getfirmStructureDetails data.")
    }
  }

  //#region useEffect
  useEffect(() => {
    // debugger
    getFirmStructureDropdown();
    getFirmTypeDropdown();
    getRegulatoryComplianceDropdown();
    getTenantDetails();
  }, []);

  //#region Tabs
  const iconstyle = {
    marginRight: '3px',
    marginBottom: '3px',
    width: '24px',
    height: '24px'
  }
  const pcTabs = [
    {
      id: 1,
      name: 'Employee Information',
      label: 'Employee Information',
      component: <FirmUserProfile key={refreshSignal} setRefreshSignal={setRefreshSignal} cuurrentTab={cuurrentTab} setCurrentTab={setCurrentTab}
        isSaveEnabled={isSaveEnabled}
        setisSaveEnabled={setisSaveEnabled} />,
      icon: <PersonOutlineOutlinedIcon sx={iconstyle} />,
      MUIIcon: true,
    }
  ];
  const tabs = [
    {
      id: 1,
      name: 'Employee Information',
      label: 'Employee Information',
      component: <FirmUserProfile key={refreshSignal} setRefreshSignal={setRefreshSignal} cuurrentTab={cuurrentTab} setCurrentTab={setCurrentTab}
        isSaveEnabled={isSaveEnabled}
        setisSaveEnabled={setisSaveEnabled} />,
      icon: <PersonOutlineOutlinedIcon sx={iconstyle} />,
      MUIIcon: true,
    },
    {
      id: 2,
      name: 'Firm Information ',
      label: 'Firm Information ',
      component: <FirmDetails initalDetails={firmDetails} setInitialDetails={setFirmDetails} getTenantDetails={getTenantDetails}
        regulatoryComplianceDropdown={regulatoryComplianceDropdown}
        firmStructureDropdown={firmStructureDropdown}
        firmTypeDropdown={firmTypeDropdown}
        otherOptionID={otherOptionID}
        isSaveEnabled={isSaveEnabled}
        setisSaveEnabled={setisSaveEnabled} />,
      icon: <PersonOutlineOutlinedIcon sx={iconstyle} />,
      MUIIcon: true,

    }
  ];

  return (
    <div className='wrapper mt2-page' style={{ backgroundColor: rootBackColorConfig.color }}>
      <Grid container xs={12} spacing={2}>
        {(firmDetails ?
          <BasicTabs
            tabs={secureLocalStorage.getItem("userrole") === AppDashboards.PC_DASHBOARD ? pcTabs : tabs}
            currtab={cuurrentTab}
            setCurrTabindex={setCurrentTab}
            ChangeTabIndex={ChangeTabIndex}
            isSaveEnabled={isSaveEnabled} /> :
          <div className="loading-container">
            <ReactLoading type="spin" color="#0000FF" height={100} width={100} />
          </div>)}
      </Grid>
      <CircleIcon/>
    </div>
  )
}

export default FirmUserProfileModal