import React from 'react'
import propTypes from 'prop-types';
import { FieldTypes } from './utlis/InputFieldsEnum';
import ALTTextField from './ALTTextField';
import SelectField from './SelectField';
import DateField from './DateField';
import MultiSelectField from './MultiSelectField';
import RadioGroupField from './RadioGroupField';
import AutoSelectField from './AutoSelectFiled';

const InputField = ({ type, ...props }) => {
  return (
    <>
      {type === FieldTypes.TEXT_FIELD && <ALTTextField {...props} />}
      {type === FieldTypes.SELECT_FIELD && <SelectField {...props} />}
      {type === FieldTypes.DATE_FIELD && <DateField {...props} />}
      {type === FieldTypes.RADIO_GROUP_FIELD && <RadioGroupField {...props} />}
      {type === FieldTypes.MULTI_SELECT_FIELD && <MultiSelectField {...props} />}
      {type === FieldTypes.AUTO_SELECT_FIELD && <AutoSelectField {...props} />}
    </>
  );
}

InputField.propTypes = {
    fieldType: propTypes.string,
    type: propTypes.string,
    textFormatType: propTypes.oneOf(['usPhoneNumberFormat', 'usCurrencyFormat', 'text', 'onlyAlphabets', 'zipcode', 'percentage', 'alphaNumeric']),
    onChange: propTypes.func.isRequired,
    variant: propTypes.oneOf(['standard', 'filled', 'outlined']),
    required: propTypes.bool,
    multiline: propTypes.bool,
    defaultValue: propTypes.string,
    placeholder: propTypes.string,
    rows: propTypes.number,
    label: propTypes.string,
    disabled: propTypes.bool,
    options: propTypes.array,
    name: propTypes.string,
    size: propTypes.string,
    tabIndex: propTypes.number,
    charactersMaxLength: propTypes.number,
    charactersMinLength: propTypes.number,
    dataType: propTypes.string,
};

InputField.defaultProps = {
    fieldType: '',
    type: 'text',
    textFormatType: 'text',
    variant: 'standard',
    size: 'small',
    required: false,
    multiline: false,
    defaultValue: '',
    placeholder: '',
    rows: 4,
    label: '',
    options: [],
    disabled: false,
    name: '',
    tabIndex: 0,
    charactersMaxLength: 100, //set based on your preference
    charactersMinLength: 100,
    dataType : 'string'
};

export default InputField;